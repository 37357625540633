import React from "react";
import { useSelector } from "react-redux";
import { activeProfileDetails } from "../../../store/SessionSlice";
import SessionTimeout from "../../../hoc/SessionTimeout";
import { Menu } from "./Menu";
import Header from "./Header";


const PatientFormLayout = ({children}) =>{
  console.log('children',children);
  const profileDetails = useSelector(activeProfileDetails);
  const reloadId = useSelector(state=>state.common.reloadKeyProps);
  return (
    <>
    <SessionTimeout timeout={process.env.REACT_APP_SESSION_TIMEOUT} />
    <div className={""}>
      <div id="">
        {/* <Menu
          MenusData={[]}
          Configures={[]}
          setActiveMenu={''}
          activeMenu={''}
          sideBarChangeHandler={''}
        /> */}
        <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column patient-content-wrap">
          <div id="content">
            <Header activeMenu={''} />
            <div className="container-fluid">
              <div className="sh_mainPortview" id="sh_mainportal_view">
                <div className="sh_innerPortview" key={reloadId}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
    );
  };



export default PatientFormLayout;