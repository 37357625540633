import React from "react";
import { isSet } from "../../../../../../utils/commonUtils";

const UnOrderedList = ({notes,label,col})=>{
    const column = isSet(col,'col-4');
    return(
        <>
          {isSet(notes) && notes.length > 0 ?
                <div className={column}>
                    <strong>{label}</strong>
                        <ul style={{ marginLeft: '30px'}}>
                            {notes.map((itm, itmIndex) => {
                                return (
                                    <li key={itmIndex}>{itm}</li>
                                )
                            })}
                        </ul>
                </div>
            : <></>}
        </>
    )
}

UnOrderedList.defaultProps = {
    col:'col-4'
}
export default UnOrderedList;

