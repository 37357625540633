
import { isSet } from "../../../../../../utils/commonUtils";
// import { ItentificationTypeOptions } from "../../../../../../utils/constant";

export const ScreeningVision = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[
                {
                    label:"Standard is at least 20/40 acuity (Snellen) in each eye with or without correction. At least 70° field of vision in horizontal meridian measured in each eye. The use of corrective lenses should be noted on the Medical Examiner's Certificate",
                    type:'Label',
                    col:'col-12'                
                },
                {
                    label:'Uncorrected',
                    type:'Label',
                    appendClass:'bg-light',
                    col:'col-12',
                },
                {
                    label:'Right Eye',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'uncorrected_right_eye',
                    id:'uncorrected_right_eye',
                },
                {
                    label:'Left Eye:',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'uncorrected_left_eye',
                    id:'uncorrected_left_eye',
                },
                {
                    label:'Both Eyes:',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'uncorrected_both_eyes',
                    id:'uncorrected_both_eyes',
                },

                {
                    label:'Corrected',
                    type:'Label',
                    appendClass:'bg-light',
                    col:'col-12',
                },
                {
                    label:'Right Eye',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'corrected_right_eye',
                    id:'corrected_right_eye',
                },
                {
                    label:'Left Eye:',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    isNumeric:true,
                    name:'corrected_left_eye',
                    id:'corrected_left_eye',
                },
                {
                    label:'Both Eyes:',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'corrected_both_eyes',
                    id:'corrected_both_eyes',
                },
                {
                    label:'Horizontal Field of Vision',
                    type:'Label',
                    appendClass:'bg-light',
                    col:'col-12',
                },
                {
                    label:'Right Eye: (degrees)',
                    type:'Number',
                    rangeMin:70,
                    col:'col-6',
                    name:'horizontal_field_of_vision_right_eye',
                    id:'horizontal_field_of_vision_right_eye',
                },
                {
                    label:'Left Eye: (degrees)',
                    type:'Number',
                    rangeMin:70,
                    col:'col-6',
                    name:'horizontal_field_of_vision_left_eye',
                    id:'horizontal_field_of_vision_left_eye',
                },
        ]
        },
    
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
            {
                label:'Suggested Result',
                type:'TextInput',
                disabled:true,
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'suggested_status',
            },{
                type:'Blank',
                name:'blank',
            },
            {
                label:'Final Determination',
                type:'SelectList',
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'status',
            },
            
           
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
    let status = 'Not Started';
    
            const unCorrectedRightEye  = isSet(formData['uncorrected_right_eye'],'');
            const unCorrectedBothEyes  = isSet(formData['uncorrected_both_eyes'],'');
            const unCorrectedLeftEye  = isSet(formData['uncorrected_left_eye'],'');

            const correctedRightEye  = isSet(formData['corrected_right_eye'],'');
            const correctedBothEyes  = isSet(formData['corrected_both_eyes'],'');
            const correctedLeftEye  = isSet(formData['corrected_left_eye'],'');

          
   
        const horizontalRightEye = isSet(formData['horizontal_field_of_vision_right_eye'],'');
        const horizontalLeftEye = isSet(formData['horizontal_field_of_vision_left_eye'],'');

        
            // Check for any empty fields
            if (
                unCorrectedRightEye === '' ||
                unCorrectedBothEyes === '' ||
                unCorrectedLeftEye === '' ||
                correctedRightEye === '' ||
                correctedBothEyes === '' ||
                correctedLeftEye === '' ||
                horizontalRightEye === '' ||
                horizontalLeftEye === ''
            ) {
                status = 'Pending';
            } 
            // Check for failed conditions
            else if (
                unCorrectedRightEye > 40 ||
                unCorrectedBothEyes > 40 ||
                unCorrectedLeftEye > 40 ||
                correctedRightEye > 40 ||
                correctedBothEyes > 40 ||
                correctedLeftEye > 40 ||
                horizontalRightEye < 70 ||
                horizontalLeftEye < 70
            ) {
                status = 'Failed';
            } 
            // Otherwise, set status to Passed
            else {
                status = 'Passed';
            }
  
    return (key==='status')?{}:{'status':status,'suggested_status':status};
}