import { useEffect, useState } from "react";
import FormInputs from "../../components/UI/FormInputs";
import ClinicService from "../../services/ClinicService";
import Buttons from "../../components/UI/Buttons";
import { getLookupByKey, isSet } from "../../utils/commonUtils";
import ClinicDepartmentService from "../../services/ClinicDepartmentService";
import { VISIT_CATEGORY, VISIT_STATUS } from "../../utils/constant";
import DropDownService from "../../services/DropDownService";
import { acl_check } from "../../utils/aclUtils";
import withReduxState from "../../hoc/wReduxState";
import PERMISSIONS from "../../utils/AclPermissions";


const PhysicalAndDrugScreenFilter = (props) => {
  const { filterData, closeHandler, submitHandler, onChangeHandler,profile} = props;
  const [clinicOptions, setClinicOptions] = useState([]);
  const [companyOptions, setCompanyOption] = useState([]);
  const [physicalOptions, setPhysicalOption] = useState([]);
  const [visitCategoryOptions, setvisitCategoryOptions] = useState([]);
  const fetchClinicList = async (query = "") => {
    try {
      const clinicList = await DropDownService.getClinicList(null, query);
      let options = [{ label: "-Select Clinic-", value: "" }];
      clinicList.map((clinic, clinicIndex) => {
        options.push({
          label: clinic.name,
          value: clinic.clinic_id,
        });
      });
      setClinicOptions(options);
    } catch (e) { }
  };
  const fetchLookup = async () => {
    const option = await getLookupByKey(VISIT_CATEGORY);
    setvisitCategoryOptions(option);
  }

  const fetchCompaniesList = async () => {
    const response = await DropDownService.getCompanies(null);
    let companiesOptions = [{ label: "-Select Company-", value: "" }];
    response.forEach((company) => {
      companiesOptions.push({
        label: company.name,
        value: company.company_uuid,
      });
    });
    setCompanyOption(companiesOptions);
  };

  const overallStatusOptions = [
    { label: "-Select Physical Status-", value: "" },
    { label: "PENDING", value: "Pending" },
    { label: "FAILED", value: "Failed" },
    { label: "PASSED", value: "Passed" },
    { label: "NOT STARTED", value: "NOT STARTED" },
    { label: "NOT ORDERED", value: "NOT ORDERED" },
  ];
  const fetchPhysicalOptions = async () => {
    let activitiesResponse = await DropDownService.getActivityDropdownList(null);
    // console.log('activitiesResponse',activitiesResponse);
    let options = [{ label: '-Select Activity-', value: '' }];
    activitiesResponse.forEach(val => {
      options.push({ label: val.activity_name, value: val.actitivity_id });
    });
    setPhysicalOption(options);
  }

  useEffect(() => {
    fetchCompaniesList();
    fetchClinicList();
    fetchPhysicalOptions();
    fetchLookup();
  }, []);
  const inputFields = [
    {
      type: "TextInput",
      placeholder: "First Name",
      className: "form-control form-control-solid",
      name: "first_name",
      value: filterData.first_name,
    },
    {
      type: "TextInput",
      placeholder: "Last Name",
      className: "form-control form-control-solid",
      name: "last_name",
      value: filterData.last_name,
    },
    {
      type: "TextInput",
      placeholder: "System ID",
      className: "form-control form-control-solid",
      name: "system_id",
      value: filterData.system_id,
    },
    {
      type: "SelectList",
      options: companyOptions,
      placeholder: "company_id",
      className: "form-control form-control-solid",
      name: "company_id",
      value: filterData.company_id,
      show:acl_check(profile.permissions,PERMISSIONS.COMPANY.LIST),
    },
    // {
    //   type: "SelectList",
    //   options: visitCategoryOptions,
    //   placeholder: "",
    //   className: "form-control form-control-solid",
    //   name: "visit_category",
    //   value: filterData.visit_category,
    // },
    {
      type: "SelectList",
      options: overallStatusOptions,
      placeholder: "physical_status",
      className: "form-control form-control-solid",
      name: "physical_status",
      value: filterData.physical_status,
    },
    {
      type: "SelectList",
      options: physicalOptions,
      placeholder: "activity_id",
      className: "form-control form-control-solid",
      name: "activity_id",
      value: filterData.activity_id,
    },
    {
      type: "Date",
      placeholder: "Visit From",
      className: "form-control form-control-solid",
      name: "visit_from",
      value: filterData.visit_from,
    },
    {
      type: "Date",
      placeholder: "Visit To",
      className: "form-control form-control-solid",
      name: "visit_to",
      value: filterData.visit_to,
    },
    {
      type: "SelectList",
      options: clinicOptions,
      className: "form-control form-control-solid",
      placeholder: "Clinic",
      name: "clinic_id",
      value: filterData.clinic_id,
      show:acl_check(profile.permissions,PERMISSIONS.CLINIC.LIST),
    },
  ];
  const onChange = (fieldName, val) => {
    // if (fieldName == 'clinic_id') {
    //   fetchClinicLocationList(val);
    // }
    onChangeHandler(fieldName, val);

  }

  return (
    <>
      <div className="sidebar_Filter toggled" id="sidebar_FilterOpen">
        <div className="sh_filterBox">
          <div className="sh_filterHeader px-2 py-1">
            <div className="card-title mb-0">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0 text-md">Search by</h6>
                <Buttons
                  //iconType="Cancel"
                  className="btn-close text-sm"
                  acl={"any"}
                  clickHandler={() => closeHandler(false)}
                />
              </div>
            </div>
          </div>
          <div className="sh_filterBody">
            <div className="row">
              {inputFields.map((field, index) =>isSet(field.show,true)==true&& (
                <div className="col-12" key={index}>
                  <div className="form-group mb-1">
                    <FormInputs
                      {...field}
                      changeHandler={(val, e) =>
                        onChange(field.name, val)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="sh_filterFooter">
            <Buttons
              clickHandler={closeHandler}
              className="btn btn-outline-secondary btn-sm text-sm"
              acl={"any"}
              label={"Clear"}
            />
            <Buttons
              clickHandler={submitHandler}
              className="btn btn-primary btn-sm text-sm"
              acl={"any"}
              label={"Apply"}
            />


          </div>
        </div>
      </div>
    </>
  );
};
export default  withReduxState(PhysicalAndDrugScreenFilter);
