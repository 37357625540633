import React from "react";
import cc from "./classnames";
import { formatPhoneNumber, validateEmail } from "../../../../utils/commonUtils";

export default function Tag({ text,inputType, remove, disabled, className }) {
  const handleOnRemove = (e) => {
    e.stopPropagation();
    remove(text);
  };
  const validate = (val,type)=>{
    if(type==='Email'){
      return validateEmail(val);
    }else if(type==='Phone'){
        const phoneNumberVal = val.replace(/\D/g, '');
        return (phoneNumberVal.length===10)?true:false;
    }else{
      return true;
    }
    console.log('type',val,type);
    
  }
  const displayTag = (val,type)=>{
   if(type==='Phone'){
    return formatPhoneNumber(val);
   }
   return val;
  }

  return (
    <span className={cc("rti--tag", className)}>
      <span className={'forTable_textEllipsis'} style={{color:(validate(text,inputType)===true)?'inherit':'red', maxWidth:'192px'}}>
        {displayTag(text,inputType)}
        </span>
      {!disabled && (
        <button
          type="button"
          onClick={handleOnRemove}
          aria-label={`remove ${text}`}
        >
          &#10005;
        </button>
      )}
    </span>
  );
}
