import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import NumberInput from "./NumberInput";
import Password from "./Password";
import PhoneNumber from "./PhoneNumber";
import SelectList from "./SelectList";
import TextArea  from "./TextArea";
import TextInput from "./TextInput";
import Age from "./Age";
import Address from "./AddressInput";
import FileUpload from "./FileUpload";
import MultiSelectDropDown from "./MultiSelectDropDown";
import Blank from "./Blank";
import NonEditInput from "./NonEditInput";
import SelectListWithSearch from "./SelectListWithSearch";
import TimePicker from "./TimePicker";
import RadioGroup from "./RadioGroup";
import RadioButton from "./RadioButton";
import DecimalInput from "./Decimal";
import YesOrNoFields from "./YesOrNoFields";
import Tags from "./Tags";
import RichTextEditor from "./RichtextEditor/RichTextEditor";
import Signature from './Signature';
import ZipCodeInput from "./ZipCodeInput";
import ImageUpload from "./ImageUpload";
import { useEffect } from "react";
import FileType from "./FileType";

 const FormInputs = (props) => {
  const {type} = props;
 switch (type) {
    case "TextInput":
      return <TextInput {...props} />;
    case "Decimal":
      return <DecimalInput {...props} />
    case "Checkbox":
        return <Checkbox {...props} />;
    case "Password":
      return <Password {...props} />;
    case "SelectList":
      return <SelectList {...props} />;
    case "MultiSelectList":
      return <SelectList {...props} />;
    case "Date":
      return <DatePicker {...props} />;
    case "Phone":
      return <PhoneNumber {...props} />;
    case 'TextArea':
        return <TextArea {...props}/> ;
    case 'Number':
        return <NumberInput {...props}/> ;
    case 'MultiSelectDropDown':
      return <MultiSelectDropDown {...props} />
    case 'Tag':
        return <Tags {...props}/> ;
    case 'Age':
        return <Age {...props}/> ;
    case 'Address':
        return <Address {...props}/> ;
    case 'FileUpload':
        return <FileUpload {...props} />;
    case 'SelectSearchList':
        return <SelectListWithSearch {...props} />;
    case 'Blank':
        return <Blank {...props}/>
    case 'NonEdit':
        return <NonEditInput {...props}/>;
    case 'Time':
        return <TimePicker {...props}  />
    case 'Radio':
        return <RadioButton {...props} />
    case 'RadioGroup':
        return <RadioGroup {...props} />
    case 'YesOrNo':
          return <YesOrNoFields {...props} />
    case 'Editor':
      return <RichTextEditor {...props}/>;
    case 'Signature':
      return <Signature {...props}/>
    case 'ZipCode':
      return <ZipCodeInput  {...props}/>
    case 'ImageUpload':
      return <ImageUpload  {...props}/>
   case 'FileType':
     return <FileType  {...props} />
    default:
      return <TextInput {...props} />;
  }
};

export default FormInputs;