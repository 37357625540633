import React, { useState, useEffect, useCallback } from 'react';
import {  WEEKDAYS_SHORT } from '../../constants';
import {  everyNminutes } from '../../calendarFunctions';
import  CalendarBodyHoc  from '../../calendarHoc';
import WeekViewHeader from './WeekViewHeader';
import WeekViewBody from './WeekViewBody';

const CalendarWeekView = (props) => {
  const { currentDay, calendarOptions, todayDate } = props;
  const [currentDate, setCurrentDate] = useState('');
  const [timeInterval, setTimeInterval] = useState([]);
  const [currentWeek, setCurrentWeek] = useState([]);
  const { showWeekends } = calendarOptions;

  const calculateWeek = useCallback(() => {
    const firstDayOfWeek = new Date(currentDay);
    firstDayOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay());

    const lastDayOfWeek = new Date(currentDay);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() - lastDayOfWeek.getDay() + 6);

    const weeks = [];
    const currDate = new Date(firstDayOfWeek);

    while (currDate <= lastDayOfWeek) {
      if (!showWeekends && (currDate.getDay() === 0 || currDate.getDay() === 6)) {
        currDate.setDate(currDate.getDate() + 1);
        continue;
      }

      let c_date = currDate.getDate();
      let c_month = currDate.getMonth() + 1;
      let c_year = currDate.getFullYear();
      if (c_date < 10) c_date = '0' + c_date;
      if (c_month < 10) c_month = '0' + c_month;
      const full_date = `${c_month}/${c_date}/${c_year}`;

      weeks.push({
        date: new Date(currDate),
        month: currDate.getMonth() + 1,
        day: currDate.getDay(),
        number: currDate.getDate(),
        displayName: `${WEEKDAYS_SHORT[currDate.getDay()]} ${(currDate.getMonth() + 1)}/${currDate.getDate()}`,
        displayWeekday: WEEKDAYS_SHORT[currDate.getDay()],
        displayDayAndMonth: currDate.getDate(),
        c_date: full_date,
        year: currDate.getFullYear(),
      });
      currDate.setDate(currDate.getDate() + 1);
    }
    setCurrentWeek(weeks);
    setTimeInterval(everyNminutes(30,0));
  }, [currentDay, showWeekends]);

  useEffect(() => {
    calculateWeek();
  }, [calculateWeek]);

  useEffect(() => {
    const c_date = currentDay.getDate();
    const c_month = currentDay.getMonth() + 1;
    const c_year = currentDay.getFullYear();
    setCurrentDate(`${c_month < 10 ? '0' + c_month : c_month}/${c_date < 10 ? '0' + c_date : c_date}/${c_year}`);
  }, [currentDay]);

  return (
    <CalendarBodyHoc {...props.calendarHocProps}>
      <div className="fc-view fc-timeGridWeek-view fc-timeGrid-view">
        <table>
          <WeekViewHeader currentWeek={currentWeek} todayDate={todayDate} calendarRecords={props.calendarRecords} />
          <WeekViewBody
            {...props}
            currentDate={currentDate}
            timeInterval={timeInterval}
            currentWeek={currentWeek}
          />
        </table>
      </div>
    </CalendarBodyHoc>
  );
};

export default React.memo(CalendarWeekView);
