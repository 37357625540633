
import { isSet } from "../../../../../../utils/commonUtils";
import { OnTabKeyPress } from "../../../../../../utils/cssUtils";

export const ScreeningBloodPressure = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[
               
            {
                label:'Height',
                placeholder:'Feet',
                col:'col-4',
                type:'Number',
                name:'height_feet',
                id:'height_feet',
                
            },
            {
                label:'',
                isLabel:false,
                placeholder:'Inches',
                col:'col-2',
                type:'Decimal',
                name:'height_inches',
                id:'height_inches',
                
            },
            {
                label:'Weight (In Pounds)',
                placeholder:'Pounds',
                col:'col-6',
                type:'Number',
                name:'weight_pounds',
                id:'weight_pounds',
                
            },
            {
                label:'Pulse Rate',
                type:'Number',
                rangeMin:90,
                rangeMax:150,
                name:'pulse_rate',
                id:'pulse_rate',
        },
        {
            label:'Pulse Rhythm Regular',
            type:'RadioGroup',
            name:'pulse_rhythm_regular',
            id:'pulse_rhythm_regular',
            options:[{label:'Yes',value:1},{label:'No',value:0}],
        },
        ]
        }, { 
            label:' Blood Pressure Reading (Systolic / Diastolic)',
            fields:[
                {
                    label:'Systolic',
                    col:'col-2',
                    type:'Label',
                },
                {
                label:' Sitting',
                col:'col-4',
                type:'Number',
                rangeMax:140,
                name:'sitting_systolic',
                id:'sitting_systolic',
                onKeyPress:(event)=>OnTabKeyPress(event,"sitting_diastolic",null),
            },
            {
                label:'Second reading (optional)',
                col:'col-6',
                type:'Number',
                rangeMax:140,
                name:'second_reading_systolic',
                id:'second_reading_systolic',
            },
            {
                label:'Diastolic',
                col:'col-2',
                type:'Label',
            },
            {
                label:' Sitting',
                col:'col-4',
                type:'Number',
                rangeMax:90,
                name:'sitting_diastolic',
                id:'sitting_diastolic',
                onKeyPress:(event)=>OnTabKeyPress(event,"notes","sitting_systolic"),
            },
            {
                label:'Second reading (optional)',
                col:'col-6',
                type:'Number',
                rangeMax:90,
                name:'second_reading_diastolic',
                id:'second_reading_diastolic',
            },
            // {
            //     label:'Blood Pressure Ranges',
            //     col:'col-12',
            //     type:'UnorderedList',
            //     notes:[
            //         <span><b>Normal</b>: Systolic less than 120 mmHg and diastolic less than 80 mmHg </span>,
            //         <span><b>Elevated</b>: Systolic 120-129 mmHg and diastolic less than 80 mmHg</span>,
            //         <span><b>Stage 1 hypertension</b>    : Systolic 130-139 mmHg or diastolic 80-89 mmHg</span>,
            //         <span><b>Stage 2 hypertension</b>    : Systolic 140 mmHg or higher or diastolic 90 mmHg or higher</span>,
            //         <span><b>Hypertensive</b>     : Systolic higher than 180 mmHg and/or diastolic higher than 120 mmHg</span>],
            // },
            
        ]
        },
   
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    type:'TextArea',
                    id:'notes',
                    name:'notes',
                    onKeyPress:(event)=>OnTabKeyPress(event,"","sitting_diastolic"),
                },
                {
                    label:'Suggested Result',
                    type:'TextInput',
                    disabled:true,
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'suggested_status',
                },
                {
                    type:'Blank',
                    name:'blank'
                },
                {
                    label:'Final Determination',
                    type:'SelectList',
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'status',
                },
            
           
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
   const pulse = isSet(formData.pulse_rate,'');
   const systolic = isSet(formData.sitting_systolic,'');
   const systolic2 = isSet(formData.second_reading_systolic,'');
   const diastolic = isSet(formData.sitting_diastolic,'');
   const diastolic2 = isSet(formData.second_reading_diastolic,'');
   let status = evaluateStatus(pulse,systolic,diastolic);
        
    return (key==='status')?{}:{'status':status,'suggested_status':status};
}


const evaluateStatus = (pulse, systolic, diastolic)=> {
    // Check if any of the parameters are empty
    if (pulse === '' && systolic === '' && diastolic === '') {
        return 'Not Started';
    }
    if (pulse === '' || systolic === '' || diastolic === '') {
       return 'Pending';
    }
    
  
    // Check if pulse is out of range
    if (pulse < 90 || pulse > 150) {
      return 'Failed';
    }
  
    // Check if systolic is out of range
    if (systolic < 0 || systolic > 140) {
      return 'Failed';
    }
  
    // Check if diastolic is out of range
    if (diastolic < 0 || diastolic > 90) {
      return 'Failed';
    }
  
    // If all parameters are within range, return 'passed'
    return 'Passed';
  }
  