import React from "react";
import CheckboxInput from "../../FormInputs/Checkbox";

const Checkbox = (props)=>{

    const changeHandler=(val,event)=>{
        props.changeHandler(val,event);
    }
    return(
        <td>
            <div style={{display:'flex',alignItems:'center'}}>
                <div className="form-check" style={{display:'inline-block'}}>
                    <CheckboxInput 
                    changeHandler={changeHandler}
                    id={props.rowId}
                    value={props.value}
                    />
                </div>
            </div>
        </td>
    )
}
Checkbox.defaultProps ={
    changeHandler:()=>{},
    isSortable:false
}
export default Checkbox;