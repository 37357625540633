
import {  isSet } from "../../../../../../utils/commonUtils";

export const ScreeningUrinalysis = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            note:'Urinalysis is required.  Numerical readings must be recorded.',
            fields:[
                {
                    label:'Protein, blood, or sugar in the urine may be an indication for further testing to rule out any underlying medical problem.',
                    type:'Label',
                    col:'col-12'                
                },
                {
                    label:'Sp. Gr',
                    type:'Decimal',
                    name:'sp_gr',
                    rangeMax:1.030,
                    rangeMin:1.002,
                    id:'sp_gr',
                },
                {
                    label:'Protein',
                    type:'Number',
                    rangeMax:0,
                    name:'protein',
                    id:'protein',
                },
                {
                    label:'Blood',
                    type:'Number',
                    rangeMax:0,
                    name:'blood',
                    id:'blood',
                },
                {
                    label:'Sugar',
                    type:'Number',
                    rangeMax:0,
                    name:'sugar',
                    id:'sugar',
                },
        ]
        },
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
            {
                label:'Suggested Result',
                type:'TextInput',
                disabled:true,
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'suggested_status',
            },
            {
                type:'Blank',
                name:'blank'
            },
            {
                label:'Final Determination Result',
                type:'SelectList',
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'status',
            },
            
           
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
   const spgr    = isSet(formData.sp_gr,'');
   const protein = isSet(formData.protein,'');
   const blood   = isSet(formData.blood,'');
   const sugar   = isSet(formData.sugar,'');

   let status = 'Not Started';

   const sgNormalRange = { min: 1.002, max: 1.030 };
   const sgWithinNormalRange = spgr >= sgNormalRange.min && spgr <= sgNormalRange.max;

   // Check Protein, Blood, and Sugar levels
   const abnormalLevels = protein > 0 || blood > 0 || sugar > 0;

   // Determine status
   if (sgWithinNormalRange && !abnormalLevels) {
        status = "Passed";
   } else if (!sgWithinNormalRange || abnormalLevels) {
        status = "Failed";
   } else {
        status = "Pending";
   }

   if(spgr ==='' && protein === '' && blood === '' && sugar ==='' ){
    status='Not Started';
   }

//    if(xray==="Abnormal"){
//     status = 'Failed';
//    }else if(isSet(xray!=='')){
//         status='Passed';
//    }
    return (key==='status')?{}:{'status':status,'suggested_status':status};
}