import "./style.css";

import React, { useState } from "react";
import { useDidUpdateEffect } from "./use-did-update-effect";

import cc from "./classnames";
import Tag from "./Tag";
import FormInputs from "..";
import { isSet } from "../../../../utils/commonUtils";

const defaultSeparators = ["Enter", ";", ","];
export const TagsInput = ({
  name,
  placeHolder,
  value,
  onChange,
  onBlur,
  separators,
  disableBackspaceRemove,
  onExisting,
  onRemoved,
  disabled,
  isEditOnRemove,
  beforeAddValidate,
  onKeyUp,
  classNames,
  isNumber,
  tagInputType,
  maxSize,
}) => {
  const [tags, setTags] = useState(value || []);
  const [inputValue, setInputValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  useDidUpdateEffect(() => {
    onChange && onChange(tags);
  }, [tags]);

  useDidUpdateEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      console.log(tags);
      setTags(value);
    }
  }, [value]);

  const handleOnKeyUp = (e) => {
    setValidationMessage('');
    e.stopPropagation();

    if (inputValue === '') {
      setShowDropdown(false);
      return false;
    }
    setShowDropdown(true);

    let text = inputValue;
    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      e.key === "Backspace"
    ) {
      e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : "";
      setTags([...tags.slice(0, -1)]);
    }

    if (text && (separators || defaultSeparators).includes(e.key)) {
      e.preventDefault();
      addTag(text);
    }
  };

  const addTag = (text) => {
    // Validate if beforeAddValidate function exists and returns false for validation failure
    if (beforeAddValidate && !beforeAddValidate(text, tags)) return;
  
    // Split text by ';' and filter out empty strings and trim each email
    const emails = text.split(';').map(email => email.trim()).filter(email => email !== '');
  
    // Check for duplicates within the text itself
    const duplicateEmails = findDuplicates(emails);
    if (duplicateEmails.length > 0) {
      onExisting && onExisting(text);
      setValidationMessage('Duplicate entries within input: ');
      return;
    }
  
    // Check for duplicates with existing tags
    const duplicateWithTags = emails.filter(email => tags.includes(email));
    if (duplicateWithTags.length > 0) {
      setValidationMessage('Duplicate entries with existing tags: ');
      return;
    }


    if(tags.includes(text)){
      onExisting && onExisting(text);
      setValidationMessage('Duplicate entries with existing tags: ');
      return;
    }
    const newTags = emails.filter(email => !tags.includes(email));
  
    // Add new unique tags to the existing tags
    setTags([...tags, ...newTags]);
  
    // Reset input and hide dropdown
    setInputValue('');
    setShowDropdown(false);
  };

  const findDuplicates = (array) => {
    const seen = {};
    const duplicates = [];
  
    array.forEach(item => {
      if (seen[item]) {
        duplicates.push(item);
      } else {
        seen[item] = true;
      }
    });
  
    return duplicates;
  }; 

  const handleAddTag = () => {
    if (inputValue) {
      addTag(inputValue);
    }
  };

  const onTagRemove = (text) => {
    setTags(tags.filter((tag) => tag !== text));
    onRemoved && onRemoved(text);
  };

  const handleOnInput = (val, { e }) => {
    setInputValue(val);
    setShowDropdown(true);
  };

  return (
    <div aria-labelledby={name} className="rti--container pr-0 pl-0">
      {tags.map((tag) => (
        <Tag
          key={tag}
          inputType={isSet(tagInputType, 'TextInput')}
          className={classNames?.tag}
          text={tag}
          remove={onTagRemove}
          disabled={disabled}
        />
      ))}
      <FormInputs
        key={0}
        type={isSet(tagInputType, 'TextInput')}
        className={cc("rti--input", classNames?.input)}
        name={name}
        placeholder={(tags.length > 0) ? 'Add More ' + placeHolder : 'Add '+placeHolder}
        onKeyDownHandler={handleOnKeyUp}
        style={{ width: '100%' }}
        // onBlur={onBlur}
        disabled={disabled}
        value={inputValue}
        onKeyPressHandler={handleOnKeyUp}
        changeHandler={handleOnInput}
      />
      {showDropdown  && isSet(inputValue,null)!==null &&  (
        <div className="rti--dropdown">
          <div className="rti--dropdown-item" onClick={handleAddTag}>
          {validationMessage !== "" ? validationMessage : "Add"} "{validationMessage !== "" ? <span className="text-danger m-1">
          {inputValue}
        </span> : inputValue}"
          </div>
        </div>
      )}
    </div>
  );
};
