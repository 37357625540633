import FormInputs from "../../../../../../components/UI/FormInputs";
import { isSet } from "../../../../../../utils/commonUtils";


const CheckboxGroup = ({isLabel,itemIndex,column,options,label,formData,...props}) => {
  
    return (
      <>
      <div className={`${column} border border-left-0 py-1`} key={itemIndex}>
        <div className="form-group row mb-0 align-items-center">
          {isSet(isLabel,true)===false?
          <></>
          :<label className="col-sm-6 f-400 text-sm text-truncate mb-0 opacity-75" title={label}>  {label}  </label>}
           <div className={(isSet(isLabel,true)===false)?"col-sm-12":"col-sm-6"}>
           {options.map((optionItem, itemIndex) => (
                <div className='form-check form-check-inline d-inline-flex' key={itemIndex}>
                    <FormInputs 
                        {...props} 
                        type='Checkbox' 
                        {...optionItem}
                        value={isSet(formData[optionItem.name],false)}
                        id={optionItem.name + '_' + itemIndex}
                        changeHandler={(val)=>props.changeHandler(optionItem.name,val)} />
                    
                    <label className="form-check-label font-weight-bold1 pl-1" htmlFor={optionItem.name + '_' + itemIndex}>{optionItem.label}</label>
                </div>
                ))}
           </div>
        </div>
     </div> 
        
      </>
    );
  };
  
  export default CheckboxGroup;