
import {isSet, optionsToKeyValuePairs } from "../../../../../../utils/commonUtils";

export const XrayPanel = (props)=>{

    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[{
                label:'Xray',
                col:'col-6',
                type:'SelectList',
                id:'xray',
                name:'xray',
                options:optionsToKeyValuePairs(['Abnormal','Normal','Incidental Finding - Referred to PCP','Abnormal - Referred to PCP']),
            },
            {
                label:'Suggested Result',
                type:'TextInput',
                disabled:true,
                options:[
                    {label:'Not Started',value:'Not Started'},
                    {label:'Pending',value:'Pending'},
                    {label:'Failed',value:'Failed'},
                    {label:'Passed',value:'Passed'}
                ],
                name:'suggested_status',
            },
            {
                label:'Notes',
                col:'col-6',
                type:'TextArea',
                name:'notes',
            },
           
          
            {
                label:'Final Determination',
                type:'SelectList',
                options:[
                    {label:'Not Started',value:'Not Started'},
                    {label:'Pending',value:'Pending'},
                    {label:'Failed',value:'Failed'},
                    {label:'Passed',value:'Passed'}
                ],
                name:'status',
            },
           
        ]
        },]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    const buttons = {}
    return {charts,defaultValue,buttons,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
   const xray = isSet(formData.xray,'');
   let status = 'Not Started';
   if(xray==="Abnormal"){
    status = 'Failed';
   }else if(isSet(xray!=='')){
        status='Passed';
   }
    return (key==='status')?{}:{'status':status,'suggested_status':status};
}