import http from './http';
// import { wOrgIdMiddleware } from '../utils/commonUtils';
import { UNPROCESSABLE_CONTENT } from '../utils/constant';

const PatientFormService = {
  sentPatientQuestionnaire:async (data)=>{
    try {
        const response = await http.POST(`patient-send-questionnaire`, data)
        if (response.code === 200 && response.data) {
        return response.data;
      }
      
      return null;
    } catch (error) {
      // console.error('Error fetching user:', error);
      throw error;
    }
  },
    sentPatientFormEmail:async (data)=>{
    try {
        const response = await http.POST(`patient-form`, data)
        if (response.code === 200 && response.data) {
        return response.data;
      }
      
      return null;
    } catch (error) {
      // console.error('Error fetching user:', error);
      throw error;
    }
  },
  //Save user
  getPatientFormsById: async (id)=>{
    try {
      const response = await http.GET(`patient-form/${id}`,)
      // console.log('response',response);
      
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  savePatientForm:async(data,headers={})=>{
    try {
      const response = await http.POST(`save-patient-form`,data,headers)      
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  patientFormLogin:async(data, headers = {})=>{
    try {
      const response = await http.POST('patient-form-login', data,headers);
      return response;
      
    } catch (error) {
      if (error.status === UNPROCESSABLE_CONTENT) {
        return error.data;
      }
      //   throw error;
    }
},
getAllPatinetForm: async () => {
  try {
    const response = await http.GET('get-all-patient-form');
    return response;

  } catch (error) {
    if (error.status === UNPROCESSABLE_CONTENT) {
      return error.data;
    }
    //   throw error;
  }
},
 getPatientFormStatus:async()=>{
  try {
    const response = await http.GET('get-patient-form-status');
    return response;

  } catch (error) {
    if (error.status === UNPROCESSABLE_CONTENT) {
      return error.data;
    }
    //   throw error;
  }
    
},
validatePatientForm:async(id=null)=>{
  try {
    const response = await http.GET(`validate-patient-form/${id}`);
    return response.data;

  } catch (error) {
    if (error.status === UNPROCESSABLE_CONTENT) {
      return error.data;
    }
    //   throw error;
  }
    
}
}

export default PatientFormService;