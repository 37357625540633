const login = {
    emailAddress: 'Dirección de correo electrónico',
    password: 'Contraseña',
    login: 'Login',
    SelfRegister: 'Acceso',
    ForgotPassword: 'Has olvidado tu contraseña',
    FirstTimeUser: 'Usuario primerizo',
    loginTerms: 'Al iniciar sesión confirmas que aceptas los Términos y Condiciones y la Política de Privacidad',
    invalidEmail: 'Correo electrónico no válido',
    invalidPassword: 'Contraseña no válida',
    resetPassword: 'Restablecer contraseña',
    returnToLogin: 'Volver al inicio de sesión',
    submit: 'Enviar',
    PasswordRecovery: 'Recuperación de Contraseña',
    enterEmailAddress: 'Ingrese su dirección de correo electrónico',
    ResetPassword: 'Restablecer la contraseña',
    EnterPassword: 'Introducir la contraseña',
    ReEnterPassword: 'Escriba la contraseña otra vez',
    resetPasswordDescription: 'Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.',
    
};

export default login;
