
import ProviderService from "../../../../../../services/ProviderService";
import { getCurrentDate, isSet, optionsToKeyValuePairs } from "../../../../../../utils/commonUtils";
import { OnTabKeyPress } from "../../../../../../utils/cssUtils";


export const ScreeningTbskinTest = (props) => {

    const patientHealthInformation = [
        { label: '1. Do you have a productive Cough?', name: 'productive_cough' },
        { label: '2. Have you had any unexplained weight loss?', name: 'unexplained_weight_loss' },
        { label: '3. Do you have Night Sweats?', name: 'night_sweats' },
        { label: '4. Do you have symptoms of exhaustion?', name: 'exhaustion' },
        { label: '5. Do you have any unexplained fever?', name: 'unexplained_fever' },
        { label: '6. Have you been exposed to anyone with TB in the past?', name: 'exposed_to_tb' },
        { label: '7. Have you lived in another country?', name: 'lived_in_other_country' },
        { label: '8. Have you ever received the BCG vaccine for tuberculosis? ', name: 'received_bcg' },
        { label: 'If received, where?', name: 'digestive_problems' },
        { label: 'If received, when?', name: 'diabetes_insulin' },
        { label: '9. Have you ever had a positive reaction (A lump 2-3 days after being placed) to a TB in the past?', name: 'positive_tb_reaction' },
        { label: 'If so, what was the size of the induration (lump) in Millimeters?', name: 'fainting_passing_out' },
        { label: '10. Did you have a chest X-ray after the positive test?', name: 'chest_xray_after_positive' },
        { label: '11. Were you evaluated in the chest clinic for a positive TB test?', name: 'chest_clinic_evaluation' },
        { label: 'If so, when?', name: 'stroke_paralysis_weakness' },
        { label: '12. Were you evaluated in a chest clinic for the positive TB test?', name: 'neck_back_problems' },
        { label: '12. Did you complete the course of medication?', name: 'completed_medication' },
    ];
    let setAllDefaultValues = ['productive_cough'];

    const charts = [{
        type: 'Grid',
        col: 'col-md-3',
        data: [{
            type: "PatientDetails",
            employeeDetails: props.employeeDetails,
            visitDetails: props.visitDetails
        }, {
            type: "CompanyDetails",
            companyDetails: props.companyDetails
        },
        ],
    },
    {
        type: 'Tab',
        col: 'col-md-9',
        data: [
            {
                id: 'provider',
                data: [
                    {
                        label: 'Activity Details - Screening - TB Skin Test',
                        fields: [
                            {
                                label: 'Location',
                                type: 'RadioGroup',
                                name: 'arm_location',
                                col: 'col-6',
                                id: 'arm_location',
                                options: [{ label: 'Right Arm', value: 'Right Arm' }, { label: 'Left Arm', value: 'Left Arm'}],
                            },
                            {
                                label: 'Return date for read',
                                type: 'Date',
                                name: 'return_date_for_read',
                                col: 'col-6',
                                id: 'return_date_for_read',
                            }
                            ,
                            {
                                label: 'Do you have any Allergies?',
                                type: 'RadioGroup',
                                name: 'has_allergies',
                                options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }],
                                col: 'col-6',
                                id: 'has_allergies',
                            }
                            ,
                            {
                                isLabel: false,
                                placeholder: 'if "Yes ,please describe"',
                                type: 'TextArea',
                                name: 'allergies',
                                col: 'col-6',
                                id: 'allergies',
                                rules:[{rule:[{field:'has_allergies',value:1,operator:'!=='}],action:'DISABLE'}],
                            },
                            {
                                label:'Suggested Result',
                                type:'SelectList',
                                disabled:true,
                                options:[
                                    {
                                        label:'Positive',
                                        value:'Positive',
                                    },
                                    {
                                        label:'Negative',
                                        value:'Negative',
                                    },
                                    {
                                        label:'Passed',
                                        value:'Passed',
                                    },
                                    {
                                        label:'Failed',
                                        value:'Failed'
                                    },
                                    {
                                        label:'Pending',
                                        value:'Pending'
                                    },
                                
                                    {
                                        label:'Not Ordered',
                                        value:'Not Ordered'
                                    },
                                    {
                                        label:'Not Started',
                                        value:'Not Started'
                                    },
                                ],
                                id:'suggested_status',
                                name:'suggested_status'
                            },
                            {
                                label:'Final Determination',
                                type:'SelectList',
                                options:[
                                    {
                                        label:'Positive',
                                        value:'Positive',
                                    },
                                    {
                                        label:'Negative',
                                        value:'Negative',
                                    },
                                    {
                                        label:'Passed',
                                        value:'Passed',
                                    },
                                    {
                                        label:'Failed',
                                        value:'Failed'
                                    },
                                    {
                                        label:'Pending',
                                        value:'Pending'
                                    },
                                
                                    {
                                        label:'Not Ordered',
                                        value:'Not Ordered'
                                    },
                                    {
                                        label:'Not Started',
                                        value:'Not Started'
                                    },
                                ],
                                id:'status',
                                name:'status'
                            },

                        ]
                    },
                    { 
                        label:' Blood Pressure Reading (Systolic / Diastolic)',
                        fields:[
                            {
                                label:'Systolic',
                                col:'col-2',
                                type:'Label',
                            },
                            {
                            label:' Sitting',
                            col:'col-4',
                            rangeMax:139,
                            type:'Number',
                            name:'sitting_systolic',
                            id:'sitting_systolic',
                            onKeyPress:(event)=>OnTabKeyPress(event,"sitting_diastolic"),
                        },
                        {
                            label:'Second reading (optional)',
                            col:'col-6',
                            rangeMax:139,
                            type:'Number',
                            name:'second_reading_systolic',
                            id:'second_reading_systolic',
                        },
                        {
                            label:'Diastolic',
                            col:'col-2',
                            type:'Label',
                        },
                        {
                            label:' Sitting',
                            col:'col-4',
                            type:'Number',
                            rangeMax:89,
                            name:'sitting_diastolic',
                            id:'sitting_diastolic',
                            onKeyPress:(event)=>OnTabKeyPress(event,"notes","sitting_systolic"),
                        },
                        {
                            label:'Second reading (optional)',
                            col:'col-6',
                            type:'Number',
                            rangeMax:89,
                            name:'second_reading_diastolic',
                            id:'second_reading_diastolic',
                        },
                        {
                            label:'Blood Pressure Status',
                            // options:isSet(props.lookupsOptions['chartResult'],[]), 
                            disabled:true,   
                            id:'blood_pressure_result',
                            name:'blood_pressure_result'
                        },
                        {
                            label:'Notes',
                            type:'TextArea',
                            id:'notes',
                            name:'notes',
                            onKeyPress:(event)=>OnTabKeyPress(event,"","sitting_diastolic"),
                        },
                       
                        
                    ]
                    },
                ]
            },
            {
                id: 'patient',
                data: [
                    {
                        label: 'Activity Details - Screening - TB Skin Test',
                        // note:'Check the body systems for abnormalities',
                        fields: [
                            {
                                type:'Label',
                                
                                label:<a onClick={()=>props.updateFormData(patientHealthInformation.reduce((acc, itm) => {
                                    acc[itm.name] = 'No';
                                    return acc;
                                }, {}))} className="badge bg-blue-soft text-light cursor-pointer custom-btn" style={{cursor:'pointer'}}>
                                        Set all to No
                                    </a>,
                                appendClass:'bg-light',
                                name:'set_all_to_no',
                                col:'col-12',
                            },
                            {
                                label: '1. Do you have a productive Cough?',
                                type: 'RadioGroup',
                                // className:'d flex',
                                name: 'productive_cough',
                                col: 'col-12',
                                id: 'productive_cough',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '2. Have you had any unexplained weight loss?',
                                type: 'RadioGroup',
                                name: 'unexplained_weight_loss',
                                col: 'col-12',
                                id: 'unexplained_weight_loss',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '3. Do you have Night Sweats?',
                                type: 'RadioGroup',
                                name: 'night_sweats',
                                col: 'col-12',
                                id: 'night_sweats',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '4. Do you have symptoms of exhaustion?',
                                type: 'RadioGroup',
                                name: 'exhaustion',
                                col: 'col-12',
                                id: 'exhaustion',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '5. Do you have any unexplained fever?',
                                type: 'RadioGroup',
                                name: 'unexplained_fever',
                                col: 'col-12',
                                id: 'unexplained_fever',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '6. Have you been exposed to anyone with TB in the past?',
                                type: 'RadioGroup',
                                name: 'exposed_to_tb',
                                col: 'col-12',
                                id: 'exposed_to_tb',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },                          {
                                label: '7. Have you lived in another country?',
                                type: 'RadioGroup',
                                name: 'lived_in_other_country',
                                col: 'col-12',
                                id: 'lived_in_other_country',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '8. Have you ever received the BCG vaccine for tuberculosis? ',
                                type: 'RadioGroup',
                                name: 'received_bcg',
                                col: 'col-12',
                                id: 'received_bcg',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: 'If received, where?',
                                type: 'TextArea',
                                name: 'bcg_location',
                                col: 'col-12',
                                id: 'bcg_location',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: 'If received, when?',
                                type: 'Date',
                                name: 'bcg_time',
                                col: 'col-12',
                                id: 'bcg_time',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },                          {
                                label: '9. Have you ever had a positive reaction (A lump 2-3 days after being placed) to a TB in the past?',
                                type: 'RadioGroup',
                                name: 'positive_tb_reaction',
                                col: 'col-12',
                                id: 'positive_tb_reaction',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: 'If so, what was the size of the induration (lump) in Millimeters?',
                                type: 'TextArea',
                                name: 'induration_size_mm',
                                col: 'col-12',
                                id: 'induration_size_mm',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '10. Did you have a chest X-ray after the positive test?',
                                type: 'RadioGroup',
                                name: 'chest_xray_after_positive',
                                col: 'col-12',
                                id: 'chest_xray_after_positive',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '11. Were you evaluated in the chest clinic for a positive TB test?',
                                type: 'RadioGroup',
                                name: 'chest_clinic_evaluation',
                                col: 'col-12',
                                id: 'chest_clinic_evaluation',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: 'If so, when?',
                                type: 'Date',
                                name: 'chest_clinic_evaluation_time',
                                col: 'col-12',
                                id: 'chest_clinic_evaluation_time',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: 'If so, were medications prescribed for you?',
                                type: 'TextArea',
                                name: 'chest_clinic_medication_prescribed',
                                col: 'col-12',
                                id: 'other_conditions',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            },
                            {
                                label: '12. Did you complete the course of medication?',
                                type: 'RadioGroup',
                                name: 'completed_medication',
                                col: 'col-12',
                                id: 'completed_medication',
                                options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                    , { label: 'No', value: 'No' }
                                ],
                            }
                        ]
                    },
                    {
                        label: 'EMPLOYEE SIGNATURE',
                        note: 'I certify that the above information is accurate and complete.',
                        col: 'col-12',
                        fields: [{
                            label: 'Employee Signature',
                            type: 'Signature',
                            col: 'col-6',
                            name: 'employee_signature',
                            id: 'employee_signature',
                        },
                        {
                            label: 'Date',
                            type: 'Date',
                            col: 'col-6',
                            id: 'signatured_date',
                            name: 'signatured_date',
                        }
                        ]
                    }]
            },
        ]
    },
    ];
    const headers = [{
        type: 'Tab',
        data: [{
            label: 'Provider', id: 'provider'
        },
        {
            label: 'Patient', id: 'patient'
        }
        ]
    }];
    const defaultValue = {
        status: 'Not Started',
        blood_pressure_result: 'Not Started',
        // return_date_for_read:getCurrentDate(),
        // reason:'Pre-employment'
    };
    const buttons = {
        // PDF: true
    }
    return { charts, defaultValue, buttons, headers, computationFunction: (formData, key) => computationRules(formData, key) };
}


const computationRules = (formData, key) => {
    const pulse = isSet(formData.pulse_rate, '');
    const systolic = isSet(formData.sitting_systolic, '');
    const diastolic = isSet(formData.sitting_diastolic, '');
    let manualStatusUpdated=formData.manualStatusUpdated ||false;
    let returnResult = {};

    let pulseRateResult = 'Not Started';
    if (pulse == '') {
        pulseRateResult = 'Not Started'
    } else if (pulse >= 60 && pulse <= 100) {
        pulseRateResult = 'Normal';
    } else if (pulse !== '') {
        pulseRateResult = 'Abnormal';
    }

    let bloodPressureResult = 'Not Started';
    if (systolic === '' && diastolic === '') {
        bloodPressureResult = 'Not Started'
    } else if (systolic === '' || diastolic === '') {
        bloodPressureResult = 'Pending';
    } else if ((systolic < 0 || systolic > 139) || (diastolic < 0 || diastolic > 89)) {
        bloodPressureResult = 'Abnormal';
    } else {
        bloodPressureResult = 'Normal';
    }
    let status=formData.status;
    let suggested_status='Pending';
    console.log('armLocation',formData.arm_location);
    if (key=='status') {
        manualStatusUpdated=true; 
    }
    if (key!='status'&&!manualStatusUpdated) {
        if (formData.arm_location) {
            suggested_status='Pending';
            status='Pending';
        }
    }
    returnResult = {
        ...returnResult,
        blood_pressure_result: bloodPressureResult,
        suggested_status:suggested_status,
        status:status,
        manualStatusUpdated:manualStatusUpdated,
    }
    return returnResult;
    // return (key==='status')?:{'status':status};
}