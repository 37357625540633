import OrganizationService from "../../services/OrganizationService";
import Clock from "./Clock";

const TimeZone = (props) => {
    let convertUserTime = new Date().toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    let userTimeClock = new Date(convertUserTime);
    let convertOrgTime = new Date().toLocaleString("en-US", { timeZone: OrganizationService.getOrgTimeZone() });
    let orgTimeClock = new Date(convertOrgTime);
    let displayUserTime = userTimeClock.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    let displayOrgTime = orgTimeClock.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    let displayUserDate = userTimeClock.toLocaleDateString('en-US');
    let displayOrgDate = orgTimeClock.toLocaleDateString('en-US');
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="p-2 px-3 label-align-center">
                <span className="d-flex justify-content-center font-weight-bold">System Time</span>
                <div className="d-flex justify-content-center">
                    <span className="d-flex justify-content-center">{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
                </div>
                <Clock hourFormat="standard" />
            </div>
            <div className="p-2 px-3 label-align-center">
                <span className="d-flex justify-content-center text-medium font-weight-bold">Organization Time</span>
                <div className="d-flex justify-content-center">
                    <span className="d-flex justify-content-center">{OrganizationService.getOrgTimeZone()}</span>
                </div>
                <Clock timeZone={OrganizationService.getOrgTimeZone()} />         
            </div>
        </div>
    );
}

export default TimeZone;
