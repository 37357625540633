import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VisitsService from "../../services/VisitsService";
import { isObject, isSet } from "../../utils/commonUtils";
import FileManagerService from "../../services/FileManagerService";
import FormInputs from "../../components/UI/FormInputs";
import PatientFormService from "../../services/PatientFormService";
import ValidationForm from "./ValidationForm";
import WithRoutify from "../../hoc/WithRoutify";

const PatientFormLogin = (props) => {
    const {Loader}=props;
    const [formData, setFormData] = useState({});
    const [validation, setValidation] = useState({});
    const query = new URLSearchParams(useLocation().search);
    const [step,setStep]=useState(1);
    const isPhoneValidation=query.get('phone')=='true'?true:false
    const patient_form_id=query.get('patient_form_id');
    const [isValidForm,setValidForm]=useState(true);
    const navigate = useNavigate();
    useEffect(()=>{
      ValidateEmailLink();
    },[]);
    const  ValidateEmailLink=async()=>{
      Loader(true);
      const response=await PatientFormService.validatePatientForm(patient_form_id);
      setValidForm(response.is_valid);
      Loader(false);
      console.log('response',response);
    }

    const changeHandler = (fieldName, value) => {
        setValidation({ ...validation, [fieldName]: '' })
        setFormData({ ...formData, [fieldName]: value });
    }
    const submitHandler=async()=>{
        const payload={
            isPhoneValidation:isPhoneValidation,
            step:step,
            patient_form_id:query.get('patient_form_id'),
            phone:formData.phone||'',
            email:formData.email||'',
            code :formData.code,
        }
        let updateValidation={...validation};
        Loader(true);
        const data=await PatientFormService.patientFormLogin(payload);
        Loader(false);
        if (data.status && data.status === "ERROR") {
            if (isObject(data.data)) {
              for (let key in data.data) {
                updateValidation = {
                  ...updateValidation,
                  [key]: data.data[key].join(","),
                };
              }
            } else {
              props.Toast.error(data.data);
            }
            if (Object.keys(updateValidation).length > 0) {
              setValidation(updateValidation);
            }
          }
          else if (step==2) {
            console.log("verification completed",data.data);
           
                navigate(`/patient_form?patient_form_id=${patient_form_id}`)
          }
          else{
            setStep(step+1);
          }
    }
    return (
        <>
        {
          isValidForm == false ?
            <ValidationForm
              setValidForm={setValidForm} 
            />
            :
            <></>
        }
        
            <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 min-vh-100 d-flex flex-column justify-content-center mx-auto">
                        <div className="card sh_loginCard border-0 rounded-lg mt-5">
                            <div className="card-header justify-content-center">
                                <div className="text-center">
                                    <img src="/assets/img/logo.png" className="sh_loginLogo" alt="Logo" />
                                </div>
                            </div>
                            <div className="card-body">
                                <h6 className="text-lg f-500">Verification</h6>
                                <form className="sh_loginCre" onSubmit={(e) => { e.preventDefault(); submitHandler(); }}> 
                                    {
                                        step===1?
                                        <>
                                        <FormInputs
                                        type={isPhoneValidation?'Phone':''}
                                        className="form-control form-control-fields"
                                        placeholder={isPhoneValidation?'Enter Phone Number':'Enter Email Address'}
                                        name={isPhoneValidation?'phone':'email'}
                                        changeHandler={(value, e) => { changeHandler(isPhoneValidation?'phone':'email', value) }}
                                        value={formData[isPhoneValidation?'phone':'email']}

                                    />
                                      {isSet(validation[query.get('patient')=='true'?'phone':'email']) !== "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation[isPhoneValidation?'phone':'email'], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                    <div className="d-flex align-items-start mt-3">
                                        <i className="fas fa-info-circle pt-2 fa-lg"></i>
                                        <p className="font-weight-normal text-gray-600 text-sm pl-2">
                                            {`Please enter your ${isPhoneValidation ? 'phone number' : 'email address'} to receive a verification code. This code will be used to verify your identity and secure your account.`}  
                                        </p>
                                    </div>
                                        </>
                                        :
                                        <>
                                        <FormInputs
                                        type=""
                                        className="form-control form-control-fields"
                                        placeholder="Enter Code"
                                        name="code"
                                        changeHandler={(value, e) => { changeHandler('code', value) }}
                                        value={formData['code']}

                                    />
                                        {isSet(validation['code'], "") !== "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation['code'], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                            <div className="d-flex align-items-start mt-3">
                                        <i className="fas fa-info-circle pt-2 fa-lg"></i>
                                        <p className="font-weight-normal text-gray-600 text-sm pl-2">
                                            {`Please check your ${isPhoneValidation ? 'phone number' : 'email address'} for the Verification Code and enter it above.`} 
                                        </p>
                                    </div>
                                    </>
                                    }
                                    <div className="d-flex align-items-center justify-content-center sh_loginButtons">
                                        <a class="btn btn-primary btn-user"  onClick={()=>submitHandler()}>Submit</a>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
        </>
    );
};

export default WithRoutify(PatientFormLogin);
