import React from "react";
import { formatPhoneNumberUS, isSet } from "../../../../../../utils/commonUtils";
import Icons from "../../../../../../components/UI/Icons";

const PatientDetails = (props) => {
    const {employeeDetails,visitDetails} = props;
    const details = [{
        label:'Name',
        value:employeeDetails.first_name+' '+employeeDetails.last_name,
    },{
        label:'DOB',
        value:employeeDetails.dob,
    },
    {
        label:'Company',
        value:isSet(employeeDetails.company) ? employeeDetails.company.name : visitDetails.company.name,
    },
    {
        label:"Driver's License",
        value:employeeDetails.driver_license,
    },
    // {
    //     label:'System ID',
    //     value:employeeDetails.system_id,
    // },
    {
        label:'Phone',
        value:formatPhoneNumberUS(employeeDetails['phone']),
    },{
        label:'Date & Time',
        col:'col-12',
        value:visitDetails.visit_date+' '+visitDetails.visit_time,
    }]
    return (
                    <div className="sh_cardBox h-auto sh_patientdetailwidget">
                        <div className="sh_cardHeader">
                            <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                            <h6 className="text-md font-weight-bold mb-0">
                                <span className="px-1">
                                <Icons type="patientdetails" />
                                </span>
                                Patient Details 
                            </h6>
                            </div>
                        </div>
                        <div className="sh_cardBody">
                            <div className="row">
                                {details.map((item,itemIndex)=>{
                                    const column = isSet(item.col,'col-12');
                                    return(
                                    <div className={column} key={itemIndex}>
                                        <div className="form-group">
                                        <div className="row" style={{borderBottom: '1px solid #f2f2f4'}}>
                                           <div className="col-6">
                                            <label className="f-400 text-sm text-truncate mb-0 opacity-70" >{item.label}</label>
                                            </div>
                                            <div className="col-6">
                                            <h6 className="font-weight-bold text-sm text-truncate" title={item.value}>{isSet(item.value,'-')}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
          
    );
}

export default PatientDetails;
