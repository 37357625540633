import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import VisitsService from "../../services/VisitsService";
import { formatDate } from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import { isSet } from "../../utils/commonUtils";
import Buttons from "../../components/UI/Buttons";
import { fetchCurrentDate } from "../../utils/DateUtils";

const DischargeAndWaiting = (props) => {
  const [waitingList, setWaitingList] = useState([]);
  const [readyForDischargeList, setReadyForDischargeList] = useState([]);
  const [dischargeList, setDischargeList] = useState([]);

  useEffect(()=>{
    fetchDischargeAndWaitingList();
  },[props.currentDay,props.calendarRecords]);
  // console.log('props',props);
  const fetchDischargeAndWaitingList = async ()=>{
    try{
      const data = await VisitsService.getWaitingAndDischargeHistory();
      // console.log('data',data);
      const { ready_for_discharge_visits, waiting_visits, discharged_visits  } = data;
      let waitingRoomsArr  = [];
      let dischargedRoomArr = [];
      let readyForDischargedArr = [];
      let currentDate = null;
      if (isSet(props.fromWhiteBoard,false)==true&&props.currentDay) {
        currentDate=formatDate(props.currentDay);
      }
      else{
        currentDate=formatDate(fetchCurrentDate());
      }    
      isSet(waiting_visits[currentDate],[]).forEach((item, index)=>{
        console.log('waiting_visits',waiting_visits);
        waitingRoomsArr.push({
          name: item.employee.first_name + ' ' + item.employee.last_name,
          time: item.visit_time,
          id: `waiting-${index}`,
          visitId:item.visit_id, // Ensure a unique id
        });
      }); 
      isSet(ready_for_discharge_visits[currentDate],[]).forEach((item, index)=>{
        readyForDischargedArr.push({
          name: item.employee.first_name + ' ' + item.employee.last_name,
          time: item.visit_time,
          id: `discharge-${index}`,
          visitId:item.visit_id, // Ensure a unique id
        });
      });

      isSet(discharged_visits[currentDate],[]).forEach((item, index)=>{
        dischargedRoomArr.push({
          name: item.employee.first_name + ' ' + item.employee.last_name,
          time: item.visit_time,
          id: `readyForDischarge-${index}`,
          visitId:item.visit_id, // Ensure a unique id
        });
      });

      setWaitingList(waitingRoomsArr);
      setDischargeList(dischargedRoomArr);
      setReadyForDischargeList(readyForDischargedArr);
    } catch(error){
      console.log('error',error);
    }
  }

  const updateVisitStatusForWidget = async (visitId,droppableId)=>{
    const {Toast} = props;
    const payload = {
      //droppableId==='readyForDischarge'
      visit_status:(droppableId==='readyForDischarge')?'Ready for Discharge':(droppableId==='waiting')?'Waiting Room':'Discharge'
    }
    // return false;
    try {
      const data = await VisitsService.updateVisitStatus(isSet(payload.organization, null),visitId, payload);
      if (data.status && data.status === "ERROR") {
        // if (isObject(data.data)) {
        //   for (let key in data.data) {
        //     updateValidation = {
        //       ...updateValidation,
        //       [key]: data.data[key].join(","),
        //     };
        //   }
        // } else {
          Toast.error(isSet(data.data,'Something went wrong..'));
        // }
        // if (Object.keys(updateValidation).length > 0) {
        //   setValidation(updateValidation);
        // }
      } else {
        fetchDischargeAndWaitingList();
        props.fetchDetails();
        Toast.success('Visits Updated Successfully');
      }
    } catch (e) {

    }
  }

  // const onDragEnd = (result) => {
  //   const { source, destination, draggableId } = result;
  //   if (!destination) {
  //     return;
  //   }
    
  //   if (source.droppableId === destination.droppableId) {
  //     // Reorder within the same list
  //     if (source.droppableId === "waiting") {
  //       const newWaitingList = Array.from(waitingList);
  //       newWaitingList.splice(source.index, 1);
  //       newWaitingList.splice(destination.index, 0, waitingList.find(item => item.id === draggableId));
  //       setWaitingList(newWaitingList);
  //     } else if (source.droppableId === "discharge") {
  //       const newDischargeList = Array.from(dischargeList);
  //       newDischargeList.splice(source.index, 1);
  //       newDischargeList.splice(destination.index, 0, dischargeList.find(item => item.id === draggableId));

  //       setDischargeList(newDischargeList);
  //     }
  //   } else {
  //     // Move from one list to another
  //     if (source.droppableId === "waiting" && destination.droppableId === "discharge") {
  //       const newWaitingList = Array.from(waitingList);
  //       newWaitingList.splice(source.index, 1);
  //       const movedPatient = waitingList.find(item => item.id === draggableId);
  //       const {visitId} = movedPatient;
  //       console.log('movedPatient',destination.droppableId ,visitId);
  //       updateVisitStatusForWidget(visitId,destination.droppableId);
  //       const newDischargeList = Array.from(dischargeList);
  //       newDischargeList.splice(destination.index, 0, movedPatient);
  //       setWaitingList(newWaitingList);
  //       setDischargeList(newDischargeList);
  //     } else if (source.droppableId === "discharge" && destination.droppableId === "waiting") {
  //       const newDischargeList = Array.from(dischargeList);
  //       newDischargeList.splice(source.index, 1);
  //       const movedPatient = dischargeList.find(item => item.id === draggableId);
  //       const {visitId} = movedPatient;
  //       updateVisitStatusForWidget(visitId,destination.droppableId);
  //       const newWaitingList = Array.from(waitingList);
  //       newWaitingList.splice(destination.index, 0, movedPatient);
  //       setDischargeList(newDischargeList);
  //       setWaitingList(newWaitingList);
  //     }
  //   }
  // };
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }
    
    if (source.droppableId === destination.droppableId) {
      // Reorder within the same list
      if (source.droppableId === "waiting") {
        const newWaitingList = Array.from(waitingList);
        newWaitingList.splice(source.index, 1);
        newWaitingList.splice(destination.index, 0, waitingList.find(item => item.id === draggableId));
        setWaitingList(newWaitingList);
      } else if (source.droppableId === "discharge") {
        const newDischargeList = Array.from(dischargeList);
        newDischargeList.splice(source.index, 1);
        newDischargeList.splice(destination.index, 0, dischargeList.find(item => item.id === draggableId));
        setDischargeList(newDischargeList);
      } else if (source.droppableId === "readyForDischarge") {
        const newReadyForDischargeList = Array.from(readyForDischargeList);
        newReadyForDischargeList.splice(source.index, 1);
        newReadyForDischargeList.splice(destination.index, 0, readyForDischargeList.find(item => item.id === draggableId));
        setReadyForDischargeList(newReadyForDischargeList);
      }
    } else {
      // Move from one list to another
      let movedPatient;
      if (source.droppableId === "waiting") {
        movedPatient = waitingList.find(item => item.id === draggableId);
        const newWaitingList = Array.from(waitingList);
        newWaitingList.splice(source.index, 1);
        setWaitingList(newWaitingList);
      } else if (source.droppableId === "discharge") {
        movedPatient = dischargeList.find(item => item.id === draggableId);
        const newDischargeList = Array.from(dischargeList);
        newDischargeList.splice(source.index, 1);
        setDischargeList(newDischargeList);
      } else if (source.droppableId === "readyForDischarge") {
        movedPatient = readyForDischargeList.find(item => item.id === draggableId);
        const newReadyForDischargeList = Array.from(readyForDischargeList);
        newReadyForDischargeList.splice(source.index, 1);
        setReadyForDischargeList(newReadyForDischargeList);
      }
      // console.log('movedPatient',movedPatient,readyForDischargeList,draggableId,source.droppableId);
      const { visitId } = movedPatient;
      updateVisitStatusForWidget(visitId, destination.droppableId);
  
      if (destination.droppableId === "waiting") {
        const newWaitingList = Array.from(waitingList);
        newWaitingList.splice(destination.index, 0, movedPatient);
        setWaitingList(newWaitingList);
      } else if (destination.droppableId === "discharge") {
        const newDischargeList = Array.from(dischargeList);
        newDischargeList.splice(destination.index, 0, movedPatient);
        setDischargeList(newDischargeList);
      } else if (destination.droppableId === "readyForDischarge") {
        const newReadyForDischargeList = Array.from(readyForDischargeList);
        newReadyForDischargeList.splice(destination.index, 0, movedPatient);
        setReadyForDischargeList(newReadyForDischargeList);
      }
    }
  };
  
  const gotToVisitDetails = (vId)=>{
    props.navigate('/visits/visit-details/' + vId+'/appointments');
  }

  return (
    
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="sh_cardBox h-auto sh_widgets sh_patientdetailwidget">
        <div className="sh_cardHeader">
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
          <h6 className="text-md font-weight-bold mb-0">
            <span className="px-2">
              <Buttons
              iconType='patientdetails'
              />
            </span>
            Waiting Room</h6>
            {waitingList.length>0 && <span className="badge badge-primary badge-count ml-2">{waitingList.length}</span>}
        </div>
        </div>
        <Droppable droppableId="waiting">
          {(provided) => (
            <div style={{'overflowX':'auto'}} className="sh_cardBody p-0 sh_widget_ht">
            <table className="table  table-hover nowrap my-0  ">
              
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {waitingList.length===0?<tr><td  colspan="2">No record found</td></tr>
                :waitingList.map((patient, index) => (
                  <Draggable key={patient.id} draggableId={patient.id} index={'waiting'+index}>
                    {(provided) => (
                      <tr
                        onClick={()=>gotToVisitDetails(patient.visitId)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td>{patient.name}</td>
                        <td>{patient.time}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
              {provided.placeholder}
            </table>
            </div>
          )}
        </Droppable>
      </div>

      <div className="sh_cardBox h-auto sh_widgets sh_visittdetailwidget ">
        <div className="sh_cardHeader">
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
            <h6 className="text-md font-weight-bold mb-0">
            <span className="px-2">
              <Buttons
              iconType='visitdetails'
              />
            </span>
              Ready for Discharge</h6>
            {readyForDischargeList.length>0 && <span className="badge badge-primary badge-count ml-2">{readyForDischargeList.length}</span>}
          </div>
        </div>
        <Droppable droppableId="readyForDischarge" droppableStatus="Ready for Discharge">
          {(provided) => (
             <div style={{'overflowX':'auto'}} className="sh_cardBody p-0 sh_widget_ht">
            <table className="table  table-hover nowrap my-0  ">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {readyForDischargeList.length===0?<tr><td  colspan="2">No record found</td></tr>
                :readyForDischargeList.map((patient, index) => (
                  <Draggable key={patient.id} draggableId={patient.id} index={'readyForDischarge'+index}>
                    {(provided) => (
                      <tr
                        onClick={()=>gotToVisitDetails(patient.visitId)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td>{patient.name}</td>
                        <td>{patient.time}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
              {provided.placeholder}
            </table>
            </div>
          )}
        </Droppable>
      </div>


      <div className="sh_cardBox h-auto sh_widgets sh_companydetailwidget ">
        <div className="sh_cardHeader">
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
            <h6 className="text-md font-weight-bold mb-0">
            <span className="px-2">
              <Buttons
              iconType='companydetails'
              />
            </span>
              Discharged</h6>
            {dischargeList.length>0 && <span className="badge badge-primary badge-count ml-2">{dischargeList.length}</span>}
          </div>
        </div>
        <Droppable droppableId="discharge"  droppableStatus="Discharged">
          {(provided) => (
             <div style={{'overflowX':'auto'}} className="sh_cardBody p-0 sh_widget_ht">
            <table className="table  table-hover nowrap my-0  ">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {dischargeList.length===0?<tr><td  colspan="2">No record found</td></tr>
                :dischargeList.map((patient, index) => (
                  <Draggable key={patient.id} draggableId={patient.id} index={'discharge'+index}>
                    {(provided) => (
                      <tr
                        onClick={()=>gotToVisitDetails(patient.visitId)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td>{patient.name}</td>
                        <td>{patient.time}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
              {provided.placeholder}
            </table>
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default WithRoutify(DischargeAndWaiting);
