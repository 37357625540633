import React from 'react';
import PropTypes from 'prop-types';
import { isSet } from '../../../utils/commonUtils';

const CheckboxInput = (props) => {
  const {
    className,
    value,
    style,
    name,
    changeHandler,
    id,
    readOnly,
    disabled
  } = props;

  const onChangeHandler = (e) => {
    
    changeHandler(Boolean(e.target.checked) ? 1 : 0, {e});
  };
  let isChecked = Boolean(value);
  // console.log('isChecked',isChecked,value);
  return (
    
   
        <input
          className={className}
          type="checkbox"
          checked={isChecked}
          style={style}
          name={name}
          id={id}
          readOnly={readOnly}
          disabled={readOnly}
          onChange={(e) => onChangeHandler(e)}
        />
   
  
  );
};

CheckboxInput.defaultProps = {
  className: 'form-check',
  checked: false,
  value: false,
  name:'default',
  style: {},
  id:'default',
  readOnly:false,
  disabled:false,
  changeHandler:()=>{}
};

CheckboxInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  style: PropTypes.object,
  // changeHandler: PropTypes.func.isRequired,
};

export default CheckboxInput;