import { OK, UNPROCESSABLE_CONTENT } from '../utils/constant';
import http from './http';


const InsuranceService = {

  saveInsurance: async (data) => {
    try {
      const response = await http.POST(`insurance-companies`, data);
      return response;
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  getInsurance: async (query = {}) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`insurance-companies?${queryString}`);
      if (response.code === OK) {
        return response;
      }
      else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  //get Insurance By Id
  getInsuranceById: async (id) => {
    try {
      const response = await http.GET(`insurance-companies/${id}`);
      if (response.code === OK) {
        return response;
      }
      else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  // update Insurance
  updateInsurance: async (id, data) => {
    try {
      const response = await http.PUT(`insurance-companies/${id}`, data);
      console.log('response', response);
      return response;
    } catch (error) {
      console.error('Error fetching Insurace:', error);
    }
  },
  // delete Insurance
  deleteInsurance: async (Id) => {
    try {
      const response = await http.DELETE(`insurance-companies/${Id}`);
      if (response.status && response.data) {
        const resData = response.data;
        if ((resData.code === 200 || resData.code === 201) && resData.data) {
          return resData.data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Employee:', error);
    }
  },
  getWorkCompInsurance: async (query = {}) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`workcomp-insurance?${queryString}`);
      if (response.code === OK) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  saveWorkCompInsurance: async (data) => {
    try {
      const response = await http.POST(`workcomp-insurance`, data);
      return response;
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  getWorkCompInsuranceById: async (id) => {
    try {
      const response = await http.GET(`workcomp-insurance/${id}`);
      if (response.code === OK) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  updateWorkCompInsurance: async (id, data) => {
    try {
      const response = await http.PUT(`workcomp-insurance/${id}`, data);
      console.log('response', response);
      return response;
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  deleteWorkCompInsurance: async (Id) => {
    try {
      const response = await http.DELETE(`workcomp-insurance/${Id}`);
      if (response.status && response.data) {
        const resData = response.data;
        if ((resData.code === 200 || resData.code === 201) && resData.data) {
          return resData.data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Employee:', error);
    }
  },

};
export default InsuranceService;