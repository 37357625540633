import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { isSet } from '../../../utils/commonUtils';

const TimePicker = ({ value='1:00:AM', changeHandler, className }) => {
  const [hour, setHour] = useState(value.substring(0, 2));
  const [minute, setMinute] = useState(value.substring(3, 5));
  const [period, setPeriod] = useState(value.substring(6, 8));

  const onChangeHandler = () => {
    const formattedTime = `${hour}:${isSet(minute,'00')} ${isSet(period,'AM')}`;
    changeHandler(formattedTime);
  };

  return (
    <div className={className}>
      <select className="" style={{borderRadius:'5px'}} value={hour} onChange={(e) => setHour(e.target.value)} onBlur={onChangeHandler}>
        {[...Array(12).keys()].map((hour) => (
          <option key={hour + 1} value={(hour + 1).toString().padStart(2, '0')}>
            {(hour + 1).toString().padStart(2, '0')}
          </option>
        ))}
      </select>
      :
      <select style={{borderRadius:'5px'}} value={minute} onChange={(e) => setMinute(e.target.value)} onBlur={onChangeHandler}>
        {[...Array(60).keys()].map((minute) => (
          <option key={minute} value={minute.toString().padStart(2, '0')}>
            {minute.toString().padStart(2, '0')}
          </option>
        ))}
      </select>
      <select style={{borderRadius:'5px'}} value={period} onChange={(e) => setPeriod(e.target.value)} onBlur={onChangeHandler}>
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );
};

TimePicker.defaultProps = {
  value: '12:00 AM',
  className: 'time-picker',
};

TimePicker.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
};

export default memo(TimePicker);
