import React, { memo, useEffect } from 'react';
const ReadOnlyField = (props) => {
    const {
        type,
        className,
        value,
        style,
        options,
    } = props;
    const displayValue = value == '' ? '--' : (options.find(option => option.value === value)?.label || '--');
    return (
        <>
            {
                type == 'SelectSearchList' || type == 'SelectList' ?
                    <input
                        {...props}
                        type="text"
                        readOnly
                        value={displayValue}
                        className={className}
                        style={style}
                    />
                    :
                    <input
                        {...props}
                        type="text"
                        readOnly
                        value={value}
                        className={className}
                        style={style}
                    />
            }

        </>

    )
}

export default memo(ReadOnlyField);
