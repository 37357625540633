import React, { useEffect, useState } from "react";
import { isSet } from "../../../utils/commonUtils";
import SwitchFields from "../../EmployeeVisitContainer/EmployeeVisitDetailView/RightContent/ActivityChart/ChartsFields";
import FormInputs from "../../../components/UI/FormInputs";
import Buttons from "../../../components/UI/Buttons";



const PatientQuestion = (props) => {    
    const {onChangeHandler,formData,bulkOnChangeHandler}=props;
    const [validations, setvalidations] = useState({});
    const isTextAreaEnabled={
        surgery_explanation: false,
        taking_medication_explanation: false,
        other_conditions_description: false,
        additional_comments_description: false
    }

    // const [formData, setFormData] = useState({});
    useEffect(() => {
    
        bulkOnChangeHandler({
            ...formData,
            isTextAreaEnabled: {
                ...isTextAreaEnabled
            },
        });

    }, []);
    const patientHealthInformation = [
        { label: '1. Head/brain injuries (e.g., concussion, seizures)', name: 'head_brain_injuries' },
        { label: '2. Seizures, epilepsy', name: 'seizures_epilepsy' },
        { label: '3. Eye problems (except glasses or contacts)', name: 'eye_problems' },
        { label: '4. Ear and/or hearing problems', name: 'ear_hearing_problems' },
        { label: '5. Heart disease, heart attack, bypass, or other', name: 'heart_disease' },
        { label: '6. Pacemaker,stents, implantable devices, or other heart procedures', name: 'pacemaker_stents_implantable_devices' },
        { label: '7. High blood pressure', name: 'high_blood_pressure' },
        { label: '8. High cholesterol', name: 'high_cholesterol' },
        { label: '9. Chronic (long-term) cough, shortness of breath, or other breathing problems', name: 'chronic_breathing_problems' },
        { label: '10. Lung disease (e.g., asthma)', name: 'lung_disease' },
        { label: '11. Kidney problems, kidney stones, or pain w/ urination', name: 'kidney_problems' },
        { label: '12. Stomach, liver, or digestive problems', name: 'digestive_problems' },
        { label: '13. Diabetes or blood sugar problems Insulin used', name: 'diabetes_insulin' },
        { label: '14. Anxiety, depression, other mental problems', name: 'mental_problems' },
        { label: '15. Fainting or passing out', name: 'fainting_passing_out' },
        { label: '16. Dizziness, headaches, numbness, tingling', name: 'dizziness_headaches' },
        { label: '17. Unexplained weight loss', name: 'unexplained_weight_loss' },
        { label: '18. Stroke, mini-stroke (TIA), paralysis, or weakness', name: 'stroke_paralysis_weakness' },
        { label: '19. Missing or limited use of arm, hand, finger, leg, foot, toe', name: 'missing_limited_use_of_limbs' },
        { label: '20. Neck or back problems', name: 'neck_back_problems' },
        { label: '21. Bone, muscle, joint, or nerve problems', name: 'bone_muscle_joint_nerve_problems' },
        { label: '22. Blood clots or bleeding problems', name: 'blood_clots_bleeding' },
        { label: '23. Cancer', name: 'cancer' },
        { label: '24. Chronic (long-term) infection or other', name: 'chronic_infection' },
        { label: '25. Sleep disorders, pauses in breathing while asleep, daytime sleepiness, loud snoring', name: 'sleep_disorders' },
        { label: '26. Have you ever had a sleep test (e.g., sleep apnea)?', name: 'had_sleep_test' },
        { label: '27. Have you ever spent a night in the hospital?', name: 'hospital_stay' },
        { label: '28. Have you ever had a broken bone?', name: 'had_broken_bone' },
        { label: '29. Have you ever used or do you now use tobacco?', name: 'tobacco_use' },
        { label: '30. Do you currently drink alcohol?', name: 'alcohol_use' },
        { label: '31. Use of an illegal substance within the past 2 years', name: 'illegal_substance_use' },
        { label: '32. Have you ever failed a drug test or been dependent?', name: 'drug_test_failure' }
    ];
    const setAllToNo = () => {
        const updatedFormData = { ...formData };

        // Loop through each question in the patient health information section
        patientHealthInformation.forEach(question => {
            updatedFormData[question.name] = 'No'; // Set the value to "No"
        });
        const fieldsToSetNo = ['surgery_history', 'taking_medications', 'other_conditions', 'additional_comments'];
        fieldsToSetNo.forEach(fieldName => {
            updatedFormData[fieldName] = 'No'; // Set the value to "No"
        });

        const fieldsToClear = ['surgery_explanation', 'taking_medication_explanation', 'other_conditions_description', 'additional_comments_description'];
        fieldsToClear.forEach(fieldName => {
            updatedFormData[fieldName] = ''; // Set the value to "No"
        });
        bulkOnChangeHandler({
            ...updatedFormData,
            isTextAreaEnabled: {
                ...isTextAreaEnabled,
                additional_comments_description: true,
                surgery_explanation: true,
                other_conditions_description: true,
                taking_medication_explanation: true,
            },
        })
    };
    const inputFields = [
        {
            label: 'Health Information',
            data: [
                {
                    type: 'button',
                    name: 'Set all to No',
                    col: 'col-form-label ml-1',
                },
                {
                    label: 'Have you ever had surgery ?',
                    type: 'RadioGroup',
                    name: 'surgery_history',
                    col: 'col-sm-6 f-400 text-sm  mb-0 opacity-75',
                    options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                        , { label: 'No', value: 'No' }
                        , { label: 'Not Sure', value: 'Not Sure', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }],
                    labelStyle: { color: '#231B68' },
                    id: 'surgery_history',
                },

                {
                    label: '',
                    type: 'TextArea',
                    col: 'col-md-6 col-12',
                    disabled: isTextAreaEnabled.surgery_explanation,
                    isLabel: false,
                    name: 'surgery_explanation',

                },

                {
                    label: "Are you currently taking medications (prescription, over-the-counter, herbal remedies, diet supplements)?",
                    type: 'RadioGroup',
                    col: 'col-sm-6 f-400 text-sm  mb-0 opacity-75',
                    labelWrap: false,
                    name: 'taking_medications',
                    options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                        , { label: 'No', value: 'No' }
                        , { label: 'Not Sure', value: 'Not Sure', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }],
                    labelStyle: { color: '#231B68' },
                    id: 'taking_medications',
                },

                {
                    label: '',
                    type: 'TextArea',
                    col: 'col-md-6 col-12',
                    disabled: isTextAreaEnabled.taking_medication_explanation,
                    name: 'taking_medication_explanation',

                },
                {
                    label: 'Do you have or have you ever had:',
                    type: 'Label'
                },
                {
                    col: 'col-12',
                    label: '',
                    // defaultBtnLabel:'Set all to No',
                    type: 'YesOrNoTable',
                    options: [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }, { label: 'Not Sure', value: 'Not Sure' }],
                    defaultValues: patientHealthInformation.reduce((acc, itm) => {
                        acc[itm.name] = 'No';
                        return acc;
                    }, {}),
                    failedValue: ['Yes', 'Not Sure'],
                    list: patientHealthInformation,

                },
                {
                    label: 'Other health condition(s) not described above ?',
                    type: 'RadioGroup',
                    name: 'other_conditions',
                    col: 'col-sm-6 f-400 text-sm  mb-0 opacity-75',
                    options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                        , { label: 'No', value: 'No' }
                        , { label: 'Not Sure', value: 'Not Sure', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }],
                    labelStyle: { color: '#231B68' },
                    id: 'other_conditions',
                },
                // {
                //     type:'Blank',
                //     col:'col-6',
                // },
                {
                    label: '',
                    type: 'TextArea',
                    col: 'col-md-6 col-12',
                    disabled: isTextAreaEnabled.other_conditions_description,
                    name: 'other_conditions_description',

                },

                {
                    label: "Did you answer 'yes' to any of questions 1-32? If so, please comment further on those health conditions below.",
                    type: 'RadioGroup',
                    col: 'col-sm-6 f-400 text-sm  mb-0 opacity-75',
                    name: 'additional_comments',
                    options: [{ label: 'Yes', value: 'Yes' }
                        , { label: 'No', value: 'No' }
                        , { label: 'Not Sure', value: 'Not Sure' }],
                    labelStyle: { color: '#231B68' },
                    id: 'additional_comments',
                },
                // {
                //     type:'Blank',
                //     col:'col-6',
                // },
                {
                    label: '',
                    type: 'TextArea',
                    col: 'col-md-6 col-12',
                    disabled: isTextAreaEnabled.additional_comments_description,
                    name: 'additional_comments_description',

                },
            ]
        }
        // {
        //     label:'More Information',
        //     data:[]
        // }
    ];
    return (
        <>
            <div className="sh_innerPortview" key={'sdfs'}>
                {inputFields.map((section, secIndex) => {
                    if (!section || !section.data) return null;
                    return (
                        <div className="sh_cardBox border-0" key={secIndex}>
                            <div className="sh_cardHeader border-0">                           
                                <h6 className="m-0 text-md font-weight-bold">
                                <Buttons
                                iconType="DashboardComp"
                                className="pr-2"
                                />
                                    {section.label}</h6>
                            </div>
                                <div className="sh_cardBody">
                                    <div className="row">
                                        {section.data.map((item, itemIndex) => {
                                            const value = isSet(formData[item.name], '');
                                            return (
                                                <div className="col-sm-12" key={itemIndex}>
                                                    {item.type === "Label" || item.type === "YesOrNoTable"
                                                        ?

                                                        <div className=""><SwitchFields
                                                            key={itemIndex}
                                                            col={"col-12"}
                                                            itemIndex={itemIndex}
                                                            formData={formData}
                                                            changeHandler={(name, val) => { onChangeHandler(name, val, {}) }}
                                                            // updateFormData={setAllToNo}
                                                            item={{ ...item }} />
                                                            {item.type === "YesOrNoTable" ? <br /> : <></>}
                                                        </div>
                                                        :
                                                        <div className={item.type === 'RadioGroup' ? 'row' : "form-group row"}>
                                                            <label className={item.col ? item.col : "col-sm-6 col-form-label opacity-75"} style={{ color: (item.type === 'RadioGroup' ? '#231B68' : '') }} >{item.label}</label>
                                                            <div className={item.options ? "col-sm-6" : (item.col ? item.col : "col-sm-6")} >
                                                                {item.type === 'button' ? <><a onClick={setAllToNo} className="col-12 badge bg-blue-soft text-light cursor-pointer custom-btn">
                                                                    {item.name}
                                                                </a><br /><br /></> : <FormInputs {...item} value={value}
                                                                    changeHandler={(val, e) => { onChangeHandler(item.name, val, e) }} />}

                                                                {isSet(validations[item.name], '') !== '' ? <span className="text-danger m-1">{isSet(validations[item.name], '')}</span> : ''}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                        </div>
                    )
                })}
            </div>
           
        </>

    )
}
export default PatientQuestion;