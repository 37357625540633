import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { preventNonNumericalInput } from '../../../utils/commonUtils';

const PhoneNumber = (props) => {
  const { numberFormat,maskChar, required,style ,id} = props;
  const handlePhoneNumberChange = (e) => {
    if (props.changeHandler) {
      let value = e.target.value;
      value = value.replace(/\D/g, ''); // Replace non-numeric characters
      props.changeHandler(value, { e });
    }
  };

  return (
    <InputMask
      mask={numberFormat}
      id={id}
      maskChar={maskChar}
      value={props.value}
      required={required}
      disabled={props.disabled}
      hidden={props.hidden}
      placeholder={props.placeholder}
      name={props.name || ''}
      className={props.className || ''}
      style={{...style}}
      onChange={handlePhoneNumberChange}
      onKeyDown={props.onKeyDownHandler}
      onKeyUp={(e) => { 
        preventNonNumericalInput(e); 
        if (props.onKeyPressHandler) props.onKeyPressHandler(e); 
      }}
    />
  );
};

PhoneNumber.defaultProps = {
  numberFormat: "(999) 999-9999",
  maskChar:'_',
  value: '',
  id:'',
  name: '',
  placeholder: '',
  onKeyDownHandler: () => {},
  onKeyPressHandler: () => {},
  className: 'form-control form-control-solid',
  required: false,
  disabled: false,
  hidden: false,
  style:{}
};

PhoneNumber.propTypes = {
  phoneNumberFormat: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  changeHandler: PropTypes.func,
  onKeyDownHandler: PropTypes.func,
  onKeyPressHandler: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default PhoneNumber;
