import React, { Component } from "react";
import WithRoutify from "../../../hoc/WithRoutify";
import WithRouter from "../../../hoc/WithRouter";
import { decryptString } from "../../../utils/commonUtils";
import TableGrid from "../../../components/UI/TableGrid";
import SectionLoader from "../../../components/UI/SectionLoader";
import EmployeeService from "../../../services/EmployeeService";
import moment from "moment";
import OrganizationService from "../../../services/OrganizationService";
import { isSet } from "../../../utils/commonUtils";


class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Add any default state properties here if needed
      isModelOpen:false,
      DocumentList:{ tableHeaders: [], tableRows: { data: [] } },
    };
  }
  fetchDocumentList = async () => {
    this.props.Loader(true);
    const { params } = this.props;
    const documents = await EmployeeService.getEmployeeVisitsHistoryList(params.patientId);
    let tableData=[];
      try{
        if(isSet(documents.data.data[0])){
          tableData = documents.data.data[0].visit_documents.map((item) => ({
            rowId: item.additional_document_id,
            data: { 
              name: item.snap_file_manager,
              updatedAt: moment.utc(item.updated_at).format('MMMM D, YYYY h:mm:ss A'),
              // action: [{
              //   className: 'btn btn-datatable btn-icon btn-transparent-dark',
              //   iconType: 'Remove',
              //   acl: 'employee-delete',
              //   title: 'Delete',
              //   isDelete: true,
              //   clickHandler: () => deleteDocument(item.id),
              // }]
            }
          }));
        
        }
      }catch(e){

      }finally{
        this.setState({  
          DocumentList: {
            tableHeaders: this.getTableHeaders(),
              tableRows: { data: tableData }
          },
        });
        this.props.Loader(false);
      }
     
      
      
    

    
    

  }

  getTableHeaders=()=> {
    const header = [
      { label: 'File Name', key: 'name', type: 'FileType' },
      { label: 'Updated Date', key: 'updatedAt', type: '' },
      // { label: 'Action', key: 'action', type: 'Actions' },
    ];
    return header;
  }

  componentDidMount =()=> {
    this.fetchDocumentList();
    // Add any logic you want to execute after the component is mounted
  }

  componentDidUpdate(prevProps) {
    // Add any logic you want to execute when props are updated
    // You can compare this.props and prevProps to determine changes
  }

  componentWillUnmount() {
    // Add any cleanup logic when the component is about to unmount
  }
  

  

  render() {
    
    const { DocumentList } = this.state;
    const patientDetails = decryptString(this.props.params.patientDetails);

    return (
      <>
        <div className="w-100 p-3">
            <div className="sh_cardBox h-auto shadow-none border">
            <div className="sh_cardHeader">
                <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                <h6 className="text-sm mb-0">Documents - {patientDetails.patientName}</h6>
                </div>
            </div>
            <div className="sh_cardBody p-0">
                <TableGrid {...DocumentList} />
                {/* <SectionLoader isLoading={DocumentSectionLoader} /> */}

            </div>
            </div>
        </div>
      </>
    );
  }

  
 
}

export default WithRouter(WithRoutify(Images));
