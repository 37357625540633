import { CREATED, OK } from '../utils/constant';
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';

const ClinicDepartmentService = {
  //Save Clinic Department
  saveClinicDepartment: wOrgIdMiddleware(async (url, data) => {
    try {
      const response = await http.POST(`${url}clinic-departments`, data)
      // if ((response.code === OK || response.code === CREATED) && response.data) {
      //   return response.data;
      // } else {
        return response;
      // }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      // throw error;
    }
  }, true),

  //get all clinic department by id
  getClinicDepartment: wOrgIdMiddleware(async (url, query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}clinic-departments?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  //get clinic department by id 
  getClinicDepartmentByDepartmentId: wOrgIdMiddleware(async (url, clinicId) => {
    try {
      const response = await http.GET(`${url}clinic-departments/${clinicId}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      // throw error;
    }
  }),
  // get clinic department by clinic id
  getClinicDepartmentByClinicId: wOrgIdMiddleware(async (url, clinicId) => {
    try {
      const response = await http.GET(`${url}clinics/${clinicId}/clinic-departments`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },true),
  //update clinic department by id
  updateClinicDepartment: wOrgIdMiddleware(async (url, id, data) => {
    try {
      const response = await http.PUT(`${url}clinic-departments/${id}`, data);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      // throw error;
    }
  }),
  //delete clinic department by id
  deleteClinicDepartment: wOrgIdMiddleware(async (url, clinicId) => {
    try {
      const response = await http.DELETE(`${url}clinic-departments/${clinicId}`);
      if (response.status && response.data) {
        if ((response.code === OK || response.code === CREATED) && response.data) {
          return response.data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),
};

export default ClinicDepartmentService;