import React from 'react';

const PrintIcon= ({size}) => {
  return (
 

 
<svg   xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 96 96" id="print">
<path stroke="#0000ff" stroke-width="5" d="M29.4815 68H12C9.79086 68 8 66.2091 8 64V35C8 32.7909 9.79086 31 12 31H84C86.2091 31 88 32.7909 88 35V64C88 66.2091 86.2091 68 84 68H66.5185M28 13C28 11.3431 29.3431 10 31 10H65C66.6569 10 68 11.3431 68 13V31H28V13z"></path>
<path stroke="#0000ff" stroke-width="5" d="M28.5 18H22C20.3431 18 19 19.3431 19 21V31H77V21C77 19.3431 75.6569 18 74 18H68"></path>
<path stroke="#0000ff" stroke-linecap="round" stroke-width="5" d="M21 53H75M58 43L75 43"></path><path fill="#12182B" d="M25 43C25 44.6569 23.6569 46 22 46C20.3431 46 19 44.6569 19 43C19 41.3432 20.3431 40 22 40C23.6569 40 25 41.3432 25 43Z"></path><path stroke="#0000ff" stroke-width="5" d="M69 55V83C69 84.6569 67.6569 86 66 86H30C28.3431 86 27 84.6569 27 83V55"></path><path stroke="#0000ff" stroke-linecap="round" stroke-width="5" d="M36 77H60M36 69H60M36 61H60"></path></svg>
  );
};
PrintIcon.defaultProps = {
  size: 26,
};
export default PrintIcon