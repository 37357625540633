import React, { Component } from "react";
import ChartsContent from "./ChartsContent";
import PatientChartsService from "../../../../../services/PatientChartsService";
import {isArray, isObject, isSet} from "../../../../../utils/commonUtils";
import WithRoutify from "../../../../../hoc/WithRoutify";
import OrganizationService from "../../../../../services/OrganizationService";
import FileManagerService from "../../../../../services/FileManagerService";
import Alert from "../../../../../components/UI/ConfirmBox/Alert";
import { createPortal } from "react-dom";
import { acl_check } from "../../../../../utils/aclUtils";
import PERMISSIONS from "../../../../../utils/AclPermissions";
import withReduxState from "../../../../../hoc/wReduxState";
class ActivityCharts extends Component {
    constructor(props) {
        super(props);
        this.state ={
            showAlert:false,
            formData:{},
            status:1000,
            hasEditAccess:true,
            validation:{}
        }
        // Add constructor if you need to initialize state or bind methods
    }

    onChangeHandler =(key,val,obj={})=>{
        const updateValidation={...this.state.validation};
        updateValidation[key]='';
        if(key==='collection_type'){
            updateValidation['collection_remarks']=''
        }
        this.setState((oldState) => ({
            formData: {
                ...oldState.formData,
                [key]: val,
                ...obj
            },
            validation:{...updateValidation}
        }));
    }
    updateFormData =(obj={})=>{

        const keysToEmptyString = ['new_adulteration_signs', 'other_reason', 'suggested_status'];

        // Map over the keys in obj and set their values to ""
        const validation = {};
        keysToEmptyString.forEach(key => {
            if (obj.hasOwnProperty(key)) {
                validation[key] = "";
            }
        });
        
        this.setState((oldState) => ({
            formData: {
                ...oldState.formData,
                ...obj
            },
            validation:{...validation}
        }));
    }
    setFormData = (obj={})=>{
        this.setState({formData:obj,status:200});
    }
    componentDidMount = ()=>{
      this.fetchPatientChartDetials();
      // edit access set
      let hasEditAccess = acl_check(this.props.profile.permissions, [PERMISSIONS.RESULT.EDIT,PERMISSIONS.VISITS.EDIT]);
      this.setState({hasEditAccess:hasEditAccess});

    }
  
    closeHandler=()=>{
        const{hasEditAccess}=this.state;
        if (!hasEditAccess) {
            this.props.toogleHanlder();
            return;
        }
        this.setState({
            showAlert:!this.state.showAlert
        });
    }
  
    render() {
        const { chartDetails } = this.props;
        const {formData,status,hasEditAccess} = this.state;
        const { activity_name, activity_type } = chartDetails;
        return (
          <>
          <ChartsContent
            type={activity_type}
            activityId={chartDetails.actitivity_id}
            mappingId={chartDetails.mapping_id}
            activityName={activity_name}
            formData={formData}
            status={status}
            employeeDetails={this.props.employeeDetails}
            companyDetails={this.props.companyDetails}
            lookupsOptions={this.props.lookupsOptions}
            stateList={this.props.stateList}
            toogleHanlder={this.closeHandler}
            visitDetails={this.props.visitDetails}
            changeHandler={this.onChangeHandler}
            setFormData={this.setFormData}
            updateFormData={this.updateFormData} 
            validation={this.state.validation}
            printLabelHandler={this.printLabelHandler}
            printResultHandler={this.printResultHandler}
            onSubmitHandler={this.onSubmitHandler}
            hasEditAccess={hasEditAccess}
            fetchPatientChartDetials={this.fetchPatientChartDetials}
              />
            {this.state.showAlert &&
              createPortal(
                <Alert
                  onDelete={this.props.toogleHanlder}
                  onSaveAndClose={this.onSubmitHandler}
                  saveAndClose={true}
                  onClose={this.closeHandler}
                  content={
                   "Are you sure you want to cancel? This action will not save any of the changes made."
                  }
                  yesButtonName={"Confirm"}
                  noButtonName={"Cancel"}
                  confirmButtonClassName='btn btn-primary'
                  cancelButtonClassName=''
                  type="warning"
                />,
                document.getElementsByClassName("sh_modalBox")[0]
              )}
          </>
        );
    }
    printLabelHandler = async (label_type,labelName)=>{
        const { chartDetails,params,Loader} = this.props;
        Loader(true);
        const {formData} = this.state;
        const activity_name = chartDetails.activity_name;
        const { mapping_id } = chartDetails;
        
        const queryfilters = {
            activity_name:(activity_name),
            label_type:label_type
        }
        try{
             
            let data = null;
            if(isSet(formData.lab)){

                data = await PatientChartsService.getPrintLabel(isSet(formData.organization,null),queryfilters,formData.id);    
            }else{
                data = await PatientChartsService.getPrintLabel(isSet(formData.organization,null),queryfilters,mapping_id);
            }
            
            const pdfData = {
                file_content:data,
                original_name:`${labelName}`,
                mime_type:'application/pdf'
                
            }
            FileManagerService.printPdf(pdfData);
        }catch(e){
            Loader(false);
        }
        Loader(false);
    }
    printResultHandler = async (type='other',isSaveAndPrint=false)=>{
        const { chartDetails,params,Loader} = this.props;
        Loader(true);
        const {formData}    = this.state;
        if(isSet(formData.id,null)===null && isSaveAndPrint===false){
            this.onSubmitHandler(type,true);
        }
        const activityName  = chartDetails.activity_name;
        const mappingId     = chartDetails.mapping_id;
        const visitId       = params.visitId;
        const labelName     = 'Print Result';
        const queryfilters  = {
            visit_id:visitId,
            activity_name:activityName
        }
        try{
            let data = null;
            if(type==='Provider'){
                data = await PatientChartsService.getProviderResult(isSet(formData.organization,null),queryfilters,mappingId);
            }else if(type==='Patient'){
                data = await PatientChartsService.getPatientResult(isSet(formData.organization,null),queryfilters,mappingId);
            }else if(type=='Lab_Report'){
                data = await PatientChartsService.getLaboratoryReport(isSet(formData.organization,null),queryfilters,formData.id);
            }else if(type=='Lab_Requisition'){
                data = await PatientChartsService.getLabRequisition(isSet(formData.organization,null),queryfilters,formData.id);

            }else{
                data = await PatientChartsService.getPrintResult(isSet(formData.organization,null),queryfilters,mappingId);
            }
            // const data = await PatientChartsService.getPrintResult(isSet(formData.organization,null),queryfilters,mappingId);
            const pdfData = {
                file_content:data,
                original_name:`${labelName}`,
                mime_type:'application/pdf'
                
            }
            FileManagerService.printPdf(pdfData);
        }catch(e){
            Loader(false);
        }
        Loader(false);
    }
    onSubmitHandler = async (type,isSaveAndPrint=false,isModelclose=true)=>{
        // console.log('isModelclose',isModelclose);
        this.setState({validation:{},showAlert:false});
        const { chartDetails,companyDetails,employeeDetails,params,Toast,Loader } = this.props;
        const companyUuid = companyDetails.company_uuid;
        const {formData} = this.state;
        const { mapping_id } = chartDetails;
        const payload = {
            patient_id:employeeDetails.employee_id, //patient id mismatch issue fix change lab
            ...formData,
            status:isSet(formData.status,"Not Started"),
            company_id:companyUuid,
            organization_uuid:OrganizationService.getOrgId(),
            visit_id:params.visitId?params.visitId:formData.visit_id,
        };
        Loader(true);
        
        try{
            let data = {};
            if(isSet(payload.id,null)===null){
                 data = await PatientChartsService.savePatientCharts(isSet(formData.organization,null),companyUuid,mapping_id,payload);
            }else{
                 data = await PatientChartsService.updatePatientCharts(isSet(formData.organization,null),companyUuid,mapping_id,payload);
            }
            Loader(false);
            let updateValidation = {...this.state.validation};
            if(data.status && data.status==="ERROR"){
                if (isObject(data.data)) {
                  for (let key in data.data) {
                    updateValidation = {
                      ...updateValidation,
                      [key]: data.data[key].join(","),
                    };
                  }
                } else {
                  Toast.error(data.data);
                }
                if (Object.keys(updateValidation).length > 0) {
                    this.setState({validation:updateValidation});
                }
              }else{
                if(isSaveAndPrint===true){
                    this.printResultHandler(type,isSaveAndPrint);
                }
                this.props.fetchVisitDetails();
                if (isModelclose==true) {
                    this.props.toogleHanlder();
                }
                Toast.success('Charts Updated Successfully');
              }

            
        }catch(e){
            Loader(false);
            console.log('e',e)
        }
        
    }

    fetchPatientChartDetials =async  ()=>{
        const { chartDetails,companyDetails,Loader } = this.props;
        const companyUuid = companyDetails.company_uuid;
        const { mapping_id } = chartDetails;
        // console.log('this.props',this.props);
        Loader(true);
        try{
            let data = await PatientChartsService.getPatientChart(companyUuid,mapping_id);
            console.log('data',data);
            Loader(false);
            data=isArray(data)?data.length==0?null:data:data; //array empty hanlde for respiratory fit test issue
            if(isSet(data,null)!==null){
                this.setFormData({...isSet(data,{}),suggested_status:isSet(data,{status:''}).status});
                // this.setState({status:200});
            }else{
                this.setState({status:200});
            }
            
        }catch(e){
            Loader(false);
        }
    }
}

export default withReduxState(WithRoutify(ActivityCharts));
