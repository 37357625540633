import { useEffect, useState } from "react";
import FormInputs from "../../components/UI/FormInputs";
import { LAB_TEST_RESULT,REASON_FOR_LAB } from "../../utils/constant";
import Buttons from "../../components/UI/Buttons";
import { isSet,getLookupByKey } from "../../utils/commonUtils";
import LabService from "../../services/LabService";
import LabOrderdService from "../../services/LabOrderdService";
import CompanyService from "../../services/CompanyService";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import PERMISSIONS from "../../utils/AclPermissions";
import { acl_check } from "../../utils/aclUtils";
import DropDownService from "../../services/DropDownService";

const LabFilter = (props) => {
    const { filterData, closeHandler, submitHandler,profile} = props;

    const [labOptions, setLabOptions] = useState([]);
    const [labOrderOptions, setLabOrderOptions] = useState([]);
    const [labTestResultOptions,setlabTestResultOptions] =useState([]);
    const [reasonForLabOptions,setreasonForLabOptions] = useState([]);
    const [clinicDepatmentOptions, setClinicDepatmentOptions] = useState([]);
    const [CompanyOptions, setCompanyOptions] = useState([]);
    const statusOptions = [{label:'-Status-',value:''},
                           {label:'PASSED',value:'PASSED'},
                           {label:'FAILED',value:'FAILED'},
                           {label:'PENDING',value:'PENDING'}];


    useEffect(() => {
        const fetchIntialDatas = async () => {
            // props.Loader(true);
            try {
                await fetchLabs();
                await fetchLabOrder();
                await fetchCompany();
    
                const labTestResult = await getLookupByKey(LAB_TEST_RESULT);
                if (labTestResult.length > 0) {
                    labTestResult[0].label = '-Test Result-';
                }
                setlabTestResultOptions(labTestResult);
    
                const reasonForLab = await getLookupByKey(REASON_FOR_LAB);
                if (reasonForLab.length > 0) {
                    reasonForLab[0].label = '-Reason For Lab-';
                }
                setreasonForLabOptions(reasonForLab);
            } finally {
                props.Loader(false); // Set loader to false after data loading completes
            }
        }
        fetchIntialDatas()
    }, [])
    useEffect(() => {
        fetchLabOrder();
    }, [filterData.lab_id]);
    const fetchLabs = async () => {
        try {
            const data = await DropDownService.getLabsList(null);
            let labOptions = [{ label: "-Lab Choice-", value: "" }];
            isSet(data,[]).forEach((itm, ind) => {
                labOptions.push({ label: itm.name, value: itm.lab_id });
            });
            setLabOptions(labOptions);
        } catch (error) {
            console.error("Error fetching labOptions:", error);
        }
    }
    const fetchLabOrder = async () => {
        let labOrderOptions = [{ label: "-Lab Order-", value: "" }];
        if (filterData.lab_id) {
            const response = await LabOrderdService.getLabOrder(null, filterData.lab_id);
            isSet(response, []).forEach((itm, ind) => {
                labOrderOptions.push({ label: itm.lab_order, value: itm.lab_order_id });
            });
        }
        setLabOrderOptions(labOrderOptions);

    }
    const fetchCompany = async () => {
        let companyListResponse = await DropDownService.getCompanies(null);
        console.log('companyListResponse',companyListResponse);
        let companyListOptions = [{ label: "-Select Company", value: "" }];
        companyListResponse&&companyListResponse.forEach((company, companyInd) => {
                companyListOptions.push({
                    label: company.name,
                    value: company.company_uuid,
                });
            });
        setCompanyOptions(companyListOptions);

    };
    const inputFields = [
        {
            type: "TextInput",
            placeholder: "First Name",
            className: "form-control form-control-solid",
            name: "first_name",
        },
        {
            type: "TextInput",
            placeholder: "Last Name",
            className: "form-control form-control-solid",
            name: "last_name",
        },
        {
            type: "TextInput",
            placeholder: "Patient ID",
            className: "form-control form-control-solid",
            name: "patient_id",
        },
        {
            type: "TextInput",
            placeholder: "Booking ID",
            className: "form-control form-control-solid",
            name: "booking_id",
        },
        {
            type: "SelectList",
            options: CompanyOptions,
            placeholder: "company_id",
            className: "form-control form-control-solid",
            name: "company_id",
            show:acl_check(profile.permissions,PERMISSIONS.COMPANY.LIST),
        },
        {
            type: "Date",
            placeholder: "DOB",
            className: "form-control form-control-solid",
            name: "dob",
        },
        {
            type: "TextInput",
            placeholder: "EMR Patient ID",
            className: "form-control form-control-solid",
            name: "emr_patient_id",
        },
        {
            type: "SelectList",
            options: labOptions,
            placeholder: "",
            className: "form-control form-control-solid",
            name: "lab_id",
        },
        {
            type: "TextInput",
            placeholder: "Lab ID",
            className: "form-control form-control-solid",
            name: "random_lab_id",
        },
        {
            type: "SelectList",
            options: labOrderOptions,
            placeholder: "Lab Order",
            className: "form-control form-control-solid",
            name: "lab_order_id",
        },
        {
            type: "SelectList",
            options: labTestResultOptions,
            // placeholder: "",
            className: "form-control form-control-solid",
            name: "test_result",
          },
        {
            type: "TextInput",
            placeholder: "Alternate ID",
            className: "form-control form-control-solid",
            name: "alternate_id",
        },
        {
            type: "Date",
            placeholder: "Test Date From",
            className: "form-control form-control-solid",
            name: "test_date_from",
        },
        {
            type: "Date",
            placeholder: "Test Date To",
            className: "form-control form-control-solid",
            name: "test_date_to",
        },
        {
            type: "Date",
            placeholder: "Result Date",
            className: "form-control form-control-solid",
            name: "result_date",
        },
        {
            type: "TextInput",
            placeholder: "Sample ID",
            className: "form-control form-control-solid",
            name: "sample_id",
        },
        {
            type: "SelectList",
            options: reasonForLabOptions,
            placeholder: "",
            className: "form-control form-control-solid",
            name: "reason_for_lab",
          },
        {
            type: "SelectList",
            options: statusOptions,
            placeholder: "",
            className: "form-control form-control-solid",
            name: "status",
          },
    ];
    return (
        <>
            <div className="sidebar_Filter toggled" id="sidebar_FilterOpen">
                <div className="sh_filterBox">
                    <div className="sh_filterHeader px-2 py-1">
                        <div className="card-title mb-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="mb-0 text-md">Search by</h6>
                                <Buttons
                                    //iconType="Cancel"
                                    className="btn-close text-sm"
                                    acl={"any"}
                                    clickHandler={() => closeHandler(false)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sh_filterBody">
                        <div className="row">
                            {inputFields.map((field, index) => isSet (field.show,true)==true&&(
                                <div className="col-12">
                                    <div className="form-group mb-1">
                                        <FormInputs
                                            value={isSet(filterData[field.name], "")}
                                            {...field}
                                            changeHandler={(val, e) =>
                                                props.onChangeHandler(field.name, val)
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="sh_filterFooter">
                        <Buttons
                            clickHandler={closeHandler}
                            className="btn btn-outline-secondary text-sm"
                            acl={"any"}
                            label={"Clear"}
                        />
                        <Buttons
                            clickHandler={submitHandler}
                            className="btn btn-primary text-sm"
                            acl={"any"}
                            label={"Apply"}
                        />


                    </div>
                </div>
            </div>
        </>
    );
};
export default withReduxState(WithRoutify(LabFilter));
