import React from "react";
import FormInputs from "../../../../../../components/UI/FormInputs";
import { isSet,getCss } from "../../../../../../utils/commonUtils";




const BasicFormFields = (props)=>{
    const {itemIndex,column,label,labelWrap,rangeMin,rangeMax,name,formData,changeHandler,defaultValue,isLabel,appendElmtClass, validation} = props;
    const value = isSet(formData[name],isSet(defaultValue,''));
    let otherArributes = {...getCss(name,value)};
    if((isSet(rangeMin,null)!==null && value<rangeMin) || isSet(rangeMax,null)!==null && value>rangeMax){
      otherArributes={
        ...otherArributes,
        style:{color:'red'}
      }
    }
    const labelWrapCls = (labelWrap===true)?'text-truncate':'';
    let reload =''
    if (formData['lab_id']!=null) {
      reload=formData['lab_id']
    }
    return(
      <>
        <div className={`${column} border border-left-0 py-1`} key={itemIndex}>
        <div className="form-group row mb-0 align-items-center">
          {isSet(isLabel,true)===false?
          <></>
          :<label className={`col-sm-6 f-400 text-sm ${labelWrapCls} mb-0 opacity-75`} title={label}>  {label}  </label>}
           
           <div className={(isSet(isLabel,true)===false)?"col-sm-12":`col-sm-6 ${appendElmtClass}`}>
              <FormInputs 
                {...props}
                {...otherArributes}
                value={value}
                reload={reload}
                changeHandler={(val,{addressParam = {},autoPopulates={}} = {})=>{
                  changeHandler(name,val,{...addressParam,...autoPopulates})} }/>
                {
                  (isSet(validation[name], "") !== "" ? (
                      <span className="text-danger m-1">
                        {isSet(validation[name], "")}
                      </span>
                    ) : (
                      ""
                    ))
                }
                  
                
           </div>
        </div>
     </div> 
     </>
    )
}

BasicFormFields.defaultProps = {
   defaultValue:null,
   isLabel:true,
   appendElmtClass:'',
   access:true,
   rangeMax:null,
   rangeMin:null,
   labelWrap:true,
   validation:{}
}
export default BasicFormFields;
