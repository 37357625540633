import React, { Component } from "react";
import TableGrid from "../../components/UI/TableGrid";
import { ToastContext } from "../../context/ToaxtContext";
import {
  isSet,
  toQueryString,
  getShortenedVisitCategoryName,
  formatDate
} from "../../utils/commonUtils";
import Buttons from "../../components/UI/Buttons";
import WithRoutify from "../../hoc/WithRoutify";
import VisitsService from "../../services/VisitsService";
import withReduxState from "../../hoc/wReduxState";
import EmployeeDetails from "../EmployeeContainer/EmployeeDetails";
import LookupService from "../../services/LookupService";
import AddEmployee from "../EmployeeContainer/AddEmployee";
import { VISIT_STATUS } from "../../utils/constant";
import WithRouter from "../../hoc/WithRouter";
import PERMISSIONS from "../../utils/AclPermissions";
import MiniPopup from "../../components/Modal/MiniPopup";
import Acl from "../../hoc/Acl";
import { getActiveIds } from "../../utils/visitUtils";
import { isLaboratoryVisitCategory } from "../../utils/visitUtils";
import ScanbarCodePopup from "../EmployeeContainer/ScanbarCodePopup";
import AddVisits from "../EmployeeVisitContainer/AddVisits";
import VisitDetails from "../EmployeeVisitContainer/VisitDetails";
import AddFollowUp from "../EmployeeVisitContainer/AddFollowUp";
import AddPhysical from "../EmployeeVisitContainer/AddPhysical";
import AddLab from "../EmployeeVisitContainer/AddLab";
import DischargeAndWaiting from "../AppointmentsContainer/DischargeAndWaiting";
import FutureAppointments from "../AppointmentsContainer/FutureAppointments";
import CalendarWidget from "../AppointmentsContainer/CalendarWidget";
import { fetchCurrentDate } from "../../utils/DateUtils";

class WhiteboardList extends Component {
  static contextType = ToastContext;
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        first_name: "",
        last_name: "",
        gender: "",
        company_id: "",
        dob: "",
        visit_status: "",
        patient_id: "",
        clinic_location_id: "",
        phone: "",
        patient_id: "",
        identification_type: "",
        identification_number: "",
        clinic_id: "",
        booking_id: "",
        pre_registration_code: "",
      },
      tableRecords: {
        tableHeaders: [],
        tableRows: {
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'visit_date',
          sort_order: 'desc',
        }
      },
      formDataInEdit: {},
      activeEditId: null,
      isModelOpen: false,
      isAddPatientModelOpen: false,
      detailViewId: null,
      isDetailViewOpen: false,
      organization_id: "",
      filterOpen: false,
      visitsAutoFillData: {},
      visitDetailViewId: null,
      isVisitDetailViewOpen: false,
      activeLabId: null,
      isLabModelOpen: false,
      isPhysicalModelOpen: false,
      isFollowUpModelOpen: false,
      snapClinicConsentForm: null,
      snapVisitConsentForm: null,
      snapEmployeeDetails: null,
      selectedAllRecords: false,
      Tab: [
        // { "label": "Active", isActive: true, name: 'Active' },
        // { "label": "Dischrg", isActive: false, name: 'Discharge' },
        { "label": "All", isActive: true, name: '' },
        { "label": "Interface", isActive: false, name: 'In Active' },

      ],
      isMiniPopupOpen: false,
      selectedVisitId: null,
      selectedVisitStatus: null,
      scanBarcodeModalOpen: false,
      autoEmployeeFillData: {},

      //
      currentDay: null,
      calendarViewType: 'WEEK',
      isCalendarWidgetDate: null,
      calendarRecords: [],
      calendarParam: { from_date: '', to_date: '' },
    };
  }

  async componentDidMount() {
    if (this.props.location?.state) {
        this.setState({ currentDay: this.props.location.state.currentDay }, this.fetchVisitsList);
    } else {
        this.setState({ currentDay: fetchCurrentDate() }, this.fetchVisitsList);
    }
}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isModelOpen !== this.props.isModelOpen) {
      this.addVisitToogleHandler();
    }
    if (prevProps.visitsAutoFillData !== this.props.visitsAutoFillData) {
      this.setState({ visitsAutoFillData: this.props.visitsAutoFillData })
    }
    if (prevState.currentDay != this.state.currentDay) {
      this.fetchVisitsList()
    }
    if (prevProps.globalFilter?.['White Board'] !== this.props?.globalFilter?.['White Board']) {
      this.fetchVisitsList();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('filterOpened')
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addVisitToogleHandler = (emptyAutoFillData = false) => {
    let updatedState = { ...this.state };
    if (emptyAutoFillData) {
      updatedState = { ...updatedState, ['visitsAutoFillData']: {} };
    }
    else {
      updatedState = { ...updatedState, ['visitsAutoFillData']: { visit_date: formatDate(this.state.currentDay) } };
    }
    updatedState = { ...updatedState, ['isModelOpen']: !this.state.isModelOpen };
    this.setState(updatedState);
  };
  scanBarcodeHandler = (obj = {}, isAddEmployee = false) => {
    this.setState({
      scanBarcodeModalOpen: !this.state.scanBarcodeModalOpen,
      autoEmployeeFillData: obj,
      isAddPatientModelOpen: isAddEmployee
    });
  }
  addNewEmployeeToogleHandler = (bool = false, nextStep = null, autoFillData) => {
    if (nextStep === 'ADD_VISIT') {
      this.setState({
        visitsAutoFillData: autoFillData,
        isAddPatientModelOpen: bool
      }, this.addVisitToogleHandler());
    } else {
      this.setState({
        isAddPatientModelOpen: bool
      });
    }
  };
  openMiniPopup = (visitId, visitStatus) => {

    this.setState({
      isMiniPopupOpen: true,
      selectedVisitStatus: visitStatus,
      selectedVisitId: visitId,
    });
  };

  closeMiniPopup = () => {
    this.setState({
      isMiniPopupOpen: false,
      selectedVisitId: null,
      selectedVisitStatus: null,
    });
  };
  render() {
    const { params } = this.props;
    const {
      tableRecords,
      isModelOpen,
      isAddPatientModelOpen,
      visitCategoryOptions,
      isDetailViewOpen,
      visitsAutoFillData,
      detailViewId,
      isVisitDetailViewOpen,
      visitDetailViewId,
      companiesOptions,
      visitStatusOption,
      isLabModelOpen,
      snapEmployeeDetails,
      Tab,
      isFollowUpModelOpen,
      isPhysicalModelOpen,
      isMiniPopupOpen,
      selectedVisitId,
      selectedVisitStatus,
      selectedAllRecords,
      autoEmployeeFillData,
      currentDay
    } = this.state;
    const isPatientHistory = (isSet(params.patientId, null) !== null) ? true : false;
    return (
      <>
        {isAddPatientModelOpen ? (
          <AddEmployee
            modelTitle={"Add Employee"}
            isFromVisitHistory={this.props.isFromVisitHistory}
            fetchEmployeeList={this.fetchVisitsList}
            isOpen={isAddPatientModelOpen}
            autoEmployeeFillData={autoEmployeeFillData}
            modalToogleHandler={this.addNewEmployeeToogleHandler}
            companiesOptions={this.state.companiesOptions}
          />
        ) : isModelOpen ? (
          <AddVisits
            modelTitle={"Add Visits"}
            fetchVisitsList={this.fetchVisitsList}
            isOpen={isModelOpen}
            autoFillData={visitsAutoFillData}
            modalToogleHandler={this.addVisitToogleHandler}
            visitCategoryOptions={visitCategoryOptions}
            companiesOptions={this.state.companiesOptions}
          />
        ) : isDetailViewOpen ? (
          <EmployeeDetails
            modalToogleHandler={this.detailViewModal}
            fetchEmployeeList={() => { }}
            employeeId={detailViewId}
          />
        ) : (
          <></>
        )}
        {isVisitDetailViewOpen ? (
          <VisitDetails
            isOpen={isVisitDetailViewOpen}
            visitId={visitDetailViewId}
            modalToogleHandler={this.visitDetailViewModal}
            fetchDetails={this.fetchVisitsList}
            snapEmployeeDetails={snapEmployeeDetails}
          />
        ) : (
          ""
        )}
        {isMiniPopupOpen && (
          <MiniPopup
            isOpen={isMiniPopupOpen}
            onClose={this.closeMiniPopup}
            onUpdate={this.handleUpdateStatus}
            visitId={selectedVisitId}
            fetchVisitsList={this.fetchVisitsList}
            selectedVisitStatus={selectedVisitStatus}
          />
        )}
        {
          isLabModelOpen ?
            <AddLab
              modelTitle={"Add Lab"}
              isOpen={isLabModelOpen}
              visitId={visitDetailViewId}
              modalToogleHandler={this.addLabToogleHandler}
              fetchRecords={this.fetchVisitsList}
            />
            :
            ''
        }
        {
          isFollowUpModelOpen ?
            <AddFollowUp
              modelTitle={"Follow Up"}
              visitId={visitDetailViewId}
              isOpen={isFollowUpModelOpen}
              modalToogleHandler={this.followUpModelHandler}
              fetchRecords={this.fetchVisitsList}
            />
            :
            ''
        }
        {
          isPhysicalModelOpen ?
            <AddPhysical
              modelTitle={"Add Physical / Drug Screen"}
              isOpen={isPhysicalModelOpen}
              visit_id={visitDetailViewId}
              fetchRecords={this.fetchVisitsList}
              modalToogleHandler={this.addPhysicalToogleHandler}
            />
            :
            ''
        }
        {
          this.state.scanBarcodeModalOpen &&
          <ScanbarCodePopup
            modleToogleHandler={this.scanBarcodeHandler}
          />
        }
        <div className="row h-100 " >
          <div className="col-12 col-lg-3 pt-2" style={{ height: 'calc(100vh - 95px)', overflowY: "auto", scrollbarWidth: 'thin' }}>
            <CalendarWidget currentDay={this.state.currentDay} calendarViewType={this.state.calendarViewType} setCurrentDate={this.setCurrentDate} />
            <DischargeAndWaiting
              fromWhiteBoard={true}
              fetchDetails={this.fetchVisitsList}
              calendarRecords={this.state.calendarRecords}
              currentDay={this.state.currentDay} />
            <FutureAppointments
              calendarRecords={this.state.calendarRecords}
            />
          </div>
          <div className="col-lg-9 col-12" style={{ height: '100calc(100vh - 95px)vh' }}>
            <div className="sh_cardBox" id="sh_cardBox">
              <div className="sh_cardHeader pb-1">
                <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                  <div>
                    <div className="tabs_wrapForTable">
                      <ul className="nav nav-borders nav-tabs border-0" role="">
                        {Tab.map((itm, itmInd) => {
                          return (
                            <>
                              <li>
                                <a
                                  className={`flex-sm-fill text-sm-center nav-link ${itm.isActive ? "active" : ""
                                    }`}
                                  aria-current="page"
                                  onClick={() => this.setActiveTab(itm)}
                                >
                                  {itm.label}
                                </a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  {/* <h6 class="text-lg f-500 mb-0">{` Employee / Patient Visits `} </h6> */}
                  <div className="d-flex">
                    <div className="form-group-fields row mr-2">
                      <div className="col-12 px-1 d-flex">
                        <div className="input-group input-group-joined input-group-solid"></div>
                        <div className="addAction">
                          <div className="btn-group">
                            <Acl acl={this.anyCheckboxSelected() == true ? PERMISSIONS.VISITS.DELETE : ''}>
                              <Buttons
                                clickHandler={() => this.deleteHandler(null, true)}
                                className="btn btn-primary"
                                isDelete={true}
                                label={"Delete"}
                                contentTitle={'Warning'}
                                content={'Selected visits may have associated activities. Are you sure you want to delete the records?'}
                              />
                            </Acl>
                          </div>
                        </div>
                        <div className="btn-group pl-2">
                          {/* <Buttons
                          clickHandler={this.filterOnClick}
                          className="btn btn-outline-secondary mr-2"
                          acl={"any"}
                          label={"Search by "}
                          iconType={"Filter"}
                        ></Buttons> */}
                        </div>
                      </div>
                    </div>
                    <div className="addAction">
                      <div className="btn-group">
                        <Buttons
                          clickHandler={this.addVisitToogleHandler}
                          className="btn btn-primary"
                          acl={"visits-create"}
                          appendAddIcon={true}
                          label={"Add Visit"}
                        />
                      </div>
                    </div>
                    {/* <Buttons
                    clickHandler={() =>
                      this.addNewEmployeeToogleHandler(true)
                    }
                    className="btn btn-primary ml-1"
                    acl={"employee-create"}
                    label={"Add Patient"}
                  /> */}
                    {/* <Buttons
                            clickHandler={this.scanBarcodeHandler}
                            className="btn btn-primary ml-1"
                            acl={"employee-create"}
                            label={"Scan barcode"}
                          /> */}
                  </div>
                </div>
              </div>

              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) => this.fetchVisitsList(filters)}
                hasHeaderAction={true} //headerProps
                headersProps={{
                  tableRecords: tableRecords,
                  headerButtons: this.getHeaderButtons(),
                  checkBoxchangeHandler: this.checkBoxchangeHandler,
                  selectedAllRecords: selectedAllRecords,
                  setSelectedAllRecords: (bool) => this.setState({ selectedAllRecords: bool })

                }}
                {...tableRecords}
                gridEditProps={{
                  formDataInEdit: this.state.formDataInEdit,
                  activeEditId: this.state.activeEditId,
                  onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  setCurrentDate = (val, calendarViewType) => {
    this.setState({ currentDay: val, calendarViewType: calendarViewType, isCalendarWidgetDate: val });
  }
  submitFiltersHandler = async () => {
    const { filters, pagination } = this.state;
    const queryfilters = {
      ...pagination,
      page: 1,
      ...filters,
    };
    await this.fetchVisitsList(queryfilters);
  };
  clearFiltersHandler = async () => {
    this.setState({
      filters: {
        first_name: "",
        last_name: "",
        gender: "",
        company_id: "",
        dob: "",
        visit_status: '',
        patient_id: "",
        phone: "",
        patient_id: "",
        identification_type: "",
        identification_number: "",
        clinic_id: "",
        booking_id: "",
        pre_registration_code: "",
        clinic_location_id: "",
      },
    }, this.submitFiltersHandler);

  };
  fetchVisitsList = async (query = {}) => {
    const { Tab, currentDay, calendarParam } = this.state
    const { tableRecords } = this.state;
    const { params } = this.props;
    const { filters } = this.state;
    const globalFilter=this.props?.globalFilter?.['White Board'] || {};
    let queryString = toQueryString({
      ...tableRecords.pagination,
      ...query,
    });

    queryString = { ...queryString, ...filters ,...globalFilter};
    const currentDate = formatDate(currentDay);
    queryString.visit_from = currentDate;
    queryString.visit_to = currentDate;
    try {
      this.props.Loader(true);
      let VisitsList = {};
      const activeTab = Tab.find(itm => itm.isActive == true);
      console.log('activetab',activeTab);
      
      query = { ...query, ['visit_status']: activeTab.name }
      VisitsList = await VisitsService.getVisitsList(queryString);
      let tableData = [];
      VisitsList.data.data.forEach((visit) => {
        const hyperLink = isSet(params.patientId, null !== null) ?
          { href: `/employee/visit-history/${params.patientId}/${params.patientDetails}/${visit.visit_id}`, title: 'Patient Visit detailed view' } :
          { href: `/visits/visit-details/${visit.visit_id}/white-board`, title: 'Patient Visit detailed view',hrefState:{currentDay:currentDay}};
        tableData = [
          ...tableData,
          {
            employeeIndex: 0,
            isHeading: false,
            rowId: visit.visit_id,
            data: {
              ...visit,
              isActive: false,
              employee_name: [
                {
                  className: "text-sm",
                  acl: "any",
                  label: visit.employee.first_name + ' ' + visit.employee.last_name,
                  clickHandler: () => this.detailViewModal(visit.employee_id)
                },
              ],
              clinic: {
                value: visit?.employee?.clinic?.name,
                ...hyperLink
              },
              company: { value: visit?.company?.name, ...hyperLink },
              visit_date: {
                value: visit.visit_date,
                ...hyperLink
              },
              visit_category: {
                value: getShortenedVisitCategoryName(visit.visit_category),
                ...hyperLink
              },
              visit_status: [
                {
                  className: "text-sm",
                  // iconType: "Edit",
                  label: visit.visit_status,
                  acl: "any",
                  title: "Update Status",
                  clickHandler: (e) =>
                    this.openMiniPopup(visit.visit_id, visit.visit_status),
                },],
              visit_time: { value: visit.visit_time, ...hyperLink },
              visit_reason: { value: visit.visit_reason, ...hyperLink },
              formatted_los_time: { value: visit.formatted_los_time, ...hyperLink },
              created_at: visit.created_at,
              updated_at: visit.updated_at,
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "ActivityChart",
                  iconSize: 15,
                  acl: "any",
                  title: 'Chart',
                  href: hyperLink.href,
                  ...hyperLink,
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Labs Order",
                  title: "Add Lab",
                  label: "",
                  acl: `${visit.visit_category == 'Employer Services - Labs' ? PERMISSIONS.RESULT.CREATE : ''}`,
                  disableButtonTitle: "Add Lab",
                  clickHandler: () =>
                    this.addLabToogleHandler(visit.visit_id),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "AddPhysical",
                  label: "",
                  disableButtonTitle: "Add Physical and Drug Screen",
                  acl: isLaboratoryVisitCategory(visit.visit_category) ? '' : visit.has_physical ? '' : 'any',
                  title: "Add Physical and Drug Screen",
                  clickHandler: () =>
                    this.addPhysicalToogleHandler(visit.visit_id, visit.company_id),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "CalenderPlus",
                  label: "",
                  acl: "visits-create",
                  title: "Follow Up",
                  clickHandler: () =>
                    this.followUpModelHandler(visit.visit_id),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Search",
                  label: "",
                  acl: "employee-edit",
                  title: "Detail view",
                  clickHandler: () =>
                    this.visitDetailViewModal(visit.visit_id, visit.employee),
                },

                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: "visits-delete",
                  title: "Delete",
                  isDelete: true,
                  contentTitle: visit.has_physical ? 'Warning' : null,
                  content: visit.has_physical ? 'This visit has some associated activities.  Are you sure you want to delete this record?' : null,
                  clickHandler: () => this.deleteHandler(visit.visit_id),
                },
              ],
            },
          },
        ];
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: this.getTableHeader(),
          tableRows: {
            type: "default",
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: VisitsList.pagination.totalCount,
          },
        },
        calendarRecords:tableData,
      }));

      this.props.Loader();
    } catch (e) {
      console.log(e);
      this.props.Loader();
    }
  };


  getTableHeader = () => {
    return [
      {
        label: "",
        name: "ALL",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      // {
      //   label: "Visit Date",
      //   key: "visit_date",
      //   type: "",
      //   inputType: "Date",
      //   sortName: 'visit_date'
      // },
      {
        label:
          "Visit Time",
        key: "visit_time",
        type: "",
        sortName: 'visit_time'
      },
      // {
      //   label: "Registration",
      //   key: "registration",
      //   type: "",
      // },
      {
        label: "Patient",
        type: 'Actions',
        key: "employee_name",
        sortName: 'employee_name'
      },
      // {
      //   label: "Clinic",
      //   key: "clinic",
      // },
      {
        label: "Company",
        key: "company",
      },

      {
        label: "Visit category",
        key: "visit_category",
        type: "",
        options: this.state.visitCategoryOptions,
        inputType: "SelectList",
        sortName: 'visit_category'
      },
      {
        label: "Visit Status",
        key: "visit_status",
        type: "Actions",
        options: this.state.visitStatusOption,
        inputType: "SelectList",
        sortName: 'visit_status'
      },
      // {
      //   label: "Reason",
      //   key: "visit_reason",
      //   type: "",
      //   inputType: "TextArea",
      //   sortName: 'visit_reason'
      // }
      , {
        label: "LOS",
        key: "formatted_los_time",
        type: "",
        inputType: "TextArea",
        sortName: 'formatted_los_time'
      },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ]
  };
  visitDetailViewModal = (visitId = null, snapEmployeeDetails = null, fetch = false) => {
    if (fetch) {
      this.fetchVisitsList();
    }
    this.setState((oldState) => ({
      isVisitDetailViewOpen: !oldState.isVisitDetailViewOpen,
      visitDetailViewId: visitId,
      snapEmployeeDetails: snapEmployeeDetails,
    }));
  };
  deleteHandler = async (visitId, multipleDelete = false) => {
    if (multipleDelete) {
      visitId = getActiveIds(this.state.tableRecords)
    }
    try {
      await VisitsService.deleteVisits(visitId);
      this.props.Toast.success("Visit Deleted successfully");
      this.fetchVisitsList();
      // this.props.Toast.success('Employee Deleted Succesfully');
      //   }
    } catch (e) { }
  };
  editHandler = (rowId, data) => {
    // console.log("rowId",rowId,"data",data);
    if (rowId == null) {
      this.setState({ activeEditId: "", formDataInEdit: {} });
      return false;
    }
    this.setState({
      activeEditId: rowId,
      formDataInEdit: data,
    });
  };
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    let updatedFilters = { ...filters };
    if (key === 'clinic_id') {
      updatedFilters['clinic_location_id'] = "";
    }
    this.setState({
      filters: {
        ...updatedFilters,
        [key]: val,
      },
    });
  };
  addLabToogleHandler = (visitId = null) => {
    this.setState(
      {
        isLabModelOpen: !this.state.isLabModelOpen,
        visitDetailViewId: visitId,
      }
    )
  }
  checkBoxchangeHandler = (value, { e }, rowId = '') => {
    if (!rowId) {
      rowId = e.target.id;
    }
    const { tableRows } = this.state.tableRecords;
    let updatedData;
    if (rowId == 'ALL') {
      updatedData = tableRows.data.map((item) => {
        item.data.isActive = value;
        return item;
      });
      this.setState({
        selectedAllRecords: false,
      });
    }
    else {
      updatedData = tableRows.data.map((item) => {
        if (item.rowId === rowId) {
          item.data.isActive = value;
        }
        return item;
      });
    }
    const allSelected = updatedData.every(item => item.data.isActive == 1);
    const allCheckbox = document.getElementById('ALL');
    if (allCheckbox) {
      allCheckbox.checked = allSelected;
    }
    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  filterOnClick = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    }, () => {
      if (document.body.classList.contains('filterOpened')) {
        document.body.classList.remove('filterOpened');
        // menuToggleElement.removeClass('active');
      } else {
        document.body.classList.add('filterOpened');
      }
    });
  };
  filterCloseHandler = (isClear = true) => {
    if (isClear) {
      this.clearFiltersHandler();
    }
    else {
      this.setState({
        filterOpen: false,
      }, () => {
        if (document.body.classList.contains('filterOpened')) {
          document.body.classList.remove('filterOpened');
          // menuToggleElement.removeClass('active');
        } else {
          document.body.classList.add('filterOpened');
        }
      });
    }
  };
  detailViewModal = (empId) => {
    this.setState((oldState) => ({
      isDetailViewOpen: !oldState.isDetailViewOpen,
      detailViewId: empId,
    }));
    if (empId == null) {
      this.fetchVisitsList();
    }
  };
  fetchVisitStatus = async () => {
    try {
      const data = await LookupService.getLookupByKey(VISIT_STATUS);
      let visitStatusArr = [{ label: '-Visit Status-', value: '' }];
      data.forEach((item) => {
        visitStatusArr.push({
          label: item.value,
          value: item.value
        });
      });
      this.setState({ visitStatusOption: visitStatusArr });
    } catch (e) {
    }
  }
  followUpModelHandler = (visit_id = '') => {
    this.setState({ isFollowUpModelOpen: !this.state.isFollowUpModelOpen, visitDetailViewId: visit_id });
  }
  addPhysicalToogleHandler = (visitId = '') => {
    this.setState({ isPhysicalModelOpen: !this.state.isPhysicalModelOpen, visitDetailViewId: visitId });
  }
  setActiveTab = (val) => {
    let { Tab, filters } = this.state;
    const { label, name, isActive } = val
    let updatedTab = Tab.map((item) => {
      if (item.label == label) {
        item.isActive = true
      }
      else {
        item.isActive = false;
      }
      return item;
    });
    this.setState((prevState) => ({
      Tab: updatedTab,
      filters: { ...prevState.filters, ['visit_status']: name },
    }), () => this.submitFiltersHandler());
  };
  anyCheckboxSelected = () => {
    const { tableRows } = this.state.tableRecords;
    if (!tableRows || !Array.isArray(tableRows.data)) {
      return false;
    }

    return tableRows.data.some(item => item.data?.isActive === 1 || item.data?.isActive === true);
  }
  getHeaderButtons = () => {
    const HeaderButtons = [
      {
        label: 'Download Csv',
        clickHandler: () => this.downloadCSV(),
      },
      {
        label: 'Delete',
        clickHandler: () => { },
      }
    ];
    return HeaderButtons;
  }
}
WhiteboardList.defaultProps = {
  isFromVisitHistory: false
}
export default WithRouter(withReduxState(WithRoutify(WhiteboardList)));
