// // TableRow.js

import { isSet } from '../../../utils/commonUtils';
import TableData from './TableData';
import Wrap from '../../../hoc/Wrap';
   

const TableRow = ({ rowData, rowIndex, tableHeaders, gridEditProps,changeHandler,...props }) => {
    const { rowId } = rowData;
    const {activeEditId} = gridEditProps;
    const HocComponent =(props.isSortable)?Wrap:'tr'; 
    return (
        <HocComponent
            key={rowIndex}
            index={props.indexKey}
            id={props.indexKey}
            // {...props.handlers}
            collection={props.collection}
            className={rowData.isHeading ? 'Tdheading' : ''}
            onMouseDown={props.onMouseDown}
            >
                {tableHeaders.map((tHead, tHeadIndex) => {
                    if (!(tHead.key in rowData.data)) {
                        return null; // Skip rendering if the key doesn't exist in rowData.data
                    }
                    let formInputProps = {type:isSet(tHead.type,'')};
                    if(isSet(rowId,null)!==null && activeEditId===rowId && isSet(tHead.inputType,null)!==null){
                        formInputProps ={
                            type:'FormInput',
                            changeHandler:gridEditProps.onChangeFormDataInEdit
                        }
                    }
                    
                    return (
                        <TableData
                            changeHandler={changeHandler}
                            options={tHead['options']}
                            key={tHeadIndex}
                            tHeadIndex={tHeadIndex}
                            value={rowData.data[tHead.key]}
                            {...tHead}
                            rowId={rowId}
                            thHeadKey={tHead.key}
                            {...formInputProps}
                            isSortable={props.isSortable}
                            gridEditProps={{ ...gridEditProps, tableHeaders:tableHeaders,rowData:rowData }}
                        />
                    );
                })}
        </HocComponent>
    );
};

TableRow.defaultProps = {
    gridEditProps:{
        activeEditId:null
    },
    handlers:{}
}
export default TableRow;



// TableRow.js
// import React from 'react';
// import { useSortable } from '@dnd-kit/sortable';
// import { isSet } from '../../../utils/commonUtils';
// import { CSS } from "@dnd-kit/utilities";
// import styled from "styled-components";

// import TableData from './TableData';
// import Wrap from '../../../hoc/Wrap';




// const TableRow = ({ rowData, tableHeaders, gridEditProps, changeHandler }) => {
//   const { rowId } = rowData;
//   const { activeEditId } = gridEditProps;
//   const { attributes, listeners, setNodeRef, transform, transition,isDragging } = useSortable({
//     id: rowId,
//   });
// //   console.log('transform',transform);
//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition: transition
//   };
  
// const HandleWrapper = styled.div`
//         height: 1rem;
//         vertical-align: bottom;
//         display: inline-block;
//         margin-right: 0.5rem;
//         svg {
//         width: 100%;
//         height: 100%;
//         }
//         cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "grab")};
//         `;
//   return (
//     <tr
//       key={rowId}
//       className={rowData.isHeading ? 'Tdheading' : ''}
//       ref={setNodeRef}
//       style={style}
//     //   {...attributes}
//     //   {...listeners}
//     >
//       <Wrap>
//         <td><DragHandler {...attributes}
//             {...listeners} HandleWrapper={HandleWrapper}/></td>
       
//         {tableHeaders.map((tHead, tHeadIndex) => {
//           if (!(tHead.key in rowData.data)) {
//             return null; // Skip rendering if the key doesn't exist in rowData.data
//           }
//           let formInputProps = { type: isSet(tHead.type, '') };
//           if (isSet(rowId, null) !== null && activeEditId === rowId && isSet(tHead.inputType, null) !== null) {
//             formInputProps = {
//               type: 'FormInput',
//               changeHandler: gridEditProps.onChangeFormDataInEdit,
//             };
//           }
//           // console.log('TableRowProps',rowData);
//           return (
//             <TableData
//               changeHandler={changeHandler}
//               options={tHead['options']}
//               key={tHeadIndex}
//               value={rowData.data[tHead.key]}
//               {...tHead}
//               rowId={rowId}
//               thHeadKey={tHead.key}
//               {...formInputProps}
//               gridEditProps={{ ...gridEditProps, tableHeaders: tableHeaders, rowData: rowData }}
//             />
//           );
//         })}
//       </Wrap>
//     </tr>
//   );
// };

// TableRow.defaultProps = {
//   gridEditProps: {
//     activeEditId: null,
//   },
// };


// const DragHandler = ({HandleWrapper,...props})=>{
//     return(
//         <HandleWrapper {...props}>
//         <svg
//           aria-hidden="true"
//           focusable="false"
//           data-prefix="fas"
//           data-icon="grip-vertical"
//           role="img"
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox="0 0 320 512"
//         >
//           <path
//             fill="currentColor"
//             d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
//           ></path>
//         </svg>
//       </HandleWrapper>
//     )
// }
// export default TableRow;
