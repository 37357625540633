
import React from 'react';

const  DashboardMessageIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 20 14.001">
  <g id="Group_118" data-name="Group 118" transform="translate(-100.24 114)">
    <path id="i" d="M.714,14A.707.707,0,0,1,0,13.3V1.237L9.545,8.944a.726.726,0,0,0,.905,0L20,1.34V13.3a.707.707,0,0,1-.714.7Zm0-14H19.285a.644.644,0,0,1,.1.021L10,7.5Z" transform="translate(100.24 -114)" fill="#002858" opacity="0.497"/>
  </g>
</svg>

);

export default DashboardMessageIcon;