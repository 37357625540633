// routes.js

import AuthContainer from "../containers/AuthContainer";
import PatientFormContainer from "../containers/PatientFormContainer";
import PatientFormList from "../containers/PatientFormContainer/PatientFormList";
import PdfResultContainer from "../containers/PdfResultContainer/PdfResultContainer";
import SelfRegisterContainer from "../containers/SelfRegisterContainer";

const defaultConfig = {
  layoutType:'Default',
  routeType:'Public'
}
const PublicRoutes = [
    {
        path: '/login/*',
        // exact: true,
        element:AuthContainer,
        // element: <AuthContainer />,
        ...defaultConfig
      },
      {
        path: "/self-register/*",
        // exact: true,
        element: SelfRegisterContainer,
        ...defaultConfig,
        routeType:'Other',
        acl: ["any"],
        // Add more properties as needed
      },
      {
        path: "/send-questionnaire/*",
        element: AuthContainer,
        ...defaultConfig,
      },
      {
        path: "/patient_form_login/*",
        exact: true,
        element: AuthContainer,
        acl: ["any"],
        layoutType: "Default",
        routeType:'Public'       
      },
      {
        path: "/patient_form/*",
        exact: true,
        element: PatientFormContainer,
        acl: ["any"],
        layoutType: "Patient Form",
        routeType:'Other'       
      },
      {
        path: "patient-form-list/patient_form/*",
        exact: true,
        element: PatientFormContainer,
        acl: ["any"],
        layoutType: "App",
        routeType:'Auth'       
      },
      {
        path: "/patient-form-list/*",
        exact: true,
        element: PatientFormList,
        acl: ["any"],
        layoutType: "App",
        routeType:'Auth'       
      },
      {
        path: "/pdf-result/*",
        exact: true,
        element: PdfResultContainer,
        acl: ["any"],
        layoutType: "Default",
        routeType:'Public'       
      },
  ];


export default PublicRoutes;