import React, { useState,useEffect } from 'react';
import {WEEKDAYS,WEEKDAYS_SHORT}  from '../../constants';
import {everyNminutes} from '../../calendarFunctions'; 
import DayViewBody from './DayViewBody';
import CalendarBodyHoc  from '../../calendarHoc';
import { isSet } from '../../../../utils/commonUtils';

const CalendarDayView = (props)=>{
    const {currentDay,todayDate} = props;
    const [currentDate,setCurrentDate] = useState(null);
    const weekDay = WEEKDAYS[currentDay.getDay()];
    // const timeList = everyNminutes(60);
    const [currentDayDetails,setCurrentDayDetails] = useState([]);
    const [timeInterval,setTimeInterval] = useState([]);

    useEffect(()=>{
          
        var weeks= [];
        const currDate = new Date(currentDay);

        let c_date = currDate.getDate();
        let c_month = currDate.getMonth() + 1;
        let c_year = currDate.getFullYear();
        if (c_date < 10) c_date = '0' + c_date;
        if (c_month < 10) c_month = '0' + c_month;
        let full_date = c_month+'/'+c_date+'/'+c_year;
        setCurrentDate(full_date);
        weeks.push({
            date:new Date(currDate),
            month:currDate.getMonth() + 1,
            day:currDate.getDay(),
            number:currDate.getDate(),
            displayName:WEEKDAYS_SHORT[currDate.getDay()]+' '+(currDate.getMonth() + 1)+'/'+currDate.getDate(),
            c_date: full_date,
            year: currDate.getFullYear(),
        });
        // console.log('currDate',weeks);
      
        setCurrentDayDetails(weeks);
        setTimeInterval(everyNminutes(30,0));
    },[currentDay])
    
    const currDate = new Date(currentDay);
    let c_date = currDate.getDate();
    let c_month = currDate.getMonth() + 1;
    let c_year = currDate.getFullYear();
    if (c_date < 10) c_date = '0' + c_date;
    if (c_month < 10) c_month = '0' + c_month;
    let c_fullDate = c_month+'/'+c_date+'/'+c_year;
    let classArr=[];
    if(new Date(todayDate).setHours(0, 0, 0, 0)===new Date(currentDate).setHours(0, 0, 0, 0)){
        classArr.push('fc-day-today');
    }
    const count = isSet(props.calendarRecords?.[c_fullDate], []).length;
    return(
        <CalendarBodyHoc {...props.calendarHocProps}>
                    <div className="fc-view fc-timeGridDay-view fc-timeGrid-view">
                        <table className="">
                            <thead className="fc-head">
                                <tr>
                                    <td className="fc-head-container fc-widget-header">
                                        <div className="fc-row fc-widget-header" style={{marginRight:'3px'}}>
                                            <table className="">
                                                <thead>
                                                    <tr>
                                                        <th className="fc-axis fc-widget-header" style={{width:'47.7188px'}}></th>
                                                        <th className="fc-day-header fc-widget-header fc-sun fc-past" data-date="2016-06-12">
                                                            <div className="row">
                                                                <div className="col d-flex justify-content-center align-items-center">
                                                                    <div></div>
                                                                    <div className="d-flex flex-column justify-content-center">
                                                                        <p className="text-md mb-0">{weekDay}</p>
                                                                        <p className={`text-md mb-0 ${classArr.join('')}`}>{c_date}</p>
                                                                    </div>
                                                                    {count > 0 ?
                                                                    <span className="badge badge-primary ml-2">{count}</span>
                                                                :<div></div>}
                                                                </div>

                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <DayViewBody 
                                {...props}
                                currentDate={currentDate}
                                timeInterval={timeInterval}
                                currentDayDetails={currentDayDetails}
                            />                            
                        </table>
                    </div>
            </CalendarBodyHoc>
    )
}

export default CalendarDayView;