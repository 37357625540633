
import React from 'react';

const  CopyIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} viewBox="0 0 18.479 21.118">
  <g id="copy_icon" data-name="copy icon" opacity="0.501">
    <path id="Path_21" data-name="Path 21" d="M90.839,153.4v-2.263A1.133,1.133,0,0,0,89.707,150H88.2v1.131a1.133,1.133,0,0,1-1.131,1.131h-8.3a1.133,1.133,0,0,1-1.131-1.131V150H76.131A1.133,1.133,0,0,0,75,151.136v14.707a1.133,1.133,0,0,0,1.131,1.131h4.525V154.53a1.133,1.133,0,0,1,1.131-1.131Z" transform="translate(-75 -147.365)" fill="#002858"/>
    <path id="Path_22" data-name="Path 22" d="M380.28.377A.378.378,0,0,0,379.9,0h-4.525A.378.378,0,0,0,375,.377v.754h5.28Z" transform="translate(-369.72)" fill="#002858"/>
    <path id="Path_23" data-name="Path 23" d="M268.237,107.14h8.3a.405.405,0,0,1,.377.377v1.508a.377.377,0,0,1-.377.377h-8.3a.405.405,0,0,1-.377-.377v-1.508A.377.377,0,0,1,268.237,107.14Z" transform="translate(-264.466 -105.255)" fill="#002858"/>
    <path id="Path_24" data-name="Path 24" d="M451,385.71H439.687a.378.378,0,0,0-.377.377v13.576a.378.378,0,0,0,.377.377H451a.378.378,0,0,0,.377-.377V386.087A.378.378,0,0,0,451,385.71Zm-2.263,10.182H441.95a.377.377,0,1,1,0-.754h6.788a.377.377,0,1,1,0,.754Zm0-1.885H441.95a.377.377,0,0,1,0-.754h6.788a.377.377,0,1,1,0,.754Zm0-1.886H441.95a.377.377,0,1,1,0-.754h6.788a.377.377,0,1,1,0,.754Zm0-1.885H441.95a.377.377,0,1,1,0-.754h6.788a.377.377,0,1,1,0,.754Z" transform="translate(-432.899 -378.922)" fill="#002858"/>
  </g>
</svg>
);

export default CopyIcon;