import React,{memo,useMemo} from 'react';
import {VIEW_TYPE_LIST} from '../constants';
import { fetchCurrentDate } from '../../../utils/DateUtils';

const CalendarViewTypes = (props)=>{

    const {changeHandler,calendarViewType,calendarOptions,calendarOptionOnChangeHandler} = props;    
	// console.log('calendarOptions',calendarOptions,JSON.stringify(VIEW_TYPE_LIST),selectedValue);
	// console.log('selectedValue',selectedValue,props);
	const selectedLabel = useMemo(() => {
		const item = VIEW_TYPE_LIST.find(item => item.id === calendarViewType);
		return item ? item.label : null;
	  }, [VIEW_TYPE_LIST, calendarViewType]);
    return(
        <div className="fc-center d-flex">

        <a className='btn-group' >
        	<OnTodayToolBar 
                changeHandler={()=>props.currentDayChangeHandler(fetchCurrentDate())}/>
        </a>

		    <div className="dropdown">
        {/* Dropdown button */}
        <button className="btn btn-primary dropdown-toggle " type="button" id="perPageDropdown" data-bs-toggle="dropdown">
		  {selectedLabel}
        </button>
        {/* Dropdown menu */}
        <div className="dropdown-menu text-sm" style={{width: "200px"}} aria-labelledby="perPageDropdown">
          {VIEW_TYPE_LIST.map((itm,itmIndex) => (
            // Dropdown items
            <span key={itmIndex} className={`dropdown-item ${calendarViewType===itm.id ? 'selected' : ''}`}  onClick={()=>changeHandler(itm.id)}>
              <div className="form-check form-check-solid">
                <label className="form-check-label">{itm.label}
                </label>
              </div>
            </span>
          ))}
		    <div key="divider" className="dropdown-divider"></div>
		   <span key={12} className={`dropdown-item ${calendarOptions.showWeekends===true ? 'selected' : ''}`}  onClick={()=>calendarOptionOnChangeHandler('showWeekends',!calendarOptions.showWeekends)}>
              <div className="form-check form-check-solid">
                <label className="form-check-label">{`Show Weekends`}
                </label>
              </div>
            </span>
        </div>
      </div>
		</div> 
    )
} 


const OnTodayToolBar = ({changeHandler})=>{
    let classArr = ["btn","btn-outline-primary","mr-2"];
    return(
        <button type="button" className={classArr.join(' ')} onClick={()=>changeHandler('CURRENT')}> Today </button>
    )
}

export default CalendarViewTypes;

// export default memo(CalendarViewTypes,(nextProps,prevProps)=>{
//     if(nextProps.selectedValue===prevProps.selectedValue || nextProps.calendarOptions===prevProps.calendarOptions){
//         return true;
//     }
// });