
import React, { Component } from 'react';
import Buttons from '../../../../../components/UI/Buttons';
import TableGrid from '../../../../../components/UI/TableGrid';
import { LabResultCalcualtion, capitalizeName, convertUnderScoreStrings, isObject, isSet, toQueryString } from '../../../../../utils/commonUtils';
import withReduxState from '../../../../../hoc/wReduxState';
import WithRoutify from '../../../../../hoc/WithRoutify';
import Employeeervice from '../../../../../services/EmployeeService';
import VaccineService from '../../../../../services/VaccineService';
import AddVaccine from '../../../AddVaccine';
import PatientChartsService from '../../../../../services/PatientChartsService';
import FileManagerService from '../../../../../services/FileManagerService';

class VaccinesTab extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders =  [
        { label: '', key: 'isActive', type: 'checkBox', changeHandler: this.checkBoxchangeHandler },
        { label: 'Patient', key: 'patient_name', },
        { label: 'System ID', key: 'system_id' },
        { label: 'Company', key: 'company' },
        { label: 'Date', key: 'visit_date' },
        { label: 'Time', key: 'visit_time' },
        { label: 'Vaccine Name ', key: 'vaccine_name' },
        { label: 'Lot', key: 'lot' },
        { label: 'Admin Site', key: 'vaccination_adminstration_site' },
        { label: 'Exp. Date', key: 'expiration_date' },
        { label: 'Series', key: 'series' },
        { label: 'Person Administrating Vaccine', key: 'person_adminstration_vaccine' },
        { label: 'Title', key: 'title' },
        { label: 'Action', key: 'action', type: 'Actions' },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: ''
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'name',
          sort_order: 'asc',
          search: {},
        }
      },
      activeLabActivity: null,
      // Your state variables go here
      isModelOpen: false,
      addOrderModelOpen:false

    }
  }

  componentDidMount() {

    this.fetchVaccineList();

    // this.fetchPhysicalList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen })
  }

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  render() {
    const {isModelOpen,activeEditId} = this.state;
    const {visitDetails}=this.props;
    return (
      <>
        {isModelOpen ? (
           <AddVaccine
           isOpen={this.state.isModelOpen}
           activeEditId={activeEditId}
           visitId={visitDetails.visit_id}
           fetchRecords={this.fetchVaccineList}
           modalToogleHandler={this.addFormHandler}
           fromChart={false}
         />
        ) : (
          <></>
        )}
         
          <div className="sh_innerPortview ">
             
             <div className="sh_cardBox h-auto shadow-none visitboxheight border mb-0">
               <div className="sh_cardHeader">
                 <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                   <h6 className="text-sm mb-0">
                    {"Vaccines"}
                   </h6>
                   <Buttons
                        title={"Add Lab"}
                        className="btn btn-icon btn-transparent-dark mr-2"
                        acl={"any"}
                        iconType={"AddIconDetailView"}
                        clickHandler={() => { this.addToogleHandler(); this.setState({ activeEditId: '' }) }}
                      />
                   
                 </div>
               </div>
               <div
                 className="sh_cardBody p-0"
                 style={{ height: "calc(100% - 120px)" }}>
                 <TableGrid {...this.state.tableRecords}/>
               </div>
             </div>
            
         </div>
      </>
    );
  }
  addToogleHandler=()=>{
    this.setState({isModelOpen:!this.state.isModelOpen})
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      search: filters,
    }
    this.fetchVaccineList(queryfilters);
  }
  clearFiltersHandler = () => {
    this.setState({
      filters: {}
    }, this.submitFiltersHandler);
  }
  fetchPhysicalList = ()=>{
    const {visitDetails,tabId} = this.props;
  let tableRows = [];
  isSet(visitDetails[tabId],[]).forEach((item,itemIndex)=>{
    const status  = item.status;
    let statusClr = 'blue';
     if(status.toLowerCase()==='passed'){
      statusClr = 'green';
    } else  if(status.toLowerCase()==='failed'){
      statusClr = 'red';
    }
    tableRows = [
      ...tableRows,
      {
        physicalIndex: itemIndex,
        isHeading: false,
        rowId: item.physical_id,
        data: {
          isActive: false,
          activity_name: item?.activity?.activity_name,
          status:{
            title:status,
            value:<span class={`badge bg-${statusClr}-soft text-${statusClr}`}>{capitalizeName(item.status.toLowerCase())}</span>
          },
          activity_type:item?.activity?.activity_type,
          action: [
              {
                className:
                  "btn btn-icon btn-transparent-dark",
                iconType: "ActivityChart",
                acl: "any",
                title:'Chart',
                clickHandler:()=>this.editHandler(item.id)
                // href:`assign-activities/${item.mapping_id}`,
               
              },
            {
              className:
                "btn btn-icon btn-transparent-dark",
              iconType: "Remove",
              acl: "any",
              title: "Delete",
              isDelete:true,
              clickHandler: () =>this.deleteHandler(item.mapping_id),
            }, 
          ],
        },
      },
    ];
    this.setState({
      tableRecords: {
        ...this.state.tableRecords,
        tableRows: {
          type: "default",
          data: tableRows,
        },
      },
    });
  })
  }
  fetchVaccineList = async (query = {}) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    try {
      this.props.Loader(true);
      const vaccineList = await await VaccineService.getVaccineByVisitId(this.props.visitDetails.visit_id,queryString)
      let tableData = [];
      vaccineList.forEach((item, index) => {
        tableData = [
          ...tableData,
          {
            labsIndex: index,
            isHeading: false,
            rowId: item.id,
            data: {
              isActive: false,
              patient_name: (item?.visit.employee?.first_name + " " + item?.visit?.employee?.last_name) || '--',
              system_id: item?.visit?.employee?.system_id || '--',
              visit_date: item.visit_date || '--',
              visit_time: item.visit_time || '--',
              vaccine_name: item.vaccine_name || '--',
              lot: item.lot_number,
              vaccination_adminstration_site: item.vaccination_adminstration_site || '--',
              expiration_date: item.vaccine_expiration_date,
              series: item.series,
              person_adminstration_vaccine: item.vaccine_adminstrator&&item.vaccine_adminstrator?.person_administrating_vaccine || '--',
              title: item.title || '--',
              company: item?.company?.name,
              active: 'Yes',
              action: [
                {
                  className: 'btn btn-icon btn-transparent-dark',
                  iconType: 'Search',
                  title: 'View',
                  type: 'GridEdit',
                  acl: 'any',
                  clickHandler: (rowId, data) => this.editHandler(item.id, item),
                },
                {
                  className: 'btn btn-icon btn-transparent-dark pl-1 pr-1',
                  title: 'Print',
                  acl: 'any',
                  children: (
                    <>
                      <svg onClick={() => { this.printLabel(item.id) }} fill="#000000" height="16" width="16" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64">	<path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002		C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995		c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507		c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649		c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012		C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z		 M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z" />	<path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z" />	<path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z" />	<circle cx="7.9590998" cy="21.8405495" r="2" />	<circle cx="14.2856998" cy="21.8405495" r="2" />	<circle cx="20.6121998" cy="21.8405495" r="2" />	<path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z" /></svg>
                    </>
                  )
                }
              ]
            }
          }
        ]
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            status: 200,
            type: "default",
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords:0
          },
        },
      }));
      this.props.Loader();
    } catch (e) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200
          }
        }
      });
      this.props.Loader();
    }
  }
  deleteHandler = async (labId,visit_id) => {
    const { Loader, Toast } = this.props;
    try {

      await Employeeervice.deleteEmployeeVisitLabById(visit_id,labId)
      .then((response) => {
          Toast.success("Lab Deleted Successfully");
        })
        .catch(err => {})
        .finally(() => {
          this.fetchVaccineList();
        });
    } catch (e) {

    }

  }
  editHandler = async(rowId) => {
      this.setState({
        activeEditId: rowId,
    },this.addToogleHandler());
  }
  printLabel = async (id, pdfname = 'vaccineResult') => {
    const response = await PatientChartsService.getVaccinePDF(id);
    const pdfData = {
      file_content: response,
      original_name: `${pdfname}`,
      mime_type: 'application/pdf'
    }
    FileManagerService.printPdf(pdfData);
  }
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  
}

export default withReduxState(WithRoutify(VaccinesTab));