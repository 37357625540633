// // TableBody.js
import React from 'react';
// import { SortableElement } from "react-sortable-hoc";
import { isSet } from '../../../utils/commonUtils';
import TableRow from './TableRow';
import Wrap from '../../../hoc/Wrap';
// import Wrap from '../../../hoc/Wrap';

const TableBody = ({ tableRows,tableHeaders,gridEditProps,collection,isDragging,...props}) => {
  // const HocComponent = (isSortable)?SortableItem:TableRow;
  
  return (
    <tbody>
      {isSet(tableRows.data,[]).length===0?
      <tr><td colSpan={tableHeaders.length} align='center'>No Record exists</td></tr>:
      isSet(tableRows.data,[]).map((rowData, index) => {
        const TrHoc = (isSet(rowData.TrHoc,null)!==null)?rowData.TrHoc:Wrap;
        return(
          <TrHoc 
            key={isSet(rowData.attributes,{}).index}
            indexKey={isSet(rowData.attributes,{}).index}
            isSortable={(rowData.TrHoc)?true:false}
            rowIndex={index} 
            tableHeaders={tableHeaders} 
            type={tableRows.type} 
            rowData={rowData} 
            gridEditProps={gridEditProps}
            collection={collection}
            recordId={rowData.rowId}
            {...isSet(rowData.attributes,{})}
            {...rowData.handlers}
            index={isSet(isSet(rowData.attributes,{}).index,index)}
            >
            <TableRow 
              // indexKey={isSet(rowData.attributes,{}).index}
              isSortable={(rowData.TrHoc)?true:false}
              // rowIndex={index} 
              // index={index}
              tableHeaders={tableHeaders} 
              type={tableRows.type} 
              rowData={rowData} 
              gridEditProps={gridEditProps}
              collection={collection}
              recordId={rowData.rowId}
              // {...isSet(rowData.attributes,{})}
              handlers={rowData.handlers}
              
               />
            </TrHoc>
        )
      })}
    </tbody>
  );
};
// const SortableItem = SortableElement(props => <Wrap {...props}>{props.children}</Wrap>);
TableBody.defaultProps = {
  tableKey:'def',
  TrHoc:Wrap,
  collection:'default'
}
export default TableBody;
