import React from "react";

const LogoutIcon = (props) => {

    return (
    <>      
      
      <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} fill="none" viewBox="0 0 24 24" id="logout"><path fill="#90CAEA" fill-rule="evenodd" d="M3 13H15V11H3V13Z" clip-rule="evenodd"></path><path fill="#90CAEA" fill-rule="evenodd" d="M5.79282 7.79291L2.29282 11.2929C1.90229 11.6834 1.90229 12.3166 2.29282 12.7071L5.79282 16.2071L7.20703 14.7929L4.41414 12L7.20703 9.20712L5.79282 7.79291Z" clip-rule="evenodd"></path><path fill="#3747D6" fill-rule="evenodd" d="M8 4C8 3.44772 8.44772 3 9 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H9C8.44772 21 8 20.5523 8 20V17H10V19H20V5H10V7H8V4Z" clip-rule="evenodd"></path></svg>
    </>
    );

};
LogoutIcon.defaultProps ={
    size:20
}
export default LogoutIcon;