// import React, { Component } from "react";

import { fetchTimeInterval, getCurrentDate, getCurrentTime, isSet, optionsToKeyValuePairs } from "../../../../../../utils/commonUtils";
import { ItentificationTypeOptions } from "../../../../../../utils/constant";

const DrugScreenPanelActivity = (panelNo)=>{
    if(panelNo===10){
        return [
            {label:'Amphetamine (AMP)',name:'amphetamine'},
            {label:'Marijuana (THC)',name:'marijuana'},
            {label:'Phencyclidine (PCP)',name:'phencyclidine'},
            {label:'Benzodiazepines (BZD)',name:'benzodiazepines'},
            {label:'Methaqualone (MTQ)',name:'methaqualone'},
            {label:'Cocaine (COC)',name:'cocaine'},
            {label:'Morphine (MOR)',name:'morphine'},
            {label:'Barbiturates (BAR)',name:'barbiturates'},
            {label:'Methadone (MTD)',name:'methadone'},
            {label:'Propoxyphene (PPX)',name:'propoxyphene'}]
    }else if(panelNo===12){
        return [
            {label:'Amphetamine (AMP)',name:'amphetamine'},
            {label:'Marijuana (THC)',name:'marijuana'},
            {label:'Phencyclidine (PCP)',name:'phencyclidine'},
            {label:'Benzodiazepines (BZD)',name:'benzodiazepines'},
            {label:'Methaqualone (MTQ)',name:'methaqualone'},
            {label:'Morphine (MOR)',name:'morphine'},
            {label:'Cocaine (COC)',name:'cocaine'},
            {label:'Nortriptyline (TCA)',name:'nortriptyline'},
            {label:'Barbiturates (BAR)',name:'barbiturates'},
            {label:'Methadone (MTD)',name:'methadone'},
            {label:'Oxycodone (OXY)',name:'oxycodone'},
            {label:'Ecstasy (MDMA)',name:'ecstasy'}]
    }else if(panelNo===13){
        return [
            {label:'Fentanyl (FEN)',name:'fentanyl'},
            {label:'Barbiturates (BAR)',name:'barbiturates'},
            {label:'Benzodiazepines (BZD)',name:'benzodiazepines'},
            {label:'Ecstasy (MDMA)',name:'ecstasy'},
            {label:'Methadone (MTD)',name:'methadone'},
            {label:'Oxycodone (OXY)',name:'oxycodone'},
            {label:'Marijuana (THC)',name:'marijuana'},
            {label:'Amphetamine (AMP)',name:'amphetamine'},
            {label:'Buprenorphine (BUP)',name:'buprenorphine'},
            {label:'Cocaine (COC)',name:'cocaine'},
            {label:'Methamphetamines (MET)',name:'methamphetamines'},
            {label:'Morphine (MOR)',name:'morphine'},
            {label:'Phencyclidine (PCP)',name:'phencyclidine'}]
    }else if(panelNo===4){
        return [
            {label:'Amphetamine (AMP)',name:'amphetamine'},
            {label:'Cocaine (COC)',name:'cocaine'},
            {label:'Morphine (MOR)',name:'morphine'},
            {label:'Phencyclidine (PCP)',name:'phencyclidine'}]
    }else if(panelNo===5){
        return [
            {label:'Amphetamine (AMP)',name:'amphetamine'},
            {label:'Marijuana (THC)',name:'marijuana'},
            {label:'Cocaine (COC)',name:'cocaine'},
            {label:'Morphine (MOR)',name:'morphine'},
            {label:'Phencyclidine (PCP)',name:'phencyclidine'}]
    }else if(panelNo===9){
        return [
            {label:'Amphetamine (AMP)',name:'amphetamine'},
            {label:'Phencyclidine (PCP)',name:'phencyclidine'},
            {label:'Benzodiazepines (BZD)',name:'benzodiazepines'},
            {label:'Methaqualone (MTQ)',name:'methaqualone'},
            {label:'Cocaine (COC)',name:'cocaine'},
            {label:'Morphine (MOR)',name:'morphine'},
            {label:'Barbiturates (BAR)',name:'barbiturates'},
            {label:'Methadone (MTD)',name:'methadone'},
            {label:'Propoxyphene (PPX)',name:'propoxyphene'}]
    }
    else{
        return [];
    }
   
}


export const DrugScreenRapidPanel = (props,panelNo,type='others')=>{
    // console.log('props',props);

    const typeOfScreenOptions = ['Pre-employment','Periodic Medical','Followup','Random','Promotion','Post Accident','Transfer','Return to Duty','Reasonable Suspicion/Cause','Diversion','Other'];
    let typeOfScreenOptionsArr = panelNo === 13? [...typeOfScreenOptions, 'Fishing License']: typeOfScreenOptions;
    let includedSection  = [];
    let includedSectionTypeOfScreen = [];
    if(type!=='EZ-Reader'){
        includedSectionTypeOfScreen =[{
                label:'Reason',
                type:'SelectList',
                name:'reason',
                id:'reason',
                required:true,
                options:optionsToKeyValuePairs(typeOfScreenOptionsArr),
                required:true
            },
            // {
            //     type:'Blank',
            //     col:'col-6',
            //     name:'blank',
            //     rules:[{rule:[{field:'reason',value:'Other',operator:'!=='}],action:'SHOW'}]
            // },
            {
                // label:'Specify Other Reason Time',
                isLabel:false,
                type:'TextArea',
                col:'col-6',
                name:'other_reason',
                placeholder:'Specify Other Reason',
                id:'other_reason',
                rules:[{rule:[{field:'reason',value:'Other',operator:'!=='}],action:'DISABLE'}],
                required:true
            }
            ,
            {
                label:"Sample ID",
                isLabel:true,
                type:'',
                col:'col-6',
                name:'sample_id',
                placeholder:'Sample ID',
                id:'sample_id',
            }
            
        ];
        includedSection=[ 
        {
            label:'Signs of Adulteration',
            note:'specimen temperature must be read within 4 minutes of collection.',
            col:'col-12',
            fields:[{
                label:'Signs of adulteration include:',
                notes:['unusual color','unusual smell','foreign objects in specimen','unusual behavior by donor'],
                type:'YesOrNoTable',
                options:[{label:'Yes',value:1},{label:'No',value:0}],
                defaultBtnLabel:'Set all to Normal',
                defaultValues:{new_adulteration_signs:0,sufficient_quantity:1,temperature_range:1},
                // computedHandler:()=>computedSignsOfAdulteration('sign_of_adulteration','in_temp_range'),
                list:[
                    {label:'Any sign of adulteration?',name:'new_adulteration_signs'},
                    {label:'In temperature range?',name:'temperature_range'},
                    {label:'Is there a sufficient quantity?',name:'sufficient_quantity'},
                    // {label:'In temperature range? (2nd read)?',name:'temperature_range_second_read'}
                ],
            },
        ]
        }];
    }
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails 
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[{
            label:'Testing Details',
            fields:[{
                label:'Arrival Date',
                type:'Date',
                name:'arrival_date',
                required:true,
            },
            {
                label:'Arrival Time',
                name:'arrival_time',
                type: "SelectSearchList",
                placeholder:'Time',
                isAsync:true,
                options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                required:true,
            },
            // {
            //     label:'Alternate ID',
            //     type:'TextInput',
            //     name:'alternate_id',
            // },
            {
                label:'Donor ID',
                type:'SelectList',
                options:ItentificationTypeOptions,
                name:'donor_id',
                required:true,
            },
            {
                label:'Donor ID Number',
                type:'TextInput',
                name:'donor_id_number',
                required:true,
            },
            props.formData.ez_file?{
                label:'Test Image',
                type:'FileType',
                name:'ez_file',
                file:props.formData.filemanager,
                required:true,
            }
            :
            null,
            {
                label:'Chain of Custody No',
                type:'TextInput',
                name:'chain_of_custody_no'
            },...includedSectionTypeOfScreen]
        },
        {
            label:'Activity Details - '+props.activityName,
            fields:[{
                label:'Result Date',
                type:'Date',
                name:'result_date',
                required:true,
            },
            {
                label:'Result Time',
                type:'TextInput',
                name:'result_time',
                placeholder:'Time',
                type: "SelectSearchList",
                isAsync:true,
                options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                required:true,
            },{
                col:'col-12',
                label:'',
                defaultBtnLabel:'Set all to Negative',
                defaultValues:DrugScreenPanelActivity(panelNo).reduce((acc, itm) => {
                    acc[itm.name] = 'NEGATIVE';
                    return acc;
                }, {}),
                type:'YesOrNoTable',
                options:[{label:'NOT STARTED',value:'NOT STARTED'},{label:'POSITIVE',value:'POSITIVE'},{label:'NEGATIVE',value:'NEGATIVE'}],
                list:DrugScreenPanelActivity(panelNo),
                
            },
        ] 
        },
        ...includedSection,
        {
            label:'Shipping Information',
            col:'col-6',
            fields:[{
                label:'Shipping Service',
                type:'SelectList',
                options:isSet(props.lookupsOptions['shippingService'],[]),
                name:'shipping_service',
            },
            {
                label:'Shipping Billing No.',
                type:'TextInput',
                name:'shipping_billing_no',
            },
            {
                label:'Notes',
                col:'col-6',
                type:'TextArea',
                name:'notes',
            },
            {
                label:'Suggested Result',
                type:'TextInput',
                disabled:true,
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'suggested_status',
            },
            {
                type:'Blank',
                name:'blank'
            },
            {
                label:'Final Determination Result',
                type:'SelectList',
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'status',
                required:props.type === 'Drug Screen - EZ-Reader' ? true : false
            },
          
        ]
        },]
    }];
    
    const defaultValue = {
        arrival_date:getCurrentDate(),
        arrival_time:getCurrentTime(),
        result_date:getCurrentDate(),
        result_time:getCurrentTime(),
        donor_id:isSet(props.employeeDetails.identification_type,''),
        donor_id_number:isSet(props.employeeDetails.identification_number,''),
        dot_agency:'FMCSA',
        collection_type:'Single',
        reason:(panelNo === 13)?'Fishing License':'',
        status:'Not Started',
        suggested_status:'Not Started',
        ...DrugScreenPanelActivity(panelNo).reduce((acc, itm) => {
            acc[itm.name] = 'NOT STARTED';
            return acc;
        }, {})
    };
    const buttons = {
        label:true,
        result:true
    }
    return {
        charts,
        defaultValue, 
        buttons,
        computationFunction: (formData,key) => computationRules(panelNo, type,formData,key)
    };
}

const computationRules = (panelNo,type,formData,key='')=>{
    const DrugPannelValues = DrugScreenPanelActivity(panelNo).reduce((acc, itm) => {
        acc[itm.name] = formData[itm.name];
        return acc;
    }, {});
    let updatedOtherReason=formData['other_reason'];
    if (key=='reason'&&formData[key]!='Other') {
        updatedOtherReason='';
    }
    const anyNotStarted = Object.values(DrugPannelValues).some(value => value === 'NOT STARTED');
    const anyPositive = Object.values(DrugPannelValues).some(value => value === 'POSITIVE');
    const allNegative = Object.values(DrugPannelValues).every(value => value === 'NEGATIVE');
    const allNonStarted = Object.values(DrugPannelValues).every(value => value === 'NOT STARTED');

    const adulteration = {new_adulteration_signs:0,sufficient_quantity:1,temperature_range:1};
    // console.log('adulteration',adulteration);

    let adulterationEqual = Object.entries(adulteration).every(([key, value]) => formData[key] === value);
    // console.log('adulterationEqual',adulterationEqual);
    let adulterationChecked = isSet(formData.new_adulteration_signs) ||isSet(formData.sufficient_quantity) ||isSet(formData.temperature_range); 

    if(type==='EZ-Reader'){
        adulterationEqual = true;
    }
    let status = '';

    // Chart Status Computation
    if (anyPositive) {
        status = 'Pending MRO';
    } else if (allNegative && adulterationEqual) {
        status = 'Passed';
    } else if (adulterationChecked ||  adulterationEqual===false) {
        status = 'Pending';
    } else if (adulterationEqual===false || !adulterationChecked) {
        status = 'Not Started';
    }
    return (key==='status')?{}:{'status':status,'suggested_status':status,'other_reason':updatedOtherReason};
}



