import React, {useState } from 'react';
import PropTypes from 'prop-types';
import { isSet } from '../../../utils/commonUtils';
import Icons from '../Icons';
import withReduxState from '../../../hoc/wReduxState';
import { acl_check } from '../../../utils/aclUtils';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import Alert from '../ConfirmBox/Alert';

const Button = (props) => {
  const { label, iconType, iconDropDownList,iconColor,iconSize, className, clickHandler, disabled, acl, title, profile, accessType, href = '#!', children,extraAttr,disableButtonTitle } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let hasAccess = true;
  try {
    hasAccess = acl_check(profile.permissions, isSet(acl, ''));
  } catch (e) {}

  if (accessType === 'Hide' && hasAccess === false) {
    return false;
  }
  
  let classNameArr = [className];
  let buttonStyle = {
    cursor:'pointer'
    // cursor: isSet(hasAccess, true) ? 'pointer' : 'not-allowed',
    // opacity: isSet(hasAccess, true) ? 1 : 0.5,
  };

  let iconObj = {
    type: iconType,
  };

  if (iconColor !== null) {
    iconObj = {
      color: iconColor,
    };
  }

  if (iconSize) {
    iconObj = {
      ...iconObj,
      size:iconSize,
    };
  }
  // if (disabled === true) { 
  //   buttonStyle = {
  //     cursor: 'not-allowed',
  //     opacity: 0.5,
  //   };
  // }

  if(isSet(hasAccess,true)===false || disabled === true){
    classNameArr.push('no-access');
   }
 
  // useEffect(()=>{
  //   return()=>{
  //     document.body
  //   }
  // },[])
  // if (disabled === true) {
  //   buttonStyle = {
  //     cursor: 'not-allowed',
  //     opacity: 0.5,
  //   };
  // }
  const buttonClickHandler = () => {
    if (props.isDelete === true) {
      setShowDeleteModal(true);
    } else {
      clickHandler();
    }
  };

  const handleDelete = () => {
    // Perform deletion logic here
    setShowDeleteModal(false); // Close the modal after deletion
    if (clickHandler && typeof clickHandler === 'function') {
      clickHandler();
    }
  };
  let btnHref = {};
  let LinkComponent = 'a';
  if(isSet(href,null)!==null && isSet(hasAccess,true)){
    btnHref = {
      to:href,
      state:isSet(props.hrefState,null),
    }
    LinkComponent = Link;
  }
  return (
    <>

{showDeleteModal && (
    createPortal(
        <Alert
          onDelete={() => handleDelete()}
          onClose={() =>setShowDeleteModal(false)}
          {...(props.contentTitle ? { contentTitle: props.contentTitle } : {})}       
          {...(props.content ? { content: props.content } : {})}
          {...(props.s ? { type: props.contentType } : {})}
        />,  document.body)
      )}
      <LinkComponent
        className={classNameArr.join(' ')}
        onClick={hasAccess ? buttonClickHandler : () => {}}
        title={hasAccess ? title:isSet(disableButtonTitle,'No permission')}
        style={buttonStyle}
        {...btnHref}
        {...extraAttr}
      >
        {iconType !== null ? <Icons type={iconType} {...iconObj} iconDropDownList={hasAccess?iconDropDownList:[]}/> : null}
        {label !== null ? props.appendAddIcon?<> <Icons type={'Plus'}/>{label}</>:label : ''}
        {children && children !== null ? children : ''}
      </LinkComponent>

    </>
  );
};

Button.defaultProps = {
  clickHandler: () => {},
  iconType: null,
  accessType: 'Disabled',
  children: null,
  disabled: false,
  label: '',
  acl:'any',
  iconColor: null,
  isDelete:false,
  extraAttr:{},
  href:null,
  disableButtonTitle:'',
  iconDropDownList:[],
  appendAddIcon:false
};

Button.propTypes = {
  clickHandler: PropTypes.func,
};

export default withReduxState(Button);
