import { OK, UNPROCESSABLE_CONTENT } from '../utils/constant';
import http from './http';


const LookupService = {

  saveLookup: async (data) => {
    try {
      const response = await http.POST(`lookup-data`, data);
      return response;
    } catch (error) {
      if (error.status === UNPROCESSABLE_CONTENT && error.data) {
        return error.data
      }
      console.error('Error fetching Lookup:', error);
      // throw error;
    }
  },
  //get Lookup
  getLookup: async (query) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`lookup-data?${queryString}`);
      if (response.code === OK) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Lookup:', error);
    }
  },
  //update Lookup
  updateLookup: async (id, data) => {
    try {
      const response = await http.PUT(`lookup-data/${id}`, data);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching Insurace:', error);
      throw error;
    }
  },
  //delete Lookup
  deleteLookup: async (Id) => {
    try {
      const response = await http.DELETE(`lookup-data/${Id}`);
      return response;
    } catch (error) {
      console.error('Error fetching Lookup:', error);
    }
  },
  //get lookup by key
  getLookupByKey: async (key) => {
    try {
      const response = await http.GET(`lookup-list/${key}`);
      if (response.code === 200) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching Lookup:', error);
    }
  },
  updateLookupPosition:async(key,mappingList)=>{
    try {
      await http.PUT(`update-lookup-position/${key}`,mappingList);
    } catch (error) {
      
    }
  }
};
export default LookupService;