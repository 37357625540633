import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { unstable_useDateField as useDateField } from '@mui/x-date-pickers/DateField';
import { useClearableField } from '@mui/x-date-pickers/hooks';
import dayjs from 'dayjs';
import { isSet } from "../../../utils/commonUtils";
import { MobileDatePicker } from '@mui/x-date-pickers';
import Icons from "../../../components/UI/Icons";
import { useMediaQuery } from '@mui/material';

const CalendarIcon = () => {
  return (
    <span>
      <Icons type={'Calender'} />
    </span>
  );
};

const BrowserField = React.forwardRef((props, ref) => {
  // console.log('props--->',props);
  const {
    disabled,
    id,
    label,
    inputRef,
    InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
    error,
    focused,
    ownerState,
    sx,
    placeholder,
    handleFocus,
    handleBlur,
    isInputFocused,
    handleCalendarClick,
    ...other
  } = props;

  const handleRef = useForkRef(containerRef, ref);
  const isMobile = useMediaQuery('(max-width:600px)');

  const defaultDateFormat = 'MM/DD/YYYY';
  
  return (
    <Box
      sx={{ ...(sx || {}), display: 'flex', alignItems: 'center', position: 'relative' }}
      id={id}
      ref={handleRef}
    >
      {startAdornment}
      <input  
        ref={inputRef} 
        {...other} 
        readOnly={false}
        placeholder={isInputFocused ? defaultDateFormat : isSet(placeholder, defaultDateFormat)} 
        className='form-control form-control-solid'  
        // onFocus={handleFocus}
        onFocus={(e) => {
          handleFocus(e);
          // e.target.click();
        }}
        onBlur={handleBlur} 
        disabled={disabled}
        value={other.value ?? ''}
      />
      {!isMobile && ( // Only show the calendar icon if not on mobile
        <div 
          style={{ position: 'absolute', right: '3px', cursor: 'pointer' }} 
          onClick={handleCalendarClick}
        > 
          <CalendarIcon />
        </div>
      )}
    </Box>
  );
});

const BrowserDateField = React.forwardRef((props, ref) => {
  const { inputRef: externalInputRef, slots, slotProps, ...textFieldProps } = props;

  const {
    onClear,
    clearable,
    ref: inputRef,
    ...fieldProps
  } = useDateField({
    props: textFieldProps,
    inputRef: externalInputRef,
  });
  
  const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
    useClearableField({
      onClear,
      clearable,
      fieldProps,
      InputProps: fieldProps.InputProps,
      slots,
      slotProps,
    });
    
  const isDateValid = (val) => {
    return val && val.match(/^\d{2}\/\d{2}\/\d{4}$/) && dayjs(val, 'MM/DD/YYYY').isValid();
  };
  
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [showPicker, setShowPicker] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const handleFocus = (e) => {
    if(!isMobile){
      e.target.click();
      setIsInputFocused(true);
      setCursorPosition(e.target);
    }
    
  };
   const setCursorPosition = (inputElement) => {
    setTimeout(() => {
        if (inputElement && inputElement.setSelectionRange) {
        inputElement.setSelectionRange(0, 2);
      }
    }, 50);
  };


  

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const handleCalendarClick = () => {
    setShowPicker(true);
  };

  const handleClosePicker = () => {
    setShowPicker(false);
  };
  // console.log('props01',props);
  return (
    <>
      <BrowserField
        ref={ref}
        inputRef={inputRef}
        {...processedFieldProps}
        isInputFocused={isInputFocused}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        handleCalendarClick={handleCalendarClick}
        value={isInputFocused ? processedFieldProps.value : isDateValid(processedFieldProps.value) ? processedFieldProps.value : ''}
        InputProps={ProcessedInputProps}
      />
      {showPicker && (
        <MobileDatePicker
          {...props}
          slotProps={{toolbar:true}}
          slots={{ field: () => <></> }} 
          onChange={props.onChange}
          onClose={handleClosePicker}
          open={showPicker}
          defaultValue={new Date()}
        />
      )}
    </>
  );
});

const BrowserDatePicker = React.forwardRef((props, ref) => {
  // console.log('props02',props);
  return (
    <DatePicker
      ref={ref}
      {...props}
      allowInput
      slots={{ field: BrowserDateField, ...props.slots}}
    />
  );
});

const DatePick = (props) => {
  const {value, changeHandler, disabled, required, readOnly, placeholder, disableFuture} = props;
  
  const onChangeHandler = (_e, e) => {
    const formattedDate = dayjs(_e).format('MM/DD/YYYY');
    changeHandler(formattedDate, {e});
  }

  const reverseFormattedDate = (formattedDate) => {
    let momentDate = '';
    try {
      momentDate = dayjs(formattedDate, 'MM/DD/YYYY');
    } catch (e) {}
    return isNaN(momentDate) ? '' : momentDate;
  };

  const reverseReadOnlyDate = (formattedDate) => {
    if (!formattedDate) { return ''; }
    const parsedDate = dayjs(formattedDate, 'MM/DD/YYYY');
    if (!parsedDate.isValid()) { return ''; }
    return parsedDate.format('MM/DD/YYYY');
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2', // Customize your primary color here
      },
      secondary: {
        main: '#dc004e', // Customize your secondary color here
      },
    },
  });

  return (
    <>
      {(disabled === true || readOnly === true) ?
        <input type="text" disabled={disabled} readOnly={readOnly} value={reverseReadOnlyDate(value)} className='form-control' />
        :
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <BrowserDatePicker
              slotProps={{
                field: { clearable: false ,required:required,placeholder:placeholder },
              }}
              value={reverseFormattedDate(value)}
              onChange={(v, e) => onChangeHandler(v, e)}
              disableFuture={disableFuture}
            />
          </ThemeProvider>
        </LocalizationProvider>
        
      }
    </>
  );
}

DatePick.defaultProps = {
  disabled: false,
  readOnly: false,
  disableFuture: false
}

export default DatePick;
