import axios from 'axios';
import AuthService from './AuthService';
import { useEffect, useMemo } from 'react';
import { success,error,warning, resetSessionTimeout } from '../store/CommonSlice';
// import { _t } from '../utils/i18nUtils'; SESSION_EXPIRE_MESSAGE
import { FORBIDDEN, NETWORK_ERROR, NETWORK_ERROR_MESSAGE, OK, TOO_MANY_REQUEST, TOO_MANY_REQUEST_ERROR_MESSAGE, UNAUTHORIZED } from '../utils/constant';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';


// const API_URL = process.env.REACT_APP_API_URL;
const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEVELOPMENT_URL : process.env.REACT_APP_API_PRODUCTION_URL;

//Default Headers
let headers = {
  'Content-Type': 'application/json',
};



const configAxios = axios.create({
    baseURL:  API_URL,
    headers: headers, 
});


const isTokenExpired = (token) => {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decoded.exp < currentTime; // Check if token is expired

    } catch (error) {
        console.error('Failed to decode token', error);
        return true; // Consider token expired if it cannot be decoded
    }
};

// REFRESH ACCESS TOKEN ONCE EXPIRED
const refreshAccessToken = async () => {
	const token = AuthService.getRefreshToken();
	try {
        const response = await axios.post(`${API_URL}refresh-token`, { refresh_token: token }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if(response.status===OK){
            const resData = response.data;
            if(resData.code===OK){
                const responseData = resData.data;
                AuthService.storeToken(responseData.access_token);
                AuthService.storeRefreshToken(responseData.refresh_token);
            }
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
    }
  };
  
const requestHandler = async (request,dispatch) => {
    //Headers
    //Bearer Token
    if(AuthService.isAuthenticated()){
        let bearerToken = AuthService.getToken();
        // console.log('bearerToken',bearerToken);
        if(isTokenExpired(bearerToken)){
            // console.log('expired');
            try{
                await refreshAccessToken();
                bearerToken = AuthService.getToken();
            }catch(e){

            }
        }
        request.headers  = {
            ...request.headers,
            'Authorization': `Bearer ${bearerToken}`,
        }
        dispatch(resetSessionTimeout(process.env.REACT_APP_SESSION_TIMEOUT));
    }
    request.params = {
        // ...headers
        // access_token: accessToken
    }
    return request;
};

const responseHandler = (response,dispatch) => {
    if (response.status === UNAUTHORIZED) {
        // window.location = '/login';
    }
    dispatch(resetSessionTimeout(process.env.REACT_APP_SESSION_TIMEOUT));
    return response;
};

//Request Error Handler
const requestErrorHandler = error => {
    return Promise.reject(error);
};

//Response Error Handler
const responseErrorHandler = (error,Toast,navigate) => {
    // console.log("responseErrorHandler",error);
    // handle the response error
    // console.log('reserror',error);
    /*1021 - Issue fix --start */
    // console.log('errorInterceptors',error);
    // Toast('error', 'Network error occurred. Please check your internet connection.');
    try{
        if(error.response && error.response.data.error==="invalid_token"){
            // const {dispatch} = store;
            // console.log('invalid token');
            // localStorage.removeItem('access_token');
            // localStorage.removeItem('refresh_token');
            // localStorage.removeItem('user');
            // dispatch(failMessage(error.response.data.message));
            // document.location.href ='/login';
        }
        else if(error.response && (error.response.status===FORBIDDEN)){
            // console.log(error.response);
            // const {dispatch} = store;
            // dispatch(failMessage(error.response.data.message));
            //For checking session is expired or not and redirect to login page.
            
        }
        else if(error.code===NETWORK_ERROR){
            Toast.error((NETWORK_ERROR_MESSAGE));
        }
        else if(error.status===TOO_MANY_REQUEST){
            Toast.error((TOO_MANY_REQUEST_ERROR_MESSAGE));
        }
        else if (error.status === UNAUTHORIZED && error.statusText === 'Unauthenticated') {
            // Toast.error((SESSION_EXPIRE_MESSAGE));
            // AuthService.sessionExpired();
            // navigate('/login');
        }
        else if (error?.response.status === UNAUTHORIZED && error?.response?.data?.message === 'Unauthenticated.') {
            // Toast.error((SESSION_EXPIRE_MESSAGE));
            // AuthService.sessionExpired();
            // navigate('/login');
        }
    }catch(e){

    }
    if(error.response !== undefined){
        return Promise.reject(error.response);
    }else{
        return Promise.reject(error);
    }
        /*1021 - Issue fix --end */

};
// configAxios.interceptors.request.use(
//     (request) => requestHandler(request),
//     (error) => requestErrorHandler(error)
// );

// configAxios.interceptors.response.use(
//     (response) => responseHandler(response),
//     (error) => responseErrorHandler(error)
// );

const  AxiosInterceptor =({children})=> {
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const Toast = useMemo(() => ({
        success: (message) => dispatch(success(message)),
        error: (message) => dispatch(error(message)),
        warning: (message) => dispatch(warning(message)),
      }), [dispatch]);
      configAxios.interceptors.request.use(
        (request) => requestHandler(request,dispatch),
        (error) => requestErrorHandler(error)
    );
    
    configAxios.interceptors.response.use(
        (response) => responseHandler(response,dispatch),
        (error) => responseErrorHandler(error,Toast,navigate)
    );
    useEffect(() => {
        const requestInterceptorId = configAxios.interceptors.request.use(
            (request) => requestHandler(request,dispatch),
            (error) => requestErrorHandler(error)
        );
        
        const responseInterceptorId = configAxios.interceptors.response.use(
            (response) => responseHandler(response,dispatch),
            (error) => responseErrorHandler(error,Toast,navigate)
        );
        const ejectInterceptors = () => {
            configAxios.interceptors.request.eject(requestInterceptorId);
            configAxios.interceptors.response.eject(responseInterceptorId);
          };
        //   Unmount
        return () => ejectInterceptors();

    }, [Toast, dispatch, navigate]);
    
    return children;
}
export { AxiosInterceptor };

export default configAxios;