import { OK } from '../utils/constant';
import http from './http';

const OrganizationService = {
    saveOrganization:async (data)=>{
      try {
        const response = await http.POST(`organizations`,data)
            if((response.code===OK || response.code===OK) && response.data){
              return response;
            }else{
              return response;
            }
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
      }  
    },
    //get organization
    getOrganization:async(query='')=>{
        try {
          const queryString = new URLSearchParams(query).toString();
            const response = await http.GET(`organizations?${queryString}`);
            if(response.code===OK && response.data){
                return response.data;
            }
          } catch (error) {
            console.error('Error fetching user:', error);
            if(error.status){
              const resData = error.data;
              if(resData.code && resData.data){
                  return resData;
              }
            }
            
            // throw error;
          } 
    },
    getOrganizationById:async(orgId)=>{
      const response = await http.GET(`organizations/${orgId}`);
      // console.log('response',response);
      if(response.code===OK && response.data){
        return response.data;
      }
    },
    //update organization
    updateOrganization:async (id,data)=>{
      try {
        const response = await http.PUT(`organizations/${id}`,data);
        if(response.code===200 && response.data){
          const resData = response;
          return resData;
        }else{
          return response;
        }
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
    //delete organization
    deleteOrganization:async (organizationsId)=>{
      try {
        const response = await http.DELETE(`organizations/${organizationsId}`);
        return response;
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
    storeOrgId: (id) => {
      localStorage.setItem('organization_id', id);
    },
    removeOrgId: () => {
      localStorage.removeItem('organization_id');
    },
    getOrgId: () => {
      // Remove the id from storage
      return localStorage.getItem('organization_id');
    },
    storeTimeZone:async(orgId)=>{
      let timeZone='';
        const response = await http.GET(`organizations/${orgId}`);
        if (response&&response.data) {
          let responseData=response.data;
          if (responseData.time_zone) {
            timeZone=responseData.time_zone;
          }
        }
        console.log('timeZone',timeZone);
        
        localStorage.setItem('organization_timezone',timeZone );
      },
      getOrgTimeZone:()=>{
        return localStorage.getItem('organization_timezone') || Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    };
  
  export default OrganizationService;