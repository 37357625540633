import React from 'react';
import MonthView from './MonthView';
import WeekView from './WeekView';
import DayView from './DayView';

const CalendarBody = (props)=>{
    const {calendarViewType} = props;

    switch (calendarViewType) {
        case "MONTH":
            return <MonthView {...props}/>   
        case "WEEK":
            return <WeekView {...props}/>   
        case "DAY":
            return <DayView {...props}/>        
        default:
            return <MonthView {...props}/>;
    }
}
export default CalendarBody;