const selfRegister = {
    // Self Register Common Fields Step-1
    Next: 'Next',
    BasicDetails: 'Basic Details',
    PatientDetails: 'Patient Details',
    Verification: 'Verification',
    Previous: 'Previous',
    
    // Self Register Form Fields Step-1
    OrganizationList: 'Organization',
    CompanyList: 'Company',
    ClinicList: 'Clinic',
    ClinicLocation: 'Clinic Location',
    FirstNamePh: 'First Name (person being tested)',
    LastNamePh: 'Last Name (person being tested)',
    CellPhonePh: 'Cell Phone (for results delivery)',
    DateOfBirth: 'Date of Birth',
    Age: 'Age',
    FirstName: 'First Name',
    LastName: 'Last Name',
    CellPhone: 'Cell Phone',

    // Self Register Form Fields Step-2
    PatientEmail: "Patient Email",
    Ethnicity: "Ethnicity",
    Race: "Race",
    Address: "Address",
    SocialSecurityNumber: "Social Security Number",
    IdentificationType: "Identification Type",
    IdentificationNumber: "Identification Number",
    EmployerName: "Employer Name",
    InsuranceName: "Insurance Name",
    InsuranceCardFront: "Insurance Card (Front)",
    InsuranceCardBack: "Insurance Card (Back)",
    IDPicture: "ID Picture",
    MemberNumber: "Member Number",
    GroupNumber: "Group Number",
    Pharmacy: "Pharmacy",
    PharmacyAddress: "Pharmacy Address",
    PharmacyPhone: "Pharmacy Phone",
    GuardianName: "Full Name of Guardian/Parent",
    GuardianPhone: "Guardian Phone Number",
    HearUsFrom: "How did you hear about us",
    PreRegistrationCode: "Pre-Registration Code",
};

export default selfRegister;
