import { isSet } from "../../../utils/commonUtils";

const RadioButton = (props) => {
  const {
    className,
    value,
    style,
    name,
    changeHandler,
    id,
    readOnly,
    disabled,
    options,
    labelStyle,
    failedValue  
  } = props;

  const onChangeHandler = (e, val) => {
    changeHandler(val); // Call the change handler with the selected value
  };
  
  return (
    <>
    {options.map((item, itemIndex) => {
      let appendStyles = {};
  
      if (
        isSet(failedValue, null) !== null &&
        failedValue.indexOf(isSet(item.value, item)) >= 0 &&
        failedValue.indexOf(value) === failedValue.indexOf(isSet(item.value, item))
      ) {
        appendStyles = { backgroundColor: 'red', borderColor: 'red' };
      }
  
      return (
        <div className={isSet(item.className, 'form-check form-check-inline d-inline-flex')} key={itemIndex}>
          <input
            className={className}
            type="radio"
            checked={value === isSet(item.value, item)}
            style={{
              ...style,
              ...appendStyles,
              ...(value === isSet(item.value, item) ? isSet(item.inputStyle, style) : {}),
            }}
            name={name}
            id={id + '_' + itemIndex}
            readOnly={readOnly}
            disabled={readOnly}
            onChange={(e) => onChangeHandler(e, isSet(item.value, item))}
          />
  
          <label
            className={'form-check-label-text font-weight-bold pl-1'}
            style={value === isSet(item.value, item) ? isSet(item.labelStyle, isSet(labelStyle, {})) : isSet(labelStyle, {})}
            htmlFor={name + '_' + itemIndex}
          >
            {isSet(item.label, item)}
          </label>
        </div>
      );
    })}
  </>
  );
};

RadioButton.defaultProps={
  className:'form-check-input',
  readOnly:false,
  disabled:false,
  failedValue:null
}
export default RadioButton;