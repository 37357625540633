import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { preventNonNumericalInput } from '../../../utils/commonUtils';

const ZipCodeInput = (props) => {
  const { required, value,disabled,id } = props;
  const handleZipCodeChange = (e) => {
    let newValue = e.target.value.replace(/\D/g, '');

    // Limit input to a maximum of 9 digits
    if (newValue.length > 9) {
      newValue = newValue.slice(0, 9);
    }
   

    if (props.changeHandler) {
      props.changeHandler(newValue, {e});
    }
  };
  let  maskedValue = value;
  if (value.length > 5) {
      maskedValue = `${value.slice(0, 5)}-${value.slice(5, 9)}`;
  }
  return (
    <input
      type="text"
      id={id}
      value={maskedValue}
      required={required}
      placeholder={props.placeholder}
      name={props.name ? props.name : ''}
      className={props.className ? props.className : ''}
      onChange={handleZipCodeChange}
      onKeyUp={(e) => preventNonNumericalInput(e)}
      disabled={disabled}
    />
  );
};

ZipCodeInput.defaultProps = {
  value: '',
  name: '',
  id:'',
  placeholder: 'Enter ZIP code',
  className: 'form-control form-control-solid',
  required: false,
  disabled:false
};

ZipCodeInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  changeHandler: PropTypes.func,
};

export default ZipCodeInput;