import { createSlice} from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
import i18n from '../i18n/config';


export const SessionSlice = createSlice({
  name: 'session',
  initialState: {
    profileDetails: {},
    profileStateList:[],
    access_token:null,
  },
  reducers: {
    setProfileDetails: (state,action) => {
      state.profileDetails = {
        ...state.profileDetails,
        [action.payload.key]: action.payload.value,
      };
    },
    removeProfile: (state,action) => {

      let updateProfile  = state.profileDetails;
      if (updateProfile.hasOwnProperty(action.payload.key)) {
        // Remove the object property with the specified key
        delete updateProfile[action.payload.key];
      }
      const keys = Object.keys(updateProfile);
      if(keys.length===0){
        state.profileDetails = updateProfile;
      }else{
        const lastKey = keys[keys.length - 1];
        AuthService.storeProfileId(updateProfile[lastKey].id);
        i18n.changeLanguage(updateProfile[lastKey]?.preferred_language || 'en');
        state.profileDetails = updateProfile;
        // console.log('lastKeyValues',keys,updateProfile[lastKey].accessToken);
      }
        
    },
    setProfileStateList:(state,action)=>{
      // console.log("state",state,'actions',action);
      state.profileStateList=action.payload
    }
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchProfile.pending, (state) => {
  //       // state.status = 'loading';
  //     })
  //     .addCase(fetchProfile.fulfilled, (state, action) => {
  //       // state.status = 'succeeded';
  //       console.log('action',action);
  //       state.profileDetails = action.payload;
  //     })
  //     .addCase(fetchProfile.rejected, (state, action) => {
  //       // state.status = 'failed';
  //       state.error = action.error.message;
  //     });
  // },
});

export const { setProfileDetails,removeProfile,setProfileStateList} = SessionSlice.actions;
export const activeProfileDetails = (state) => state.session.profileDetails[AuthService.getProfileId()];
export default SessionSlice.reducer;