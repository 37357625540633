import React from 'react';

const LoginIcon= ({color,size}) => {
  return (
    
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 25" id="login"><path fill="#90CAEA" fill-rule="evenodd" d="M15 13.5H3V11.5H15V13.5Z" clip-rule="evenodd"></path><path fill="#90CAEA" fill-rule="evenodd" d="M12.2072 8.29289L15.7072 11.7929C16.0977 12.1834 16.0977 12.8166 15.7072 13.2071L12.2072 16.7071L10.793 15.2929L13.5859 12.5L10.793 9.70711L12.2072 8.29289Z" clip-rule="evenodd"></path><path fill="#3747D6" fill-rule="evenodd" d="M8 4.5C8 3.94772 8.44772 3.5 9 3.5H21C21.5523 3.5 22 3.94772 22 4.5V20.5C22 21.0523 21.5523 21.5 21 21.5H9C8.44772 21.5 8 21.0523 8 20.5V17.5H10V19.5H20V5.5H10V7.5H8V4.5Z" clip-rule="evenodd"></path></svg>

  );
};
LoginIcon.defaultProps = {
  size: 20,
  color:''
};
export default LoginIcon