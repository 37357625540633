import React from 'react';
import { ItentificationTypeOptions, EthinicityOptions, raceOptions, SOCIAL_SECURITY_NUMBER_FORMAT } from "../../utils/constant";
import { isSet } from '../../utils/commonUtils';
import FormInputs from '../../components/UI/FormInputs';
import { _t } from '../../utils/i18nUtils';
import Buttons from '../../components/UI/Buttons';


const StepTwo = (props) => {


  const { changeHandler, formData, validation, isVisible ,insuranceListOptions} = props;
  



  const HearUsFromOptions = [
    {
      label: "- How Did You Hear About Us? -",
      value: "",
    }, {
      label: "Google",
      value: "Google",
    }, {
      label: "Simply Book Me",
      value: "Simply Book Me",
    }, {
      label: "Solv",
      value: "Solv",
    }, {
      label: "Friend/Family/Co-Worker",
      value: "Friend/Family/Co-Worker",
    }, {
      label: "Drive-by",
      value: "Drive-by",
    }, {
      label: "Employee Services/Work-Comp",
      value: "Employee Services/Work-Comp",
    }, {
      label: "Other",
      value: "Other",
    },
  ];

  const inputFields =
    [

      {
        type: "SelectList",
        label: _t('Ethnicity'),
        placeholder: _t('Ethnicity'),
        className: "form-control form-control-solid",
        name: "ethnicity",
        options: EthinicityOptions,
        col: "col-lg-4 col-md-6 col-12",
        // required: true,
        // value: formData.ethnicity,
      },
      // {
      //   type: "SelectList",
      //   label: _t('Race'),
      //   placeholder: _t('Race'),
      //   options: raceOptions,
      //   className: "form-control form-control-solid",
      //   name: "race",
      //   col: "col-lg-4 col-6",
      //   required: true,
      //   value: formData.race,
      // },
      {
        type: "Address",
        label: _t('Address'),
        placeholder: _t('Address'),
        className: "form-control form-control-solid",
        name: "address",
        col: "col-lg-4 col-md-6 col-12",
        required: true,
        // value: formData.address,
      },
      {
        type: "Phone",
        label: _t('SocialSecurityNumber'),
        placeholder: _t('SocialSecurityNumber'),
        className: "form-control form-control-solid",
        name: "social_security_number",
        col: "col-lg-4 col-md-6 col-12",
        numberFormat:SOCIAL_SECURITY_NUMBER_FORMAT,
        maskChar:'x'
      },
      {
        type: "SelectList",
        label: _t('IdentificationType'),
        placeholder: _t('IdentificationType'),
        className: "form-control form-control-solid",
        name: "identification_type",
        options: ItentificationTypeOptions,
        col: "col-lg-4 col-md-6 col-12",
        required:true
        // value: formData.identification_type,
      },
      {
        type: "TextInput",
        label: _t('IdentificationNumber'),
        placeholder: _t('IdentificationNumber'),
        className: "form-control form-control-solid",
        name: "identification_number",
        col: "col-lg-4 col-md-6 col-12",
        required:true
        // value: formData.identification_number,
      },
      {
        type: "TextInput",
        label: _t('EmployerName'),
        placeholder: _t('EmployerName'),
        className: "form-control form-control-solid",
        name: "employer_name",
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.identification_number,
      },
      {
        type: "SelectList",
        label: _t('InsuranceName'),
        placeholder: _t('InsuranceName'),
        className: "form-control form-control-solid",
        name: "insurance_id",
        col: "col-lg-4 col-md-6 col-12",
        options:insuranceListOptions,
        required:true
      },
      {
        type: "FileUpload",
        label: _t('InsuranceCardFront'),
        placeholder: "",
        className: "form-control form-control-solid",
        id: 'insurance_card_front',
        name: "insurance_card_front",
        col: "col-lg-4 col-md-6 col-12",
        value: {base64: formData.insurance_card_front},
      },
      {
        type: "FileUpload",
        label: _t('InsuranceCardBack'),
        placeholder: "",
        className: "form-control form-control-solid",
        id: 'insurance_card_back',
        name: "insurance_card_back",
        col: "col-lg-4 col-md-6 col-12",
        value: { base64: formData.insurance_card_back},
      },
      {
        type: "FileUpload",
        label: _t('IDPicture'),
        placeholder: "",
        className: "form-control form-control-solid",
        id: 'id_picture',
        name: "id_picture",
        value: { base64: formData.id_picture},
        col: "col-lg-4 col-md-6 col-12",
        // required: true,
        // value: formData.id_picture,
      },
      {
        type: "TextInput",
        label: _t('MemberNumber'),
        placeholder: _t('MemberNumber'),
        className: "form-control form-control-solid",
        name: "member_number",
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.identification_number,
      },
      {
        type: "TextInput",
        label: _t('GroupNumber'),
        placeholder: _t('GroupNumber'),
        className: "form-control form-control-solid",
        name: "group_number",
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.identification_number,
      },
      {
        type: "TextInput",
        label: _t('Pharmacy'),
        placeholder: _t('Pharmacy'),
        className: "form-control form-control-solid",
        name: "pharmacy",
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.identification_number,
      },
      {
        type: "Address",
        label: _t('PharmacyAddress'),
        placeholder: _t('PharmacyAddress'),
        className: "form-control form-control-solid",
        name: "pharmacy_address",
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.address,
      },
      {
        type: "Phone",
        label: _t('PharmacyPhone'),
        placeholder: _t('PharmacyPhone'),
        className: "form-control form-control-solid",
        name: "pharmacy_phone",
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.phone,
      },
      {
        type: "TextInput",
        label: _t('GuardianName'),
        placeholder: _t('GuardianName'),
        className: "form-control form-control-solid",
        name: "guardian_name",
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.identification_number,
      },
      {
        type: "Phone",
        label: _t('GuardianPhone'),
        placeholder: _t('GuardianPhone'),
        className: "form-control form-control-solid",
        name: "guardian_phone",
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.phone,
      },
      {
        type: "SelectList",
        label: _t('HearUsFrom'),
        placeholder: _t('HearUsFrom'),
        className: "form-control form-control-solid",
        name: "hear_us_from",
        options: HearUsFromOptions,
        col: "col-lg-4 col-md-6 col-12"
        // value: formData.identification_type,
      }


    ];


  return (
    <div className={`formbold-form-step-2 ${isVisible? 'active' : ''}`}>
 <h6 class="text-md font-weight-bold mb-3"><span class="pr-2 selflogin-icon ">
        <Buttons
        iconType="patientdetails"
        />
        </span>Patient Details</h6>
      <div className="row">
        {inputFields &&
          inputFields.map((field, index) => (
            <div className={field.col} key={index}>
              <div className="form-group">
                <label className="text-sm text-truncate mb-0 opacity-75"> {field.label} </label>
                <FormInputs
                  changeHandler={(value, e) =>
                    changeHandler(value, field.name, e)
                  }
                  value={formData?.[field.name]}
                  {...field}
                />
                {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
              </div>
            </div>
          ))}
      </div>


    </div>
  );
};

export default StepTwo;