import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WithRoutify from "../../hoc/WithRoutify";
import PatientFormService from "../../services/PatientFormService";
import Buttons from "../../components/UI/Buttons";
import ConsentForm from "./Forms/ConsentForm";
import PatientQuestion from "./Forms/PatientQuestion";
import UploadId from "./Forms/UploadId";
import UploadAdditionalDocument from "./Forms/UploadAdditionalDocument";
import FormCompletion from "./Forms/FormCompletion";
import AuthService from "../../services/AuthService";
import { isObject } from "../../utils/commonUtils";

const PatientForm = (props) => {
    const [formData, setFormData] = useState({});
    const [patientFormData, setPatientFormData] = useState({});
    const [validation,setValidation]=useState({});
    const [currentKey, setCurrentKey] = useState(null);
    const query = new URLSearchParams(useLocation().search);
    const [consentForm,setConsentForm]=useState(null);
    const patient_form_id = query.get("patient_form_id");

    const PatientForms = {
        new_patient_intake_form:ConsentForm,
        patient_medical_history: PatientQuestion,   
        upload_required_id: UploadId,
        upload_additional_documents: UploadAdditionalDocument,
        form_completion: FormCompletion
    };

    useEffect(() => {
        fetchPatientForms();
    }, []);

    const fetchPatientForms = async () => {
        try {
            props.Loader(true);
            let response = await PatientFormService.getPatientFormsById(patient_form_id);
            props.Loader(false);
            setPatientFormData(response);
            const formKey = Object.keys(PatientForms).find(key => response[key]);
            setCurrentKey(formKey || 'form_completion');
            setFormData({});
        } catch (error) {
            console.error("Error fetching patient forms:", error);
        }
    };
    console.log('consetform',consentForm);

    const onChangeHandler = (name, value) => {
        console.log('name',name,'value',value);
        setFormData({ ...formData, [name]: value });
        setValidation({ ...validation, [name]: '' });
    };

    const bulkOnChangeHandler = (data) => {
        setFormData(data);
    };
    // console.log('formData',formData);

    const onSubmitHandler = async () => {
        try {
            let payload;
            const headers = {};
    
            if (['upload_required_id', 'upload_additional_documents'].includes(currentKey)) {
                // if (!formData.file) return ;
                payload = new FormData();
                payload.append('file', formData.file);
                payload.append('employee_id', patientFormData.employee_id);
                payload.append('document_type', currentKey);
                payload.append('key', currentKey);
                payload.append('patient_form_id', patient_form_id);
                headers['Content-Type'] = 'multipart/form-data';
            } else {
                payload = {
                    ...formData,
                    key: currentKey,
                    visit_id: patientFormData.visit_id,
                    employee_id: patientFormData.employee_id,
                    organization_id: patientFormData.organization_id,
                    patient_form_id: patientFormData.patient_form_id,
                };
            }
            props.Loader(true)
            const response=await PatientFormService.savePatientForm(payload, headers);
            props.Loader(false)
            console.log('response',response);
            let updateValidation = {};
             if (response.status && response.status == "ERROR") {
                if (isObject(response.data)) {
                    for (let key in response.data) {
                        updateValidation = {
                            ...updateValidation,
                            [key]: response.data[key].join(","),
                        };
                    }
                } else {
                    props.Toast.error(response.data);
                }
                if (Object.keys(updateValidation).length > 0) {
                    setValidation(updateValidation);
                    return false;
                }
            }
            fetchPatientForms();
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error as appropriate, e.g., show a notification to the user
        }
    };
    console.log('validation',validation);

    const CurrentComponent = PatientForms[currentKey];

    return (
        <>
            <div className="sh_cardBox">
                <div className="sh_cardHeader">
                    <div className=" d-flex justify-content-between">
                <h6 class="text-lg f-500 mb-0 ml-1">Patient Form</h6>
                 {
                            AuthService.getToken()?
                            <Buttons
                            className="badge back_btn"
                            label={"Back"}
                            iconType={"Back"}
                            acl={"any"}
                            href='/patient-form-list'
                            
                          />
                          :''
                        }
                    </div>
          
                </div>
                <div className="sh_cardBody p-0">
                    {CurrentComponent ? (
                        <CurrentComponent
                            consentForm={consentForm}
                            patientFormData={patientFormData}
                            formData={formData}
                            bulkOnChangeHandler={bulkOnChangeHandler}
                            onChangeHandler={onChangeHandler}
                            validation={validation}
                        />
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>            
                <div className="sh_cardFooter d-flex justify-content-center">
                    {currentKey !== 'form_completion' && (
                        <Buttons
                        clickHandler={() => onSubmitHandler()}
                        className="btn btn-primary"
                        acl={"any"}
                        label={"Submit"}
                      />
                    )}
                </div>
            </div>
        </>
    );
};

export default WithRoutify(PatientForm);
