
import {  isSet } from "../../../../../../utils/commonUtils";

export const ScreeningVP = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);

    const plates = [12,8,5,29,74,7,45,2,"no number",16,"trace a line",35,96,"trace both lines"];


    let platesArr = [];
    let setAllDefaultValues = {};
    plates.map((plate,plateIndex)=>{
        setAllDefaultValues = {
            ...setAllDefaultValues,
            ['plate_number_'+(plateIndex+1)+'']:1
        }
        platesArr = [
            ...platesArr,
            {
                label:`Plate No. ${plateIndex+1}`,
                type:'Label',
                isBold:false,
                fontBold:'100',
                col:'col-2'                
            },
            {
                label:<span style={{color:'green'}}>{plate}</span>,
                type:'Label',
                fontBold:'100',
                col:'col-2'                
            },
        {
            label:'Mark "Yes" if Correct, "No" if Incorrect',
            col:'col-8',
            type:'RadioGroup',
            name:'plate_number_'+(plateIndex+1)+'',
            id:'plate_number_'+(plateIndex+1)+'',
            options:[{label:'Yes',value:1},{label:'No',value:0}],
        },
        {
            type:'Blank',
            col:'col-4',
            rules:[{rule:[{field:'plate_number_'+(plateIndex+1)+'',value:0,operator:'==='}],action:'SHOW'}]
        },
        {
            label:'Patient Answer (if not readable)',
            type:'TextArea',
            col:'col-8',
            rules:[{rule:[{field:'plate_number_'+(plateIndex+1)+'',value:0,operator:'==='}],action:'SHOW'}],
            id:'plate_number_'+(plateIndex+1)+'_patient_answer',
            name:'plate_number_'+(plateIndex+1)+'_patient_answer',

        }
        ]
    });
    
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[
                {
                    label:'Plate',
                    type:'Label',
                    appendClass:'bg-light',
                    col:'col-2'                
                },
                {
                    label:'Answer',
                    type:'Label',
                    appendClass:'bg-light',
                    col:'col-2'                
                },
                {
                    type:'Label',
                    
                    label:<a onClick={()=>props.updateFormData({...setAllDefaultValues,...computationRules({...props.formData,...setAllDefaultValues},plates,'plate_number_1')}) } className="badge bg-blue-soft text-light cursor-pointer custom-btn" style={{cursor:'pointer'}}>
                            Set all to Yes
                        </a>,
                    appendClass:'bg-light',
                    col:'col-8',
                },
               ...platesArr
        ]
        },
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
            {
                label:'Suggested Result',
                type:'TextInput',
                disabled:true,
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'suggested_status',
            },
            {
                type:'Blank',
                name:'blank'
            },
            {
                label:'Final Determination',
                type:'SelectList',
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'status',
            },
           
           
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,plates,key)};
}



const computationRules = (formData,plates,key)=>{
    
    let status = 'Not Started'; 
    let suggestedStatus = 'Not Started'; 
    let zeroCount = 0;
    let nullFound = false;
    let isComputationReadingsOnChnaged = false;
    plates.forEach((plate, plateIndex) => {
        const value = isSet(formData[`plate_number_${plateIndex + 1}`], null);
        if(key===`plate_number_${plateIndex + 1}`){
            isComputationReadingsOnChnaged = true;
        }
        if (value === 0) {
            zeroCount++;
        } else if (value === null) {
            nullFound = true;
        }
    });
    if(isComputationReadingsOnChnaged===false){return false;}
    if (zeroCount > 2) {
        status = 'Failed';
        suggestedStatus = 'Abnormal, Deficiency';
    } else if (nullFound) {
        status = 'Pending';
        suggestedStatus = 'Pending';
    } else {
        status = 'Passed';
        suggestedStatus = 'Normal, No Deficiency';
    }
    return (key==='status')?{}:{'status':status,'suggested_status':suggestedStatus};

}