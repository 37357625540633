import React from "react";
// import Buttons from "../../../components/UI/Buttons";
import WithRoutify from "../../../hoc/WithRoutify";
import Icons from "../../../components/UI/Icons";

const MessageWidget = (props) => {
  const inputFields = [
    {
      label: "",
      name: "name",
      // value:employeeDetails['first_name'], 
    },
    {
      label: "",
      name: "",
      // value:employeeDetails['last_name'],
    },
    {
      label: "",
      name: "DOB",
      // value:employeeDetails['dob'],
    },
    // {
    //   label: "Patient Phone",
    //   name: "phone",
    //   value:formatPhoneNumberUS(employeeDetails['phone']),
    // },
  ];
  return (
    <>
    {/* {
      viewModelOpen?
      <EmployeeDetails
      employeeId={props.employeeId}
      modalToogleHandler={viewPatientDetails}
      fetchEmployeeList={()=>props.fetchEmployeeDetails(employeeId)}
      />
      :
      ''
    } */}
      <div className="sh_cardBox h-auto sh_testdetailwidget">
        <div className="sh_cardHeader p-2">
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
            <h6 className="text-md font-weight-bold mb-0">
            <span className="px-2">
              <Icons type="Schedule" size={15} />
            </span>
            Messages</h6>
            {/* <Buttons
            className="  text-xs text-info"
            label={" View More"}
            acl={'any'}
            clickHandler={viewPatientDetails}
            /> */}
          </div>
        </div>
        <div className="sh_cardBody py-0">
          <div className="row">
            {inputFields.map((field, fieldInd) => (
                <div className="col-md-12" key={fieldInd}>
                  <div className="form-group mb-0">
                    <div className="row" style={{borderBottom: '0px solid #f2f2f4'}}>
                      <div className="col-6 pt-2">
                    <label className="f-400 text-sm text-truncate mb-0 opacity-75">
                      {field.label}
                    </label>
                    </div>
                    <div className="col-6 pt-2">
                    <h6 className="font-weight-bold text-sm text-truncate mb-0" title={field.value}></h6>
                    </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default WithRoutify(MessageWidget);
