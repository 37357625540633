import { useState } from "react";
import Buttons from "../../../components/UI/Buttons";
import WithRoutify from "../../../hoc/WithRoutify";
import VisitDetailView from "../VisitDetails"; 
import Icons from "../../../components/UI/Icons";
import { getShortenedVisitCategoryName, isSet } from "../../../utils/commonUtils";
const VisitDetails = (props) => {
  const [viewModelOpen,setViewModelOpen]=useState(false);
  const {visitDetails}=props
  const viewPatientDetails = () => {
    setViewModelOpen(!viewModelOpen);
    props.fetchVisitList(props.visit_id);
  };

  return (
    <>
    {
      viewModelOpen?
      <VisitDetailView
      isOpen={true}
      visitId={props.visit_id}
      modalToogleHandler={viewPatientDetails}
      fetchDetails={()=>props.fetchVisitList(props.visit_id)}
      snapEmployeeDetails={visitDetails.employee}
      />
      :
      ''
    }
    <div className="sh_cardBox sh_visittdetailwidget h-auto">
      <div className="sh_cardHeader p-2">
        <div className="form-group d-flex mb-0 justify-content-between align-items-center">
          <h6 className="text-md  font-weight-bold mb-0">
          <span className="px-2">
              <Icons type="visitdetails" />
            </span>
            Visit Details
          </h6>
          <Buttons
            className="  text-xs text-info"
            label={" View More"}
            acl={"any"}
            clickHandler={()=>setViewModelOpen(true)}
          />{" "}
        </div>
      </div>
      <div className="sh_cardBody py-0">
        <div className="row">
          {renderVisitDetail("Visit Category",  getShortenedVisitCategoryName(visitDetails.visit_category))}
          {renderVisitDetail("Visit Date & Time", visitDetails.visit_date&&visitDetails.visit_time&&`${visitDetails.visit_date} & ${visitDetails.visit_time}`)}
          {renderVisitDetail("LOS", `${visitDetails.formatted_los_time}`)}
        </div>
      </div>
    </div>
    </>
  );
};

const renderVisitDetail = (label, value) => (
  <div className="col-md-12" key={label}>
    <div className="form-group mb-0">
     <div className="row" style={{borderBottom: '1px solid #f2f2f4'}}>
      <div className="col-6 pt-2">
      <label className="f-400 text-sm text-truncate mb-0 opacity-75">{label}</label>
      </div>
      <div className="col-6 pt-2">
      <h6 className="font-weight-bold text-sm text-truncate" title={value?value:"--"}>{isSet(value,'--')}</h6>
    </div>
    </div>
    </div>
  </div>
);

export default WithRoutify(VisitDetails);
