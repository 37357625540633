import Buttons from "../../../components/UI/Buttons";
import Progressbar from "../../../components/UI/Progressbar";
const Invoice = (props) => {
    const getProgressStyles=(value)=>{
        let pathColor='62BB46';
        if (value <= 50) {
            pathColor= '#d8423a'; // Red for 0-50
        } else if (value <= 75) {
            pathColor= '#FDA635'; // Yellow for 51-75
        } else {
            pathColor= '#62BB46'; // Green for 76-100
        }
        return {pathColor}
        
    }
    return (
        <>
            <div className="sh_cardBox sh_widgets sh_dash_invoiceht sh_patientdetailwidget" >
                <div className="sh_cardHeader">
                    <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                        <h6 className="text-md font-weight-bold mb-0">
                            <span className="px-2 open-invoice-icon">
                                <Buttons
                                    iconType='DashboardInvoice'
                                />
                            </span>
                            Open Invoices
                        </h6>
                    </div>
                </div>
                <div className="sh_cardBody py-0 ">
                    <div className="d-flex justify-content-between py-2">
                        <Progressbar
                        className={"dashboard_progress-bar"}
                           styles={getProgressStyles(20)}
                           value={20}
                        />
                        <div className="text-align-left">
                            <h6 className="text-xl font-weight-bold mb-0">
                                $0.00
                            </h6>
                            <span className="text-secondary text-xs">Current</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between py-2">
                    <Progressbar
                        className={"dashboard_progress-bar"}
                           styles={getProgressStyles(75)}
                           value={75}
                        />
                        <div className="text-align-left">
                            <h6 className="text-xl font-weight-bold mb-0">
                                $0.00
                            </h6>
                            <span className="text-secondary text-xs">30+Days</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between py-2">
                    <Progressbar
                        className={"dashboard_progress-bar"}
                           styles={getProgressStyles(100)}
                           value={100}
                        />                      <div className="text-align-left">
                            <h6 className="text-xl font-weight-bold mb-0">
                                $0.00
                            </h6>
                            <span className="text-secondary text-xs">60+Days</span>
                        </div>
                    </div>

                    <div className="dashboardtable py-0">
                        <div className="row ">
                            <div className="col-4  font-weight-bold"><a>Invoice</a></div>
                            <div className="col-4  font-weight-bold"><a>Invoice Date</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>Amount Due</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                            <div className="col-4 px-0 font-weight-bold"><a>
                                0000</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                                --</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>$0.00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                            <div className="col-4 px-0 font-weight-bold"><a>
                                0000</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                                --</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>$0.00</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                            <div className="col-4 px-0 font-weight-bold"><a>
                                0000</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                                --</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>$0.00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                            <div className="col-4 px-0 font-weight-bold"><a>
                                0000</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                                --</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>$0.00</a></div>
                        </div>
                    </div>
                </div>
                <div className="sh_cardFooter border-0 py-0 ">
                    <button className="btn dashboardbtn  btn-danger"> Pay Now</button>
                    <button className="btn dashboardbtn btn-info"> Contracted Rates</button>
                </div>
            </div>
        </>
    );

}
export default Invoice;