// import { Button } from "bootstrap";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import FormInputs from "../../components/UI/FormInputs";
import { fetchTimeInterval, getCurrentDate, getCurrentTime, getLookupByKey, isObject, isSet } from "../../utils/commonUtils";
import { VISIT_CATEGORY } from "../../utils/constant";
import Buttons from "../../components/UI/Buttons";
import VisitsService from "../../services/VisitsService";
import WithRoutify from "../../hoc/WithRoutify";

const AddFollowUp = (props) => {
  const { visitId, isOpen, modalToogleHandler, fetchRecords, Toast } = props;
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [visitCategoryOptions, setvisitCategoryOptions] = useState([]);
  useEffect(() => {
    fetchVisitCategory();
    formData.visit_date=getCurrentDate();
    formData.visit_time= getCurrentTime().toString().padStart(2, '0');
  }, []);
  const fetchVisitCategory = async () => {
    const key = await getLookupByKey(VISIT_CATEGORY);
    setvisitCategoryOptions(key);
  }
  const inputFields = [
    {
      type: "Date",
      label: "Visit Date",
      placeholder: "",
      className: "form-control form-control-solid",
      name: "visit_date",
      required: true
    },
    {
      type: "SelectSearchList",
      label: "Visit Time",
      col: 'col-6',
      placeholder: "Visit Time",
      isAsync: true,
      options: [{ 'label': getCurrentTime(), value: getCurrentTime() }],
      loadCallBack: (val, callback) => fetchTimeInterval(val, callback),
      className: "form-control form-control-solid",
      name: "visit_time",
    },
    {
      type: "SelectList",
      label: "Visit Category",
      placeholder: "",
      className: "form-control form-control-solid",
      name: "visit_category",
      options: visitCategoryOptions,
      required: true
    },
  ];
  const changeHandler = (value, fieldName) => {
    const updateFormData = { ...formData };
    const updateValidation = { ...validation };
    updateFormData[fieldName] = value;
    updateValidation[fieldName] = '';
    setFormData(updateFormData);
    setValidation(updateValidation);
  }
  const formSubmitHandler = async () => {
    let payload = { ...formData };
    payload.visit_id = visitId;
    const response = await VisitsService.createFollowUp(payload);
    let updateValidation = { ...validation };
    console.log('response', response);
    if (response.status && response.status === "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(response.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      fetchRecords();
      modalToogleHandler();
      Toast.success("Follow Up Added Successfully");
    }
  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={modalToogleHandler}
        dialogClassName="hResponsive"
        keyboard={false}
        backdrop="static"
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{"Follow Up"}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => (
                  <div className={`col-12`} key={index}>
                    <div className="form-group">
                      <label className="text-gray-900 text-md opacity-75">
                        {field.label}
                      </label>
                      <FormInputs
                        changeHandler={(val, e) => changeHandler(val, field.name, e)}
                        value={formData[field.name]}
                        {...field}
                      />
                      {isSet(validation[field.name], "") !== "" ? (
                        <span className="text-danger m-1">
                          {isSet(validation[field.name], "")}
                        </span>
                      ) : ("")}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              type='Single'
              className="btn btn-primary"
              clickHandler={() => formSubmitHandler()}
              label='Submit'
              acl='visits-create' />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
export default WithRoutify(AddFollowUp);