import LookupService from "../services/LookupService";
import OrganizationService from "../services/OrganizationService";
import CryptoJS from 'crypto-js';
import pako from 'pako';
import moment from 'moment';
// import UserService from "../services/UserService";

//CHECK IS  OBJECT OR NOT
// export const isObject = (o) => {
//     return o instanceof Object && o.constructor === Object;
// }

export const isObject = (value) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export const isArray = (value) => {
  return Array.isArray(value);
}
//CHECK IS  NUMBER OR NOT
export const isNumber = (n) => {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

//CHECK IS THE STRING IS JSON STRING OR NOT
export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
//CHECK IS DATE OR NOT
export const isDate = (sDate) => {
  if (isSet(sDate, '').toString() === parseInt(isSet(sDate, '')).toString()) return false;
  var tryDate = new Date(sDate);
  return (tryDate && tryDate.toString() !== "NaN" && tryDate !== "Invalid Date");
}

export const capitalizeFirstLetter = (text) => {
  let returnData = '';
  if (text) {
    returnData = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  return returnData;
}
//CHECK IS EMAIL OR NOT
export const isEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
// export const isEmail = (email) => {
//   return email.match(
//     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   );
// };
//EMAIL VALIDATION
export const validateEmail = (inputMail) => {
  let isValid = true;
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  if (!pattern.test(inputMail)) {
    isValid = false;
  }
  return isValid;
}

export const isSet = (val, def, type = '') => {
  const decimalPointFields = ['Currency', 'Decimal', 'Number', 'LongNumber'];
  if (decimalPointFields.indexOf(type) !== -1 && val !== undefined && val !== 'undefined' && val !== '' && val !== null) {
    if (isSet(val, '') === 0) {
      return def;
    }
    return isSet(val, '');
  } else if ((val !== undefined && val !== 'undefined' && val !== '' && val !== null && type !== 'ARRAY') || (type === 'ARRAY' && isSet(val, []).length !== 0)) {
    return val;
  }
  return def;
}

//Non Numeric Input Validation {phone number}
export const preventNonNumericalInput = (event) => {
  const keyCode = event.keyCode || event.which;

  // Allow: Backspace, Tab, Enter, Delete
  if (keyCode === 46 || keyCode === 8 || keyCode === 9 || keyCode === 13 || keyCode === 110 || keyCode === 190) {
    return;
  }

  // Ensure that it is a number and stop the keypress
  if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
    event.preventDefault();
  }
};

export const isStrongPassword = (password) => {
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);

  if (
    password.length >= minLength &&
    hasUppercase &&
    hasLowercase &&
    hasNumber &&
    hasSpecialChar
  ) {
    return []; // Password is strong
  }

  // Password is weak, construct and return a validation message
  const validationMessages = [];
  if (password.length < minLength) {
    validationMessages.push("Password must be at least 8 characters long.");
  } else
    if (!hasUppercase) {
      validationMessages.push("Password must contain at least one uppercase letter.");
    }
  if (!hasLowercase) {
    validationMessages.push("Password must contain at least one lowercase letter.");
  }
  if (!hasNumber) {
    validationMessages.push("Password must contain at least one number.");
  }
  if (!hasSpecialChar) {
    validationMessages.push("Password must contain at least one special character.");
  }

  return validationMessages;
};
export const getPasswordStrength = (password) => {
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  // Check if the password meets all criteria
  if (
    password.length >= minLength &&
    hasUppercase &&
    hasLowercase &&
    hasNumber &&
    hasSpecialChar
  ) {
    return 'Strong Password';
  } else {
    return 'Weak Password';
  }
};

export const validateInputField = (input, type, isRequired = false) => {
  // Check if the field is required and empty
  if (isRequired === true) {
    if (input === '' || input === null || input === undefined) {
      return false;
    }
  }
  // Validate based on the specified type
  switch (type) {
    case 'email':
      return validateEmail(input);
    case 'dob':
      return isDate(input);
    default:
      return true;
  }
};



export function getDecryptedPassword() {
  const encryptedPassword = process.env.REACT_APP_ENCRYPTED_PASSWORD;
  const secretKey = process.env.REACT_APP_SECRET_KEY;
    if (!encryptedPassword || !secretKey) {
        throw new Error('Environment variables are not set');
    }

    const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedPassword;
}

//input=>clicnic_admin output=>Clinic Admin
export const convertUnderScoreStrings = (inputString) => {
  if (inputString) {
    // Split the string into an array 
    let words = inputString.split('_');

    // Capitalize the first letter 
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let resultString = capitalizedWords.join(' ');
    return resultString;
  }
}
export const getOrganizationId = () => {
  // console.log('localStorageOrgid',localStorage.getItem('org_id'));
  return localStorage.getItem('org_id');
  // console.log('UserService.getProfile()',UserService.getProfile());
  // return UserService.getProfile().organization_id;
}


export const wOrgIdMiddleware = (apiMethod, isGetFromSelectId) => async (argOne, ...restArgs) => {
  const orgId = (isGetFromSelectId === true && argOne !== null) ? argOne : OrganizationService.getOrgId();
  if (isSet(orgId, null) === null) {
    return false;
  }

  const apiUrl = `organizations/${orgId}/`;

  return await apiMethod(apiUrl, ...(isGetFromSelectId ? [] : [argOne]), ...restArgs);
};

/*
 @param 134
 return $134
 if convertToCurrency=false ->$134 ->134
*/
export const convertToCurrency = (currency = '', convertToCurrency = true) => {
  if (convertToCurrency === false) {
    return parseFloat(currency.replace('$', ''));
  }
  return `$${currency}`;
};


/*
 @param String
 return First Letter as Caps 
 ex: first name=>First Name
*/
export const capitalizeName = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const capitalizeAfterSpace = (str)=> {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split(' ')    // Split the string into words
    .map(word => {
      // Capitalize the first letter only if it's not a number
      return isNaN(word) ? word.charAt(0).toUpperCase() + word.slice(1) : word;
    })
    .join(' '); // Join the words back into a single string
}
export const getCurrentDate = () => {
  // const today = new Date();
  let convertOrgTime = new Date().toLocaleString("en-US", { timeZone: OrganizationService.getOrgTimeZone() });
  let orgTimeClock = new Date(convertOrgTime);
  const month = (orgTimeClock.getMonth() + 1).toString().padStart(2, '0');
  const year = orgTimeClock.getFullYear();
  const date = orgTimeClock.getDate().toString().padStart(2, '0');
  return `${month}/${date}/${year}`;
};

// export const getCurrentTime = () => {
//   const current = new Date();
//   const minutes = current.getMinutes();

//   // Round to the nearest half-hour
//   const roundedMinutes = Math.round(minutes / 30) * 30;

//   // Set the new minutes and get the updated time
//   current.setMinutes(roundedMinutes);

//   const time = current.toLocaleTimeString("en-US", {
//     hour: 'numeric',
//     minute: 'numeric',
//     hour12: true
//   });

//   return time;
// };

export const getCurrentTime = () => {
  let convertOrgTime = new Date().toLocaleString("en-US", { timeZone: OrganizationService.getOrgTimeZone() });
  let orgTimeClock = new Date(convertOrgTime);

  let hours = orgTimeClock.getHours();
  let minutes = orgTimeClock.getMinutes();
  let period = 'AM';
  // Round minutes to the nearest half-hour
  const roundedMinutes = Math.ceil(minutes / 30) * 30;

  // Adjust hours if rounding causes minutes to go beyond 59
  if (roundedMinutes === 60) {
    hours = (hours + 1) % 24; // Ensure hours stay within 0-23 range
    minutes = 0;
  } else {
    minutes = roundedMinutes;
  }

  // If minutes are greater than 30, increment the hours
  if (minutes > 30) {
    hours = (hours + 1) % 24; // Ensure hours stay within 0-23 range
    minutes = 0;
  }

  // Convert hours and minutes to strings and prepend '0' if they're single digits
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  // Determine the period (AM/PM)
  if (hours >= 12) {
    period = 'PM';
    hours = hours % 12 || 12; // Convert 0 hours to 12 for 12-hour format
  }

  // Ensure both hours and minutes are represented with leading zeros
  hours = hours.toString().padStart(2, '0');
  minutes = minutes.toString().padStart(2, '0');

  const time = `${hours}:${minutes} ${period}`;

  return time;
};

export const getCurrentTime1 = (date = new Date()) => {
  // Calculate current time
  // let currentHours = date.getHours();
  let currentMinutes = date.getMinutes();

  // Calculate time at least 30 minutes later
  let futureDate = new Date(date.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

  // Round to the nearest 30-minute interval
  const roundedMinutes = Math.ceil(futureDate.getMinutes() / 30) * 30;
  futureDate.setMinutes(roundedMinutes);

  // If the current minutes are between 10 and 30, allow rounding to the next 30-minute interval
  if (currentMinutes > 10 && currentMinutes <= 30) {
    futureDate = new Date(date.getTime() + (60 - currentMinutes) * 60000); // Move to the next hour
    futureDate.setMinutes(30); // Set minutes to 30
  }

  let hours = futureDate.getHours();
  let minutes = futureDate.getMinutes();
  let period = 'AM';

  // Determine the period (AM/PM)
  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) hours -= 12; // Convert to 12-hour format, but keep 12 PM as 12
  } else {
    if (hours === 0) hours = 12; // Convert 0 hours to 12 for 12 AM
  }

  // Convert hours and minutes to strings and prepend '0' if they're single digits
  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');

  const time = `${hoursStr}:${minutesStr} ${period}`;

  return time;
};



export const base64Encode = (str) => {
  return btoa(str);
}

export const base64Decode = (str) => {
  return atob(str);
}

export const encryptString = (obj) => {
  const str = JSON.stringify(obj);
  return base64Encode(str);
}

export const decryptString = (str) => {
  const objStr = base64Decode(str);
  return JSON.parse(objStr);
}
export const calculateAge = (birthday = '', currentDate = new Date()) => {
  if (birthday) {
    const [month, day, year] = birthday.split('/').map(Number);
    const birthDate = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  return null; // Return null if no valid birthday is provided
};
export const fetchTimeInterval = async (val, callback=()=>{}) => {
  const timeInterval = generateTimeIntervals(val);
  let timeArr = [];
  timeInterval.forEach((item) => {
    timeArr.push({
      label: item,
      value: item
    });
  });
  callback(timeArr);
  return timeArr;
}
export const generateTimeIntervals = (searchQuery = '') => {
  const intervals = [];
  const startTime = 0; // Start time in minutes (0 represents 12:00 AM)
  const endTime = 24 * 60; // End time in minutes (24 hours * 60 minutes)

  for (let minutes = startTime; minutes < endTime; minutes += 30) {
    const hour = Math.floor(minutes / 60) % 12 || 12;
    const minute = (minutes % 60).toString().padStart(2, '0');
    const period = minutes < 12 * 60 ? 'AM' : 'PM';
    const time = `${hour.toString().padStart(2, '0')}:${minute} ${period}`;

    // Filter based on search query
    if (time.toLowerCase().includes(searchQuery.toLowerCase())) {
      intervals.push(time);
    }
  }

  return intervals;
};
//check is empty string 
export const isEmptyString = (value) => {
  return value.trim() === '';
};
//check is object empty or not
export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}
export const isObjectValueEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        return false; // If any value is not empty, return false
      }
    }
  }
  return true; // If all values are empty, return true
}


export const toQueryString = (pagination, query) => {
  const { page, perPage, search, sort_by, sort_order } = { ...pagination, ...query };
  return {
    page: page,
    perPage: perPage,
    sort_by: sort_by,
    sort_order: sort_order,
    ...search
  }
}

export const getBase64String = async file => {
  return new Promise(resolve => {
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load something...
    reader.onload = () => {
      // Get the base64 data without the data URI prefix
      const base64WithPrefix = reader.result; 
      const base64WithoutPrefix = reader.result.split(",")[1];
      resolve({base64WithPrefix, base64WithoutPrefix, fileName: file.name });
    };
  });
};
export const getLookupByKey = async (key,withNone=true) => {
  let lookupOptions = (withNone===true)?[{ label: '-Select-', value: '' }]:[];
  try {
    const data = await LookupService.getLookupByKey(key)
    data.forEach((item) => {
      lookupOptions.push({
        label: item.value,
        value: item.value
      });
    });

    return lookupOptions;
  } catch (e) {
    //catch execption if need
    console.error('error in getLookupByKey', e);
    return lookupOptions;
  }
}


export const evaluate = (rules, valueObj) => {
  let rulesInStr = '';
  let returnResult = null;
  isSet(rules,[]).forEach((itm)=>{
    rulesInStr = '';
    isSet(itm.rule,[]).forEach((rule)=>{
      const logical = (isSet(rule.logical,'')==='AND')?' && ':(isSet(rule.logical,'')==='OR')?' || ':'';
      rulesInStr+='"'+isSet(valueObj[rule.field],'')+'"'+rule.operator+'"'+isSet(rule.value,'')+'"'+logical;
    });
    try{
      const result = eval(rulesInStr);
      returnResult = {result:result,action:itm.action};
    }catch(e){

    }
  });
  return returnResult;
}

export const optionsToKeyValuePairs = (arr,isNone=true)=>{
  let prepEnd = [];
  if(isNone===true){
    prepEnd=[{label:'-None-',value:''}];
  }
  return [...prepEnd,...arr.map((itm,itmIndex)=>{
    return{
        label:itm,
        value:itm,
        key:itmIndex
    }
  })];
}

const locations = {
  AK: "Alaska",
  AL: "Alabama",
  AR: "Arkansas",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon"
};

// Function to map abbreviations to full state names
export function getLocation(input) {
  // Search for match by abbreviation (key)
  if (locations[input]) {
    return { [input]: locations[input] };
  }
  
  // Search for match by full name (value)
  const key = Object.keys(locations).find(k => locations[k] === input);
  
  if (key) {
    return { [key]: input };
  }
  
  return null; // Return null if no match is found
}


// 9597656690 to (959) 765-6690
export function formatPhoneNumberUS(phoneNumber) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-numeric characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the cleaned number to XXXXXXXXXX format
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  
}


export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
}

export const formatZipCode = (zipCode) =>{
  const cleaned = ('' + zipCode).replace(/\D/g, '');
  if (cleaned.length === 9) {
    return cleaned.substr(0, 5) + '-' + cleaned.substr(5);
  } else if (cleaned.length > 5) {
    return cleaned.substr(0, 5);
  }
  return zipCode;
}

export const getShortenedVisitCategoryName = (category) =>  {
  if (!category) return '';
  // Check the beginning of the category string to determine the appropriate prefix
  if (category.startsWith("Employer Services")) {
    return `ES - ${category.replace("Employer Services - ", "")}`;
  } else if (category.startsWith("Urgent Care")) {
    return `UC - ${category.replace("Urgent Care - ", "")}`;
  } else if (category.startsWith("Work Comp")) {
    return `WC - ${category.replace("Work Comp - ", "")}`;
  }

  return category;
};

export const getCss = (key, val,mode='DARK') => {
  const statusArr = ['status','suggested_status','pulse_rate_result','blood_pressure_result','urinalysis_result','vission_result','hearing_result','surgery_history','taking_medications','OtherArray'];
  
  if(isArray(key)){
    key = 'OtherArray';
  }
  // console.log('key.,,,',key);
  let cssClasses = {};
  if(mode==='LIGHT'){
    statusArr.forEach((item)=>{
      cssClasses = {
        ...cssClasses,
        [item]:{
          'normal,nodeficiency':'bg-green-soft text-green',
          'abnormal,deficiency':'bg-red-soft text-red',
          pending: 'bg-blue-soft text-blue',
          negative: 'bg-green-dark text-green-soft',
          positive: 'bg-red-dark text-red-soft',
          normal: 'bg-green-soft text-green',
          passed: 'bg-green-soft text-green',
          notstarted: 'bg-blue-soft text-blue',
          pendingmro: 'bg-blue-soft text-blue',
          pendinglabconfirmation: 'bg-blue-soft text-blue',
          failed: 'bg-red-soft text-red',
          abnormal: 'bg-red-soft text-red',
          yes:'bg-red-soft text-red',
          'notsure':'bg-red-soft text-red',
          no:'bg-green-soft text-green',
          'thesubjectfailedthefittest':'bg-red-soft text-red',
          'thesubjectpassedthefittest':'bg-green-soft text-green',
        }
      }
    });
  }else{
    statusArr.forEach((item)=>{
      cssClasses = {
        ...cssClasses,
        [item]:{
          'normal,nodeficiency':'bg-green-dark text-green-soft',
          'abnormal,deficiency':'bg-red-dark text-red-soft',
          pending: 'bg-blue-dark text-blue-soft',
          negative: 'bg-green-dark text-green-soft',
          positive: 'bg-red-dark text-red-soft',
          normal: 'bg-green-dark text-green-soft',
          passed: 'bg-green-dark text-green-soft',
          notstarted: 'bg-blue-dark text-blue-soft',
          pendingmro: 'bg-blue-dark text-blue-soft',
          pendinglabconfirmation: 'bg-blue-dark text-blue-soft',
          failed: 'bg-red-dark text-red-soft',
          abnormal: 'bg-red-dark text-red-soft',
          yes:'bg-red-soft text-red',
          'notsure':'bg-red-soft text-red',
          no:'bg-green-soft text-green',
          ordered:'bg-blue-dark text-blue-soft',
          'completed/administrated':'bg-green-dark text-green-soft',
          'thesubjectfailedthefittest':'bg-red-dark text-red-soft',
          'thesubjectpassedthefittest':'bg-green-dark text-green-soft',
        }
      }
    });
  }
 
  
 
   // Convert the provided value to a standardized format (e.g., lowercase with spaces removed)
   const sanitizedVal = val.toString().toLowerCase().replace(/\s/g, '');
  // console.log('sanitizedVal',key, val,sanitizedVal);
   // Check if the provided key exists in the cssClasses object
   if (cssClasses.hasOwnProperty(key)) {
     // Retrieve the class name corresponding to the sanitized value
     const appendClassName = cssClasses[key][sanitizedVal];
     // Return the class name
     return { appendClassName };
   } else {
     // Handle the case where the provided key is not found
   
     return { appendClassName: '' };
   }
 };

 export const LabResultCalcualtion=(test_result)=>{
  let color="blue";
  let status="PENDING";
  test_result=test_result.toUpperCase();
  if (test_result==='COMPLETED'||test_result==='LAB PANEL - COMPLETE'||test_result==='LAB PANEL - NEGATIVE'||test_result==='NEGATIVE'||test_result==='NOT DETECTED') {
    color='green';
    status="NEGATIVE";
  }
  if (test_result==='DETECTED'||test_result==='DETECTED - PENDING PCR CONFIRM'||test_result==='LAB PANEL - POSITIVE'|| test_result==='POSITIVE') {
    color='red';
    status="POSITIVE";
  }
  if (test_result==='DECLINED'||test_result==='INCONCLUSIVE'||test_result==='NOT TESTED'||test_result==='ORDERED'||test_result==='PENDING'||test_result==='RECEIVED') {
    color='blue';
    status="PENDING";
  }
  return{status,color}

 }


 export const formatDate = (date) => {
  // Get month, day, and year components
  const month = date.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed month
  const day = date.getDate();
  const year = date.getFullYear();

  // Ensure two-digit formatting by prepending '0' if needed
  const formattedMonth = month < 10 ? '0' + month : month;
  const formattedDay = day < 10 ? '0' + day : day;

  // Concatenate components in 'm/d/Y' format
  const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;

  return formattedDate;
};

export const stripHtmlTags = (html)=>{
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent.trim() || "";
}

export const decryptAES =  (encryptedString1) => {
  // return   ;
  if (process.env.REACT_APP_PRODUCTION_MODE=='false') {
    return encryptedString1;
  }
  const encryptionKey = process.env.REACT_APP_API_KEY;//process.env.REACT_APP_API_KEY;

    try {
      // Convert decrypted data to string
      let encryptStr = CryptoJS.enc.Base64.parse(encryptedString1);
      let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);

      encryptData = JSON.parse(encryptData);
      let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
      const ecnStr = encryptData.value;
      var decrypted = CryptoJS.AES.decrypt(ecnStr.toString(),  CryptoJS.enc.Base64.parse(encryptionKey), {
          iv : iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
      let serializedData = decrypted;
      try{
        serializedData = decrypted.match(/"(.*)"/)[1];
      }catch(e){

      }
      
      const binaryString  = atob(serializedData);
      const binaryArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
      }
      const decompressedData = pako.inflate(binaryArray, { to: 'string' });
      const returnData =  JSON.parse(decompressedData);
      return returnData;
    } catch (error) {
        console.error('Error decrypting data:', error);
        return null;
    }
};

// Helper function to convert a file to base64
export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result.split(',')[1]);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};


const arrayBufferToBase64 = (buffer) => {
  const binary = [];
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary.push(String.fromCharCode(bytes[i]));
  }
  return binary.join('');
}
 // if (data instanceof FormData) {
    //   const formDataObject = {};
    //   const entries = Array.from(data.entries());
    //   await Promise.all(entries.map(async ([key, value]) => {
    //     if (value instanceof File) {
    //       const base64File = await fileToBase64(value);
    //       formDataObject[key] = {
    //         name: value.name,
    //         type: value.type,
    //         size:value.size,
    //         fileData: base64File
    //       };
    //     } else {
    //       formDataObject[key] = value;
    //     }
    //   }));
    
    //   data = formDataObject;
    // }
    // let files = [];
export const encryptAES = (data)=>{
  // return data;  
  if (process.env.REACT_APP_PRODUCTION_MODE=='false') {
    return data;
  }
  const encryptionKey = process.env.REACT_APP_API_KEY;
  try {
   
    let formDataObject = {};
    const formData = new FormData();
    if (data instanceof FormData) {
      const entries = Array.from(data.entries());
      
      entries.forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formDataObject[key] = value;
        }
      });

      data = formDataObject;
    }

    const text = JSON.stringify(data);
    // console.log('formDataObject',text);
    const compressedData = pako.deflate(text, { to: 'string' });
    const compressedDatas = arrayBufferToBase64(compressedData);

    
    // const compressedDatas = String.fromCharCode.apply(null, compressedData)
    const serializedData  = btoa(compressedDatas);
    // console.log('serializedData',serializedData);
    const serializedString = `s:${serializedData.length}:"${serializedData}";`;
    // Encrypt the text
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(serializedString, CryptoJS.enc.Base64.parse(encryptionKey), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const encryptedStr = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

  
    // Encode IV and encrypted value to Base64
    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const valueBase64 = encrypted.toString();
       // Step 4: Generate MAC (Message Authentication Code)
    const mac = CryptoJS.HmacSHA256(ivBase64 + encryptedStr, CryptoJS.enc.Base64.parse(encryptionKey)).toString(CryptoJS.enc.Hex);

    // Create the object structure similar to the one you provided for decryption
    const encryptedData = {
      iv: ivBase64,
      value: valueBase64,
      mac: mac, // Add MAC if needed
      tag: '' // Add tag if needed for AEAD algorithms
    };

    // Serialize and encode the data as a JSON string
    const jsonData = JSON.stringify(encryptedData);
    const base64EncodedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(jsonData));
    // console.log('base64EncodedData',base64EncodedData);
    // return {
    //   encrypted_data:base64EncodedData
    // };
    
    formData.append('encrypted_data', base64EncodedData);
    // console.log('formData',formData);
    // files.forEach(({ key, value }) => {
    //   formData.append(key, value);
    // });

    return formData;
  } catch (error) {
    console.error('Error encrypting data:', error);
    return null;
  }
}
export const TaxIdFormatter=(value)=>{
  // Remove any non-numeric characters
  value = value.replace(/\D/g, '');

  // Insert '-' after the fifth character
  if (value.length >= 2 && value.charAt(2) !== '-') {
    value = value.slice(0, 2) + '-' + value.slice(2);
  }

  // Limit to 10 characters including '-'
  value = value.slice(0, 10);

  // Handle backspacing after the hyphen
  if (value.length > 2 && value.charAt(2) === '-' && value.charAt(3) === '') {
    // Remove the hyphen if it's followed by an empty character
    value = value.slice(0, 2) + value.slice(3);
  }
  return value;
}
export const USDateTimeString=(value)=>{
  return value&&moment.utc(value).format('MMMM D, YYYY h:mm:ss A');
}
export const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  console.log("ua :", navigator);

  const browser = { family: "Unknown", version: "Unknown" };
  let isLinux = false;
  let isMacOs = false;
  let isWindows = false;
  let isAndroid = false;
  let isIos = false;
  let isMobile = false;
  let isDesktop = false;

  // Detect browser family and version
  if (/chrome|crios|crmo/i.test(ua)) {
    browser.family = "Chrome";
    browser.version = ua.match(/(?:chrome|crios|crmo)\/(\d+)/i)[1];
  } else if (/firefox|iceweasel|fxios/i.test(ua)) {
    browser.family = "Firefox";
    browser.version = ua.match(/(?:firefox|iceweasel|fxios)\/(\d+)/i)[1];
  } else if (/safari/i.test(ua) && !/chrome|crios|crmo/i.test(ua)) {
    browser.family = "Safari";
    browser.version = ua.match(/version\/(\d+)/i)[1];
  } else if (/msie|trident/i.test(ua)) {
    browser.family = "Internet Explorer";
    browser.version = ua.match(/(?:msie |rv:)(\d+)/i)[1];
  } else if (/edg/i.test(ua)) {
    browser.family = "Edge";
    browser.version = ua.match(/edg\/(\d+)/i)[1];
  } else if (/opr\/|opios/i.test(ua)) {
    browser.family = "Opera";
    browser.version = ua.match(/(?:opr|opios)\/(\d+)/i)[1];
  }

  // Detect operating system
  if (/windows nt/i.test(ua)) {
    isWindows = true;
  } else if (/mac os x/i.test(ua)) {
    isMacOs = true;
  } else if (/android/i.test(ua)) {
    isAndroid = true;
  } else if (/linux/i.test(ua)) {
    isLinux = true;
  } else if (/iphone|ipad|ipod/i.test(ua)) {
    isIos = true;
  }

  // Detect if mobile or desktop
  if (/mobi/i.test(ua)) {
    isMobile = true;
  } else {
    isDesktop = true;
  }

  return {
    browser: browser,
    isAndroid: isAndroid,
    isIos: isIos,
    isLinux: isLinux,
    isMacOs: isMacOs,
    isWindows: isWindows,
    isMobile: isMobile,
    isDesktop: isDesktop
  };
}


