import React, { useEffect, useState, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import Select, { defaultTheme } from 'react-select';
import { isSet } from '../../../utils/commonUtils';

const { colors } = defaultTheme;

const selectStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  // multiValue: (base) => ({
  //   ...base,
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  //   whiteSpace: 'nowrap',
  //   maxWidth: '150px', // Adjust this value as needed
  // }),
  // multiValueLabel: (base) => ({
  //   ...base,
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  //   whiteSpace: 'nowrap',
  //   maxWidth: '150px', // Adjust this value as needed
  // }),
  // placeholder: (provided, state) => ({
  //   ...provided,
  //   display: state.hasValue ? 'none' : 'block',
  //   color: 'gray',
  // }),
  control: (provided) => ({
    ...provided,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

const SelectListWithSearch = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [asyncOptions, setAsyncOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  const {
    className,
    name,
    isMulti,
    isAutoPopulate,
    options,
    value,
    style,
    focusStyle,
    readOnly,
    isAsync,
    isCacheNeeded,
    required,
    placeholder,
    loadCallBack,
    changeHandler
  } = props;

  useEffect(() => {
    if (loadCallBack && isAsync) {
      setAsyncOptions([]);
      setLoading(true);
      loadCallBack('', (loadedOptions) => {
        setAsyncOptions(loadedOptions);
        setLoading(false);
      });
    }
  }, [props.reload]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
      setInputValue(''); // Clear the input value when opening the dropdown
    }
  }, [isOpen]);

  const handleChange = (selectedOption,{action}) => {
   
    // console.log('selectedOption',action,isMulti,selectedOption);
    // if(isMulti===false && action==='clear'){
    //   return false;
    // }
    if (isMulti) {
      let multiSelectValue = [];
      selectedOption.forEach((item) => {
        multiSelectValue.push(item.value);
      });
      changeHandler(multiSelectValue.join(','), {});
    } else if (isAutoPopulate) {
      changeHandler(isSet(selectedOption,{value:''}).value, { autoPopulates: selectedOption.autoPopulateFields });
    } else {
      changeHandler(isSet(selectedOption,{value:''}).value, {});
    }
    if(action==='select-option' && isMulti===false){
      setIsOpen(false);
    }
  };

  let defaultValueOptions = [];
  let displayValueOptions = [];
  const optionArr = asyncOptions.length === 0 ? options : asyncOptions;
  optionArr.forEach((item) => {
    if (isMulti) {
      if (value?.split(',').includes(item.value)) {
        defaultValueOptions.push(item);
        displayValueOptions.push(item.label);
      }
    } else if (value === item.value) {
      displayValueOptions.push(item.label);
      defaultValueOptions.push(item);
    }
  });

  const requiredStyles = required ? {
    borderLeft: '2px solid red',
    borderRadius: '0.35rem',
  } : {};

  return (
    <div id={isSet(props.id,name)} style={{ position: 'relative', ...requiredStyles }}>
      <div
        // className={className}
        className={`${className} text-truncate`}
        onClick={(readOnly === true) ? () => {} : () => setIsOpen((prev) => !prev)}
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          padding: '8px',
          border: '1px solid var(--snap-common-border)',
          borderRadius: '0.35rem',
          width: '100%', // Ensure it takes the full width of the container
        }}
        title={displayValueOptions.join(', ')}
      >
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {isSet(displayValueOptions, []).length > 0 ? (displayValueOptions.length>2)?`${displayValueOptions.length} items selected...`:`${displayValueOptions.join(', ')}` : '-Select-'}
      </span>
        {/* {isSet(displayValueOptions, []).length > 0 ? `${displayValueOptions.join(', ')}` : '-Select-'} */}
        <ChevronDown />
      </div>
      {isOpen && (
        <Menu>
          {isAsync ? (
            <AsyncSelect
              cacheOptions={isCacheNeeded}
              loadOptions={(inputValue, callback) => {
                loadCallBack(inputValue, (loadedOptions) => {
                  setAsyncOptions(loadedOptions);
                  callback(loadedOptions);
                });
              }}
              closeMenuOnScroll={true}
              defaultOptions={isLoading ? [] : asyncOptions.length > 0 ? asyncOptions : options}
              isLoading={isLoading}
              isDisabled={readOnly}
              name={name}
              id={isSet(props.id,name)}
              value={defaultValueOptions}
              onChange={handleChange}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              placeholder={placeholder}
              styles={selectStyles}
              menuIsOpen
              isMulti={isMulti}
              inputValue={inputValue}
              onInputChange={(newValue) => setInputValue(newValue)}
              ref={inputRef}
              // isClearable
            />
          ) : (
            <Select
              closeMenuOnScroll={true}
              cacheOptions
              isSearchable
              options={options}
              name={name}
              id={isSet(props.id,name)}
              value={defaultValueOptions}
              onChange={handleChange}
              placeholder={placeholder}
              styles={selectStyles}
              isMulti={isMulti}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              menuIsOpen
              inputValue={inputValue}
              onInputChange={(newValue) => setInputValue(newValue)}
              ref={inputRef}
              // isClearable
            />
          )}
        </Menu>
      )}
      {isOpen && <Blanket onClick={() => setIsOpen(false)} />}
    </div>
  );
};

// Styled components
const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        width: '100%',
      }}
      {...props}
    />
  );
};

const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

const Svg = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...props}
  />
);

const DropdownIndicator = () => (
  <div
    style={{
      color: colors.neutral20,
      height: 24,
      width: 32,
    }}
  >
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

SelectListWithSearch.defaultProps = {
  isMandatory: false,
  className: 'form-control form-control-solid text-truncate',
  placeholder: 'Search',
  value: '',
  style: {},
  focusStyle: {},
  options: [],
  isCacheNeeded: true,
  loadCallBack: () => {},
  onKeyPressHandler: () => {},
  readOnly: false,
  required: false,
  isMulti: false,
  isAutoPopulate: false,
  isAsync: false,
  validation: {
    isValid: true,
    message: '',
  },
};

export default SelectListWithSearch;




// import React, { useEffect, useRef, useState } from "react";
// import AsyncSelect from "react-select/async";
// import Select, { components } from 'react-select';
// import { isSet } from "../../../utils/commonUtils";
// const { Placeholder } = components;

// const SelectListWithSearch = (props) => {
//   const [asyncOptions, setAsyncOptions] = useState([]);
//   const [isLoading, setLoading] = useState(false);
//   const defaultOptions = [];
//   const {
//     className,
//     value,
//     name,
//     isMulti,
//     isAutoPopulate,
//     options,
//     style,
//     focusStyle,
//     readOnly,
//     isAsync,
//     isCacheNeeded,
//     required,
//     placeholder,// Added placeholder prop
//   } = props;

//   useEffect(() => {
//     if (props.loadCallBack && isAsync === true) {
//       setAsyncOptions([]);
//       setLoading(true); //loader for async load options
//       props.loadCallBack('', (loadedOptions) => {
//         setAsyncOptions(loadedOptions);
//         setLoading(false);
//       });
      
//     }
//   }, [props.reload])

//   const asyncSelectOnChange = (selectedOption) => {
//     if (isMulti === true) {
//       let multiSelectValue = [];
//       isSet(selectedOption, []).map((item) => {
//         multiSelectValue.push(item.value);
//         return item;
//       });
//       props.changeHandler(multiSelectValue.join(','), {});
//     } else if (isAutoPopulate) {
//       props.changeHandler(selectedOption.value, { autoPopulates: selectedOption.autoPopulateFields });
//     } else {
//       props.changeHandler(selectedOption.value, {});
//     }
//   };

//   let defaultValueOptions = [];
//   if (isAsync === true) {
//     const optionArr = (asyncOptions.length === 0) ? ((options.length === 0) ? defaultOptions : options) : asyncOptions;
//     optionArr.forEach((item) => {
//       if (value === item.value) {
//         item.checked = true;
//         defaultValueOptions.push(item);
//       }
//     });
//   } else {
//     options.forEach((item) => {
//       if (isMulti === true) {
//         if (value.split(',').indexOf(item.value) !== -1 || value.split(',').indexOf(item.id) !== -1) {
//           defaultValueOptions.push({...item,checked:true});
//         }
//       } else if (value === item.value) {
//         defaultValueOptions.push({...item,checked:true});
//       }
//     });
//   }
//   let otherAttr = {};
//   if (isMulti === true) {
//     otherAttr = {
//       isMulti: true
//     }
//   }

//   const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       width:'100%',
//       ...style,
//       backgroundColor: state.isFocused ? "#eef2f83d":"var(--snap-formcontrol-bg)",
//       borderColor: "var(--snap-common-border)",
//       boxShadow: state.isFocused ? "0 0 0 0.15rem rgba(78, 115, 223, 0.15)" : provided.boxShadow,
//       "&:hover": {
//         ...focusStyle,
        
//       }
//     }),
//     menu: (provided) => ({
//       ...provided,
//       zIndex: 2,
//     }),
//    option: (styles, state) => ({
//     ...styles,
//     backgroundColor: state.isSelected
//       ? "#047ffa"
//       : state.isFocused
//       ? "#047ffa"
//       : "var(--snap-formcontrol-bg)",
//     color: state.isSelected || state.isFocused ? "#ffffff" : "var(--body-color)",
//     borderColor: "var(--snap-common-border)",
//     "&:hover": {
//       backgroundColor: "#047ffa",
//       color: "#ffffff"
//     }
//   }),
//   };
//   const requiredStyles = required ? { //required field style 
//     borderLeft: '2px solid red',
//     borderRadius: '0.35rem',
//   } : {};

//   return (
//     <>
//     {/* <SectionLoader isLoading={isLoading}/> */}
//     <div style={requiredStyles}>
//       {isAsync === true ?
//         <AsyncSelect
//           cacheOptions={isCacheNeeded}
//           onChange={(selectedOption) => asyncSelectOnChange(selectedOption)}
//           loadOptions={(inputValue, callback) => {
//             props.loadCallBack(inputValue, (loadedOptions) => {
//               setAsyncOptions(loadedOptions);
//               callback(loadedOptions);
//             });
//           }}
//           defaultOptions={isLoading ? [] : (asyncOptions.length > 0 ? asyncOptions : options)} //default option load 
//           // defaultOptions={isAsync === true ? (asyncOptions.length > 0 ? asyncOptions : options) : options.length === 0 ? defaultOptions : options}
//           isLoading={isLoading}
//           isDisabled={readOnly}
//           isSearchable={false}
//           name={name}
//           value={defaultValueOptions}
//           components={{ Placeholder: CustomPlaceholder }} // Custom placeholder component
//           placeholder={placeholder} // Set the placeholder text
//           styles={customStyles}
//         /> :
//         <Select
//           cacheOptions
//           {...otherAttr}
//           isSearchable={false}
//           onChange={(selectedOption) => asyncSelectOnChange(selectedOption)}
//           options={options}
//           placeholder={placeholder} // Set the placeholder text
//           value={defaultValueOptions}
//           isDisabled={readOnly}
//           name={name}
//           menuPosition="absolute"
//           styles={customStyles}
//           components={{ Placeholder: CustomPlaceholder }} // Custom placeholder component
//         />
//       }
//       </div>
//     </>
//   );
// };

// // Custom placeholder component
// const CustomPlaceholder = (props) => {
//   return (
//     <Placeholder {...props}>
//       {props.children} {/* This will render the provided placeholder text */}
//     </Placeholder>
//   );
// };

// SelectListWithSearch.defaultProps = {
//   isMandatory: false,
//   className: 'form-control form-control-solid',
//   placeholder: 'Search', // Default placeholder text
//   value: '',
//   style: {},
//   focusStyle:{},
//   options: [],
//   isCacheNeeded: true,
//   loadCallBack: () => { },
//   onKeyPressHandler: () => { },
//   readOnly: false,
//   required: false,
//   isMulti: false,
//   isAutoPopulate: false,
//   isAsync: false,
//   validation: {
//     isValid: true,
//     message: ''
//   },
// };

// export default SelectListWithSearch;


// import React, { useState } from 'react';
// import Button from '@atlaskit/button';
// import Select from 'react-select';
// import { defaultTheme } from 'react-select';

// // Define your StateOption and stateOptions here
// const stateOptions = [
//   { label: 'Alabama', value: 'alabama' },
//   { label: 'Alaska', value: 'alaska' }
//   // Add more options here
// ];

// const { colors } = defaultTheme;

// const selectStyles = {
//   control: (provided) => ({
//     ...provided,
//     minWidth: 240,
//     margin: 8,
//   }),
//   menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
// };

// const DropdownComponent = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [value, setValue] = useState(null);

//   const handleChange = (newValue) => {
//     setValue(newValue);
//     setIsOpen(false);
//   };

//   return React.createElement('div', { style: { position: 'relative' } },
//     React.createElement(Button, {
//       iconAfter: React.createElement(ChevronDown),
//       onClick: () => setIsOpen((prev) => !prev),
//       isSelected: isOpen,
//     }, value ? `${value.label}` : '-Select-'),
//     isOpen && React.createElement(Menu, null,
//       React.createElement(Select, {
//         autoFocus: true,
//         backspaceRemovesValue: false,
//         components: { DropdownIndicator, IndicatorSeparator: null },
//         controlShouldRenderValue: false,
//         hideSelectedOptions: false,
//         isClearable: false,
//         menuIsOpen: true,
//         onChange: handleChange,
//         options: stateOptions,
//         placeholder: 'Search...',
//         styles: selectStyles,
//         tabSelectsValue: false,
//         value: value,
//       })
//     ),
//     isOpen && React.createElement(Blanket, { onClick: () => setIsOpen(false) })
//   );
// };

// // Styled components

// const Menu = (props) => {
//   const shadow = 'hsla(218, 50%, 10%, 0.1)';
//   return React.createElement('div', {
//     style: {
//       backgroundColor: 'white',
//       borderRadius: 4,
//       boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
//       marginTop: 8,
//       position: 'absolute',
//       zIndex: 2,
//     },
//     ...props
//   });
// };

// const Blanket = (props) => (
//   React.createElement('div', {
//     style: {
//       bottom: 0,
//       left: 0,
//       top: 0,
//       right: 0,
//       position: 'fixed',
//       zIndex: 1,
//     },
//     ...props
//   })
// );

// const Svg = (props) => (
//   React.createElement('svg', {
//     width: 24,
//     height: 24,
//     viewBox: '0 0 24 24',
//     focusable: 'false',
//     role: 'presentation',
//     ...props
//   })
// );

// const DropdownIndicator = () => (
//   React.createElement('div', {
//     style: { color: colors.neutral20, height: 24, width: 32 }
//   },
//     React.createElement(Svg, null,
//       React.createElement('path', {
//         d: 'M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z',
//         fill: 'currentColor',
//         fillRule: 'evenodd',
//       })
//     )
//   )
// );

// const ChevronDown = () => (
//   React.createElement(Svg, { style: { marginRight: -6 } },
//     React.createElement('path', {
//       d: 'M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z',
//       fill: 'currentColor',
//       fillRule: 'evenodd',
//     })
//   )
// );

// export default DropdownComponent;









