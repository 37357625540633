import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import OrganizationService from '../services/OrganizationService';
import { convertUnderScoreStrings } from '../utils/commonUtils';
import DropDownService from '../services/DropDownService';
import OrganizationService from '../services/OrganizationService';

export const fetchGlobalOrganization = createAsyncThunk(
  'organization/fetchOrganization',
  async (profileDetails, { getState, dispatch }) => {
   
    if (profileDetails.is_multiple_organization) {
      try {
        const org = await DropDownService.getOrganizationDropDownList();
        if (org.length === 1) {
          dispatch(setDefaultOrganization(org[0].organization_uuid));
        }
        const orgOpt = [{ label: "-Select Organization-", value: '' }];
        org.data.forEach(itm => {
          orgOpt.push({ label: convertUnderScoreStrings(itm.name), value: itm.organization_uuid });
        });
        //stored org id if only one we dont show in dropdown if 1 one org
        if (org.data.length===1) {
          OrganizationService.storeOrgId(org.data[0].organization_uuid)
        }
        return orgOpt;
      } catch (error) {
        throw error;
      }
    }
    return [];
  }
);

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organizationList: [],
    selectedOrganization: '',
  },
  reducers: {
    setDefaultOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalOrganization.pending, (state) => {
        // state.loading = true;
        // state.error = null;
      })
      .addCase(fetchGlobalOrganization.fulfilled, (state, action) => {
        // state.loading = false;
        state.organizationList = action.payload;
      })
      .addCase(fetchGlobalOrganization.rejected, (state, action) => {
        // state.loading = false;
        // state.error = action.error.message;
      });
  },
});

export const { setDefaultOrganization } = organizationSlice.actions;

export const organizationList = (state) => state.organization.organizationList;
export const selectSelectedOrganization = (state) => state.organization.selectedOrganization;

export default organizationSlice.reducer;
