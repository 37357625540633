import Button from './Buttons/Button';
import withReduxState from '../../hoc/wReduxState';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { checkBoxActiveIds, checkBoxSelectedAllRecords, setSelectedAllRecords } from '../../store/GlobalFilterSlice';
import { isSet } from '../../utils/commonUtils';
import Acl from '../../hoc/Acl';


const HeaderButtons = ({ tableRecords, headerButtons, checkBoxchangeHandler }) => {
  const dispatch = useDispatch();
  const activeIds = useSelector(checkBoxActiveIds);  
  const selectedAllRecords = useSelector(checkBoxSelectedAllRecords);  
  const totalCount = tableRecords?.pagination?.totalRecords;
  // console.log('activeIdsLEngth',activeIds.length);
  return (
    <>
      {activeIds.length > 0 && (
        <>
          <div
            id="pageSelect"
            style={{
              borderBottom: '1px solid #dfe3ee',
              textAlign: 'center',
            }}
          >
            {`Selected ${selectedAllRecords ? `All ${totalCount}` : `${activeIds.length} Entries`}`}
            <a
              className="btn btn-link"
              onClick={(e) => {
                checkBoxchangeHandler(
                  selectedAllRecords ? 0 : 1,
                  { e },
                  'ALL'
                );
                dispatch(setSelectedAllRecords(!selectedAllRecords));
              }}
            >
              <strong>{selectedAllRecords ? 'Clear Selection' : 'Select All Entries'}</strong>
            </a>
            <>
            {headerButtons.map((btn, btnInd) => (
              <>
                <Acl acl={isSet(btn.acl,'any')}>
                  <Button
                    {...btn}
                    key={btnInd}
                    clickHandler={() =>
                      btn.clickHandler(selectedAllRecords ? 'ALL' : activeIds.join(','))
                    }
                  />
                </Acl>
              </>
            ))}
            </>
          </div>
        </>
      )}
    </>
  );
};


export default withReduxState(HeaderButtons);