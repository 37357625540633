import React, { Component } from 'react';
import LabsService from '../../services/LabService';
import TableGrid from '../../components/UI/TableGrid';
import FormInputs from '../../components/UI/FormInputs';
import { LabResultCalcualtion, capitalizeName, isObject, toQueryString } from '../../utils/commonUtils';
import Buttons from '../../components/UI/Buttons';
import WithRoutify from '../../hoc/WithRoutify';
import { OK } from '../../utils/constant';
import withReduxState from '../../hoc/wReduxState';
import LabFilter from './LabFilter';
import ReactDOM from "react-dom";
import Employeeervice from '../../services/EmployeeService';
import AddLab from '../EmployeeVisitContainer/AddLab';
import PatientChartsService from '../../services/PatientChartsService';
import FileManagerService from '../../services/FileManagerService';
import Acl from '../../hoc/Acl';
import PERMISSIONS from '../../utils/AclPermissions';
import { anyCheckboxSelected, getActiveIds } from '../../utils/visitUtils';
import Hl7OrderModal from '../../components/Modal/Hl7OrderModal';



class LabsList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      { label: '', key: 'isActive', type: 'checkBox', name:'ALL', changeHandler: this.checkBoxchangeHandler },
      { label: 'Status', key: 'status',},
      { label: 'Lab ID', key: 'lab_id' },
      { label: 'Company', key: 'company' },
      // { label: 'Company ID', key: 'company_id' },
      { label: 'Patient', key: 'patient' },
      // { label: 'Patient ID', key: 'patient_id' },
      // { label: 'Location', key: 'location' },
      { label: 'Test Date', key: 'test_date' },
      { label: 'Time', key: 'time' },
      { label: 'Lab Order', key: 'lab_order' },
      { label: 'Result Date', key: 'result_date' },
      { label: 'Test Result', key: 'test_result' },
      // { label: 'Alternate ID', key: 'alternate_id' },
      { label: 'Action', key: 'action', type: 'Actions' },
    ];
    this.state = {
      filters: {
        search_query: ''
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'name',
          sort_order: 'asc',
          search: {},
        }
      },
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      labOrderModalOpen:false,
      filterOpen: false,
      activeVisitId:null,
      selectedAllRecords:false,

    }
  }

  componentDidMount() {

    this.fetchLabsList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.globalFilter.Labs!==this.props?.globalFilter?.Labs) {
      this.fetchLabsList();
    }
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen })
  }

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId,activeVisitId,selectedAllRecords,labOrderModalOpen } = this.state;
    return (
      <>
        {isModelOpen ? (
          <AddLab
            activeEditId={activeEditId}
            modelTitle={`${this.state.activeEditId ? 'View and Update' : 'Add'} Lab`}
            isOpen={this.state.isModelOpen}
            visitId={activeVisitId}
            fetchRecords={this.fetchLabsList}
            modalToogleHandler={this.addFormHandler}
          />
        ) : (
          <></>
        )}
        {
          labOrderModalOpen?
          <Hl7OrderModal
          addFormHanlder={this.labOrderHandler}
          id={activeEditId}
          />
          :
          ''
        }
        {/* <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"lab-create"}
          content={"Labs"}
        > */}
        <div className="sh_cardBox" id="123">
          {
            this.props.isFromEmployeeHistory?
            <>
             <div className="sh_cardHeader">
                 <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                   <h6 className="text-sm mb-0">
                     Lab History - {this.props.patientDetails.patientName}
                   </h6>
                   <div className="addAction d-flex justify-content-between align-items-center">
                   </div>
                 </div>
               </div>
            </>
            :
            <div className="sh_cardHeader">
            <div className="form-group app-heading-tab d-flex mb-0 justify-content-between align-items-center">
              <h6 class="text-xl f-500 mb-0"> Lab List </h6>
              <div className="d-flex">
                <div className="form-group-fields row mr-2">
                  <div className="col-12 px-1 d-flex">
                    <div className="input-group input-group-joined input-group-solid">
                    </div>
                    <div className="col-12 pl-0">
                    <Acl acl={anyCheckboxSelected(this.state.tableRecords)==true?PERMISSIONS.RESULT.DELETE:''}>
                      <Buttons
                        clickHandler={()=>this.deleteHandler(null,null,true)}
                        className="btn btn-primary"
                        isDelete={true}
                        label={"Delete"}
                      />
                      </Acl>
                      <div className='btn-group'>
                        <div className="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Lab"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      </div>

                      <div className="btn-group pl-2">
                      {/* <Buttons
                          clickHandler={this.filterOnClick}
                          className="btn btn-outline-secondary mr-2"
                          acl={"any"}
                          label={"Search by "}
                          iconType={"Filter"}
                        ></Buttons> */}

                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                   
                      </div>
                    </div>
                
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

          <div className="sh_cardBody p-0 statusBadge">
            <TableGrid
              hasPagination={true}
              fetchTableRecords={(filters) => this.fetchLabsList(filters)}
              hasHeaderAction={true} //headerProps
              headersProps={{
                tableRecords:tableRecords,
                headerButtons:this.getHeaderButtons(),
                checkBoxchangeHandler:this.checkBoxchangeHandler,
                selectedAllRecords:selectedAllRecords,
                setSelectedAllRecords:(bool) => this.setState({ selectedAllRecords: bool })

              }}
              {...tableRecords}
            />
          </div>
        </div>
        {/* </TableSkeleton> */}
        {this.state.filterOpen ? (
          <>
            {ReactDOM.createPortal(
              <LabFilter
                onChangeHandler={this.onChangeFiltersHandler}
                filterData={this.state.filters}
                closeHandler={this.filterCloseHandler}
                submitHandler={this.submitFiltersHandler}
              />,
              document.getElementById("sh_mainportal_view") // Render the component into the portal container
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    }
    this.fetchLabsList(queryfilters);
  }
  clearFiltersHandler = () => {
    this.setState({
      filters: {}
    }, this.submitFiltersHandler);
  }
  fetchLabsList = async (query = {}) => {
    const { tableRecords,selectedAllRecords } = this.state;
    const globalFilter=this.props?.globalFilter?.Labs || {};
    let queryString = toQueryString({ ...tableRecords.pagination, ...query });
    if (this.props.isFromEmployeeHistory && this.props.employee_id) {
      queryString.employee_id = this.props.employee_id;
    }
    else{
      queryString={...queryString,...globalFilter};
    }
    try {
      this.props.Loader(true);
      const labsList = await Employeeervice.getAllEmployeeVisitLab(queryString);
      let tableData = [];
      labsList.data.data.forEach((item, index) => {
        const {status,color} =LabResultCalcualtion(item.test_result);
        // console.log('status',color,status,index);
        tableData = [
          ...tableData,
          {
            labsIndex: index,
            isHeading: false,
            rowId: item.id,
            data: {
              isActive: selectedAllRecords || false,
              status:{
                title:'Status',
                value:<span class={`badge bg-${color}-soft text-${color}`}>{capitalizeName(status.toLowerCase())}</span>
              },
              lab_id: item.random_lab_id || '--',
              company: item.visit?.company?.name || '--',
              company_id: item.visit?.company?.system_id || '--',
              patient: item?.visit?.employee?.first_name && item?.visit?.employee.last_name ? item.visit.employee.first_name +" "+ item.visit.employee.last_name : '--',
              patient_id: item?.patient_id || '--',
              location: item?.clinic_department?.facility_name || '--',
              test_date: item.test_date || '--',
              time: item.test_time || '--',
              lab_order: item.lab_order?.lab_order || '--',
              result_date: item.result_date || '--',
              test_result: item.test_result || '--',
              alternate_id: item.alternate_id || '--',
              active: 'Yes',
              action: [
                // {
                //   className: 'btn btn-icon btn-transparent-dark',
                //   iconType: 'Labs Order',
                //   title: 'Labs Order',
                //   acl: 'any',
                //   clickHandler: () => this.labOrderHandler(item?.id),
                // },
                {
                className: 'btn btn-icon btn-transparent-dark',
                iconType: 'Search',
                title: 'View',
                type: 'GridEdit',
                acl: 'any',
                clickHandler: (rowId, data) => this.editHandler(rowId, item?.visit?.visit_id),
                updateHandler: () => this.updateHandler(''),
              },  
              {
                className: 'btn btn-icon btn-transparent-dark pl-1 pr-1',
                title: 'Print',
                acl:'any',
                children:(
                  <>
                     {/* <button
                        className="btn dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-left shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            Print Lab Label 1 ⅛ X 3 ½
                          </a>
                          <a className="dropdown-item" href="#">
                            Print Lab Label 2 X 3.8
                          </a>
                        </div>
                      </button> */}
                      <span className="dropdown dropdown-toggle position-static noArrow" data-bs-toggle="dropdown">
                       <svg fill="#000000" height="16" width="16" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64">	<path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002		C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995		c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507		c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649		c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012		C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z		 M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z"/>	<path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z"/>	<path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z"/>	<circle cx="7.9590998" cy="21.8405495" r="2"/>	<circle cx="14.2856998" cy="21.8405495" r="2"/>	<circle cx="20.6121998" cy="21.8405495" r="2"/>	<path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z"/></svg>

                       
                      </span>
                      <div className="dropdown-menu dropdown-menu-left shadow" aria-labelledby="userDropdown">
                          <div className="dropdown-item" href="#" onClick={()=>this.printlabel(item,"print_label_1x3")}>
                            Print Lab Label 1 ⅛ X 3 ½
                          </div>
                          <div className="dropdown-item" href="#" onClick={()=>this.printlabel(item,"print_label_2x3")}>
                            Print Lab Label 2 X 3.8
                          </div>
                          <div className="dropdown-item" href="#" onClick={()=>this.printlabel(item,"lab_report")}>
                            Print Lab Report
                          </div>
                          <div className="dropdown-item" href="#" onClick={()=>this.printlabel(item,"lab_requisition")}>
                            Print Lab Requisition
                          </div>
                        </div>
                  </>
                )
               },
                {
                className: 'btn btn-datatable btn-icon btn-transparent-dark',
                iconType: 'Remove',
                acl: 'any',
                title: 'Delete',
                isDelete: true,
                clickHandler: (rowId) => this.deleteHandler(item.id, item?.visit?.visit_id)
              },         
            ]
            }
          }
        ]
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            status: 200,
            type: "default",
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: labsList.data.total
          },
        },
      }));
      this.props.Loader();
    } catch (e) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200
          }
        }
      });
      this.props.Loader();
    }
  }
  deleteHandler = async (labId,vist_id,multipleDelete=false) => {
    const { Loader, Toast } = this.props;
    try {
      if (multipleDelete) {
        labId=getActiveIds(this.state.tableRecords)
      }
      await Employeeervice.deleteEmployeeVisitLabById(vist_id,labId)
        .then((response) => {
          Toast.success("Lab Deleted Successfully");
        })
        .catch(err => {

        })
        .finally(() => {
          this.fetchLabsList();
        });
    } catch (e) {

    }

  }
  editHandler = (rowId, visitId) => {
    this.setState({
      activeVisitId:visitId,
      activeEditId: rowId,
    }, this.addFormHandler());
  }
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    });
  }
  onChangeFormDataInEdit = (key, val) => {
    this.setState((prevState) => ({
      formDataInEdit: {
        ...prevState.formDataInEdit,
        [key]: val,
        validation: {
          ...prevState.formDataInEdit.validation,
          [key]: '', // Set validation empty
        },
      },
    }));
  }
  updateHandler = async (labsId) => {
    const { formDataInEdit } = this.state;
    const { name, description } = formDataInEdit;
    let payload = {
      name: name,
      description: description
    }
    try {
      const res = await LabsService.updateLabs(labsId, payload);
      // this.editHandler(null, {}, null);
      if (res.code === OK) {
        this.props.Toast.success('Lab Updated Successfully');
        this.editHandler(null, {}, null);
      }
      else if (res.status == "ERROR" && res.data) {
        if (isObject(res.data)) {
          let validaitons = {};
          for (let key in res.data) {
            validaitons = { ...validaitons, [key]: res.data[key][0] };
          }
          this.setState((prevState) => ({
            formDataInEdit: {
              ...prevState.formDataInEdit,
              validation: validaitons,
            },
          }));
        }
      }

    } catch (e) {

    }
    this.fetchLabsList();
  }
  filterOnClick = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    }, () => {
      if (document.body.classList.contains('filterOpened')) {
        document.body.classList.remove('filterOpened');
        // menuToggleElement.removeClass('active');
      } else {
        document.body.classList.add('filterOpened');
      }
    });
  };
  filterCloseHandler = (isClear = true) => {
    if (isClear) {
      this.clearFiltersHandler();
    }
    else {
      this.setState({
        filterOpen: false,
      }, () => {
        if (document.body.classList.contains('filterOpened')) {
          document.body.classList.remove('filterOpened');
          // menuToggleElement.removeClass('active');
        } else {
          document.body.classList.add('filterOpened');
        }
      });
    }
  };
  printlabel=async(data,type,labelName='lab')=>{
    const queryfilters = {
      label_type:type,
      visit_id:data.visit_id,
  }
  if (type=='lab_report') {
    data = await PatientChartsService.getLaboratoryReport(null,queryfilters,data.id);
  }
  else if(type=='lab_requisition'){
    data = await PatientChartsService.getLabRequisition(null,queryfilters,data.id);
  }
  else{
    data = await PatientChartsService.getPrintLabel(null,queryfilters,data.id);
  }
  const pdfData = {
    file_content:data,
    original_name:`${labelName}`,
    mime_type:'application/pdf'
    
}
FileManagerService.printPdf(pdfData);



  }
  checkBoxchangeHandler = (value, { e },rowId='') => {
    if (!rowId) {
      rowId = e.target.id;
    }    
    const { tableRows } = this.state.tableRecords;
    let updatedData;
    if (rowId=='ALL') {
       updatedData = tableRows.data.map((item) => {
          item.data.isActive = value;
        return item;
      });
      this.setState({
        selectedAllRecords: false,
    });
    }
    else{
       updatedData = tableRows.data.map((item) => {
        if (item.rowId === rowId) {
          item.data.isActive = value;
        }
        return item;
      });
    }
    const allSelected = updatedData.every(item => item.data.isActive ==1);
    const allCheckbox = document.getElementById('ALL');
    if (allCheckbox) {
      allCheckbox.checked = allSelected;
    }
    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  getHeaderButtons=()=>{
    const HeaderButtons=[
      {
        label:'Download Csv',
        clickHandler:()=>this.downloadCSV(),
      },
      {
        label:'Delete',
        clickHandler:()=>{},
      }
    ];
    return HeaderButtons;
  }
  labOrderHandler=(id=null)=>{
    this.setState({labOrderModalOpen:!this.state.labOrderModalOpen,activeEditId:id});
  }

}

export default withReduxState(WithRoutify(LabsList));