// src/services/userService.js
import http from './http';
import { OK } from '../utils/constant';
import { wOrgIdMiddleware } from '../utils/commonUtils';
// import { useDispatch } from 'react-redux';
// import {setProfileDetails} from '../store/ProfileSlice';

const Employeeervice = {
  getEmployeeList: wOrgIdMiddleware(async (url, query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    //?${queryString}
    try {

      const response = await http.GET(`${url}employees?${queryString}`);
      
        if (response.code === 200 && response.data) {
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }),
  getEmployeeListForPhysical: wOrgIdMiddleware(async (url, query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    //?${queryString}
    try {

      const response = await http.GET(`${url}employees-physical?${queryString}`);
      
        if (response.code === 200 && response.data) {
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }),
  getEmployeeVisitsHistoryList: wOrgIdMiddleware(async (url, patientId,query={}) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}employees/visits-history/${patientId}?${queryString}`);
      
        if (response.code === 200 && response.data) {
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }),
  getEmployeeVisitsHistoryForPhysical: wOrgIdMiddleware(async (url, patientId,query={}) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}employees-visits-for-physical/${patientId}?${queryString}`);
      
        if (response.code === 200 && response.data) {
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }),
  getEmployeeVisitsHistoryByVisitIds: wOrgIdMiddleware(async (url, visitIds,employeeId) => {
    try {

      const response = await http.POST(`${url}employees-visits-for-physical/${employeeId}`,visitIds);
      
        if (response.code === 200 && response.data) {
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }),
  getEmployee: wOrgIdMiddleware(async (url, employeeId) => {
    try {
      // const orgId       = OrganizationService.getOrgId();
      const response = await http.GET(`${url}employees/${employeeId}`);
      
        if (response.code === OK) {
          return response.data;
        }
      
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  //Save user
  saveEmployee: wOrgIdMiddleware(async (url = '', data, headers = {}) => {
    try {
      console.log('employee data fronte-->',data);
      const response = await http.POST(`${url}employees`, data, headers)
      // console.log('response',response);
      
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  assignCompanyForSelfRegisterPatients: wOrgIdMiddleware(async (url = '', data, headers = {}) => {
    try {

      const response = await http.POST(`${url}employees_assign_company`, data, headers)
      // console.log('response',response);
      
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),

  

  //Save user
  updateEmployee: wOrgIdMiddleware(async (url, employeeId, data,headers={}) => {
    try {
      
      const response = await http.POST(`${url}update_employees/${employeeId}`, data,headers);
      
        if (response.code === 200 && response.data) {
          // UserService.storeProfile(resData.data);

          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),
  //Delete Employee
  deleteEmployee: wOrgIdMiddleware(async (url = '', employeeId) => {
    try {
      const response = await http.DELETE(`${url}employees/${employeeId}`);
      return response;
      
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),

  //Save user
  addAdditionalDocument: async (data, headers = {
    'Content-Type': 'multipart/form-data'
  }) => {
    try {

      const response = await http.POST(`employee-additonal-documents`, data, headers);
      return response;
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  getAdditionalDocumentsList: async (query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    // console.log('queryString',queryString);
    //?${queryString}
    try {
      const response = await http.GET(`employee-additonal-documents?${queryString}`);
      
        if (response.code === 200 && response.data) {
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  },
  //Delete Employee
  deleteAdditionalDocument: async (additionalDocId) => {
    try {
      const response = await http.DELETE(`employee-additonal-documents/${additionalDocId}`);
      return response;    
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  },
  //Save user
  addProffOfLabsAndVaccine: async (data, headers = {
    'Content-Type': 'multipart/form-data'
  }) => {
    try {

      const response = await http.POST(`employee-proof-documents`, data, headers)
      // console.log('response',response);
      
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  deleteProffOfLabsAndVaccine: async (labDocId) => {
    try {
      const response = await http.DELETE(`employee-proof-documents/${labDocId}`);
      
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return null;
        }
      
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  },
  updateProffOfLabsAndVaccine: async (proofId, data,headers) => {
    try {
      const response = await http.POST(`employee-proof-documents/update/${proofId}`, data,headers);
      
        // if (response.code === 200 && response.data) {
          // UserService.storeProfile(resData.data);

          return response;
        // } else {
        //   return null;
        // }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  },
  generateSpecialInstruction: async (data = '') => {
    const response = await http.POST(`/generate-pdf`, data);
    return response
  },
  saveEmployeeVisitLab: wOrgIdMiddleware(async (url, visitId, data = '') => {
    try {
      const response = await http.POST(`${url}employee-visits/${visitId}/lab`, data);
      // console.log('response',response);
      
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  getEmployeeCharts: wOrgIdMiddleware(async (url, payload) => {
    try {
      const response = await http.POST(`${url}get-employee-charts`,payload);      
      return response?.data;
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  }),
  getEmployeeVisitLab: wOrgIdMiddleware(async (url, visitId) => {
    try {
      const response = await http.GET(`${url}employee-visits/${visitId}/lab`);
      // console.log('response',response);
      
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  getAllEmployeeVisitLab: wOrgIdMiddleware(async (url, query={}) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}lab-list?${queryString}`);
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  getEmployeeVisitLabById: wOrgIdMiddleware(async (url, visitId, labId) => {
    try {
      const response = await http.GET(`${url}employee-visits/${visitId}/lab/${labId}`);
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  updateEmployeeVisitLabById: wOrgIdMiddleware(async (url, visitId, labId,data) => {
    try {
      const response = await http.PUT(`${url}employee-visits/${visitId}/lab/${labId}`, data);
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  deleteEmployeeVisitLabById: wOrgIdMiddleware(async (url, visitId, labId) => {
    console.log(visitId, labId);
    try {
      const response = await http.DELETE(`${url}employee-visits/${visitId}/lab/${labId}`);
        if ((response.code === 200 || response.code === 201) && response.data) {
          return response.data;
        } else {
          return response;
        }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  sendInvitationLinkToEmployee: (async (data) => {
    try {
      const response = await http.POST(`send-mail-invite`,data);
      return response;
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),

  // storeEmployeeId: (id) => {
  //   localStorage.setItem('employee_id', id);
  // },
  // removeEmployeeId: () => {
  //   localStorage.removeItem('employee_id');
  // },
  // getEmployeeId: () => {
  //   // Remove the id from storage
  //   return localStorage.getItem('employee_id');
  // },
};

export default Employeeervice;