
import { isSet } from "../../../../../../utils/commonUtils";

export const ScreeningBAT = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[
                {
                    label:'Technician',
                    col:'col-6',
                    type:'RadioGroup',
                    name:'technician',
                    id:'technician',
                    options:['BAT','STT'],
                },
                {
                    label:'Device',
                    col:'col-6',
                    type:'RadioGroup',
                    name:'device',
                    id:'device',
                    options:['SALIVA','BREATH'],
                },
                {
                    label:'15-Minute Wait',
                    col:'col-6',
                    type:'RadioGroup',
                    name:'fifteen_minute_wait',
                    id:'fifteen_minute_wait',
                    options:[{label:'Yes',value:1},{label:'No',value:0}],
                },
                {
                    type:'Blank',
                    name:'blank'
                },
               
        ]
        },
         { 
            label:'Screening Test',
            note:'For BREATH DEVICE* write on the space below only if the testing device is not designed to print',
            fields:[
                {   
                    label:'Screening',
                    type:'Label',
                    col:'col-12',
                    appendClass:'bg-light'
                },
                {
                    label:'Testing Device Name',
                    type:'TextInput',
                    name:'testing_device_name',
                    id:'testing_device_name',
                },
                {
                    label:'Device Serial # OR Lot # & Exp. Date',
                    type:'TextInput',
                    name:'device_serial_lot_exp',
                    id:'device_serial_lot_exp',
                },
                {
                    label:'Activation Time',
                    type: "TextInput",
                    placeholder:'Time',
                    // isAsync:true,
                    // options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                    // loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                    name:'activation_time',
                    id:'activation_time',
                },
                {
                    label:'Reading Time',
                    type: "TextInput",
                    // type: "SelectSearchList",
                    placeholder:'Time',
                    // isAsync:true,
                    // options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                    // loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                    name:'reading_time',
                    id:'reading_time',
                },
                {
                    label:'Result',
                    type:'Decimal',
                    rangeMax:0.01,
                    name:'result',
                    id:'result',
                },
                {
                    label:'',
                    type:'Blank',
                    name:'blank',
                    id:'blank',
                },

                {   
                    label:'Confirmation',
                    type:'Label',
                    col:'col-12',
                    appendClass:'bg-light'
                },
                {
                    label:'Testing Device Name',
                    type:'TextInput',
                    name:'confirmation_testing_device_name',
                    id:'confirmation_testing_device_name',
                },
                {
                    label:'Device Serial # OR Lot # & Exp. Date',
                    type:'TextInput',
                    name:'confirmation_device_serial_lot_exp',
                    id:'confirmation_device_serial_lot_exp',
                },
                {
                    label:'Activation Time',
                    type: "TextInput",
                    placeholder:'Time',
                    // isAsync:true,
                    // options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                    // loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                    name:'confirmation_activation_time',
                    id:'confirmation_activation_time',
                },
                {
                    label:'Reading Time',
                    type: "TextInput",
                    placeholder:'Time',
                    // isAsync:true,
                    // options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                    // loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                    name:'confirmation_reading_time',
                    id:'confirmation_reading_time',
                },
                {
                    label:'Result',
                    type:'Decimal',
                    rangeMax:0.01,
                    name:'confirmation_result',
                    id:'confirmation_result',
                },
                {
                    label:'',
                    type:'Blank',
                    name:'blank',
                    id:'blank',
                },
                {
                    label:"Alcohol Technician's Company",
                    type:'TextInput',
                    name:'alcohol_technician_company',
                    id:'alcohol_technician_company',
                },
                {
                    label:"Alcohol Technician's Name (First Name and Last Name)",
                    type:'TextInput',
                    name:'alcohol_technician_name',
                    id:'alcohol_technician_name',
                },
                {
                    label:'Company Street Address',
                    type:'TextInput',
                    name:'company_street_address',
                    id:'company_street_address',
                },
                {
                    label:'Company City',
                    type:'TextInput',
                    name:'company_city',
                    id:'company_city',
                },
                {
                    label:'Company State',
                    type:'TextInput',
                    name:'company_state',
                    id:'company_state',
                },
                {
                    label:'Company Zip',
                    type:'TextInput',
                    name:'company_zip',
                    id:'company_zip',
                },
                {
                    label:'Company Phone Number',
                    type:'TextInput',
                    name:'company_phone_number',
                    id:'company_phone_number',
                },
                {
                    type:'Blank',
                    name:'blank'
                },
              
            ]
        },
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
                {
                    label:'Suggested Result',
                    type:'TextInput',
                    disabled:true,
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'suggested_status',
                },
                {
                    type:'Blank',
                    name:'blank'
                },
                {
                    label:'Final Determination',
                    type:'SelectList',
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'status',
                },
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
   const result = isSet(formData.result,'');
   const confirmationResult = isSet(formData.confirmation_result,'');
   let status = 'Not Started';
   if(result==='' || confirmationResult===''){
    status = 'Pending';
   }else if (Number(result) <= 0.01 || Number(confirmationResult) <= 0.01) {
        status = 'Passed';
    } else if (Number(result) >= 0.02 && Number(confirmationResult) >= 0.02) {
        status = 'Failed';
    } 
    return (key==='status')?{}:{'status':status,'suggested_status':status};
}