import React, { Component } from "react";
import WithRoutify from "../../../../../hoc/WithRoutify";
import Buttons from "../../../../../components/UI/Buttons";
import AddActivities from "./AddActivities";
import {capitalizeName, isSet,getLocation,formatPhoneNumberUS } from "../../../../../utils/commonUtils";
import TableGrid from "../../../../../components/UI/TableGrid";
import VisitsService from "../../../../../services/VisitsService";
import ActivitiesService from "../../../../../services/ActivitiesService";
import ActivityCharts from "../ActivityChart";
import Icons from "../../../../../components/UI/Icons";
import ActivityChartsContent from "../ActivityConsent";
import FileManagerService from "../../../../../services/FileManagerService";
import PatientChartsService from "../../../../../services/PatientChartsService";
import WithRouter from "../../../../../hoc/WithRouter";
import AddLab from "../../../AddLab";
import AddVaccine from "../../../AddVaccine";
import DropDownService from "../../../../../services/DropDownService";
import AssignCompany from "./AssignCompany";
import { PDFDocument } from 'pdf-lib';
import StatusBar from "./StatusBar";

class PhysicalTab extends Component {
  
  constructor(props) {
    super(props);
    const tableHeaders =  [
      // { label: '',key:'statusicon'},
      { label: 'Activity Name',key:'activity_name'},
      { label: 'Activity Type',key:'activity_type'},
      { label: 'Status',key:'status'},    
      { label: 'Consent Status',key:'consent'},
      { label: 'Action',key:'action',type:'Actions'},
      // Add more columns as needed
    ];
    this.state = {
      overAllStatus:'Pending',
      // Add any default state properties here if needed
      isModelOpen:false,
      isVaccineModelOpen:false,
      isLabModelOpen:false,
      isActivityChartOpen:false,
      isActivityConsentOpen:false,
      isAssignCompany: !isSet(this.props.visitDetails.company),
      assignCompanyOptions:[],
      activityChartDetails:{},
      tableRecords:{
        tableHeaders:tableHeaders,
        tableRows:{
            data:[]
        },
    },
    };
  }

  componentDidMount =() =>{
    
    // Add any logic you want to execute after the component is mounted
    this.fetchPhysicalList();
    // this.fetchOptions();
  }

  componentDidUpdate = (prevProps) =>{
    if(prevProps.visitDetails!==this.props.visitDetails){
      this.fetchPhysicalList();
    }
    // if (prevProps.tabId!=this.props.tabId) { //re-render when tab id changs 
    //   this.fetchPhysicalList();
    // }
    // Add any logic you want to execute when props are updated
    // You can compare this.props and prevProps to determine changes
  }

  componentWillUnmount() {
    // Add any cleanup logic when the component is about to unmount
  }
  addToogleHandler = ()=>{
    this.setState({isModelOpen:!this.state.isModelOpen})
  }
  assignTabToggleHandler = ()=>{ 
    this.setState({isAssignCompany:!this.state.isAssignCompany})
  }
  
  activityConsentToogleHandler = (chartDetails={})=>{
    this.setState({isActivityConsentOpen:!this.state.isActivityConsentOpen,activityChartDetails:chartDetails});
  }
  activityChartToogleHandler = (chartDetails={})=>{
    this.setState({isActivityChartOpen:!this.state.isActivityChartOpen,activityChartDetails:chartDetails});
  }
  render() {
    const { assignCompanyOptions,isAssignCompany,isModelOpen,isActivityChartOpen,activityChartDetails,overAllStatus,isActivityConsentOpen } = this.state;
    const {visitDetails} = this.props;
    const {company_physical} = visitDetails;
    let statusClr = 'blue';
     if(overAllStatus.toLowerCase()==='passed'){
      statusClr = 'green';
    } else  if(overAllStatus.toLowerCase()==='failed'){
      statusClr = 'red';
    } 
    // console.log('company_physical',visitDetails);
    return (
      <>
        {
          this.state.isLabModelOpen ?

            <AddLab
              modelTitle={"Add Lab"}
              isOpen={true}
              visitId={visitDetails.visit_id}
              mapping_id={activityChartDetails.mapping_id}
              modalToogleHandler={this.addLabToogleHandler}
              fetchRecords={this.props.fetchVisitDetails}
              fromChart={true}
            />
            :
            ''
        }
        {
          this.state.isVaccineModelOpen ?
            <AddVaccine
              modelTitle={"Add Vaccine"}
              isOpen={true}
              visitId={visitDetails.visit_id}
              mapping_id={activityChartDetails.mapping_id}
              modalToogleHandler={this.addVaccineToogleHandler}
              fetchRecords={this.props.fetchVisitDetails}
              fromChart={true}
            />
            : ''

        }
      
      {
        isModelOpen?
        ( isAssignCompany ? <AssignCompany
              modelTitle={"Assign Company"}
              isOpen={this.state.isAssignCompany}
              addToogleHandler={this.addToogleHandler}
              assignToggleHandler={this.assignTabToggleHandler}
              fetchVisitDetails={()=>this.props.fetchVisitDetails()}
              options={assignCompanyOptions}
              employee_id={this.props.visitDetails.employee.employee_id}
        /> :<AddActivities
              modelTitle={"Add Activities"}
              isOpen={this.state.isModelOpen}
              fetchVisitDetails={()=>this.props.fetchVisitDetails()}
              addToogleHandler={this.addToogleHandler}
              options={this.props.physicalOption}
            />)
        :(isActivityChartOpen)?
          <ActivityCharts 
            chartDetails={activityChartDetails}
            isOpen={isActivityChartOpen}
            visitDetails={this.props.visitDetails}
            fetchVisitDetails={()=>this.props.fetchVisitDetails()}
            stateList={this.props.stateList}
            employeeDetails={this.props.employeeDetails}
            lookupsOptions={this.props.lookupsOptions}
            companyDetails={this.props.companyDetails}
            toogleHanlder={this.activityChartToogleHandler} 
            />
        :(isActivityConsentOpen)?
          <ActivityChartsContent 
            chartDetails={activityChartDetails}
            isOpen={isActivityConsentOpen}
            visitDetails={this.props.visitDetails}
            fetchVisitDetails={()=>this.props.fetchVisitDetails()}
            stateList={this.props.stateList}
            employeeDetails={this.props.employeeDetails}
            lookupsOptions={this.props.lookupsOptions}
            companyDetails={this.props.companyDetails}
            toogleHanlder={this.activityConsentToogleHandler} />
        :''
      }
         
          <div className="sh_innerPortview ">
              <div className="sh_cardBox h-auto shadow-none visitboxheight border mb-0">
                <div className="sh_cardHeader">
                  <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                    <div className="d-flex col-1 px-0">
                      {/* <h6 className="text-sm mb-0">
                      {`${isSet(visitDetails?.visit_date,'')}`} 
                      <span> <Icons type={statusClr} 
                        {(this.state.tableRecords.tableRows.data.length>0 && isSet(visitDetails?.visit_date,'')!=='' )?overAllStatus:''}
                        />
                        </span>
                      </h6> */}
                      <span className={` badge bg-${statusClr}-soft text-${statusClr} def_badgeWidth`} >
                        {(this.state.tableRecords.tableRows.data.length>0 && isSet(visitDetails?.visit_date,'')!=='' )?overAllStatus:''}
                      </span>
                      </div>
                      {/* {(isSet(company_physical?.physical_name,'')!=='')?' - '+isSet(company_physical?.physical_name,''):''}  */}
                     <StatusBar
                     visitDetails={visitDetails}
                     />
                    <div className="addAction d-flex justify-content-between align-items-center printbtn-status">
                      <Buttons
                        title={"Add Physical / Drug Screen"}
                        className="btn btn-icon btn-transparent-dark mr-2"
                        acl={"any"}
                        iconType={"AddIconDetailView"}
                        clickHandler={this.addToogleHandler}
                      />
                     <a  className=" btn  btn-outline-primary dropdown dropdown-toggle position-static noArrow" data-bs-toggle="dropdown" title="Print">
                      <span >
                      <i className="fas fa-print text-md "/> Print Report                     
                      </span>                      

                      <div className="dropdown-menu dropdown-menu-left shadow" aria-labelledby="userDropdown">
                          <div className="dropdown-item" href="#" onClick={() => this.printClearanceReport()}>
                          Clearance Report
                          </div>
                          <div className="dropdown-item" href="#" onClick={()=>this.printConsentHandler('consent form')}>
                          Print Consents
                          </div>
                          
                        </div>                      
                        </a>

                      {/* <a className="btn btn-datatable btn-icon btn-transparent-dark" onClick={()=>this.printConsentHandler('Consent form')}>Print Consent</a> */}
                    </div>
                  </div>
                  {/* <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                  <h6 className="text-sm mb-0">
                    <span className="pr-1"><Icons type="green" /><span className="ml-1">Passed</span></span>
                    <span className="px-1"> <Icons type="red" /><span className="ml-1">Failed</span></span>
                    <span className="px-1"><Icons type="blue" /><span className="ml-1">Not Started</span></span>
                  </h6>
                  </div> */}
                </div>
                <div
                  className="sh_cardBody p-0"
                  style={{ height: "calc(100% - 120px)" }}>
                  <TableGrid {...this.state.tableRecords} />
                </div>
              </div>
             
          </div>
         
      </>
    );
  }
  printClearanceReport = async ()=>{
    const {params,Loader,Toast} = this.props;
    const visitId = params.visitId;
    Loader(true);
    try{
        const data = await PatientChartsService.getClearanceReportPDF(null,visitId);
        if (!data) {
          Toast.error("Clearance Report Not Found");
          Loader(false);
          return;
        }
        const pdfData = {
            file_content:data,
            original_name:`clearance-report`,
            mime_type:'application/pdf'
            
        }
        FileManagerService.printPdf(pdfData);
    }catch(e){
      console.log('e',e);
    }
    Loader(false);
}

  printMedicalExaminationPdfs = async (mappingId,type,pdfName)=>{
    const {params,Loader} = this.props;
    // const visitId = params.visitId;
    Loader(true);
    try{
      let data, existingPdfBytes;
      if(type== 'CERT'){
        data = await PatientChartsService.getMedicalExaminerCertificatePDFData(null,mappingId);
      }else if (type== 'DOT'){
        data = await PatientChartsService.getMedicalExaminationReportPDFData(null,mappingId);
      }
      existingPdfBytes = await fetch(`/assets/pdfs/${pdfName}.pdf`).then(res => res.arrayBuffer());
        console.log('Fetching PDF...');
        console.log(data);
        console.log('Fetched PDF file successfully:', existingPdfBytes);
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const form = pdfDoc.getForm();
        const fields = form.getFields();

    //   fields.forEach(field => {
    //   const name = field.getName();
    //   console.log('Field name:', name);
    //   console.log(field.constructor.name );
    // });

 Object.keys(data).forEach((key) => {
  try{ 
    const field = form.getField(key); // Get the field using the key
  
  if (field) {
    let value =  data[key].toString() ; // Ensure the value is a string or fallback
   
    const name = field.getName();
    //   console.log('Field name:', name);
    //   console.log(field.constructor.name );
    // console.log(value);

    if(name === 'examPhone' || name === 'driverPhone' || name === 'medicalPhone' || name === 'medicalPhoneState'){
     console.log('name' , name , value);
     value = formatPhoneNumberUS(value);
      console.log('name' , name , value);
    }
    
    if (value !== '--') {
    // Check field type using field.constructor.name
    switch (field.constructor.name) {
      case 'PDFTextField':  
      try{ 
          field.setText(value);
        }catch(e){
          console.log('e',field.getName(),field.constructor.name,e);
        }
        break;
      case 'PDFDropdown':
        try{ 
// Function to select the dropdown value
const selectDropdownValue = (field, value) => {
  const dropdownOptions = field.getOptions(); // Get all dropdown options
  
  // Get the full name from the abbreviation if it's a state; otherwise, use the value directly
  const stateKeyValue = getLocation(value); // e.g., { AK: 'Alaska' } or null
  const fullName = stateKeyValue ? Object.values(stateKeyValue)[0] : value;

  // console.log(`The key is: ${key}`);

if(stateKeyValue){
  const key = Object.keys(stateKeyValue)[0]; // Extracts 'AK'
 if (dropdownOptions.includes(key)) {
  field.select(key);
} else {
  console.warn(`The key '${key}' is not available in the dropdown.. Field Name - '${field.getName()}'`);
  console.warn(`Dropdown options - '${dropdownOptions}'`);
}
}
  // Check if the dropdown contains the passed value (e.g., 'AK' or 'fight')
  if (dropdownOptions.includes(value)) {
    field.select(value);
  }
  // Check if the dropdown contains the full name (e.g., 'Alaska')
  else if (dropdownOptions.includes(fullName)) {
    field.select(fullName);
  }
  // Log a warning if neither is found
  else {
    console.warn(`Value '${value}' or its full name '${fullName}' is not available in the dropdown.`);
  }
};

// Example Usage for States
selectDropdownValue(field, value);    
}catch(e){
  console.log('e',field.getName(),field.constructor.name,e);
}
        break;
      case 'PDFCheckBox':
        // Check/uncheck a checkbox based on the value
        try{
        if (value === 'Yes' || value === 1 || value=== '1') {
          // console.log('check');
          field.check();
        } else {
          field.uncheck();
        }
      }catch(e){
        console.log('e',field.getName(),field.constructor.name,e);
      }
        break;
      case 'PDFRadioGroup':

        try{
            const options = field.getOptions(); 
            console.log(options);     
            field.select(value);
                  
      }catch(e){
        console.log('e',field.getName(),field.constructor.name,e);
      }
         
        break;
      default:
        console.warn(`Field '${key}' is of unsupported type: ${field.constructor.name}`);
        break;
      }
   }
  } else {
    console.warn(`Field '${key}' not found in the form.`);
  }
}catch(e){
  console.log('e',e);
}
});

      if (type == 'CERT') {
        try{
        if(data.examinerSignature && data.examinerSignature !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.examinerSignature).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(0); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 160,  // X coordinate where the signature will appear
            y: 274,  // Y coordinate for the signature
            width: 100,  // Signature image width
            height: 30,  // Signature image height
          });
        }
        if(data.driverSignature && data.driverSignature !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.driverSignature).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(0); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 130,  // X coordinate where the signature will appear
            y: 130,  // Y coordinate for the signature
            width: 100,  // Signature image width
            height: 30,  // Signature image height
          });
        }
      }catch(e){
        console.log('e',e);
      }
      } else if (type == 'DOT') {
         try{
        if(data.driverSignature && data.driverSignature !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.driverSignature).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(1); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 130,  // X coordinate where the signature will appear
            y: 183,  // Y coordinate for the signature
            width: 60,  // Signature image width
            height: 18,  // Signature image height
          });
        }
        if(data.amendedExaminerSignature && data.amendedExaminerSignature !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.amendedExaminerSignature).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(3); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 250,  // X coordinate where the signature will appear
            y: 510,  // Y coordinate for the signature
            width: 60,  // Signature image width
            height: 20,  // Signature image height
          });
        }
        if(data.examinerSignatureFederal && data.examinerSignatureFederal !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.examinerSignatureFederal).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(3); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 180,  // X coordinate where the signature will appear
            y: 425,  // Y coordinate for the signature
            width: 70,  // Signature image width
            height: 20,  // Signature image height
          });
        }
        if(data.examinerSignatureState && data.examinerSignatureState !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.examinerSignatureState).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(4); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 160,  // X coordinate where the signature will appear
            y: 524,  // Y coordinate for the signature
            width: 70,  // Signature image width
            height: 20,  // Signature image height
          });
        }
      }catch(e){
        console.log('e',e);
      }
      
      }

        const pdfBytes = await pdfDoc.save();
    
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
      
        link.download = type === 'CERT' ? 'MedicalCertificate.pdf' : type === 'DOT' ? 'MedicalReport.pdf' : 'default.pdf';        
        link.click();

    }catch (e) {
      console.error('Error occurred while processing the PDF:', e);
    } finally {
      Loader(false); // Ensure the loader is hidden even if an error occurs
    }
  }
  
  printConsentHandler = async (labelName)=>{
    const {params,Loader,Toast} = this.props;
    const {activityChartDetails} = this.state;
    const activity_name = activityChartDetails.activity_name;
    const visitId = params.visitId;
    const queryfilters = {
        activity_name:(activity_name),
    }
    Loader(true);
    try{
        const data = await PatientChartsService.getPrintConsent(null,queryfilters,visitId);
        if (!data) {
          Toast.error("Consents Form Not Found");
          Loader(false);
          return;
        }
        const pdfData = {
            file_content:data,
            original_name:`${labelName}`,
            mime_type:'application/pdf'
            
        }
        FileManagerService.printPdf(pdfData);
    }catch(e){
      console.log('e',e);
    }
    Loader(false);
}

  
  fetchPhysicalList = ()=>{
    if(this.state.isAssignCompany){
      this.getAssignCompanyOptionsForSelfRegister();
    }
    const {visitDetails} = this.props;
  let tableRows = [];
  let overAllStatus = visitDetails[0].overall_status;
  let passedCnt = 0;
  // let failedCnt = 0;
  let totalCnt= 0;
  isSet(visitDetails?.mapping_activity,[]).forEach((item,itemIndex)=>{
    totalCnt = itemIndex +1;
    let status  = item.status;
    if (item.status=='Completed/Administrated') {
      item.status= 'passed';
      status='passed'
    }
    if (item.status=='Ordered') {
      item.status='pending'
      status='pending'
    }
    let statusClr = 'blue';
     if(status.toLowerCase()==='passed' || status ==='The subject Passed the fit test'){
      statusClr = 'green'; 
      passedCnt++;
    } else  if(status.toLowerCase()==='failed' || status ==='The subject Failed the fit test' ){
      statusClr = 'red';
      // failedCnt++;
      overAllStatus = 'Failed';
    }
    const consentIcon = (isSet(item?.activity_consent_declination?.id,null)!==null)?'ConsentEdit':'ConsentAdd';
    // console.log("item",item,item.activity.activity_type);
    tableRows = [
      ...tableRows,
      {
        physicalIndex: itemIndex,
        isHeading: false,
        rowId: item.physical_id,
        data: {
          isActive: false,
          activity_name: {
            value:
              <>
                <a className="px-1" title={status} >
                  <Icons type={statusClr} />
                </a>
                {item?.activity?.activity_name}

              </>
          },
          status:{
            title:status,
            value:<>
            <div className="d-flex justify-content-left">
            {/* <div className="d-flex Justify-content-end px-2"><Icons type={statusClr} /></div> */}
            <div >{capitalizeName(item.status.toLowerCase())}</div>
            </div>
            </>          
            },
          //isSet(item?.activity.active_consents,null)
          activity_type:item?.activity?.activity_type,
          consent:{
            value:(isSet(item?.activity.active_consents,null)!==null)?
              <Icons type={consentIcon} clickHandler={()=>this.activityConsentToogleHandler({...item?.activity,mapping_id:item.mapping_id,activityConsentDeclination:item?.activity_consent_declination})}/>
              :<>-</>
          }
          ,
          action: [    
              {
                className: "btn px-0",      
                iconType:"Certificate",
                acl: ( item.activity.activity_type === 'Provider Exam' && visitDetails.providerExam.find(provider => provider.mapping_id === item.mapping_id)?.isFederalOrState === true ) ?'any': '',
                title: "Certificate",
                disableButtonTitle:"Certificate",
                iconColor:'#000',
                iconDropDownList:[{
                  label:'Examiner Certificate',
                  clickHandler:()=>this.printMedicalExaminationPdfs(item.mapping_id,'CERT','MedicalExaminationCertificate')
              },
              {
                  label:'Examination Report (DOT)',
                  clickHandler:()=>this.printMedicalExaminationPdfs(item.mapping_id,'DOT','MedicalExaminationReport')
              }]
             }
             ,         
              (item.activity.activity_type === 'Lab' && visitDetails.laboratory.find(lab => lab.mapping_id === item.mapping_id)?.hasOrder === false) ||
              (item.activity.activity_type === 'Vaccine' && visitDetails.vaccines.find(vaccine => vaccine.mapping_id === item.mapping_id)?.hasOrder === false)||
              (item.activity.activity_type === 'Lab - Point of Care' && visitDetails.laboratory.find(lab => lab.mapping_id === item.mapping_id)?.hasOrder === false)
              ?
              {
                className: "btn btn-icon btn-transparent-dark",
                iconType: "AddPlus",
                title: "Add Order",
                label: "",
                acl: "any",
                clickHandler: () => this.orderLabToogleHandler(item.activity.activity_type,item),
              }
              :
              {
                className: "btn btn-icon btn-transparent-dark",
                iconType: "ActivityChart",
                acl: "any",
                title: 'Chart',
                clickHandler: () => this.activityChartToogleHandler({ ...item.activity, mapping_id: item.mapping_id })
              }
            
              ,
            {
              className:
                "btn btn-icon btn-transparent-dark",
              iconType: "Remove",
              acl: "any",
              title: "Delete",
              isDelete:true,
              clickHandler: () =>this.deleteHandler(item.mapping_id),
            }, 
          ],
        },
      },
    ];
  })
if(totalCnt==passedCnt){
  overAllStatus = 'Passed';
}
// if(failedCnt>0){
//   overAllStatus = 'Failed';
// }
  this.setState({
    overAllStatus:overAllStatus,
    tableRecords: {
      ...this.state.tableRecords,
      tableRows: {
        type: "default",
        data: tableRows,
      },
    },
  });
  }
  orderLabToogleHandler = (type = '', chartDetails = {}) => {
    if (type == 'Lab'||type=='Lab - Point of Care') {
      this.addLabToogleHandler(chartDetails);
    } else if (type == 'Vaccine') {
      this.addVaccineToogleHandler(chartDetails);
    }
  };
  addVaccineToogleHandler=(chartDetails={})=>{
    this.setState({isVaccineModelOpen:!this.state.isVaccineModelOpen,activityChartDetails:chartDetails})
  }
  addLabToogleHandler=(chartDetails={},chartOpen=false)=>{
    this.setState({isLabModelOpen:!this.state.isLabModelOpen,activityChartDetails:chartDetails});
  }

  getAssignCompanyOptionsForSelfRegister= async()=>{
    
    const {visitDetails} = this.props;
    const {employee} =visitDetails;
    const clinic_id = employee.clinic_id;
    const assignCompanyOptions = await DropDownService.getAssignCompanyOptionsForSelfRegister(clinic_id);
    let options=[{label:'-Select Company-',value:''}];
      assignCompanyOptions.forEach(val => {
        options.push({label:val.name,value:val.company_uuid});
      });
    //activity bundle
    this.setState({assignCompanyOptions:options})
  }

  fetchOptions=async()=>{
    //array of key for fetch 
    //fetch key value and store in keysOptions
    let activitiesResponse=await ActivitiesService.getActivities();
    // console.log('activitiesResponse',activitiesResponse);
      let options=[{label:'-Select-',value:''}];
      activitiesResponse.forEach(val => {
        options.push({label:val.activity_name,value:val.actitivity_id});
      });
    //activity bundle
    this.setState({options:options})
  }
  deleteHandler = async (id)=>{
    VisitsService.deleteActivityInChartInVisits(id)
    .then((res) => {
      //toast and show error logic
      this.props.fetchVisitDetails();
      this.props.Toast.success('Physical Deleted Successfully');
    })
    .catch((err) => {
      //
    })
    .finally(() => {
      //
    });
  }
}

export default  WithRouter(WithRoutify(PhysicalTab));
