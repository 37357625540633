import React, { useState } from 'react';
import StepOne from './SelfRegisterStepOne';
import StepTwo from './SelfRegisterStepTwo';
import StepThree from './SelfRegisterStepThree';
import Modal from "react-bootstrap/Modal";
import Buttons from '../../components/UI/Buttons';
import { _t } from '../../utils/i18nUtils';
import {
    useNavigate,useParams
} from "react-router-dom";
import { createPortal } from "react-dom";
import Alert from '../../components/UI/ConfirmBox/Alert';
import { isSet, isObject} from '../../utils/commonUtils';
import AuthService from '../../services/AuthService';
import WithRoutify from '../../hoc/WithRoutify';
import DropDownService from '../../services/DropDownService';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';





const SelfRegisterModal = (props) => {

    const { clinicId } = useParams();
    let extratedClinicId = clinicId;
    if(isSet(clinicId) && clinicId.split('=').length>0){
        extratedClinicId = clinicId? clinicId.split('=')[1] : '';
    }
    
  

    const [step, setStep] = useState(1);  
    const previous = _t('Previous');
    const [formData, setFormData] = useState({clinic_id:isSet(extratedClinicId) ? extratedClinicId : ''});
    const [showAlert, setShowAlert] = useState(false);
    const [validation, setValidation] = useState({});
    const [clinicsListOptions, setClinicsListOptions] = useState([]);
    const [clinicLocationOptions, setClinicLocationOptions] = useState([]);
    const [insuranceListOptions, setInsuranceListOptions] = useState([]);
    const navigate = useNavigate();


    useEffect(() => { 
        fetchClinicList();
        // Check if clinicId is present
        fetchInsuranceList();

    }, []);

    useEffect(() => {
        if (isSet(formData.clinic_id)) {
            fetchClinicLocationList(formData.clinic_id);
        }else{
            setClinicLocationOptions([]);
        }
    }, [formData.clinic_id]);

    const fetchInsuranceList = async ()=>{
        let options = [{label:'-Select-',value:''}]
        try{  
            const res = await DropDownService.getInsuranceDropDownSelfRegisteration();
            const resData = res.data;
            resData.forEach((item)=>{
                options.push({
                    label:item.insurance_name,
                    value:item.insurance_company_id
                })
            });
            setInsuranceListOptions(options);
        }catch(e){
        }
    }


    const fetchClinicLocationList = async (clinicId) => {
        try {
            const data = await DropDownService.getClinicLocationsForSelfRegistration(clinicId);
            let clinicLocationInArr = [{ label: '-None-', value: '' }];
            isSet(data, []).forEach((item, itemIndex) => {
                clinicLocationInArr.push({
                    label: item.facility_name,
                    value: item.id,
                    key: itemIndex
                })
            });
            setClinicLocationOptions(clinicLocationInArr);
        } catch (e) {

        }
    }

    const fetchClinicList = async (value="",callback=null) => {
        let res = await DropDownService.getClinicListForSelfRegistration(value);

        let options = [{ label: '-Select Clinic-', value: '' }];
        res && isSet(res, []).forEach((itm) => {
            options.push({ label: itm.name, value: itm.clinic_id });
        });
        setClinicsListOptions(options);
        if(isSet(callback)){
            callback(options);
        }
    };

    const handleNextStep = async () => {
        console.log(step);
        let updateValidation = validation;


        const payload = {
            ...formData
        }
        if (!payload.notification_consent) {
            props.Toast.error("To continue, please agree to receive notifications.");
            return;
        }
        // if (!payload.captcha) {
        //     props.Toast.error("To continue, please fill captcha");
        //     return;
        // }                
        const formDataPayload = new FormData();
        for (const i in payload) {
            formDataPayload.append(i, payload[i]);
        }
        formDataPayload.append('step', step)
        updateValidation = { ...validation };
        // return false;
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        try{
            props.Loader(true);
            const data = await AuthService.loginWithCheckingOTP(formDataPayload, headers);
            if (data.status && data.status === "ERROR") {
                if (isObject(data.data)) {
                    for (let key in data.data) {
                        updateValidation = {
                            ...updateValidation,
                            [key]: data.data[key].join(","),
                        };
                    }
                } else {
                    props.Toast.error(data.data);
                    props.Loader(false);
                    navigate("/login/forgetPassword");
                }
                if (Object.keys(updateValidation).length > 0) {
                    setValidation(updateValidation);
                    props.Loader(false);
                    return;
                }

            } else if (step == 3) {
                
                props.Loader(false);
                props.Toast.success('Verification Code Successfully Verified.Please Reset Your System Password to Login');
                navigate("/login/forgetPassword");
                
                // const password = getDecryptedPassword(); 
                // const credentials = { email: data.data.email, password: password };
                    
                    
                    // const userData = await AuthService.login(credentials);
                    // // console.log('userData',userData);
                    // if (userData.status && userData.status === 'ERROR') {
                    //     props.Loader(false);
                    //     setValidation(isSet(userData.data.error, 'Authentication failed') + '  The information you have provided cannot be authenticated. Check your login information and try again');
                    //     props.Toast.error('Something went wrong.Please try to login through forget password');
                    //     navigate("/login/forgetPassword");
                    // }else{
                    //     props.Loader(false);
                    //     props.Toast.success('OTP Verified Successfully');
                    // }
                    // const isAuthenticated = AuthService.isAuthenticated();
                    // // console.log('is authenticated',isAuthenticated);
                    // if (isAuthenticated === true) {

                    //     const profileData = await UserService.getUserProfile();
                    //     if (isSet(profileData.organization_id, null) !== null) {
                    //         OrganizationService.storeOrgId(profileData.organization_id);
                    //     }
                    //     AuthService.storeProfileId(profileData.id);
                    //     dispatch(setProfileDetails({ key: profileData.id, value: profileData }));
                    //     props.Loader(false);
                    //     let stateListResponse = await UserService.getUserStateList();
                    //     let states = [{ label: '-Select State-', value: '' }];
                    //     stateListResponse && stateListResponse.forEach((val) => {
                    //         states.push({ label: val.code, value: val.code })
                    //     });
                    //     dispatch(setProfileStateList(states));
                    //     navigate("/");

                    // }

                

                // props.Loader(false);
                }else {
                    setFormData({...formData,employee_id:data?.data.employee_id ||null});
                    props.Loader(false);
                    let nextStep = step + 1;
                    if (data?.data.employee_id ) {
                       nextStep = nextStep+1;
                    }
                    setStep(nextStep); 
                    
                }

        }catch(error){
            props.Loader(false);
            props.Toast.error('Something went wrong.Please try to login through forget password');
            navigate("/login/forgetPassword");
        }
        
    };

    const handlePreviousStep = () => {
        let prevStep = step -1 ;
        if (step === 3) {
            setFormData({
                ...formData,
                ['otp']: '',
            });
        }
        if (formData.employee_id) {
            prevStep = prevStep-1;
         }
        setStep(prevStep);

    };

    const handleClose = () => {
        setShowAlert(prevShowAlert => !showAlert);

    };
    const closeHandler = () => {
        navigate('/');
    }

    const changeHandler = async (val, fieldName, e) => {
        let updatedFormData = { ...formData };
        
        updatedFormData[fieldName]=val;
        
        setFormData(updatedFormData);
        setValidation({ ...validation, [fieldName]: "" });
    };

    
    
    const clearOtp = async() => {
        setFormData({
            ...formData,
            ['otp']: '',
        });
    }


    return (
        <>
            {showAlert &&
                createPortal(
                    <Alert
                        onDelete={closeHandler}
                        onClose={handleClose}
                        content={
                            "Are you sure you want to cancel? This action will not save any of the changes made."
                        }
                        yesButtonName={"Confirm"}
                        noButtonName={"Cancel"}
                        type="warning"
                    />,
                    document.getElementsByClassName("sh_modalBox")[0]
                )}
            <Modal
                show={true}
                // onHide={false}
                size="xl"
                dialogClassName="hResponsive"
                contentClassName="h-100"
                backdrop="static"
                keyboard={false}
            >

                {/* <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} role="dialog">
                <div className="modal-dialog hResponsive modal-xl">
                    <div className="modal-content h-100">
                        <div className="sh_modalBox formbold-form-wrapper"> */}
                <div className='sh_modalBox'>
                    <Modal.Header >

                        <Modal.Title style={{ margin: '0 auto' }}>
                            <div className="formbold-steps" >
                                <ul className="justify-content-center">
                                    <li className={`formbold-step-menu1 ${step === 1 ? 'active' : ''}`}>
                                        <span>1</span>
                                        <h6 className="text-lg mb-0 ">{_t('BasicDetails')}</h6>
                                    </li>
                                    <li className={`formbold-step-menu2 ${step === 2 ? 'active' : ''}`}>
                                        <span>2</span>
                                        <h6 className="text-lg mb-0 ">{_t('PatientDetails')}</h6>
                                    </li>
                                    <li className={`formbold-step-menu3 ${step === 3 ? 'active' : ''}`}>
                                        <span>3</span>
                                       <h6 className="text-lg mb-0 ">{_t('Verification')}</h6> 
                                    </li>
                                </ul>
                            </div>
                        </Modal.Title>
                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="">
                            {/* {step === 1 && <StepOne formData={formData} fetchClinicOptions={fetchClinicList} clinicOptions={clinicsListOptions} clinicLocationOptions={clinicLocationOptions} validation={validation} changeHandler={changeHandler} />}
                            {step === 2 && <StepTwo formData={formData} validation={validation} changeHandler={changeHandler} />}
                            {step === 3 && <StepThree formData={formData} validation={validation} changeHandler={changeHandler} />} */}
                            { <StepOne formData={formData} fetchClinicOptions={fetchClinicList} isClinicSelfRegistration={extratedClinicId?true:false} clinicOptions={clinicsListOptions} clinicLocationOptions={clinicLocationOptions} isVisible={step === 1 ? true : false} validation={validation} changeHandler={changeHandler} />}
                            { <StepTwo formData={formData} validation={validation} isVisible={step === 2 ? true : false} insuranceListOptions={insuranceListOptions} changeHandler={changeHandler} />}
                            { <StepThree clearOtp={clearOtp} formData={formData} validation={validation} isVisible={step === 3 ? true : false} changeHandler={changeHandler}  setValidation={setValidation}/>}
                        </div>
                    </Modal.Body>


                    <Modal.Footer className="d-flex justify-content-center">
                        {step > 1 && (
                            <Buttons
                                className="formbold-back-btn btn btn-secondary border-0"
                                clickHandler={() => handlePreviousStep()}
                                label={previous}
                                acl={"any"}
                            />
                        )}
                        <Buttons
                            clickHandler={() => handleNextStep()}
                            className="formbold-btn btn btn-primary btn-user"
                            // acl="any"
                            acl={step === 3 ? formData?.otp?.length>0?'any':'':'any'}
                            // disabled={true}
                            // disabled={step==3 ? true : false}
                            label={step === 3 ? _t('Submit') : _t('Next')}
                        />
                    </Modal.Footer>
                </div>

                {/* </div></div>
                </div></div> */}
            </Modal>
        </>


    );
};

export default WithRoutify(SelfRegisterModal);