import { Modal } from "react-bootstrap";
import FormInputs from "../../../../../../components/UI/FormInputs";
import Buttons from "../../../../../../components/UI/Buttons";
import { useState } from "react";
import Employeeervice from "../../../../../../services/EmployeeService";
import { isObject, isSet } from "../../../../../../utils/commonUtils";
import WithRoutify from "../../../../../../hoc/WithRoutify";
import VisitsService from "../../../../../../services/VisitsService";

const AddDocumentModal = (props) => {
    const { visitDetails, mappingId, activityId,addFormHandler,Loader,Toast} = props;
    const [validation, setValidation] = useState({});
    const [file, setFile] = useState({});
    const { company_id, organization_id, employee_id ,visit_id} = visitDetails;
    const submitHandler = async () => {
        let updateValidation = { ...validation };
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('employee_id', employee_id);
            formData.append('visit_id', visit_id);
            formData.append('activity_id', activityId);
            formData.append('visit_mapping_id', mappingId);
            formData.append('company_id', company_id);
            formData.append('organization_id', organization_id);
            formData.append('document_type', 'CHART_DOCUMENT');
            Loader(true);
            const data = await VisitsService.addPhysicalAttachements(formData);
            Loader(false);
            if (data.status && data.status == "ERROR") {
                if (isObject(data.data)) {
                    for (let key in data.data) {
                        updateValidation = {
                            ...updateValidation,
                            [key]: data.data[key].join(',')
                        }
                    }
                } else {
                    props.Toast.error(data.data);
                }

                if (Object.keys(updateValidation).length > 0) {
                    setValidation(updateValidation);
                    return false;
                }
            } else {
                setFile('');
                setValidation({});
                addFormHandler();
                let messages = `Document Added Successfully`;
                Toast.success(messages);
            }

        } catch (error) {
            console.error("Error submitting document:", error);
        }
    }
    return (
        <>
            <Modal
                show={true}
                keyboard={false}
                onHide={addFormHandler}
                contentClassName='h-100'
                backdrop="static"
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h6 className="text-lg mb-0 font-weight-bold">
                            Add Document
                        </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="text-sm text-truncate mb-0 opacity-150">{"Document"}</label>
                    <FormInputs
                        type='FileUpload'
                        title="Field Document"
                        name='file'
                        className=''
                        value={isSet(file, '')}
                        changeHandler={(val) => {
                            setFile(val);
                            setValidation({});
                        }}
                        
                    />
                    {isSet(validation['file'], '') !== '' ? <span className="text-danger m-1">
                        {isSet(validation['file'], '')}</span> : ''}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Buttons
                        type='Single'
                        className="btn btn-primary"
                        clickHandler={() => submitHandler()}
                        label='Upload'
                        acl={'any'}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default WithRoutify(AddDocumentModal);
