import React from 'react';

const CrossCloseIcon= ({size,color}) => {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="close">
        <path fill={color} d="M9.016 40.837a1.001 1.001 0 0 0 1.415-.001l14.292-14.309 14.292 14.309a1 1 0 1 0 1.416-1.413L26.153 25.129 40.43 10.836a1 1 0 1 0-1.415-1.413L24.722 23.732 10.43 9.423a1 1 0 1 0-1.415 1.413l14.276 14.293L9.015 39.423a1 1 0 0 0 .001 1.414z"></path>
    </svg>
  );
};
CrossCloseIcon.defaultProps = {
  size: 20,
  color:'#5f6368'
};
export default CrossCloseIcon