import React, { Component } from "react";
import WithRoutify from "../../../hoc/WithRoutify";
import WithRouter from "../../../hoc/WithRouter";
import { capitalizeName, decryptString, toQueryString } from "../../../utils/commonUtils";
import TableGrid from "../../../components/UI/TableGrid";
import EmployeeService from "../../../services/EmployeeService";
import { isSet } from "../../../utils/commonUtils";
import Icons from "../../../components/UI/Icons";
import ActivityCharts from "../../EmployeeVisitContainer/EmployeeVisitDetailView/RightContent/ActivityChart/index";
import { getStatusAndColor } from "../../../utils/visitUtils";
import VisitsService from "../../../services/VisitsService";
import withReduxState from "../../../hoc/wReduxState";
import LookupService from "../../../services/LookupService";


class ScreeningsList extends Component {
  constructor(props) {
    super(props);
    const tableHeaders = [
      { label: 'Activity Name', key: 'activity_name' },
      { label: 'Activity Type', key: 'activity_type' },
      { label: 'Status', key: 'status' },
      { label: 'Action', key: 'action', type: 'Actions' },
    ];
    this.state = {
      isActivityChartOpen: false,
      activeChart: null,
      lookupsOptions: {},
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status: 1000,
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "created_at",
          sort_order: "asc",
          search: {},
        },
      },
    };
  }

  componentDidMount = () => {
    this.fetchPhysical();
    this.fetchLookups();
  }

  componentDidUpdate(prevProps) {
    // Add any logic you want to execute when props are updated
    // You can compare this.props and prevProps to determine changes
  }

  componentWillUnmount() {
    // Add any cleanup logic when the component is about to unmount
  }




  render() {

    const { tableRecords, isActivityChartOpen, activeChart, lookupsOptions } = this.state;
    const { patientDetails } = this.props;

    return (
      <>
        {
          isActivityChartOpen ?
            <ActivityCharts
              chartDetails={activeChart.activity_data}
              isOpen={false}
              visitDetails={activeChart.visit_data}
              fetchVisitDetails={this.fetchPhysical}
              stateList={this.props.stateList}
              employeeDetails={activeChart.employee_data}
              lookupsOptions={lookupsOptions}
              companyDetails={activeChart.company_data}
              toogleHanlder={this.activityChartToogleHandler}
            />
            :
            <></>

        }
        <div className="w-100 p-3">
          <div className="sh_cardBox h-auto shadow-none border">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                <h6 className="text-sm mb-0">Screenings & Tests - {patientDetails.patientName}</h6>
              </div>
            </div>
            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchPhysical(filters)
                }
                {...tableRecords} />
            </div>
          </div>
        </div>
      </>
    );
  }
  fetchPhysical = async (query = {}) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({
      ...tableRecords.pagination,
      ...query,
    });
    const payload = {
      type: 'physical',
      hasPagination: true,
      employee_id: this.props.patientDetails.employee_id,
      ...queryString
    }
    let tableRows = [];
    this.props.setLoader(true);
    const response = await EmployeeService.getEmployeeCharts(payload);
    this.props.setLoader(false);
    console.log('response', response);

    response.data.forEach((item, itemIndex) => {
      const { status, statusClr } = getStatusAndColor(item.status);
      let activityData = {
        ...item.activity,
        mapping_id: item.mapping_id,
        visit_id: item.visit_id
      };
      const chartData = {
        activity_data: activityData,
        company_data: item.visit.employee.company,
        visit_data: item.visit,
        employee_data: item.visit.employee
      };
      tableRows = [
        ...tableRows,
        {
          physicalIndex: itemIndex,
          isHeading: false,
          rowId: item.physical_id,
          data: {
            isActive: false,
            activity_name: {
              value:
                <>
                  <a className="btn btn-icon btn-transparent-dark mr-1" title={status} >
                    <Icons type={statusClr} />
                  </a>
                  {item?.activity?.activity_name}

                </>
            },
            status: {
              title: status,
              value: <>
                <div className="d-flex justify-content-left">
                  <div >{capitalizeName(item.status.toLowerCase())}</div>
                </div>
              </>
            },
            activity_type: item?.activity?.activity_type,
            action: [
              {
                className: "btn btn-icon btn-transparent-dark",
                iconType: "ActivityChart",
                acl: "any",
                title: 'Chart',
                clickHandler: () => this.activityChartToogleHandler(chartData)
              }
              ,
              {
                className:
                  "btn btn-icon btn-transparent-dark",
                iconType: "Remove",
                acl: "any",
                title: "Delete",
                isDelete: true,
                clickHandler: () => this.deleteHandler(item.mapping_id),
              },
            ],
          },
        },
      ];
    });
    this.setState({
      tableRecords: {
        ...this.state.tableRecords,
        tableRows: {
          type: "default",
          data: tableRows,
        },
        pagination: {
          ...this.state.tableRecords.pagination,
          ...query,
          totalRecords: response?.total,
        },
      },
    });
  }
  activityChartToogleHandler = (chartDetails = {}) => {
    this.setState({ isActivityChartOpen: !this.state.isActivityChartOpen, activeChart: chartDetails });
  }
  deleteHandler = async (id) => {
    VisitsService.deleteActivityInChartInVisits(id)
      .then((res) => {
        this.props.Toast.success('Activities Deleted Successfully');
        this.fetchPhysical();
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  }
  fetchLookups = async () => {
    const [chartResult, shippingService] = await Promise.all([
      LookupService.getLookupByKey('chart_result'),
      LookupService.getLookupByKey('shipping_service'),
    ]);
    let shippingServiceOptions = [{ label: '-None-', value: '' }];
    isSet(shippingService, []).forEach((item) => {
      shippingServiceOptions.push({
        label: item.value,
        value: item.value
      });
    });
    let chartResultOptions = [{ label: '-None-', value: '' }];
    isSet(chartResult, []).forEach((item) => {
      chartResultOptions.push({
        label: item.value,
        value: item.value
      });
    });
    this.setState({
      lookupsOptions: {
        shippingService: shippingServiceOptions,
        chartResult: chartResultOptions
      }
    })
  }
}

export default withReduxState(WithRouter(WithRoutify(ScreeningsList)));
