import { OK } from '../utils/constant';
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';


const VaccineAdministratorService = {
  
  saveVaccineAdministration: wOrgIdMiddleware(async (url = '', data) => {
    try {
      const response = await http.POST(`${url}vaccine-administration`, data);
  
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  getVaccineAdministration: wOrgIdMiddleware(async (url = '', query) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}vaccine-administration?${queryString}`);
      
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  getVaccineAdministrationById: wOrgIdMiddleware(async (url = '', id) => {
    try {
      const response = await http.GET(`${url}vaccine-administration/${id}`);
      
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      // throw error;
    }
  }, true),
  
  updateVaccineAdministration: wOrgIdMiddleware(async (url = '', id, data) => {
    try {
      const response = await http.PUT(`${url}vaccine-administration/${id}`, data);
      if (response.code === OK) {
        return response.data;
      }else{
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
    }
  }, true),
  deleteVaccineAdministration: wOrgIdMiddleware(async (url = '', id) => {
    try {
      const response = await http.DELETE(`${url}vaccine-administration/${id}`);
      
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return null;
      }

    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
      throw error;
    }
  }, true),
};

export default VaccineAdministratorService;