import http from './http';
import { OK } from '../utils/constant';
import { getBrowserInfo } from '../utils/commonUtils';

const FileManagerService = {
  uploadFile: async (data) => {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      const response = await http.POST(`upload-files`, data, headers)
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  downloadFile: async (fileId) => {
    try {
      const response = await http.GET(`download-files/${fileId}`, { responseType: 'blob' });
      if (response.code === OK) {
        FileManagerService.downloadBlob(response.data);
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  downloadBlob: (data) => {

    if (!data.file_content || !data.mime_type || !data.original_name) {
      // console.log('Incomplete file data:', data);
      return;
    }

    // Convert the base64 string to a Uint8Array
    const binaryString = window.atob(data.file_content);
    const binaryData = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      binaryData[i] = binaryString.charCodeAt(i);
    }
    // console.log('binaryData',binaryData);
    // Create a blob from the binary data
    const blob = new Blob([binaryData], { type: data.mime_type });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    // console.log('url',url,link);
    link.href = url;
    link.setAttribute('download', data.original_name);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    // link.click();
    // Trigger a click event programmatically
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    link.dispatchEvent(clickEvent);

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  },
  downloaPdf: (data) => {
    try {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.original_name || 'file'}.pdf`);
      document.body.appendChild(link);
      link.click();
    }
    catch {

    }
  },
  printPdf1: (data) => {
    try {
      if (!data.file_content || !data.mime_type || !data.original_name) {
        console.log('Incomplete file data:', data);
        return;
      }

      // Convert the base64 string to a Uint8Array
      const binaryString = window.atob(data.file_content);
      const binaryData = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        binaryData[i] = binaryString.charCodeAt(i);
      }
      // console.log('binaryData',binaryData);
      // Create a blob from the binary data
      const blob = new Blob([binaryData], { type: data.mime_type });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error while opening PDF:', error);
    }

  },
  printPdf:(data)=>{
    try {
      if (!data.file_content || !data.mime_type || !data.original_name) {
        console.log('Incomplete file data:', data);
        return;
      }
      const {isMobile}=getBrowserInfo();
      if (!isMobile) {
        FileManagerService.printPdf1(data);
        return;
      }
      const binaryString = window.atob(data.file_content);
      const binaryData = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        binaryData[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([binaryData], { type: data.mime_type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      // a.style.display = 'none';
      a.href = url;
      a.download = data.original_name;
      document.body.appendChild(a);
  
      // Programmatically click the link to trigger the download
      a.click();
  
      // Remove the link when done
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error while opening PDF:', error);
    }
  },
  downloadCsv:(base64Csv,filename='sample.csv')=>{
    const csvContent = atob(base64Csv); // Decode base64 to binary
    const blob = new Blob([csvContent], { type: 'text/csv' });
    // Create a link element
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    // Append the link to the body
    document.body.appendChild(a);

    // Programmatically click the link to trigger the download
    a.click();

    // Remove the link when done
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
};

export default FileManagerService;
/*https://stackoverflow.com/questions/72095610/download-pdf-in-react-js*/