import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import PatientDetails from "./PatientDetails";
import Labs from "./Labs";
import Documents from "./Documents";
import Buttons from "../../../components/UI/Buttons";
import EmployeeService from "../../../services/EmployeeService";
import WithRoutify from "../../../hoc/WithRoutify";
import { capitalizeName, isObject, isSet } from "../../../utils/commonUtils";
import AssignCompany from "../../../components/Modal/AssignCompany";

const MAIN_TABS = [
    { label: "Patient Details", component: PatientDetails },
    { label: "Documents", component: Documents },
    { label: "Proof of Labs or Vaccinations", component: Labs }
];

class EmployeeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            formData: {},
            validations: {},
            company_assigned:1,
        };
    }

    componentDidMount = () => {
        const { employeeId } = this.props;
        this.fetchEmployeeDetails(employeeId);
    };

    render() {
        const { modalToogleHandler } = this.props;
        const { activeTab, formData } = this.state;
        console.log('formadata',formData);
        

        return (
            <>
                {
                    this.state.company_assigned == 0 ?
                        <AssignCompany
                            employeeId={this.props.employeeId}
                            modleToogleHandler={()=>this.fetchEmployeeDetails(this.props.employeeId)}
                        />
                        : ''
                }
            <Modal
                show={true}
                onHide={() => modalToogleHandler(null)}
                size="xl"
                dialogClassName="hResponsive"
                contentClassName="h-100"
                backdrop="static"
                keyboard={false}
            >
                <div className="sh_modalBox">
                    <Modal.Header closeButton className="p-2">
                        <div className="tabs-wrapper">
                            <ul className="nav nav-borders nav-tabs border-0" role="tablist">
                                {MAIN_TABS.map((item, index) => (
                                    <li key={index}>
                                        <a
                                            href="#!"
                                            onClick={() => this.setActiveTabHandler(index)}
                                            className={`cursor-pointer nav-link ${index === activeTab ? "active" : ""}`}
                                        >
                                            {item.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#eef2f8" }}>
                        <div className="card-body tab-content p-0">
                            {MAIN_TABS.map((item, index) => {
                                const TabComponent = item.component;
                                return (
                                    <div key={index} className={`tab-pane ${index === activeTab ? "show active" : ""}`}>
                                        <TabComponent
                                            {...this.props}
                                            formData={formData}
                                            onChangeHandler={this.onChangeHandler}
                                            fetchEmployeeDetails={this.fetchEmployeeDetails}
                                            validations={this.state.validations}
                                            company_id={formData?.company_id ||''}
                                            clinic_id={formData.clinic_id}
                                            employeeId={this.props.employeeId}

                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Modal.Body>
                    <div className="d-flex justify-content-center modal-footer">
                        <Buttons
                            type="Single"
                            className="btn btn-primary"
                            clickHandler={this.updateEmployeeDetails}
                            label="Update"
                            acl={"employee-edit"}
                        />
                    </div>
                </div>
            </Modal>
            </>
        );
    }

    setActiveTabHandler = (tabIndex) => {
        this.setState({
            activeTab: tabIndex
        });
    };

    fetchEmployeeDetails = async (employeeId,load=null) => {
        const { Loader } = this.props;
        Loader(true);
        try {
            const data = await EmployeeService.getEmployee(employeeId);
              
            if (load == 'LAB') {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        proof_documents: data.proof_documents
                    }
                });
            }else {
                this.setState({ formData: data,company_assigned:data.company_assigned});
            }
                        
            Loader(false);
        } catch (error) {
            Loader(false);
            console.error("Error fetching employee details:", error);
        }
    };

    updateEmployeeDetails = async () => {
        const { Loader } = this.props;
        Loader(true);

        const { formData } = this.state;

        // Validation object to hold validation errors
        let updateValidation = { ...this.state.validations };
        const { employeeId, Toast, modalToogleHandler, fetchEmployeeList } = this.props;

        try {
            const payload = { ...formData }; // Create payload
            delete payload.company;
            delete payload.updated_by;
            delete payload.updated_at; 
            delete payload.system_id;
            delete payload.created_at;
            delete payload.created_by;
            delete payload.additional_documents;
            delete payload.proof_documents;
            const formDataPayload = new FormData();
            for(const i in payload){
                if(isObject(payload[i]) && isSet(payload[i].file_id,null)!==null){
                    formDataPayload.append(i, payload[i].file_id,);
                }else{
                    formDataPayload.append(i, payload[i]);
                }
                
            }
            //insurance_card_front
            
            
            // Remove unnecessary fields from payload
            const headers = {
                'Content-Type': 'multipart/form-data'
              };

            const res = await EmployeeService.updateEmployee(employeeId, formDataPayload,headers);
            
            if (res.status && res.status === "ERROR") {
                if (isObject(res.data)) {
                  for (let key in res.data) {
                    updateValidation = {
                      ...updateValidation,
                      [key]: res.data[key].join(","),
                    };
                  }
                } else {
                  Toast.error(res.data);
                }
                if (Object.keys(updateValidation).length > 0) {
                    this.setState({ validations: updateValidation });
                }
              } else {
                Toast.success("Employee/patient updated successfully");
                modalToogleHandler(null);
            }
        } catch (error) {
            console.error("Error updating employee details:", error);
            Toast.error("An error occurred while updating employee details.");
            
            Loader(false);
        }
        Loader(false);

        fetchEmployeeList();
    };

    onChangeHandler = async(fieldName, value, dependencyValues = {}) => {
        let updatedValidation = { ...this.state.validations };

        // Clear existing validation message for the field being changed
        if (isSet(updatedValidation[fieldName], '') !== '') {
            updatedValidation[fieldName] = ''; // Clear the validation message
            this.setState({ validations: updatedValidation });
        }
        let updatedFormData = { ...this.state.formData };

        if (fieldName === "address") {
            const {addressParam}=dependencyValues;
            if (addressParam) {
                updatedFormData = {
                    ...updatedFormData,
                    street_address: addressParam.street || "",
                    city: addressParam.city || "",
                    state: addressParam.state || "",
                    country: addressParam.country || "",
                    zip_code: addressParam.zipcode || ""
                };
            }
        }
        if (fieldName=="status") {
            value==true?value=1:value=0;
        }
        if (fieldName=='identification_number') {   
            if (updatedFormData.identification_type=="Driver's License") { //identification_number update for driver license
                updatedFormData['driver_license']=value;
            }
        }

        if(fieldName === 'identification_type' && value === "Driver's License" && updatedFormData.identification_number !=''){
            updatedFormData['driver_license'] = updatedFormData.identification_number;
        }else if(fieldName === 'identification_type' && value !== "Driver's License" && updatedFormData.identification_number !=''){
            updatedFormData['driver_license'] = '';
        }
        if ((fieldName === 'dependent_id')) {
            const { phone, address,city,street_address, zip_code, state,employee_id,company_id,clinic_id} = await this.getDependentDetails(fieldName === 'dependent_id' ? value : '');    
            updatedFormData['phone'] = phone;
        
            updatedFormData['street_address'] = street_address;
            updatedFormData['zip_code'] = zip_code;
            updatedFormData['state'] = state;
            updatedFormData['address']=address;
            updatedFormData['city']=city;
        
            // updatedFormData['employee_id'] = employee_id;
            updatedFormData['company_id']=company_id;
            updatedFormData['clinic_id']=clinic_id;
          }
          if (fieldName=='is_dependent') {
            if (value==0) {
                updatedFormData['dependent_id']='';
            }
          }

        updatedFormData[fieldName] = value;

        this.setState({
            formData: updatedFormData
        });
    };
    getDependentDetails=async(employee_id='')=>{
        this.props.Loader(true);
        const employeeDetails=await EmployeeService.getEmployee(employee_id);
        this.props.Loader(false);
        return {
          phone:employeeDetails.phone ||'',
    
          address:employeeDetails.address ||'',
          street_address:employeeDetails.street_address ||'',
          state:employeeDetails.state ||'',
          city:employeeDetails.city || '',
          zip_code:employeeDetails.zip_code ||'',
    
          company_id:employeeDetails.company_id,
          clinic_id:employeeDetails.clinic_id,
          employee_id:employee_id,
        }
      }
}

export default WithRoutify(EmployeeDetails);
