import React, { useState, useEffect, useRef } from 'react';
import DropDownService from '../../../../services/DropDownService';
import { isSet } from '../../../../utils/commonUtils';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './globalSearch.css';
import { v4 as uuidv4 } from 'uuid';
import AddVisits from '../../../../containers/EmployeeVisitContainer/AddVisits';
import Buttons from '../../../UI/Buttons';
import PERMISSIONS from '../../../../utils/AclPermissions';
import WithRoutify from '../../../../hoc/WithRoutify';
import Acl from '../../../../hoc/Acl';

const GlobalSearchInput = (props) => {
    const { searchTerm, setSearchTerm, activeMenu, submitHandler, globalFilter } = props;
    const [showDropdown, setShowDropdown] = useState(false);
    const [options, setOptions] = useState([]);
    const [visitModalOpen, setVisitModalOpen] = useState(false);
    const inputRef=useRef(null);
    const [patientModalOpen, setPatientModalOpen] = useState(false);
    const [visitAutoFillData, setVisitAutoFillData] = useState({});
    const [inputFocused, setInputFocused] = useState(false);
    const EmployeeSearchWindows=['Chart','Visits','Appointments','White Board','Charts','Labs','Vaccine','Physicals'];
    // const isLoading = useSelector(state=>state.common.isLoading); 
    

    useEffect(() => {
        if (!searchTerm) {
            clearFilterHandler();
        }
    }, [searchTerm]);
    useEffect(()=>{
        if (inputFocused==false) {
            setShowDropdown(false);
        }
    },[showDropdown]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.search-dropdown-container')) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOptionClick = (option) => {
        setSearchTerm(option.name);
        setShowDropdown(false);
        if (EmployeeSearchWindows.includes(activeMenu)) {
            submitHandler({  ...globalFilter,employee_id: option.value, searchName: option.name });
        }
    };
    const handleSearchChange = (value, event) => {
        setSearchTerm(value);
        fetchOptions(value);
        if (value) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };
    const fetchOptions = async (searchTerm) => {
        const payload = { menu: activeMenu, search: searchTerm }
        const response = await DropDownService.getGlobalSearchDropDown(null, payload);
        let optionsArr = getOptionsByActiveWindow(response);
        setOptions(optionsArr);
    }
    const onEnter = (event) => {
        const asciiCode = event.keyCode || event.which;
        if (asciiCode == 13 && !searchTerm) {
            submitHandler({ ...globalFilter, employee_id: '', searchName: '' });
        }
    }
    const clearFilterHandler = () => {
        setShowDropdown(false);
        setSearchTerm('');
        setInputFocused(false);
        inputRef&&inputRef.current.blur();
        setOptions([]);
        if (EmployeeSearchWindows.includes(activeMenu)) {
            submitHandler({
                ...globalFilter,
                employee_id: '',
                searchName: ''
            });
        }
    }
    const visitToggleHandler = (emptyAutoFillData = false) => {
        if (emptyAutoFillData) {
            setVisitAutoFillData({});
        }
        setVisitModalOpen(!visitModalOpen);
    }
    // const addNewEmployeeToogleHandler = (bool = false, nextStep = null, autoFillData) => {
    //     if (nextStep === 'ADD_VISIT') {
    //         setVisitAutoFillData(autoFillData);
    //         setPatientModalOpen(bool);
    //         visitToggleHandler();
    //     } else {
    //         setPatientModalOpen(bool);
    //     }
    // };
    const fetchVisitList = () => { //update  global filter with reload 
        submitHandler({...globalFilter,'reloads':uuidv4()});
    }    
    return (
        <>
            {
                visitModalOpen ?
                    <AddVisits
                        modelTitle={"Add Visits"}
                        fetchVisitsList={() => fetchVisitList()}
                        isOpen={visitModalOpen}
                        autoFillData={visitAutoFillData}
                        modalToogleHandler={visitToggleHandler}
                    />
                    :
                    <></>
            }
            {/* {
                patientModalOpen ?
                    <AddEmployee
                        modelTitle={"Add Employee"}
                        // isFromVisitHistory={this.props.isFromVisitHistory}
                        // fetchEmployeeList={this.fetchVisitsList}
                        isOpen={patientModalOpen}
                        // autoEmployeeFillData={autoEmployeeFillData}
                        modalToogleHandler={addNewEmployeeToogleHandler}
                    />
                    :
                    <></>
            } */}
            <div className="search-dropdown-container" style={{ position: 'relative' }}>
                <ul className="form-group ml-auto mb-0">
                    <div className="position-relative globalesearch-div">
                        {/* <div class="form-control globalesearch form-control-solid" style={{width:'100%'}}>Search by company,clinic...</div> */}
                        <input
                            className={`form-control globalesearch form-control-solid ${inputFocused ? 'focused' : ''}`}
                            placeholder="Search"
                            ref={inputRef}
                            // type="search"
                            value={searchTerm}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => {if(showDropdown===false) { setInputFocused(false) } }}
                            onKeyUp={(e) => onEnter(e)}
                            onChange={(e) => handleSearchChange(e.target.value, e)}
                        />
                        {/* <i class="fas fa-search position-absolute" style={{left:'10px',top:'50%',transform:'translateY(-50%)'}}></i>  */}
                        {isSet(searchTerm, '') === '' &&
                            <i onClick={props.toggleSearch} style={{ cursor: 'pointer', right: '10px', top: '50%', transform: 'translateY(-50%)' }} className={`position-absolute fa ${props.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        }
                        {isSet(searchTerm, '') !== '' &&
                            <i onClick={() => clearFilterHandler()} style={{ cursor: 'pointer', right: '10px', top: '50%', transform: 'translateY(-50%)' }} className={`position-absolute fa ${props.isOpen ? 'fa fa-times-circle' : 'fa fa-times-circle'}`}></i>
                        }
                    </div>
                </ul>
                <TransitionGroup>
                    {showDropdown && (
                        <CSSTransition
                            key={'searchkey'}
                            in={showDropdown}
                            timeout={300} /* Animation duration */
                            classNames="slide" /* Custom CSS class prefix */
                            unmountOnExit /* Automatically unmounts the element when the animation ends */
                            onExited={() => {
                                // setTimeout(() => {
                                        setInputFocused(false);
                                // }, 100);
                                // console.log('Slide up transition completed');
                              }}
                        >
                            <>
                            <ul className="globalsearch-dropdown show" style={{ zIndex: 1000, listStyleType: 'none' }}>
                                {options.length > 0 ? (
                                    options.map((option, index) => (
                                        <li
                                            style={{
                                                cursor: 'pointer',
                                                padding: '8px',
                                                listStyle: 'none',
                                                borderBottom: '1px solid #ccc',
                                            }}
                                        >
                                            <ul className='d-flex align-items-center justify-content-between px-0' style={{ listStyleType: 'none' }}>
                                                <li
                                                   style={{width:'90%'}}
                                                    key={index}
                                                    onClick={() => handleOptionClick(option)}

                                                >
                                                    {option.label}
                                                </li>
                                                <Acl acl={PERMISSIONS.VISITS.CREATE}>
                                                <li style={{width:'10%'}}>
                                                    <Buttons
                                                        title={"Add Visit"}
                                                        className="btn btn-icon btn-transparent-dark"
                                                        acl={PERMISSIONS.VISITS.CREATE}
                                                        iconType={"AddIconDetailView"}
                                                        clickHandler={() => {
                                                            setVisitAutoFillData({
                                                                company_id: option.api_data.company_id,
                                                                employee_id: option.api_data.id
                                                            });
                                                            visitToggleHandler();
                                                        }}
                                                    />
                                                </li>
                                                </Acl>
                                            </ul>
                                        </li>
                                    ))
                                ) : (
                                    <li style={{ padding: '8px' }}>No results found</li>
                                )}
                                    {/* <Acl acl={PERMISSIONS.EMPLOYEE.CREATE}>
                                        <Buttons
                                            type='Single'
                                            className="btn btn-primary"
                                              clickHandler={() => addNewEmployeeToogleHandler(true)}
                                            label='Add Patient'
                                            acl={'any'}
                                        />
                                    </Acl> */}
                            </ul>
                          
                            </>
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
        </>
    );
    function getOptionsByActiveWindow(response) {
        let optionsArr = [];
        if (EmployeeSearchWindows.includes(activeMenu)) {
            response.forEach((itm, index) => {
                optionsArr.push({
                    // label: `${itm.name} / ${itm.dob} / ${itm.company} / ${itm.phone}`,
                    label: 
                    <div className="">
                        <p className="mb-0"><strong>{itm.name}</strong></p>
                        <p className="mb-0"><span> <i class="fa fa-home"></i>  </span> {itm.company}</p>
                        <p className="mb-0">
                            <span><i class="fa fa-phone" style={{ transform: 'rotateY(180deg)' }}></i></span> {itm.phone}
                            <span className='px-2'><i class="fa fa-calendar" aria-hidden="true"></i></span> {itm.dob}
                        </p>
                    </div>,
                    value: itm.id,
                    name: itm.name,
                    api_data: itm
                })
            });
        }
        return optionsArr;
    }
};

export default WithRoutify(GlobalSearchInput);
