import Buttons from "../../../components/UI/Buttons";
// import TableGrid from "../../../components/UI/TableGrid";
import PERMISSIONS from "../../../utils/AclPermissions";
import { capitalizeName } from "../../../utils/commonUtils";

const VisitDetails = (props) => {
    // const tableHeaders = [
    //     { label: "Status", key: "status", type: "", },
    //     { label: "Activity Name", key: "activity_name", },
    //     { label: "Activity Type", key: "general_email", },
    //     { label: "Consent Form", key: "phone", type: "", },
    //     { label: "Action", key: "action", },
    // ];
    const { visitData, visitList, fetchChart, printClearanceReport, printMedicalExaminationPdfs, orderLabToogleHandler, activityChartToogleHandler, deleteHandler, printConsentHandler } = props;
    return (
        <>

            {
                visitData.visit_data && visitData.visit_data.length > 0 ?
                    <>
                        {visitData.visit_data && visitData.visit_data.map((visitItem, visitDataIndex) => (

                            <div key={visitDataIndex} className="card mb-2">
                                <div class="" style={{ position: 'absolute', right: '10%' }}
                                ><div class="addAction d-flex justify-content-center align-items-center">

                                        <a className="btn  btn-outline-primary  btn-sm my-2 py-0" title="Print">
                                            <span className="dropdown dropdown-toggle position-static noArrow" data-bs-toggle="dropdown">
                                                <i className="fas fa-print text-md " /> Print Report
                                            </span>

                                            <div className="dropdown-menu dropdown-menu-left shadow" aria-labelledby="userDropdown">
                                                <div className="dropdown-item" href="#" onClick={() => printClearanceReport(visitItem.visit_id)}>
                                                    Clearance Report
                                                </div>
                                                <div className="dropdown-item" href="#" onClick={() => printConsentHandler('consent form', visitItem.visit_id)}>
                                                    Print Consents
                                                </div>

                                            </div>
                                        </a>

                                    </div>
                                </div>
                                <div class="card-header sh_accordionheader collapsed" href={`#collapseCardVisit${visitDataIndex}_${visitDataIndex}`} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls={`collapseCardVisit${visitDataIndex}_${visitDataIndex}`} onClick={() => fetchChart(visitItem.visit_id, visitItem)}>


                                    <div class="form-group d-flex mb-0 justify-content-between align-items-center" >
                                        <h6 class="text-sm mb-0">
                                            {/* {visitItem.visit_category} */}
                                            {visitItem.visit_date}<span class={`badge bg-${visitItem.statusClr}-soft text-${visitItem.statusClr} ml-3`}>{capitalizeName(visitItem.overall_status.toLowerCase())}</span>
                                        </h6>

                                    </div>
                                </div>
                                <div className="collapse sh_collapse" id={`collapseCardVisit${visitDataIndex}_${visitDataIndex}`} data-bs-parent={`#accordion2_${visitDataIndex}`}>
                                    <div className="card-body p-1">
                                        {/* Chart Data */}
                                        {/* <TableGrid
                                            tableHeaders={tableHeaders}
                                            tableRows={{ data: isSet(visitList?.[visitItem.visit_id]?.[0].mapping_activity, []) }}
                                        /> */}
                                        <table className="table dataTable table-hover nowrap my-0 table-striped " style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Activity Name</th>
                                                    <th>Activity Type</th>
                                                    <th>Consent Form</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {visitList && visitList[visitItem.visit_id] && visitList[visitItem.visit_id][0]?.mapping_activity.length > 0 ? (
                                                    visitList && visitList[visitItem.visit_id] && visitList[visitItem.visit_id][0]?.mapping_activity.map((chartData, chartIndex) => (
                                                        <tr key={chartIndex}>
                                                            <td className="forTable_textEllipsis" title="NOT STARTED">
                                                                <span class={`badge bg-${chartData.statusClr}-soft text-${chartData.statusClr}`}>{capitalizeName(chartData.status.toLowerCase())}</span>
                                                            </td>
                                                            <td className="forTable_textEllipsis" title={chartData.activity.activity_name}>
                                                                {chartData.activity.activity_name}
                                                            </td>
                                                            <td className="forTable_textEllipsis" title={chartData.activity.activity_type}>
                                                                {chartData.activity.activity_type}
                                                            </td>
                                                            <td className="forTable_textEllipsis" title="">
                                                                -
                                                            </td>
                                                            <td>
                                                                <div className="actionTable">
                                                                    <Buttons
                                                                        className="btn px-0"
                                                                        title="Certificate"
                                                                        disableButtonTitle="Certificate"
                                                                        acl = { (chartData.activity.activity_type === 'Provider Exam' &&  visitList[visitItem.visit_id].providerExam.find(provider => provider.mapping_id === chartData.mapping_id)?.isFederalOrState === true ) ?'any': '' }
                                                                        iconType="Certificate"
                                                                        iconColor={'#000'}
                                                                        iconDropDownList={[{
                                                                            label:'Examiner Certificate',
                                                                            clickHandler:()=>printMedicalExaminationPdfs(chartData.mapping_id,'CERT','MedicalExaminationCertificate')
                                                                        },
                                                                        {
                                                                            label:'Examination Report (DOT)',
                                                                            clickHandler:()=>printMedicalExaminationPdfs(chartData.mapping_id,'DOT','MedicalExaminationReport')
                                                                        }]}
                                                                    />
                                                                    {
                                                                        (chartData.activity.activity_type == 'Lab' &&
                                                                            visitList[visitItem.visit_id].laboratory.find(lab => lab.mapping_id === chartData.mapping_id)?.hasOrder === false) ||
                                                                            (chartData.activity.activity_type == 'Lab - Point of Care' &&
                                                                                visitList[visitItem.visit_id].laboratory.find(lab => lab.mapping_id === chartData.mapping_id)?.hasOrder === false) ||
                                                                            (chartData.activity.activity_type == 'Vaccine' &&
                                                                                visitList[visitItem.visit_id].vaccines.find(vaccine => vaccine.mapping_id === chartData.mapping_id)?.hasOrder === false)
                                                                            ?
                                                                            <Buttons
                                                                                className="btn btn-datatable btn-icon btn-transparent-dark"
                                                                                title="Add Order"
                                                                                acl={PERMISSIONS.RESULT.CREATE}
                                                                                iconType="AddPlus"
                                                                                clickHandler={() => orderLabToogleHandler(chartData.activity.activity_type, chartData)}
                                                                            />

                                                                            :
                                                                            <Buttons
                                                                                className="btn btn-datatable btn-icon btn-transparent-dark"
                                                                                title="Chart"
                                                                                acl="any"
                                                                                iconType="ActivityChart"
                                                                                clickHandler={() => activityChartToogleHandler({ ...chartData.activity, mapping_id: chartData.mapping_id, visit_id: visitItem.visit_id })}

                                                                            />
                                                                    }
                                                                    <Buttons
                                                                        className="btn btn-datatable btn-icon btn-transparent-dark"
                                                                        title="Delete"
                                                                        isDelete={true}
                                                                        acl={PERMISSIONS.RESULT.DELETE}
                                                                        iconType="Remove"
                                                                        clickHandler={() => deleteHandler(chartData.mapping_id)}
                                                                    />


                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={5} align='center'>No Record exists</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </>
                    :
                    <>
                        <div align='center'> No Record exists </div>
                    </>
            }
        </>
    );
}
export default VisitDetails;