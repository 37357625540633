import React from "react";

const CalenderPlusIcon = (props)=>{
    return(
        <>
           <svg xmlns="http://www.w3.org/2000/svg" fill="#4e73df" width="18px" height="18px" viewBox="0 0 256 256" id="Flat">
  <path d="M207.99414,31.99707h-24v-8a8,8,0,0,0-16,0v8h-80v-8a8,8,0,1,0-16,0v8h-24a16.01583,16.01583,0,0,0-16,16v160a16.01583,16.01583,0,0,0,16,16h160a16.01582,16.01582,0,0,0,16-16v-160A16.01582,16.01582,0,0,0,207.99414,31.99707ZM156,160H136v20a8,8,0,0,1-16,0V160H100a8,8,0,0,1,0-16h20V124a8,8,0,0,1,16,0v20h20a8,8,0,0,1,0,16Zm51.99414-80.00293h-160v-32h24v8a8,8,0,0,0,16,0v-8h80v8a8,8,0,1,0,16,0v-8h24Z"/>
</svg>
        </>
    )
}
export default CalenderPlusIcon;