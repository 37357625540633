import { decryptAES, encryptAES, isSet } from '../utils/commonUtils';
import { CREATED, OK } from '../utils/constant';
import httpInstance from './config';


//GET METHOD
const GET =( url) => {
	// sent a GET request
	let response = httpInstance.get(url).then((res)=>{
		if(res.status===200){
			const resData = res.data;
			if(resData.code===200){
				return {
					...resData,
					data:decryptAES(resData.data)
				};
				
			}
		}
	})
	.catch(error => {
		// console.log('error',error);
		if(error.status){
              const resData = error.data;
              if(resData.code && resData.data){
                  return {
					...resData,
					data:decryptAES(resData.data)
				};;
              }
        }
		if (error.response) {
			// Request made and server responded
			// console.log(error.response.data);
			// console.log(error.response.status);
			if(error.status===403){
				//For checking session is expired or not and redirect to login page.
				// localStorage.removeItem('access_token');
			}
		  } else if (error.request) {
			// The request was made but no response was received
			// console.log(error.request);
		 } else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		  }
	  });
	 return response;
}

const POST = async (url, body, header) => {
	// console.log('header',header);
	return  httpInstance.post(url, encryptAES(body), {
	  headers: header
	}).then((res)=>{
		if(res.status && res.data){
			const resData = res.data;
			if(resData.code===OK || resData.code===CREATED){
				return {
					...resData,
					data:decryptAES(resData.data)
				};
				
			}
		}
	}).catch(error => {
		// console.log('error',error);
		if(error.status){
              const resData = error.data;
              if(resData.code && resData.data){
                  return {
					...resData,
					data:decryptAES(resData.data)
				};;
              }
        }
	  });
}



const POSTwithoutAES = (url, body, header) => {
	// console.log('header',header);
	let response = httpInstance.post(url, body, {
	  headers: header
	}).then((res)=>{
		if(res.status===200){
			const resData = res.data;
			if((resData.code===OK || resData.code===CREATED)){
				return resData;
				
			}
		}
	}).catch(error => {
		// console.log('error',error);
		if(error.status){
              const resData = error.data;
              if(resData.code && resData.data){
                  return resData;
              }
        }
	  });
	return response;
}

const PUT = (url, body, header) => {
	let response = httpInstance.put(url, encryptAES(body), {
		headers: header
	  }).then((res)=>{
		  if(res.status===200){
			  const resData = res.data;
			  if((resData.code===OK || resData.code===CREATED)){
				  return {
					  ...resData,
					  data:decryptAES(resData.data)
				  };
				  
			  }
		  }
	  }).catch(error => {
		//   console.log('error',error);
		  if(error.status){
				const resData = error.data;
				if(resData.code && resData.data){
					return {
					  ...resData,
					  data:decryptAES(resData.data)
				  };;
				}
		  }
		});
	  return response;
}


const DELETE = (url,body={}) => {
	let response =  httpInstance.delete(url,{ data: body })
	.then((res)=>{
		if(res.status===200){
			const resData = res.data;
			if(isSet(resData) && (resData.code===OK || resData.code===CREATED)){
				return {
					...resData,
					data:decryptAES(resData.data)
				};
				
			}else{
				return null;
			}
		}
	}).catch(error => {
		console.log('error',error);
		if(error.status){
			  const resData = error.data;
			  if(resData.code && resData.data){
				  return {
					...resData,
					data:decryptAES(resData.data)
				};;
			  }
		}
	  });
	  return response;
}
const apiMethods = { GET, PUT, POST, DELETE, POSTwithoutAES };

export default apiMethods;
