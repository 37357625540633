
import ProviderService from "../../../../../../services/ProviderService";
import {isSet, optionsToKeyValuePairs } from "../../../../../../utils/commonUtils";
import { OnTabKeyPress } from "../../../../../../utils/cssUtils";


export const MinimalExam = (props)=>{

    const physicalExamination = [
        {label:'General',name:'physical_examination_general',onKeyPress:(event)=>OnTabKeyPress(event,"","sitting_diastolic")},
        {label:'Skin',name:'physical_examination_skin'},
        {label:'Eyes',name:'physical_examination_eyes'},
        {label:'Ears',name:'physical_examination_ears'},
        {label:'Mouth / throat',name:'physical_examination_mouth_throat'},
        {label:'Cardiovascular',name:'physical_examination_cardiovascular'},
        {label:'Lungs / chest',name:'physical_examination_lungs_chest'},
        {label:'Abdomen',name:'physical_examination_abdomen'},
        {label:'Genito urinary sys / hernias',name:'physical_examination_genito_urinary'},
        {label:'Back / Spine',name:'physical_examination_back_spine'},
        {label:'Extremities / joints',name:'physical_examination_extremities_joints'},
        {label:'Neurological sys / reflexes',name:'physical_examination_neurological_sys_reflexes'},
        {label:'Gait',name:'physical_examination_gait'},
        {label:'Vascular system',name:'physical_examination_vascular_system'}];

        const patientHealthInformation = [
            {label:'1. Head/brain injuries (e.g., concussion, seizures)',name:'head_brain_injuries'},
            {label:'2. Eye problems (except glasses or contacts)',name:'eye_problems'},
            {label:'3. Ear and/or hearing problems',name:'ear_hearing_problems'},
            {label:'4. Heart disease, heart attack, bypass, or other',name:'heart_disease'},
            {label:'5. High blood pressure',name:'blood_clots_bleeding'},
            {label:'6. High cholesterol',name:'high_cholesterol'},
            {label:'7. Lung disease (e.g., asthma)',name:'lung_disease'},
            {label:'8. Kidney problems, kidney stones, or pain w/ urination',name:'kidney_problems'},
            {label:'9. Stomach, liver, or digestive problems',name:'digestive_problems'},
            {label:'10. Diabetes or blood sugar problems Insulin used',name:'diabetes_insulin'},
            {label:'11. Anxiety, depression, other mental problems',name:'mental_problems'},
            {label:'12. Fainting or passing out',name:'fainting_passing_out'},
            {label:'13. Dizziness, headaches, numbness, tingling',name:'dizziness_headaches'},
            {label:'14. Unexplained weight loss',name:'unexplained_weight_loss'},
            {label:'15. Stroke, mini-stroke (TIA), paralysis, or weakness',name:'stroke_paralysis_weakness'},
            {label:'16. Neck or back problems',name:'neck_back_problems'},
            {label:'17. Bone, muscle, joint, or nerve problems',name:'bone_muscle_joint_nerve_problems'},
            {label:'18. Blood clots or bleeding problems',name:'blood_clots_bleeding'},
            {label:'19. Cancer',name:'cancer'},
            {label:'20. Chronic (long-term) infection or other',name:'chronic_infection'},
            {label:'21. Have you ever spent a night in the hospital?',name:'hospital_stay'},
            {label:'22. Have you ever used or do you now use tobacco?',name:'tobacco_use'},
            {label:'23. Do you currently drink alcohol?',name:'alcohol_use'},
            {label:'24. Use of an illegal substance within the past 2 years',name:'illegal_substance_use'},
            {label:'25. Have you ever failed a drug test or been dependent?',name:'drug_test_failure'}
        ]; 
        

    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },
        {
            type:"ResultSummary",
            results:[
                {
                normalMsg:'Provider Section',
                abnormalMsg:'Provider Section',
                data:[
                    {label:'Pulse',keyVal:'pulse_rate_result'},
                    {label:'Blood Pressure',keyVal:'blood_pressure_result'},
                ],
            },
            {
                normalMsg:'Patient Section',
                abnormalMsg:'Patient Section',
                label:'Patient',
                data:[
                    {label:'Surgeries',keyVal:'surgery_history',type:'yesorno'},
                    {label:'Medications',keyVal:'taking_medications',type:'yesorno'},
                    {label:'Health History',keyVal:patientHealthInformation.map(item => item.name),type:'ANY'},
                ],
            }]
            
        },
        {
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        },
    ],
    },
    {
        type:'Tab',
        col:'col-md-9',
        data:[
            {
            id:'provider',
            data:  [
                {
                    label:'Activity Details - Health Information Review',
                    note:'Examination Report (to be filled out by the Provider)',
                    fields:[{
                        label:'Review and discuss Employee/patient answers and any available medical records. Comment on the responses to the "health history" questions that may affect the ability of the employee to perform their job functions. ',
                        type:'TextArea',
                        name:'health_information_review',
                        col:'col-12',
                        id:'health_information_review',
                    }]
                },
                {
                    label:'Pulse Rate & Height',
                    fields:[{
                        label:'Height',
                        placeholder:'Feet',
                        type:'Number',
                        col:'col-4',
                        name:'height_feet',
                        id:'height_feet',
                        
                    },
                    {
                        label:'',
                        isLabel:false,
                        col:'col-2',
                        placeholder:'Inches',
                        type:'Decimal',
                        name:'height_inches',
                        id:'height_inches',
                        
                    },
                    {
                        label:'Weight (In Pounds)',
                        col:'col-6',
                        placeholder:'Pounds',
                        type:'Number',
                        name:'weight_pounds',
                        id:'weight_pounds',
                        
                    },
                   {
                        label:'Pulse Rate',
                        type:'Number',
                        rangeMin:90,
                        rangeMax:150,
                        name:'pulse_rate',
                        id:'pulse_rate'
                    },
                    {
                        label:'Pulse Rhythm Regular',
                        type:'RadioGroup',
                        name:'pulse_rhythm_regular',
                        id:'pulse_rhythm_regular',
                        options:[{label:'Yes',value:1},{label:'No',value:0}],
                    },
                    {
                        type:'Blank',
                        name:'blank'
                    },
                    
                    {
                        label:'Pulse Rate Status',
                        type:'TextInput',
                        name:'pulse_rate_result',
                        id:'pulse_rate_result',
                        disabled:true,       
                        // readOnly:true,                 
                    },
                   
                ]
                },
                { 
                    label:' Blood Pressure Reading (Systolic / Diastolic)',
                    fields:[
                        {
                            label:'Systolic',
                            col:'col-2',
                            type:'Label',
                        },
                        {
                        label:' Sitting',
                        col:'col-4',
                        rangeMax:139,
                        type:'Number',
                        name:'sitting_systolic',
                        onKeyPress:(event)=>OnTabKeyPress(event,"sitting_diastolic",""),
                        id:'sitting_systolic',
                    },
                    {
                        label:'Second reading (optional)',
                        col:'col-6',
                        rangeMax:139,
                        type:'Number',
                        name:'second_reading_systolic',
                        id:'second_reading_systolic',
                    },
                    {
                        label:'Diastolic',
                        col:'col-2',
                        type:'Label',
                    },
                    {
                        label:' Sitting',
                        col:'col-4',
                        type:'Number',
                        rangeMax:89,
                        name:'sitting_diastolic',
                        id:'sitting_diastolic',
                        onKeyPress:(event)=>OnTabKeyPress(event,"checkbox_physical_examination_general0_0","sitting_systolic"),
                    },
                    {
                        label:'Second reading (optional)',
                        col:'col-6',
                        type:'Number',
                        rangeMax:89,
                        name:'second_reading_diastolic',
                        id:'second_reading_diastolic',
                    },
                    {
                        label:'',
                        type:'Blank',
                        name:'blank'
                    },
                    {
                        label:'Blood Pressure Status',
                        type:'TextInput',
                        name:'blood_pressure_result',
                        id:'blood_pressure_result',
                        disabled:true,                        
                    },
                    
                    // {
                    //     label:'Blood Pressure Ranges',
                    //     col:'col-12',
                    //     type:'UnorderedList',
                    //     notes:[
                    //         <span><b>Normal</b>: Systolic less than 120 mmHg and diastolic less than 80 mmHg </span>,
                    //         <span><b>Elevated</b>: Systolic 120-129 mmHg and diastolic less than 80 mmHg</span>,
                    //         <span><b>Stage 1 hypertension</b>    : Systolic 130-139 mmHg or diastolic 80-89 mmHg</span>,
                    //         <span><b>Stage 2 hypertension</b>    : Systolic 140 mmHg or higher or diastolic 90 mmHg or higher</span>,
                    //         <span><b>Hypertensive</b>     : Systolic higher than 180 mmHg and/or diastolic higher than 120 mmHg</span>],
                    // },
                    
                ]
                },
                {
                    label:'Physical Examination',
                    note:'Check the body systems for abnormalities',
                    fields:[{
                        label:'The presence of a certain condition may not necessarily disqualify an employee from the job, particularly if the condition is controlled adequately, is not likely to worsen, or is readily amenable to treatment. Even if a condition does not disqualify an employee, the Provider may consider deferring employment temporarily. Also, the employee should be advised to take the necessary steps to correct the condition as soon as possible, particularly if neglecting the condition could result in a more serious illness that might affect employment.',
                        type:'Label'
                    }, {
                        col:'col-12',
                        label:'',
                        defaultBtnLabel:'Set all to Normal',
                        type:'YesOrNoTable',
                        options:[{label:'Normal',value:'Normal'},{label:'Abnormal',value:'Abnormal'}],
                        defaultValues:physicalExamination.reduce((acc, itm) => {
                            acc[itm.name] = 'Normal';
                            return acc;
                        }, {}),
                        list:physicalExamination,
                        
                    }]
                },
                {
                    label:'Medical Examiner Determination(Non-DOT) and Status',
                    col:'col-6',
                    fields:[{
                        label:'Medical Record#',
                        type:'TextInput',
                        name:'medical_record',
                        id:'medical_record'
                    },
                    {
                        label:'Exam Date',
                        type:'Date',
                        name:'exam_date',
                        id:'exam_date'

                    },{
                        label:'Employment Status',
                        type:'MultiSelectDropDown',
                        isToogle:true,
                        options:{
                            default:[{label: 'Select All', value: 'ALL'}],
                            ['Medically acceptable for the position offered']:optionsToKeyValuePairs([
                                "Commercial driving",
                                "Limited lifting/pulling/pushing no more than",
                                "Must wear corrective lenses",
                                "No work where distinguishing color is required",
                                "No work in high elevations",
                                "Other existing conditions",
                            ],false),
                            ["Vaccines/Boosters"]:optionsToKeyValuePairs([
                                "Recommended Hep A Vaccine/Booster",
                                "Recommended Hep B Vaccine/Booster",
                                "Other Vaccines/Boosters",
                            ],false),
                            ["Color Vision"]:optionsToKeyValuePairs([
                                "No Recommendations for Color Vision",
                                "Color vision recommendations"
                            ],false),
                            ["Audio Testing"]:optionsToKeyValuePairs([
                                "No Recommendations for Audio Testing",
                                "Audio testing recommendations"
                            ],false),
                            ["Grip Strength"]:optionsToKeyValuePairs([
                                "No Recommendations for Grip Strength",
                                "Grip strength recommendations"
                            ],false),
                            ["NO significant health problems present"]:[
                               { label:"No Work Accommodations are necessary ",value:"NO significant_No Work Accommodations are necessary "},
                               { label:"No activity restrictions are necessary",value:"NO significant_No activity restrictions are necessary"}
                            ],
                            ["MINOR significant health problems present"]:[
                                { label:"No Work Accommodations are necessary",value:"MINOR significant_No Work Accommodations are necessary"},
                                { label:"No activity restrictions are necessary",value:"MINOR significant_No activity restrictions are necessary"},
                                { label:"Patient needs follow up with private doctor",value:"MINOR significant_Patient needs follow up with private doctor"},
                             ],
                            ["SIGNIFICANT health problems present"]:optionsToKeyValuePairs([
                                "Work Accommodations are necessary",
                                "We will contact the employer directly",
                            ],false),
                            ["Other"]:optionsToKeyValuePairs([
                                "Placed on medical hold pending "
                            ],false)
                        },
                    
                        id:'employment_status',
                        name:'employment_status',
                    },
                    {
                        label:'Provider',
                        type:'SelectSearchList',
                        name:'provider',
                        id:'provider',
                        isAsync:true,
                        options:[],
                        autoPopulateFields:{
                            // medical_examiners_certificate_expiration_date:'unavailable_until',
                        },
                        loadCallBack:(val,callback)=>fetchProviderList(val,props,callback),
                    },
                    {
                        label:'Provider Signature/Consent',
                        type:'Signature',
                        col:'col-6',
                        name:'provider_signature',
                        id:'provider_signature'
                    },
                    {
                        label:'Date',
                        type:'Date',
                        id:'examiner_determination_date',
                        name:'examiner_determination_date',
                    },
                   
                    {
                        label:'Notes',
                        col:'col-6',
                        type:'TextArea',
                        name:'notes',
                    },
                    {
                        label:'Status',
                        type:'SelectList',
                        // options:[],
                        options:isSet(props.lookupsOptions['chartResult'],[]),
                        name:'status',
                        id:'status',
                    },
                ]
                },
              ]
        },
        {
            id:'patient',
            data:  [
                {
                    label:'Health Information',
                    // note:'Check the body systems for abnormalities',
                    fields:[
                        {
                        label:'Have you ever had surgery ?',
                        type:'RadioGroup',
                        name:'surgery_history',
                        col:'col-12',
                        id:'surgery_history',
                        options:['Yes','No','Not Sure'],
                    },
                    {
                        type:'Blank',
                        col:'col-6',
                    },
                    {
                        label:'',
                        type:'TextArea',
                        col:'col-6',
                        isLabel:false,
                        disabled:true,
                        rules:[{rule:[{field:'surgery_history',value:'Yes',operator:'==='}],action:'ENABLE'}],
                        name:'surgery_explanation',
                        id:'surgery_explanation',
                    },
                    
                    {
                        label:"Are you currently taking medications (prescription, over-the-counter, herbal remedies, diet supplements)?",
                        type:'RadioGroup',
                        col:'col-12',
                        name:'taking_medications',
                        id:'taking_medications',
                        options:['Yes','No','Not Sure'],
                    },
                    {
                        type:'Blank',
                        col:'col-6',
                    },
                    {
                        label:'',
                        type:'TextArea',
                        col:'col-6',
                        isLabel:false,
                        disabled:true,
                        rules:[{rule:[{field:'taking_medications',value:'Yes',operator:'==='}],action:'ENABLE'}],
                        name:'taking_medication_explanation',
                        id:'taking_medication_explanation',
                    },
                    {
                        label:'Do you have or have you ever had:',
                        type:'Label'
                    }, 
                    {
                        col:'col-12',
                        label:'',
                        defaultBtnLabel:'Set all to No',
                        type:'YesOrNoTable',
                        options:[{label:'Yes',value:'Yes'},{label:'No',value:'No'},{label:'Not Sure',value:'Not Sure'}],
                        defaultValues:patientHealthInformation.reduce((acc, itm) => {
                            acc[itm.name] = 'No';
                            return acc;
                        }, {}),
                        failedValue:['Yes','Not Sure'],
                        list:patientHealthInformation,
                        
                    },
                    {
                        label:'Other health condition(s) not described above ?',
                        type:'RadioGroup',
                        name:'other_conditions',
                        col:'col-12',
                        id:'other_conditions',
                        options:['Yes','No','Not Sure'],
                    },
                    {
                        type:'Blank',
                        col:'col-6',
                    },
                    {
                        label:'',
                        type:'TextArea',
                        col:'col-6',
                        isLabel:false,
                        disabled:true,
                        rules:[{rule:[{field:'other_conditions',value:'Yes',operator:'==='}],action:'ENABLE'}],
                        isLabel:false,
                        name:'other_conditions_description',
                        id:'other_conditions_description',
                    },
                    
                    {
                        label:"Did you answer 'yes' to any of questions 1-25 ? If so, please",
                        type:'RadioGroup',
                        col:'col-12',
                        name:'additional_comments',
                        id:'additional_comments',
                        options:['Yes','No','Not Sure'],
                    },
                    {
                        type:'Blank',
                        col:'col-6',
                    },
                    {
                        label:'',
                        type:'TextArea',
                        col:'col-6',
                        disabled:true,
                        rules:[{rule:[{field:'additional_comments',value:'Yes',operator:'==='}],action:'ENABLE'}],
                        isLabel:false,
                        name:'additional_comments_description',
                        id:'additional_comments_description',
                    },
                ]
                },
                {
                    label:'Approval Signature & Date',
                    note:'I certify that the above information is accurate and complete.',
                    col:'col-12',
                    fields:[{
                        label:'Employee Signature',
                        type:'Signature',
                        col:'col-6',
                        name:'employee_signature',
                        id:'employee_signature',
                    },
                    {
                        label:'Date',
                        type:'Date',
                        col:'col-6',
                        id:'signatured_date',
                        name:'signatured_date',
                    }
                ]
                }]
        },
        props.formData.id?
        {
            id:'patientVisitHistory',
            data:[ {
                label:'Documents',
                fields:[{
                    type:'ChartDocument',
                    employeeDetails:props.employeeDetails,
                    visitDetails:props.visitDetails,
                    mappingId:props.mappingId
                }]
            }],
        }
        :''
    ]
    },
   ];
    const headers = [{
        type:'Tab',
        data:[{
            label:'Provider',id:'provider'
        },
        {
            label:'Patient',id:'patient'
        },{
            label:'Patient Visit History',
            id:'patientVisitHistory'
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        pulse_rate_result:'Not Started',
        blood_pressure_result:'Not Started',
        // reason:'Pre-employment'
    };
    const buttons = {
        PDF:true
    }
    return {charts,defaultValue,buttons,headers,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
    const pulse = isSet(formData.pulse_rate,'');
    const systolic = isSet(formData.sitting_systolic,'');
    const diastolic = isSet(formData.sitting_diastolic,'');
    let returnResult = {};

    let pulseRateResult = 'Not Started';
    if(pulse==''){
        pulseRateResult = 'Not Started'
    }else if (pulse >= 60 && pulse <= 100) {
        pulseRateResult =  'Normal';
    }else if(pulse!==''){
        pulseRateResult = 'Abnormal';
    }

    let bloodPressureResult = 'Not Started';
    if(systolic === '' && diastolic === ''){
        bloodPressureResult = 'Not Started'
    }else if (systolic === '' || diastolic === '') {
        bloodPressureResult = 'Pending';
    } else if ((systolic < 0 || systolic > 139) || (diastolic < 0 || diastolic >89)) {
        bloodPressureResult = 'Abnormal';
    } else {
        bloodPressureResult = 'Normal';
    }
    
    returnResult = {
        ...returnResult,
        pulse_rate_result:pulseRateResult,
        blood_pressure_result:bloodPressureResult
    }
    return returnResult;
    // return (key==='status')?:{'status':status};
}

export const fetchProviderList = async (val,props, callback=()=>{}) => {
    const {employeeDetails} = props;
    let optionArr = [];
    try{
        const data = await ProviderService.getProviderByClinicId(employeeDetails.clinic_id,{search:val});
        isSet(data,[]).map((item,itemIndex)=>{
            optionArr.push({
                label:isSet(item.first_name,'')+' '+isSet(item.last_name,''),
                value:item.provider_id,
                data:item,
                index:itemIndex
            })
        });
        callback(optionArr);
    }catch(e){

    }
    return optionArr;
  }