import React, { useEffect,useState } from 'react';
// import {
//     WEEKDAYS_SHORT,
//   } from '../../constants';
import 
    CalendarBodyHoc
 from '../../calendarHoc';
import MonthViewHeader from './MonthViewHeader';
import MonthViewBody from './MonthViewBody';
// import moment from 'moment';
// import { isSet } from '../../../../utils/commonUtils';

const CalendarMonthView = (props)=>{
    const {calendarOptions,calendarViewType,currentDay} = props;
    const [calendarMonth,setCalendarMonth] = useState([]);
    const {showWeekends} = calendarOptions;
    useEffect(() => {
        const date = currentDay;
        const month = date.getMonth();
        const year = date.getFullYear();
        let firstDayOfMonth = new Date(year, month, 1);
        let weekdayOfFirstDay = firstDayOfMonth.getDay();
        let currentDays = [];
        for (let day = 0; day < 42; day++) {
            if (day === 0 && weekdayOfFirstDay === 0) {
                firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
            } else if (day === 0) {
                firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (day - weekdayOfFirstDay));
            } else {
                firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
            }
    
            let dayOfWeek = firstDayOfMonth.getDay();
            // Checking if it's a weekend and whether to include it based on showWeekends
            if (!showWeekends && (dayOfWeek === 0 || dayOfWeek === 6)) {
                continue; // Skip weekend days if showWeekends is false
            }
    
            let c_date = firstDayOfMonth.getDate();
            let c_month = firstDayOfMonth.getMonth() + 1;
            let c_year = firstDayOfMonth.getFullYear();
            if (c_date < 10) c_date = '0' + c_date;
            if (c_month < 10) c_month = '0' + c_month;
            let full_date = c_month+'/'+c_date+'/'+c_year;
    
            let calendarDay = {
                currentMonth: (firstDayOfMonth.getMonth() === month),
                date: (new Date(firstDayOfMonth)),
                c_date: full_date,
                month: firstDayOfMonth.getMonth() + 1,
                number: firstDayOfMonth.getDate(),
                selected: (firstDayOfMonth.toDateString() === date.toDateString()),
                year: firstDayOfMonth.getFullYear(),
                [full_date]: {data: []}
            }
        
            currentDays.push(calendarDay);
        }
        const chunkingCount = (showWeekends===true)?7:5;
        const currentDaysArr =  currentDays.reduce((resultArray, item, index) => { 
            const chunkIndex = Math.floor(index/chunkingCount);
            // console.log('chunkIndex',chunkIndex);
            if(!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, []);
        setCalendarMonth(currentDaysArr);
    }, [currentDay, calendarViewType,showWeekends]);
    
    
    return(
        <CalendarBodyHoc {...props.calendarHocProps}>
            <div className="fc-view fc-dayGridMonth-view fc-basic-view fc-dayGrid-view">
                <table >
                    <MonthViewHeader showWeekends={showWeekends}/>
                    <MonthViewBody
                        {...props}
                        calendarMonth={calendarMonth} 
                    />
                     
                </table>
            </div>
        </CalendarBodyHoc>
    )
}
CalendarMonthView.defaultProps = {
    isShowActivities:false
}

export default CalendarMonthView;