import { fetchTimeInterval, getCurrentDate, getCurrentTime, getLookupByKey } from "../../../../../../utils/commonUtils";
import { VACCINATION_NAME, VACCINATION_SPECIFY_REASON } from "../../../../../../utils/constant";

export const fetchVaccineName = async (val, props, callback = () => { }) => {
  let optionArr = [];
  try {
    const vaccine_name = await getLookupByKey(VACCINATION_NAME);
    optionArr = vaccine_name;
    callback(optionArr);
  } catch (e) {

  }
  return optionArr;
}
export const fetchVaccineSpecifyReason = async (val, props, callback = () => { }) => {
  let optionArr = [];
  try {
    const reason = await getLookupByKey(VACCINATION_SPECIFY_REASON,false);
    optionArr = reason;
    console.log("reason",reason);
    callback(reason);
  } catch (e) {

  }
  return optionArr;
}
export const Vaccination = (props) => {

  let charts = [{
    type: 'Grid',
    col: 'col-lg-3',
    data: [{
      type: "PatientDetails",
      employeeDetails: props.employeeDetails,
      visitDetails: props.visitDetails
    }, {
      type: "CompanyDetails",
      companyDetails: props.companyDetails
    }],
  },
  {
    type: 'SectionBased',
    col: 'col-lg-9',
    data: [
      {
        label: `Activity Details - ${props.activityName}`,
        fields: [
          {
            label: "Visit Date",
            placeholder: "",
            className: "form-control form-control-solid",
            id: "random_lab_id",
            options: [],
            type: 'Date',
            name: "visit_date",
          },
          {
            type: "SelectSearchList",
            label: "Visit Time",
            placeholder: "Time",
            isAsync: true,
            options: [{ 'label': getCurrentTime(), value: getCurrentTime() }],
            loadCallBack: (val, callback) => fetchTimeInterval(val, callback),
            className: "form-control form-control-solid",
            name: "visit_time",
          },
          {
            type: "SelectSearchList",
            label: "Vaccine Name",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "vaccine_name",
            isAsync: true,
            options:[],
            loadCallBack: (val, callback) => fetchVaccineName(val, props, callback),
          },
          {
            type:"NonEdit" ,
            label: "",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "",
          },
          {
            type: "RadioGroup",
            options:[{label:'Yes',value:1},{label:'No',value:0}],
            formGroup: "form-group form-check form-check-solid",
            className: "form-check-input",
            label: "Patient declines vaccination",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "patient_declines_vaccination",
          },
          {
            type:"NonEdit" ,
            label: "",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "",
            rules:[{rule:[{field:'patient_declines_vaccination',value:1,operator:'!=='}],action:'SHOW'}],
          },
          {
            type:"SelectSearchList",
            label: "Specify reason",
            options:[],
            isAsync: true,
            placeholder: "",
            isMulti:true,
            className: "form-control form-control-solid",
            name: "specify_reason",
            rules:[{rule:[{field:'patient_declines_vaccination',value:1,operator:'==='}],action:'SHOW'}],
            loadCallBack: (val, callback) => fetchVaccineSpecifyReason(val, props, callback),
          },
          {
            type:"" ,
            label: "Lot Number",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "lot_number",
          },
          {
            type: "SelectList",
            label: "Vaccine Administration Site",
            placeholder: "",
            className: "form-control form-control-solid",
            options:[{label:"--Select--",value:""},{label:"Left Deltoid",value:"Left Deltoid"},{label:"Right Deltoid",value:"Right Deltoid"}],
            name: "vaccination_adminstration_site",
          },
          {
            type: "Date",
            label: "Vaccine Expiration Date",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "vaccine_expiration_date",
          },
          {
            type: "SelectList",
            options:[{label:"--Select--",value:""},{label:"1",value:"1"},{label:"2",value:"2"}],
            label: "Vaccine Series",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "series",
          },
          {
            type: "TextArea",
            label: "Notes",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "notes",
          },
          {
            type: "SelectList",
            options:[{label:"--Select--",value:""},{label:"Completed/Administrated",value:"Completed/Administrated"},{label:"Ordered",value:"Ordered"}],
            label: "Status",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "status",
          },
          
        ]
      },]
  }];
  const defaultValue = {
    visit_date:getCurrentDate(),
    visit_time:getCurrentTime(),
  };
  const buttons = {

  }
  return { buttons, charts, defaultValue, computationFunction: (formData, key) => computationRules(formData, key) };
}
const computationRules = (formData, key) => {
  let returnResult = {};
  return returnResult;
}