// import React from "react";
// // import { useParams } from 'react-router-dom';
// import LoaderContext from "../context/LoaderContext";
// import { ToastContext} from "../context/ToaxtContext";

// const WithRoutify = (WrappedComponent) => {
//   // return class extends React.PureComponent {
//   //   render() {
//   //     return (
//   //       <LoaderContext.Consumer>
//   //         {(loaderContext) => (
//   //           <ToastContext.Consumer>
//   //             {(toastContext) => (
//   //               <WrappedComponent
//   //                 {...this.props}
//   //                 // params={useParams()}
//   //                 Loader={loaderContext.Loader}
//   //                 Toast={toastContext}
//   //               />
//   //             )}
//   //           </ToastContext.Consumer>
//   //         )}
//   //       </LoaderContext.Consumer>
//   //     );
//   //   }
//   // };
// };

// export default WithRoutify;



// Define a Higher Order Component (HOC)
// import React from "react";
// import {showToast,loader} from "../store/CommonSlice";

// const WithRoutify = (WrappedComponent) => {
 
//   return class extends React.Component {
    
    
//     render() {
//       const toast = {
//         success:(message) => () => {
//           dispatch(showToast({ type: 'success', message }));
//         },
//         error:(message) => () => {
//           dispatch(showToast({ type: 'success', message }));
//         },
//         warning:(message) => () => {
//           dispatch(showToast({ type: 'success', message }));
//         }
//       }
//       // Pass the shared methods as props to the wrapped component
//       return (
//         <WrappedComponent
//           Toast={toast}
//           Loader={loader}
//           {...this.props}
//         />
//       );
//     }
//   }
// };

// export default WithRoutify;

import React from "react";
import { useDispatch } from "react-redux"; 
import { success, error, warning, loader } from "../store/CommonSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const WithRoutify = (WrappedComponent) => {
  return function WithRoutifyWrapper(props) {
    const dispatch = useDispatch(); 
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    const Toast = {
      success: (message) =>  dispatch(success(message)),
      error: (message) => dispatch(error(message)),
      warning: (message) => dispatch(warning(message)),
    };

    return <WrappedComponent 
      Toast={Toast} 
      Loader={(bool)=>dispatch(loader(bool))}
      location={location}
      params={params}
      navigate={navigate}
      {...props} />;
  };
};
export default WithRoutify;