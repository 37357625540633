import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isObject,isSet } from "../../../../utils/commonUtils";
import FormInputs from "../../../../components/UI/FormInputs";
import WithRoutify from "../../../../hoc/WithRoutify";
import VisitsService from "../../../../services/VisitsService";
import WithRouter from "../../../../hoc/WithRouter";
import ActivityConsentDeclationService from "../../../../services/ActivityConsentDeclationService";
import OrganizationService from "../../../../services/OrganizationService";
import PatientChartsService from "../../../../services/PatientChartsService";

const AddActivities=(props)=>{
  const { isOpen,addToogleHandler, modelTitle,Toast,options,params} =props;
  const [consentDetails,setConsentDetails] = useState({activityConsent:{},activityDeclination:[]});
  
  const [formData,setFormData] = useState({
    visit_id: params.visitId,
    activity_id: '',
    declination_message_checked:false,
  })
  const [validation,setValidation] = useState({});
  const fetchConsentActivity= async (activityId)=>{
    try {
        const data = await ActivityConsentDeclationService.getConsentActivitiesByActivityId(activityId);
        const {chartDetails} = props;
    
        setFormData({
            ...formData,
            consent_authorization_message:activityConsent.authorization_message_en,
            declination_message:activityConsent.declination_message_en,
            consent_msg:data.activityConsent.activity_consent_content_en,
            ...isSet(chartDetails.activityConsentDeclination,{})
        });
        setConsentDetails(data);
    }catch(e){

    }
  }
  useEffect(()=>{
    const {chartDetails} = props;
    
    // console.log('chartDetails',);
    fetchConsentActivity(isSet(chartDetails.actitivity_id,''));
  },[]);
 
  
//   console.log('formData',props);
  const {activityConsent,activityDeclination} = consentDetails;
//   console.log('activityDeclination',activityDeclination);

      
        const inputFields = [
               {
                type:'Checkbox',
                label:isSet(activityConsent.authorization_message_en,''),
                col:'col-12',
                isLabelAppend: true,
                className: "form-check-input",
                name:'authorization_message_checked',
                id:'authorization_message_checked',
               },
               {
                type:'Checkbox',
                col:'col-12',
                name:'declination_message_checked',
                id:'declination_message_checked',
                isLabelAppend: true,
                className: "form-check-input",
                label:isSet(activityConsent.declination_message_en,''),
               },
               {
                type:'Blank',
                isShow: Boolean(isSet(formData?.declination_message_checked,false)),   
                name:''
                },
                {
                    type:'Checkbox',
                    label:'Other Reason',
                    className: "form-check-input",
                    isLabelAppend: true,
                    isShow:Boolean(isSet(formData?.declination_message_checked,false)),   
                    name:'is_other_reason',
                    id:'is_other_reason'
                },
               {   
                label:'Reasons for Declination',
                type:"SelectSearchList",
                isShow:Boolean(isSet(formData?.declination_message_checked,false)),   
                isMulti:true,
                options:activityDeclination.map((item) => {
                    return {
                        label: item.reason_for_declination_en,
                        value: item.reason_for_declination_en,//activity_declination_id
                    };
                }),
                name:'reasons_for_declination',
                },
                
                
                {
                    label:'Specify Reason',
                    type:'TextArea',
                    isShow:Boolean(isSet(formData?.declination_message_checked,false)),   
                    disabled:isSet(Boolean(formData?.is_other_reason),false)===true?false:true,
                    name:'other_reason',
                    id:'other_reason'
                },
              {
                label: "Patient Signature/Consent",
                type: "Signature",
                className: "form-control form-control-solid",
                name: "patient_signature",
                options:[],
                required:true
              },
              {
                label:'Date',
                type:'Date',
                name:'consent_date',
                required:true
              }
        ];
        
        const changeHandler = (val, fieldName) => {
            let obj = {};
            if(fieldName==='declination_message_checked'){
                obj = {authorization_message_checked:false};
            }else if(fieldName==='authorization_message_checked'){
                obj = {declination_message_checked:false};
            }

          setFormData({
              ...formData,
              [fieldName]: val,
              ...obj
            });
            setValidation({...validation,[fieldName]:''});
        };
        const formSubmitHandler = async () => {
          let updateValidation = {...validation};
            const { chartDetails,companyDetails,employeeDetails,params,Toast,Loader } = props;
            const companyUuid = companyDetails.company_uuid;
            const { mapping_id } = chartDetails;
            const payload = {
                ...formData,
                activity_id:chartDetails.actitivity_id,
                patient_id:employeeDetails.employee_id,
                company_id:companyUuid,
                organization_uuid:OrganizationService.getOrgId(),
                visit_id:params.visitId,
            };
            Loader(true);
            try{
                let data = {};
                if(isSet(payload.id,null)===null){
                    data = await PatientChartsService.saveActivityConsentCharts(isSet(formData.organization,null),companyUuid,mapping_id,payload);
                }else{
                     data = await PatientChartsService.updateActivityConsentCharts(isSet(formData.organization,null),companyUuid,mapping_id,payload);
                }
                Loader(false);
                if(data.status && data.status==="ERROR"){
                    if (isObject(data.data)) {
                      for (let key in data.data) {
                        updateValidation = {
                          ...updateValidation,
                          [key]: data.data[key].join(","),
                        };
                      }
                    } else {
                    Toast.error(data.data);
                    }
                    if (Object.keys(updateValidation).length > 0) {
                      setValidation(updateValidation);
                    }
                }else{
                    props.fetchVisitDetails();
                    props.toogleHanlder();
                    Toast.success('Charts Consent Updated Successfully');
                }

                
            }catch(e){
                Loader(false);
                console.log('e',e)
            }
        }
      return (
        <>
          <Modal show={isOpen} 
          onHide={props.toogleHanlder}
          keyboard={false}
          dialogClassName="hResponsive"
          contentClassName="h-100"
          backdrop="static" size="lg">
           <div className="sh_modalBox">
              <Modal.Header closeButton>
                <Modal.Title>
                  <h6 className="text-lg mb-0 font-weight-bold">
                    Consent Form
                  </h6>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="tab-pane active show" role="tabpanel" id="ac">
                    <div className="card mb-2">
                        <a href="#collapseCardExample" className="d-block sh_accordionheader card-header" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample" style={{position:'sticky',top:'-20px',zIndex:'1'}}>
                            <h6 className="m-0 text-md ">Activity Consents for <span className="badge bg-blue-soft text-blue"> {props.chartDetails.activity_name} </span> </h6>
                        </a>
                        <div className="collapse show" id="collapseCardExample" data-bs-parent="#modal111">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 mb-2 p-1" style={{border:'1px solid var(--snap-common-border)',borderRadius:'10px'}}>
                                    <HTMLContent htmlString= {activityConsent.activity_consent_content_en} />
                                    </div>
                                {inputFields &&
                                    inputFields.map((field, fieldIndex) => {
                                        if(isSet(field.isShow,true)===false){return false}
                                        return(
                                            <div className={`${isSet(field.col,'col-6')}`} key={fieldIndex}>
                                        <div className={`form-group ${isSet(field.isLabelAppend, false) === true ? 'form-check form-check-solid' : ''}`} >
                                          {(isSet(field.isLabelAppend, false) === false) ?
                                            <label className="mb-0 text-sm opacity-75 text-truncate">
                                              {field.label}
                                            </label>
                                            : <></>}
            
                                          <FormInputs {...field} value={isSet(formData[field.name],'')} changeHandler={(value, e) => changeHandler(value, field.name)} />
                                          {(isSet(field.isLabelAppend, false) === true) ?
                                            <label className="form-check-label" htmlFor={field.name}>
                                              {field.label}
                                            </label>
                                            : <></>}
                                          {isSet(validation[field.name], "") !== "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation[field.name], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
              </Modal.Body>
      
              <Modal.Footer className="d-flex justify-content-center">
                <Button variant="primary" className="" onClick={()=>formSubmitHandler()}>
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        </>
      );
    };
export default WithRouter(WithRoutify(AddActivities));


const HTMLContent = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };