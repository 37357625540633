import React,{Component} from 'react';
import CalendarHeader from './CalendarHeader';
import CalendarBody from './CalendarBody';
import {MONTHS,MONTHS_SHORT} from './constants';
import { isSet } from '../../utils/commonUtils';
import Buttons from '../UI/Buttons';
import { getDateRangeHandler } from './calendarFunctions';
import withReduxState from '../../hoc/wReduxState';
import { fetchCurrentDate } from '../../utils/DateUtils';

class Calendar extends Component{
    constructor(props) {
      super(props);
      this.state = {
        calendarOptions:{
            showWeekends:true
        },
        direction:null,
        currentDay:this.props.currentDay,
        calendarLabel: MONTHS[fetchCurrentDate().getMonth()] +' '+fetchCurrentDate().getFullYear(),
        isShowMorePopup:false,
        isShowQuickViewPopup:false,
        anchorTagPosition:{},
        popupCalendarEvent:{},
        quickViewDetails:{},
        popupCalendarData:{dateTime:{},data:[]}
      }
    }
    
    componentDidMount = (prevProps,prevState)=>{
        const {calendarViewType} = this.props;
        this.setState({    
            currentDay:isSet(this.props.currentDay,fetchCurrentDate()),
            calendarLabel:this.updateCalendarLabel(this.state.currentDay,calendarViewType)
        });
        // this.scrolIntoStartingtime();
       
    }
    componentDidUpdate = (prevProps,prevState)=>{
        if(prevProps.currentDay!==this.props.currentDay && prevProps.isCalendarWidgetDate!==this.props.isCalendarWidgetDate){
            this.currentDayChangeHandler(this.props.currentDay,this.state.direction);
            // this.scrolIntoStartingtime();
        }
        // const elemt = document.getElementById("calendarRow_0700");
        // const rect = element.getBoundingClientRect();  // Get the element's position relative to the viewport
        // const topPosition = rect.top + window.pageYOffset; // Add the current scroll position to get the top relative to the document
        // console.log("Top position:", topPosition);
        // console.log('this.props',this.props);
        // document.getElementById("calendarRow_0700").scrollIntoView({
        //     behavior: "smooth",  // Optional: for smooth scrolling
        //     block: "start",     // Scroll the element to the center of the viewport
        //     inline: "nearest"    // Aligns the element vertically and horizontally
        // });

        if(prevState.isShowQuickViewPopup!==this.state.isShowQuickViewPopup){
            var popup = document.getElementById('calendarQuickViewPopup');
            if (popup) {
                // Ensure the element is focusable
                popup.setAttribute('tabindex', '-1');
                // Scroll the element into view
                popup.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                // Focus the element
                // popup.focus();
            }
        }
        if(prevState.isShowMorePopup!==this.state.isShowMorePopup){
            var popup = document.getElementById('calendarViewMorePopup');
            if (popup) {
                // Ensure the element is focusable
                popup.setAttribute('tabindex', '-1');
                // Scroll the element into view
                popup.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                // Focus the element
                // popup.focus();
            }
        }
    }

    // scrolIntoStartingtime = ()=>{
        // const checkInterval = 100; 
        // const maxAttempts = 20;
        // let attempts = 0;
        // const intervalId = setInterval(() => {
        // const element = document.getElementById("calendarRow_0730");

        // if (element) {
        //     element.scrollIntoView({
        //     behavior: "smooth",  // Optional: for smooth scrolling
        //     block: "start",     // Scroll the element to the start of the viewport
        //     inline: "nearest"   // Aligns the element vertically and horizontally
        //     });
        //     clearInterval(intervalId); // Clear the interval
        // } else if (attempts >= maxAttempts) {
        //     // Stop checking if the maximum number of attempts is reached
        //     clearInterval(intervalId);
        //     // console.log("Element not found after maximum attempts");
        // }
        // attempts++;
        // }, checkInterval);
    // }
    calendarOptionOnChangeHandler = (key,val)=>{
        // console.log('key,val',key,val);
        this.setState({
            calendarOptions:{
                ...this.state.calendarOptions,
                [key]:val
            }
        })
    }
    showMoreCalendarGridForm = (e, dateItem, calendarData) => {
        // Prevent default behavior of the anchor tag
        e.stopPropagation();
        e.preventDefault();
        const closestTr = e.target.closest('td').querySelector('a');
        console.log('closestTr',closestTr);
        const cellRect = closestTr.getBoundingClientRect();
        const calendarDiv = document.getElementById('calendar');
        const calendarRect = calendarDiv.getBoundingClientRect();
    
        // Calculate position relative to the calendar, considering scroll position
        const topRelativeToCalendar = cellRect.top - calendarRect.top + calendarDiv.scrollTop;
    
        // Calculate the left position relative to the viewport
        const leftRelativeToViewport = cellRect.left - calendarRect.left;

        // const rightRelativeToViewport = cellRect.right - calendarRect.right;
        const widthRelativeToViewport = cellRect.width - calendarRect.width;
    
        // Get viewport dimensions
        const viewportWidth = window.innerWidth;
        
        
        // if(right)
        // Adjust the left position if the popup overflows the right edge of the viewport
        const popupWidth = 240;
        
        let left = leftRelativeToViewport;
        if (leftRelativeToViewport + popupWidth > viewportWidth) {
            left = viewportWidth - popupWidth;
        }
        // console.log('calendarRect',cellRect.width,calendarRect.width);
        // console.log('calendarRect',Math.abs(widthRelativeToViewport)<Math.abs(leftRelativeToViewport+popupWidth),Math.abs(widthRelativeToViewport)-Math.abs(leftRelativeToViewport+popupWidth));
        if(Math.abs(widthRelativeToViewport)<Math.abs(leftRelativeToViewport+popupWidth)){
            // console.log('Beforeleft',left);
            left = left-Math.abs(Math.abs(widthRelativeToViewport)-Math.abs(leftRelativeToViewport+popupWidth));
            // console.log('Afterleft',left);
        }
        // if(0>rightRelativeToViewport){
        //     left = (left-rightRelativeToViewport)-popupWidth;
           
        // }
        const anchorTagPosition = {
            top: (topRelativeToCalendar + cellRect.height)-30,
            left: left
        };
    
        this.setState({
            isShowMorePopup: true,
            anchorTagPosition: anchorTagPosition,
            popupCalendarEvent: e,
            popupCalendarData: { dateTime: dateItem, data: calendarData }
        });
    }

    showQuickVisitView = (e, calendarData) => {
        // Prevent default behavior of the anchor tag
        e.stopPropagation();
        e.preventDefault();
        const {popupCalendarEvent} = this.state;
        const evnt = e;//Object.entries(popupCalendarEvent).length===0?e:popupCalendarEvent;
        let closestTr = {};
        try{
            closestTr = evnt.target.closest('td').querySelector('a');
        }catch(e){
            closestTr = popupCalendarEvent.target.closest('td').querySelector('a');
        }
        

        const cellRect = closestTr.getBoundingClientRect();
        const calendarDiv = document.getElementById('calendar');
        const calendarRect = calendarDiv.getBoundingClientRect();
    
        // Calculate position relative to the calendar, considering scroll position
        const topRelativeToCalendar = cellRect.top - calendarRect.top + calendarDiv.scrollTop;
    
        // Calculate the left position relative to the viewport
        const leftRelativeToViewport = cellRect.left - calendarRect.left;

        // const rightRelativeToViewport = cellRect.right - calendarRect.right;
        const widthRelativeToViewport = cellRect.width - calendarRect.width;
    
        // Get viewport dimensions
        const viewportWidth = window.innerWidth;
        
        
        // if(right)
        // Adjust the left position if the popup overflows the right edge of the viewport
        const popupWidth = 340;//240;
        
        let left = leftRelativeToViewport;
        if (leftRelativeToViewport + popupWidth > viewportWidth) {
            left = viewportWidth - popupWidth;
        }
        if(Math.abs(widthRelativeToViewport)<Math.abs(leftRelativeToViewport+popupWidth)){
            left = left-Math.abs(Math.abs(widthRelativeToViewport)-Math.abs(leftRelativeToViewport+popupWidth));
        }
        // if(0>rightRelativeToViewport){
        //     left = (left-rightRelativeToViewport)-popupWidth;
           
        // }
        const anchorTagPosition = {
            top: (topRelativeToCalendar + cellRect.height)-30,
            left: left
        };

       
        this.setState({
            isShowQuickViewPopup: true,
            // isShowMorePopup:
            anchorTagPosition: anchorTagPosition,
            quickViewDetails:calendarData
        });
    }
    
    openViewMorePopupToogle = ()=>{
        this.setState({isShowMorePopup:!this.state.isShowMorePopup});
    }
    openQuickVisitViewPopupToogle = ()=>{
        this.setState({isShowQuickViewPopup:!this.state.isShowQuickViewPopup});
    }
    render(){
       
        const {calendarOptions,calendarLabel,currentDay,isShowMorePopup,isShowQuickViewPopup,quickViewDetails,popupCalendarData,anchorTagPosition} = this.state;
        const {calendarViewType,visitStatusOption} = this.props;
        return(
            <>
                <div className="sh_cardHeader">
                    <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                    {/*<h6 className="text-lg f-500 mb-0"> Appointments </h6>*/}
                        
                        <>
                            <CalendarHeader 
                                {...this.props}
                                calendarViewType={calendarViewType}
                                calendarOptions={calendarOptions}
                                currentDay={currentDay} 
                                label={calendarLabel}
                                // visitStatusOption={visitStatusOption}
                               
                                calendarOptionOnChangeHandler={this.calendarOptionOnChangeHandler}
                                currentDayChangeHandler={this.currentDayChangeHandler}
                                viewTypeChangeHandler={this.calendarViewTypeChangeHandler}  />
                        </>
                        <div className="addAction">
                            <div className="btn-group">
                                <Buttons
                                // appendAddIcon={true}
                                clickHandler={() => this.props.addVisitToogleHandler()}
                                className="btn btn-primary"
                                acl={["visits-create",'appointment-create']}
                                label={"Add Visit"} />
                            </div>
                        </div>
                     
                    </div>
                </div>
                <div className="sh_cardBody p-0 position-relative">
                    <div className="card border-0 bg-transparent" style={{position:'absolute', inset:'0'}}>
                        <div className="sh_innerPortview w-100">
                
                            <CalendarBody 
                                {...this.props}
                                calendarHocProps = {{
                                    direction:this.state.direction,
                                    currentDay:this.state.currentDay,
                                    isShowMorePopup:isShowMorePopup,
                                    isShowQuickViewPopup:isShowQuickViewPopup,
                                    anchorTagPosition:anchorTagPosition,
                                    popupCalendarData:popupCalendarData,
                                    visitStatusOption:visitStatusOption,
                                    quickViewDetails:quickViewDetails,
                                    calendarActionHandler:this.refetchCalendarDetails,
                                    modalToogle:this.openViewMorePopupToogle,
                                    quickViewModalToogle:this.openQuickVisitViewPopupToogle,
                                    viewVisitHandler:this.props.viewVisitHandler,
                                    showQuickVisitView:this.showQuickVisitView,
                                }}
                                currentDay={currentDay} 
                                todayDate={fetchCurrentDate()}
                                calendarOptions={calendarOptions}
                                showQuickVisitView={this.showQuickVisitView}
                                calendarViewType={calendarViewType}
                                showMoreDetailsDataHandler={this.showMoreCalendarGridForm}
                            />
                        </div>
                    </div>
                </div> 
            </>
        )
    }

    

    currentDayChangeHandler = async (val,type=null)=>{
        const {calendarViewType} = this.props;
        this.setState({
            currentDay:val,
            direction:type,
            calendarLabel:this.updateCalendarLabel(val,calendarViewType)
        },()=>{
            let calendarParam = {}
            calendarParam = getDateRangeHandler(calendarViewType,val);        
            this.props.calendarActionHandler(calendarViewType,calendarParam,val);
        });
        
    }
    refetchCalendarDetails = ()=>{
        let calendarParam = {}
        const {calendarViewType} = this.props;
        const {currentDay}  = this.state;
        calendarParam = getDateRangeHandler(calendarViewType,currentDay);  
        this.props.calendarActionHandler(calendarViewType,calendarParam,currentDay);
    }
    calendarViewTypeChangeHandler =  (viewType)=>{
        let {currentDay} = this.state;
         this.setState({
            direction:null,
            calendarLabel:this.updateCalendarLabel(currentDay,viewType),
            currentDay:currentDay
        },()=>{
            //console.log('calendarViewTypeChangeHandler===',currentDay)
            let calendarParam = {}
            calendarParam = getDateRangeHandler(viewType,currentDay);        
            //console.log('calendarViewTypeChangeHandler==',calendarParam)
            //await this.props.changeGridViewHandler('CALENDAR',{},true,viewType,calendarParam,currentDay)
             this.props.calendarActionHandler(viewType,calendarParam,currentDay);
            //  this.scrolIntoStartingtime();
             
        });
        
    }
    updateCalendarLabel = (currentDay,calendarViewType)=>{
        //Default Month view label
        let calendarLabel = MONTHS[currentDay.getMonth()] +' '+currentDay.getFullYear();
        if(calendarViewType==='DAY'){
            //Day view Label
            calendarLabel           = currentDay.getDate()+' '+MONTHS[currentDay.getMonth()] +' '+currentDay.getFullYear();
        }else if(calendarViewType==='WEEK'){
            //Week view label
            let firstDayOfWeek      = new Date(currentDay.setDate(currentDay.getDate() - currentDay.getDay() ));
            const lastDayOfWeek     = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate()+6);
            const fromDateStr       = firstDayOfWeek.getDate();
            const fromDateMonth     = MONTHS_SHORT[firstDayOfWeek.getMonth()];
            const fromDateYear      =  firstDayOfWeek.getFullYear();
            const toDateMonth       = MONTHS_SHORT[lastDayOfWeek.getMonth()];
            const toDateYear        =  lastDayOfWeek.getFullYear();
            const toDateStr         = lastDayOfWeek.getDate();

            const fromDateMonthStr  = fromDateMonth;
            const fromDateYearStr   = (fromDateYear!==toDateYear)?', '+fromDateYear:'';
            const toDateMonthStr    =  (fromDateMonth===toDateMonth)?'':toDateMonth;
            const toDateYearStr     = ', '+toDateYear;

            calendarLabel  = (fromDateMonthStr)+' '+fromDateStr+fromDateYearStr+' - ';
            calendarLabel += (toDateMonthStr)+' '+toDateStr+toDateYearStr;
        }
        return calendarLabel;
    }
}


Calendar.defaultProps = {
    currentDay:fetchCurrentDate(),
    calendarViewType:'WEEK',
}
export default withReduxState(Calendar);