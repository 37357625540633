import React, { Component } from "react";
import FormInputs from "../../../components/UI/FormInputs";
import EmployeeService from "../../../services/EmployeeService";
import { isObject, isSet } from "../../../utils/commonUtils";
import TableGrid from "../../../components/UI/TableGrid";
import WithRoutify from "../../../hoc/WithRoutify";
import LookupService from "../../../services/LookupService";
import SectionLoader from "../../../components/UI/SectionLoader";
import { LAB_TEST_RESULT, LAB_TYPE } from "../../../utils/constant";

class Labs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labFormData: {},
            labsList: { tableHeaders: [], tableRows: { data: [] } },
            selectListOptions: { labType: [], testResult: [] },
            activeEditId:null,
            formDataInEdit:{},
            isLoading:false,
            validation:{},
        };
    }

    componentDidMount() {
        this.fetchLabsList();
        this.fetchLookupsData();
    }
    componentDidUpdate = (prevProps,prevState)=>{
        if(prevProps.formData.proof_documents!==this.props.formData.proof_documents || prevState.selectListOptions!==this.state.selectListOptions){
            this.fetchLabsList();
        }
    }
    setLoaded = (bool)=>{
        this.setState({isLoading:bool})
    }

    fetchLabsList = async () => {
        try {
            const labDocuments = isSet(this.props.formData.proof_documents, []);
            // console.log('labDocuments',labDocuments);
            const tableData = labDocuments.map((item) => ({
                rowId: item.proof_document_id,
                data: {
                    snap_file_manager: item.snap_file_manager,
                    document_type: item.document_type,
                    test_date: item.test_date,
                    test_result: item.test_result,
                    action: [{
                        className: "btn btn-icon btn-transparent-dark",
                        iconType: "Edit",
                        title: "Edit",
                        type: "GridEdit",
                        acl: "employee-edit",
                        clickHandler: (rowId, data) => this.editHandler(rowId, data),
                        updateHandler: () => this.updateHandler(item.proof_document_id),
                        onChangeFormDataInEdit: (key, val) =>
                          this.onChangeFormDataInEdit(key, val),
                      },{
                        className: 'btn btn-icon btn-transparent-dark',
                        iconType: 'Remove',
                        acl: 'employee-delete',
                        title: 'Delete',
                        isDelete:true,
                        clickHandler: () => this.deleteHandler(item.proof_document_id),
                    }]
                }
            }));

            this.setState({ labsList: { tableHeaders: this.getTableHeaders(), tableRows: { data: tableData } } });
        } catch (error) {
            console.error("Error fetching lab documents:", error);
        }
    }

    getTableHeaders = () => [
        { label: 'Lab Document', key: 'snap_file_manager', type: 'FileType', inputType: 'FileUpload' },
        { label: 'Type', key: 'document_type', type: '',inputType: 'SelectList',options:this.state.selectListOptions['labType'] },
        { label: 'Date', key: 'test_date', type: '',inputType: 'Date' },
        { label: 'Result', key: 'test_result', type: '',inputType: 'SelectList',options:this.state.selectListOptions['testResult']  },
        { label: 'Action', key: 'action', type: 'Actions' },
        // Add more columns as needed
    ];

    changeHandler = (val, key) => {
        this.setState(prevState => ({
            labFormData: { ...prevState.labFormData, [key]: val },
            validation:{...prevState.validation,[key]:''}
        }));
    }

    submitHandler = async () => {
        this.setLoaded(true);
        try {
            const formDataPayload = new FormData();
            formDataPayload.append('employee_id', this.props.employeeId);
            for (const key in this.state.labFormData) {
                formDataPayload.append(key, this.state.labFormData[key]);
            }
            formDataPayload.append('documeny_type',this.state.labFormData.document_type)


            const response= await EmployeeService.addProffOfLabsAndVaccine(formDataPayload);
            let updateValidation={...this.state.validation};
            this.setLoaded(false);
            if (response.status && response.status == "ERROR") {
                if (isObject(response.data)) {
                  for (let key in response.data) {
                    updateValidation = {
                      ...updateValidation,
                      [key]: response.data[key].join(","),
                    };
                  }
                } else {
                  this.props.Toast.error(response.data);
                }
                if (Object.keys(updateValidation).length > 0) {
                  this.setState({validation:updateValidation});
                  return;
                }
              }
            this.props.fetchEmployeeDetails(this.props.employeeId,'LAB');
            this.setState({labFormData:{}});
        } catch (error) {
            console.error("Error submitting lab document:", error);
        }
    }

    deleteHandler = async (additionalDocId) => {
            this.setLoaded(true);
            this.setState({ validation: '' });
            try {
                await EmployeeService.deleteProffOfLabsAndVaccine(additionalDocId);
                this.props.Toast.success('Document Removed successfully');
                this.props.fetchEmployeeDetails(this.props.employeeId);
            } catch (error) {
                console.error("Error deleting lab document:", error);
            }
            this.setLoaded(false);
    }

    fetchLookupsData = async () => {
        try {
            const [labTypeData, testResultData] = await Promise.all([
                LookupService.getLookupByKey(LAB_TYPE),
                LookupService.getLookupByKey(LAB_TEST_RESULT)
            ]);
            let labTypeOptions = [{ label: '-None-', value: '' }];
            let testResultOptions = [{ label: '-None-', value: '' }];
            labTypeData.forEach((item) => {
                labTypeOptions.push({
                    label: item.value,
                    value: item.value
                });
            });
            testResultData.forEach((item) => {
                testResultOptions.push({
                    label: item.value,
                    value: item.value
                });
            });
            this.setState({
                selectListOptions: {
                    labType: labTypeOptions,
                    testResult: testResultOptions
                }
            });
        } catch (e) {

        }
    }

    render() {
        const { formDataInEdit,labFormData, labsList, selectListOptions ,validation} = this.state;

        const inputFields = [
            {
                type: 'FileUpload',
                label: 'Lab Document',
                title: 'Field Document',
                className: '',
                name: 'file',
                value: labFormData.file,
            },
            {
                type: 'SelectList',
                label: 'Lab Type',
                options: selectListOptions['labType'],
                className: 'form-control form-control-solid',
                name: 'document_type',
                required:true
            },
            {
                type: 'Date',
                label: 'Test Date',
                className: 'form-control form-control-solid',
                name: 'test_date'
            },
            {
                type: 'SelectList',
                label: 'Test Result',
                options: selectListOptions['testResult'],
                className: 'form-control form-control-solid',
                name: 'test_result'
            }
        ];

        return (
            <div className="sh_innerPortview">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="sh_cardBox h-auto shadow-none border">
                            <div className="sh_cardBody p-3">
                                <div className="row">
                                    {inputFields.map((item, index) => (
                                        <div className="col-12" key={index}>
                                            <div className="form-group">
                                                <label className="text-sm text-truncate mb-0 opacity-75">{item.label}</label>
                                                <FormInputs
                                                    {...item}
                                                    value={isSet(labFormData[item.name], '')}
                                                    changeHandler={(val) => this.changeHandler(val, item.name)}
                                                />
                                                {isSet(validation[item.name], "") !== "" ? (<span className="text-danger m-1"> {validation[item.name]}</span>)
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="sh_cardFooter justify-content-center">
                                <button className="btn btn-primary btn-sm text-sm" onClick={this.submitHandler}>Add</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8">
                        <div className="sh_cardBox h-auto shadow-none border">
                            <div className="sh_cardHeader">
                                <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                                    <h6 className="text-md mb-0">Proof of Labs or Vaccinations - Employee Submitted</h6>
                                </div>
                            </div>
                            <div className="sh_cardBody p-0">
                                <SectionLoader isLoading={this.state.isLoading}/>
                                <TableGrid 
                                    {...labsList} 
                                    gridEditProps={{
                                        formDataInEdit: formDataInEdit,
                                        activeEditId: this.state.activeEditId,
                                        onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                                      }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    editHandler = (rowId, data) => {
        if (rowId == null) {
          this.setState({ activeEditId: "", formDataInEdit: {} });
          return false;
        }
        this.setState({
          activeEditId: rowId,
          formDataInEdit: data,
        });
      };
      
      onChangeFormDataInEdit = (key, val) => {
        this.setState((prevState) => ({
          formDataInEdit: {
            ...prevState.formDataInEdit,
            [key]: val,
            validation: {
              ...prevState.formDataInEdit.validation,
              [key]: "", // Set validation empty
            },
          },
        }));
      };
      updateHandler = async () => {
        this.setLoaded(true);
        this.setState({ validation: {} });
        const { formDataInEdit,activeEditId } = this.state;
        // console.log('formDataInEdit',formDataInEdit,proofId);
        const formDataPayload = new FormData();
        if (formDataInEdit) {
            formDataPayload.append('document_type', formDataInEdit.document_type || '');
            formDataPayload.append('test_date', formDataInEdit.test_date || '');
            formDataPayload.append('test_result', formDataInEdit.test_result || '');
            if (formDataInEdit.snap_file_manager) {
                const fileId = formDataInEdit.snap_file_manager?.file_id || formDataInEdit.snap_file_manager;
                formDataPayload.append('snap_file_manager', fileId);
            }
        }

        const headers = {
            'Content-Type': 'multipart/form-data'
          };
        
        try {
          const response = await EmployeeService.updateProffOfLabsAndVaccine(activeEditId, formDataPayload,headers);
          
          if (response.code === 500) {
            this.props.Toast.error(isSet(response.data, "Something went wrong.."));
          } else if (response.status === "ERROR" && response.data) {
            if (isObject(response.data)) {
                let validaitons={};
                for (let key in response.data) {
                 validaitons={...validaitons,[key]:response.data[key][0]};
                }
                  this.setState((prevState) => ({
                  formDataInEdit: {
                    ...prevState.formDataInEdit,
                    validation: validaitons,
                  },
                }));
              }
            this.setLoaded(false);
          } else {
            this.props.Toast.success("Employee/patient updated successfully");
            this.editHandler(null, {});
          }
          
          // console.log('data',data);
        } catch (e) {this.setLoaded(false);}
        this.setLoaded(false);
        this.props.fetchEmployeeDetails(this.props.employeeId);
      };
}

export default WithRoutify(Labs);
