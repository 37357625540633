import React from "react";

const ProfIcon = (props) => {

    return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="profile" width={props.size} height={props.size}>
      <circle cx="16" cy="8.5" r="5.5" fill="#5f7cf9"></circle>
      <path fill="#8c9eff" d="M16 15a9 9 0 0 0-9 9v4a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-4a9 9 0 0 0-9-9Z"></path>
      <path fill="#4062ed" d="M16 3v11a5.5 5.5 0 0 0 0-11Z"></path>
      <path fill="#5f7cf9" d="M16 15v14h8a1 1 0 0 0 1-1v-4a9 9 0 0 0-9-9Z"></path>
    </svg>
    </>
    );

};
ProfIcon.defaultProps ={
    size:20
}
export default ProfIcon;