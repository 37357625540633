import React, { useEffect, useState } from "react";
import { TagsInput } from "./TagInput";
import { max } from "moment";

const Tags = (props) => {
  const [selected, setSelected] = useState([]);
  useEffect(()=>{
    if(props.value){
        const newSelected = props.value.split(';').filter(value => value.trim() !== '');
        setSelected(newSelected);
    }
  },[props.value]);
  const changeHandler = (tags) => {
    // Join the array of tags into a string separated by semicolons
    const newValue = tags.join(';');
    // Call the parent component's onChange handler with the new value
    props.changeHandler(newValue,{});
    // Update the local state with the new array of tags
    setSelected(tags);
  };

  return (
    <div>
      <TagsInput
        value={selected}
        tagInputType={props.tagInputType}
        onChange={changeHandler}
        inputProps={{ className: "form-control form-control-solid" }} 
        placeHolder={props['placeholder']}
        isNumber={props['isNumber']}
        maxSize={props['maxSize']}
      />
    </div>
  );
};
Tags.defaultProps = {
  isNumber:false,
  maxSize:100,
}

export default Tags;