import React, { useEffect, useRef,useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
// import { isSet } from '../../../utils/commonUtils';
// import './Signature.css';

const SignaturePad = (props) => {
    const signatureCanvasRef = useRef(null);
    const parentRef = useRef(null);
    const [parentWidth, setParentWidth] = useState(200);
    const { value,disabled,readOnly,required } = props;

    useEffect(() => {
        if (parentRef.current) {
            let width = 200;
            if(parentRef.current.clientWidth>0){
                width  = parentRef.current.clientWidth;
            }
          setParentWidth(width);
        }
      }, []);
    
    useEffect(() => {
        if (value) {
            const image = new Image();
            image.src = value;
            image.onload = () => {
                if(signatureCanvasRef.current){
                    const canvas = signatureCanvasRef.current.getCanvas();
                    const context = canvas.getContext('2d');
                    context.drawImage(image, 0, 0);
                }
               
            };
        }
    }, [value]);

    const clearSignature = () => {
        signatureCanvasRef.current.clear();
        props.changeHandler('');
    };

    const saveSignature = () => {
        const signatureImage = signatureCanvasRef.current.toDataURL();
        // console.log('signatureImage',signatureImage);
        props.changeHandler(signatureImage);
    };

    return (
        <div className='input-group1'>
            <div style={{ border: '1px solid #ddd', height: '70px',width:'100%',padding:'1px' , borderLeft: required ? '2px solid red' : '' }} className='form-control' ref={parentRef}>
                {(disabled===true || readOnly==true)?
                 <img src={value} />
                 :
                 <>
                <SignatureCanvas
                    ref={signatureCanvasRef}
                    penColor='black'
                    minWidth={0.5} 
                    disabled={disabled}
                    maxWidth={1.5}// Set the minimum width for thinner lines
                    onEnd={saveSignature}
                    canvasProps={{ width: parentWidth, height: 70, className: 'signature-canvas ' }}
                />
                </>
                }
            </div>
            {(disabled===true || readOnly==true)?<></>:
             <button onClick={clearSignature} className='text-xs btn btn-xs btn-outline-secondary mt-1'>Clear</button>}
        </div>
    );
};

SignaturePad.defaultProps = {
    disabled:false,
    readOnly:false,
    required:false
}
export default SignaturePad;
