import React from 'react';
import {
    WEEKDAYS_SHORT
} from '../../constants';
import { isSet } from '../../../../utils/commonUtils';

const MonthViewHeader  = ({currentWeek,todayDate,calendarRecords})=>{
    return(
        <thead className="fc-head">
             <tr>
                <td className="fc-head-container fc-widget-header">
                   <div className="fc-row fc-widget-header" style={{marginRight:'3px'}}>
                      <table className="">
                         <thead>
                            <tr>
                               <th className="fc-axis fc-widget-header" style={{width: '47.7188px'}}></th>
                               {currentWeek.map((item,itemIndex)=>{
                                 let classArr=[];
                                 if(new Date(todayDate).setHours(0, 0, 0, 0)===new Date(item.date).setHours(0, 0, 0, 0)){
                                    classArr.push('fc-day-today');
                                }
                               
                                const count = isSet(calendarRecords?.[item.c_date], []).length;
                                return(
                                    <th key={itemIndex} className={`fc-day-header fc-widget-header  fc-past fc-${WEEKDAYS_SHORT[item.day]} ${classArr.join(' ')}`} data-date={item.date}>

                                      
                                       <div className="row">
                                       <div className="col d-flex justify-content-between align-items-center">
                                          <div></div>
                                          <div>
                                             <p className="text-md mb-0">{item.displayWeekday}</p>
                                             <p className="text-md mb-0">{item.displayDayAndMonth}</p>
                                          </div>
                                          {count > 0 ?
                                             <span className="badge badge-primary badge-count ml-2">{count}</span>
                                          :<div></div>}
                                          </div>
                                       </div>
                                     

                                    </th>
                                )
                              })}
                           </tr>
                        </thead>
                     </table>
                  </div>
               </td>
            </tr>
         </thead>
    )
}

export default MonthViewHeader;