import React from "react";
import {
    WEEKDAYS_SHORT
} from '../../../../constants';
import { isSet } from "../../../../../../utils/commonUtils";
import { calendarWidgetVisitTypeBasedColor } from "../../../../calendarFunctions";

const MonthDayContent = (props)=>{
    const {listItem,keyIndex,showMoreDetailsDataHandler,todayDate} = props; 
    return(
        <div className="fc-content-skeleton" key={keyIndex}>
            <table>
                <MonthDayContentHeader todayDate={todayDate} listItem={listItem}  addVisitToogleHandler={props.addVisitToogleHandler}/>                               
                <tbody>
                    <tr>
                        {
                            
                            listItem.map((dateItem,itemIndex)=>{
                                let getDateRecords = []; 
                                try{
                                    getDateRecords = isSet(props.calendarRecords[dateItem.c_date],[]);
                                }catch(e){
                                    // console.log('getDateRecords',e);
                                }
                                // let incCount = 0;
                                return(
                                    <td onClick={()=>props.addVisitToogleHandler({visit_date:dateItem.c_date})} key={itemIndex}>
                                        {
                                            isSet(getDateRecords,[]).map((item,ind)=>{                                             
                                                
                                                if(ind>2){
                                                    return false;
                                                }  
                                                const typeBasedClass = calendarWidgetVisitTypeBasedColor(item.visit_category);
                                                return(
                                                    <>
                                                        <a key={ind} title={`${item.visit_time} : ${item.employee.first_name} ${item.employee.last_name}`} onClick={(e)=>{e.stopPropagation(); props.showQuickVisitView(e,item)}}>
                                                        <div className={`mainValueTag ${typeBasedClass}`} data-toggle="dropdown">
                                                            
                                                                        {/*<>
                                                                            <span className="text-bold" style={{'color':'#000000'}}>{item.employee.first_name} {item.employee.last_name}</span>:
                                                                            <span className="text-success">{item.visit_time}</span>, 
                                                                        </>*/}

                                                            <>
                                                                <span className="text-sm font-weight-bold">{item.visit_time}</span>
                                                                <span className="text-xs font-weight-bold"> : </span>  
                                                                
                                                                <span className="text-sm">{item.employee.first_name} {item.employee.last_name}</span>
                                                            </>  
                                                                                                                                                                       
                                                        </div> 
                                                        </a>
                                                        {
                                                         (ind===2 && getDateRecords.length>3)?
                                                         <a className="text-danger textUnderline text-small quick-sidebar-toggler cursor-pointer" onClick={(e)=>showMoreDetailsDataHandler(e,{date:dateItem.c_date,time:''},isSet(getDateRecords,[]))}> + More ... </a>
                                                         :
                                                         <></>   
                                                        }                                                   
                                                    </>
                                                )                                                
                                            })}
                                    </td>
                                )
                                                     
                            })}
                                                               
                    </tr> 
                </tbody>
            </table>
        </div>
    )
}
const MonthDayContentHeader = ({listItem,addVisitToogleHandler,todayDate})=>{
    return(
        <thead>
            <tr>
                {listItem.map((item,itemIndex)=>{ 
                    let classArr = ["fc-day-top","fc-"+WEEKDAYS_SHORT[itemIndex]+"","fc-past"];
                    if(item.currentMonth===false){
                        classArr = ["fc-day-top","fc-"+WEEKDAYS_SHORT[itemIndex]+"","fc-other-month","fc-past"];                        
                    }
                    if(new Date(todayDate).setHours(0, 0, 0, 0)===new Date(item.date).setHours(0, 0, 0, 0)){
                        classArr.push('fc-day-today');
                    }
                        return(
                            <td className={classArr.join(' ')} key={itemIndex} data-date={item.date} onClick={()=>addVisitToogleHandler({visit_date:item.c_date})}>
                                <span className="fc-day-number">{item.number}</span>
                            </td>
                        )
                })}
            </tr>
        </thead>  
    )
}

MonthDayContent.defaultProps = {
    isShowActivities:false,
}
export default MonthDayContent;