import React from 'react';
const OrgIcon = (props) => {
  // console.log('compannie icon size',props.size);
    return (
      <>
        
        <svg width={props.size} height={props.size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"  id="organization"> <defs><linearGradient id="a" x1="0.773" y1="1.25" x2="0.227" y2="-0.25" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#6114f8"></stop><stop offset="0.167" stop-color="#6e23f9"></stop><stop offset="1" stop-color="#00b6ff"></stop></linearGradient></defs> <path fill="#0bbbf9" d="M20.792,14.5H14.5V9h-1v5.5H7.208A2.711,2.711,0,0,0,4.5,17.208V21h1V17.208A1.71,1.71,0,0,1,7.208,15.5H13.5V21h1V15.5h6.292A1.71,1.71,0,0,1,22.5,17.208V21h1V17.208A2.711,2.711,0,0,0,20.792,14.5Z" fill="url(#a)"></path><circle cx="5" cy="23" r="3" fill="url(#a)"></circle><circle cx="14" cy="23" r="3" fill="url(#a)"></circle><circle cx="23" cy="23" r="3" fill="url(#a)"></circle><circle cx="14" cy="6" r="4" fill="url(#a)"></circle> </svg>
      </>
    );
  };
  OrgIcon.defaultProps ={
    size:28
}

export default OrgIcon;
  