import React, { Component } from "react";
import TableGrid from "../../components/UI/TableGrid";
import { ToastContext } from "../../context/ToaxtContext";
import ReactDOM from "react-dom";

import {
  encryptString,
  formatPhoneNumberUS,
  isSet,
} from "../../utils/commonUtils";
import Buttons from "../../components/UI/Buttons";
import WithRoutify from "../../hoc/WithRoutify";
import EmployeeService from "../../services/EmployeeService";
import withReduxState from "../../hoc/wReduxState";
import AddEmployee from "./AddEmployee";
import { genderOptions } from "../../utils/constant";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeFilter from "./EmployeeFilter";
import AddDependents from "./AddDependents";
import FormInputs from "../../components/UI/FormInputs";
import PERMISSIONS from "../../utils/AclPermissions";
import { acl_check } from "../../utils/aclUtils";
import SendInviteLinkModal from "../../components/Modal/sendInviteLinkModal";
import ScanbarCodePopup from "./ScanbarCodePopup";


class EmployeeList extends Component {
  static contextType = ToastContext;
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
     
      {
        label: "First Name",
        key: "first_name",
        type: "",
        inputType: "TextInput",
      },
      {
        label: "Last Name",
        key: "last_name",
        type: "",
        inputType: "TextInput",
      },
      { label: "DOB", key: "dob", type: "", inputType: "Date" },
      {
        label: "Gender",
        key: "gender",
        type: "",
        inputType: "SelectList",
        options: genderOptions,
      },
      { label: "Cell Number", key: "phone", inputType: "Phone" },
      { label: "Company", key: "company" },
      { label: "Status", key: "status" },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    this.state = {
      addEmployeeAutoFillData:{},
      filters: {
        first_name: "",
        last_name: "",
        gender: "",
        company_id: "",
        dob: "",
        patient_id: "",
        phone: "",
        patient_id: "",
        identification_type: "",
        identification_number: "",
        clinic_id: "",
        booking_id: "",
        pre_registration_code: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
        },
        pagination:{
          page:1,
          perPage:25,
          totalRecords:0,
          sort_by:'',
          sort_order:'',
        },
      },
      
      formDataInEdit: {},
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      detailViewId: null,
      isDetailViewOpen: false,
      isDependentModelOpen:false,
      inviteModalOpen:false,
      scanBarcodeModalOpen:false,
      filterOpen: false,
      isActiveDataLoaded:true,
      company_id:null,
      clinic_id:null,
    };
  }

  componentDidMount() {
    this.fetchEmployeeList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.globalFilter.Chart!==this.props?.globalFilter?.Chart) {
      this.fetchEmployeeList();
    }
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
    document.body.classList.remove('filterOpened')
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addEmployeeToogleHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen,addEmployeeAutoFillData:{} });
  };

  render() {
    const {
      tableRecords,
      filters,
      isModelOpen,
      isDetailViewOpen,
      detailViewId,
      // isActiveDataLoaded,
      isDependentModelOpen,
      addEmployeeAutoFillData
      // company,
      // clinic_id
    } = this.state;
    // console.log('addEmployeeAutoFillData',addEmployeeAutoFillData);
    return (
      <>
        {isModelOpen ? (
          <AddEmployee
            modelTitle={"Add Employee"}
            fetchEmployeeList={this.fetchEmployeeList}
            isOpen={this.state.isModelOpen}
            autoEmployeeFillData={addEmployeeAutoFillData}
            modalToogleHandler={this.addEmployeeToogleHandler}
            companiesOptions={this.state.companiesOptions}
          />
        ) : isDetailViewOpen ? (
          <EmployeeDetails
            modalToogleHandler={this.detailViewModal}
            fetchEmployeeList={this.fetchEmployeeList}
            employeeId={detailViewId}
            company_id={this.state.company_id}
            clinic_id={this.state.clinic_id}
          />
        ) : (
          <></>
        )}
        {
          isDependentModelOpen?
          <AddDependents
          employeeId={detailViewId}
          fetchEmployeeList={this.fetchEmployeeList}
          addFormHandler={this.addDependentToogle}
          />
          :
          ''
        }
        {
          this.state.inviteModalOpen?
          <SendInviteLinkModal
          modleToogleHandler={this.inviteToogleHandler}
          />
          :
          ''
        }
         {
          this.state.scanBarcodeModalOpen &&
          <ScanbarCodePopup
            modleToogleHandler={this.scanBarcodeHandler}
          />
        }
     
          <div className="sh_cardBox">
            <div className="sh_cardHeader p-1">
              <div className="form-group d-flex mb-0 app-heading-tab justify-content-between align-items-center app-heading-md">
                {/* <h6 class="text-lg f-500 mb-0"> Employee/Patient List </h6> */}
                <div className="app-header-tab">
                  <div className="tabs_wrapForTable pr-2">
                    <ul
                      class="nav nav-borders nav-tabs border-0"
                      role="tablist"
                    >
                      <li>
                        <a
                          class={`flex-sm-fill text-sm-center nav-link ${
                            this.state.isActiveDataLoaded ? "active" : ""
                          }`}
                          aria-current="page"
                          onClick={() => this.activeFilter(true)}
                          href="#"
                        >
                          Active Employee
                        </a>
                      </li>
                      <li>
                        <a
                          class={`flex-sm-fill text-sm-center nav-link  ${
                            this.state.isActiveDataLoaded ? "" : "active"
                          } `}
                          href="#"
                          onClick={() => this.activeFilter(false)}
                        >
                          In-Active Employee
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="d-flex ml-3 mt-1 filteropen_header">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      {/* <div className="input-group input-group-joined input-group-solid"></div> */}
                      <div className="d-flex sh_filter_formheader">
                      <div className="d-flex p-1">
                        <div className="btn-group pl-2">
                       {/* <div><Buttons
                          clickHandler={this.filterOnClick}
                          className="btn btn-outline-secondary mr-2"
                          acl={"any"}
                          label={"Search by "}
                          iconType={"Filter"}
                        ></Buttons></div>  */}
                      </div>
                       {/* <div>
                          <FormInputs
                            placeholder="Employee"
                            value={filters["search_query"]}
                            changeHandler={(val) =>
                              this.onChangeFiltersHandler("search_query", val)
                            }
                            className="form-control"
                          />
                        </div> */}
                        </div>
                        <div className="d-flex">                           
                      <div className="addAction mr-2">
                      {/* <div className="btn-group p-1">
                            <button
                              className="btn btn-primary"
                              onClick={() => this.submitFiltersHandler()}
                            >
                              Search
                            </button>
                            <button
                              className="btn btn-outline-secondary"
                              onClick={() => this.clearFiltersHandler()}
                            >
                              Clear
                            </button>
                          </div> */}
                        <div className="btn-group pl-2">
                          <Buttons
                            clickHandler={this.addEmployeeToogleHandler}
                            className="btn btn-primary"
                            // appendAddIcon={true}
                            acl={"employee-create"}
                            label={"Add Patient"}
                          />
                        
                      </div>
                      <div className="btn-group pl-1">
                      <Buttons
                            clickHandler={this.inviteToogleHandler}
                            className="btn btn-primary"
                            // appendAddIcon={true}
                            acl={"employee-create"}
                            label={"Invite Patient"}
                          />
                          </div>
                          <div className="btn-group">
                          <Buttons
                            clickHandler={this.scanBarcodeHandler}
                            className="btn btn-primary ml-1"
                            acl={"employee-create"}
                            label={"Scan barcode"}
                          />
                          </div>
                          </div>

                      </div>
                      </div>
                    </div>
                  </div>
                  
                  
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) => this.fetchEmployeeList(filters)}
                {...tableRecords}
              />
            </div>
          </div>
        {/* </TableSkeleton> */}

        {this.state.filterOpen ? (
          <>
            {ReactDOM.createPortal(
              <EmployeeFilter
                onChangeHandler={this.onChangeFiltersHandler}
                filterData={this.state.filters}
                closeHandler={this.filterCloseHandler}
                submitHandler={this.submitFiltersHandler}
                companiesOptions={this.state.companiesOptions}
              />,
              document.getElementById("sh_mainportal_view") // Render the component into the portal container
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters } = this.state;
    let filter = {};
    this.state.isActiveDataLoaded ? (filter = { active: true }) : (filter = { inactive: true });
  
    await this.fetchEmployeeList({ ...filters, ...filter });
  };
  
  clearFiltersHandler = ()=>{
    this.setState({
      filters: {
        ...this.state.filters,
        search_query:'',    
      },
    },this.submitFiltersHandler);
  };
  
  fetchEmployeeList = async (query = '') => {
    try {
      if (query=='') {
        this.state.isActiveDataLoaded?query={active:true}:query={inactive:true};
      }
      const globalFilter=this.props?.globalFilter?.Chart || {};
      query={...query,...globalFilter}
      this.props.Loader(true);
      const EmployeeList = await EmployeeService.getEmployeeList(query);
      let tableData = [];
      EmployeeList.data.data.forEach((employee, employeeIndex) => {
        const patientParams = encryptString({
          patientName:(employee.first_name+' '+employee.last_name+' '+employee.dob),
          company_id:employee.company_id,
          company_assigned:employee.company_assigned,
          employee_id:employee.employee_id,
        });
        tableData = [
          ...tableData,
          {
            employeeIndex: 0,
            isHeading: false,
            rowId: employee.employee_id,
            data: {
              isActive: false,
              first_name: employee.first_name,
              last_name: employee.last_name,
              dob: employee.dob,
              gender: employee.gender,
              phone:formatPhoneNumberUS(employee.phone) || "--",
              company: isSet(employee.company) ? employee.company.name : '-- Company Need to Assigned --',
              created_at: employee.created_at,
              updated_at: employee.updated_at,
              status: employee.status?"Active":'InActive',
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Search",
                  label: "",
                  acl: "employee-edit",
                  title: "Detail view",
                  clickHandler: () =>
                    this.detailViewModal(employee.employee_id,employee.company_id,employee.clinic_id),
                },
                // {
                //   className: "",
                //   iconType: "UserGroup",
                //   label: "",
                //   acl: "employee-edit",
                //   title: "Dependents",
                //   clickHandler: () =>
                //     this.addDependentToogle(employee.employee_id),
                // },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "PatientVisit",
                  iconSize: 15,
                  label: "",
                  acl: PERMISSIONS.VISITS.LIST,
                  title: "Visits History",
                  href: acl_check(this.props.profile.permissions,PERMISSIONS.VISITS.LIST)?`/employee/visit-history/${employee.employee_id}/${patientParams}`:''
                },
                // {
                //   className: "btn btn-datatable btn-icon btn-transparent-dark",
                //   iconType: "Edit",
                //   title: "Edit",
                //   type: "GridEdit",
                //   acl: "employee-edit",
                //   clickHandler: (rowId, data) => this.editHandler(rowId, data),
                //   updateHandler: () => this.updateHandler(employee.employee_id),
                //   onChangeFormDataInEdit: (key, val) =>
                //     this.onChangeFormDataInEdit(key, val),
                // },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: "employee-delete",
                  title: "Delete",
                  isDelete:true,
                  clickHandler: () => this.deleteHandler(employee.employee_id),
                },
              ],
            },
          },
        ];
      });
      const updatePagination = (prevStatePagination, newPagination) => ({
        page: newPagination.page || prevStatePagination.page,
        perPage: newPagination.perPage || prevStatePagination.perPage,
        totalRecords: EmployeeList.pagination.totalCount,
        sort_by: newPagination.sort_by || prevStatePagination.sort_by,
        sort_order: newPagination.sort_order || prevStatePagination.sort_order,
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...updatePagination(prevState.tableRecords.pagination, query),
          },
        },
      }));
      this.props.Loader();
    } catch (e) {
      this.props.Loader();
    }
  };
  detailViewModal = (empId,companyId="",clinicId="") => {
    this.setState((oldState) => ({
      isDetailViewOpen: !oldState.isDetailViewOpen,
      detailViewId: empId,
      company_id:companyId,
      clinic_id:clinicId
    }));
  };
  deleteHandler = async (employeeId) => {
      try {
        let respons=await EmployeeService.deleteEmployee(employeeId);
        if(isSet(respons)&&respons.status=="ERROR" &&respons.data){
          this.props.Toast.error(respons.data);
        }else{
            this.props.Toast.success('Employee Deleted Successfully');
            this.fetchEmployeeList();
        }
      } catch (e) {}
  };
  editHandler = (rowId, data) => {
    // console.log("rowId",rowId,"data",data);
    if (rowId == null) {
      this.setState({ activeEditId: "", formDataInEdit: {} });
      return false;
    }
    this.setState({
      activeEditId: rowId,
      formDataInEdit: data,
    });
  };
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  addDependentToogle=(employee_id='')=>{
    this.setState({isDependentModelOpen:!this.state.isDependentModelOpen,detailViewId:employee_id});
  }
  inviteToogleHandler=()=>{
    this.setState({inviteModalOpen:!this.state.inviteModalOpen});
  }
  scanBarcodeHandler=(obj={},isAddEmployee=false)=>{
    this.setState({
      scanBarcodeModalOpen:!this.state.scanBarcodeModalOpen,
      addEmployeeAutoFillData:obj,
      isModelOpen:isAddEmployee
    });
  }

  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  filterOnClick = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    },()=>{
      if (document.body.classList.contains('filterOpened')) {
        document.body.classList.remove('filterOpened');
        // menuToggleElement.removeClass('active');
      } else {
        document.body.classList.add('filterOpened');
      }
    });
  };
  filterCloseHandler = (isClear=true) => {
    if (isClear) {
      this.setState({
        filters: {
          ...this.state.filters,
          first_name: "",
          last_name: "",
          gender: "",
          company_id: "",
          dob: "",
          patient_id: "",
          phone: "",
          patient_id: "",
          identification_type: "",
          identification_number: "",
          clinic_id: "",
          booking_id: "",
          pre_registration_code: "",
        },
      },this.submitFiltersHandler);
    }
    else{
      this.setState({
        filterOpen: false,
      },()=>{
        if (document.body.classList.contains('filterOpened')) {
          document.body.classList.remove('filterOpened');
          // menuToggleElement.removeClass('active');
        } else {
          document.body.classList.add('filterOpened');
        }
      });
    }
    
  };
  activeFilter=(status)=>{
    let filters={};
    if (status) {
       filters={
        active:true,
      };
      this.setState({
        isActiveDataLoaded:true
      })
    }
    else{
      filters={
        inactive:true
      }
      this.setState({
        isActiveDataLoaded:false
      })

    }
    this.fetchEmployeeList(filters);
  }
}
export default withReduxState(WithRoutify(EmployeeList));