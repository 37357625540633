import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Employeeervice from "../../services/EmployeeService";
import DropDownService from "../../services/DropDownService";
import FormInputs from "../UI/FormInputs";
import { isSet } from "../../utils/commonUtils";
import HL7Service from "../../services/HL7Service";
import WithRoutify from "../../hoc/WithRoutify";

const Hl7OrderModal = (props) => {
    const { addFormHanlder,id,Toast} = props;
    const [labData,setLabData]=useState({});
    const [configList,setConfigList]=useState({});
    const inputFields=getInputFields();
    const [formData, setFormData] = useState({type:'lab',lab_id:id});
    const [validation, setValidation] = useState({});
    console.log('labData',labData);

    useEffect(()=>{
        fetchLabDetails();
        fetchConfigList();
    },[]);
    const fetchLabDetails=async()=>{
        let response = await Employeeervice.getEmployeeVisitLabById('visit_id', id);
        setLabData(response);
    }
    const fetchConfigList=async()=>{
        let response = await DropDownService.getSftpConfigList();
        let configOptions=[{label:"-Select-",value:''}];
        response.forEach((itm,ind) => {
            configOptions.push({label:itm.name,value:itm.id});
        });
        setConfigList(configOptions);
        console.log('respose',response);
    }
    const changeHandler = (val, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: val,
        });
        setValidation({ ...validation, [fieldName]: '' });
    };
    const formSubmitHandler=async()=>{
        const payload={...formData};
        if (!formData?.config_id) {
            setValidation({'config_id':"SFTP Configuration Field is Required"});
            return false;
        }
        HL7Service.pushHL7LabOrder(payload);
        Toast.success("Order Placed Sucessfully");
        addFormHanlder();

        
    }

    return (
        <>
            <Modal show={true}
                onHide={addFormHanlder}
                dialogClassName=''
                keyboard={false}
                contentClassName='h-100'
                backdrop="static"
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h6 className="text-lg mb-0 font-weight-bold">{"Place Lab Order"}</h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                            {inputFields &&
                                inputFields.map((field, index) => (
                                    <div className="col-12" key={index}>
                                        <div className="form-group">
                                            <label className="mb-0 text-md opacity-75"> {field.label} </label>
                                            <FormInputs {...field} changeHandler={(value, e) => changeHandler(value, field.name)}
                                            value={isSet(field.value,formData[field.name])} 
                                                />
                                            {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="" onClick={() => formSubmitHandler()}>
                            Submit
                        </Button>
                    </Modal.Footer>
            </Modal>
        </>
    );
    function getInputFields(){
        const inputFields = [
            {
                type: "TextInput",
                label: "Lab ID",
                className: "form-control form-control-solid",
                name: "random_lab_id",
                value:labData.random_lab_id,
                readOnly:true,
            },

            {
                type: "SelectList",
                label: "SFTP Configuration",
                className: "form-control form-control-solid",
                name: "config_id",
                options: configList,
                required: true
            },
        ];
        return inputFields;
    }
}
export default WithRoutify(Hl7OrderModal);