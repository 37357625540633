import React from 'react';
import { connect } from 'react-redux';
import { isSet } from '../utils/commonUtils';
import AuthService from '../services/AuthService';
import { clearDDStateByKey } from '../store/DropDownSlice';

const withReduxState = (WrappedComponent) => {
 
  const mapStateToProps = (state) => {    
    const profileId = isSet(AuthService.getProfileId(),null);
    return {
      profile: (profileId !== null) ? state.session.profileDetails[profileId] || {} : {},
      reloadKeyProps:state.common.reloadKeyProps,
      globalFilter:state.globalFilter.data,
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    clearDDStateByKey: (key) => dispatch(clearDDStateByKey(key)),
  });
  const ConnectedComponent = connect(mapStateToProps,mapDispatchToProps)(WrappedComponent);

  return (props) => <ConnectedComponent {...props} />;
};

export default withReduxState;