import React, { Component } from "react";
import WithRoutify from "../../../hoc/WithRoutify";
import Buttons from "../../../components/UI/Buttons";
// import TableGrid from "../../../components/UI/TableGrid";
import WithRouter from "../../../hoc/WithRouter";
import VisitsList from "../../EmployeeVisitContainer/VisitsList";
import { decryptString } from "../../../utils/commonUtils";
import OrganizationService from "../../../services/OrganizationService";
import AssignCompany from "../../../components/Modal/AssignCompany";
import PERMISSIONS from "../../../utils/AclPermissions";

class VisitHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Add any default state properties here if needed
      isModelOpen:false,
      assignCompanyModal:false,
      filters: {
        first_name: "",
        last_name: "",
        gender: "",
        company_id: "",
        dob: "",
        patient_id: "",
        phone: "",
        patient_id: "",
        identification_type: "",
        identification_number: "",
        clinic_id: "",
        booking_id: "",
        pre_registration_code: "",
      },
    };
  }

  componentDidMount =()=> {
    const { params } = this.props;
    const patientDetails = decryptString(params.patientDetails);
    if (!patientDetails.company_assigned ) {
      this.setState({assignCompanyModal:true})
    }
  }

  componentDidUpdate(prevProps) {
    // Add any logic you want to execute when props are updated
    // You can compare this.props and prevProps to determine changes
  }

  componentWillUnmount() {
    // Add any cleanup logic when the component is about to unmount
  }
  addVisitToogleHandler = ()=>{
    const { params } = this.props;
    const patientDetails = decryptString(params.patientDetails);
    //patientDetails
    this.setState({
      isModelOpen:!this.state.isModelOpen,
      visitsAutoFillData:{
        company_id:patientDetails.company_id,
        employee_id:params.patientId,
        organization_id:OrganizationService.getOrgId(),
      }
    });
  }
  render() {
    const { params } = this.props;
    const { isModelOpen,visitsAutoFillData,assignCompanyModal} = this.state;
    const patientDetails = decryptString(params.patientDetails);
    return (
      <>
        <div id="home" className="tab-pane active show" role="tabpanel">
          <div className="sh_innerPortview">
            <div className=" p-3">
              <div className="sh_cardBox  shadow-none border">
                <div className="sh_cardHeader">
                  <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                    <h6 className="text-sm mb-0">
                      Visits History -  {patientDetails.patientName}
                    </h6>
                    <div className="addAction d-flex justify-content-between align-items-center">
                      <Buttons
                        title={"Add Physical / Drug Screen"}
                        className="btn btn-icon btn-transparent-dark"
                        acl={PERMISSIONS.VISITS.CREATE}
                        iconType={"AddIconDetailView"}
                        clickHandler={this.addVisitToogleHandler}
                        visitsAutoFillData={visitsAutoFillData}
                      />
                    </div>
                  </div>
                </div>
                {
                  assignCompanyModal ?
                    <AssignCompany
                      employeeId={params.patientId}
                      modleToogleHandler={ this.companyAssignToogleHandler}
                    />
                    : ''
                }
                <VisitsList 
                  isModelOpen={isModelOpen} 
                  isFromVisitHistory={true}
                  visitsAutoFillData={visitsAutoFillData}/>
                </div>
              </div>
          </div>
        </div>
      </>
    );
  }
  companyAssignToogleHandler=()=>{
    this.setState({assignCompanyModal:!this.state.assignCompanyModal});
  }
 
}

export default WithRouter(WithRoutify(VisitHistory));
