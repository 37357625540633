import React from 'react';

const DotRoundedIcon= ({className}) => {
  return (
    <svg class={`svg-inline--fa fa-circle fa-sm ${className} `} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"></path></svg>    
    // <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="close">
    //     <path fill={color} d="M9.016 40.837a1.001 1.001 0 0 0 1.415-.001l14.292-14.309 14.292 14.309a1 1 0 1 0 1.416-1.413L26.153 25.129 40.43 10.836a1 1 0 1 0-1.415-1.413L24.722 23.732 10.43 9.423a1 1 0 1 0-1.415 1.413l14.276 14.293L9.015 39.423a1 1 0 0 0 .001 1.414z"></path>
    // </svg>
  );
};
DotRoundedIcon.defaultProps = {
    className:'text-blue'
//   size: 20,
//   color:'#5f6368'
};
export default DotRoundedIcon