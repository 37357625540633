import React, { useEffect, useState } from "react";
import FormInputs from "../../../../../../components/UI/FormInputs";
import { getLookupByKey, isObject, isSet } from "../../../../../../utils/commonUtils";
import TableGrid from "../../../../../../components/UI/TableGrid";
import WithRoutify from "../../../../../../hoc/WithRoutify";
import SectionLoader from "../../../../../../components/UI/SectionLoader";
import PatientChartsService from "../../../../../../services/PatientChartsService";
import Buttons from "../../../../../../components/UI/Buttons";
import AddLabPanel from "./AddLabPanel";

const LabPanel = (props) => {
    const { Toast, Loader,fetchRecords} = props;
    const parent_id=props.formData.id;
    const [panelList, setPanelList] = useState({ tableHeaders: [], tableRows: { data: [] } });
    const [isLoading, setLoaded] = useState(false);
    const [validation, setValidation] = useState({});
    const [laborderOptions, setLabOrderOptions] = useState([]);
    const [formData, setFormData] = useState([]);
    const [isModalOpen,setModalToogle]  = useState(false);
    useEffect(() => {
        fetchLabOrder();
    }, []);
    useEffect(() => {
        if (parent_id) {
            fetchPanelList();
        }
    }, [parent_id]);
    const fetchLabOrder = async () => {
        // await props.fetchLabOrderList(null, null, (res) => {
        //     res.unshift({label:"-Select-",value:''})
        //     setLabOrderOptions(res);
        // });
        const response=await getLookupByKey('child_laborder');
        setLabOrderOptions(response);
    }
    const fetchPanelList = async () => {
        try {
            setLoaded(true);
            const response=await PatientChartsService.getChildLabOrders(parent_id);
            const tableData = response.map((item) => ({
                rowId: item.id,
                data: {
                    lab_order: item.lab_order,
                    result: item.result,
                    reference_range: item.reference_range,
                    units: item.units,
                    flag: item.flag,
                    lab_message: item.lab_message,
                    action: [
                        {
                            className: "btn btn-icon btn-transparent-dark",
                            iconType: "Edit",
                            title: "View",
                            acl: "company-edit",
                            clickHandler: () => editHandler(item.id, item)
                        },
                        {
                            className: 'btn btn-icon btn-transparent-dark',
                            iconType: 'Remove',
                            acl: 'any',
                            title: 'Delete',
                            isDelete: true,
                            clickHandler: () => deleteHandler(item.id),
                        }]
                }
            }));

            setPanelList({ tableHeaders: getTableHeaders(), tableRows: { data: tableData } });
            setLoaded(false);
        } catch (error) {
            setLoaded(false);
            console.error("Error fetching additional documents:", error);
        }
    }
    const getTableHeaders = () => [
        { label: 'Lab Ordered', key: 'lab_order', type: '' },
        { label: 'Result', key: 'result', type: '' },
        { label: 'Reference Range', key: 'reference_range', type: '' },
        { label: 'Units', key: 'units', type: '' },
        { label: 'Flag', key: 'flag', type: '' },
        { label: 'Lab Message', key: 'lab_message', type: '' },
        { label: 'Action', key: 'action', type: 'Actions' },
        // Add more columns as needed
    ];

    const submiHandler = async () => {
        //submit
        Loader();
        let response;
        let updateValidation = {};
        formData.parent_lab_id=parent_id;
        if (formData.id) {
            response =await PatientChartsService.updateChildLabOrders(formData.id,formData)
        }
        else {
            response =await PatientChartsService.saveChildLabOrders(formData)
           
        }
        Loader(false);
        // console.log('response',response);
        if (response && response.status && response.status == "ERROR") {
            if (isObject(response.data)) {
                for (let key in response.data) {
                    updateValidation = {
                        ...updateValidation,
                        [key]: response.data[key].join(","),
                    };
                }
            } else {
                Toast.error(response.data);
                return false;
            }
            if (Object.keys(updateValidation).length > 0) {
                setValidation(updateValidation);
                return false;
            }
        }
        let message=`Lab Panel ${formData.id?'Updated':'Added'} Successfully`
        Toast.success(message);
        setModalToogle(false);
        fetchPanelList();
        fetchRecords();
        setFormData({});
        setValidation({});
    }
    const deleteHandler = async (id) => {
        const response=await PatientChartsService.deleteChildLabOrders(id);
        fetchPanelList();
        fetchRecords();
        Toast.success(`Lab Panel Deleted Successfully`);
    }
    const editHandler = (id, item) => {
        setValidation({});
        setFormData({ ...item, ['id']: id });
        setModalToogle(true);
    }
    const inputFields = [
        {
            type: "SelectList",
            options: laborderOptions,
            label: 'Lab Order',
            name: 'lab_order',
            placeholder: 'Lab Order',
            required:true,
        }
        ,
        {
            type: 'TextInput',
            label: 'Result',
            name: 'result',
            placeholder: 'Result',
        }
        ,
        {
            type: 'TextInput',
            label: 'Reference Range',
            name: 'reference_range',
            placeholder: 'Reference Range',
        }
        ,
        {
            type: 'TextInput',
            label: 'Units',
            name: 'units',
            placeholder: 'Units',
        },
        {
            type: 'TextInput',
            label: 'Flag',
            name: 'flag',
            placeholder: 'Flag',
        },
        {
            type: 'TextInput',
            label: 'Lab Message',
            name: 'lab_message',
            placeholder: 'Lab Message',
        }
    ];
    const changeHandler = (fieldName, value, e) => {
        setFormData({ ...formData, [fieldName]: value });
        setValidation({ ...validation, [fieldName]: '' });
    }
    // console.log('panelList',panelList);

    return (
        <>
        {isModalOpen===true &&
            <AddLabPanel 
                formData={formData}
                validation={validation}
                changeHandler={changeHandler}
                inputFields={inputFields}
                submiHandler={submiHandler} 
                toogleHandler={()=>setModalToogle(false)} />
        }
        
        <div className="sh_innerPortview">
            <div className="row">
                {/* <div className="col-12 col-lg-4">
                    <div className="sh_cardBox h-auto shadow-none border">
                        <div className="sh_cardBody p-3">
                            {
                                inputFields.map((itm, ind) => {
                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group mb-1">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label className="text-sm text-truncate mb-0 opacity-75">{itm.label}</label></div>
                                                            <div className="col-12">
                                                                <FormInputs
                                                                    changeHandler={(value, e) => changeHandler(itm.name, value, e)}
                                                                    value={formData[itm.name]}
                                                                    {...itm}
                                                                />
                                                                {isSet(validation[itm.name], '') !== '' ? <span className="text-danger m-1">
                                                                    {isSet(validation[itm.name], '')}</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </>
                                    )
                                })
                            }

                        </div>
                        <div className="sh_cardFooter justify-content-center">
                            <button className="btn btn-primary btn-sm text-sm" onClick={submiHandler}> {formData.id ? 'Update' : 'Add'} </button>
                        </div>
                    </div>
                </div> */}
                <div className="col-12 col-lg-12">

                    <div className="sh_cardBox h-auto sh_labpanelht shadow-none border">
                        <div className="sh_cardHeader">
                            <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                                <h6 className="text-md mb-0">Lab Panel List </h6>
                                <div className="addAction d-flex justify-content-between align-items-center">
                                    <Buttons
                                        title={"Add Lab Panel"}
                                        className="btn btn-icon btn-transparent-dark mr-2"
                                        acl={"any"}
                                        iconType={"AddIconDetailView"}
                                        clickHandler={()=>{setFormData({});setModalToogle(true);setValidation({})}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sh_cardBody p-0">
                            <SectionLoader isLoading={isLoading} />
                            <TableGrid {...panelList} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
LabPanel.defaultProps={
    fetchRecords:()=>{}
}

export default WithRoutify(LabPanel);
