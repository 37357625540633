import React from "react";
import TableGrid from "../../../components/UI/TableGrid";

const OtherTab = (props)=>{
    const {patientDetails,selectedTab}=props;
    const tableRecords= { tableHeaders: [], tableRows: { data: [] } };
    return(
        <>
          <div className="w-100 p-3">
            <div className="sh_cardBox h-auto shadow-none border">
            <div className="sh_cardHeader">
                <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                <h6 className="text-sm mb-0">{selectedTab} - {patientDetails.patientName}</h6>
                </div>
            </div>
            <div className="sh_cardBody p-0">
                <TableGrid {...tableRecords} />
            </div>
            </div>
        </div>
        </>
    )
}

export default OtherTab;