import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Calendar.css'; // Import custom CSS for styling
import { WEEKDAYS_SHORT } from '../../components/Calendar/constants';
import moment from 'moment';
import { isSet } from '../../utils/commonUtils';

const Calendar = (props) => {
  const [showCalendar, setShowCalendar] = useState(true); // State to control visibility of the calendar
  const currDate = new Date();

  // Get the current date
  const currentDate = `${currDate.getMonth() + 1}/${currDate.getDate()}/${currDate.getFullYear()}`;

  // State to keep track of the current month and year
  const [currentMonth, setCurrentMonth] = useState(currDate.getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(currDate.getFullYear());
  // State to keep track of the selected date
  const [selectedDate, setSelectedDate] = useState(null);

  // Function to change the month forward with animation
  const nextMonth = () => {
    setShowCalendar(false); // Hide calendar with animation
    setTimeout(() => {
      if (currentMonth === 12) {
        setCurrentMonth(1);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(currentMonth + 1);
      }
      setShowCalendar(true); // Show calendar with animation
    }, 300); // Adjust the delay according to your transition duration
  };

  useEffect(()=>{
    setCurrentMonth(isSet(props.currentDay,currDate).getMonth()+1);
    setCurrentYear(isSet(props.currentDay,currDate).getFullYear());
  },[props.currentDay])

  useEffect(() => {    
    if (props?.currentDay&&selectedDate==null) {
      const dateObj = moment(props.currentDay, 'MM/DD/YYYY').toDate();
      const fullDate = `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
      setSelectedDate(fullDate);
    }
  }, [props.currentDay]);
  

  // Function to change the month backward with animation
  const prevMonth = () => {
    setShowCalendar(false); // Hide calendar with animation
    setTimeout(() => {
      if (currentMonth === 1) {
        setCurrentMonth(12);
        setCurrentYear(currentYear - 1);
      } else {
        setCurrentMonth(currentMonth - 1);
      }
      setShowCalendar(true); // Show calendar with animation
    }, 300); // Adjust the delay according to your transition duration
  };

  // Function to get the number of days in the current month
  const getDaysInMonth = () => {
    return new Date(currentYear, currentMonth, 0).getDate();
  };

  // Function to get the starting day of the month
  const getStartingDayOfMonth = () => {
    return new Date(currentYear, currentMonth - 1, 1).getDay();
  };

  const daysInMonth = getDaysInMonth();
  const startingDay = getStartingDayOfMonth();

  // Create an array of date numbers for the current month
  const dates = Array.from({ length: daysInMonth }, (_, index) => index + 1);

  // Function to handle date click
  const handleDateClick = (date, viewType) => {
    const dateStr = moment(date, 'MM/DD/YYYY').toDate();
    
    const { calendarViewType } = props;
    props.setCurrentDate(dateStr, (viewType !== '') ? viewType : calendarViewType);
    setSelectedDate(date);
  };

  return (
    <div className={`sh_cardBox h-auto sh_widgets`}>
      <div className="sh_cardHeader border-0">
      <div className="form-group d-flex mb-0 justify-content-between align-items-center">
       <div className="d-flex align-items-center">
            <div className="ml-auto fc_arrow"> {/* Utilizing 'ml-auto' class to push the content to the right */}
              <NextAndPrevToolBar type='PREV' changeHandler={prevMonth} />
            </div>
          </div>
          <h6 className="text-md font-weight-bold mb-0">
            {new Date(currentYear, currentMonth - 1).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
          </h6>
          <div className="d-flex align-items-center">
            <div className="ml-auto fc_arrow"> {/* Utilizing 'ml-auto' class to push the content to the right */}
              <NextAndPrevToolBar type='NEXT' changeHandler={nextMonth} />
            </div>
          </div>
        </div>
      </div>
      <div className="sh_cardBody">
        <div className="calendar-container">
          <CSSTransition
            in={showCalendar}
            timeout={300}
            classNames="rotate"
            unmountOnExit
          >
            <div className="calendar-grid">
              <div className="row weekday_row">
                {WEEKDAYS_SHORT.map((days, index) => (
                  <div className="calendar-col text-center" key={index}>{days}</div>
                ))}
              </div>
              {[...Array(Math.ceil((dates.length + startingDay) / 7)).keys()].map((weekIndex) => (
                <div key={weekIndex} className="row">
                  {[...Array(7).keys()].map((dayIndex) => {
                    const dayNumber = weekIndex * 7 + dayIndex + 1 - startingDay;
                    const isCurrentMonth = dayNumber > 0 && dayNumber <= daysInMonth;
                    const fullDate = `${currentMonth}/${dayNumber}/${currentYear}`;
                    return (
                      <div
                        key={dayIndex}
                        className={`calendar-col cursor-pointer text-center ${isCurrentMonth ? '' : 'text-muted'}`}
                        onClick={() => handleDateClick(fullDate, fullDate === selectedDate ? 'DAY' : '')}
                        title={isCurrentMonth ? fullDate : ''}
                      >
                        <div className={` ${currentDate === fullDate ? 'current-date' : ''} ${fullDate === selectedDate ? 'selected-date' : ''}`}>{isCurrentMonth ? dayNumber : ''}</div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

export default Calendar;

const NextAndPrevToolBar = ({ changeHandler, type }) => {
  const icon = (type === 'PREV') ? 'left' : 'right';
  let classArr = ["cursor-pointer", "fa-lg", "text-primary", "pl-3", "fa fa-chevron-" + icon];
  return (
    <span className={classArr.join(' ')} onClick={() => changeHandler(type)}></span>
  );
};
