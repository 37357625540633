import React, { useEffect, useState } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/css';
import 'swiper/css/navigation';

const TimelineTab = (props) => {
    const data=props.timelineData;
    const [steps, setSteps] = useState(data);

    useEffect(() => {
        setSteps(data);
    }, [data]);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container', {
            slidesPerView: steps.length == 1 ? 1 : 2,
            // spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        return () => {
            swiper.destroy(true, true);
        };
    }, [steps]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="swiper-container">
                        <div className="swiper-wrapper timeline">
                            {steps.map((step, index) => (
                                <div className="swiper-slide" key={index}>
                                    <div className="timestamp">
                                        <span className="text-lg font-weight-bold">{step.date}</span>
                                        <span className='text-md mb-0'>{step.time}</span>
                                    </div>
                                    <div className="status">
                                        <div className="icon"><i className={getIconByStatus(step.status)}></i></div>
                                        <span className="text-lg font-weight-bold">{step.status}</span>
                                    </div>
                                   <span className='text-md mb-0'> {step.updated_by}</span>
                                </div>
                            ))}
                        </div>
                        <div className="swiper-button-prev text-primary"></div>
                        <div className="swiper-button-next text-primary"></div>
                    </div>
                </div>
            </div>
        </div>
    );

    function getIconByStatus(status) {
        if (status) {
            switch (status) {
                case "New":
                    return "fa fa-plus-circle";
                case "Waiting Room":
                    return "fa fa-info-circle";
                case "Ready for Discharge":
                    return "fa fa-check-circle";
                case "Checked In":
                    return "fa fa-sign-in-alt";
                case "No Show":
                    return "fa fa-times-circle";
                case "Scheduled":
                    return "fa fa-calendar-alt";
                case "Pre-Registered":
                    return "fa fa-file-alt";
                case "Discharge":
                    return "fa fa-sign-out-alt";
                case "Cancelled":
                    return "fa fa-ban";
                case "Self-Registered":
                    return "fa fa-user-plus";
                case "Active":
                    return "fa fa-user-check";
                default:
                    return "fa fa-question-circle"; // Default icon for unknown status
            }
        }
        return "fa fa-question-circle"; // Default icon for undefined status
    }
};

export default TimelineTab;
