// src/services/userService.js
import { OK } from '../utils/constant';
import http from './http';
// import { useDispatch } from 'react-redux';
// import {setProfileDetails} from '../store/ProfileSlice';


const UserService = {
  //GET PROFILE
  getUserProfile: async () => {
      try {
        
        const response = await http.GET(`user_profile`);
          const resData = response;
          if(resData.code===200 && resData.data){
            UserService.storeProfile(resData.data);
            return resData.data;
        }
        return null;
      } catch (error) {
        // console.error('Error fetching user:', error);
        // throw error;
      }
    },
    getUserList: async (query={}) => {
      const queryString = new URLSearchParams(query).toString();
      try {
        
        const response = await http.GET(`users/by-role?${queryString}`);
        // console.log('response',response);
          if(response.code===200 && response.data){
            return response.data;
          }
        return null;
      } catch (error) {
        // console.error('Error fetching user:', error);
        throw error;
      }
    },
    getProfile:  () => {
      const storedProfile = localStorage.getItem('userProfile');
        if (storedProfile) {
          // If it exists, parse and return it
          try{
            // console.log('storedProfile',storedProfile);
            return JSON.parse(storedProfile);
          }catch(e){

          }
        }
    },
    //get user by id 
    getUserById: async (id) => {
      try {
        
        const response = await http.GET(`users/${id}`);
          if(response.code===200 && response.data){
            // UserService.storeProfile(resData.data);
            // console.log('resData.data',resData.data);
            return response.data;
        }
        return null;
      } catch (error) {
        // console.error('Error fetching user:', error);
        throw error;
      }
    },
    //Save user
    saveUser:async (data)=>{
      try {
        const response = await http.POST(`users`,data)
        // console.log('response',response);
            if((response.code===200 || response.code===201) && response.data){
              return response.data;
            }else{
              return response;
            }
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        
        // throw error;
      }  
    },

     //Save user
     updateUser:async (userId,data)=>{
      try {
        const response = await http.PUT(`users/${userId}`,data);
          if((response.code===200 || response.code===201) && response.data){
            return response.data;
          }else{
            return response;
          }
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
    updateUserProfile:async (userId,data)=>{
      try {
        const response = await http.PUT(`/update-profile/${userId}`,data);
          if((response.code===200 || response.code===201) && response.data){
            return response.data;
          }else{
            return response;
          }
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
    updateBulkUser:async (body)=>{
      try {
        const response = await http.PUT(`users  `,body);
        if(response.status===200 && response.data){
          const resData = response.data;
          return resData;
        }else{
          return response;
        }
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching updateBulkUser:', error);
        throw error;
      }  
    },
    //Save user
    deleteUser:async (userId)=>{
      try {
        const response = await http.DELETE(`users/${userId}`);
        return response;
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
   
    // STORE PROFILE
    storeProfile: (data) => {
      localStorage.setItem('userProfile',JSON.stringify(data));
    },
    //REMOVE PROFILE
    removeProfile: () => {
      // Remove the token from storage
      localStorage.removeItem('userProfile');
    },
    getUserStateList:async()=>{
      let defaultCountryCode='US';
     let response=await http.GET(`state-list/${defaultCountryCode}`);
     if (response.data && response.code===OK) {
      return response.data
     }
     else{
      return null;
     }
    },
    changePassword:async(data)=>{
      try {
      let response=await http.POST(`update-password`,data);
      return response;
    }
    catch(error){
      //
    }
    },
    
    updateDarkMode:async (userId,data) => {
      const response = await http.PUT(`update-theme/${userId}`,data);
      if(response.code===200 && response.data){
        const resData = response.data;
        return resData;      
      }
    }
    
  };
  
  export default UserService;