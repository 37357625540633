import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { alignProperty } from '@mui/material/styles/cssUtils';

function ProgressBarData({ value: { status, percentage }, max }) {
    let variant;

    if (status === 'success') {
        variant = 'success';
    } else if (status === 'pending') {
        variant = 'warning';
    } else if (status === 'failed') {
        variant = 'danger';
    } else {
        variant = 'info'; // Default variant
    }

    console.log('progressbar', { status, percentage });

    const labelStyle = {
        color: '#000',
        alignProperty:'center'
    };
    const progressBarStyle = {
        // backgroundColor: '#f6f6f6',
        height: '17px',
        width: '100px',
    };

    return (
        <div style={{ position: 'relative', height: '30px', padding:'5px' }}>
            <ProgressBar 
                animated={status === 'pending'}
                max={max}
                variant={variant} 
                now={percentage} 
                label={<span style={labelStyle}>{`${percentage}%`}</span>} 
                style={progressBarStyle}
            />
        </div>
    );
}

ProgressBarData.defaultProps = {
    max: 100
};

ProgressBarData.propTypes = {
    value: PropTypes.shape({
        status: PropTypes.string.isRequired,
        percentage: PropTypes.number.isRequired,
    }).isRequired,
    max: PropTypes.number,
};

export default ProgressBarData;
