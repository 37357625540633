import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Alert = (props) => {
  const {
    onDelete,
    onClose,
    content,
    contentTitle,
    yesButtonName,
    noButtonName,
    type,
    cancelButtonClassName,
    confirmButtonClassName,
    saveAndClose,
    onSaveAndClose
  } = props;
  return (
    <>
      <Modal
        show={true}
        onHide={() => onClose()}
        backdrop="static"
        dialogClassName="sh_modalConfirm"
        keyboard={false}
        className="modal-fade"
      >
        {/* <Modal.Header> */}
          {/* if header needed */}
        {/* </Modal.Header> */}
        
        <div className=" flex-column">
          {/* render icon based on type */}
          {type === 'delete' && (
            <div className="icon-box">
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
          )}
          {type === 'warning' && (
            <div className="icon-box" style={{ border: '3px solid #ffdd1b' }}>
              <i className="fas fa-exclamation-triangle" aria-hidden="true" style={{ color: '#ffdd1b' }}></i>
            </div>
          )}
          {/* title */}
          <h4 className="modal-title w-100">{contentTitle}</h4>
        </div>
        {/* body and content */}
        <Modal.Body>
          <div className="modal-body">
            <p className="mb-0">{content}</p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer justify-content-between">
         {
          saveAndClose?
          <>
          <button type="button" className="btn btn-outline-secondary" onClick={onClose}>Cancel</button>
          <button type="button" className="btn btn-warning" onClick={onDelete}>Confirm</button>
          <button type="button" className="btn btn-primary" onClick={onSaveAndClose}>Save & Close</button>
          </>
          :
          <>
          <button type="button" className="btn btn-outline-secondary" onClick={onClose}>{noButtonName}</button>
          {/* render yes button based on type */}
          {type === 'delete'&&(
            <button type="button" className="btn btn-danger" onClick={onDelete}>{yesButtonName}</button>
          )}
          {type === 'warning'&&(
            <button type="button" className={confirmButtonClassName?confirmButtonClassName:'btn btn-warning'} onClick={onDelete}>{yesButtonName}</button>
          )}
          </>
         }
          {/* */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

Alert.defaultProps = {
  contentTitle: 'Are you sure?',
  content: 'Do you really want to delete this record?',
  type: 'delete',
  noButtonName: 'Cancel',
  yesButtonName: 'Confirm',
  confirmButtonClassName:'',
  cancelButtonClassName:'',
  onClose: () => {},
  onDelete: () => {},
  saveAndClose:false,
  onSaveAndClose:()=>{}

};

Alert.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func
};

export default Alert;
