import React from "react";
import { isSet } from "../../../../../../utils/commonUtils";

const LabelField = ({col,label,fontBold,isBold,appendClass,border})=>{
    const column = isSet(col,'col-12');
    const fontWeight = (isBold===true)?'font-weight-bold':'';
    const borderCls = (border===true)?'border border-left-0':'';
    return(
        <div className={`${column} ${borderCls}  py-1 ${appendClass}`} >
        <label className={`${fontWeight} f-${fontBold}  mb-0 opacity-75`} title={label}>  {label}  </label>
        </div>
    )
}
LabelField.defaultProps = {
    col:'col-12',
    appendClass:'',
    fontBold:'400',
    isBold:true,
    border:true
}
export default LabelField;