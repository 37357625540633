import { OK } from '../utils/constant';
import http from './http';
import OrganizationService from './OrganizationService';


const ActivityConsentDeclationService = {
  updateActivitiesConsent: async (activityId, data) => {
    try {
      let orgId = OrganizationService.getOrgId();
      const response = await http.PUT(`activity/consents/${activityId}?organization_id=${orgId}`, data);
      // console.log('response',response);
      
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  getActivitiesConsentById: async (activityId) => {
    try {
      const response = await http.GET(`activity/consents/${activityId}`);
      
        if (response.code === OK) {
          return response.data;
        }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  saveActivityConsentDeclination: async (data) => {
    try {
      let orgId = OrganizationService.getOrgId();
      const response = await http.POST(`activity/declinations?organization_id=${orgId}`, data);
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      // throw error;
    }
  },
  getActivitiesConsentDeclinationById: async (id) => {
    try {
      const response = await http.GET(`activity/declinations/${id}`);
      if (response.code === OK) {
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
    }
  },
  deleteActivityConsentDeclinationById: async (id) => {
    try {
      let orgId = OrganizationService.getOrgId();
      const response = await http.DELETE(`activity/declinations/${id}?organization_id=${orgId}`);
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
      throw error;
    }
  },
  upatateActivityConsentDeclinationById: async (id, data) => {
    try {
      let orgId = OrganizationService.getOrgId();
      const response = await http.PUT(`activity/declinations/${id}?organization_id=${orgId}`, data);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
      throw error;
    }
  },
  getConsentActivitiesByActivityId: async (activityId) => {
    try {
      const response = await http.GET(`activity/${activityId}/consents_declinations`);
      
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
};

export default ActivityConsentDeclationService;