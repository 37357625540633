import moment from "moment";
import { MONTHS } from "./constants";

export const  tConv24 =(time24) =>{
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };


  export const everyNminutes = (n,startAt=0)=>{
    var result = [];    
    for(var hours = startAt; hours < 24; hours++){
        for(var minutes = 0; minutes < 60; minutes = minutes+n){
              var h = '';
              var m = '';
              if(hours<10){
                 h = '0' + hours;
              }else{
                  h = hours;
              }
              if(minutes<10){
                 m = '0' + minutes;
              }else{
                  m = minutes;
              }
            result.push(h + ':' + m);
        }
    }
    return result;
};


export const  convertTimeFormat = (time)=> {
    const [hours, minutesAndMeridian] = time.split(':');
    const [minutes, meridian] = minutesAndMeridian.split(' ');
    let roundedHours = parseInt(hours, 10);
    
    // Round to the nearest hour
    // if (parseInt(minutes, 10) >= 30) {
    //   roundedHours += 1;
    // }
  
    // Prepend 0 if single digit
    roundedHours = roundedHours < 10 ? `0${roundedHours}` : roundedHours;
  
    // Set minutes to 00 for 1-hour intervals
    const formattedMinutes = '00';
    return `${roundedHours}:${formattedMinutes} ${meridian}`;
  }
  
  export const   rearrangeDataForWeekView = (jsonData) =>{
    const organizedVisits = {};
    try{
      Object.keys(jsonData).forEach(date => {
        const visitsByDate = jsonData[date];
        visitsByDate.forEach(visit => {
          const time = visit.visit_time;//convertTimeFormat(visit.visit_time);
          if (!organizedVisits[date]) {
            organizedVisits[date] = {};
          }
          if (!organizedVisits[date][time]) {
            organizedVisits[date][time] = [];
          }
          organizedVisits[date][time].push(visit);
        });
      });
    }catch(e){

    }
    
     return organizedVisits;
    // console.log('organizedVisits',organizedVisits);
  }

  export const getDateRangeHandler = (calendarViewType,currentDay) => {
    let calendarParam = {}
    if(calendarViewType === 'WEEK'){
        let firstDayOfWeek = new Date(currentDay.setDate(currentDay.getDate() - currentDay.getDay() )); 
        const lastDayOfWeek = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate()+6);
        let firstDayParam = moment(firstDayOfWeek,'YYYY-MM-DD HH:mm:ss.S',true).format('MM-DD-YYYY');
        let lastDayParam = moment(lastDayOfWeek,'YYYY-MM-DD HH:mm:ss.S',true).format('MM-DD-YYYY');
        calendarParam = {
            from_date:firstDayParam,
            to_date:lastDayParam
        } 
    }else if(calendarViewType === 'DAY'){
        let firstDay = new Date(currentDay.getDate()+' '+MONTHS[currentDay.getMonth()] +' '+currentDay.getFullYear());
        let lastDay = new Date(currentDay.getDate()+' '+MONTHS[currentDay.getMonth()] +' '+currentDay.getFullYear());
        let firstDayParam = moment(firstDay,'YYYY-MM-DD HH:mm:ss.S',true).format('MM-DD-YYYY');
        let lastDayParam = moment(lastDay,'YYYY-MM-DD HH:mm:ss.S',true).format('MM-DD-YYYY');
        calendarParam = {
            from_date:firstDayParam,
            to_date:lastDayParam
        }
    }else if(calendarViewType === 'MONTH'){
        let firstDay = new Date(currentDay.getFullYear(), currentDay.getMonth(), 1);
        let lastDay = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 0);
        let firstDayParam = moment(firstDay,'YYYY-MM-DD HH:mm:ss.S',true).format('MM-DD-YYYY');
        let lastDayParam = moment(lastDay,'YYYY-MM-DD HH:mm:ss.S',true).format('MM-DD-YYYY'); 
        calendarParam = {
            from_date:firstDayParam,
            to_date:lastDayParam
        }
    }
    return calendarParam;  
}


export const calendarWidgetVisitTypeBasedColor = (category,type='GRID')=>{

 
// Convert visitCategory to lowercase
const visitCategory = category.toLowerCase();

// Strings to check for purple color class
const purpleCategories = ['physicals', 'labs', 'vaccines'];

// Color classes
let colorClass = '';

if (visitCategory.includes("employer services") && visitCategory.includes("drug screens")) {
    colorClass = (type==='GRID')?'visit-category-yellow':(type=='COLOR')?'#f4a609':'cal_text-yellow';
} else if (visitCategory.includes("employer services") && purpleCategories.some(vCategory => visitCategory.includes(vCategory))) {
    colorClass = (type==='GRID')?'visit-category-purple':(type=='COLOR')?'#6A1B9A ':'text-purple';
} else if (visitCategory.includes("work comp")) {
    colorClass = (type==='GRID')?'visit-category-blue':(type=='COLOR')?'#1d61f3':'text-blue';
} else if (visitCategory.includes("urgent care")) {
    colorClass = (type==='GRID')?'visit-category-red':(type=='COLOR')?'#e91503':'cal_text-red';
}else{
  colorClass = (type==='GRID')?'visit-category-red':(type=='COLOR')?'#e91503':'text-red';
}
return colorClass;

}