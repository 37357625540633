import React from 'react';

const DashboardIcons = (props) => {
    const { type, width = 20, height = 20 } = props;

    const renderIcon = () => {
        switch (type) {
            case 'DashboardInvoice':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27.577 24">
                        <path id="i" d="M0,12A11.863,11.863,0,0,1,11.7,0,11.855,11.855,0,0,1,23.4,12a12.227,12.227,0,0,1-.471,3.371q-.834,1-1.675,1.991c-.085.093-.164.192-.244.285-.108-.081-.21-.169-.318-.25-.539-.431-1.108-.885-1.675-1.316a1.764,1.764,0,0,0-1.085-.413,1.783,1.783,0,0,0-1.272.629c-.085.093-.153.174-.216.25a1.537,1.537,0,0,0-.125,1.822l.045.07c.137.221.284.454.454.681.619.832,1.255,1.67,1.88,2.5A11.445,11.445,0,0,1,11.7,24,11.855,11.855,0,0,1,0,12Zm7.95,3.568c-.119.39-.222.786-.329,1.181-.142.53-.091.658.4.9a6.981,6.981,0,0,0,1.987.582c.539.087.557.111.562.681,0,.256,0,.518.006.774.005.326.159.518.482.524.375.012.75.012,1.119,0a.436.436,0,0,0,.46-.495c0-.355.017-.71,0-1.065a.555.555,0,0,1,.471-.64,3.754,3.754,0,0,0,1.954-1.281,3.641,3.641,0,0,0-1.147-5.472,18.73,18.73,0,0,0-1.959-.908,4.505,4.505,0,0,1-1.073-.617.968.968,0,0,1,.233-1.705,1.828,1.828,0,0,1,.659-.14,4.807,4.807,0,0,1,2.476.5c.392.192.523.134.653-.285.136-.443.256-.891.38-1.333a.468.468,0,0,0-.3-.623,6.082,6.082,0,0,0-1.561-.477c-.71-.11-.7-.117-.71-.844-.006-1.03-.006-1.03-1.011-1.03-.142,0-.29-.006-.438,0-.471.012-.551.093-.562.582v.652c-.005.646-.005.634-.607.862a3.423,3.423,0,0,0-2.453,3.19,3.28,3.28,0,0,0,1.806,3.126,19.251,19.251,0,0,0,2.255,1.036,3.5,3.5,0,0,1,.823.495,1.165,1.165,0,0,1-.267,2.02,2.681,2.681,0,0,1-1.448.192,6.264,6.264,0,0,1-2.2-.675.812.812,0,0,0-.349-.125C8.116,15.155,8.036,15.284,7.95,15.572Zm12.828,7.038q-1.567-2.078-3.123-4.151c-.159-.215-.3-.448-.443-.675a.44.44,0,0,1,.045-.553,2.305,2.305,0,0,1,.153-.18.761.761,0,0,1,.522-.308.8.8,0,0,1,.46.2c.784.6,1.551,1.223,2.329,1.84.142.117.284.227.437.35.306-.361.6-.71.9-1.06q1.977-2.349,3.952-4.692c.261-.308.432-.46.625-.46a1.124,1.124,0,0,1,.63.344c.38.32.409.46.119.885q-2.862,4.209-5.73,8.411a.573.573,0,0,1-.454.3A.542.542,0,0,1,20.778,22.609Z" fill="#0623c1" />
                    </svg>
                );
            case 'DashboardPhysical':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20.267 24">
                        <path id="Health" d="M9.482,23.688A16.957,16.957,0,0,1,0,8.353V3.287H1.843A12.163,12.163,0,0,0,10.089.041L10.133,0l.044.041a12.164,12.164,0,0,0,8.247,3.246h1.843V8.353a16.958,16.958,0,0,1-9.482,15.335L10.133,24ZM5.431,14.163H7.949v2.6h4.368v-2.6h2.519v-4.5H12.317v-2.6H7.949v2.6H5.431Zm3.882,1.191v-2.6H6.794V11.065H9.313v-2.6h1.64v2.6h2.519v1.692H10.953v2.6Z" fill="#0623c1" />
                    </svg>

                );
            case 'DashboardDrug':
                return (

                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26.989 21.713">
                        <path id="true-false" d="M10.474,4.02,5.895,8.212,3.9,6.231,2.076,8.068l3.748,3.718,6.4-5.856Zm4.443,3.857v2.588h12.94V7.876ZM4.186,14.725l-1.83,1.83,2.967,2.967L2.357,22.489l1.83,1.83,2.967-2.967,2.967,2.967,1.83-1.83L8.983,19.522l2.967-2.967-1.83-1.83L7.154,17.692Zm10.731,3.5v2.588h12.94V18.228Z" transform="translate(-1.369 -3.313)" fill="#0623c1" stroke="rgba(0,0,0,0)" stroke-width="1" />
                    </svg>
                );
            case 'DashboardCompliance':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24.131 24">
                        <g id="work-restrictions-icon" transform="translate(-4.4 -4.5)">
                            <path id="Path_51" data-name="Path 51" d="M15.1,43.8a1,1,0,1,0,1,1A.988.988,0,0,0,15.1,43.8Z" transform="translate(-5.459 -22.117)" fill="#0623c1" />
                            <path id="Path_52" data-name="Path 52" d="M13.881,15.622c2.4-1.353,2.532-2.75,2.488-3.318a1.522,1.522,0,0,0-.917-1.266.641.641,0,0,0-.568.044,1.44,1.44,0,0,0-.568.873.47.47,0,0,1-.437.349.46.46,0,0,1-.437-.349,1.853,1.853,0,0,0-.568-.873.809.809,0,0,0-.306-.087.554.554,0,0,0-.262.044,1.4,1.4,0,0,0-.917,1.266C11.349,12.872,11.479,14.269,13.881,15.622Z" transform="translate(-3.936 -3.657)" fill="#0623c1" />
                            <path id="Path_53" data-name="Path 53" d="M23.6,4.5H5.231a.83.83,0,0,0-.831.831V27.669a.83.83,0,0,0,.831.831H23.6a.83.83,0,0,0,.831-.831V5.331A.8.8,0,0,0,23.6,4.5ZM6.586,8.566a2.286,2.286,0,0,1,1.53-2.011,1.6,1.6,0,0,1,1.268.131,2.2,2.2,0,0,1,.568.525,1.637,1.637,0,0,1,.568-.525,1.481,1.481,0,0,1,1.224-.131,2.236,2.236,0,0,1,1.53,2.011c.087.743-.087,2.667-3.148,4.284a.569.569,0,0,1-.437,0C6.674,11.232,6.5,9.309,6.586,8.566Zm5.685,18.623a.438.438,0,0,1-.437-.437,2.192,2.192,0,0,0-2.186-2.186,2.165,2.165,0,0,0-2.186,2.186.438.438,0,0,1-.437.437.47.47,0,0,1-.437-.437,3.022,3.022,0,0,1,1.705-2.71,1.9,1.9,0,0,1-.568-1.311,1.88,1.88,0,0,1,3.761,0,1.745,1.745,0,0,1-.568,1.311,3.022,3.022,0,0,1,1.705,2.71C12.708,26.97,12.49,27.189,12.271,27.189Zm9.489-3.235H15.594a.47.47,0,0,1-.437-.437.438.438,0,0,1,.437-.437H21.76a.437.437,0,1,1,0,.874Zm0-2.973H12.883a.437.437,0,1,1,0-.874H21.76a.47.47,0,0,1,.437.437A.438.438,0,0,1,21.76,20.981Zm0-2.71H6.674a.413.413,0,0,1-.437-.437.438.438,0,0,1,.437-.437H21.76a.47.47,0,0,1,.437.437A.413.413,0,0,1,21.76,18.27Zm0-2.667H6.674a.413.413,0,0,1-.437-.437.438.438,0,0,1,.437-.437H21.76a.47.47,0,0,1,.437.437A.413.413,0,0,1,21.76,15.6Zm0-3.016H15.594a.438.438,0,0,1-.437-.437.47.47,0,0,1,.437-.437H21.76a.47.47,0,0,1,.437.437A.413.413,0,0,1,21.76,12.587Zm0-2.973H15.594a.437.437,0,1,1,0-.874H21.76a.438.438,0,0,1,.437.437A.413.413,0,0,1,21.76,9.615Z" transform="translate(0 0)" fill="#0623c1" />
                            <path id="Path_54" data-name="Path 54" d="M55.535,51.4,56.67,49H54.4Z" transform="translate(-28.139 -25.04)" fill="#0623c1" />
                            <path id="Path_55" data-name="Path 55" d="M56.321,12.449a1.093,1.093,0,0,0-.786-.349A1.156,1.156,0,0,0,54.4,13.235V27.772h2.27V13.279A1.118,1.118,0,0,0,56.321,12.449Z" transform="translate(-28.139 -4.282)" fill="#0623c1" />
                        </g>
                    </svg>
                );
            case 'DashboardLab':
                return (

                    <svg fill="#0623c1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 47.49 41.418">
                        <defs>
                            <linearGradient id="a" x1="0.773" y1="1.25" x2="0.227" y2="-0.25" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#6114f8" />
                                <stop offset="0.167" stopColor="#6e23f9" />
                                <stop offset="1" stopColor="#00b6ff" />
                            </linearGradient>
                        </defs>
                        <path
                            className="a"
                            d="M45.588,109.155,37.47,94.819l-.518-13.991h.864a.743.743,0,0,0,.691-.691V78.755a.743.743,0,0,0-.691-.691H27.365a.743.743,0,0,0-.691.691v1.382a.743.743,0,0,0,.691.691h.777V94.819L24,102.332l5.441,9.586a6.743,6.743,0,0,1,1.209,3.023H42.565c3.8,0,5.182-2.591,3.023-5.786Zm-16.582,3.109-8.9-15.718-.6-15.287h.95a.774.774,0,0,0,.777-.777V79.014a.774.774,0,0,0-.777-.777H9.056a.774.774,0,0,0-.777.777v1.468a.774.774,0,0,0,.777.777h.864V96.546L1.2,112.178c-2.245,3.455-.691,6.3,3.455,6.3H25.638c4.145,0,5.614-2.764,3.368-6.218ZM12.856,97.323V81.259h3.627l.6,16.064,4.4,8.291H8.8l4.059-8.291Zm18.05-1.814V80.828h3.282l.518,14.682,4.059,7.6H27.105l3.8-7.6Z"
                            transform="translate(0.358 -77.564)"
                        />
                    </svg>
                );
            case 'DashboardComp':
                return (

                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24.131 24">
                        <g id="work-restrictions-icon" transform="translate(-4.4 -4.5)">
                            <path id="Path_51" data-name="Path 51" d="M15.1,43.8a1,1,0,1,0,1,1A.988.988,0,0,0,15.1,43.8Z" transform="translate(-5.459 -22.117)" fill="#0623c1" />
                            <path id="Path_52" data-name="Path 52" d="M13.881,15.622c2.4-1.353,2.532-2.75,2.488-3.318a1.522,1.522,0,0,0-.917-1.266.641.641,0,0,0-.568.044,1.44,1.44,0,0,0-.568.873.47.47,0,0,1-.437.349.46.46,0,0,1-.437-.349,1.853,1.853,0,0,0-.568-.873.809.809,0,0,0-.306-.087.554.554,0,0,0-.262.044,1.4,1.4,0,0,0-.917,1.266C11.349,12.872,11.479,14.269,13.881,15.622Z" transform="translate(-3.936 -3.657)" fill="#0623c1" />
                            <path id="Path_53" data-name="Path 53" d="M23.6,4.5H5.231a.83.83,0,0,0-.831.831V27.669a.83.83,0,0,0,.831.831H23.6a.83.83,0,0,0,.831-.831V5.331A.8.8,0,0,0,23.6,4.5ZM6.586,8.566a2.286,2.286,0,0,1,1.53-2.011,1.6,1.6,0,0,1,1.268.131,2.2,2.2,0,0,1,.568.525,1.637,1.637,0,0,1,.568-.525,1.481,1.481,0,0,1,1.224-.131,2.236,2.236,0,0,1,1.53,2.011c.087.743-.087,2.667-3.148,4.284a.569.569,0,0,1-.437,0C6.674,11.232,6.5,9.309,6.586,8.566Zm5.685,18.623a.438.438,0,0,1-.437-.437,2.192,2.192,0,0,0-2.186-2.186,2.165,2.165,0,0,0-2.186,2.186.438.438,0,0,1-.437.437.47.47,0,0,1-.437-.437,3.022,3.022,0,0,1,1.705-2.71,1.9,1.9,0,0,1-.568-1.311,1.88,1.88,0,0,1,3.761,0,1.745,1.745,0,0,1-.568,1.311,3.022,3.022,0,0,1,1.705,2.71C12.708,26.97,12.49,27.189,12.271,27.189Zm9.489-3.235H15.594a.47.47,0,0,1-.437-.437.438.438,0,0,1,.437-.437H21.76a.437.437,0,1,1,0,.874Zm0-2.973H12.883a.437.437,0,1,1,0-.874H21.76a.47.47,0,0,1,.437.437A.438.438,0,0,1,21.76,20.981Zm0-2.71H6.674a.413.413,0,0,1-.437-.437.438.438,0,0,1,.437-.437H21.76a.47.47,0,0,1,.437.437A.413.413,0,0,1,21.76,18.27Zm0-2.667H6.674a.413.413,0,0,1-.437-.437.438.438,0,0,1,.437-.437H21.76a.47.47,0,0,1,.437.437A.413.413,0,0,1,21.76,15.6Zm0-3.016H15.594a.438.438,0,0,1-.437-.437.47.47,0,0,1,.437-.437H21.76a.47.47,0,0,1,.437.437A.413.413,0,0,1,21.76,12.587Zm0-2.973H15.594a.437.437,0,1,1,0-.874H21.76a.438.438,0,0,1,.437.437A.413.413,0,0,1,21.76,9.615Z" transform="translate(0 0)" fill="#0623c1" />
                            <path id="Path_54" data-name="Path 54" d="M55.535,51.4,56.67,49H54.4Z" transform="translate(-28.139 -25.04)" fill="#0623c1" />
                            <path id="Path_55" data-name="Path 55" d="M56.321,12.449a1.093,1.093,0,0,0-.786-.349A1.156,1.156,0,0,0,54.4,13.235V27.772h2.27V13.279A1.118,1.118,0,0,0,56.321,12.449Z" transform="translate(-28.139 -4.282)" fill="#0623c1" />
                        </g>
                    </svg>
                );

            case 'DashboardClaim':
                return (

                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
                        <g id="missing-claim-numbers" transform="translate(-7.332 -7.332)">
                            <circle id="Ellipse_9" data-name="Ellipse 9" cx="0.53" cy="0.53" r="0.53" transform="translate(18.802 24.132)" fill="#0623c1" />
                            <path id="Path_58" data-name="Path 58" d="M28.285,15l-6.642-2.751L15,15l-2.751,6.642L15,28.285l6.642,2.751,6.642-2.751,2.751-6.642ZM21.642,28.3a1.427,1.427,0,1,1,1.427-1.427A1.428,1.428,0,0,1,21.642,28.3Zm1.479-5.2a1.481,1.481,0,0,1-2.958,0L19.82,16.91a1.825,1.825,0,1,1,3.645,0Z" transform="translate(-2.311 -2.311)" fill="#0623c1" />
                            <path id="Path_59" data-name="Path 59" d="M16.451,11.974,23.605,9.01V7.332l-8.342,3.455Z" transform="translate(-4.236)" fill="#0623c1" />
                            <path id="Path_60" data-name="Path 60" d="M31.136,20a.974.974,0,0,0-.973,1.029l.383,6.9a.59.59,0,0,0,1.179,0l.383-6.9A.974.974,0,0,0,31.136,20Z" transform="translate(-11.804 -6.771)" fill="#0623c1" />
                            <path id="Path_61" data-name="Path 61" d="M50.874,33l-2.963,7.155L49.1,41.342,52.553,33Z" transform="translate(-21.221 -13.706)" fill="#0623c1" />
                            <path id="Path_62" data-name="Path 62" d="M9.011,23.605l2.963-7.155-1.187-1.187L7.332,23.605Z" transform="translate(0 -4.236)" fill="#0623c1" />
                            <path id="Path_63" data-name="Path 63" d="M9.011,33H7.332l3.455,8.342,1.187-1.187Z" transform="translate(0 -13.706)" fill="#0623c1" />
                            <path id="Path_64" data-name="Path 64" d="M33,7.332V9.01l7.155,2.964,1.187-1.187Z" transform="translate(-13.706)" fill="#0623c1" />
                            <path id="Path_65" data-name="Path 65" d="M14.322,14.322h0Z" transform="translate(-3.285 -3.285)" fill="#0623c1" />
                            <path id="Path_66" data-name="Path 66" d="M14.322,49.677h0Z" transform="translate(-3.285 -22.051)" fill="#0623c1" />
                            <path id="Path_67" data-name="Path 67" d="M49.677,49.678h0Z" transform="translate(-22.051 -22.051)" fill="#0623c1" />
                            <path id="Path_68" data-name="Path 68" d="M49.678,14.322h0Z" transform="translate(-22.051 -3.285)" fill="#0623c1" />
                            <path id="Path_69" data-name="Path 69" d="M47.911,16.451l2.963,7.155h1.679L49.1,15.264Z" transform="translate(-21.221 -4.236)" fill="#0623c1" />
                            <path id="Path_70" data-name="Path 70" d="M23.605,50.875l-7.155-2.964L15.264,49.1l8.342,3.455Z" transform="translate(-4.236 -21.221)" fill="#0623c1" />
                            <path id="Path_71" data-name="Path 71" d="M40.155,47.911,33,50.875v1.679L41.342,49.1Z" transform="translate(-13.706 -21.221)" fill="#0623c1" />
                        </g>
                    </svg>
                );
            case 'DashboardRestrictions':
                return (

                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24.096 24.096">
                        <g id="work-restrictions-icon" transform="translate(-7 -7)">
                            <path id="Path_56" data-name="Path 56" d="M29.643,19.928a.723.723,0,0,0-.723.723v3.132a.482.482,0,0,1-.964,0v-4.1a.723.723,0,0,0-1.446,0v5.06a.482.482,0,1,1-.964,0V18.723a.723.723,0,1,0-1.446,0v6.024a.482.482,0,1,1-.964,0v-5.06a.723.723,0,0,0-1.446,0v5.191l-.4-.469A1.678,1.678,0,0,0,19.23,24a1.158,1.158,0,0,0-.465,1.593l2.017,3.492a3.856,3.856,0,0,0,3.339,1.927h2.39a3.855,3.855,0,0,0,3.855-3.855V20.651A.723.723,0,0,0,29.643,19.928Z" transform="translate(-6.017 -5.699)" fill="#0623c1" />
                            <path id="Path_57" data-name="Path 57" d="M19.048,7A12.048,12.048,0,1,0,31.1,19.048,12.058,12.058,0,0,0,19.048,7Zm7.6,17.946-1.692-1.692a4.854,4.854,0,0,1-1.451,1.961l1.436,1.436a9.621,9.621,0,0,1-13.5-13.5l3.267,3.267V13.988a1.651,1.651,0,0,1,.2-.786l-1.759-1.759a9.621,9.621,0,0,1,13.5,13.5Z" transform="translate(0 0)" fill="#0623c1" />
                        </g>
                    </svg>
                );


        }
    };

    return (
        <>
            {renderIcon()}
        </>
    );
};

export default DashboardIcons;
