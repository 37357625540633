import { createSlice } from '@reduxjs/toolkit';
const CHECKBOX_INTIAL_STATE={
  cbSelectedAllRecords:false,
  cbActiveIds:[],
};
const GlobalFilterSlice = createSlice({
  name: 'global_filter',
  initialState: {
    data: {},
    checkbox:CHECKBOX_INTIAL_STATE
  },
  reducers: {
    updateGlobalFilter: (state, action) => {
      state.data = action.payload;
    },
    resetGlobalFilter: (state) => {
      state.data = {}; 
    },
    //checkbox reducers
    setSelectedAllRecords: (state, action) => {
      state.checkbox.cbSelectedAllRecords = action.payload;
    },
    setActiveIds: (state, action) => {
      state.checkbox.cbActiveIds = action.payload;
    },
    clearCbState: (state, action = false) => {
      state.checkbox = CHECKBOX_INTIAL_STATE;

    },
  },
  extraReducers: (builder) => {
  },
});

export const { updateGlobalFilter,resetGlobalFilter,setSelectedAllRecords,setActiveIds,clearCbState} = GlobalFilterSlice.actions;

export const getGlobalFilterState = (state) => state.globalFilter.data;
export const checkBoxActiveIds = (state) => state.globalFilter.checkbox.cbActiveIds;
export const checkBoxSelectedAllRecords = (state) => state.globalFilter.checkbox.cbSelectedAllRecords;
export default GlobalFilterSlice.reducer;
