
import {  isSet } from "../../../../../../utils/commonUtils";

export const Spirometry = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        { 
            label:'Measure - FVC',
            fields:[{
                label:'Actual - 1',
                col:'col-4',
                type:'Number',
                name:'fvc_actual_1',
                id:'fvc_actual_1',
            },
            {
                label:'Actual - 2',
                col:'col-4',
                type:'Number',
                name:'fvc_actual_2',
                id:'fvc_actual_2',
            },
            {
                label:'Actual - 3',
                col:'col-4',
                type:'Number',
                name:'fvc_actual_3',
                id:'fvc_actual_2',
            },
            {
                label:'Predicted',
                col:'col-4',
                type:'Number',
                name:'fvc_predicted',
                id:'fvc_predicted',
            }, 
            {
                label:'(Best) Actual',
                col:'col-4',
                type:'Number',
                readOnly:true,
                name:'fvc_best_actual',
                id:'fvc_best_actual',
            }, 
            {
                label:'% Predicted',
                col:'col-4',
                type:'TextInput',
                readOnly:true,
                name:'fvc_percentage_predicted',
                id:'fvc_percentage_predicted',
            }, 
        ]
        },
        { 
            label:'Measure - FEV1',
            fields:[{
                label:'Actual - 1',
                col:'col-4',
                type:'Number',
                name:'fev1_actual_1',
                id:'fev1_actual_1',
            },
            {
                label:'Actual - 2',
                col:'col-4',
                type:'Number',
                name:'fev1_actual_2',
                id:'fev1_actual_2',
            },
            {
                label:'Actual - 3',
                col:'col-4',
                type:'Number',
                name:'fev1_actual_3',
                id:'fev1_actual_3',
            },
            {
                label:'Predicted',
                col:'col-4',
                type:'TextInput',
                name:'fev1_predicted',
                id:'fev1_predicted',
            }, 
            {
                label:'(Best) Actual',
                col:'col-4',
                type:'Number',
                readOnly:true,
                name:'fev1_best_actual',
                id:'fev1_best_actual',
            }, 
            {
                label:'% Predicted',
                col:'col-4',
                type:'TextInput',
                readOnly:true,
                name:'fev1_percentage_predicted',
                id:'fev1_percentage_predicted',
            }, 
        ]
        },
        { 
            label:'Measure - FEV1 / FVC',
            fields:[{
                label:'Actual - 1',
                col:'col-4',
                type:'Number',
                name:'fev1_fvc_actual_1',
                id:'fev1_fvc_actual_1',
            },
            {
                label:'Actual - 2',
                col:'col-4',
                type:'Number',
                name:'fev1_fvc_actual_2',
                id:'fev1_fvc_actual_2',
            },
            {
                label:'Actual - 3',
                col:'col-4',
                type:'Number',
                name:'fev1_fvc_actual_3',
                id:'fev1_fvc_actual_3',
            },
            {
                label:'Predicted',
                col:'col-4',
                type:'Number',
                name:'fev1_fvc_predicted',
                id:'fev1_fvc_predicted',
            }, 
            {
                label:'(Best) Actual',
                col:'col-4',
                type:'Number',
                readOnly:true,
                name:'fev1_fvc_best_actual',
                id:'fev1_fvc_best_actual',
            }, 
            {
                label:'% Predicted',
                col:'col-4',
                type:'TextInput',
                readOnly:true,
                name:'fev1_fvc_percentage_predicted',
                id:'fev1_fvc_percentage_predicted',
            }, 
        ]
        }, 
        {
            label:'Status & Others',
            fields:[
                {
                    label:'Pulmonary Classifications',
                    col:'col-12',
                    type:'UnorderedList',
                    notes:["FVC (Restrictive) Classification:  No Restrictive Disease","FEV1 (Obstructive) Classification:  No Obstructive Disease","NIOSH Respirator Classification:   Approved for Unlimited Use"],
                },
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
                {
                    label:'Status',
                    col:'col-6',
                    type:'SelectList',
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'status',
                },
            
           
        ]
        },]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData)=>{
    let returnData = {}; 
    const prefixes = ["fvc","fev1","fev1_fvc","fev1_fev6"]; // Define the prefixes
    prefixes.forEach(prefix => {
        const actual1 = isSet(formData[prefix + '_actual_1'], 0);
        const actual2 = isSet(formData[prefix + '_actual_2'], 0);
        const actual3 = isSet(formData[prefix + '_actual_3'], 0);
        let bestActual = isSet(formData[prefix + '_best_actual'], 0);
        const predicted = isSet(formData[prefix + '_predicted'], 0);

        const best_actual = Math.max(actual1, actual2, actual3);
        if(best_actual!==0){
            bestActual = best_actual;
            returnData = {
                ...returnData,
                [prefix + '_best_actual']: best_actual
            };
        }
        

        if (bestActual !== 0 && predicted !== 0) {
            const percentage_predicted = ((Number(bestActual) / Number(predicted)) * 100).toFixed(2);
            returnData = {
                ...returnData,
                [prefix + '_percentage_predicted']: percentage_predicted
            };
        }
    });
    return {...returnData};

}