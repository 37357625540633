import Buttons from "../components/UI/Buttons";
import { isSet } from "../utils/commonUtils";

const TableSkeleton = (props) => {
  const { children,tableRecords,addFormHandler, content, acl } = props;
  const totalRecords=isSet(tableRecords?.tableRows?.data,[]).length;
  let status = tableRecords?.tableRows?.status;
  let isSearch  = false;
  try{
    isSearch = (Object.entries(tableRecords?.pagination?.search).length===0)?false:true;
  }catch(e){

  }
  // console.log('isSearch',totalRecords,isSearch,status);
  
  return (
    <>
      {(isSet(status,200)!==200)?
        <></>
      :(totalRecords === 0 && isSearch===false) ? (
        <div className="sh_cardBox skeleton_Img">
          <div className="text-center">
            <p className="text-lg font-weight-bold">
              No {content} Found. To proceed further please add.
            </p>
            <Buttons
              className="btn btn-primary"
              clickHandler={() => addFormHandler()}
              label={"Add New"}
              acl={acl}
            />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

TableSkeleton.defaultProps = {
  content: "Data",
  acl: "any",
  addFormHandler: () => {}
};

export default TableSkeleton;
