import React from 'react';
import DayGrid  from './DayGrid';
import DayContent from './DayContent';

const WeekGrid = (props)=>{
    const {keyIndex,listItem} = props;
    //calendarMonthDay
    return(
        <div className="fc-row fc-week fc-widget-content fc-rigid" style={{height:'160px'}} key={keyIndex}>
            {/* Month Day Grid */}
            <DayGrid 
                {...props}
                listItem={listItem}
                keyIndex={keyIndex} 
            />
            {/* Month Day  Content*/}
            <DayContent    
                {...props}
                listItem={listItem}
                keyIndex={keyIndex} 
            />

        </div>
    )
}


WeekGrid.defaultProps = {

}
export default WeekGrid;