import React from "react";
import YesOrNoFields from "./YesOrNoFields";
import BasicFormFields from "./BasicFormFields";
import PatientDetails from "./PatientDetails";
import CompanyDetails from "./CompanyDetails";
import CheckboxGroup from "./CheckBoxGroup";
import UnOrderedList from "./UnOrderedList";
import LabelField from "./LabelField";
import ResultSummary from "./ResultSummary";
import ChartDocument from "./ChartDocument";
import LabPanel from "./LabPanel";

const SwitchFields = ({item,...props}) => {
  switch (item.type) {
    case "PatientDetails":
      return <PatientDetails {...item} {...props}/>
    case "CompanyDetails":
        return <CompanyDetails {...item} {...props}/>
    case "ResultSummary":
      return <ResultSummary {...item} {...props}/>
    case "YesOrNoTable":
          return <YesOrNoFields {...item} {...props}/>
    case "CheckBoxGroup":
      return <CheckboxGroup  {...item} {...props}/>
    case "UnorderedList":
      return <UnOrderedList {...item} {...props} />
    case "Label":
        return <LabelField {...item} {...props} />;
    case "ChartDocument":
      return <ChartDocument {...item} {...props} />
    case "LabPanel":
      return <LabPanel {...item} {...props} />
    default:
      return <BasicFormFields {...item} {...props}/>;
  }

  
};

export default SwitchFields;