import { useState } from "react";
import FormInputs from "../../../components/UI/FormInputs";
import { isSet } from "../../../utils/commonUtils";

const UploadAdditionalDocument = (props) => {
    const { onChangeHandler, formData, bulkOnChangeHandler,validation} = props;
    const [imageUrl, setImageUrl] = useState('');
    const handleFileChange = (field, file) => {
        onChangeHandler(field, file);
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setImageUrl(fileUrl);
        }
    };
    const removeImageHandler=()=>{
        onChangeHandler('file', null);
        setImageUrl('');
    }
    return (
        <>   
            <div className="col-lg-6  d-flex flex-column justify-content-center mx-auto">
                <div className="row"><div class="col-md-7 mx-auto">
                    <div className="card sh_loginCard uploadborder border-0 my-5 shadow_new">
                        <div className="card-body ">
                            <h6 className="text-lg f-500 mb-2 p-2  d-flex justify-content-center">Upload Additional Documents</h6>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                    <div className="form-group uploadid">
                                        <label className="text-sm text-truncate mb-0 opacity-75 input-file">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#fdfdfd"><path  d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>{"Upload Id"}
                                        <FormInputs
                                            type='FileUpload'
                                            title="Field Document"
                                            className=''
                                            id="input-file"
                                            changeHandler={(val) => handleFileChange('file', val)} value={formData.file || ''}
                                        /></label>  
                                        {
                                            imageUrl?
                                            <button  className="removeupload ml-2" onClick={()=>removeImageHandler()}>
                                        Remove Image
                                    </button> :
                                    ''
                                        }                                     
                                    </div>   
                                       <div className="uploadid-selected">
                                               <p>{formData.file?.name || ''}</p> 
                                                {imageUrl && <img src={imageUrl} className="" alt="upload Id" />}
                                            </div>
                                        {isSet(validation['file'], "") !== "" ? (<span className="text-danger m-1"> {validation['file']}</span>)
                                            :
                                            ""
                                        }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
};

export default UploadAdditionalDocument;
