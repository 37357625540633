import React, { Component } from 'react';
import FormInputs from '../../components/UI/FormInputs';
import TableGrid from '../../components/UI/TableGrid';
import { toQueryString } from '../../utils/commonUtils';
import withReduxState from '../../hoc/wReduxState';
import WithRoutify from '../../hoc/WithRoutify';
import PatientChartsService from '../../services/PatientChartsService';
import FileManagerService from '../../services/FileManagerService';
import VaccineFilter from './VaccineFilter';
import ReactDOM from "react-dom";
import AddVaccine from '../EmployeeVisitContainer/AddVaccine';

class VaccineResult extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      { label: '', key: 'isActive', type: 'checkBox', changeHandler: this.checkBoxchangeHandler },
      { label: 'Patient', key: 'patient_name', },
      { label: 'System ID', key: 'system_id' },
      { label: 'Company', key: 'company' },
      { label: 'Date', key: 'visit_date' },
      { label: 'Time', key: 'visit_time' },
      { label: 'Vaccine Name ', key: 'vaccine_name' },
      { label: 'Lot', key: 'lot' },
      { label: 'Admin Site', key: 'vaccination_adminstration_site' },
      { label: 'Exp. Date', key: 'expiration_date' },
      { label: 'Series', key: 'series' },
      { label: 'Person Administrating Vaccine', key: 'person_adminstration_vaccine' },
      { label: 'Title', key: 'title' },
      { label: 'Action', key: 'action', type: 'Actions' },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: ''
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'visit_date',
          sort_order: 'desc',
          search: {},
        }
      },
      activeEditId: null,
      activeEditData: null,
      isModelOpen: false,
      filterOpen: false,
    }
  }

  componentDidMount() {
    this.fetchVaccineResult();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.globalFilter.Vaccine!==this.props?.globalFilter?.Vaccine) {
      this.fetchVaccineResult();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('filterOpened')
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen })
  }

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId, activeEditData } = this.state;
    return (
      <>
        {isModelOpen ? (
          <AddVaccine
            isOpen={this.state.isModelOpen}
            activeEditId={activeEditId}
            mapping_id={activeEditData.visit_mapping_id}
            visitId={activeEditData.visit_id}
            activeEditData={activeEditData}
            fetchRecords={this.fetchVaccineResult}
            modalToogleHandler={this.addFormHandler}
            fromChart={false}
          />
        ) : (
          <></>
        )}
        {/* <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"lab-create"}
          content={"Labs"}
        > */}
        <div className="sh_cardBox">
          {
            this.props.isFromEmployeeHistory ?
              <>
               <div className="sh_cardHeader">
                 <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                   <h6 className="text-sm mb-0">
                     Vaccine History - {this.props.patientDetails.patientName}
                   </h6>
                   <div className="addAction d-flex justify-content-between align-items-center">
                   </div>
                 </div>
               </div>
              </>
              :
              <div className="sh_cardHeader">
                <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                  <h6 class="text-xl f-500 mb-0"> Vaccine Result</h6>
                  <div className="d-flex">
                    <div className="form-group-fields row mr-2">
                      <div className="col-12 px-1 d-flex">
                        <div className="input-group input-group-joined input-group-solid">
                        </div>
                        <div className="col-12">
                            <div className="btn-group ">
                            <div className="input-group input-group-joined input-group-solid">
                              <FormInputs
                                placeholder="Vaccine"
                                value={filters["search_query"]}
                                changeHandler={(val) =>
                                  this.onChangeFiltersHandler("search_query", val)
                                }
                                className="form-control"
                              />
                            </div>
                            </div>
                          <div className="btn-group pl-2">
                            {/* <Buttons
                              clickHandler={this.filterOnClick}
                              className="btn btn-outline-secondary mr-2"
                              acl={"any"}
                              label={"Search by "}
                              iconType={"Filter"}
                            ></Buttons> */}

                            <button
                              className="btn btn-primary"
                              onClick={() => this.submitFiltersHandler()}
                            >
                              Search
                            </button>
                            <button
                              className="btn btn-outline-secondary"
                              onClick={() => this.clearFiltersHandler()}
                            >
                              Clear
                            </button>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
          <div className="sh_cardBody p-0">
            <TableGrid
              hasPagination={true}
              fetchTableRecords={(filters) => this.fetchVaccineResult(filters)}
              {...tableRecords}
            />
          </div>
        </div>
        {this.state.filterOpen ? (
          <>
            {ReactDOM.createPortal(
              <VaccineFilter
                onChangeHandler={this.onChangeFiltersHandler}
                filterData={this.state.filters}
                closeHandler={this.filterCloseHandler}
                submitHandler={this.submitFiltersHandler}
              />,
              document.getElementById("sh_mainportal_view") // Render the component into the portal container
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      search: filters,
    }
    this.fetchVaccineResult(queryfilters);
  }
  clearFiltersHandler = () => {
    this.setState({
      filters: {}
    }, this.submitFiltersHandler);
  }
  fetchVaccineResult = async (query = {}) => {
    const { tableRecords } = this.state;
    let queryString = toQueryString({ ...tableRecords.pagination, ...query });
    const globalFilter=this.props?.globalFilter?.Vaccine || {};
    if (this.props.isFromEmployeeHistory && this.props.employee_id) {
      queryString.employee_id = this.props.employee_id;
    }
    queryString = {...queryString,...globalFilter}
    try {
      this.props.Loader(true);
      const VaccineResult = await PatientChartsService.getVaccinationResult(null, queryString);
      let tableData = [];
      VaccineResult.data.forEach((item, index) => {
        tableData = [
          ...tableData,
          {
            labsIndex: index,
            isHeading: false,
            rowId: item.id,
            data: {
              isActive: false,
              patient_name: item?.visit?.employee ? `${item.visit.employee.first_name} ${item.visit.employee.last_name}` : '--',
              system_id: item?.visit?.employee?.system_id || '--',
              visit_date: item.visit_date || '--',
              visit_time: item.visit_time || '--',
              vaccine_name: item.vaccine_name || '--',
              lot: item.lot_number,
              vaccination_adminstration_site: item.vaccination_adminstration_site || '--',
              expiration_date: item.vaccine_expiration_date,
              series: item.series,
              person_adminstration_vaccine: item.vaccine_adminstrator&&item.vaccine_adminstrator?.person_administrating_vaccine || '--',
              title: item.title || '--',
              company: item?.company?.name,
              active: 'Yes',
              action: [
                {
                  className: 'btn btn-icon btn-transparent-dark',
                  iconType: 'Search',
                  title: 'View',
                  type: 'GridEdit',
                  acl: 'any',
                  clickHandler: (rowId, data) => this.editHandler(item.id, item),
                },
                {
                  className: 'btn btn-icon btn-transparent-dark pl-1 pr-1',
                  title: 'Print',
                  acl: 'any',
                  children: (
                    <>
                      <svg onClick={() => { this.printLabel(item.id) }} fill="#000000" height="16" width="16" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64">	<path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002		C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995		c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507		c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649		c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012		C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z		 M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z" />	<path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z" />	<path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z" />	<circle cx="7.9590998" cy="21.8405495" r="2" />	<circle cx="14.2856998" cy="21.8405495" r="2" />	<circle cx="20.6121998" cy="21.8405495" r="2" />	<path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z" /></svg>
                    </>
                  )
                }
              ]
            }
          }
        ]
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            status: 200,
            type: "default",
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: VaccineResult.total
          },
        },
      }));
      this.props.Loader();
    } catch (e) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200
          }
        }
      });
      this.props.Loader();
    }
  }
  deleteHandler = async (labId, vist_id) => {
    const { Loader, Toast } = this.props;

  }
  editHandler = (rowId, data) => {
    this.setState({
      activeEditId: rowId,
      activeEditData: data
    }, this.addFormHandler());
  }
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    });
  }
  printLabel = async (id, pdfname = 'vaccineResult') => {
    const response = await PatientChartsService.getVaccinePDF(id);
    const pdfData = {
      file_content: response,
      original_name: `${pdfname}`,
      mime_type: 'application/pdf'
    }
    FileManagerService.printPdf(pdfData);
  }
  onChangeFormDataInEdit = (key, val) => {
    this.setState((prevState) => ({
      formDataInEdit: {
        ...prevState.formDataInEdit,
        [key]: val,
        validation: {
          ...prevState.formDataInEdit.validation,
          [key]: '', // Set validation empty
        },
      },
    }));
  }
  filterOnClick = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    }, () => {
      if (document.body.classList.contains('filterOpened')) {
        document.body.classList.remove('filterOpened');
        // menuToggleElement.removeClass('active');
      } else {
        document.body.classList.add('filterOpened');
      }
    });
  };
  filterCloseHandler = (isClear = true) => {
    if (isClear) {
      this.clearFiltersHandler();
    }
    else {
      this.setState({
        filterOpen: false,
      }, () => {
        if (document.body.classList.contains('filterOpened')) {
          document.body.classList.remove('filterOpened');
          // menuToggleElement.removeClass('active');
        } else {
          document.body.classList.add('filterOpened');
        }
      });
    }
  };
  checkBoxchangeHandler = (value, { e }) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };

}

export default withReduxState(WithRoutify(VaccineResult));