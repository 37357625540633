import React,{useCallback, useEffect} from "react";

const FullScreenMode = ()=>{

    const sideBarChangeHandler = useCallback((e) => {
        document.body.classList.toggle('fullScreenOpened');
        const currentElmt = e.currentTarget;
        const iconElement = currentElmt.querySelector('i');
        if (iconElement.classList.contains('fa-window-maximize')) {
            iconElement.classList.remove('fa-window-maximize');
            iconElement.classList.add('fa-window-restore');
            currentElmt.title = 'Minimize window';
        } else if (iconElement.classList.contains('fa-window-restore')) {
            iconElement.classList.remove('fa-window-restore');
            iconElement.classList.add('fa-window-maximize');
            currentElmt.title = 'Maximize window';
        }

        e.currentTarget.closest('.sh_cardBox').classList.toggle('panel-fullscreen');
      },[]);
      
      useEffect(()=>{
        return () => {
            document.body.classList.remove('fullScreenOpened');
          };
      },[])

    return(
        <div className="addAction d-flex">
            <button
                id="panel-fullscreen"
                onClick={(e)=>sideBarChangeHandler(e)}
                className="btn ml-2"
                title='Maximize window'>
                {/* <i className="fa fa-window-maximize" aria-hidden="true"></i> */}
            </button>
        </div>
    )
}

export default FullScreenMode;