import React,{useEffect, useState,useRef}  from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@react-google-maps/api';
import { isSet } from '../../../utils/commonUtils';


// import {setAndGetAttributes} from './FormInputFunctions';

const AddressInput = (props)=>{
  // Props Attr
   const {
    type,
    className,
    placeholder,
    value,
    style,
    name,
    required,
    readOnly,
    disabled,
    id
  } = props;
  // Handler
   const {
    changeHandler,
    isFirstLetterCaps
    // onKeyPressHandler
  } = props;

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
      const updatePacContainerPosition = () => {
        const inputElement = inputRef.current;
        const pacContainers  = document.querySelectorAll('.pac-container');
        if (inputElement && pacContainers) {
          pacContainers.forEach(pacContainer => {
            // const inputRect = inputElement.getBoundingClientRect();
            // const pacRect = pacContainer.getBoundingClientRect();
            // Find the pac-container that is currently displayed
            pacContainer.style.display = "none";
            // if (pacRect.top === inputRect.bottom && pacRect.left === inputRect.left) {
              // pacContainer.style.top = `${inputRect.bottom}px`;
              // pacContainer.style.left = `${inputRect.left}px`;
              // pacContainer.style.width = `${inputRect.width}px`;
            // }
          });
        }
      };
  
      updatePacContainerPosition();
      const scrollableContainer = document.querySelector('.'+props.scrollingArea+'');
      if (scrollableContainer) {
        scrollableContainer.addEventListener('scroll', updatePacContainerPosition);
      } else {
        window.addEventListener('scroll', updatePacContainerPosition);
      }
  
      window.addEventListener('resize', updatePacContainerPosition);
  
      return () => {
        if (scrollableContainer) {
          scrollableContainer.removeEventListener('scroll', updatePacContainerPosition);
        } else {
          window.removeEventListener('scroll', updatePacContainerPosition);
        }
        window.removeEventListener('resize', updatePacContainerPosition);
      };
    }, []);

    useEffect(()=>{
        setInputValue(value);
    },[value]);
    
    const handlePlaceSelect = () => {
        if (selectedPlace != null) {
            const place = selectedPlace.getPlace();
            // console.log('place',place);
            let searchTerm = {
                address:place.formatted_address,
            };
                try{
                    place.address_components.map((item)=>{
                        if(item.types.indexOf('route')!==-1){
                            searchTerm = {...searchTerm,'street':item.long_name};
                        }else if(item.types.indexOf('locality')!==-1 || item.types.indexOf('administrative_area_level_3')!==-1){
                            searchTerm = {...searchTerm,'city':item.long_name};
                        }else if(item.types.indexOf('administrative_area_level_1')!==-1 ){
                            searchTerm = {...searchTerm,'state':item.short_name}; 
                        }else if(item.types.indexOf("country")!==-1 ){
                            searchTerm= {...searchTerm,'country':item.long_name}; 
                        }
                        else if(item.types.indexOf("administrative_area_level_2")!==-1 ){
                          searchTerm= {...searchTerm,'county':item.long_name}; 
                      }
                        else if(item.types.indexOf("postal_code")!==-1 ){
                            searchTerm= {...searchTerm,'zipcode':item.long_name}; 
                        }
                    }); 
                }catch(e){

                }
                setInputValue(place.formatted_address);
                changeHandler(place.formatted_address,{addressParam:searchTerm});
          }else{
             changeHandler('',{
                street:'',
                city:'',
                state:'',
                zipcode:'',
             });
          }
    };
    const handleInputChange = (e) => {
      let targetValue = e.target.value;
      // Convert the first letter of each word to uppercase if required
      if (isFirstLetterCaps) {
        targetValue = targetValue
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
      if (isSet(targetValue, null) === null) {
        changeHandler("", {
          street: "",
          city: "",
          state: "",
          zipcode: "",
        });
      }
      setInputValue(targetValue);
      changeHandler(targetValue, {});
    };
    return(
        <>
        <Autocomplete
                onLoad={(autocomplete) => setSelectedPlace(autocomplete)}
                onPlaceChanged={handlePlaceSelect}
                options={{
                   componentRestrictions: { country: 'us' } // Restrict to US addresses
                }}

                 >
                <input 
                   ref={inputRef}
                    type={type}
                    disabled={disabled}
                    className={className}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    {...style}
                    readOnly={readOnly}
                    value={inputValue}
                    required={required}
                    onChange={handleInputChange} 
                />
            </Autocomplete>
        </>
          
    )
}
AddressInput.defaultProps = {
  type:'text',
  disabled:false,
  isMandatory:false,
  className: 'form-control form-control-solid',
  placeholder:'',
  value:'',
  id:'',
  style:{},
  onKeyPressHandler:()=>{},
  readOnly:false,
  required:false,
  validation:{
    isValid:true,message:''
  },
  scrollingArea:'modal-body'

}
AddressInput.propTypes = {
  type: PropTypes.string,
  isMandatory: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  // validationRules: PropTypes.array,
  // valid: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
};

export default AddressInput;
