import React,{useEffect, useState} from "react";
import {useSelector } from 'react-redux';
import LoaderIcon from "../Icons/LoaderIcon";
import { createPortal } from "react-dom";


const Loader = ()=>{
    const [targetContainer,setTargetContainer]=useState(); 
    useEffect(() => {
      setTargetContainer(document.body); 
    }, []);
    const isLoading = useSelector(state=>state.common.isLoading);
    if(isLoading===true){ 
        return(
            <>
            {targetContainer && createPortal(isLoading && <LoaderIcon />, targetContainer)}
            </>
        )
    }else{
        return null;
    }
    
}

export default Loader;