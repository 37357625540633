import React, { Component } from "react";
import ClinicService from "../../services/ClinicService";

import AddClinicDepartment from "./AddClinicDepartment";
import TableGrid from "../../components/UI/TableGrid";
import { capitalizeFirstLetter, getOrganizationId, isObject, isSet, toQueryString } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import { Button } from "react-bootstrap";
import { ToastContext } from "../../context/ToaxtContext";
import WithRoutify from "../../hoc/WithRoutify";
import ClinicDepartmentService from "../../services/ClinicDepartmentService";
import TableSkeleton from "../../hoc/TableSkeleton";
import DropDownService from "../../services/DropDownService";


class ClinicDepartmentList extends Component {
  static contextType = ToastContext;
  constructor(props) {
    super(props); // Add this line to properly initialize the parent class
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.listOnchangeHandler,
      },

      {
        label: "Clinic",
        key: "clinic_name",
        inputType: "SelectList",
        options: [],
      },
      { label: "Facility Name", key: "facility_name", inputType: "TextInput" },
      { label: "Clinic Code", key: "clinic_code", inputType: "TextInput" },
      { label: "CLIA", key: "clia", inputType: "TextInput" },
      {
        label: "Street Address",
        key: "street_address",
        inputType: "TextInput",
      },
      { label: "City", key: "city", inputType: "TextInput" },
      { label: "State", key: "state", inputType: "TextInput" },
      { label: "Zip Code", key: "zip_code", inputType: "TextInput" },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    // Initialize state or bind methods if needed
    this.state = {
      isModelOpen: false,
      clinicListOption: [],
      filters: {
        search_query: "",
        clinic_id: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "name",
          sort_order: "asc",
          search: {},
        },
      },
      activeEditId: null,
    };
  }

  componentDidMount = async () => {
    // Code to run after the component has mounted
    const { Loader } = this.props;
    Loader(true);
    await this.fetchClinicList();
    this.fetchClinicDepartmentList();
  };

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    let { filters } = this.state;
    this.setState({ filters: { ...filters, [key]: val } });
  };
  listOnchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  render() {
    const {
      isAddFormModel,
      clinicListOption,
      tableRecords,
      isModelOpen,
      filter,
    } = this.state;
    // console.log("clinicListOption tableRecords",tableRecords);
    return (
      <>
        {isModelOpen ? (
          <AddClinicDepartment
            modelTitle={`${this.state.activeEditId ? "View and Update" : "Add"} Clinic Department`}
            isOpen={this.state.isModelOpen}
            clinicList={this.state.clinicListOption}
            addFormHandler={this.addFormHandler}
            fetchClinicDepartment={this.fetchClinicDepartmentList}
            activeEditId={this.state.activeEditId}
          />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={()=>{this.addFormHandler(); this.setState({activeEditId:""})}}
          acl={"any"}
          content={"Department"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between formheaderresp">
                <h6 class="text-lg f-500 mb-0"> Clinic Department List </h6>
                <div className="addAction d-flex">
                  <div className="form-group-fields row justify-content-end">
                    <div className="col-md-4 col-4 px-1">
                      <FormInputs
                        placeholder="Clinics"
                        type="SelectList"
                        options={clinicListOption}
                        name="clinic_id"
                        value={this.state.filters.clinic_id}
                        changeHandler={(val) =>
                          this.onChangeFiltersHandler("clinic_id", val)
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 col-3 px-1">
                      <div className="input-group input-group-joined input-group-solid mr-2">
                        <FormInputs
                          placeholder="Clinic Department"
                          name="search_query"
                          value={this.state.filters.search_query}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className=" pl-1 btn-group mb-1 mr-2">
                      <Button
                        className="btn btn-primary"
                        onClick={() => this.submitFiltersHandler()}
                      >
                        Search
                      </Button>
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => this.clearFiltersHandler()}
                      >
                        Clear
                      </button>
                    </div>
                  </div>

                  <div className="btn-group pl-2  mb-1">
                    <Buttons
                      clickHandler={() => {
                        this.addFormHandler();
                        this.setState({ activeEditId: "" });
                      }}
                      className="btn btn-primary"
                      acl={"clinic-department-create"}
                      label={"Add"}
                    />
                    <button
                      className="btn btn-outline-secondary dropdown no-arrow"
                      data-bs-toggle="dropdown"
                    >
                      <span className="dropdown-toggle">
                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                      </span>
                      <div
                        className="dropdown-menu dropdown-menu-right shadow"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Group Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Grid Edit
                        </a>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(query) =>
                  this.fetchClinicDepartmentList(query)
                }
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }

  // triggers when submit filter handler 
  submitFiltersHandler = () => {
    //filter Submit logic goes here//
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search: filters,
    };
    this.fetchClinicDepartmentList(queryfilters);
  };
  // triggers when clear filter handler 
  clearFiltersHandler = () => {
    this.setState({
      filters: {}
    }, this.submitFiltersHandler);
  }
  fetchClinicDepartmentList = async (query) => {
    const { tableRecords } = this.state;
    const { Loader } = this.props;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    Loader(true);
    ClinicDepartmentService.getClinicDepartment(queryString)
      .then((res) => {
        Loader(false);
        let tableData = [];
        // console.log("clinicLocationList", res);
        res.data?.map((clinic, locationIndex) => {
          tableData = [
            ...tableData,
            {
              locationIndex: locationIndex,
              isHeading: false,
              rowId: clinic.id,
              data: {
                rowId: clinic.id,
                clinic_name:clinic.snap_clinic.name,
                clinic_id: clinic.clinic_id,
                isActive: false,
                description: clinic.description,
                facility_name: clinic.facility_name,
                clinic_code: clinic.clinic_code,
                clia: clinic.clia,
                street_address: clinic.street_address,
                state: clinic.state,
                city: clinic.city,
                zip_code: clinic.zip_code,
                active: "Yes",
                action: [
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Search",
                    title: "View",
                    type: "GridEdit",
                    acl: "clinic-department-edit",
                    clickHandler: (rowId, data) =>
                      this.editHandler(rowId, data, clinic.clinic_id),
                  },
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Remove",
                    acl: "clinic-department-delete",
                    title: "Delete",
                    isDelete: true,
                    clickHandler: () => this.deleteHandler(clinic.id),
                  },
                ],
              },
            },
          ];
        });
        this.setState((prevState) => ({
          tableRecords: {
            ...prevState.tableRecords,
            tableHeaders: prevState.tableRecords.tableHeaders,
            tableRows: {
              type: "default",
              status: 200,
              data: tableData,
            },
            pagination: {
              ...prevState.tableRecords.pagination,
              ...query,
              totalRecords: res?.pagination?.totalCount,
            },
          },
        }));
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          tableRecords: {
            ...tableRecords,
            tableRows: {
              ...tableRecords.tableRows,
              status: 200,
            },
          },
        });
      })
      .finally(() => {
        Loader(false);
      });
  };
  fetchClinicList = async () => {
    DropDownService.getClinicList(null).then((response) => {
      let options = [{ label: "Clinics", value: "" }];
      response &&
        response.forEach((itm) => {
          options.push({ label: itm.name, value: itm.clinic_id });
        });
      this.setState((prevState) => ({
        clinicListOption: options,
      }));
    });
    this.fetchClinicDepartmentList();
  };

  deleteHandler = async (clinicId) => {
    ClinicDepartmentService.deleteClinicDepartment(clinicId)
      .then((response) => {
        if (response) {
          this.fetchClinicDepartmentList();
          this.props.Toast.success("Clinic Department Deleted Successfully");
        }
      })
      .catch({});
  };
  editHandler = (rowId, data, clinic_id) => {
    this.setState((prevState) => ({
      activeEditId: rowId,
    }));
    this.addFormHandler();
  };
}

export default WithRoutify(ClinicDepartmentList);
