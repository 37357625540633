import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {formatPhoneNumberUS, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import EmployeeService from "../../services/EmployeeService";
import withReduxState from "../../hoc/wReduxState";
import WithRoutify from "../../hoc/WithRoutify";
import Buttons from "../../components/UI/Buttons";
import { ItentificationTypeOptions, EthinicityOptions, genderOptions, raceOptions, RelationShipOptions } from "../../utils/constant";
import ClinicDepartmentService from "../../services/ClinicDepartmentService";
import { useSelector } from "react-redux";
import { organizationList } from "../../store/OrganizationSlice";
import DropDownService from "../../services/DropDownService";
import OrganizationService from "../../services/OrganizationService";
import { FieldValidationFocus } from "../../utils/cssUtils";

const AddEmployee = (props) => {
  const { Toast } = props;
  const [validation, setValidation] = useState({});
  const [clinicLocationOptions, setClinicLocationOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const { isOpen, modelTitle } = props;
  const organizationListOptions = useSelector(organizationList);
  const [companyOptions,setCompanyOption]=useState([]);
  const [clinicOptions,setClinicOptions]=useState([]);
  const [employeeOptions,setEmployeeOptions]=useState([]);
  const isMultipleOrg=props.profile.is_multiple_organization;
  const inputFields=getInputFields();
  useEffect(()=>{
    fetchCompaniesList();
    setFormData({...formData,clinic_name:'',clinic_id:'',clinic_department_id:'',...isSet(props.autoEmployeeFillData,{})});
    fetchEmployeeListOptions();
  },[formData.organization]);


  useEffect(()=>{
      fetchCompanyDetails(formData.company_id);
  },[formData.company_id]);
  // useEffect(()=>{
  // },[companyOptions,clinicLocationOptions])
useEffect(()=>{
  if (formData.clinic_id) {
    fetchClinicLocationList(formData.clinic_id)
  }
},[formData.clinic_id]);
 const fetchCompaniesList = async () => {
  let companiesOptions = [{ label: "-Select Company-", value: "" }];
  let response=[];
  if (isMultipleOrg) {
    response=await DropDownService.getCompanies(formData.organization); 
  }
  else{
    response=await DropDownService.getCompanies(null);
  }
  response&&isSet(response, []).forEach((company) => {
    companiesOptions.push({
      label: company.name,
      value: company.company_uuid,
    });
  });
  setCompanyOption(companiesOptions);
  };
  const changeHandler = async (val, fieldName,dependencyValues = {}) => {
    let updateFormData={...formData};
    const updateValidation={...validation};
    if (fieldName === "address") {
      const {addressParam}=dependencyValues;
      if (addressParam) {
        updateFormData = {
              ...updateFormData,
              street_address: addressParam.street || "",
              city: addressParam.city || "",
              state: addressParam.state || "",
              country: addressParam.country || "",
              zip_code: addressParam.zipcode || ""
          };
      }
  }
  if ((fieldName === 'dependent_id')) {
    const { phone, address,city,street_address, zip_code, state,employee_id,company_id,clinic_id} = await getDependentDetails(fieldName === 'dependent_id' ? val : '');    
    updateFormData['phone'] = phone;

    updateFormData['street_address'] = street_address;
    updateFormData['zip_code'] = zip_code;
    updateFormData['state'] = state;
    updateFormData['address']=address;
    updateFormData['city']=city;

    // updateFormData['employee_id'] = employee_id;
    updateFormData['company_id']=company_id;
    updateFormData['clinic_id']=clinic_id;
  }
  if (fieldName=='is_dependent') {
    if (val==0) {
      updateFormData['dependent_id']='';
    }
  }
    updateValidation[fieldName]='';
    updateFormData[fieldName]=val;
    setFormData(updateFormData);
    setValidation(updateValidation);
  };
  const fetchCompanyDetails = async (companyId) => {
    try {
      // let updateFormData={...formData};
      let updateValidation={...validation};
      if (companyId) {

        const data = await DropDownService.getClinicDropDownListByCompany(isSet(formData.organization,null),{company_id:companyId});
        console.log('assigned_cinics',data);
        let options=[{label:'-Select Clinic',value:''}];
        data.forEach(item => {
          options.push({label:item.name,value:item.clinic_id})
        });
        setClinicOptions(options);
      }
      // else{
      //   updateFormData['clinic_name']='';
      //   updateFormData['clinic_id']='';
      //   let clinicLocationInArr = [{ label: '-None-', value: '' }];
      //   setClinicLocationOptions(clinicLocationInArr);
      // }  
      // setFormData(updateFormData);
      setValidation(updateValidation);
       
    } catch (e) {

    }
  }
  const fetchClinicLocationList = async (clinicId) => {
    try {
      const data = await ClinicDepartmentService.getClinicDepartmentByClinicId(isSet(formData.organization,null),clinicId);
      let clinicLocationInArr = [{ label: '-Select-', value: '' }];
      isSet(data, []).forEach((item, itemIndex) => {
        clinicLocationInArr.push({
          label: item.facility_name,
          value: item.id,
          key: itemIndex
        })
      });
      setClinicLocationOptions(clinicLocationInArr);
    } catch (e) {

    }
  }
  const fetchEmployeeListOptions=async()=>{
    const response =await DropDownService.getEmployeeList(isSet(formData.organization,null));
    let options=[{label:'-Select-',value:''}]
    response&&response?.forEach((itm,index)=>{
      options.push({label:`${itm.first_name} ${itm.last_name} - ${formatPhoneNumberUS(itm.phone)}`,value:itm.employee_id});
    });
    setEmployeeOptions(options);    
  }
  const getDependentDetails=async(employee_id='')=>{
    props.Loader(true);
    const employeeDetails=await EmployeeService.getEmployee(employee_id);
    props.Loader(false);
    return {
      phone:employeeDetails.phone ||'',

      address:employeeDetails.address ||'',
      street_address:employeeDetails.street_address ||'',
      state:employeeDetails.state ||'',
      city:employeeDetails.city || '',
      zip_code:employeeDetails.zip_code ||'',

      company_id:employeeDetails.company_id,
      clinic_id:employeeDetails.clinic_id,
      employee_id:employee_id,
    }
  }

  const formSubmitHandler = async () => {
    const { Loader } = props;
    let updateValidation = {};
    //check if global admin 
    if (isSet(props.profile.is_multiple_organization, false) === true && organizationListOptions.length > 2) {
      //check organization filled if no throwing validation
      if (isSet(formData.organization, false) == false) {
        setValidation({
          ...updateValidation,
          ['organization']: 'The Organization Field is Required',
        });
        return false
      }
    }
    const payload = {
      ...formData
    }
    const formDataPayload = new FormData();
    for (const i in payload) {
      formDataPayload.append(i, payload[i]);
    }
    
    // return false;
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    try {
      Loader(true);
      const data = await EmployeeService.saveEmployee(isSet(formData.organization, null), formDataPayload, headers);
      console.log('saved emplyoleee dataaa-->',data);
      Loader(false);
      // console.log('data',data);
      if (data.status && data.status === "ERROR") {
        if (isObject(data.data)) {
          for (let key in data.data) {
            updateValidation = {
              ...updateValidation,
              [key]: data.data[key].join(","),
            };
          }
        } else {
          props.Toast.error(data.data);
        }
        if (Object.keys(updateValidation).length > 0) {
          setValidation(updateValidation);
          FieldValidationFocus(inputFields, updateValidation); 
        }
      } else {
        props.fetchEmployeeList();
        props.modalToogleHandler(false, 'ADD_VISIT', {
          ...data,
          visit_date:props.autoFillData?.visit_date,
          visit_time:props.autoFillData?.visit_time});
        Toast.success('Employee Added Successfully');
      }
    } catch (e) {
      Loader(false);

    }
  }
  // console.log('formData',formData);
  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => {
          props.modalToogleHandler(false);
          if (props.clearAutoEmployeeFillData) {
            props.clearAutoEmployeeFillData(); 
          }
        }}
        size='xl'
        dialogClassName='hResponsive'
        contentClassName='h-100'
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">
                {modelTitle}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body tab-content p-0">
              {inputFields &&
                inputFields.map((section, sectionIndex) => {
                  return (
                    <>
                      <div className="card mb-2">
                        <a
                          href={`#collapseCardExample_${sectionIndex}`}
                          className="d-block sh_accordionheader card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 className="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                        <div
                          className="collapse show"
                          id={`collapseCardExample_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div className="card-body">
                            <div className="row">
                              {section.data.map((field, fieldIndex) => {
                                const col = isSet(field.col, 'col-lg-4 col-6');
                                if (isSet(field.isShow, true) === false) { return false }
                                return (
                                  <div className={`${col} `} key={fieldIndex}>
                                    <div className={`form-group ${isSet(field.isLabelAppend, false) === true ? 'form-check form-check-solid' : ''}`} >
                                      {(isSet(field.isLabelAppend, false) === false) ?
                                        <label className="mb-0 text-sm opacity-75 text-truncate">
                                          {field.label}
                                        </label>
                                        : <></>}

                                      <FormInputs {...field} id={isSet(field.id,field.name)} value={formData[field.name]} changeHandler={(value, e) => changeHandler(value, field.name, e)} />
                                      {(isSet(field.isLabelAppend, false) === true) ?
                                        <label className="form-check-label" htmlFor={field.name}>
                                          {field.label}
                                        </label>
                                        : <></>}
                                      {isSet(field.isNotValidate) ? <></>
                                        : (isSet(validation[field.name], "") !== "" ? (
                                          <span className="text-danger m-1">
                                            {isSet(validation[field.name], "")}
                                          </span>
                                        ) : (
                                          ""
                                        ))}
                                    </div>
                                  </div>
                                )

                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              type='Single'
              className="btn btn-primary"
              clickHandler={() => formSubmitHandler()}
              label='Save'
              acl={'any'} />
            {/* <Buttons
              clickHandler={() => addFormHandler()}
              className="btn btn-outline-secondary rounded-pill"
              acl={"any"}
              label={"Cancel"}
            />
            */}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields(){
    const inputFields = [
      {
        title: 'Company and Clinic details',
        data: [
          {
            type: "SelectList",
            label: "Company",
            placeholder: "Company",
            className: "form-control form-control-solid",
            name: "company_id",
            options: companyOptions,
            value: formData.company_id,
            required: true,
          },
          {
            type: "SelectList",
            options:clinicOptions,
            label: "Clinic",
            placeholder: "Clinic",
            className: "form-control form-control-solid",
            name: "clinic_id",
            value: formData.clinic_id,
            required: true,
          },
          {
            type: "SelectList",
            label: "Clinic Department",
            placeholder: "Clinic Department",
            className: "form-control form-control-solid",
            name: "clinic_department_id",
            options: clinicLocationOptions,
            readOnly: isSet(formData.company_id, null) === null ? true : false,
            value: formData.clinic_department_id,
          },
        ]
      },
      {
        title: 'Patient Details',
        data: [
          {
            type: "Checkbox",
            label: "Is Dependent ?",
            placeholder: "",
            isLabelAppend: true,
            className: "form-check-input",
            id: 'is_dependent',
            name: "is_dependent",
            value: formData.is_dependent,
          },
           {
            type: "SelectList",
            options:employeeOptions,
            label: "Patient/Employee",
            placeholder: "Patient/Employee",
            className: "form-control form-control-solid",
            name: "dependent_id",
            value: formData.dependent_id,
            required: true,
            isShow:formData.is_dependent==1,
          },
          {
            type: "SelectList",
            options:RelationShipOptions,
            label: "Dependent Relationship",
            placeholder: "Last Name",
            className: "form-control form-control-solid",
            name: "dependent_relationship",
            value: formData.dependent_relationship,
            isShow:formData.is_dependent==1,
          },
  
          {
            type: "TextInput",
            label: "First Name",
            placeholder: "First Name",
            className: "form-control form-control-solid",
            name: "first_name",
            isFirstLetterCaps:true,
            value: formData.first_name,
            required: true,
          },
  
          {
            type: "TextInput",
            label: "Last Name",
            placeholder: "Last Name",
            className: "form-control form-control-solid",
            name: "last_name",
            value: formData.last_name,
            isFirstLetterCaps:true,
            required: true,
          },
          {
            type: "SelectList",
            label: "Gender",
            placeholder: "Gender",
            className: "form-control form-control-solid",
            name: "gender",
            options: genderOptions,
            value: formData.gender,
            required:true
          },
          {
            type: "Date",
            label: "Date of Birth",
            placeholder: "Date of Birth",
            className: "form-control form-control-solid",
            name: "dob",
            value: formData.dob,
            required: true,
            disableFuture:true
          },
          {
            type: "Age",
            label: "Age",
            placeholder: "",
            readOnly: true,
            className: "form-control form-control-solid",
            name: "dob",
            value: formData.dob,
            required: true,
            isNotValidate:true
          },
          {
            type: "Phone",
            label: "Cell Number",
            placeholder: "Cell Number",
            className: "form-control form-control-solid",
            name: "phone",
            value: formData.phone,
            required: true,
          },
          {
            type: "TextInput",
            label: "Email",
            placeholder: "Email",
            className: "form-control form-control-solid",
            name: "email",
            value: formData.email,
            required:formData.is_online_portal==1?true:false,
          },
          {
            type: "SelectList",
            label: "Identification type",
            placeholder: "Identification type",
            className: "form-control form-control-solid",
            name: "identification_type",
            options: ItentificationTypeOptions,
            value: formData.identification_type,
          },
          {
            type: "TextInput",
            label: "Identification number",
            placeholder: "Identification number",
            className: "form-control form-control-solid",
            name: "identification_number",
            value: formData.identification_number,
          },
  
  
  
          {
            type: "SelectList",
            label: "Ethnicity",
            placeholder: "Ethnicity",
            className: "form-control form-control-solid",
            name: "ethnicity",
            options: EthinicityOptions,
            value: formData.ethnicity,
          },
          {
            type: "SelectList",
            label: "Race",
            placeholder: "Race",
            options: raceOptions,
            className: "form-control form-control-solid",
            name: "race",
            value: formData.race,
          },
        ]
      }, {
        title: 'Address Details',
        data: [
          {
            type: "Address",
            label: "Address",
            placeholder: "Address",
            className: "form-control form-control-solid",
            name: "address",
            value: formData.address,
          },
          {
            type: "TextInput",
            label: "Street",
            placeholder: "street",
            className: "form-control form-control-solid",
            name: "street_address",
            value: formData.street_address,
          },
          {
            type: "TextInput",
            label: "City",
            placeholder: "City",
            className: "form-control form-control-solid",
            name: "city",
            value: formData.city,
          },
          {
            type: "TextInput",
            label: "State",
            placeholder: "State",
            className: "form-control form-control-solid",
            name: "state",
            value: formData.state,
          },
          {
            type: "ZipCode",
            label: "Zipcode",
            placeholder: "Zipcode",
            className: "form-control form-control-solid",
            name: "zip_code",
            value: formData.zip_code,
          },
        ]
      },
      {
        title: 'Other Details',
        data: [
          {
            type: "Checkbox",
            label: "Create Online Access for Patients ?",
            placeholder: "",
            isLabelAppend: true,
            className: "form-check-input",
            id: 'is_online_portal',
            name: "is_online_portal",
            value: formData.is_online_portal,
          },
          {
            type: "Checkbox",
            label: "Preferred Language: Spanish",
            placeholder: "",
            isLabelAppend: true,
            className: "form-check-input",
            id: 'is_spanish_preferred_language',
            name: "is_spanish_preferred_language",
            value: formData.is_spanish_preferred_language,
          },
          {
            type: "Blank",
            label: "",
            col:"col-lg-4 col-12",
            placeholder: "",
            // isLabelAppend:true,
          },
          {
            type: "FileUpload",
            label: "ID Picture",
            placeholder: "",
            // isLabelAppend:true,
            className: "form-control form-control-solid",
            id: 'id_picture',
            name: "id_picture",
            value: formData.id_picture,
            // required: true,
          },
          {
            type: "FileUpload",
            label: "Insurance Card (Front)",
            placeholder: "",
            // isLabelAppend:true,
            className: "form-control form-control-solid",
            id: 'insurance_card_front',
            name: "insurance_card_front",
            value: formData.insurance_card_front,
          },
          {
            type: "FileUpload",
            label: "Insurance Card (Back)",
            placeholder: "",
            // isLabelAppend:true,
            className: "form-control form-control-solid",
            id: 'insurance_card_back',
            name: "insurance_card_back",
            value: formData.insurance_card_back,
          },
         
        ]
      }
    ];
    if (isSet(props.profile.is_multiple_organization, false) === true) {
      inputFields[0].data.unshift(
        {
          type: "SelectList",
          label: "Organization",
          options:organizationListOptions,
          placeholder: "",
          className: "form-control form-control-solid",
          name: "organization",
          value: isSet(formData.organization,OrganizationService.getOrgId()),
          required:true
        }
      );
    }
    return inputFields;
  }
};

AddEmployee.defaultProps = {
  autoFillData:{visit_date:'',visit_time:''},
  autoEmployeeFillData:{}
}
export default withReduxState(WithRoutify(AddEmployee));