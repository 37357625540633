import Buttons from "../../../components/UI/Buttons";

const ComplianceMonitor = (props) => {
  return (
    <>
      <div className="sh_cardBox sh_widgets sh_dash_invoiceht sh_patientdetailwidget" >
        <div className="sh_cardHeader">
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
            <h6 className="text-md font-weight-bold mb-0">
              <span className="px-2">
                <Buttons
                  iconType='DashboardDrug'
                />
              </span>
              Employee Compliance Montior
            </h6>
          </div>
        </div>
        <div className="sh_cardBody py-0 dashboard_card">
          <div className="row ">
            <div className="col-4  font-weight-bold"><a>Employee</a></div>
            <div className="col-4  font-weight-bold"><a>Screening</a></div>
            <div className="col-4 font-weight-bold ratescount"><a>Due Date</a></div>
          </div>
          <div className="row dashstatusbg first">
            <div className="col-4 px-0 font-weight-bold"><a>
              0000</a></div>
            <div className="col-4 px-0 font-weight-bold"><a>
              --</a></div>
            <div className="col-4 font-weight-bold ratescount"><a>MM/DD/YYYY</a></div>
          </div>
          <div className="row dashstatusbg ">
            <div className="col-4 px-0 font-weight-bold"><a>
              0000</a></div>
            <div className="col-4 px-0 font-weight-bold"><a>
              --</a></div>
            <div className="col-4 font-weight-bold ratescount"><a>MM/DD/YYYY</a></div>
          </div>
        </div>
        <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
          <button className="btn dashboardbtn  btn-danger px-4"> Review All</button>

        </div>
      </div>
    </>
  );
}
export default ComplianceMonitor;