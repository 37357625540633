import React from "react";
import { Modal } from "react-bootstrap";
import FormInputs from "../../../../../../components/UI/FormInputs";
import { isSet } from "../../../../../../utils/commonUtils";
import Button from "../../../../../../components/UI/Buttons/Button";


const AddLabPanel = (props) => {
    const { inputFields, toogleHandler, submiHandler, validation, changeHandler, formData} = props;

    return (
        <Modal
            show={true}
            onHide={toogleHandler}
            keyboard={false}
            contentClassName='h-100'
            backdrop="static"
            size="md"
        >
            <div className="sh_modalBox1">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h6 className="text-lg mb-0 font-weight-bold">
                            {formData.id ? 'Edit Lab Panel' : 'Add Lab Panel'}
                        </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {inputFields.map((itm, ind) => (
                            <div className="row" key={ind}>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="text-sm text-truncate mb-0 opacity-75">{itm.label}</label>
                                            </div>
                                            <div className="col-6">
                                                <FormInputs
                                                    changeHandler={(value, e) => changeHandler(itm.name, value, e)}
                                                    value={formData[itm.name]}
                                                    {...itm}
                                                />
                                                {isSet(validation[itm.name], '') !== '' && (
                                                    <span className="text-danger m-1">
                                                        {isSet(validation[itm.name], '')}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button
                        className="btn btn-primary btn-sm text-sm"
                        acl={'any'}
                        clickHandler={() => submiHandler()}
                    >
                        {formData.id ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

export default AddLabPanel;