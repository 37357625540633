import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import WithRouter from "../../hoc/WithRouter";
import WithRoutify from "../../hoc/WithRoutify";
import Employeeervice from "../../services/EmployeeService";
import DropDownService from "../../services/DropDownService";
import { isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../UI/FormInputs";
import { FieldValidationFocus } from "../../utils/cssUtils";

const SendInviteLinkModal = (props) => {
    const { modleToogleHandler, employeeId, Toast } = props;
    const [options, setOptions] = useState([]);
    const [validation, setValidation] = useState({});
    const [formData, setFormData] = useState({
        employee_id: employeeId,
    });
    useEffect(() => {
        getClinic();
    }, []);
    const getClinic = async () => {
        const clinicList = await DropDownService.getClinicList(null);
        console.log('clinicList', clinicList);
                let options = [{ label: '-Select Clinic-', value: '' }];
                clinicList.forEach(val => {
            options.push({ label: val.name, value: val.clinic_id });
        });
        console.log('options',options);
        setOptions(options);
    }

    const inputFields = [

        {
            type: "SelectList",
            label: "Clinic",
            placeholder: "Company Name",
            className: "form-control form-control-solid",
            name: "clinic_id",
            options: options,
            value: formData.clinic_id,
            required: true
        },
        {
            type: "Tag",
            label: "Email",
            placeholder: "Patient Email",
            className: "form-control form-control-solid",
            name: "employee_email",
            tagInputType:'Email',
            options: options,
            value: formData.company_id,
            required: true
        }
    ];
    const changeHandler = (val, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: val,
        });
        setValidation({ ...validation, [fieldName]: '' });
    };
    const formSubmitHandler = async () => {
        let data = await Employeeervice.sendInvitationLinkToEmployee(formData);
        let updateValidation = {};
        if (data.status && data.status === "ERROR") {
            if (isObject(data.data)) {
                for (let key in data.data) {
                    updateValidation = {
                        ...updateValidation,
                        [key]: data.data[key].join(","),
                    };
                }
            } else {
                props.Toast.error(data.data);
            }
            if (Object.keys(updateValidation).length > 0) {
                setValidation(updateValidation);
                FieldValidationFocus(inputFields, updateValidation);
            }
        } else {
            modleToogleHandler();
            Toast.success('Email Sent to Patient Successfully');

        }
    }
    return (
        <>
            <Modal show={true} onHide={modleToogleHandler} dialogClassName='' keyboard={false}
                contentClassName='h-100'
                backdrop="static" size="md">
                <div className="sh_modalBox1">
                    <Modal.Header closeButton >
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">
                                {'Send Invitation  to Patient'}
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {inputFields &&
                                inputFields.map((field, index) => (
                                    <div className="col-12" key={index}>
                                        <div className="form-group">
                                            <label className="mb-0 text-md opacity-75"> {field.label} </label>
                                            <FormInputs  id={isSet(field.id,field.name)} {...field} changeHandler={(value, e) => changeHandler(value, field.name)} />
                                            {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="" onClick={() => formSubmitHandler()}>
                            Send Email
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default WithRouter(WithRoutify(SendInviteLinkModal));