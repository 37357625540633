import React from 'react';

const ActivityChartIcon= ({size,color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" id="report">
        <path fill={color} d="M19 4h-3V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v1H5a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Zm-9 0h4v2h-4V4Zm-2 6h5a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2Zm8 8H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-3H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Z" data-name="Layer 2"></path>
    </svg>
  );
};
ActivityChartIcon.defaultProps = {
  size: 16,
  color:'#5453D2'
};
export default ActivityChartIcon