import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getOrganizationId, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import CompanyService from "../../services/CompanyService";
import { CREATED, OK } from "../../utils/constant";
import Buttons from "../../components/UI/Buttons";
// import { isGlobalAdmin } from "../../utils/aclUtils";
// import UserService from "../../services/UserService";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import LookupService from "../../services/LookupService";
import WithRouter from "../../hoc/WithRouter";
import BasicPhysicalService from "../../services/PhysicalService";
import DropDownService from "../../services/DropDownService";
// import { useLocation, useNavigate } from "react-router-dom";

const AddPhysicalFromTemplate = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [basicPhysicalOptions,setBasicPhysical] = useState([]);
  const { isOpen, addFormHandler, modelTitle } = props;
  useEffect(()=>{
    fetchBasicPhysicalList();
  },[])
  const inputFields = [
    {
      type: "SelectSearchList",
      label: "Basic Physical",
      options: basicPhysicalOptions,
      placeholder: "",
      className: "form-control form-control-solid",
      name: "physical_id",
    },
    
    
  ];
  //clinicOptions
  const changeHandler = (fieldName,val) => {
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({ ...validation, [fieldName]: "" });
  };
  const fetchBasicPhysicalList = async ()=>{
    try{
      const response=await DropDownService.getBasicPhysicalDropdownList(null);
      let physicalInArr = [{ label: '-None-', value: '' }];
      response.forEach((item) => {
        physicalInArr.push({
                    label: item.physical_activity,
                    value: item.physical_id
                });
            });
        setBasicPhysical(physicalInArr);
    }catch(e){
    
    }
  }
  const formSubmitHandler = async () => {
    //validation add submit goes here
    const companyId = props.params.companyId;
    // console.log('formData',formData);
    // return false;
    const payload = {
        ...formData,
    }
  
    let updateValidation = { ...validation };
    await CompanyService.saveCompaniesPhysicalActivitiesFromTemplate(null,companyId,payload.physical_id)
      .then((res) => {
        if (res.code == OK || res.code == CREATED) {
          addFormHandler();
          props.fetchPhysicalList();
          addFormHandler();
          if(payload.physical_id != null){
            props.Toast.success("Physical Added Successfully");
          }       
        } else if (res.status && res.status == "ERROR") {
          if (isObject(res.data)) {
            for (let key in res.data.errors) {
              updateValidation = {
                ...updateValidation,
                [key]: res.data.errors[key].join(","),
              };
            }
          } else {
            props.Toast.error(res.data);
          }
          if (Object.keys(updateValidation).length > 0) {
            setValidation(updateValidation);
          }
        }
      })
      .catch()
      .finally();
  };
  return (
    <>
      <Modal 
        show={isOpen} 
        onHide={addFormHandler}
        size="md"
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
      >
      <div className="sh_modalBox">
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {inputFields &&
                      inputFields.map((field, index) => (
                        <div className="col-12" key={index}>
                          <div className={`form-group ${field.type==='Checkbox'?'form-check form-check-solid':''}`}>
                            {(field.type!=='Checkbox')?<label className="text-gray-900 text-md opacity-75"> {field.label} </label>:''}
                            <FormInputs {...field} changeHandler={(val)=>changeHandler(field.name,val)} value={isSet(formData[field.name],'')} />
                            {(field.type==='Checkbox')?<label className="form-check-label" htmlFor={field.name}> {field.label} </label>:''}
                            {isSet(validation[field.name],'')!=='' ? <span className="text-danger m-1">{isSet(validation[field.name],'')}</span> : ''}
                          </div>
                        </div>
                      ))}
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <div className="btn-group">
            <Buttons
              clickHandler={() => formSubmitHandler()}
              className="btn btn-primary"
              acl={"any"}
              label={"Submit"}
            />
          </div>
        </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default WithRouter(withReduxState(WithRoutify(AddPhysicalFromTemplate)));
