import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkSessionTimeout, clearSessionTimeout, resetSessionTimeout, setSessionIsActive } from '../store/CommonSlice';
import AuthService from '../services/AuthService'; 
import WithRoutify from './WithRoutify';
import { SESSION_EXPIRE_MESSAGE } from '../utils/constant';

//REACT_APP_SESSION_TIMEOUT

const SessionTimeout = ({ timeout = process.env.REACT_APP_SESSION_TIMEOUT,...props }) => {
  const dispatch = useDispatch();
  const isSessionIsActive = useSelector((state) => state.common.isSessionIsActive);

  useEffect(() => {
    const handleActivity = () => {
      if (AuthService.isAuthenticated()) {
         dispatch(setSessionIsActive(true));
        //  console.log('session....');
         dispatch(resetSessionTimeout(timeout));
      }
    };

    const events = ['mousemove', 'keydown', 'mousedown', 'click', 'touchstart'];

    if (AuthService.isAuthenticated()) {
      events.forEach(event => window.addEventListener(event, handleActivity));
      dispatch(resetSessionTimeout(timeout));
    }
    dispatch(checkSessionTimeout());
    return () => {
      dispatch(clearSessionTimeout());
      events.forEach(event => window.removeEventListener(event, handleActivity));
    };
  }, [dispatch, timeout]);

  useEffect(() => {
    if (!isSessionIsActive) {
      props.Toast.error((SESSION_EXPIRE_MESSAGE));
      AuthService.sessionExpired();
      props.navigate('/login');
    }
  }, [isSessionIsActive,props.Toast,props.navigate]);

  return null; // This component does not render anything
};

export default WithRoutify(SessionTimeout);
