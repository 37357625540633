import React from "react";
import WeekGrid from "./WeekGrid";

const MonthViewBody = (props)=>{
    const {calendarMonth} = props;
    return(
        <tbody className="fc-body">
            <tr>
                <td className="fc-widget-content">
                    <div className="fc-scroller fc-day-grid-container" style={{"height":"709.703px"}}>
                        <div className="fc-day-grid">
                        {calendarMonth.map((weekItem,weekItemIndex)=>{
                            return(
                                <WeekGrid 
                                    {...props}
                                    listItem={weekItem}
                                    key={weekItemIndex}
                                    indexKey={weekItemIndex} 
                                />
                            )
                        })}
                       
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default MonthViewBody;