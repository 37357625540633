import React, { useEffect, useState } from "react";
import {MONTHS,WEEKDAYS_SHORT}  from '../../../constants';
import {tConv24,everyNminutes, rearrangeDataForWeekView, calendarWidgetVisitTypeBasedColor} from '../../../calendarFunctions'; 
import { isSet } from "../../../../../utils/commonUtils"; 
import Wrap from "../../../../../hoc/Wrap";
import { use } from "i18next";

const DayViewBody = (props)=>{
	const {timeInterval,showMoreDetailsDataHandler,getDateRecords,params} = props;
	const [maxSlotDisplay,setMaxSlotDisplay]  = useState(3);
	// const {isShowActivities} = tableRecords;
	const calendarWeeksRecords = rearrangeDataForWeekView(props.calendarRecords);
	useEffect(()=>{
		const updateMaxSlotDisplay = () => {
			const calendarDiv = document.getElementById('calendar');
			if (calendarDiv) {
			  const calendarRect = calendarDiv.getBoundingClientRect();
			  const { width } = calendarRect;
			  setMaxSlotDisplay(Math.floor(width / 180));
			  console.log('calendarRect', Math.floor(width / 180));
			}
		  };
	  
		  // Initial calculation
		  updateMaxSlotDisplay();
	  
		  // Add event listener for resize
		  window.addEventListener('resize', updateMaxSlotDisplay);
	  
		  // Cleanup event listener on component unmount
		  return () => {
			window.removeEventListener('resize', updateMaxSlotDisplay);
		  };
	},[])
	return(
		<tbody className="fc-body">
            <tr>
                <td className="fc-widget-content">
                   
                    <div className="fc-scroller fc-time-grid-container" /* style={{height:'653.109px'}} */  >
                        <div className="fc-time-grid">
                            <div className="fc-bg">
                                  <table className="">
                                     <tbody>
                                        <tr>
                                           <td className="fc-axis fc-widget-content" style={{width:'46.7188px'}}></td>
                                           <td className="fc-day fc-widget-content fc-sun fc-past" data-date={props.currentDate}></td>
                                        </tr>
                                     </tbody>
                                  </table>
                            </div>  
                            <div className="fc-slats">
                                <table className="">
                                    <tbody>
                                        {
											timeInterval.map((time,timeIndex)=>{
												const timeIn12 = tConv24(time);
												return(
	                                                <Wrap key={timeIndex}>
	                                                    <tr data-time="00:00:00">
	                                                        <td className="fc-axis fc-time fc-widget-content" style={{width:'46.7188px'}}><span>{tConv24(time)}</span></td>
	                                                        <td className="fc-widget-content sh-dayView"  onClick={()=>props.addVisitToogleHandler({visit_date:props.currentDate,visit_time:timeIn12})}>
	                                                        	{
						                                          props.currentDayDetails.map((dateItem,ind)=>{
						                                             if(calendarWeeksRecords){
																		const timeIn12 = tConv24(time);
																		// console.log('dateItem.c_date',calendarWeeksRecords,dateItem.c_date,timeIn12)
																		const itemData = isSet(calendarWeeksRecords?.[dateItem.c_date]?.[timeIn12], []);
																		
						                                                return(
						                                                   <>
						                                                      {
						                                                         isSet(itemData,[]).map((item,ind)=>{
						                                                            let getHour = item.getHour;
						                                                            if((ind+1)>maxSlotDisplay){
						                                                                  return false;
						                                                            } 
																					const typeBasedClass = calendarWidgetVisitTypeBasedColor(item.visit_category);
						                                                            return(
						                                                               <>
						                                                                 
																						 <a onClick={(e)=>{e.stopPropagation(); props.showQuickVisitView(e,item)}}>
																							<div className={`mainValueTag ${typeBasedClass}`} style={{width:'160px'}} data-toggle="dropdown" title={`${item.visit_time} : ${item.employee.first_name} ${item.employee.last_name}`}>
																								
																								<>
																									<span className="text-sm font-weight-bold">{item.visit_time}</span>
																									<span className="font-weight-bold"> : </span>
																									<span className="text-sm">{item.employee.first_name} {item.employee.last_name}</span>
																									
																								</> 
																																																			
																							</div> 
																							</a>     
						                                                                  {
						                                                                    ((ind+1)===maxSlotDisplay && itemData.length>maxSlotDisplay)?
						                                                                    <a className="text-danger textUnderline text-small quick-sidebar-toggler cursor-pointer" style={{'display':'block','text-align':'right'}} onClick={(e)=>showMoreDetailsDataHandler(e,{date:dateItem.c_date,time:timeIn12},isSet(itemData,[]))}> + More ... </a>
						                                                                    :
						                                                                    <></>   
						                                                                  }
						                                                               </>
						                                                            )                                                            
						                                                         })                                                      
						                                                      }
						                                                   </>  
						                                                )
						                                             }else{
						                                                return(
						                                                   <></>  
						                                                ) 
						                                             }
						                                             
						                                          })
						                                        }
	                                                        </td>
	                                                    </tr>
	                                                    <tr data-time={timeIn12} id={`calendarRow_${(time.toString().replace(':',''))}`} className="fc-minor" onClick={()=>props.addVisitToogleHandler({visit_date:props.currentDate,visit_time:timeIn12})}>
	                                                        <td className="fc-axis fc-time fc-widget-content" style={{width:'46.7188px'}}></td>
	                                                        <td className="fc-widget-content"></td>
	                                                    </tr>
	                                                </Wrap>
	                                            )
											})
										}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
	)
}

export default DayViewBody;;