import React, { useCallback, useState } from "react";

const Pagination = React.memo((props) => {
  const { pagination, fetchTableRecords } = props;
  const {data}=props.tableRows
  const totalPages = Math.ceil(pagination.totalRecords / pagination.perPage);

  //scroll to top when prev or next click
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  const [PerPageData,setPerPagedata]=useState([
    { label: "10 per page", value: 10, selected: false },
    { label: "25 per page", value: 25, selected: true },
    { label: "50 per page", value: 50, selected: false },
    { label: "100 per page", value: 100, selected: false },
  ]);

  // Memoized function using useCallback
  const handlePaginationChange = useCallback((pageChange) => {
    // If to prevent pagination within pages 
    if (!(pagination.page + pageChange > totalPages) && pagination.page + pageChange >= 1) {
      const newPagination = { ...pagination, page: pagination.page + pageChange };
      fetchTableRecords(newPagination);
    }
  }, [pagination, fetchTableRecords, totalPages]);



  const perPageDataChangeHandler = useCallback((value) => {
    // If to prevent unwanted call if the same value
    if (pagination.perPage !== value) {
      const newPagination = { ...pagination, perPage: value, page: 1 };
      fetchTableRecords(newPagination);
      //update seleted perPage for css change
      let updatedPerPageData=PerPageData.map(item=>{
        if (item.value==value) {
          item.selected=true
        }
        else{
          item.selected=false
        }
        return item;
      }
      );
      setPerPagedata(updatedPerPageData)
    }
  }, [pagination, fetchTableRecords]);
  
  return (
    <>
    {
      data&&data.length>0?
      <div className="sh_cardFooter sh_pagination">
      <div className="dropdown">
        {/* Dropdown button */}
        <button className="btn bg-white input-group-text mr-2 dropdown-toggle text-sm" type="button" id="perPageDropdown" data-bs-toggle="dropdown">
          {pagination.perPage} per page
        </button>
        {/* Dropdown menu */}
        <div className="dropdown-menu text-sm" aria-labelledby="perPageDropdown">
          {PerPageData.map((itm) => (
            // Dropdown items
            <span key={itm.value} className={`dropdown-item ${itm.selected ? 'selected' : ''}`} onClick={() => perPageDataChangeHandler(itm.value)}>
              <div className="form-check form-check-solid">
                <label className="form-check-label">{itm.label}
                </label>
              </div>
            </span>
          ))}
        </div>
      </div>

      <div className="d-flex align-items-center">
        {/* Pagination information */}
        <span className="input-group-text mr-2 text-sm bg-white">
          {/* {pagination?.page} - {pagination?.perPage} of {pagination.totalRecords} */}
          {pagination && (
          <>
          {pagination.page * pagination.perPage - pagination.perPage + 1} -{' '}
          {Math.min(
            pagination.page * pagination.perPage,
            pagination.totalRecords
            )}{' '}
            of {pagination.totalRecords}
            </>
            )}
        </span>
        {/* Pagination buttons */}
        <div className="btn-group rounded-pill">
          {/* Previous button */}
          <button
            type="button"
            className={`btn btn-outline-light btn-xs`}
            style={{ cursor: pagination.page <= 1 ? 'not-allowed' : 'pointer' }}
            onClick={() => handlePaginationChange(-1)}
          >
            <i className="fa fa-angle-left" aria-hidden="true"></i>
          </button>
          {/* Next button */}
          <button
            type="button"
            className={`btn btn-outline-light btn-xs `}
            style={{ cursor: pagination.page >= totalPages ? 'not-allowed' : 'pointer' }}
            onClick={() => handlePaginationChange(1)}
          >
            <i className="fa fa-angle-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
      :
      ''
    }
    
    </>
  );
});

export default Pagination;
