import Buttons from "../../../components/UI/Buttons";
import SectionLoader from "../../../components/UI/SectionLoader";
import { encryptString } from "../../../utils/commonUtils";

const Physical = (props) => {
    const { data,filter,isLoading} = props;
    const { physical } = data;
    const {company_id,clinic_id}=filter;
    const filterParams=encryptString({
        visit_category:'Employer Services - Physicals',
        company_id:company_id || '',
        clinic_id:clinic_id || '',
      });
    return (
        <>
            
            <div className="sh_cardBox sh_widgets sh_patientdetailwidget" >
                    <div className="sh_cardHeader">
                        <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                            <h6 className="text-md font-weight-bold mb-0">
                                <span className="px-2">
                                    <Buttons
                                        iconType='DashboardPhysical'
                                    />
                                </span>
                                Physicals
                            </h6>
                        </div>
                    </div>
                    <div className="sh_cardBody py-0 dashboard_card">
                    <SectionLoader isLoading={isLoading}/>
                        <div className="row ">
                            <div className="col-8  font-weight-bold"><a>Status</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>Count</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                            <div className="col-8 px-0 font-weight-bold"><a>
                                <Buttons
                                    iconType="bluecircle"
                                    className="px-2"
                                />
                                Pending</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>{physical?.pending || 0}</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                            <div className="col-8 px-0 font-weight-bold"><a>
                                <Buttons
                                    iconType="yellowcircle"
                                    className="px-2"
                                />
                                Completed</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>{physical?.completed || 0}</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                            <div className="col-8 px-0 font-weight-bold"><a>
                                <Buttons
                                    iconType="greencircle"
                                    className="px-2"
                                />
                                Passed</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>{physical?.passed || 0}</a></div>
                        </div>
                        <div className="row dashstatusbg">
                            <div className="col-8 px-0 font-weight-bold"><a>
                                <Buttons
                                    iconType="redcircle"
                                    className="px-2"
                                />
                                Failed</a></div>
                            <div className="col-4 font-weight-bold ratescount"><a>{physical?.failed || 0}</a></div>
                        </div>
                    </div>
                    <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                        <Buttons className="btn dashboardbtn  btn-info px-4" href={`/result/physical-drug-screens/${filterParams}`} acl={'any'} >
                            Review All
                        </Buttons>
                    </div>
            </div>

 
        </>
    );
}
export default Physical;