import React, { useEffect, useState } from "react";
import Buttons from "../../../../../components/UI/Buttons";
import VisitsService from "../../../../../services/VisitsService";

const StatusBar = (props) => {
    const {visitDetails}=props;    
    const {visit_id,overall_status}=visitDetails;
    const [timeLineData,setTimeLineData]=useState([]);
    const defaultIcon="greycircle";
    const completedIcon="green";
    const failedIcon="red";
    const pendingIcon="bluecircle";

    useEffect(()=>{
        fetchTimeLine();
    },[overall_status]);

    //fetch time line status
    const fetchTimeLine=async()=>{
        const response=await VisitsService.getVisitChartTimeLine(visit_id);
        // console.log('StatusBar',response);
        setTimeLineData(response);
    };
    //sts obj prepare
    const statusData = [
        {
            title: "Arrival",
            date: timeLineData.arrival_date,
            time: timeLineData.arrival_time,
            progress:timeLineData.arrival_progress,
            button: {
                title: "Arrival",
                iconType: timeLineData.arrival_progress==100?completedIcon:defaultIcon
            }
        },
        {
            title: "Started",
            date: timeLineData.started_date,
            time: timeLineData.started_time,
            progress:timeLineData.started_progress,
            button: {
                title: "Started",
               iconType: timeLineData.started_progress==100?completedIcon:defaultIcon
            }
        },
        {
            title: "Pending Results",
            date:timeLineData.pending_date,
            time: timeLineData.pending_time,
            progress:timeLineData.pending_progress,
            button: {
                title: "Pending",
                iconType: timeLineData.pending_progress==100?completedIcon:timeLineData.overall_status=="Pending"?pendingIcon:defaultIcon,
            }
        },
        {
            
            title: "Pass / Fail",
            date:"",
            time: "",
            progress:timeLineData.delivered_progress,
            button: {
                title: "Completed",
                iconType:timeLineData.delivered_progress==100?timeLineData.overall_status=="Passed"?completedIcon:failedIcon:defaultIcon,
            }
        },
        {
            title: "Delivered",
            date:timeLineData.delivered_date,
            time: timeLineData.delivered_time,
            progress:timeLineData.delivered_progress,
            button: {
                title: "Delivered",
                iconType: timeLineData.delivered_progress==100?completedIcon:defaultIcon
            }
        }
    ];

    return (
        <div className="d-flex statusbar-timeline">
            {statusData.map((status, index) => (
                <div className="statuscard" key={index}>
                    <div className="position-relative d-flex" style={{width:'100%',alignItems:'center'}}>
                        <div className="" >
                            <Buttons title={status.button.title} iconType={status.button.iconType} acl={"any"} />
                        </div>
                        <div className="progress status-timeline">
                            <div className="progress-bar" style={{ width: `${status?.progress ||0}%` }}></div>
                        </div>

                    </div>
                    <div className="text-center statusbar-timeline-list" >
                        <p className="mb-0">{status.title}</p>
                        <div className="statuscard-date">{status.date}</div>
                        <div className="statuscard-date">{status.time}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default StatusBar;
