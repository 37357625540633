import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Buttons from "../../../components/UI/Buttons";
import CompanySplitedFields from "./CompanySplitFields";
import { isObject, isSet, TaxIdFormatter } from "../../../utils/commonUtils";
import { getCurrentStepInputFields } from "./companyFields";
import WithRoutify from "../../../hoc/WithRoutify";
import withReduxState from "../../../hoc/wReduxState";
import { useSelector } from "react-redux";
import { organizationList } from "../../../store/OrganizationSlice";
import DropDownService from "../../../services/DropDownService";
import LookupService from "../../../services/LookupService";
import { WORK_COMP_INSURANCE } from "../../../utils/constant";
import CompanyService from "../../../services/CompanyService";
import ClinicService from "../../../services/ClinicService";
import { FieldValidationFocus } from "../../../utils/cssUtils";

const AddCompanyWizard = (props) => {
    const { addFormHandler, fetchTableRecords, Loader, Toast } = props
    const steps = [
        { label: "Company Details", key: 'company_details', last_page: false },
        { label: "Company ID & Insurance", key: 'id_insurance', last_page: false },
        { label: "Notifications", key: 'notifications', last_page: false },
        { label: "Other Details", key: 'others_details', last_page: true },
    ];
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [validation, setValidation] = useState({});

    // Options for dropdowns
    const is_multiple_organization = props.profile.is_multiple_organization;
    const organizationListOptions = useSelector(organizationList);
    const [clinicOptions, setClinicOptions] = useState([]);
    const [salesRepOptions, setSalesRepOptions] = useState([]);
    const [workCompInsuranceOptions, setWorkCompInsuranceOptions] = useState([]);
    const stateList = useSelector((state) => state.session.profileStateList);
    const [isCopyFromCompanyChecked, setIsCopyFromCompanyChecked] = useState(false);

    // Input fields
    const fieldArgs = { currentStep, organizationListOptions, is_multiple_organization, clinicOptions, stateList, workCompInsuranceOptions, isCopyFromCompanyChecked, salesRepOptions }
    const inputFields = getCurrentStepInputFields(fieldArgs);
    //

    // Fetch options based on org
    useEffect(() => {
        fetchClinic();
        fetchSalesRepOptions();
        fetchWorkCompInsuranceOptions();
    }, [formData.organization]);
    //fetch clinic
    const fetchClinic = async () => {
        let opt = [{ label: "ALL", value: "ALL" }];
        let clinicResponse = await DropDownService.getClinicList(isSet(formData.organization, null));
        if (clinicResponse) {
            clinicResponse?.forEach((itm, ind) => {
                opt.push({ label: itm.name, value: itm.clinic_id });
            });
        }
        setClinicOptions(opt);
    }
    const fetchWorkCompInsuranceOptions = async () => {
        let opt = [{ label: "-Select-", value: "" }]
        let response = await DropDownService.getWorkCompDropDown();
        if (response) {
            response?.forEach((itm, ind) => {
                opt.push({ label: itm.name, value: itm.id });
            });
        }
        setWorkCompInsuranceOptions(opt);
    }
    const fetchSalesRepOptions = async () => {
        let opt = [{ label: "-Select-", value: "" }]
        const response = await ClinicService.getSalesRep(isSet(formData.organization, null));
        console.log(response);
        if (response) {
            response.forEach((itm) => {
                opt.push({ label: itm.sales_rep, value: itm.sales_rep });
            });
        }
        setSalesRepOptions(opt);
    }
    // Handle modal close
    const handleClose = () => {
        addFormHandler();
    };

    // Handle next step
    const handleNextStep = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
    };
    // Handle previous step
    const handlePreviousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };
    const changeHandler = async (value, fieldName, { addressParam = {}, selectVal }) => {
        let updatedFormData = { ...formData };
        let updateValidation = { ...validation };

        // Handle the checkbox change
        if (fieldName === 'copy_from_company') {
            if (isSet(formData.address, false) == false) {
                setValidation({
                    ...updateValidation,
                    ["copy_from_company"]: "Company Address Required to Copy Address",
                });
                return false;
            }

            console.log(value);
            if (value === 1) {
                updatedFormData["accounts_payable_address"] = isSet(formData.address, "");
                updateValidation["accounts_payable_address"] = "";
                updatedFormData["accounts_payable_city"] = isSet(formData.city, "");
                updateValidation["accounts_payable_city"] = "";
                updatedFormData["accounts_payable_state"] = isSet(formData.state, "");
                updateValidation["accounts_payable_state"] = "";
                updatedFormData["accounts_payable_postal_code"] = isSet(formData.postal_code, "");
                updateValidation["accounts_payable_postal_code"] = "";
                updatedFormData["accounts_payable_contact_name"] = isSet(formData.name, "");
                updateValidation["accounts_payable_contact_name"] = "";
                updatedFormData["accounts_payable_contact_phone"] = isSet(formData.phone, "");
                updateValidation["accounts_payable_contact_phone"] = "";
            } else {
                updatedFormData["accounts_payable_address"] = "";
                updatedFormData["accounts_payable_city"] = "";
                updatedFormData["accounts_payable_state"] = "";
                updatedFormData["accounts_payable_postal_code"] = "";
                updatedFormData["accounts_payable_contact_name"] = "";
                updatedFormData["accounts_payable_contact_phone"] = "";
            }
            setIsCopyFromCompanyChecked(!isCopyFromCompanyChecked);
        }

        // Handle other field changes
        if (fieldName === "address") {
            if (addressParam.street) {
                updatedFormData["address"] = addressParam.street;
                updateValidation["address"] = "";
            }
            if (addressParam.state) {
                updatedFormData["state"] = addressParam.state;
                updateValidation["state"] = "";
            }
            if (addressParam.zipcode) {
                updatedFormData["postal_code"] = addressParam.zipcode;
                updateValidation["postal_code"] = "";
            }
            if (addressParam.city) {
                updatedFormData["city"] = addressParam.city;
                updateValidation["city"] = "";
            }
            if (addressParam.county) {
                updatedFormData["county"] = addressParam.county;
                updateValidation["county"] = "";
            }
        }
        //acounts payable address
        if (fieldName == "accounts_payable_address") {
            if (isSet(addressParam.street, false)) {
                updatedFormData["accounts_payable_street"] = addressParam.street;
                updateValidation["accounts_payable_street"] = "";
            }
            if (isSet(addressParam.state, false)) {
                updatedFormData["accounts_payable_state"] = addressParam.state;
                updateValidation["accounts_payable_state"] = "";
            }
            if (isSet(addressParam.zipcode, false)) {
                updatedFormData["accounts_payable_postal_code"] = addressParam.zipcode;
                updateValidation["accounts_payable_postal_code"] = "";
            }
            if (isSet(addressParam.city, false)) {
                updatedFormData["accounts_payable_city"] = addressParam.city;
                updateValidation["accounts_payable_city"] = "";
            }
        }


        if (fieldName === 'clinic_id') {
            value = handleClinicSelection(value, selectVal, formData, clinicOptions);
        }

        if (fieldName === "tax_id") {
            value = TaxIdFormatter(value);
        }

        updatedFormData[fieldName] = value;
        updateValidation[fieldName] = '';
        setFormData(updatedFormData);
        setValidation(updateValidation);
    };

    // Handle form submit
    const formSubmitHandler = async () => {
        let current_validation = steps.find((step, ind) => currentStep === ind + 1);
        console.log('current_step', current_validation);

        let updateValidation = {};
        let payload = {
            ...formData,
            step_validation: true,
            current_step: current_validation.key,
            last_page: current_validation.last_page,
        }
        //check if global admin 
        if (isSet(props.profile.is_multiple_organization, false) === true && organizationListOptions.length > 2) {
            //check organization filled if no throwing validation
            if (isSet(formData.organization, false) == false) {
                setValidation({
                    ...updateValidation,
                    ['organization']: 'The Organization Field is Required',
                });
                return false
            }
        }
        Loader(true);
        let res = await CompanyService.saveCompany(isSet(formData.organization, null), payload);
        Loader(false);
        if (res.status && res.status == "ERROR") {
            if (isObject(res.data)) {
                for (let key in res.data) {
                    updateValidation = {
                        ...updateValidation,
                        [key]: res.data[key].join(","),
                    };
                }
            } else {
                props.Toast.error(res.data);
            }
            if (Object.keys(updateValidation).length > 0) {
                setValidation(updateValidation);
                FieldValidationFocus(inputFields, updateValidation);
            }
            return;
        }
        if (current_validation.last_page) {
            addFormHandler();
            fetchTableRecords();
            let message = `Company Added Successfully`;
            Toast.success(message);
        }
        else {
            handleNextStep();
        }


    }
    return (
        <>
            <Modal
                show={true}
                size="xl"
                dialogClassName="hResponsive "
                contentClassName="h-100"
                keyboard={false}
            >
                <div className='sh_modalBox'>
                    <Modal.Header>
                        <Modal.Title style={{ margin: '0 auto' }}>
                            <div className="formbold-steps">
                                <ul className="justify-content-center">
                                    {steps.map((step, index) => (
                                        <li key={index} className={`formbold-step-menu${index + 1} ${currentStep === index + 1 ? 'active' : ''}`}>
                                            <span>{index + 1}</span>
                                            <h6 className="text-lg mb-0">{step.label}</h6>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Modal.Title>
                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <CompanySplitedFields
                                inputFields={inputFields}
                                formData={formData}
                                validation={validation}
                                changeHandler={changeHandler}
                            />
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                        <Buttons
                            acl={"any"}
                            clickHandler={handlePreviousStep}
                            className="formbold-btn btn btn-secondary"
                            label="Previous"
                            disabled={currentStep === 1}
                        />
                        <Buttons
                            acl={"any"}

                            clickHandler={formSubmitHandler}
                            className="formbold-btn btn btn-primary btn-user"
                            label={currentStep === steps.length ? "Submit" : "Next"}
                        />
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
    function handleClinicSelection(value, selectVal, formData, clinicOptions) {
        console.log('value', value, 'selectVal', selectVal);
        let fieldName = 'clinic_id';
        if (value) {
            if (selectVal === 'ALL') {
                value = 'ALL';
            } else {
                let data = formData[fieldName]?.split(',') || [];
                if (!data.includes(selectVal)) {
                    data.push(selectVal);
                }
                value = data.join(',');
            }
        }
        else {
            if (formData[fieldName] === 'ALL') {
                if (selectVal != 'ALL') {
                    let removeClinics = clinicOptions
                        .filter(fil => fil.value !== selectVal && fil.value !== 'ALL')
                        .map(item => item.value);

                    console.log('removeClinics', removeClinics);
                    value = removeClinics.join(',');
                }
                else {
                    value = '';
                }

            } else {
                let data = formData[fieldName].split(',') || [];
                let index = data.indexOf(selectVal);

                if (index !== -1) {
                    data.splice(index, 1);
                }
                value = data.join(',');
            }
        }

        if (value) {
            let lengthCheck = value.split(',').filter(fil => fil != '');
            console.log('lengthCheck', lengthCheck, lengthCheck.length, value);

            if (clinicOptions.length - 1 === lengthCheck.length) {
                value = 'ALL';
            }
        }
        return value;
    }
}

export default withReduxState(WithRoutify(AddCompanyWizard));
