
import {  isSet } from "../../../../../../utils/commonUtils";

export const ScreeningBoxLifting = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[{
                label:'Weighted Lift and Review of proper Body Mechanics with the  following:',
                col:'col-12',
                type:'CheckBoxGroup',
                name:'weightList',
                id:'weightList',
                options:[{label:' Floor to waist x 3',name:'floor_to_waist'},{label:' Push / Pull x 3',name:'push_pull'},{label:' Waist level carry with pivot',name:'waist_level_carry'},{label:'Waist to shoulder x 3',name:'waist_to_shoulder'}],
            }
        ]
        },{
            label:'Employee has demonstrated properly lifting technique with weight requirements',
            fields:[{
                label:'Recommendations',
                col:'col-12',
                type:'RadioGroup',
                name:'recommendations',
                id:'recommendations',
                options:['No Restrictions','Restrictions'],
            },
           
            {
                label:'Notes',
                col:'col-6',
                type:'TextArea',
                name:'notes',
            },
            {
                label:'Status',
                col:'col-6',
                type:'SelectList',
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'status',
            }
        ]
        },]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
  return {};
}