import { Route, Routes } from 'react-router-dom';
import Wrap from '../../hoc/Wrap';
import PatientForm from './PatientForm';

const PatientFormContainer = () => {

  return (
    <Wrap>
      <Routes>
        <Route path="/" key={0} element={<PatientForm />} />
        <Route path="/patient_form" key={1} element={<PatientForm />} />
      </Routes>
    </Wrap>
  );
};






export default PatientFormContainer;