import React, { useState, useEffect } from 'react';
import { isObject, isSet } from '../../utils/commonUtils';
import FormInputs from '../../components/UI/FormInputs';
import AuthService from '../../services/AuthService';
import { _t } from '../../utils/i18nUtils';
import Buttons from '../../components/UI/Buttons';

const StepThree = (props) => {
  const { changeHandler, formData, validation,isVisible,validatePhoneNumber,setValidation , clearOtp } = props;

  const [timer, setTimer] = useState(0);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isPhoneEditable, setIsPhoneEditable] = useState(false); // Start with the email field editable
  const [isReceiveButtonVisible, setIsReceiveButtonVisible] = useState(true);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && isOTPSent) {
      setIsOTPSent(false);
      clearOtp();
    }
    return () => clearInterval(interval);
  }, [timer, isOTPSent]);

  const handleReceiveOTP = async() => {
      const response=await AuthService.sendVerificationCodeForSelfRegistration(formData.phone,formData.employee_id);
      let updateValidation={...validation};
      if (response.status && response.status === "ERROR") {
        if (isObject(response.data)) {
            for (let key in response.data) {
                updateValidation = {
                    ...updateValidation,
                    [key]: response.data[key].join(","),
                };
            }
        } else {
            props.Toast.error(response.data);
        }
        if (Object.keys(updateValidation).length > 0) {
            setValidation(updateValidation);
            return;
        }
    }
      setTimer(900); // Set timer for seconds
      setIsOTPSent(true);
      setIsReceiveButtonVisible(false);
      setIsPhoneEditable(false); 
  };

  const handleChangeNumber = () => {
    setIsPhoneEditable(true);
    setIsOTPSent(false);
    setIsReceiveButtonVisible(true);
    setTimer(0);
  };

  const handleResendOTP = async() => {
    handleReceiveOTP();
  };

  const inputFields = [
    {
      type: "Phone",
      label: _t('CellPhone'),
      placeholder: _t('CellPhone'),
      className: "form-control form-control-solid",
      name: "phone",
      disabled: (!isPhoneEditable),
      col: "col-md-6 col-12",
      hidden: isOTPSent,
      required: true
    },
    // {
    //   type: "",
    //   label: "Email",
    //   placeholder: "Email",
    //   className: "form-control form-control-solid",
    //   name: "email",
    //   disabled: (!isPhoneEditable),
    //   col: "col-md-6 col-12",
    //   hidden: isOTPSent,
    //   required: true
    // },
    {
      type: "TextInput",
      label: "Verification Code",
      placeholder: "Enter Verification Code",
      className: "form-control form-control-solid",
      name: "otp",
      col: "col-md-7 col-12",
      hidden: !isOTPSent // Hide OTP field if OTP not sent yet
    },
  ];

  return (
    <div className={`formbold-form-step-2 ${isVisible? 'active' : ''}`} style={{ textAlign: 'center' }}>
      <h6 class="text-md font-weight-bold mb-3 " style={{ textAlign: 'start' }}>
        <span class="pr-2 selflogin-icon ">
        <Buttons
        iconType="Verification"
        />
        </span>Verification</h6>
      <div className="row justify-content-center">
        {inputFields.map((field, index) => (
          <div className={field.col} >
            <div className="form-group">
              {/* {field.label === 'OTP' && isOTPSent ? <label className="text-sm text-truncate mb-0 opacity-75"> {field.label} </label> : field.label === 'OTP' ? } */}
              {isOTPSent ? (field.label === 'Verification Code' ? <label className="text-sm text-truncate mb-0 opacity-75" > {field.label} </label> : <></>) : (field.label !== 'Verification Code' ? <label className="text-sm text-truncate mb-0 opacity-75"> {field.label} </label> : <></>)}

              <FormInputs
                changeHandler={(value, e) => changeHandler(value, field.name, e)}
                value={formData?.[field.name]}
                hidden={field.hidden}
                {...field}
              />
              {isSet(validation[field.name], '') !== '' &&
                <span className="text-danger m-1">{isSet(validation[field.name], '')}</span>
              }
            </div>
          </div>
        ))}
      </div>

      {isOTPSent && timer > 0 && (
        <div>
          <p>Verification Code is valid for {timer} seconds.</p>
        </div>
      )}

      {isReceiveButtonVisible ? (
        <>
          <button onClick={handleReceiveOTP} className="btn btn-success" style={{ marginRight: '10px' }}>Receive Verification Code</button>
          <button onClick={handleChangeNumber} className="btn btn-warning">Change Number</button>

        </>

      ) : (
        <>
          {timer === 0 ? (
            <>
              <button onClick={handleResendOTP} className="btn btn-danger" style={{ marginRight: '10px' }}>Resend Verification Code</button>
              <button onClick={handleChangeNumber} className="btn btn-warning">Change Number</button>
            </>

          ) : null}
        </>
      )}


    </div>
  );
};

export default StepThree;
