import React, { Component } from "react";
import TableGrid from "../../components/UI/TableGrid";
import { capitalizeName, toQueryString, USDateTimeString } from "../../utils/commonUtils";
import InsuranceService from "../../services/InsuranceService";
import WithRoutify from "../../hoc/WithRoutify";
import PatientFormService from "../../services/PatientFormService";
import { fetchNotificationStatus } from "../../store/NotificationSlice";
import { connect } from "react-redux";
import WithRouter from "../../hoc/WithRouter";

class PatientFormList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      {
        label: "Form Status",
        key: "form_status",
        type: "",
        inputType: "TextInput",
      },
      { label: "Visit Category ", key: "visit_category" },
      { label: "Created By", key: "created_by" },
      { label: "Created At", key: "created_at" },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "insurance_name",
          sort_order: "asc",
          search: {},
        },
      },
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
    };
  }

  componentDidMount() {
    this.patientFormList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen,activeEditId} = this.state;
    return (
      <>
  
    
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0">Patient Form List</h6>

                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div class="col-12 px-1 d-flex">
                      <div class="input-group input-group-joined input-group-solid">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
             
                {...tableRecords}
              />
            </div>
          </div>
      </>
    );
  }
  submitFiltersHandler = () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    };
    this.fetchInsuranceList(queryfilters);
  };
  clearFiltersHandler = () => {
    this.setState(
      {
        filters: {},
      },
      this.submitFiltersHandler
    );
  };
  patientFormList  = async (query = {}) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    this.props.Loader(true);
    PatientFormService.getAllPatinetForm(queryString)
      .then((res) => {
        console.log('res',res);
        let responseData = res.data;
        let tableData = [];
        responseData.map((form, index) => {
          tableData = [
            ...tableData,
            {
              index: index,
              isHeading: false,
              rowId: form.patient_form_id,
              data: {
                isActive: false,
                form_status:{
                  title:form.form_status,
                  value:<span class={`badge bg-${form.form_status=='Pending'?'blue':'green'}-soft text-${form.form_status=='Pending'?'blue':'green'}`}>{capitalizeName(form.form_status.toLowerCase())}</span>
                },
                visit_category: form?.visit_category||'--',
                created_at:USDateTimeString(form.created_at),
                created_by:form.created_by?form.created_by?.first_name + form.created_by?.last_name:'--',
                active: "Yes",
                action: [
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Search",
                    title: "View",
                    acl: "any",
                    href:`patient_form?patient_form_id=${form.patient_form_id}`,
                    clickHandler: (rowId, data) =>
                      this.editHandler(
                        rowId,
                        data,
                      ),
                  },
                ],
              },
            },
          ];
        });
        console.log('tableData tableData',tableData);
        //setState
        this.setState((prevState) => ({
          tableRecords: {
            ...prevState.tableRecords,
            tableHeaders: prevState.tableRecords.tableHeaders,
            tableRows: {
              type: "default",
              data: tableData,
              status: 200,
            },
          },
        }));
      })
      .catch((err) => {
        this.setState({
          tableRecords: {
            ...tableRecords,
            tableRows: {
              ...tableRecords.tableRows,
              status: 200,
            },
          },
        });
      })
      .finally(() => {
        this.props.Loader(false);
        this.props.fetchNotificationStatus();//update top notification count status with redux api call 
      });
  };
  deleteHandler = async (id) => {

  };
  editHandler = (rowId, data) => {
    console.log('rowid',rowId);
  };
  onChangeFiltersHandler = (key, val) => {

  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}
const mapStateToProps = (state) => ({
  // notification: notificationStatus(state),
  // loading: state.notification.loading,
  // error: state.notification.error,
});

const mapDispatchToProps = {
  fetchNotificationStatus,
};
export default WithRouter(
    WithRoutify(
      connect(mapStateToProps, mapDispatchToProps)(PatientFormList)
    )
);