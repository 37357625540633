// store
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import SessionSlice from './SessionSlice';
import CommonSlice from './CommonSlice';
import OrganizationSlice from './OrganizationSlice';
import NotificationSlice from './NotificationSlice';
import GlobalFilterSlice from './GlobalFilterSlice';
import DropDownSlice from './DropDownSlice';


const persistConfig = {
  key: 'myPersistedSlice',
  storage,
};
const persistedReducer = persistReducer(persistConfig, SessionSlice);
const globalPersistedReducer = persistReducer(persistConfig, GlobalFilterSlice);

//Slice : reducer
const store = configureStore({
  reducer: {
    session: persistedReducer,
    common:CommonSlice,
    organization:OrganizationSlice,
    notification:NotificationSlice,
    globalFilter: GlobalFilterSlice,
    dropDown:DropDownSlice,
    // Other slices if any
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: ['persist/PERSIST'],
      // },
    }),
});

const persistor = persistStore(store);

export { store, persistor };