import React from 'react';
import VisitDetails from './VisitDetails';
import { formatPhoneNumberUS, isSet } from '../../../utils/commonUtils';
import Icons from '../../../components/UI/Icons';

const EmployeeDetails = (props) => {
    const { employeeList, openAccordionId, visitList, fetchChart, fetchEmployeeList, fetchVisitDetails, handleAccordinClick, printClearanceReport, printMedicalExaminationPdfs, orderLabToogleHandler, activityChartToogleHandler, deleteHandler, printConsentHandler } = props;
    return (
        
        <>
            {   employeeList && employeeList.length > 0 ?
                employeeList && employeeList.map((item, itemIndex) => {
                    return (
                        <>
                            <div className="card m-2">
                                <a href={`#collapseCardExample_${itemIndex}`}
                                    onClick={() => handleAccordinClick(itemIndex, item.employee_id, item)}
                                    className={`d-block card-header sh_accordionheader accordion-button ${openAccordionId === itemIndex ? '' : 'collapsed'}`}
                                    data-bs-toggle="collapse" role="button"
                                    aria-expanded={openAccordionId === itemIndex}
                                    aria-controls={`collapseCardExample_${itemIndex}`}>
                                    <h6 className="m-0 text-md">{item.first_name + " " + item.last_name} - {item.dob}</h6>
                                </a>
                                <div className={`accordion-collapse collapse ${openAccordionId === itemIndex ? 'show' : ''}`} id={`collapseCardExample_${itemIndex}`} data-bs-parent="#accordion1">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-3 col-12">
                                                <div className="sh_cardBox h-auto sh_companydetailwidget">
                                                    <div className="sh_cardHeader">
                                                        <div
                                                            className="form-group d-flex mb-0 justify-content-between align-items-center">
                                                            <h6 className="text-md font-weight-bold mb-0">
                                                                <span className="px-1">
                                                                <Icons type="companydetails" />
                                                                </span>
                                                                Company Details
                                                           </h6>
                                                            <a
                                                                className="  text-xs text-info"
                                                                style={{ cursor: 'pointer' }}></a>
                                                        </div>
                                                    </div>
                                                    <div className="sh_cardBody py-0">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-0">
                                                                    <div className='row' style={{borderBottom: '1px solid #f2f2f4' }}>
                                                                        <div className='col-6 pt-1'>
                                                                    <label className="f-400 text-sm text-truncate mb-0 opacity-75">Name</label>
                                                                    </div> <div className='col-6 pt-1'>
                                                                    <h6 className="font-weight-bold text-sm text-truncate"
                                                                        title={item.company.name}>{item.company.name}</h6>
                                                                    </div></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-0">
                                                                <div className='row' style={{borderBottom: '1px solid #f2f2f4' }}>
                                                                <div className='col-6 pt-1'><label
                                                                    className="f-400 text-sm text-truncate mb-0 opacity-75">Phone
                                                                </label> </div> <div className='col-6 pt-1'>
                                                                    <h6 className="font-weight-bold text-sm text-truncate"
                                                                        title={formatPhoneNumberUS(item.company.phone)}>{formatPhoneNumberUS (item.company.phone)}</h6>
                                                               </div>
                                                               </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-0">
                                                                <div className='row' style={{borderBottom: '1px solid #f2f2f4' }}>
                                                                <div className='col-6 pt-1'>
                                                                    <label className="f-400 text-sm text-truncate mb-0 opacity-75">Email
                                                                </label>
                                                                </div> <div className='col-6 pt-1'>
                                                                    <h6 className="font-weight-bold text-sm text-truncate"
                                                                        title={item.company?.general_email}>{isSet(item.company.address,'--')}</h6>
                                                               </div>
                                                               </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-0">
                                                                <div className='row' style={{borderBottom: '1px solid #f2f2f4' }}>
                                                                <div className='col-6 pt-1'><label
                                                                    className="f-400 text-sm text-truncate mb-0 opacity-75">System ID
                                                                </label>
                                                                </div> <div className='col-6 pt-1'>
                                                                    <h6 className="font-weight-bold text-sm text-truncate"
                                                                        title={item.company.system_id}>{item.company.system_id}</h6>
                                                                </div>
                                                                </div>
                                                                </div>                                                               
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                {/* Visit Details Content */}
                                                <div className="sh_cardBox h-auto sh_visittdetailwidget sh_visitht">

                                                    <div className="sh_cardHeader mb-2">
                                                        <div
                                                            className="form-group d-flex mb-0 justify-content-between align-items-center">
                                                            <h6 className="text-md mb-0">
                                                                <span className="px-1">
                                                                <Icons type="visitdetails" />
                                                                </span>
                                                                Visit Details
                                                            </h6>
                                                            <a
                                                                className="  text-xs text-info"
                                                                style={{ cursor: 'pointer' }}></a>
                                                        </div>
                                                    </div>
                                                    <div className="sh_cardBody p-1" >
                                                    <div id={`accordion2_${itemIndex}`}>
                                                        {/* Content for each visit */}

                                                        <VisitDetails
                                                            printConsentHandler={printConsentHandler}
                                                            visitData={item}
                                                            visitDataIndex={itemIndex}
                                                            employeeList={employeeList}
                                                            openAccordionId={openAccordionId}
                                                            visitList={visitList}
                                                            fetchChart={fetchChart}
                                                            fetchEmployeeList={fetchEmployeeList}
                                                            fetchVisitDetails={fetchVisitDetails}
                                                            handleAccordinClick={handleAccordinClick}
                                                            printClearanceReport={printClearanceReport}
                                                            printMedicalExaminationPdfs={printMedicalExaminationPdfs}
                                                            orderLabToogleHandler={orderLabToogleHandler}
                                                            activityChartToogleHandler={activityChartToogleHandler}
                                                            deleteHandler={deleteHandler}
                                                        />


                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    );
                } 
                )
                : <> <div align='center'> No Record exists </div></>
            }
        </>
    );
};

export default EmployeeDetails;