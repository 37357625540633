import React, { Component } from "react";
import TableGrid from "../../../components/UI/TableGrid";
import BasicPhysicalService from "../../../services/PhysicalService";
import WithRouter from "../../../hoc/WithRouter";
import CompanyService from "../../../services/CompanyService";
import Buttons from "../../../components/UI/Buttons";
import FormInputs from "../../../components/UI/FormInputs";
import WithRoutify from "../../../hoc/WithRoutify";
import PERMISSIONS from "../../../utils/AclPermissions";

class RightContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        activity_name: "",
        activity_code: "",
        activity_type: "",
      },
      tableRecords: {
        tableHeaders: [
          {label:'',key:'dragging',type:'DragHandler'},
          { label: "", key: "action", type: "Actions" },
          { label: "Name", key: "activity_name", type: "", inputType: "TextInput" },
          { label: "Active Code", key: "activity_code", inputType: "vs" },
          { label: "Active Type", key: "activity_type", inputType: "TextInput" },
          // Add more columns as needed
        ],
        tableRows: {
          data: [],
        },
      },
      isLoading:true
    };
  }

  componentDidMount =() =>{
    this.fetchActivitesList(this.state.filter);
  }
  componentDidUpdate = (PrevProps,prevState)=>{
    if(PrevProps.leftContentCount!==this.props.leftContentCount){
      this.fetchActivitesList(this.state.filter);
    }
    if(PrevProps.isLoading !== this.props.isLoading || prevState.isLoading !== this.state.isLoading){
      if(!this.props.isLoading && !this.state.isLoading){
        this.props.Loader(false);
      }
    }
  }

  fetchActivitesList = async (filter = {}) => {
    const { params } = this.props;
    let activitiesResponse = [];
    if (this.props.isFromCompany === true) {
      activitiesResponse =
        await CompanyService.getCompanyPhysicalActivitiesList(
          params.physicalId,
          filter
        );
    } else {
      activitiesResponse =
        await BasicPhysicalService.getBasicPhysicalActivitiesList(
          params.physicalId,
          filter
        );
    }
    const leftContentCount = this.props.leftContentCount;
    let tableData = [];
    activitiesResponse.forEach((itm, itmIndex) => {
      tableData = [
        ...tableData,
        {
          ActivityIndex: 0,
          isHeading: false,
          rowId: itm.actitivity_id,
          TrHoc: this.props.SortableItem,
          handlers: {
            onMouseDown: () => this.props.handleSortStart({
              rowId: itm.actitivity_id,
              type: 'activity',
              activity_name: itm.activity_name,
              activity_code: itm.activity_code,
              activity_type: itm.activity_type,
            })
          },
          attributes: {
            index: leftContentCount + itmIndex,
            key: `activity-${itmIndex}`
          },
          data: {
            dragging: {
              acl:this.props.isFromCompany==true?PERMISSIONS.COMPANY.EDIT:PERMISSIONS.BASIC_PHYSICAL.EDIT
            },
            isActive: true,
            activity_name: itm.activity_name,
            activity_code: itm.activity_code,
            activity_type: itm.activity_type,
            active: "Yes",
            action: [
              {
                className: "badge bg-blue-soft text-light cursor-pointer custom-btn",
                acl:this.props.isFromCompany==true?PERMISSIONS.COMPANY.EDIT:PERMISSIONS.BASIC_PHYSICAL.EDIT,
                label: "Assign",
                href: "#!",
                clickHandler: () => this.props.assignHandler({
                  actitivity_id: itm.actitivity_id,
                  sort_order: "1",
                }),
              },
            ],
          },
        },
      ];
    });
    
    this.setState({
      tableRecords: {
        ...this.state.tableRecords,
        tableRows: {
          type: "default",
          data: tableData,
        },
      },
    },()=>{
      this.setState({isLoading:false});
    });
    
  };

  filterChangeHandler = (fieldName, value) => {
    this.setState(prevState => ({
      filter: { ...prevState.filter, [fieldName]: value }
    }));
  };

  searchFilterHandler = async (isClear) => {
    if (isClear) {
      this.setState(prevState => {
        const updateState = {
          ...prevState.filter,
          activity_name: "",
          activity_code: "",
          activity_type: "",
        };
        this.fetchActivitesList(updateState);
        return { filter: updateState };
      });
    } else {
      this.fetchActivitesList(this.state.filter);
    }
  };

  render() {
    const {filter} = this.state;
    // console.log('leftContentCount',this.props.leftContentCount);
    return (
      <>
      <div className="col-lg-6 col-12">
      <div className="sh_cardBox">
        <div className="sh_cardHeader">
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
            <div className="row">
              <div className="col-2 px-1 pl-2">
                <div className="form-group mb-0">
                  <FormInputs
                    type="TextInput"
                    className="form-control form-control-solid"
                    placeholder="Name"
                    name={"activity_name"}
                    changeHandler={(val, e) =>
                      this.filterChangeHandler("activity_name", val)
                    }
                    value={filter["activity_name"]}
                  />
                </div>
              </div>
              <div className="col-3 px-1">
                <div className="form-group mb-0">
                  <FormInputs
                    type="TextInput"
                    className="form-control form-control-solid"
                    placeholder="Activity Code"
                    name={"activity_code"}
                    changeHandler={(val, e) =>
                      this.filterChangeHandler("activity_code", val)
                    }
                    value={filter["activity_code"]}
                  />
                </div>
              </div>
              <div className="col-3 px-1">
                <div className="form-group mb-0">
                  <FormInputs
                    type="SelectList"
                    className="form-control form-control-solid"
                    placeholder="Activity Code"
                    name={"activity_type"}
                    options={this.props.activityTypeOption}
                    changeHandler={(val, e) =>
                      this.filterChangeHandler("activity_type", val)
                    }
                    value={filter["activity_type"]}
                  />
                </div>
              </div>
              <div className="col-3 px-1">
                <div className="btn-group pl-2">
                  <Buttons
                    label={"Search"}
                    className="btn btn-primary"
                    acl={"any"}
                    clickHandler={() => this.searchFilterHandler(false)}
                  />
                  <Buttons
                    label={"Clear"}
                    acl={"any"}
                    clickHandler={() => this.searchFilterHandler(true)}
                    className="btn btn-outline-secondary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sh_cardBody p-0 position-relative">
          <div
            className="card border-0 bg-transparent"
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              left: "0px",
              bottom: "0px",
            }}
          >
            <div className="sh_innerPortview w-100">
              <div
                className="sh_cardBox shadow-none"
                style={{ borderRadius: "0" }}
              >
                <div
                  className="sh_cardBody p-0 position-relative"
                  // style={{ height: "calc(100% - 120px)" }}
                >
                  <TableGrid
                    // isSortable={true}
                    // collection={'activity'}
                    collection={this.props.isDragging?'activity':'physical'}
                    isDragging={this.props.isDragging}
                    {...this.state.tableRecords}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </>
    );
  }
}

export default WithRoutify(WithRouter(RightContent));
