const organization = {
    OrganizationList: 'Lista de organizaciones',
    Organization: 'Organización',
    AddressAndContact:'Dirección y Contacto',
    
    FederalTaxID:"ID de impuestos federales",
    GroupNPI:"NPI grupal",
    CLIA:"CLIA",
    MedicareGroupID:'Identificación de grupo de Medicare',
    MedicaidGroupID:"Identificación de grupo de Medicaid",
    DOLID:"ID del Departamento de Trabajo",
    PayerOverride:"Anulación del pagador",
    VaccineRegID:"ID de registro de vacuna",
    MedicalDirectorName:"Nombre del director médico",
    LabDirectorName:"Nombre del director del laboratorio",
    RemitAddress:"Dirección de envío",
    RemitCity:"Ciudad remitente",
    RemitState:"Estado de envío",
    RemitZip4:"Enviar código postal+4",
    BillingPhone:"Teléfono de facturación",
    BillingFax:"Fax de facturación",
    BillingEmails:"Correo electrónico (s) de facturación",
    RCMCompany:"Empresa RCM",
    OrganizationLogo:"Logotipo de la organización",
    BusinessLicense:"Licencia de negocios",
    IDsCertificates:"ID y certificados",
    Directors:"Directoras",
    BillingRemitAddress:"Dirección de envío de facturación",
    Images:"Imágenes",
    ViewandUpdate:"View and Update",
    Organizations:"Organizaciones"
};

export default organization;