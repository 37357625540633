import React from 'react';
import PropTypes from 'prop-types';
// import { isSet } from '../../../utils/commonUtils';

const RadioButton = (props) => {
  const {
    className,
    value,
    style,
    name,
    changeHandler,
    id,
    readOnly,
    disabled,
    onKeyPress
  } = props;

  const onChangeHandler = (e) => {
    
    changeHandler(Boolean(e.target.checked), {e});
  };
  let isChecked = Boolean(value);
  // console.log('isChecked',isChecked,value);
  return (
    
   
        <input
          className={className}
          type="radio"
          checked={isChecked}
          style={{...style}}
          name={name}
          id={id ?? name}
          onKeyDown={onKeyPress}
          readOnly={readOnly}
          disabled={readOnly}
          tabIndex={readOnly ? -1 : 0} 
          onChange={(e) => onChangeHandler(e)}
        />
   
  
  );
};

RadioButton.defaultProps = {
  className: 'form-check-input',
  checked: false,
  value: false,
  name:'default',
  style: {},
  id:'default',
  readOnly:false,
  disabled:false,
  changeHandler:()=>{},
  onKeyPress:()=>{}
};

RadioButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  style: PropTypes.object,
  // changeHandler: PropTypes.func.isRequired,
};

export default RadioButton;