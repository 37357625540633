import React from "react";
import {SortableHandle } from "react-sortable-hoc";
import Acl from "../../../../hoc/Acl";
import { isSet } from "../../../../utils/commonUtils";

const DragHandler = ({value})=>{
    const {acl} = value;
   return(
        <td>
            <div style={{display:'flex',alignItems:'center'}} >
                <Acl acl={isSet(acl,'any')}>
               <RowHandler />
               </Acl>
            </div>
        </td>
    )
}
DragHandler.defaultProps ={
    changeHandler:()=>{},
    isSortable:false
}

export default DragHandler;

const RowHandler = SortableHandle(() => <span className="handle" style={{cursor:'grab'}}> <i className="fas fa-grip-vertical"></i></span>);
 