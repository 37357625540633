// src/services/userService.js
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';
import { CREATED, OK } from '../utils/constant';
// import { useDispatch } from 'react-redux';
// import {setProfileDetails} from '../store/ProfileSlice';

const PatientChartsService = {

  getPatientChart: wOrgIdMiddleware(async (url, companyuuid, activityMappingId) => {
    try {
      const response = await http.GET(`${url}companies/${companyuuid}/visit-activity-mappings/${activityMappingId}/activity-charts`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  //Save save PatientCharts 
  savePatientCharts: wOrgIdMiddleware(async (url = '', companyuuid, activityMappingId, data) => {
    try {
      const response = await http.POST(`${url}companies/${companyuuid}/visit-activity-mappings/${activityMappingId}/activity-charts`, data)
      if ((response.code === OK || response.code === CREATED) && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  // Update 
  updatePatientCharts: wOrgIdMiddleware(async (url = '', companyuuid, activityMappingId, data) => {
    try {
      const response = await http.PUT(`${url}companies/${companyuuid}/visit-activity-mappings/${activityMappingId}/activity-charts`, data)
      if ((response.code === OK || response.code === CREATED) && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  getPrintLabel: wOrgIdMiddleware(async (url, query = {}, visitMappingId) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}get-visit-label/${visitMappingId}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),

  getPrintConsent: wOrgIdMiddleware(async (url, query = {}, visitId) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}get-visit-consent-pdf/${visitId}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),
  getPrintResult: wOrgIdMiddleware(async (url, query = {}, mappingId) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}get-results-pdf/${mappingId}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),
  getProviderResult: wOrgIdMiddleware(async (url, query = {}, mappingId) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}get-provider-pdf/${mappingId}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),

  getPatientResult: wOrgIdMiddleware(async (url, query = {}, mappingId) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}get-history-pdf/${mappingId}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),
  getLaboratoryReport: wOrgIdMiddleware(async (url, query = {}, visitLabId) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}get-laboratory-report/${visitLabId}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),
  getLabRequisition: wOrgIdMiddleware(async (url, query = {}, visitLabId) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}get-laboratory-requisition/${visitLabId}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),
  //Save user
  saveActivityConsentCharts: wOrgIdMiddleware(async (url = '', companyuuid, activityMappingId, data) => {
    try {
      const response = await http.POST(`${url}companies/${companyuuid}/visit-activity-mappings/${activityMappingId}/activity-consent`, data)
      if ((response.code === OK || response.code === CREATED) && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  updateActivityConsentCharts: wOrgIdMiddleware(async (url = '', companyuuid, activityMappingId, data) => {
    try {
      const response = await http.PUT(`${url}companies/${companyuuid}/visit-activity-mappings/${activityMappingId}/activity-consent`, data)
      if ((response.code === OK || response.code === CREATED) && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  getClearanceReportPDF: wOrgIdMiddleware(async (url = '', visit_id) => {
    // console.log(visit_id, url);
    try {
      const response = await http.GET(`${url}clearance-report/${visit_id}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),
  getMedicalExaminationReportPDFData: wOrgIdMiddleware(async (url = '', mapping_id) => {

    try {
      const response = await http.GET(`${url}get-medical-examination-report-pdf/${mapping_id}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),
  getMedicalExaminerCertificatePDFData: wOrgIdMiddleware(async (url = '', mapping_id) => {

     try {
       const response = await http.GET(`${url}get-medical-examiner-certificate/${mapping_id}`);
       if (response.code === OK) {
         return response.data;
       }
     } catch (error) {
       console.error('Error fetching user:', error);
       if (error.status) {
         const resData = error.data;
         if (resData.code && resData.data) {
           return resData;
         }
       }
 
       throw error;
     }
   }, true),
  getVaccinationResult: wOrgIdMiddleware(async (url = '', query = {}) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}vaccines-list?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }, true),
  getVaccinePDF: wOrgIdMiddleware(async (url = '', id) => {
    try {
      const response = await http.GET(`${url}get-vaccine-pdf/${id}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }),
  getChildLabOrders: wOrgIdMiddleware(async (url = '', parent_lab_id) => {
    try {
      const response = await http.GET(`${url}childlaborders/parent/${parent_lab_id}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      throw error;
    }
  }),
  saveChildLabOrders: wOrgIdMiddleware(async (url = '', formdata = {}) => {
    const response = await http.POST(`${url}childlaborders/`, formdata);
    return response;
  }),
  updateChildLabOrders: wOrgIdMiddleware(async (url = '', id, formdata = {}) => {
    const response = await http.PUT(`${url}childlaborders/${id}`, formdata);
    return response;
  }),
  deleteChildLabOrders: wOrgIdMiddleware(async (url = '',id) => {
    const response = await http.DELETE(`${url}childlaborders/${id}`);
    return response;
  }),

};

export default PatientChartsService;