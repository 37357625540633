import React from "react";
import AppLayout from "./AppLayout";
import DefaultLayout from "./Default";
import PatientFormLayout from "./PreRegistrationLayout";
const Layouts = ({type,children})=>{
    switch (type) {
        case 'App':
           return <AppLayout>{children}</AppLayout>;
        case 'Patient Form':
            return <PatientFormLayout>{children}</PatientFormLayout>
        default:
          return <DefaultLayout>{children}</DefaultLayout>;
    }
   
}

export default Layouts;