import { Route, Routes, useNavigate } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import LoginForm from "./LoginForm";
import { useState } from "react";
import { isObject, isStrongPassword } from "../../utils/commonUtils";
import AuthService from "../../services/AuthService";
import PasswordForm from "../../components/AuthComponent/ForgetPasswordComponent";
import WithRoutify from "../../hoc/WithRoutify";
import {UNPROCESSABLE_CONTENT } from "../../utils/constant";
import PatientFormLogin from "../PatientFormContainer/PatientFormLogin";

const AuthContainer = (props) => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const [forgetPassFormData,setForgetPassFormData] =useState({
    email:'',
  });
  const [forgetPassValidation,setForgetPassValidation] =useState({
    email:[],
  });
  const [resetPassFormData,setResetPassFormData] =useState({
    code:'',
    enterpassword:'',
    reenterpassword:'',
  });
  const [resetPassValidation,setResetPassValidation] =useState({
    code:[],
    enterpassword:[],
    reenterpassword:[],
  });

  const clearResetForm=()=>{
    
    setResetPassFormData({
      code:'',
      enterpassword:'',
      reenterpassword:''
    }); 
    setResetPassValidation({
      code:[],
      enterpassword:[],
      reenterpassword:[],
    });
  }

  const clearForgetForm=()=>{
    setForgetPassFormData({ email:'',});
    setForgetPassValidation({
      email:[],
    });
  }
  const ResetPassInputChangeHandler = (value, e) => {
    let fieldName = e.target.name;
    setResetPassFormData({ ...resetPassFormData, [fieldName]: value });
    setResetPassValidation({
      ...resetPassValidation,
      [fieldName]:[]
    }) 
  };
  const forgetPassInputChangeHandler = (value, e) => {
    let fieldName = e.target.name;
    setForgetPassFormData({ ...forgetPassFormData, [fieldName]: value });
    setForgetPassValidation({...forgetPassValidation,[fieldName]:''})
  };
  const forgetPassSubmitHandler=async()=>{
      const updateFormData = (newData) => {
        setForgetPassFormData((prevData) => ({
          ...prevData,
          ...newData,
        }));
      };
      // const  WEB__URL = process.env.REACT_WEB__URL;
      let host=window.location.host;
      let WEB__URL="/login/resetPassword";
      const encodedEmail = encodeURIComponent(forgetPassFormData.email);
      let preparedURL=`${host}${WEB__URL}?gmail=${forgetPassFormData.email}`
     await updateFormData({ resetURL: WEB__URL });
     props.Loader(true);
     const response= await AuthService.forgetPasswordEmailResetLink(forgetPassFormData,preparedURL);
     if(response.code === 500){
       props.Toast.error("Currently Not Able To ServeOops! We encountered an issue while processing your password change request. Please try again later.");
     }
     if (response.code === 200) {
        navigate(`/login/resetPassword?gmail=${encodedEmail}`);
        setForgetPassFormData({ email:'',});
        props.Toast.success(response.data);
        
      }
      
      if (response.code==UNPROCESSABLE_CONTENT) {
        if (isObject(response.data)) {
          setForgetPassValidation(response.data);
        }
        else{
          props.Toast.error(response.data);
        }
      }
   
    props.Loader();
}
const resetPassSubmitHandler=async()=>{
  if(isValidPassword()){
    // backend submit Logic 
    const gmailParam = queryParameters.get('gmail');
    let decodeEmail=decodeURIComponent(gmailParam);
    let body={
      email: decodeEmail,
      code:resetPassFormData.code,
      password: resetPassFormData.reenterpassword
    }
    const response =await AuthService.resetPassword(body);
    if (response&&response.code==200&&response.data) {
      props.Toast.success(response.data);
      setResetPassFormData({
        code:'',
        enterpassword:'',
        reenterpassword:''
      });
      navigate('/login');

    }
    else{
      props.Toast.error(response.data);
    }
   }
}
const isValidPassword = () => {
  const validationErrors = {};

  // Code validation
  if (resetPassFormData.code === '') {
    validationErrors.code = ['Code must not be empty'];
  }

  // Password validation
  const enterpassword = resetPassFormData.enterpassword;
  const reenterpassword = resetPassFormData.reenterpassword;

  if (enterpassword !== reenterpassword || (enterpassword === '' && reenterpassword === '')) {
    validationErrors.enterpassword = ['Passwords must be same'];
    validationErrors.reenterpassword = ['Passwords must be same'];
  } else {
    validationErrors.enterpassword = isStrongPassword(enterpassword);
    validationErrors.reenterpassword = isStrongPassword(reenterpassword);
  }

  setResetPassValidation(validationErrors);

  // Check if there are any validation errors
  return Object.keys(validationErrors).every((key) => validationErrors[key].length === 0);
};

  const forgetPasswordProps = {
    title: "Password Recovery",
    formInputs: [
      { "label": "Email Address", "placeHolder": "Enter Email Address","name":"email","value":forgetPassFormData['email'],type:"TextInput"},
    ],
    description: (<div className="small mb-3 text-muted">Enter your email address and we will send you a link to reset your password.</div>),
    submmitButton: "Reset",
  };

  const resetPasswordProps = {
    title: "Reset Password",
    formInputs: [
      { "label": "code", "placeHolder": "Enter your code","value":resetPassFormData['code'],"name":'code',type:"Number"},
      { "label": "password", "placeHolder": "Enter Password","value":resetPassFormData['enterpassword'],"name":'enterpassword',type:"TextInput",isPassword:true},
      { "label": "repassword", "placeHolder": "Re-enter Password","value":resetPassFormData['reenterpassword'],name:'reenterpassword',type:"TextInput",isPassword:true},
    ],
    description: (
      <div className="d-flex align-items-start mt-3">
        <i className="fas fa-info-circle pt-2 fa-lg"></i>
        <p className="font-weight-normal text-gray-600 text-sm pl-2">
          Password must be at least <span className="text-gray-900 font-weight-bold">8 characters</span> and contains
          <span className="text-gray-900 font-weight-bold"> 1 number</span>, <span className="text-gray-900 font-weight-bold">1 uppercase letter</span>,
          <span className="text-gray-900 font-weight-bold"> 1 lowercase letter</span>, and <span className="text-gray-900 font-weight-bold">1 symbol</span>
        </p>
      </div>
    ),
    submmitButton: "Submit",
  };

  return(
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<LoginForm />} />
        <Route path="/forgetPassword" key={1} element={<PasswordForm {...forgetPasswordProps} clearForm={clearForgetForm} validation={forgetPassValidation} inputChangeHandler={forgetPassInputChangeHandler} submitHandler={forgetPassSubmitHandler}/>}/>
        <Route path="/resetPassword" key={2} element={<PasswordForm {...resetPasswordProps} clearForm={clearResetForm} validation={resetPassValidation} inputChangeHandler={ResetPassInputChangeHandler} submitHandler={resetPassSubmitHandler}/>}/>
        <Route path="/patient_form_login" key={3}  element={<PatientFormLogin />} />
        <Route path="/send-questionnaire" key={4}  element={<LoginForm />} />
      </Routes> 
  
     
        
    </Wrap> 
       );
      };
export default WithRoutify(AuthContainer);
