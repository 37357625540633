import React from "react";
import { isObject, isSet } from "../../../../utils/commonUtils";
// import FileManagerService from "../../../../services/FileManagerService";
// import WithRoutify from "../../../../hoc/WithRoutify";

const HyperLink = (props)=>{
    
    let disValue = props.value;
    let attrbs = {};
    if(isObject(props.value)){
        disValue = props.value.value;
        attrbs = isSet(props.value.attributes,{});
    }
    return (
        <td class="forTable_textEllipsis" key={"column"} title={isSet(disValue,'')} {...attrbs}>
            <a href="#!" onClick={props.value.clickHandler}>{isSet(disValue,'')}</a>
        </td>
    )
}
HyperLink.defaultProps ={
    clickHandler:()=>{}
}

export default (HyperLink);