import React, { useState }  from 'react';
import PropTypes from 'prop-types';
const TextInput = (props)=>{
  const [showPassword, setShowPassword] = useState(false);

  // Props Attr
   const {
    type,
    className,
    placeholder,
    value,
    style,
    name,
    required, 
    autocomplete,
  } = props;
  // Handler
   const {
    changeHandler,
    onKeyPressHandler
  } = props;

    const onChangeHandler = (e)=>{
      changeHandler(e.target.value,{e});       
    }
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    return(
      <>
     <div style={{ position: 'relative' }}>
      <input
        type={showPassword ? 'text' : 'password'}
        className={className}
        name={name}
        placeholder={placeholder}
        onKeyUp ={onKeyPressHandler}
        value={value}
        autocomplete={autocomplete}
        onChange={onChangeHandler}
        required={required}
      />
      <span
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
        }}
        onClick={togglePasswordVisibility}
      >
        <i className={showPassword?'far fa-eye fourth_light_color':'far fa-eye-slash fourth_light_color'}></i>
      </span>
    </div>
    </>
    )
}
TextInput.defaultProps = {
  type:'text',
  isMandatory:false,
  className: 'form-control',
  placeholder:'',
  value:'',
  onKeyPressHandler:()=>{},
  required:false,
  autocomplete:'',
  style:{},
  validation:{
    isValid:true,message:''
  },

}
TextInput.propTypes = {
  type: PropTypes.string,
  isMandatory: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  changeHandler: PropTypes.func.isRequired,
};

export default TextInput;
