
import React from 'react';

const ReportIcon = ({size}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24">
  <g id="reports_icon_copy" data-name="reports icon copy" transform="translate(-100)">
    <path id="Path_16" data-name="Path 16" d="M109,0V9h-9V24h20V0Zm-3,19.1h-2V15h2Zm5,0h-2V12h2Zm5,0h-2V9h2Z" fill="#0623c1"/>
    <path id="Path_17" data-name="Path 17" d="M107,0l-7,7h7Z" fill="#0623c1"/>
  </g>
</svg>
  );
};
ReportIcon.defaultProps = {
  size: 24
};

export default ReportIcon;