import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import WithRouter from "../../hoc/WithRouter";
import WithRoutify from "../../hoc/WithRoutify";
import { capitalizeAfterSpace, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";

// Define an object with regex patterns for each field
const licenseObj = {
    DCA: {key:'',name:'cardholder'},//Vehicle class(es) the cardholder is allowed to drive
    DCB: {key:'',name:'Restrictions'},//Restrictions on driving privileges (e.g., only automatic transmission)
    DCD: {key:'',name:'Additional privileges'},//Additional privileges granted to the cardholder (e.g., transportation of hazardous material)
    DBA: {key:'',name:'Expiration date',type:'Date'},//Expiration date of the document
    DCS: {key:'last_name',name:'Last Name'},
    DAC: {key:'first_name',name:'First Name'},
    DAD: {key:'',name:'Middle Name'},//Middle name(s)
    DBD: {key:'',name:'Issued Date',type:'Date'},//Issue date of the document
    DBB: {key:'dob',name:'Date of Birth',type:'Date'},//Date of birth
    DBC: {key:'gender',type:'Gender'},//Gender
    DAY: {key:'',name:'Eye color'},//Eye color
    DAU: {key:'',name:'Height'},//
    DAG: {key:'address',name:'Street name'},
    DAI: {key:'city',name:'City name'},//Address: City name
    DAJ: {key:'state',name:'State name'},//Address: State name
    DAK: {key:'zip_code'},//Address: Postal code
    DAQ: {key:'identification_number'},//ID number of the cardholder
    DCF: {key:'',name:'Document Id'},//ID number of the document
    DCG: {key:'country',name:'Country'},
    DDE: {key:'',name:'Is Last name truncated'},//Indicator that the last name is truncated
    DDF: {key:'',name:'Is first name truncated'},//Indicator that the first name is truncated
    DDG: {key:'',name:'Is middle name truncated'},//Indicator that the middle name(s) are truncated


}

const ScanBarcodeModal = (props) => {
    const { modleToogleHandler, Toast } = props;
    const [validation, setValidation] = useState({});
    const [licenseData,setLicenseData] = useState('');
    // const [formData, setFormData] = useState({
    //     identification_type:"Driver's License"
    // });
    useEffect(()=>{
        document.getElementById('barcode-area').focus();
    },[])

    const inputFields = [
        {
            type: "TextArea",
            label: "",
            id:'barcode-area',
            style:{height:'170px'},
            placeholder: "Input Barcode Values here",
            className: "form-control form-control-solid",
            name: "barcode",
            value:licenseData,
            required: true
        }
    ];
    const changeHandler = (val, fieldName) => {
        setLicenseData(val);
        setValidation({ ...validation, [fieldName]: '' });
    };
    const  parseDriverLicenseData = (input)=> {
        const keys = [
          'DAQ', 'DCS', 'DDE', 'DAC', 'DDF', 'DAD', 'DDG', 'DCA', 'DCB', 'DCD', 
          'DBD', 'DBB', 'DBA', 'DBC', 'DAU', 'DAY', 'DAG', 'DAI', 'DAJ', 'DAK', 
          'DCF', 'DCG', 'DAW', 'DAZ', 'DCK', 'DDN', 'DBA', 'DBD'
        ];
      
        let parsedData = {};
      
        // Regex pattern to match the keys followed by their values
        const regex = new RegExp(`(${keys.join('|')})([\\s\\S]*?)(?=${keys.join('|')}|$)`, 'g');
        let match;
      
        // Loop through the string and extract key-value pairs
        while ((match = regex.exec(input)) !== null) {
          const [_, key, value] = match;
          try{
            if(isSet(licenseObj[key],null)!==null && isSet(licenseObj[key].key,null)!==null){
                let val = value.trim();
                if(isSet(licenseObj[key].type,'Text')==='Date'){
                    const month = val.slice(0, 2); // "10"
                    const day = val.slice(2, 4);   // "31"
                    const year = val.slice(4);      // "2024"
                    val = `${month}/${day}/${year}`;
                }else if(isSet(licenseObj[key].type,'Text')==='Gender'){
                    if(val==="1"){ val='Male'}
                    else if(val==="2"){val='Female'}
                    else{ val='Unknown'}
                }else{
                    val = capitalizeAfterSpace(val)
                }
                parsedData ={
                    ...parsedData,
                    [licenseObj[key].key]:val
                  }
              }
          }catch(e){

          }
         
        }
      
        return parsedData;
      }
    const formSubmitHandler = async () => {
        if(!licenseData){
            setValidation({...validation,['barcode']:'Barcode field is required'});
            document.getElementById('barcode-area').focus();
            return false;
        }
          const result = parseDriverLicenseData(licenseData);
        //   console.log(result);
          
        //   console.log('extractedData',result);
          modleToogleHandler({
            identification_type:"Driver's License",
            street_address:isSet(result['address'],''),
            ...result
          },true);
       
    }
    return (
        <>
            <Modal show={true} onHide={modleToogleHandler} dialogClassName='' keyboard={false}
                contentClassName='h-100'
                backdrop="static" size="md">
                <div className="sh_modalBox1">
                    <Modal.Header closeButton >
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">
                                {'Scan Barcode for New Patient Visit'}
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {inputFields &&
                                inputFields.map((field, index) => (
                                    <div className="col-12" key={index}>
                                        <div className="form-group">
                                            <label className="mb-0 text-md opacity-75"> {field.label} </label>
                                            <FormInputs {...field} changeHandler={(value, e) => changeHandler(value, field.name)} />
                                            {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="" onClick={() => formSubmitHandler()}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default WithRouter(WithRoutify(ScanBarcodeModal));