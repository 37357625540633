// src/services/userService.js
import { CREATED, OK } from '../utils/constant';
import OrganizationService from './OrganizationService';
import http from './http';
// import { useDispatch } from 'react-redux';
// import {setProfileDetails} from '../store/ProfileSlice';


const BasicPhysicalService = {
    //Save Clinic Loaction
    saveBasicPhysical:async (orgQueryString,data)=>{
      try {        
        const response = await http.POST(`basic-physicals${orgQueryString}`,data)
        
        return response;
      } catch (error) {
        console.log('errror ',error);
        console.error('Error fetching user:', error);
        if (error.status===422 && error.data) {
          return error.data
        }
        
        // throw error;
      }  
    },
    getBasicPhysical:async(orgId,query={})=>{
        try {
          const queryString = new URLSearchParams(query).toString();
            const response = await http.GET(`basic-physicals?${queryString}&organization_id=${orgId}`);
            // console.log("response",response.data);
            
            if (response.code===OK) {
              return response;
            }
            
          } catch (error) {
            console.error('Error fetching user:', error);
            if(error.status){
              const resData = error.data;
              if(resData.code && resData.data){
                  return resData;
              }
            }
            
            // throw error;
          } 
    },
    getBasicPhysicalById:async(id)=>{
      try {
          const response = await http.GET(`basic-physicals/${id}`);
          // console.log("response",response.data);
          
          if (response.code===OK) {
            return response;
          }
          
        } catch (error) {
          console.error('Error fetching user:', error);
          if(error.status){
            const resData = error.data;
            if(resData.code && resData.data){
                return resData;
            }
          }
          
          // throw error;
        } 
  },
    updateBasicPhysical:async (orgQueryString,id,data)=>{
      try {
        const response = await http.PUT(`basic-physicals/${id}${orgQueryString}`,data);
        return response;
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
    deleteBasicPhysical:async (orgId,clinicId)=>{
      try {
        const response = await http.DELETE(`basic-physicals/${clinicId}?organization_id=${orgId}`);
        return response;
        
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
    saveBasicPhysicalActivities:async (orgId,data)=>{
      try {
        const response = await http.POST(`physical-activities-mappings?organization_id=${orgId}`,data)
        // console.log('response',response);
          
        if((response.code===OK || response.code===OK) && response.data){
          return response.data;
        }else{
          return response;
        }
          
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        
        // throw error;
      }  
    },

    getBasicPhysicalActivities:async(physicalId,query)=>{
      try {
          const response = await http.GET(`physical-activities-mappings/${physicalId}`);
          // console.log("response",response.data);
          
          if (response.code===OK) {
            return response;
          }
          
        } catch (error) {
          console.error('Error fetching user:', error);
          if(error.status){
            const resData = error.data;
            if(resData.code && resData.data){
                return resData;
            }
          }
          
          // throw error;
        } 
  },
  getBasicPhysicalActivitiesList:async(physicalId,query)=>{
    const queryString = new URLSearchParams(query).toString();
    try {
        let orgId = OrganizationService.getOrgId();
        const response = await http.GET(`unmapped-activities/basic-physical/${physicalId}?${queryString}&organization_id=${orgId}`);
        // console.log("response",response.data);
        
        if(response.code===OK && response.data){
          return response.data;
        }
        
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        
        // throw error;
      } 
  },
  updateBasicPhysicalActivities:async (physicalId,id,data)=>{
    try {
      const response = await http.PUT(`physical-activities-mappings/${id}`,data);
      if(response.code===OK && response.data){
        return response.data;
      }
    } catch (error) {
      if(error.status){
        const resData = error.data;
        if(resData.code && resData.data){
            return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }  
  },
  updateBasicPhysicalActivitiesPosition:async (physicalId,data)=>{
    try {
      const response = await http.POST(`mappings/update-position`,data);
      if(response.code===OK && response.data){
        return response.data;
      }
    } catch (error) {
      if(error.status){
        const resData = error.data;
        if(resData.code && resData.data){
            return resData;
        }
      }
      console.log('Error fetching user:', error);
      // throw error;
    }  
  },
  //
  deleteBasicPhysicalActivities:async (orgId,physicalId,clinicId)=>{
    try {
      const response = await http.DELETE(`physical-activities-mappings/${clinicId}?organization_id=${orgId}`);
      
      if((response.code===OK || response.code===CREATED)  && response.data){
        return response.data;
      }else{
        return response;
      }
    } catch (error) {
      if(error.status){
        const resData = error.data;
        if(resData.code && resData.data){
            return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }  
  },
    };
  
  export default BasicPhysicalService;