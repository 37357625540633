import Button from "../../../../../components/UI/Buttons/Button";
import Employeeervice from "../../../../../services/EmployeeService";
import FileManagerService from "../../../../../services/FileManagerService";
import { isSet } from "../../../../../utils/commonUtils";

const SpecialInstructionTab = (props) => {
  const specialInstruction = props.visitDetails?.company?.employer_special_instructions;
  // const specialInstruction = '<p style="color: blue; font-size: 18px;">This is a special instruction with <strong>HTML</strong> and <em>CSS</em>.</p>';
//   console.log(props.visitDetails.company.name);
  const printInstructionHandler=async()=>{
    const payload={
        htmlContent:specialInstruction,
        viewName:'',
        date:'',
    }
    let response=await Employeeervice.generateSpecialInstruction(payload);
    console.log('response',response);
    let dowloadData={
        file_content:response.data,
        original_name:`${props.visitDetails.company.name}`,
        mime_type:'application/pdf'
        
    }
    FileManagerService.printPdf(dowloadData);
}
  return (
    <>
       
        <div className="sh_cardBox h-auto shadow-none visitboxheight border mb-0">
           
             {/* <Button
              label='Print Special Instructions'
              className='btn btn-primary btn-sm text-sm'
              acl={specialInstruction?'any':''}
              clickHandler={()=>printInstructionHandler()}
              disabled={true}
              />*/}
          <div className="sh_cardHeader">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="text-sm mb-0"> Instructions </h6>
              <Button
                title={"Print the Instructions"}
                iconType={"Print"}
                className='btn btn-transparent-light btn-icon'
                acl={specialInstruction?'any':''}
                clickHandler={()=>printInstructionHandler()}
                />
            </div>
            
          </div>
           

      
          <div className="sh_cardBody">
            
              {/* <div dangerouslySetInnerHTML={{ __html: specialInstruction }} /> */}
              
              <p className=" text-justify mb-0">
                {specialInstruction}
              </p>
               
         
          </div>
          
          
        </div>
       
    </>
  );
};
export default SpecialInstructionTab;



