import React from "react";
import {isSet,isObject} from '../../../../utils/commonUtils';
import THeader from './THeader';
import Actions from "./Action";
import Checkbox from "./Checkbox";
import FormInput from "./FormInput";
import FileType from "./FileType";
import HyperLink from "./HyperLink";
import { Link } from "react-router-dom";
import DragHandler from "./DragHandler";
import ProgressBarData from "./ProgressBar";

const TableData =(props)=>{
    const {Tag} = props;
    switch (isSet(props.type,'')) {
        case "THeader":
            return <THeader {...props} />  
        case "Actions":
            return <Actions {...props}  />
        case "checkBox":
            return <Checkbox {...props}/>
        case "FormInput":
            return <FormInput {...props} />
        case "FileType":
            return <FileType {...props} />
        case "HyperLink":
            return <HyperLink {...props} />
        case "DragHandler":
            return <DragHandler {...props} />
            case "ProgressBar":
                return <ProgressBarData {...props} />
        default:
            let disValue = props.value;
            let attrbs = {};
            let objTitle = '';
            if(isObject(props.value)){
                disValue = props.value.value;
                objTitle = isSet(props.value.title,'')
                attrbs = isSet(props.value.attributes,{});
            }
            const title = isObject(isSet(disValue,''))?objTitle:isSet(disValue,'');
            return <Tag class="forTable_textEllipsis" key={"column"} title={title} {...attrbs}>
                {props.value && props.value.clickHandler ?
                <span onClick={props.value.clickHandler}>{isSet(disValue,'')}</span>:
                props.value && props.value.href?
                <Link style={{color:'inherit',textDecoration:'none' }} to={props.value.href}>{isSet(disValue,'')}</Link>
                :isSet(disValue,'')}
                </Tag>
    }
}

TableData.defaultProps = {
    Tag:'td',
    attributes:{},
    PrependElement:<></>
}
export default TableData;

