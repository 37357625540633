import { useEffect, useState } from "react";
import FormInputs from "../../components/UI/FormInputs";
import { ItentificationTypeOptions } from "../../utils/constant";
import ClinicService from "../../services/ClinicService";
import Buttons from "../../components/UI/Buttons";
import DropDownService from "../../services/DropDownService";

const EmployeeFilter = (props) => {
  const { filterData, closeHandler, submitHandler } = props;
  const [clinicOptions, setClinicOptions] = useState([]);
  const [companyOptions, setCompanyOption] = useState([]);
  const fetchClinicList = async (query = "") => {
    try {
      const clinicList = await DropDownService.getClinicList(null, query);
      let options = [{ label: "-Select Clinic-", value: "" }];
      clinicList.map((clinic, clinicIndex) => {
        options.push({
          label: clinic.name,
          value: clinic.clinic_id,
        });
      });
      setClinicOptions(options);
    } catch (e) { }
  };

  const fetchCompaniesList = async () => {
    const response=await DropDownService.getCompanies(null);
        let companiesOptions = [{ label: "-Select Company-", value: "" }];
        response.forEach((company) => {
          companiesOptions.push({
            label: company.name,
            value: company.company_uuid,
          });
        });
        setCompanyOption(companiesOptions);
  };
  useEffect(() => {
    fetchClinicList();
    fetchCompaniesList();
  },[]);
  const inputFields = [
    {
      type: "TextInput",
      placeholder: "First Name",
      className: "form-control form-control-solid",
      name: "first_name",
      value: filterData.first_name,
    },
    {
      type: "TextInput",
      placeholder: "Last Name",
      className: "form-control form-control-solid",
      name: "last_name",
      value: filterData.last_name,
    },
    {
      type: "TextInput",
      placeholder: "Patient ID",
      className: "form-control form-control-solid",
      name: "patient_id",
      value: filterData.patient_id,
    },
    {
      type: "Date",
      placeholder: "DOB",
      className: "form-control form-control-solid",
      name: "dob",
      value: filterData.dob,
    },
    {
      type: "SelectList",
      options: companyOptions,
      placeholder: "company_id",
      className: "form-control form-control-solid",
      name: "company_id",
      value: filterData.company_id,
    },
    {
      type: "Phone",
      placeholder: "Phone",
      className: "form-control form-control-solid",
      name: "phone",
      value: filterData.phone,
    },
    {
      type: "SelectList",
      options: ItentificationTypeOptions,
      className: "form-control form-control-solid",
      name: "identification_type",
      value: filterData.identification_type,
    },
    {
      type: "TextInput",
      className: "form-control form-control-solid",
      placeholder: "Identification Number",
      name: "identification_number",
      value: filterData.identification_number,
    },
    {
      type: "SelectList",
      options: clinicOptions,
      className: "form-control form-control-solid",
      placeholder: "Identification Number",
      name: "clinic_id",
      value: filterData.clinic_id,
    },
    {
      type: "TextInput",
      className: "form-control form-control-solid",
      placeholder: "Booking ID",
      name: "booking_id",
      value: filterData.booking_id,
    },
    {
      type: "TextInput",
      className: "form-control form-control-solid",
      placeholder: "Pre Registration Code",
      name: "pre_registration_code",
      value: filterData.pre_registration_code,
    },
  ];
  // console.log('clinicOptions',clinicOptions);
  return (
    <>
      <div className="sidebar_Filter toggled" id="sidebar_FilterOpen">
        <div className="sh_filterBox">
          <div className="sh_filterHeader px-2 py-1">
            <div className="card-title mb-0">
              <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0 text-md">Search by</h6>
              <Buttons
              //iconType="Cancel"
              className="btn-close text-sm"
              acl={"any"}
              clickHandler={()=>closeHandler(false)}
              />
              </div>
            </div>
          </div>
          <div className="sh_filterBody">
            <div className="row">
              {inputFields.map((field, index) => (
                <div className="col-12" key={index}>
                  <div className="form-group mb-1">
                    <FormInputs
                      {...field}
                      changeHandler={(val, e) =>
                        props.onChangeHandler(field.name, val)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="sh_filterFooter">
          <Buttons
          clickHandler={closeHandler}
          className="btn btn-outline-secondary btn-sm text-sm"
          acl={"any"}
          label={"Clear"}
            />
         <Buttons
          clickHandler={submitHandler}
          className="btn btn-primary btn-sm text-sm"
          acl={"any"}
          label={"Apply"}
            />
           
                      
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeFilter;
