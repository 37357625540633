import React, { memo,useEffect }  from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import ReadOnlyField from './ReadOnlyField';
// import {setAndGetAttributes} from './FormInputFunctions';

const PickList = (props)=>{
  // Props Attr
   const {
    className,
    readOnly,
    disabled,
    value,
    style,
    options,
    additionalAttrs,
    name,
    required,
    appendClassName,
    defaultOptions,
    id
  } = props;
  // Handler
   const {
    changeHandler
  } = props;
  const optionsToRender = options.length > 0 ? options : defaultOptions;
    const onChangeHandler = (e)=>{
      changeHandler(e.target.value,{e});       
    } 
    return(
      <>
        {
          readOnly==true ? //if readonly true show text field instead of picklist
          <ReadOnlyField {...props} options={options} />
            :
            <select
              className={className + ' ' + appendClassName}
              name={name}
              id={id}
              value={value}
              style={style}
              {...additionalAttrs}
              readOnly={readOnly}
              required={required}
              disabled={disabled}
              onChange={(e) => onChangeHandler(e)} >
              {optionsToRender?.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

        }
      </>
      
    )
}

PickList.defaultProps = {
  isMandatory:false,
  required:false,
  className: 'form-control',
  appendClassName:'',
  placeholder:'',
  value:'',
  id:'',
  style:{},
  readOnly:false,
  disabled:false,
  options:[],
  defaultOptions:[{label:'-Select-',value:'-'}],
  additionalAttrs :{},
  validation:{
    isValid:true,message:''
  },

}
PickList.propTypes = {
  type: PropTypes.string,
  isMandatory: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  // validationRules: PropTypes.array,
  // valid: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
};

export default memo(PickList);
