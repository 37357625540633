import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';
// import { CREATED, OK } from '../utils/constant';

const CsvService = {
    downloadSampleCsv: async (type) => {
        const response = await http.GET(`download-sample-csv?type=${type}`);
        return response;
      },
    getCsvFileHeaders:async(file,headers = {'Content-Type': 'multipart/form-data'})=>{
        const response = await http.POST(`get-csv-headers`,file,headers);
        return response.data;  
    },
    processCsvImports:async(data,headers = {'Content-Type': 'multipart/form-data'})=>{
        const response = await http.POST(`process-csv-import`,data,headers);
        return response;  
    },
    getCsvLog:wOrgIdMiddleware(async(url)=>{
        const response = await http.GET(`${url}get-csv-log`);
        return response.data;  
    }),
    getEzlist:(async(query={})=>{
        const queryString = new URLSearchParams(query).toString();
        const response = await http.GET(`get-ezrecords?${queryString}`);
        return response.data;  
    }),
    getEzreaderById:(async(id)=>{
        const response = await http.GET(`ezrecord-by-id/${id}`);
        return response.data;  
    }),
    updateEzRecordById:(async(id,data)=>{
        const response = await http.PUT(`update-ezrecord-by-id/${id}`,data);
        return response.data;  
    }),
    downloadCsvReport:wOrgIdMiddleware(async(url,data)=>{
        // const queryString = new URLSearchParams(query).toString();
        const response = await http.POST(`${url}downloadCsvReport`,data);
        return response;  
    }),
    
    
};

export default CsvService;