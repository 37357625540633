import Buttons from "../../../components/UI/Buttons";

const WorkerCompCarrier = (props) => {
    return (
        <>
            <div className="sh_cardBox sh_widgets sh_patientdetailwidget" >
                <div className="sh_cardHeader">
                    <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                        <h6 className="text-md font-weight-bold mb-0">
                            <span className="px-2">
                                <Buttons
                                    iconType='DashboardComp'
                                />
                            </span>
                            Worker's Comp Carrier
                        </h6>
                    </div>
                </div>
                <div className="sh_cardBody py-0 dashboard_card">
                    <div className="row ">
                        <div className="col-12  font-weight-bold"><a>Insurnace Info</a></div>
                    </div>
                    <div className="row dashstatusbg first pl-2">
                        <div className="col-8 px-0 font-weight-bold"><a>
                            Traveler's Insurance</a></div>
                        <div className="col-4 font-weight-bold ratescount"><a></a></div>
                    </div>
                    <div className="row dashstatusbg pl-2">
                        <div className="col-8 px-0 font-weight-bold"><a>
                            Policy#</a></div>
                        <div className="col-4 font-weight-bold ratescount">
                            <Buttons
                                iconType="Copyicon"
                                acl="any"
                            />
                        </div>

                    </div>
                    <div className="row dashstatusbg first pl-2">
                        <div className="col-8 px-0 font-weight-bold"><a>
                            Phone:</a></div>
                        <div className="col-4 font-weight-bold ratescount"><a></a></div>
                    </div>
                    <div className="row dashstatusbg pl-2">
                        <div className="col-8 px-0 font-weight-bold"><a>
                            Adjuster:</a></div>
                        <div className="col-4 font-weight-bold ratescount">
                            <Buttons
                                iconType="DashboardMessage"
                                acl="any"
                            />
                        </div>
                    </div>
                </div>
                <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                    <button className="btn dashboardbtn  btn-info px-4"> Update Ins.Info</button>
                </div>
            </div>

        </>
    );
}
export default WorkerCompCarrier;