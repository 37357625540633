import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
// import { TranslateClient, TranslateTextCommand } from "@aws-sdk/client-translate"; // Import Translate client
import './style.css';
// import config from './aws-exports';
 
class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.quillRef = React.createRef(); // Create a ref for the Quill editor
    this.state = {
      htmlState: this.props.value,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleThemeChange = this.handleThemeChange.bind(this);
    // this.translateText = this.translateText.bind(this); // Bind translation function
  }

  componentDidMount() {
    this.setState({ htmlState: this.props.value });
    this.setCursorToEnd(); // Set cursor to end after initial render
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ htmlState: this.props.value });
      this.setCursorToEnd(); // Set cursor to end after each update
    }
  }

  handleChange(html) {
    // console.log('converting...s');
    const htmlWithNbsp = html;//.replace(/ /g, '&nbsp;'); // Replace spaces with &nbsp;
    // console.log('converted');
    this.setState({ htmlState: htmlWithNbsp }, () => this.props.changeHandler(htmlWithNbsp, {}));
  }

  handleThemeChange(newTheme) {
    if (newTheme === 'core') newTheme = null;
    this.setState({ theme: newTheme });
  }

//    translateText = async () => {
    
//     const client = new TranslateClient({ region: "us-east-2" }); // No need to pass credentials explicitly
//     const command = new TranslateTextCommand({
//         SourceLanguageCode: "en",
//         TargetLanguageCode: "es",
//         Text: "Hello, world!"
//     });

//     try {
//         const response = await client.send(command);
//         console.log(response.TranslatedText);
//     } catch (error) {
//         console.error(error);
//     }
// }

  setCursorToEnd() {
    // const quillEditor = this.quillRef.current.editor;
    // quillEditor.setSelection(quillEditor.getLength(), 0); // Set cursor to the end
  }

  render() {
    return (
      <div>
        <ReactQuill
          ref={this.quillRef} // Assign the ref to the Quill editor
          theme={this.state.theme}
          onChange={this.handleChange}
          value={this.state.htmlState}
          modules={RichTextEditor.modules}
          formats={RichTextEditor.formats}
          bounds={'.app'}
          placeholder={this.props.placeholder}
        />
        {/* <button onClick={this.translateText}>Translate to Spanish</button> Add translation button */}
      </div>
    );
  }
}

RichTextEditor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

RichTextEditor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

RichTextEditor.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  changeHandler: PropTypes.func,
};

export default RichTextEditor;
