const selfRegister = {
    // Self Register Common Fields Step-1
    Next:'Siguiente',
    BasicDetails:'Detalles Básicos',
    PatientDetails:'Detalles del Paciente',
    Verification:'Verificación',
    Previous:'Anterior',

    // Self Register Form Fields Step-1
    OrganizationList:'Organización',
    CompanyList:'Compañía',
    ClinicList:'Clínica',
    ClinicLocation:'Ubicación de la clínica',
    FirstNamePh:'Nombre (persona que está siendo examinada)',
    LastNamePh: 'Apellido (persona que se está haciendo la prueba)',
    CellPhonePh:'Celular (para entrega de resultados)',
    DateOfBirth:'Fecha de nacimiento',
    Age:'Edad',
    FirstName:'Nombre',
    LastName:'Apellido',
    CellPhone:'Celular',



    // Self Register Form Fields Step-2
    PatientEmail: "Correo electrónico del paciente",
    Ethnicity: "Etnicidad",
    Race: "Raza",
    Address: "Dirección",
    SocialSecurityNumber: "Número de Seguro Social",
    IdentificationType: "Tipo de Identificación",
    IdentificationNumber: "Número de Identificación",
    EmployerName: "Nombre del Empleador",
    InsuranceName: "Nombre del Seguro",
    InsuranceCardFront: "Cargar tarjeta de seguro (frente)",
    InsuranceCardBack: "Subir Tarjeta de Seguro (Reverso)",
    IDPicture: "Subir foto de identificación",
    MemberNumber: "Número de miembro",
    GroupNumber: "Número de grupo",
    Pharmacy: "Farmacia (opcional)",
    PharmacyAddress: "Dirección de la farmacia",
    PharmacyPhone: "Número de teléfono de la farmacia",
    GuardianName: "Nombre Completo del Tutor/Padres",
    GuardianPhone: "Número de Teléfono del Tutor",
    HearUsFrom: "Cómo se enteró de nosotros?",
    PreRegistrationCode:"código de preinscripción"
};

export default selfRegister;
