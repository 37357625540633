import React from "react";
import Toast from "./Toast";
import Loader from "./Loader";


const Common = ()=>{
    return(
        <>
        <Loader />
        <Toast />
        </>
    )
}

export default Common;