import React from "react";
import ChartsFields from "./ChartsFields";
import { evaluate, isSet } from "../../../../../utils/commonUtils";
import Icons from "../../../../../components/UI/Icons";

const SectionBasedDetails = ({label,rules,fields,note,col,formData,changeHandler,updateFormData,readOnly,validation})=>{
   let result = null;
   let action = '';
   if(isSet(rules,[]).length>0){
      const resultData = evaluate(isSet(rules,[]),formData);
      action = resultData.action;
      result = resultData.result;
   }
   if(action===''){
   }else if((action==='SHOW' && result===false) ||  (action==='HIDE' && result===true)){
      return false;
   }
    return(
        <div className="card">
        <div className="d-block card-header p-2" style={{position:'sticky',top:'-20px',zIndex:'1'}}>
         <div className="d-flex align-items-center justify-content-between">
            <h6 className="m-0 text-md  ">
                {label} 
            </h6>
            {/* {(isSet(note,null)!==null)?
               <label class="mb-0">
                  {typeof note === 'string'?
                  <>
                     <Icons type='Warning' />
                     <code class="text-danger"> Note: {note}</code> 
                  </>:
                  note
                  }
               
               </label>
            :<></>} */}
            
         </div>
           
        </div>
        <div className="card-body">
           <div className="row">
            {fields.map((item,itemIndex)=>{
               if (!item) return null;
               const column = isSet(item.col,isSet(col,'col-6'));
               let result = null;
               let action = '';
               let attr={};
               if(isSet(item.rules,[]).length>0){
                  const resultData = evaluate(isSet(item.rules,[]),formData);
                  action = resultData.action;
                  result = resultData.result;
               }
               if(action===''){
               }else if((action==='SHOW' && result===false) ||  (action==='HIDE' && result===true)){
                  return false;
               }else if(action==='ENABLE' && result===true){
                  attr={
                     ...attr,
                     disabled:false
                  }
               }else if(action==='DISABLE' && result===true){
                  attr={
                     ...attr,
                     disabled:true
                  }
               }else if(action==='REQUIRED' && result===true){
                  attr={
                     ...attr,
                     required:true
                  }
               }
              
               

               return(
                  <>
                  <ChartsFields 
                     key={itemIndex}
                     column={column} 
                     itemIndex={itemIndex}
                     formData={formData}
                     changeHandler={changeHandler}
                     updateFormData={updateFormData}
                     item={{...item,...attr}}
                     readOnly={readOnly}
                     validation={validation}
                     />
                     </>
                     
               )
               
               
            })}
           </div>    
        </div>                  
     </div>
    )
}

export default SectionBasedDetails;