import React,{useMemo}  from 'react';
import PropTypes from 'prop-types';

// import {setAndGetAttributes} from './FormInputFunctions';

const Age = (props)=>{
  // Props Attr
   const {
    type,
    className,
    placeholder,
    value,
    style,
    name,
    required,
    readOnly
  } = props;
  // Handler
   const {
    changeHandler,
    onKeyPressHandler
  } = props;

    const onChangeHandler = (e)=>{
      changeHandler(e.target.value,{e});       
    } 
    const today = useMemo(() => new Date(), []);
    const calculatedAge = useMemo(() => {
      // console.log('valuevalue',value);
        if (!value) return null; // Return null if DOB is not set
        

        const birthDate = new Date(value);
        if (isNaN(birthDate.getTime())) {
          return ''; // Return null if DOB is invalid
        }
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        
        return age;
    }, [value, today]);
    return(
     
        <input 
          type={type}
          className={className}
          name={name}
          placeholder={placeholder}
          value={calculatedAge}
          {...style}
          onKeyUp ={onKeyPressHandler}
          required={required}
          readOnly={readOnly}
          onChange={(e)=>onChangeHandler(e)}   />    
    )
}
Age.defaultProps = {
  type:'text',
  isMandatory:false,
  className: 'form-control form-control-solid',
  placeholder:'',
  value:'',
  style:{},
  onKeyPressHandler:()=>{},
  readOnly:false,
  required:false,
  validation:{
    isValid:true,message:''
  },

}
Age.propTypes = {
  type: PropTypes.string,
  isMandatory: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  // validationRules: PropTypes.array,
  // valid: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
};

export default Age;
