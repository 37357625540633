const common = {
    NetworkError: 'Network error occurred. Please check your internet connection',
    SomethingWentsWrong: 'Something went wrong. Please contact admin',
    CellNumber:'Numero de celular',
    Address:'DIRECCIÓN',
    Street:'Calle',
    City:'Ciudad',
    State:'Estado',
    Action:'Acción',
    Code:'Código',
    Zip4:'Código postal+4',
    County:"Condado",
    Fax:"Fax",
    TimeZone:'Zona horaria',
    Add:'Agregar',
    Search:'Buscar',
    Clear:'Clara'
};

export default common;