import { wOrgIdMiddleware } from '../utils/commonUtils';
import { CREATED, OK } from '../utils/constant';
import OrganizationService from './OrganizationService';
import http from './http';


const CompanyService = {
  saveCompany: wOrgIdMiddleware(async (url, data) => {
    try {
      const response = await http.POST(`${url}companies`, data);
      // console.log('rspomse', response);
      if (response.code === OK && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  //get company
  getCompany: wOrgIdMiddleware(async (url, companyId) => {
    try {
      const response = await http.GET(`${url}companies/${companyId}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },true),
  //get  all company
  getCompanies: wOrgIdMiddleware(async (url, query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}companies?${queryString}`);
      // console.log('respomse', response);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
    }
  }),
  //update company
  updateCompany: wOrgIdMiddleware(async (url, id, data) => {
    // const orgId = OrganizationService.getOrgId();
    try {
      const response = await http.PUT(`${url}companies/${id}`, data);
      // console.log("response", response);
      if (response.status === 200 && response.data) {
        const resData = response.data;
        return resData;
      } else {
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching company:', error);
      throw error;
    }
  }),
  //delete company
  deleteCompany: wOrgIdMiddleware(async (url, companyId) => {
    // const orgId = OrganizationService.getOrgId();
    try {
      const response = await http.DELETE(`${url}companies/${companyId}`);
      return response;

    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching Company:', error);
      throw error;
    }
  }),
  //get company employees 
  getCompaniesEmployee: wOrgIdMiddleware(async (url, companyId, query = '') => {
    try {
      const response = await http.GET(`${url}companies/${companyId}/employees/auto-suggestion?name=${query}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),

  //save physical for company with comapny id
  saveCompaniesPhysical: wOrgIdMiddleware(async (url, companyId, data) => {
    try {
      const response = await http.POST(`${url}companies/${companyId}/company-physicals`, data);
      return response;
    } catch (error) {
      console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  getCompanyPhysicalActivitiesList: async (physicalId, filter) => {
    const queryString = new URLSearchParams(filter).toString();
    try {
      let orgId = OrganizationService.getOrgId();
      const response = await http.GET(`unmapped-activities/company-physical/${physicalId}?${queryString}&organization_id=${orgId}`);
      if (response.code === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  //get company
  getCompaniesPhysical: wOrgIdMiddleware(async (url, companyId, query = '') => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}companies/${companyId}/company-physicals?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  //get company
  getCompaniesPhysicalById: wOrgIdMiddleware(async (url, companyId,id, query = '') => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}companies/${companyId}/company-physicals/${id}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  //update company physical
  updateCompaniesPhysical: wOrgIdMiddleware(async (url, companyId, id, data) => {
    try {
      const response = await http.PUT(`${url}companies/${companyId}/company-physicals/${id}`, data);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching company:', error);
      throw error;
    }
  }),
  //delete company physical
  deleteCompaniesPhysical: wOrgIdMiddleware(async (url, companyId, physicalId) => {
    // const orgId = OrganizationService.getOrgId();
    try {
      const response = await http.DELETE(`${url}companies/${companyId}/company-physicals/${physicalId}`);
      
        return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching Company:', error);
      throw error;
    }
  }),
  saveCompaniesPhysicalActivities: wOrgIdMiddleware(async (url, data, companyId) => {
    try {
      const response = await http.POST(`${url}companies/${companyId}/company-physicals-activities`, data);
      return response;
    } catch (error) {
      console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
    }
  }, true),
  getCompaniesPhysicalActivities: wOrgIdMiddleware(async (url, companyId, physicalId, query = '') => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}companies/${companyId}/company-physicals-activities/${physicalId}?${queryString}`);
      if (response.code === OK) {
        return response.data;
      }
      else {
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  //save company physical
  saveCompaniesPhysicalActivitiesFromTemplate: wOrgIdMiddleware(async (url, companyId, data) => {
    try {
      const response = await http.POST(`${url}companies/${companyId}/${data}`, {});
      return response;
    } catch (error) {
      console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  //delete company physical activity
  deleteCompaniesPhysicalActivities: wOrgIdMiddleware(async (url, companyId, activityId) => {

    try {
      const response = await http.DELETE(`${url}companies/${companyId}/company-physicals-activities/${activityId}`);
      if (response.code === 200 && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching Company:', error);
      throw error;
    }
  }),
  //get drug screen enabled 
  getByDrugScreenEnabled: wOrgIdMiddleware(async (url, companyId) => {
    try {
      const response = await http.GET(`${url}companies/${companyId}/get-by-drugscreen-enabled`)
      if (response.code === OK && response.data) {
        return response.data;
      } else {
        return response;
      }
    }
    catch (error) {
      console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
    }
  }),

  addCompanyDocument: wOrgIdMiddleware(async (url = '', data, headers = {
    'Content-Type': 'multipart/form-data'
  }) => {
    try {

      const response = await http.POST(`${url}add-company-documents`, data, headers)
      if ((response.code === OK || response.code === CREATED) && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  getCompanyDocument: wOrgIdMiddleware(async (url = '', company_id) => {
    try {
      const response = await http.GET(`${url}get-company-documents/${company_id}`)
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),
  //delete company
  deleteCompanyDocument: wOrgIdMiddleware(async (url, id) => {
    // const orgId = OrganizationService.getOrgId();
    try {
      const response = await http.DELETE(`${url}delete-company-documents/${id}`);
      if (response.code === OK) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching Company:', error);
    }
  }),

};

export default CompanyService;
