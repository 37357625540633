import React from "react";
import {
    WEEKDAYS_SHORT
} from '../../../constants';

const MonthViewCell = (props)=>{
    const {listItem,keyIndex,todayDate} = props;
    return(
        <div className="fc-bg" key={'monthviewcell'+keyIndex}>
            <table className="">
                <tbody>
                    <tr>
                        {listItem.map((item,itemIndex)=>{
                            let classArr = ["fc-day","fc-widget-content","fc-"+WEEKDAYS_SHORT[itemIndex]+"","fc-past"];
                            if(item.currentMonth===false){
                                classArr = ["fc-day","fc-widget-content","fc-"+WEEKDAYS_SHORT[itemIndex]+"","fc-other-month","fc-past"];
                            }
                            if(new Date(todayDate).setHours(0, 0, 0, 0)===new Date(item.date).setHours(0, 0, 0, 0)){
                                classArr.push('fc-day-today');
                            }
                                return(
                                    <td 
                                        key={itemIndex}
                                        onClick={()=>props.addVisitToogleHandler({visit_date:item.c_date})}
                                        className={classArr.join(' ')} 
                                        data-date={item.date}>    
                                    </td>
                                )
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MonthViewCell;