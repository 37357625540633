import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { isObject, isSet } from "../../utils/commonUtils";
import { CREATED, OK } from "../../utils/constant";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import LookupService from "../../services/LookupService";
import Employeeervice from "../../services/EmployeeService";
import DependentService from "../../services/DependentService";

class AddDependent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      validation: {},
      formDataInEdit: null,
      activeEditId: null,
      tableRecords: {
        tableHeaders: [
          { label: 'First Name', key: 'first_name', inputType: 'TextInput' },
          { label: 'Last Name', key: 'last_name', inputType: 'TextInput' },
          // { label: 'Action', key: 'action', type: 'Actions' },
        ],
        tableRows: {
          type: "default",
          data: []
        },
      }
    };
  }

  componentDidMount() {
    this.fetchDependentList();
  }

  

  render() {
    const { isOpen, modelTitle,employeeId } = this.props;
    const { formData, validation, formDataInEdit, activeEditId, tableRecords } = this.state;
    
    const inputFields = [
      {
        type: "TextInput",
        label: "First Name",
        placeholder: "First Name",
        className: "form-control form-control-solid",
        name: "first_name",
        value: formData.first_name,
        isFirstLetterCaps:true,
        required:true
      },
      {
        type: "TextInput",
        label: "Last Name",
        placeholder: "Last Name",
        className: "form-control form-control-solid",
        name: "last_name",
        value: formData.last_name,
        isFirstLetterCaps:true,
        required:true
      },
    ];
    return (
      <>
        <Modal show={true} onHide={this.modelCloseHandler} 
        size="lg" 
        dialogClassName='hResponsive'
         keyboard={false}
        contentClassName='h-100' 
        backdrop="static">
          <div className="sh_modalBox">
            <Modal.Header closeButton>
              <Modal.Title>
                <h6 className="text-lg mb-0 font-weight-bold">{`Add Dependents  `}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="sh_innerPortview w-100 d-flex">
                <div class="w-50 p-3">
                  <div class="sh_cardBox h-auto shadow-none border">
                    <div class="sh_cardBody p-3">
                      <div class="row">
                        {inputFields &&
                          inputFields.map((field, index) => (
                            <div className={`col-12`} key={index}>
                              <div className="form-group">
                                <label className="text-gray-900 text-md opacity-75">
                                  {field.label}{" "}
                                </label>
                                <FormInputs
                                  {...field}
                                  value={formData[field.name]}
                                  changeHandler={(value,e)=>this.changeHandler(value,field.name)}
                                />
                                {isSet(validation[field.name], "") !== "" ? (
                                  <span className="text-danger m-1">
                                    {isSet(validation[field.name], "")}
                                  </span>
                                ) : (
                                    ""
                                  )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div class="sh_cardFooter justify-content-center">
                      <button
                        class="btn btn-primary btn-sm text-sm"
                        onClick={this.formSubmitHandler}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="w-100 p-3">
                  <div class="sh_cardBox h-auto shadow-none border">
                    <div class="sh_cardHeader">
                      <div class="form-group d-flex mb-0 justify-content-between align-items-center">
                        <h6 class="text-md mb-0">{`Dependents`}</h6>
                      </div>
                    </div>
                    <div class="sh_cardBody p-0">
                      <TableGrid
                        {...tableRecords}
                        gridEditProps={{
                          formDataInEdit: formDataInEdit,
                          activeEditId: activeEditId,
                          onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                        }} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>

           {/* <Modal.Footer className="d-flex justify-content-center">
              <Buttons
                type='Single'
                className="btn btn-outline-secondary"
                clickHandler={this.modelCloseHandler}
                label='Cancel'
                acl={'any'} />
            </Modal.Footer>*/}
          </div>
        </Modal>
      </>
    );
  }

  changeHandler = (val, fieldName) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [fieldName]: val,
      },
      validation:{
        ...prevState.validation,
        [fieldName]:''
      }
      
    }));
  };

  formSubmitHandler = async () => {
    const { formData } = this.state;
    const { Loader, Toast,activeLookup,employeeId} = this.props;
    let updateValidation = { ...this.state.validation };
    Loader(true);
    let response = await DependentService.saveDependent(employeeId,formData);
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      }
      if (Object.keys(updateValidation).length > 0) {
        this.setState({ validation: updateValidation });
      }
    }
    else {
      Toast.success("Dependent Added Successfully");
    }
    this.fetchDependentList();
    this.setState({ formData: {} });
    Loader(false);
  };

  deleteHandler = (id) => {
    const { Toast } = this.props;
      DependentService.deleteDependent(this.props.employeeId,id)
        .then((res) => {
          this.fetchDependentList();
          Toast.success('Dependent Deleted Successfully');
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
  }

    editHandler = (rowId, data) => {
      this.setState({ formDataInEdit: data, activeEditId: rowId });
    };

    updateHandler = async (id) => {
      const {formDataInEdit} = this.state;
      const {employeeId}=this.props;
      let response = await DependentService.updateDependent(employeeId,id,formDataInEdit);
      if (response.code === OK) {
        this.props.Toast.success(" Dependent Updated Successfully");
        this.editHandler(null, {}, null);
        this.fetchDependentList();
      }
      else if (response.status=="ERROR"&&response.data) {
        if (isObject(response.data)) {
          let validaitons={};
          for (let key in response.data) {
           validaitons={...validaitons,[key]:response.data[key][0]};
          }
            this.setState((prevState) => ({
            formDataInEdit: {
              ...prevState.formDataInEdit,
              validation: validaitons,
            },
          }));
        }
      }
    };

    fetchDependentList= async () => {
      const { activeLookup } = this.props;
      const LookupListData = await DependentService.getDependentList(this.props.employeeId);
      let tableData = [];
      LookupListData.map((item, Index) => {
        tableData = [
          ...tableData,
          {
            Index: Index,
            isHeading: false,
            rowId: item.id,
            data: {
                first_name:item.dependent.first_name,
                last_name:item.dependent.last_name,
              active: "Yes",
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Edit",
                  title: "Edit",
                  type: "GridEdit",
                  acl: "any",
                  clickHandler: (rowId, data) => this.editHandler(rowId, data),
                  updateHandler: () => this.updateHandler(item.id),
                  onChangeFormDataInEdit: (key, val) => this.onChangeFormDataInEdit(key, val),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: "any",
                  title: "Delete",
                  isDelete:true,
                  clickHandler: () => this.deleteHandler(item.id),
                },
              ],
            },
          },
        ];
      });
      this.setState({
        tableRecords: {
          ...this.state.tableRecords,
          tableRows: {
            type: "default",
            data: tableData,
          }
        }
      });
    };

    modelCloseHandler = () => {
      const { addFormHandler } = this.props;
      addFormHandler();
    };

    onChangeFormDataInEdit = (key, val) => {
      this.setState((prevState) => ({
        formDataInEdit: {
          ...prevState.formDataInEdit,
          [key]: val,
          validation: {
            ...prevState.formDataInEdit.validation,
            [key]: '', // Set validation empty
          },
        },
      }));
    };
}

export default WithRoutify(AddDependent);



