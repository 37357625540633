import React, { Component } from "react";
import { EthinicityOptions, ItentificationTypeOptions, RelationShipOptions, SOCIAL_SECURITY_NUMBER_FORMAT, genderOptions, raceOptions } from "../../../utils/constant";
import FormInputs from "../../../components/UI/FormInputs";
// import EmployeeService from "../../../services/EmployeeService";
import { formatPhoneNumberUS, isSet } from "../../../utils/commonUtils";
import WithRoutify from "../../../hoc/WithRoutify";
import InsuranceService from "../../../services/InsuranceService";
import ClinicService from "../../../services/ClinicService";
import CompanyService from "../../../services/CompanyService";
import DivisionService from "../../../services/DivisionService";
import ClinicDepartmentService from "../../../services/ClinicDepartmentService";
import DropDownService from "../../../services/DropDownService";

class PatientDetails extends Component{
    constructor(props){
        super(props);
        this.state = {
            insuranceListOptions:[],
            clinicLocationListOptions:[],
            fetchCompaniesListOptions:[],
            clinicListOptions:[],
            divisionListOption:[],
            division2ListOption:[]
            // formData:{}
        }
    }
    componentDidMount = ()=>{
        const {formData} = this.props;
        const clinicId =  formData.clinic_id;
        this.fetchEmployeeListOptions();
        if(isSet(formData.clinic_id,null)!==null){
            this.fetchClinicApis(clinicId);
            this.fetchDivisionList(formData.company_id);
            if(isSet(formData.division_id,null)!==null){
              this.fetchDivision2List(formData.division_id);
            }
        }
        try{
            if(isSet(formData.division_id,null)!==null){
              this.fetchDivision2List(formData.division_id);
            }
        }catch(e){

        }
        
        this.fetchInsuranceList();
        this.fetchCompaniesList();
        
    }
    componentDidUpdate =(prevProps)=>{
        const {formData} = this.props;
        if(  prevProps.formData.company_id!==this.props.formData.company_id){
            this.fetchClinicApis(this.props.formData.company_id);
        }
        try{
          if(prevProps.formData.division_id!==this.props.formData.division_id){
            if(isSet(formData.division_id,null)!==null){
              this.fetchDivision2List(formData.division_id);
            }
          }
          if(prevProps.formData.company_id!==this.props.formData.company_id){
            if(isSet(formData.company_id,null)!==null){
              this.fetchDivisionList(formData.company_id);
              this.fetchCompaniesList();
            }
          }
          if(prevProps.formData.clinic_id!==this.props.formData.clinic_id){
            if(isSet(formData.clinic_id,null)!==null){
              this.fetchClinicLocationList(formData.clinic_id);
            }
            else{
              this.setState({clinicLocationListOptions:[]});
            }
          }
        }catch(e){

        }
        
    }
    fetchClinicApis = async (company_id)=>{
        const [clinicLocationList, clincList] = await Promise.all([
            // this.fetchClinicLocationList(clinicId),
            this.fetchClinicDetails(company_id)
          ]);
          // this.props.onChangeHandler('clinic_name',isSet(clinicDetails,{clinic_name:''}).name);
          // this.setState({
          //   // clinicLocationListOptions:clinicLocationList,
          //   clinicListOptions:clincList
          // });
    }
     fetchClinicDetails = async (company_id)=>{
        try{
          let options=[{label:'-Select Clinic',value:''}];
          if (company_id) {
            const data = await DropDownService.getClinicDropDownListByCompany(null,{company_id:company_id});
            data.forEach(item => {
              options.push({label:item.name,value:item.clinic_id})
            });
          }
          this.setState({
            clinicListOptions:options
          });
          return options;
        }catch(e){
  
        }
      }
       fetchClinicLocationList = async (clinicId)=>{
        try{
          const data = await ClinicDepartmentService.getClinicDepartmentByClinicId(null,clinicId);
          let clinicLocationInArr = [{label:'-None-',value:''}];
          isSet(data,[]).forEach((item,itemIndex)=>{
            clinicLocationInArr.push({
              label:item.facility_name,
              value:item.id,
              key:itemIndex
            })
          });
          this.setState({clinicLocationListOptions:clinicLocationInArr});
          return clinicLocationInArr;
        }catch(e){
  
        }
      }
    render(){
        const {formData,onChangeHandler,validations} = this.props;
        const {clinicLocationListOptions,divisionListOption,division2ListOption,employeeListOptions} = this.state;
        console.log('clinicLocationListOptions',clinicLocationListOptions);
        
        const inputFields = [
          {
            label:'Company and Clinic Details',
            data:[
              {
                type: "SelectList",
                label: "Company",
                placeholder: "Company",
                className: "form-control form-control-solid",
                name: "company_id",
                // readOnly:true,
                options:this.state.fetchCompaniesListOptions,
                required:true,
              },
              {
                type: "SelectList",
                options:this.state.clinicListOptions,
                label: "Clinic",
                placeholder: "Clinic",
                className: "form-control form-control-solid",
                name: "clinic_id",
                // readOnly:true,
                required:true,
              },
              {
                type: "SelectList",
                label: "Clinic Department",
                placeholder: "Clinic Department",
                className: "form-control form-control-solid",
                name: "clinic_department_id",
                options:clinicLocationListOptions,
                // readOnly:isSet(formData.company_id,null)===null?true:false,
                // value:formData.clinic_location_id,
              },
              {
                type: "SelectList",
                label: "Division",
                placeholder: "Division",
                className: "form-control form-control-solid",
                name: "division_id",
                options:divisionListOption,
                // required:true,
              },{
                type: "SelectList",
                label: "Division 2",
                placeholder: "Division 2",
                className: "form-control form-control-solid",
                name: "division2_id",
                options:division2ListOption,
                // required:true,
              },
            ]
          },
            {
              label:'Personal',
              data:[
                {
                  type: "Checkbox",
                  label: "Is Dependent ?",
                  placeholder: "",
                  isLabelAppend:true,
                  className: "form-check-input",
                  id:'is_dependent',
                  name: "is_dependent",
                },
                {
                  type: "SelectList",
                  options:employeeListOptions,
                  label: "Patient/Employee",
                  placeholder: "Patient/Employee",
                  className: "form-control form-control-solid",
                  name: "dependent_id",
                  value: formData.dependent_id,
                  required: true,
                  isShow:formData.is_dependent==1,
                },
                {
                  type: "SelectList",
                  options:RelationShipOptions,
                  label: "Dependent Relationship",
                  placeholder: "Last Name",
                  className: "form-control form-control-solid",
                  name: "dependent_relationship",
                  value: formData.dependent_relationship,
                  isShow:formData.is_dependent==1,
                },
                {
                type: "TextInput",
                label: "First Name",
                placeholder: "First Name",
                className: "form-control form-control-solid",
                name: "first_name",
                required: true,
                isFirstLetterCaps:true,
              },
              {
                type: "TextInput",
                label: "Last Name",
                placeholder: "Last Name",
                className: "form-control form-control-solid",
                name: "last_name",
                required: true,
                isFirstLetterCaps:true,
              },
              {
                type: "SelectList",
                label: "Gender",
                placeholder: "Gender",
                options:genderOptions,
                className: "form-control form-control-solid",
                name: "gender",
                required:true
              },
              {
                type: "Phone",
                label: "Cell Number",
                placeholder: "Cell Number",
                className: "form-control form-control-solid",
                name: "phone",
                required: true,
              },
              {
                type: "Date",
                label: "Date of Birth",
                placeholder: "Date of Birth",
                withAge:true,
                className: "form-control form-control-solid",
                name: "dob",
                disableFuture:true,
                required:true
              },
              {
                type: "Age",
                label: "Age",
                placeholder: "",
                readOnly:true,
                className: "form-control form-control-solid",
                isNotValidate:true,
                name: "dob",
              },
              {
                type: "SelectList",
                label: "Ethnicity",
                placeholder: "Ethnicity",
                className: "form-control form-control-solid",
                name: "ethnicity",
                options:EthinicityOptions,
                // value:formData.ethnicity,
              },
              {
                type: "SelectList",
                label: "Race",
                placeholder: "Race",
                options:raceOptions,
                className: "form-control form-control-solid",
                name: "race",
                // value:formData.race,
              },
              {
                type: "Checkbox",
                label: "Active",
                placeholder: "",
                isLabelAppend:true,
                className: "form-check-input",
                id:'status',
                name: "status",
              },
              ]
            },
            {
                label:'General',
                data:[
                  {
                  type: "SelectList",
                  label: "Insurance Name",
                  placeholder: "Insurance Name",
                  className: "form-control form-control-solid",
                  name: "insurance_id",
                  options:this.state.insuranceListOptions,
                },
                {
                  type: "TextInput",
                  label: "Member Number",
                  placeholder: "Member Name",
                  className: "form-control form-control-solid",
                  name: "member_number",
                },
                {
                  type: "TextInput",
                  label: "Group Number",
                  placeholder: "Group Number",
                  className: "form-control form-control-solid",
                  name: "group_number",
                },
                {
                  type: "FileUpload",
                  label: "ID Picture",
                  placeholder: "",
                  className: "form-control1 form-control-solid1",
                  name: "id_picture",
                  // required: true,
                },
                {
                  type: "FileUpload",
                  label: "Insurance Card (Front)",
                  placeholder: "",
                  className: "form-control1 form-control-solid1",
                  name: "insurance_card_front",
                },
                {
                  type: "FileUpload",
                  label: "Insurance Card (Back)",
                  placeholder: "",
                  className: "form-control1 form-control-solid1",
                  name: "insurance_card_back",
                },
              
                {
                  type: "TextInput",
                  label: "Email",
                  placeholder: "Email",
                  className: "form-control form-control-solid",
                  name: "email",
                },
                  {
                    type: "TextInput",
                    label: "Patient Id",
                    placeholder: "Patient Id",
                    className: "form-control form-control-solid",
                    name: "patient_id",
                  },
                  {
                    type: "TextInput",
                    label: "Booking Id",
                    placeholder: "Booking Id",
                    className: "form-control form-control-solid",
                    name: "booking_id",
                  },
                  {
                    type: "NonEdit",
                    label: "System Id",
                    placeholder: "",
                    className: "",
                    name: "system_id",
                  },
                  {
                    type: "NonEdit",
                    label: "EMR Patient ID",
                    placeholder: "",
                    className: "",
                    name: "",
                  },
                  {
                    type: "SelectList",
                    label: "Identification type",
                    placeholder: "Identification type",
                    className: "form-control form-control-solid",
                    name: "identification_type",
                    options:ItentificationTypeOptions,
                    // value:formData.identification_type,
                  },
                  {
                    type: "TextInput",
                    label: "Identification number",
                    placeholder: "Identification number",
                    className: "form-control form-control-solid",
                    name: "identification_number",
                    // value:formData.identification_number,
                  },
                  {
                    type: "TextInput",
                    label: "Driver's License",
                    placeholder: "Driver's License",
                    className: "form-control form-control-solid",
                    name: "driver_license",
                    // value:formData.identification_number,
                  },
                {
                  type: "Checkbox",
                  label: "Disable Labs / Drug Screen Notification",
                  placeholder: "",
                  isLabelAppend:true,
                  className: "form-check-input",
                  id:'disable_labs_drugscreen_notification',
                  name: "disable_labs_drugscreen_notification",
                },
                // {
                //   type: "TextInput",
                //   label: "Pre-Registration Code",
                //   placeholder: "Pre-Registration Code",
                //   className: "form-control form-control-solid",
                //   name: "pre_registration_code",
                //   readOnly:true,
                // },
                ]
              },{
              label:'Address & Contact',
              data:[
                {
                type: "Address",
                label: "Full Address",
                placeholder: "Full Address",
                className: "form-control form-control-solid",
                name: "address",
              },
              {
                type: "TextInput",
                label: "Street",
                placeholder: "street",
                className: "form-control form-control-solid",
                name: "street_address",
              },
              {
                type: "TextInput",
                label: "City",
                placeholder: "City",
                className: "form-control form-control-solid",
                name: "city",
              },
              {
                type: "TextInput",
                label: "State",
                placeholder: "State",
                className: "form-control form-control-solid",
                name: "state",
              },
              {
                type: "ZipCode",
                label: "Zipcode",
                placeholder: "Zipcode",
                className: "form-control form-control-solid",
                name: "zip_code",
              },
              {
                type: "Phone",
                label: "Social Security Number",
                placeholder: "Social Security Number",
                className: "form-control form-control-solid",
                name: "social_security_number",
                numberFormat:SOCIAL_SECURITY_NUMBER_FORMAT,
                maskChar:'x'
              },
              {
                type: "Phone",
                label: "Cell Number",
                placeholder: "Cell Number",
                className: "form-control form-control-solid",
                name: "phone",
              },
              {
                type: "TextInput",
                label: "Full Name of Guardian & Parents",
                placeholder: "Full Name of Guardian & Parents",
                className: "form-control form-control-solid",
                name: "guardian_name",
              },
              {
                type: "Phone",
                label: "Guardian Cell Number",
                placeholder: "Guardian Cell Number",
                className: "form-control form-control-solid",
                name: "guardian_phone",
              },
            ]
            }
          ];
        
        return(
            <>
            
            {inputFields.map((section, secIndex) => {
                return (
                    <div className="card mb-2" key={secIndex}>
                        <a href={`#collapseCardExample_${secIndex}`} className="d-block sh_accordionheader card-header" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseCardExample" style={{ position: 'sticky', top: '-20px', zIndex: '1' }}>
                            <h6 className="mb-0 text-md">{section.label}</h6>
                        </a>
                        <div className="collapse show" id={`collapseCardExample_${secIndex}`} data-bs-parent="#modal111">
                            <div className="card-body">
                                <div className="row">
                                    {section.data.map((item, itemIndex) => {
                                      if (isSet(item.isShow, true) === false) { return false }
                                        const value = isSet(formData[item.name],'');
                                        return (
                                            <div className="col-sm-12" key={itemIndex}>
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label opacity-75">{item.label}</label>
                                                    <div className="col-sm-9">
                                                      {isSet(item.isLabelAppend,false)===true?
                                                      <div className="form-check">
                                                        <FormInputs {...item} value={value} changeHandler={(val,e)=>{onChangeHandler(item.name,val,e);   if (item.name === 'company_id'){this.fetchCompanyDetails(val);}  }} />
                                                      </div>
                                                      :<>
                                                      <FormInputs {...item} value={value} changeHandler={(val,e)=>{onChangeHandler(item.name,val,e);   if (item.name === 'company_id'){this.fetchCompanyDetails(val);}  }} />
                                                      </> }

                                                      {isSet(item.isNotValidate) ? <></> : (isSet(validations[item.name], '') !== '' ? <span className="text-danger m-1">{isSet(validations[item.name], '')}</span> : '')}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            </>
        )
    }
     fetchCompanyDetails = async (companyId)=>{
        try{
          const data = await CompanyService.getCompany(null,companyId);
            // this.props.onChangeHandler('clinic_id',data.clinic_id);
            // this.fetchClinicApis(data.clinic_id,companyId);
        }catch(e){
  
        }
      }
      fetchCompaniesList = async (query = '') => {
        // Assuming this.props.Loader(true); and this.props.Loader(false); are correctly managed elsewhere
        
        const companyId = this.props.company_id;
      
        try {
          let res = null;
          // Call CompanyService.getCompanies with await
        
          if(isSet(companyId)){
            res = await CompanyService.getCompanies(query);
          }else{
            const clinicId = this.props.clinic_id;
            query = {clinic_id : clinicId};
            res = await CompanyService.getCompanies(query);
          }
        
           
          
          let companiesOptions = [{ label: '-None-', value: '' }];
          res?.data.data.forEach((company) => {
            companiesOptions.push({
              label: company.name,
              value: company.company_uuid
            });
          });
      
          this.setState({
            fetchCompaniesListOptions: companiesOptions
          });
        } catch (err) {
          console.log("err===>", err);
        } finally {
          this.props.Loader(false);
        }
      };
      fetchDivisionList = async (companyId)=>{
        // const {Loader} = this.props;
        let options = [{label:'-Select-',value:''}]
        try{
            const res = await DivisionService.getDivisionByCompanyId(companyId);
            
            res.forEach((item)=>{
                options.push({
                    label:item.division_name,
                    value:item.division_id
                })
            });
            this.setState({divisionListOption:options});
        }catch(e){
        }
      }
      fetchDivision2List = async (divisionId)=>{
        // const {Loader} = this.props;
        let options = [{label:'-Select-',value:''}]
        try{
            const res = await DivisionService.getDivisionByParentId(divisionId);
           
            res.forEach((item)=>{
                options.push({
                    label:item.division_name,
                    value:item.division_id
                })
            });
            this.setState({division2ListOption:options});
        }catch(e){
        }
      }
    fetchInsuranceList = async ()=>{
        // const {Loader} = this.props;
        let options = [{label:'-Select-',value:''}]
        try{  
            const res = await InsuranceService.getInsurance();
            const resData = res.data;
            resData.data.data.forEach((item)=>{
                options.push({
                    label:item.insurance_name,
                    value:item.insurance_company_id
                })
            });
            this.setState({insuranceListOptions:options});
            // console.log('InsuranceList',data);
        }catch(e){
        }
    }
    fetchEmployeeListOptions=async()=>{
      const response =await DropDownService.getEmployeeList(null);
      let options=[{label:'-Select-',value:''}]
      response&&response?.forEach((itm,index)=>{
        if (itm.employee_id!=this.props.employeeId) {
          options.push({label:`${itm.first_name} ${itm.last_name} - ${formatPhoneNumberUS(itm.phone)}`,value:itm.employee_id}); 
        }
      });
      this.setState({employeeListOptions:options});   
    }
}


export default WithRoutify(PatientDetails);