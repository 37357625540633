import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FormInputs from "../../../components/UI/FormInputs";
import { isSet } from "../../../utils/commonUtils";
import { FieldValidationFocus } from "../../../utils/cssUtils";

const AddAssignActivities = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({ sort_order: 1 });
  const { isOpen, addFormHandler, modelTitle, submitHandler,tableRecords } = props;
  const [isDefaultStatusDisabled, setIsDefaultStatusDisabled] = useState(true);
  const tableData=tableRecords.tableRows.data;
  const tableActivityIds = tableData.map(record => record.data.activity_id);
  const filteredActivityListOptions = props.activityListOptions.filter(option => {
    return !tableActivityIds.includes(option.activity_id);
});
  const inputFields = [
    {
      type: "SelectList",
      label: "Activity Name ",
      placeholder: "Activity Name ",
      className: "form-control form-control-solid",
      name: "actitivity_id",
      options:filteredActivityListOptions,
      required: true,
    },
    // {
    //   type: "TextInput",
    //   label: "Sort Order",
    //   placeholder: "Sort Order",
    //   className: "form-control form-control-solid",
    //   name: "sort_order",
    // },
    {
      label: "Default Status",
      type: "SelectList",
      options: [
        { label: 'Pending MRO', value: 'Pending MRO' },
        { label: 'Pending Lab Confirmation', value: 'Pending Lab Confirmation' },
      ],
      className: "form-control form-control-solid",
      name: "default_status",
      disabled: isDefaultStatusDisabled
    }

  ];
  const changeHandler = (val, fieldName) => {
    let updateFormData = { ...formData };
    let updateValidation = { ...validation };
    if (fieldName == 'actitivity_id') {
      const selectedActivity = props.activityListOptions.find(
        item => item.value === val
      );
      const drugScreenTypes = [
        'Drug Screen - 5 Panel',
        'Drug Screen - 10 Panel',
        'Drug Screen - 12 Panel',
        'Drug Screen - 13 Panel',
        'Drug Screen - 4 Panel'
      ];
      if (selectedActivity && drugScreenTypes.includes(selectedActivity.activity_type)) {
        updateFormData['default_status'] = 'Pending MRO';
        setIsDefaultStatusDisabled(false);
      } else {
        updateFormData['default_status'] = '';
        setIsDefaultStatusDisabled(true);
      }

    }
    updateFormData[fieldName] = val;
    updateValidation[fieldName] = '';
    setFormData(updateFormData);
    setValidation(updateValidation);
  };
  const formSubmitHandler = async () => {
    //submit
    let validation = await submitHandler(formData, addFormHandler, props.fetchList);
    if (validation) {
      setValidation(validation);
      FieldValidationFocus(inputFields, validation);
    }

  }
  return (
    <>
      <Modal show={isOpen} onHide={addFormHandler} dialogClassName="hResponsive" contentClassName="h-100" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="text-lg mb-0 font-weight-bold"> {modelTitle} </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row"> {inputFields && inputFields.map((field, index) => (<div className="col-12" key={index}>
            <div className="form-group">
              <label className="text-gray-900 text-md opacity-75"> {field.label} </label>
              <FormInputs {...field} id={isSet(field.id,field.name)}  changeHandler={(val, e) => changeHandler(val, field.name)} value={isSet(formData[field.name], '')} />
              {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
            </div>
          </div>))} </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" className="" onClick={() => formSubmitHandler()}> Submit </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddAssignActivities;