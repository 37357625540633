import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import { useEffect, useState } from "react";
import DropDownService from "../../services/DropDownService";
import { getLookupByKey, getOrganizationId, isSet } from "../../utils/commonUtils";
import DivisionService from "../../services/DivisionService";
import { VACCINATION_NAME } from "../../utils/constant";
import AuthService from "../../services/AuthService";
import withReduxState from "../../hoc/wReduxState";
import PERMISSIONS from "../../utils/AclPermissions";
import { acl_check } from "../../utils/aclUtils";

const VaccineFilter = (props) => {
    const { filterData, closeHandler, submitHandler,profile} = props;
    const [CompanyOptions, setCompanyOptions] = useState([]);
    const [DivisionOptions, setDivisionOptions] = useState([]);
    const [VacccineName,setVacccineName]=useState([]);
    useEffect(() => {
        const fetchIntialDatas = async () => {
            await fetchCompany();
            await fetchlookups();
            
        }
        fetchIntialDatas()
    }, [])
    useEffect(()=>{
        fetchDivision();
    },[filterData.company_id]);
    const fetchCompany=async()=>{
        let companiesOptions = [{ label: "-Select Company-", value: "" }];
        const response=await DropDownService.getCompanies(null);
        response&&isSet(response, []).forEach((company) => {
            companiesOptions.push({
              label: company.name,
              value: company.company_uuid,
            });
          });
          setCompanyOptions(companiesOptions);
    }
    const fetchDivision=async()=>{
        let divisionOptions = [{ label: "-Select Division-", value: "" }];
        const response=await DivisionService.getDivision(null);
        console.log('response',response);
        response.data.data&&isSet(response.data.data, []).forEach((division) => {
            divisionOptions.push({
                label:division.division_name,
                value:division.division_id
            });
          });
          setDivisionOptions(divisionOptions);
    }
    const fetchlookups=async()=>{
        const vaccine_name = await getLookupByKey(VACCINATION_NAME);
        if (vaccine_name.length > 0) {
            vaccine_name[0].label = '-Vaccine Name-';
        }
        setVacccineName(vaccine_name);
    }
    const inputFields = [
        {
            type: "",
            label:"First Name",
            placeholder: "First Name",
            name: "first_name",
        },
        {
            type: "",
            label:"Last Name",
            placeholder: "Last Name",
            name: "last_name",
        },
        {
            type: "",
            label:"Patient ID",
            placeholder: "Patient ID",
            name: "patient_id",
        },
        {
            type: "",
            label:"Company Code",
            placeholder: "Company Code",
            name: "company_code",
        },
        {
            type: "SelectList",
            options:CompanyOptions,
            label:"Company",
            placeholder: "Company",
            name: "company_id",
            show:acl_check(profile.permissions,PERMISSIONS.COMPANY.LIST),
        },
        {
            type: "SelectList",
            label:"Division",
            options:DivisionOptions,
            placeholder: "Division",
            name: "division_id",
            show:acl_check(profile.permissions,PERMISSIONS.COMPANY_DIVISION.LIST),
        },
        {
            type: "",
            label:"System ID",
            placeholder: "System ID",
            name: "system_id",
        },
        {
            type: "Date",
            label:"DOB",
            name: "dob",
        },
        {
            type: "TextInput",
            label:"EMR Patient ID",
            placeholder: "EMR Patient ID",
            name: "emr_patient_id",
        },
        {
            type: "Date",
            label:"Visit Date",
            placeholder: "Visit Date",
            name: "visit_date",
        },
        {
            type: "SelectList",
            label:"Vaccine Series",
            options:[{'label':'-Vaccine Series-',value:''},{'label':'1',value:1},{'label':'2',value:'2'}],
            placeholder: "Vaccine Series",
            name: "vaccine_series",
        },
        {
            type: "SelectList",
            label:"Admin Site",
            options:[{'label':'-Admin Site-',value:''},{label:'Left Deltoid',value:'Left Deltoid'},{'label':'Right Deltoid',value:'Right Deltoid'}],
            placeholder: "Admin Site",
            name: "admin_site",
        },
        {
            type: "SelectList",
            label:"Vaccine Name",
            options:VacccineName,
            placeholder: "Vaccine Name",
            name: "vaccine_name",
        },
        {
            type: "TextInput",
            label:"Lot Number",
            placeholder: "Lot Number",
            name: "lot_number",
        },


    ];
    return (
        <>
            <div className="sidebar_Filter toggled" id="sidebar_FilterOpen">
                <div className="sh_filterBox">
                    <div className="sh_filterHeader px-2 py-1">
                        <div className="card-title mb-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="mb-0 text-md">Search by</h6>
                                <Buttons
                                    //iconType="Cancel"
                                    className="btn-close text-sm"
                                    acl={"any"}
                                    clickHandler={() => closeHandler(false)}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="sh_filterBody">
                        <div className="row">
                            {inputFields.map((field, index) => isSet (field.show,true)==true&&(
                                <div className="col-12" key={index}>
                                    <div className="form-group mb-1">
                                        <FormInputs
                                        className={field.className?field.className:"form-control form-control-solid"}
                                            {...field}
                                            value={filterData[field.name]}
                                            changeHandler={(val, e) =>
                                                props.onChangeHandler(field.name, val)
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="sh_filterFooter">
                        <Buttons
                            clickHandler={closeHandler}
                            className="btn btn-outline-secondary text-sm"
                            acl={"any"}
                            label={"Clear"}
                        />
                        <Buttons
                            clickHandler={submitHandler}
                            className="btn btn-primary text-sm"
                            acl={"any"}
                            label={"Apply"}
                        />


                    </div>
                </div>
            </div>
        </>
    );
};
export default withReduxState(VaccineFilter);
