import { wOrgIdMiddleware } from '../utils/commonUtils';
import { OK } from '../utils/constant';
import http from './http';


const ProviderService = {


    // //Save provider Loaction
    saveProvider: wOrgIdMiddleware(async (url, data) => {
        try {
            const response = await http.POST(`${url}provider-profile`, data)
            return response;
            
        } catch (error) {
            console.error('Error fetching provider:', error);
            if (error.status) {
                const resData = error.data;
                if (resData.code && resData.data) {
                    return resData;
                }
            }

            // throw error;
        }
    }, true),
    //get provider
    getProviderById: wOrgIdMiddleware(async (url, providerId) => {
        try {
            // const orgId       = OrganizationService.getOrgId();
            const response = await http.GET(`${url}provider-profile/${providerId}`);
            
            if (response.code === OK) {
                return response.data;
            }
            
        } catch (error) {
            // console.error('Error fetching provider:', error);
            if (error.status) {
                const resData = error.data;
                if (resData.code && resData.data) {
                    return resData;
                }
            }

            // throw error;
        }
    }),
    //get provider
    getProviders: wOrgIdMiddleware(async (url, query = {}) => {
        const queryString = new URLSearchParams(query).toString();
        try {
            const response = await http.GET(`${url}provider-profile?${queryString}`);
            
            if (response.code === OK) {
                return response.data;
            }
            
        } catch (error) {
            // console.error('Error fetching provider:', error);
            if (error.status) {
                const resData = error.data;
                if (resData.code && resData.data) {
                    return resData;
                }
            }

            // throw error;
        }
    },true),
    //update provider
    updateProvider: wOrgIdMiddleware(async (url, id, data) => {
        try {
            const response = await http.PUT(`${url}provider-profile/${id}`, data);
            return response;
        } catch (error) {
            if (error.status) {
                const resData = error.data;
                if (resData.code && resData.data) {
                    return resData;
                }
            }
            console.error('Error fetching provider:', error);
            throw error;
        }
    }),
    //delete provider
    deleteProvider: wOrgIdMiddleware(async (url, id) => {
        try {
            const response = await http.DELETE(`${url}provider-profile/${id}`);
            return response;
            
        } catch (error) {
            if (error.status) {
                const resData = error.data;
                if (resData.code && resData.data) {
                    return resData;
                }
            }
            console.error('Error fetching provider:', error);
            throw error;
        }
    }),
    //get provider
    //http://localhost:8000/api/organizations/02846116e3d84c199e6837b556109336/providers-by-clinic/e069e936d7ee4d289c8e0c9d847d0956
    getProviderByClinicId: wOrgIdMiddleware(async (url, clinicId,query={}) => {
        const queryString = new URLSearchParams(query).toString();
        try {
            // const orgId       = OrganizationService.getOrgId();
            const response = await http.GET(`${url}providers-by-clinic/${clinicId}?${queryString}`);
            
            if (response.code === OK) {
                return response.data;
            }
            
        } catch (error) {
            // console.error('Error fetching provider:', error);
            if (error.status) {
                const resData = error.data;
                if (resData.code && resData.data) {
                    return resData;
                }
            }

            // throw error;
        }
    }),
    
};

export default ProviderService;
