import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import EmployeeList from "./EmployeeList";
// import EmployeeDetailView from "./EmployeeDetailView";
// import VisitsList from "../EmployeeVisitContainer/VisitsList";
import EmployeeCharts from "./EmployeeCharts";
import EmployeeVisitDetailView from "../EmployeeVisitContainer/EmployeeVisitDetailView";

const EmployeeContainer = () => {

  return (
    <Wrap>  
      {/* Other common elements in UserContainer */}
      <Routes>
        <Route path="/" key={0}  element={<EmployeeList/>} />
        {/* <Route path="visit-history/:patientId/:patientName" key={1}  element={<VisitsList/>} /> */}
        <Route path="visit-history/:patientId/:patientDetails" key={1}  element={<EmployeeCharts/>} />
        <Route path="visit-history/:patientId/:patientDetails/:visitId" key={2}  element={<EmployeeVisitDetailView/>} />
        {/* <Route path="/permissions" key={1} element={</>}/> */}
      </Routes>
      {/* <Outlet /> */}
  
     
        
    </Wrap>
  );
};






export default EmployeeContainer;
