import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isObject, isSet } from "../../../../../utils/commonUtils";
import FormInputs from "../../../../../components/UI/FormInputs";
// import { useLocation, useNavigate } from "react-router-dom";
// import LabService from "../../services/LabService";
// import { useToast } from '../../context/ToaxtContext';
// import LookupService from "../../../../services/LookupService";
// import ActivitiesService from "../../../../../services/ActivitiesService";
import WithRoutify from "../../../../../hoc/WithRoutify";
import WithRouter from "../../../../../hoc/WithRouter";
import Employeeervice from "../../../../../services/EmployeeService";

const AssignCompany = (props) => {
  const { isOpen, addToogleHandler, employee_id, modelTitle, Toast, options } = props;
  const [validation, setValidation] = useState({});


  const [formData, setFormData] = useState({
    employee_id: employee_id,
    // visit_id: params.visitId,
  })
  console.log(options);

  const inputFields = [

    {
      type: "SelectSearchList",
      label: "Company",
      placeholder: "Company Name",
      className: "form-control form-control-solid",
      name: "company_id",
      options: options,
      value: formData.company_id,
      required: true
    },
  ];
  const changeHandler = (val, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({ ...validation, [fieldName]: '' });
  };
  const formSubmitHandler = async () => {
    let data = await Employeeervice.assignCompanyForSelfRegisterPatients(isSet(formData.organization, null), formData);
    let updateValidation = { ...validation };
    // console.log('saveActivities',data);
    if (data.status && data.status === "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data.errors) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data.errors[key].join(","),
          };
        }
      } else {
        props.Toast.error(data.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      props.fetchVisitDetails();
      props.assignToggleHandler();

      Toast.success('Company Assigned Successfully');
    }
  }
  return (
    <>
      <Modal show={isOpen} onHide={addToogleHandler} dialogClassName='' keyboard={false}
        contentClassName='h-100'
        backdrop="static" size="md">
        <div className="sh_modalBox1">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">
                {modelTitle}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <p>
                  <span style={{ color: 'red' }}>Required:</span> <span>Assign a Company to the Self Registered Patient first before adding physical</span>
                </p>
              </div>
            </div>
            <br/>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => (
                  <div className="col-12" key={index}>
                    <div className="form-group">
                      <label className="mb-0 text-md opacity-75"> {field.label} </label>
                      <FormInputs {...field} changeHandler={(value, e) => changeHandler(value, field.name)} />
                      {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="primary" className="" onClick={() => formSubmitHandler()}>
              Submit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default WithRouter(WithRoutify(AssignCompany));