const common = {
    NetworkError: 'Network error occurred. Please check your internet connection',
    SomethingWentsWrong: 'Something went wrong. Please contact admin',
    CellNumber:'Cell Number',
    Address:'Address',
    Street:'Street',
    City:'City',
    State:'State',
    Action:'Action',
    Code:'Code',
    Zip4:'Zip+4',
    County:"County",
    Fax:"Fax",
    TimeZone:'TimeZone',
    Add:'Add',
    Search:'Search',
    Clear:'Clear'
};

export default common;