import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ValidationForm = (props) => {
  const navigate = useNavigate();

  return (
    <>

      <Modal
        show={true}
        contentClassName='h-100'
        backdrop="static"
        keyboard={false}>
        <Modal.Header
          style={{ borderBottom: 'none', padding: '0.5rem' }}
        >

        </Modal.Header>
        <Modal.Body>

          <div className="text-center">
            <svg
              id="Layer_1"
              width="32px"
              height="32px"
              fill="green"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 457.57"
            >
              <defs>
                <style>{".cls-1{fill-rule:evenodd;}"}</style>
              </defs>
              <path
                className="cls-1"
                d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z"
              />
            </svg>
            <h6 className="text-lg font-weight-bold mb-2"> Your Patient Form is Completed Already </h6>


          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="primary"
            className=" "
          onClick={() => navigate('/login')}
          >
            Back to Login
          </Button>

          <Button
            variant="primary"
            className=""
          onClick={() => props.setValidForm(true)}
          >
            Continue Anyway
          </Button>

        </Modal.Footer>
      </Modal>



    </>
  );
}
export default ValidationForm;

 

