
import {  isSet } from "../../../../../../utils/commonUtils";

export const ScreeningHearing = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[
                {
                    label:'Standard: Must first perceive whispered voice at not less than 5 feet OR average hearing loss of less than or equal to 40 dB, in better ear (with or without hearing aid).',
                    type:'Label',
                    col:'col-12'                
                },
            {
                label:'Check if hearing aid used for test',
                col:'col-12',
                type:'RadioGroup',
                name:'check_hearing_aid_used',
                id:'check_hearing_aid_used',
                options:['Right Ear','Left Ear','Neither'],
            },
        ]
        },
         { 
            label:'Whisper Test Results',
            note:'Record distance (in feet) from driver at which a forced whispered voice can first be heard',
            fields:[
                {
                    label:'Right Ear',
                    type:'Number',
                    rangeMin:5,
                    name:'whisper_test_results_right_ear',
                    id:'whisper_test_results_right_ear',
                },
                {
                    label:'Left Ear',
                    type:'Number',
                    rangeMin:5,
                    name:'whisper_test_results_left_ear',
                    id:'whisper_test_results_left_ear',
                },
            ]
        },
        { 
            label:'OR: Audiometric Test Results',
            fields:[
                {
                    label:'Right Ear',
                    type:'Label',
                    appendClass:'bg-light',
                    col:'col-12',
                },
                {
                    label:'500 Hz',
                    type:'Number',
                    col:'col-6',
                    rangeMax:40,
                    name:'audiometric_test_results_right_ear_500_hz',
                    id:'audiometric_test_results_right_ear_500_hz',
                },
                {
                    label:'1000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-6',
                    name:'audiometric_test_results_right_ear_1000_hz',
                    id:'audiometric_test_results_right_ear_1000_hz',
                },
                {
                    label:'2000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-6',
                    name:'audiometric_test_results_right_ear_2000_hz',
                    id:'audiometric_test_results_right_ear_2000_hz',
                },
                {
                    label:'Average',
                    type:'Number',
                    readOnly:true,
                    rangeMax:40,
                    col:'col-6',
                    name:'audiometric_test_results_right_ear_average',
                    id:'audiometric_test_results_right_ear_average',
                },
                {
                    label:'Left Ear',
                    appendClass:'bg-light',
                    type:'Label',
                    col:'col-12',
                },
                {
                    label:'500 Hz',
                    type:'Number',
                    col:'col-6',
                    rangeMax:40,
                    name:'audiometric_test_results_left_ear_500_hz',
                    id:'audiometric_test_results_left_ear_500_hz',
                },
                {
                    label:'1000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-6',
                    name:'audiometric_test_results_left_ear_1000_hz',
                    id:'audiometric_test_results_left_ear_1000_hz',
                },
                {
                    label:'2000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-6',
                    name:'audiometric_test_results_left_ear_2000_hz',
                    id:'audiometric_test_results_left_ear_2000_hz',
                },
                {
                    label:'Average',
                    type:'Number',
                    rangeMax:40,
                    readOnly:true,
                    col:'col-6',
                    name:'audiometric_test_results_left_ear_average',
                    id:'audiometric_test_results_left_ear_average',
                },
            ]
        },
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    type:'TextArea',
                    id:'notes',
                    name:'notes',
                },
                {
                    label:'Suggested Result',
                    type:'TextInput',
                    disabled:true,
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'suggested_status',
                },
                {
                    type:'Blank',
                    name:'blank'
                },
                {
                    label:'Final Determination',
                    type:'SelectList',
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'status',
                },
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
    
    let returnData = {};
    const earPrefixes = ['right_ear_', 'left_ear_'];
    const whisperTestRightEar = isSet(formData[`whisper_test_results_right_ear`], '');
    const whisperTestLeftEar = isSet(formData[`whisper_test_results_left_ear`], '');
    earPrefixes.forEach(earPrefix => {
        const frequencies = [500, 1000, 2000];
        let total = 0;
        frequencies.forEach(frequency => {
            const value = isSet(formData[`audiometric_test_results_${earPrefix}${frequency}_hz`], '');
            total += Number(value);
        });
        const average = (total / frequencies.length).toFixed(2);

        returnData[`audiometric_test_results_${earPrefix}average`] = average;
    });

    
    const leftAverage = returnData[`audiometric_test_results_right_ear_average`];
    const rightAverage = returnData[`audiometric_test_results_left_ear_average`];
    let status = 'Not Started';
    if(leftAverage==='0.00' && rightAverage==='0.00' && whisperTestRightEar=='' && whisperTestLeftEar===''){
        status = 'Not Started';
    }else if ((leftAverage!=='0.00' && leftAverage <= 40 && leftAverage !== 0) || (rightAverage!=='0.00' && rightAverage <= 40 && rightAverage !== 0) || (whisperTestRightEar >= 5 || whisperTestLeftEar >= 5)) {
        // console.log('ssss',leftAverage,rightAverage,whisperTestRightEar,whisperTestLeftEar);
        status = 'Passed';
    } else if ((leftAverage === 0 || rightAverage === 0) && (leftAverage > 40 || rightAverage > 40)) {
        status = 'Failed';
    } else if(leftAverage > 40 || rightAverage > 40 || (whisperTestRightEar < 5 && whisperTestLeftEar < 5)){
        status = 'Failed';
    }else{
        status = 'Pending';
    }

    return (key==='status')?{...returnData}:{...returnData,'status':status,'suggested_status':status};

}