import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import WithRouter from "../../hoc/WithRouter";
import WithRoutify from "../../hoc/WithRoutify";
import Employeeervice from "../../services/EmployeeService";
import DropDownService from "../../services/DropDownService";
import { isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../UI/FormInputs";

const AssignCompany = (props) => {
    const { modleToogleHandler, employeeId, Toast } = props;
    const [options, setOptions] = useState([]);
    const [validation, setValidation] = useState({});
    const [formData, setFormData] = useState({
        employee_id: employeeId,
    });
    useEffect(() => {
        getAssignCompanyOptionsForSelfRegister();
    }, []);
    const getAssignCompanyOptionsForSelfRegister = async () => {
        const EmployeeDetails = await Employeeervice.getEmployee(employeeId);
        console.log('EmployyeDetails', EmployeeDetails);
        const assignCompanyOptions = await DropDownService.getAssignCompanyOptionsForSelfRegister(EmployeeDetails.clinic_id);
        let options = [{ label: '-Select Company-', value: '' }];
        assignCompanyOptions.forEach(val => {
            options.push({ label: val.name, value: val.company_uuid });
        });
        setOptions(options);
    }

    const inputFields = [

        {
            type: "SelectSearchList",
            label: "Company",
            placeholder: "Company Name",
            className: "form-control form-control-solid",
            name: "company_id",
            options: options,
            value: formData.company_id,
            required: true
        },
    ];
    const changeHandler = (val, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: val,
        });
        setValidation({ ...validation, [fieldName]: '' });
    };
    const formSubmitHandler = async () => {
        let data = await Employeeervice.assignCompanyForSelfRegisterPatients(isSet(formData.organization, null), formData);
        console.log('data', data);
        let updateValidation = {};
        // console.log('saveActivities',data);
        if (data.status && data.status === "ERROR") {
            if (isObject(data.data)) {
                for (let key in data.data) {
                    updateValidation = {
                        ...updateValidation,
                        [key]: data.data[key].join(","),
                    };
                }
            } else {
                props.Toast.error(data.data);
            }
            if (Object.keys(updateValidation).length > 0) {
                setValidation(updateValidation);
            }
        } else {
            modleToogleHandler();
            Toast.success('Company Assigned Successfully');

        }
    }
    console.log('validation ', validation);
    return (
        <>
            <Modal show={true} onHide={modleToogleHandler} dialogClassName='' keyboard={false}
                contentClassName='h-100'
                backdrop="static" size="md">
                <div className="sh_modalBox1">
                    <Modal.Header >
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">
                                {'Company Assign'}
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <p>
                                    <span style={{ color: 'red' }}>Required:</span> <span>Assign a Company to the Patient first</span>
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            {inputFields &&
                                inputFields.map((field, index) => (
                                    <div className="col-12" key={index}>
                                        <div className="form-group">
                                            <label className="mb-0 text-md opacity-75"> {field.label} </label>
                                            <FormInputs {...field} changeHandler={(value, e) => changeHandler(value, field.name)} />
                                            {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="" onClick={() => formSubmitHandler()}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default WithRouter(WithRoutify(AssignCompany));