import React, { memo }  from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';

// import {setAndGetAttributes} from './FormInputFunctions';

const TextInput = (props)=>{
  // Props Attr
   const {
    type,
    className,
    placeholder,
    value,
    style,
    hidden,
    name,
    required,
    appendClassName,
    disabled,
    readOnly,
    id
  } = props;
  // Handler
   const {
    changeHandler,
    onKeyPressHandler,
    onKeyDownHandler,
    isFirstLetterCaps,
    allUpperCase,
    max,
    isNumeric,
    isDecimal
  } = props;

  const onChangeHandler = (e) => {
    let val = e.target.value;
    
    // Convert the first letter of each word to uppercase if required
    if (isFirstLetterCaps) {
      val = val.split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
    }
  
    // Convert non-numeric characters to uppercase
    if (allUpperCase) {
      val = val.replace(/[^a-zA-Z]/g, '').toUpperCase();
    }
  
    // Validate against max value if provided
    if (max) {
      const maxValue = parseInt(max);
        val = val.slice(0, maxValue);
    }
    if (isNumeric) {
      val = val.replace(/\D/g, ''); // Replace non-numeric characters with empty string
    }
    if (isDecimal) {
      val = val.replace(/[^\d.]/g, ''); // Replace characters that are not digits or dots with empty string
    }
  
    changeHandler(val, {e});
  }
  
    return(
        <input 
          type={'text'}
          id={id}
          className={className+' '+appendClassName}
          name={name}
          placeholder={placeholder}
          value={value}
          style={{...style}}
          onKeyUp ={onKeyPressHandler}
          onKeyDown ={onKeyDownHandler}
          required={required}
          hidden={hidden}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e)=>onChangeHandler(e)}   />    
    )
}
TextInput.defaultProps = {
  type:'text',
  isMandatory:false,
  className: 'form-control form-control-solid',
  placeholder:'',
  appendClassName:'',
  value:'',
  id:'',
  style:{},
  onKeyPressHandler:()=>{},
  onKeyDownHandler:()=>{},
  readOnly:false,
  disabled:false,
  required:false,
  isNumeric:false,
  isDecimal:false,
  hidden:false,
  validation:{
    isValid:true,message:''
  },

}
TextInput.propTypes = {
  type: PropTypes.string,
  isMandatory: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  // validationRules: PropTypes.array,
  // valid: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
};

export default memo(TextInput);
