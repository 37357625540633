import FormInputs from "../../../components/UI/FormInputs";
import { isSet } from "../../../utils/commonUtils";
import SwitchFields from "../EmployeeVisitDetailView/RightContent/ActivityChart/ChartsFields";

const VisitDetailsTab = (props) => {
    const { inputFields, formData, validations,onChangeHandler,setAllToNo ,hasEditAccess} = props;
    return (
        <>
            {inputFields.map((section, secIndex) => {
                if (!section || !section.data) return null;
                return (
                    <div className="card mb-2" key={secIndex}>
                        <a href={`#collapseCardExample_${secIndex}`} className="d-block sh_accordionheader card-header" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseCardExample" style={{ position: 'sticky', top: '-20px', zIndex: '1' }}>
                            <h6 className="m-0 text-md">{section.label}</h6>
                        </a>
                        <div className="collapse show" id={`collapseCardExample_${secIndex}`} data-bs-parent="#modal111">
                            <div className="card-body">
                                <div className="row">
                                    {section.data.map((item, itemIndex) => {
                                        const value = isSet(formData[item.name], '');
                                        return (
                                            <div className="col-sm-12" key={itemIndex}>
                                                {item.type === "Label" || item.type === "YesOrNoTable"
                                                    ?

                                                    <div className=""><SwitchFields
                                                        key={itemIndex}
                                                        col={"col-12"}
                                                        readOnly={isSet(item.readOnly, !hasEditAccess)}
                                                        itemIndex={itemIndex}
                                                        formData={formData}
                                                        changeHandler={(name, val) => {onChangeHandler(name, val, {}) }}
                                                        // updateFormData={this.setAllToNo}
                                                        item={{ ...item }} />
                                                        {item.type === "YesOrNoTable" ? <br /> : <></>}
                                                    </div>
                                                    :
                                                    <div className={item.type === 'RadioGroup' ? 'row' : "form-group row"}>
                                                        <label className={item.col ? item.col : "col-sm-6 col-form-label opacity-75"} style={{ color: (item.type === 'RadioGroup' ? '#231B68' : '') }} >{item.label}</label>
                                                        <div className={item.options ? "col-sm-6" : (item.col ? item.col : "col-sm-6")} >
                                                            {item.type === 'button' ? <><a onClick={() => {
                                                                if (hasEditAccess) {
                                                                    props.setAllToNo();
                                                                }
                                                            }} 
                                                            className="col-12 badge bg-blue-soft text-light cursor-pointer custom-btn" >
                                                                {item.name}
                                                            </a><br /><br /></> : <FormInputs {...item} value={value} readOnly={isSet(item.readOnly, !hasEditAccess)}
                                                                changeHandler={(val, e) => {onChangeHandler(item.name, val, e) }} />}

                                                            {isSet(validations[item.name], '') !== '' ? <span className="text-danger m-1">{isSet(validations[item.name], '')}</span> : ''}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
}
export default VisitDetailsTab;