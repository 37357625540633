import React from "react";
import { isSet } from "../../../../../../utils/commonUtils";
import FormInputs from "../../../../../../components/UI/FormInputs";

const YesOrNoFields = ({ notes,options,col,failedValue, list,label,appendClass,readOnly,validation,...props}) => {
//    console.log('updateFormData',props)
    
    return (
        <>      
            {isSet(notes) && notes.length > 0 ?
                <div className="col-4">
                    <strong>{label}</strong>
                        <ul style={{ marginLeft: '30px'}}>
                            {notes.map((itm, itmIndex) => {
                                return (
                                    <li key={itmIndex}>{itm}</li>
                                )
                            })}
                        </ul>
                </div>
            : <></>}

                <div className={`${isSet(col,'col-8')} px-0`}>
                    <table className={`table table-striped table-bordered mb-0  ${appendClass}`}>
                        <thead>
                            <tr>
                                <th>
                                    <a onClick={()=>props.updateFormData(isSet(props.defaultValues,{}))} className="badge bg-blue-soft text-light cursor-pointer custom-btn" style={{cursor:'pointer'}}>
                                        {props.defaultBtnLabel}
                                    </a></th>
                                {options.map((option,optionIndex)=>{
                                    return(
                                        <th key={optionIndex}>{option.label}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((tData, tIndex) => {
                                
                                return (
                                    <>
                                    <tr key={tIndex}>
                                        <td {...isSet(tData.attr,{})} >
                                            {(isSet(tData.isSubField,false)===true)?
                                                <span style={{paddingLeft:'20px'}}>{tData.label}</span>
                                            :tData.label
                                            } 
                                            {
                                                (isSet(validation) && isSet(validation[tData.name], "") !== "" ? (
                                                    <span className="text-danger m-1">
                                                        {isSet(validation[tData.name], "")}
                                                    </span>
                                                ) : (
                                                    ""
                                                ))
                                            }
                                        </td>
                                        {options.map((option,optionIndex)=>{
                                            const value = (isSet(props.formData[tData.name],null)==option.value)?true:false;

                                            let tdStyle = {};

                                            if(isSet(failedValue,null)!==null &&  failedValue.indexOf(option.value)>=0 &&   failedValue.indexOf(isSet(props.formData[tData.name],null)) === failedValue.indexOf(option.value) ){
                                                tdStyle = {backgroundColor:'red',borderColor:'red'};
                                            }
                                            
                                            return(
                                                <>
                                                <td key={optionIndex}>
                                                {(isSet(tData.name,'')!=='')?
                                                <>
                                                    <div className="form-check">
                                                        <FormInputs 
                                                            className="form-check-input" 
                                                            type='Radio'  
                                                            style={{...tdStyle}}
                                                            name={`${tData.name}_${tIndex}`} 
                                                            id={`checkbox_${tData.name}${tIndex}_${optionIndex}`} 
                                                            value={value}
                                                            readOnly={readOnly}
                                                            onKeyPress={tData.onKeyPress}
                                                            changeHandler={()=>props.changeHandler(tData.name,option.value)} /> 
                                                    </div>
                                                    
                                                     </>
                                                :<></>}
                                                   
                                                </td>
                                               
                                                
                                                </>
                                            )
                                        })}
                                      
                                    </tr>
                                    
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
        </>
    )
}

YesOrNoFields.defaultProps = {
 appendClass:'mt-3',
 failedValue:null,
 validation:{}
}

export default YesOrNoFields;
