import React from 'react';
import CalendarViewTypes from './CalendarViewTypes';
import CalendarToolbarAndLabel from './CalendarToolbarAndLabel';
import { fetchCurrentDate } from '../../../utils/DateUtils';


const CalendarHeader =(props)=>{

	const {
		calendarViewType,
		viewTypeChangeHandler,
		currentDay,
		label,
		calendarOptions,
		calendarOptionOnChangeHandler
	} = props;

	const toolBarChangeHandler = (type)=>{
		var now = currentDay;
		if(type==='NEXT'){
			if(calendarViewType==='WEEK'){
				const firstDayOfWeek = new Date(currentDay.setDate(currentDay.getDate() - currentDay.getDay() ));
				current = new Date(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate()+7);
			}else if(calendarViewType==='MONTH'){
				if (now.getMonth() == 11) {
					var current = new Date(now.getFullYear() + 1, 0, 1);
				} else {
					var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
				}
			}else{
				current = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate()+1);
			}
			
		}else if(type==='PREV'){
			if(calendarViewType==='WEEK'){
				const firstDayOfWeek = new Date(currentDay.setDate(currentDay.getDate() - currentDay.getDay() ));
				current = new Date(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate()-7);
			}else if(calendarViewType==='MONTH'){
				if (now.getMonth() == 0) {
					current = new Date(now.getFullYear() - 1, 11, 1);
				} else {
					current = new Date(now.getFullYear(), now.getMonth() - 1, 1);
				}
			}else{
				current = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate()-1);
			}
		}else{
			current = fetchCurrentDate();
		}
		props.currentDayChangeHandler(current,(type==='PREV')?'Left':(type==='NEXT')?'Right':null);
		
	};
    return(
      <>
				<CalendarViewTypes 
					{...props}
					calendarOptionOnChangeHandler={calendarOptionOnChangeHandler}
					calendarOptions={calendarOptions}
					changeHandler={viewTypeChangeHandler}
					selectedValue={calendarViewType} />

				<CalendarToolbarAndLabel 
					changeHandler={toolBarChangeHandler}
					label={label}	/>
	</>
		
    )   
}

export default CalendarHeader;