import React from "react";

const CalendarToolbarAndLabel = (props)=>{
    const {changeHandler,label} = props;
    return(
         <>
        {/* <div className="fc-left w-100">
            
            <NextAndPrevToolBar 
                type='PREV'
                changeHandler={changeHandler}/>
            <NextAndPrevToolBar 
                 type='NEXT'
                changeHandler={changeHandler}/>
                <CalendarLabel 
                label={label} />
		</div> */}
        </>
    )
}


const NextAndPrevToolBar = ({changeHandler,type})=>{
    const icon = (type==='PREV')?'left':'right';
    let classArr = ["cursor-pointer","fa-lg","text-primary","pl-3","fa fa-chevron-"+icon];
    return(
        <span className={classArr.join(' ')} onClick={()=>changeHandler(type)}></span> 
    )
}



const CalendarLabel = ({label})=>{
    return(
        <span className="text-lg font-weight-bold pl-3"> {label} </span> 
    )
}
export default CalendarToolbarAndLabel;