import { createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
// import { isSet } from '../utils/commonUtils';


export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    toasts: [],
    reloadKeyProps:null,
    isLoading:false,
    isSessionIsActive: true, 
    sessionTimeoutId: null,
  },
  reducers: {
    showToastAction: (state, action) => {
      const { type, message,id } = action.payload;
      state.toasts.push({ id, type, message });
    },
    hideToast: (state, action) => {
      const id = action.payload;
      state.toasts = state.toasts.filter(toast => toast.id !== id);
    },
    reloadPropsAction: (state,action) => {
      state.reloadKeyProps  = action.payload;
    },
    loader: (state,action=false) => {
      state.isLoading   = action.payload;
      //(isSet(action.payload,null)===null)?!state.isLoading:action.payload
    },
    setSessionIsActive: (state, action) => {
      state.isSessionIsActive = action.payload;
    },
    clearSessionTimeout:(state)=>{
      clearTimeout(state.sessionTimeoutId);
      state.sessionTimeoutId = null;
    },
    setSessionTimeoutId: (state, action) => { 
      state.sessionTimeoutId = action.payload;
    },

  },
});

export const { showToastAction, hideToast,loader,setSessionIsActive,clearSessionTimeout,setSessionTimeoutId, reloadPropsAction} = commonSlice.actions;
export default commonSlice.reducer;



// Toast utility function
export const success = (message) => (dispatch) => {
  dispatch(showToast({ type: 'success', message }));
};

export const error = (message) => (dispatch) => {
  dispatch(showToast({ type: 'error', message }));
};


export const warning = (message) => (dispatch) => {
  dispatch(showToast({ type: 'warning', message }));
};


export const showToast = ({type, message,}) => (dispatch) => {
  const id = Date.now();
  dispatch(showToastAction({ type, message, id }));
  // Automatically hide the toast after the specified timeout
  setTimeout(() => {
    dispatch(hideToast(id));
  }, 1000);
};


export const resetSessionTimeout = (timeout) => (dispatch, getState) => {
  const { sessionTimeoutId } = getState().common;
  let updateSessionTimeoutId = sessionTimeoutId;
  if (updateSessionTimeoutId!==null){ 
    clearTimeout(updateSessionTimeoutId); 
    updateSessionTimeoutId = null;
    const startTime = Date.now();
    localStorage.setItem('sessionStartTime', startTime.toString());
    localStorage.setItem('sessionTimeoutDuration', timeout.toString());  
  }
  
  updateSessionTimeoutId = setTimeout(() => {
      if (AuthService.isAuthenticated()) {
          dispatch(setSessionIsActive(false));
      }
  }, parseInt(timeout));
  dispatch(setSessionTimeoutId(updateSessionTimeoutId));
}


export const checkSessionTimeout = () => (dispatch) => {
  const startTime       = parseInt(localStorage.getItem('sessionStartTime'));
  const timeoutDuration = parseInt(localStorage.getItem('sessionTimeoutDuration'));

  if (!isNaN(startTime) && !isNaN(timeoutDuration)) {
    const currentTime = Date.now();
    const sessionEndTime = startTime + timeoutDuration;
    // console.log('currentTime',currentTime,sessionEndTime);
    if (currentTime > sessionEndTime) {
      if (AuthService.isAuthenticated()) {
        dispatch(setSessionIsActive(false));
      }
    }
  }
};