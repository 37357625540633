import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import CompanyList from "./CompanyList";
import CompanyPhysicalList from "./CompanyPhysicalList";
import AssignActivities from "../BasicPhysicalContainer/PhysicalActivities";

const CompanyContainer=()=>{
    return(
        <>
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<CompanyList />} />
        <Route path="/physical/:companyId/:companyName/activities/:physicalId/:physicalName" key={1}  element={<AssignActivities isFromCompany={true} />} />
        <Route path="/physical/:companyId/:companyName" key={2}  element={<CompanyPhysicalList  />} />
        {/* <Route path="/detail-view/:companyId/:action" key={3}  element={<CompanyDetailViewContainer/>} /> */}
        
      </Routes>
      </Wrap>
      </>
    );
}
export default CompanyContainer; 

// /physical/ac58a542479d4b71b1f9d2fce0299dba/Precision%20Health%20Services/activities/2bbb677becef478cbc4fe5ef13e5d8e1/sdfsdf