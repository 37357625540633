import React from "react";

const ChangePasswordIcon = (props) => {

    return (
    <>      
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="password" width={props.size} height={props.size}> <path fill="#6ca4ff" d="M19,10H5V8A7,7,0,0,1,19,8ZM7,8H17A5,5,0,0,0,7,8Z"></path><path fill="#0554f1" d="M19,20H5a4,4,0,0,1-4-4V12A4,4,0,0,1,5,8H19a4,4,0,0,1,4,4v4A4,4,0,0,1,19,20ZM5,10a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2Z"></path><path fill="#0554f1" d="M19,9H5a3,3,0,0,0-3,3v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V12A3,3,0,0,0,19,9ZM6,15a1,1,0,1,1,1-1A1,1,0,0,1,6,15Zm4,0a1,1,0,1,1,1-1A1,1,0,0,1,10,15Zm4,0a1,1,0,1,1,1-1A1,1,0,0,1,14,15Zm4,0a1,1,0,1,1,1-1A1,1,0,0,1,18,15Z"></path> </svg>
    </>
    );

};
ChangePasswordIcon.defaultProps ={
    size:20
}
export default ChangePasswordIcon;