
import { isSet } from "../../../../../../utils/commonUtils";

export const ScreeningHW = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[
                {
                    label:'Standard: Must first perceive whispered voice at not less than 5 feet ',
                    type:'Label',
                    col:'col-12'                
                },
            {
                label:'Check if hearing aid used for test',
                col:'col-12',
                type:'RadioGroup',
                name:'check_hearing_aid_used',
                id:'check_hearing_aid_used',
                options:['Right Ear','Left Ear','Neither'],
            },
        ]
        },
         { 
            label:'Whisper Test Results',
            note:'Record distance (in feet) from driver at which a forced whispered voice can first be heard',
            fields:[
                {
                    label:'Right Ear',
                    type:'Number',
                    rangeMin:5,
                    name:'whisper_test_results_right_ear',
                    id:'whisper_test_results_right_ear',
                },
                {
                    label:'Left Ear',
                    type:'Number',
                    rangeMin:5,
                    name:'whisper_test_results_left_ear',
                    id:'whisper_test_results_left_ear',
                },
            ]
        },
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
                {
                    label:'Suggested Result',
                    type:'TextInput',
                    disabled:true,
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'suggested_status',
                },
                {
                    type:'Blank',
                    name:'blank'
                },
                {
                    label:'Final Determination',
                    type:'SelectList',
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'status',
                },
            
           
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
    let status = 'Not Started';
    const horizontalRightEar = isSet(formData['whisper_test_results_right_ear'],'');
    const horizontalLeftEar = isSet(formData['whisper_test_results_left_ear'],'');
    if(horizontalRightEar==='' && horizontalLeftEar===''){
        status = 'Pending';
    }else if(horizontalRightEar<5 &&  horizontalLeftEar<5){
        status = 'Failed';
    }else{
        status = 'Passed';
    }
    return (key==='status')?{}:{'status':status,'suggested_status':status};
}