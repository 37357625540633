import React from "react";
// import {MONTHS,WEEKDAYS_SHORT}  from '../../../constants';
import {tConv24, rearrangeDataForWeekView, calendarWidgetVisitTypeBasedColor} from '../../../calendarFunctions'; 
import Wrap from "../../../../../hoc/Wrap";
import { isSet } from "../../../../../utils/commonUtils";


const WeekViewBody = (props)=>{
    const {timeInterval,currentWeek,showMoreDetailsDataHandler,todayDate} = props;
    const calendarWeeksRecords = rearrangeDataForWeekView(props.calendarRecords);
    return(
        <tbody className="fc-body">
        <tr>
           <td className="fc-widget-content">

              <div className="fc-scroller fc-time-grid-container" /*style={{height:'653.109px'}} */ >
                 <div className="fc-time-grid">
                    <div className="fc-bg">
                       <table className="">
                          <tbody>
                             <tr>
                             <td className="fc-axis fc-widget-content" style={{width:'47.7188px'}}></td>
                              {currentWeek.map((item,itemIndex)=>{
                                 let classArr = [];
                                 if(new Date(todayDate).setHours(0, 0, 0, 0)===new Date(item.date).setHours(0, 0, 0, 0)){
                                    classArr.push('fc-day-today');
                                }
                                 return(
                                    <td key={itemIndex} className={`fc-day fc-widget-content fc-${item.displayWeekday} ${classArr.join(' ')} fc-past`} data-date={item.c_date}></td>
                                 )
                              })}
                             </tr>
                          </tbody>
                       </table>
                    </div>
                    <div className="fc-slats">
                       <table className="">
                          <tbody>
                            {
                              
                              timeInterval.map((time,timeIndex)=>{
                                return(
                                    <Wrap key={'time'+timeIndex}>
                                       <tr data-time={time}  >
                                           <td className="fc-axis fc-time fc-widget-content" style={{width:'47.7188px'}}>
                                               <span>{tConv24(time)}</span>
                                           </td>
                                           {
                                             currentWeek.map((dateItem,ind)=>{
                                                const timeIn12 = tConv24(time);
                                                if(calendarWeeksRecords){
                                                   const itemData = isSet(calendarWeeksRecords?.[dateItem.c_date]?.[timeIn12], []);
                                                   
                                                   return(
                                                      <td data-date={dateItem.c_date} onClick={()=>props.addVisitToogleHandler({visit_date:dateItem.c_date,visit_time:tConv24(time)})} className="fc-widget-content">
                                                         {
                                                            isSet(itemData,[]).map((item,ind)=>{
                                                               // let getHour = item.getHour;
                                                               if(ind>1){
                                                                     return false;
                                                               } 
                                                               const typeBasedClass = calendarWidgetVisitTypeBasedColor(item.visit_category);
                                                               return(
                                                                  <>
                                                                     <a 
                                                                        title={`${item.visit_time} : ${item.employee.first_name} ${item.employee.last_name}`}
                                                                        onClick={(e)=>{e.stopPropagation(); props.showQuickVisitView(e,item)}}>
                                                                     <div className={`mainValueTag ${typeBasedClass}`} data-toggle="dropdown">
                                                                           
                                                                                       <>
                                                                                       <span className="text-sm font-weight-bold">{item.visit_time}</span> 
                                                                                          <span className="font-weight-bold"> : </span>
                                                                                          <span className="text-sm">{item.employee.first_name} {item.employee.last_name}</span>  

                                                                                         
                                                                                       </> 
                                                                                                                                                                                    
                                                                     </div> 
                                                                     </a>                                                       
                                                                                                                                         
                                                                     {
                                                                       (ind===1 && itemData.length>2)?
                                                                       <a className="text-danger textUnderline text-small quick-sidebar-toggler cursor-pointer" onClick={(e)=>showMoreDetailsDataHandler(e,{date:dateItem.c_date,time:timeIn12},isSet(itemData,[]))}> + More ... </a>
                                                                       :
                                                                       <></>   
                                                                     }
                                                                  </>
                                                               )                                                            
                                                            })                                                      
                                                         }
                                                      </td>  
                                                   )
                                                }else{
                                                   return(
                                                      <td data-date={dateItem.c_date} onClick={()=>props.addVisitToogleHandler({visit_date:dateItem.c_date,visit_time:timeIn12})} className="fc-widget-content"></td>  
                                                   ) 
                                                }
                                                
                                             })
                                           }                                        
                                       </tr>
                                       <tr className="fc-minor" id={`calendarRow_${(time.toString().replace(':',''))}`}>
                                           <td className="fc-axis fc-time fc-widget-content" style={{width:'47.7188px'}}></td>
                                           {
                                             currentWeek.map((dateItem,ind)=>{
                                                return(
                                                   <td key={'timebottom'+ind} data-date={dateItem.c_date} onClick={()=>props.addVisitToogleHandler({visit_date:dateItem.c_date,visit_time:tConv24(time)})} className="fc-widget-content"></td>  
                                                )
                                             })
                                           }
                                       </tr>
                                    </Wrap>
                                )
                              })
                           
                           }
                             
                             
                          </tbody>
                       </table>
                    </div> 
                   
                 </div>
              </div>
          </td>
        </tr>
    </tbody>
    )
}

WeekViewBody.defaultProps = {
   isShowActivities:false
}
export default WeekViewBody;