
import { isSet } from "../../../../../../utils/commonUtils";

export const ScreeningVF = (props,type)=>{
    const {formData} = props;
    let note = '';
    let activityFields = [];
    if(type==='VF'){
        note = 'At least 70° field of vision in horizontal meridian measured in each eye.';
        activityFields = [
            {
                label:'Horizontal Field of Vision',
                type:'Label',
                appendClass:'bg-light',
                col:'col-12',
            },
            {
                label:'Right Eye: (degrees)',
                type:'TextInput',
                col:'col-6',
                rangeMin:70,
                name:'horizontal_field_of_vision_right_eye',
                id:'horizontal_field_of_vision_right_eye',
            },
            {
                label:'Left Eye: (degrees)',
                type:'TextInput',
                col:'col-6',
                rangeMin:70,
                name:'horizontal_field_of_vision_left_eye',
                id:'horizontal_field_of_vision_left_eye',
            }
        ]
    }else  if(type==='VJ'){
        note = 'Standard is below 20/40 acuity (Jaeger) in each eye with or without correction.';
        activityFields =[
            {
                label:'Uncorrected',
                type:'Label',
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                appendClass:'bg-light',
                col:'col-12',
            },
            {
                label:'Right Eye',
                type:'TextInput',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                col:'col-6',
                name:'uncorrected_right_eye',
                id:'uncorrected_right_eye',
            },
            {
                label:'Left Eye:',
                type:'TextInput',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                col:'col-6',
                name:'uncorrected_left_eye',
                id:'uncorrected_left_eye',
            },
            {
                label:'Both Eyes:',
                type:'TextInput',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                col:'col-6',
                name:'uncorrected_both_eyes',
                id:'uncorrected_both_eyes',
            },

            {
                label:'Corrected',
                type:'Label',
                appendClass:'bg-light',
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                col:'col-12',
            },
            {
                label:'Right Eye',
                type:'TextInput',
                col:'col-6',
                rangeMax:40,
                isNumeric:true,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                name:'corrected_right_eye',
                id:'corrected_right_eye',
            },
            {
                label:'Left Eye:',
                type:'TextInput',
                col:'col-6',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                name:'corrected_left_eye',
                id:'corrected_left_eye',
            },
            {
                label:'Both Eyes:',
                type:'TextInput',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                col:'col-6',
                name:'corrected_both_eyes',
                id:'corrected_both_eyes',
            },
        ]
    }else  if(type==='VS'){
        note = 'Standard is below 20/40 acuity (Snelling) in each eye with or without correction.';
        activityFields =[
            {
                label:'Uncorrected',
                type:'Label',
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                appendClass:'bg-light',
                col:'col-12',
            },
            {
                label:'Right Eye',
                type:'TextInput',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                col:'col-4',
                name:'uncorrected_right_eye',
                id:'uncorrected_right_eye',
            },
            {
                label:'Left Eye:',
                type:'TextInput',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                col:'col-4',
                name:'uncorrected_left_eye',
                id:'uncorrected_left_eye',
            },
            {
                label:'Both Eyes:',
                type:'TextInput',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                col:'col-4',
                name:'uncorrected_both_eyes',
                id:'uncorrected_both_eyes',
            },

            {
                label:'Corrected',
                type:'Label',
                appendClass:'bg-light',
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                col:'col-12',
            },
            {
                label:'Right Eye',
                type:'TextInput',
                col:'col-4',
                rangeMax:40,
                isNumeric:true,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                name:'corrected_right_eye',
                id:'corrected_right_eye',
            },
            {
                label:'Left Eye:',
                type:'TextInput',
                col:'col-4',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                name:'corrected_left_eye',
                id:'corrected_left_eye',
            },
            {
                label:'Both Eyes:',
                type:'TextInput',
                rangeMax:40,
                // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                isNumeric:true,
                col:'col-4',
                name:'corrected_both_eyes',
                id:'corrected_both_eyes',
            },
        ]
    }
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            note:note,
            fields:[
                {
                    label:'Wearing corrective lenses',
                    col:'col-6',
                    type:'RadioGroup',
                    name:'wearing_corrective_lenses',
                    id:'wearing_corrective_lenses',
                    options:[{label:'Yes',value:1},{label:'No',value:0}],
                },
                {
                    type:'Blank',
                    name:'blank'
                },
               ...activityFields
        ]
        },
    
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
                {
                    label:'Suggested Result',
                    type:'TextInput',
                    disabled:true,
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'suggested_status',
                },
                {
                    type:'Blank',
                    name:'blank'
                },
            {
                label:'Final Determination',
                type:'SelectList',
                options:isSet(props.lookupsOptions['chartResult'],[]),
                name:'status',
            },
           
           
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key,type)};
}


const computationRules = (formData,key,type)=>{
    let status = 'Not Started';
    const wearing_corrective_lenses = isSet(formData['wearing_corrective_lenses'],null);
    const conditions = wearing_corrective_lenses===0?'uncorrected':'corrected';
    if((type==='VS' || type=='VJ') && wearing_corrective_lenses!==null){
        // if(wearing_corrective_lenses===1){
            const rightEye  = isSet(formData[''+conditions+'_right_eye'],'');
            const bothEyes  = isSet(formData[''+conditions+'_both_eyes'],'');
            const leftEye  = isSet(formData[''+conditions+'_left_eye'],'');

            if (rightEye === '' || bothEyes === '' || leftEye === '') {
                status = 'Pending';
            } else if (rightEye > 40 || bothEyes > 40 || leftEye > 40) {
                status = 'Failed';
            } else {
                status = 'Passed';
            }
        // }
    }else if(type==='VF'){
        const horizontalRighteye = isSet(formData['horizontal_field_of_vision_right_eye'],'');
        const horizontalLeftEye = isSet(formData['horizontal_field_of_vision_left_eye'],'');
        if(horizontalRighteye==='' || horizontalLeftEye===''){
            status = 'Pending';
        }else if(horizontalRighteye<70 ||  horizontalLeftEye<70){
            status = 'Failed';
        }else{
            status = 'Passed';
        }
    }
    
    
    return (key==='status')?{}:{'status':status,'suggested_status':status};
}