import React from "react";
import FileManagerService from "../../../services/FileManagerService";
import WithRoutify from "../../../hoc/WithRoutify";
import { isSet } from "../../../utils/commonUtils";

const FileType = (props)=>{
    console.log('prosp',props);
    const value = props.file;
    const downloadFile = async (value)=>{
        props.Loader(true);
      try {
        await FileManagerService.downloadFile(value);
        props.Loader(false);
        } catch (error) {
            console.log('Error downloading file:', error);
            props.Loader(false);
        }
    }
    return(
        <td>
            {(isSet(value.file_id,null)!==null)?
                <div className='sh_fileupload tdfile'>
                <a  className='sh_fileupload_link' href='#!' onClick={()=>downloadFile(value.file_id)} title={value.original_name}>{value.original_name}</a>
                </div>
                :<></>}
        </td>
    )
}
FileType.defaultProps ={
    changeHandler:()=>{}
}

export default WithRoutify(FileType);