import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import SelfRegister from "./SelfRegister";


const SelfRegisterContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<SelfRegister/>} />
        <Route path="/:clinicId" key={1}  element={<SelfRegister/>} />
      </Routes>
  
     
        
    </Wrap>
  );
};
export default SelfRegisterContainer;
