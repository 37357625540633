
import {  isSet, optionsToKeyValuePairs } from "../../../../../../utils/commonUtils";

export const ScreeningLadderClimb = (props)=>{
    const {activityName} = props;
    let appendCharts = [];
    if(activityName==='Screening - EKG'){
        appendCharts = [{
            type:'SectionBased',
            col:'col-md-9',
            data:[
                {
                    label:'Activity Details - '+props.activityName,
                    fields:[{
                        label:'EKG',
                        col:'col-6',
                        type:'SelectList',
                        name:'screening_ekg',
                        id:'screening_ekg',
                        options:optionsToKeyValuePairs(['Abnormal','Normal','Incidental Finding - Referred to PCP','Abnormal - Referred to PCP']),
                    },
                    {
                        label:'Suggested Result',
                        type:'TextInput',
                        disabled:true,
                        options:isSet(props.lookupsOptions['chartResult'],[]),
                        name:'suggested_status',
                    },
                    {
                        label:'Notes',
                        col:'col-6',
                        type:'TextArea',
                        name:'notes',
                    },
                    {
                        label:'Final Determination',
                        type:'SelectList',
                        options:isSet(props.lookupsOptions['chartResult'],[]),
                        name:'status',
                    },
                    
                   
                ]
                }]
        }]
    }else{
        appendCharts = [{
            type:'SectionBased',
            col:'col-md-9',
            data:[
            {
                label:'Activity Details - '+props.activityName,
                fields:[
                
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
                {
                    label:'Status',
                    col:'col-6',
                    type:'SelectList',
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'status',
                },
               
                ]   
            }]
        }]
    }
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },...appendCharts];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{

    const screeningEkg = isSet(formData.screening_ekg,'');
    let status = 'Not Started';
    if(screeningEkg===''){
     status = 'Pending';
    }else if (screeningEkg==='Abnormal') {
         status = 'Failed';
     } else{
         status = 'Passed';
     }
     let statusUpdateExcludeKeys=['notes']; // status update for some fileds exculdes
     if (statusUpdateExcludeKeys.includes(key)) {
       return {}; 
     }
     return (key==='status')?{}:{'status':status,'suggested_status':status};
}