import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Animate } from 'react-move';
import { easeQuadInOut } from 'd3-ease';

const Progressbar = (props) => {
  const { className,value, duration } = props;
  const styles={
    pathColor: "#3498db",
    textColor: "#383838",
    trailColor: "#ecf0f1",
    backgroundColor: '#3e98c7',
    textSize: '16px',
    ...props.styles
  };
  const [isAnimated, setIsAnimated] = useState(true);
  const valueStart = 0;

  return (
    <>
      <div className={className}>
        <Animate
          start={() => ({
            value: valueStart
          })}
          update={() => ({
            value: [isAnimated ? value : valueStart], 
            timing: {
              duration: duration * 1000, 
              ease: easeQuadInOut
            }
          })}
        >
          {({ value }) => {
            const roundedValue = Math.round(value);
            return (
              <CircularProgressbar
                value={roundedValue}
                styles={buildStyles({
                  ...styles 
                })}
                // text={`${props.value}%`}
              />
            );
          }}
        </Animate>
      </div>
    </>
  );
};
Progressbar.defaultProps = {
  className:'',
  duration:1,
}
export default Progressbar;
