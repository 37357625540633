import React from "react";

const BackIcon = ({ width = 14, height = 14, color = "#ea4335", ...props }) => {
  return (
   <>
   {/* <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-arrow-left me-1"
    >
      <line x1="19" y1="12" x2="5" y2="12"></line>
      <polyline points="12 19 5 12 12 5"></polyline>
    </svg> */}
    </> 
  );
};
export default BackIcon;
