import React, { memo,useEffect }  from 'react';
import PropTypes from 'prop-types';

// import {setAndGetAttributes} from './FormInputFunctions';

const YesOrNo = (props)=>{
  // Props Attr
   const {
    className,
    readOnly,
    disabled,
    value,
    style,
    options,
    additionalAttrs,
    name,
    required,
    appendClassName,
    defaultSelect,
    id
  } = props;
  // Handler
   const {
    changeHandler
  } = props;
  useEffect(()=>{
    //when mounting default select is seted
    changeHandler(0,{});
  },[]);

    const onChangeHandler = (e)=>{
      changeHandler(e.target.value,{e});       
    } 
    return(
      <select
        className={className+' '+appendClassName}
        name={name}
        id={id}
        value={value}
        style={style}
        {...additionalAttrs }
        readOnly={readOnly}
        required={required}
        disabled={disabled}
        onChange={(e) => onChangeHandler(e)} >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>   
    )
}

YesOrNo.defaultProps = {
  isMandatory:false,
  required:false,
  className: 'form-control',
  appendClassName:'',
  placeholder:'',
  value:'',
  id:'',
  style:{},
  readOnly:false,
  disabled:false,
  options:[
    // {label:'-Select-',value:''},
    {label:'No',value:'0'},
    {label:'Yes',value:'1'}
  ],
  additionalAttrs :{},
  validation:{
    isValid:true,message:''
  },
  defaultSelect:0

}
 YesOrNo.propTypes = {
  type: PropTypes.string,
  isMandatory: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  // validationRules: PropTypes.array,
  // valid: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
};

export default memo(YesOrNo);
