import http from './http';
const HL7Service = {
  pushHL7LabOrder: async (data) => {
    try {
      const response = await http.POST(`hl7-push-order`,data);
      return response.data;
    } catch (error) {
      console.error('Error fetching :', error);
    }
  },
  pullHL7LabOrder: async (data) => {
    try {
      const response = await http.POST(`hl7-pull-result`,data);
      return response.data;
    } catch (error) {
      console.error('Error fetching :', error);
    }
  },  
  getHl7LogList: async (query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`hl7-log?${queryString}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching :', error);
    }
  },
  getHl7LogById: async (id) => {
    try {
      const response = await http.GET(`hl7-log/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching :', error);
    }
  },
  hl7MessageParse: async (body) => {
    try {
      const response = await http.POST(`hl7-message-parse`,body);
      return response.data;
    } catch (error) {
      console.error('Error fetching :', error);
    }
  },
  hl7ReprocessFile: async (body) => {
    const headers={
      'Content-Type': 'multipart/form-data'
    }
    try {
      const response = await http.POST(`hl7-reproces-file`,body,headers);
      return response.data;
    } catch (error) {
      console.error('Error fetching :', error);
    }
  },
  
};

export default HL7Service;