import React from 'react';

const QrCode= ({size}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 96 96" id="qr-code"><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" d="M86 67L86 79C86 82.866 82.866 86 79 86L67 86M86 29L86 17C86 13.134 82.866 10 79 10L67 10M29 86L17 86C13.134 86 10 82.866 10 79L10 67M10 29L10 17C10 13.134 13.134 10 17 10L29 10"></path><path stroke="#000" stroke-linecap="round" stroke-width="8" d="M5 48H91"></path></svg>
  );
};
QrCode.defaultProps = {
  size: 15,
};
export default QrCode