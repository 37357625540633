import React from 'react';

const SettingsIcon = ({size}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={size} height={size } viewBox="0 0 38.078 38.174">
      <defs>
        <linearGradient id="a" x1="0.773" y1="1.25" x2="0.227" y2="-0.25" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#6114f8" />
          <stop offset="0.167" stopColor="#6e23f9" />
          <stop offset="1" stopColor="#00b6ff" />
        </linearGradient>
      </defs>
      <path className="a" d="M37.078,15.918v5.338s-2.383.667-6,1.334a9.923,9.923,0,0,1-.572,1.525c2.383,2.955,3.908,5.052,3.908,5.052L29.072,34.5s-2.1-1.525-5.052-3.908c-.477.191-.953.381-1.525.572-.667,3.622-1.334,6-1.334,6H15.823s-.667-2.383-1.334-6a9.923,9.923,0,0,1-1.525-.572C10.008,32.98,7.911,34.5,7.911,34.5L2.574,29.167s1.525-2.1,3.908-5.052c-.191-.477-.381-1.048-.572-1.525C2.383,21.828,0,21.16,0,21.16V15.823s2.383-.667,6-1.334c.191-.572.381-1.048.572-1.525C4.194,10.008,2.669,7.911,2.669,7.911L8.007,2.574S10.1,4.1,13.058,6.482c.477-.191,1.048-.381,1.525-.572C15.251,2.383,15.918,0,15.918,0h5.338s.667,2.383,1.334,6c.477.191,1.048.381,1.525.572,2.955-2.383,5.052-3.908,5.052-3.908L34.5,8.007S32.98,10.1,30.6,13.058c.191.477.381.953.572,1.525C34.6,15.251,37.078,15.918,37.078,15.918Zm-18.587-4.1a6.672,6.672,0,1,0,6.672,6.672A6.631,6.631,0,0,0,18.492,11.819Z" transform="translate(0.5 0.5)" />
    </svg>
  );
};
SettingsIcon.defaultProps = {
  size: 24
};

export default SettingsIcon