import { useEffect, useRef, useState } from "react";
import FormInputs from "../../../../UI/FormInputs";
import Buttons from "../../../../UI/Buttons";
import WithDropdownState from "../../../../../hoc/WithDropdownState";


const EmployeeFilter = (props) => {
  const { closeHandler,filterData, toggleSearch,onChangeHandler, isOpen,submitHandler,clearFilterHandler,visitStatus,visitCategory,companyOptions,clinicOptions } = props;  
  // const menuRef = useRef(null);
  
  const clearHandler = () => {
    clearFilterHandler(); 
  };
  const inputFields = [
    {
      label:"Company",
      type: "SelectList",
      options: companyOptions,
      placeholder: "company_id",
      className: "form-control form-control-solid",
      name: "company_id",
      value: filterData.company_id,
    },
    {
      label:"Visit From",
      type: "Date",
      placeholder: "Visit From",
      className: "form-control form-control-solid",
      name: "visit_from",
      value: filterData.visit_from,
    },
    {
      label:"Visit To",
      type: "Date",
      placeholder: "Visit To",
      className: "form-control form-control-solid",
      name: "visit_to",
      value: filterData.visit_to,
    },
    {
      label:"Sample ID",
      type: "TextInput",
      className: "form-control form-control-solid",
      placeholder: "Sample ID",
      name: "sample_id",
      value: filterData.sample_id,
    },
    {
      label:"Visit Category",
      type: "SelectList",
      options: visitCategory,
      placeholder: "",
      className: "form-control form-control-solid",
      name: "visit_category",
      value: filterData.visit_category,
    },
    {
      label:"Visit Status",
      type: "SelectList",
      options: visitStatus,
      placeholder: "",
      className: "form-control form-control-solid",
      name: "visit_status",
      value: filterData.visit_status,
    },
    {
      label:"Clinic",
      type: "SelectList",
      options: clinicOptions,
      className: "form-control form-control-solid",
      placeholder: "",
      name: "clinic_id",
      value: filterData.clinic_id,
    },
    {
      label:"Cup ID",
      type: "TextInput",
      className: "form-control form-control-solid",
      placeholder: "Cup ID",
      name: "cup_id",
      value: filterData.cup_id,
    },
  ];
  return (
    <>
      <span style={{ marginLeft: '' }} className="" >
        <div id="collapsemenu"  class={`collapse ${isOpen?'show':''}`} style={{ zIndex:'1045',top: '45px', left: '25%', right: '25%', position: 'absolute' }}>
          <div class="collapse-inner mb-0">
            <div className="sh_modalBox shadow-lg">
              <div className="modal-header">
                <h6 className="text-lg mb-0 font-weight-bold">
                  Additional Search
                </h6>
                <Buttons
                  //iconType="Cancel"
                  className="btn-close text-sm"
                  acl={"any"}
                  clickHandler={() => toggleSearch()}
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  {
                    inputFields.map((itm, ind) => {
                      return (
                        <>
                          <div class="col-4">
                            <div class="form-group mb-1">
                              <label class="text-sm text-truncate mb-0 opacity-75">{itm.label}</label>
                              <div>
                                <FormInputs
                                placeholder={itm.placeholder}
                                className="form-control form-control-solid "
                                type={itm.type}
                                name={itm.name}
                                changeHandler={(value, e) =>
                                  onChangeHandler(itm.name,value, e)
                                }
                                {...itm}
                                
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })
                  }
                </div>

              </div>
              <div class="d-flex justify-content-center modal-footer py-0">
                <button type="button" class="btn btn-outline-secondary"  onClick={clearHandler}>Clear</button>
                <button type="button" class="btn btn-primary" onClick={()=>submitHandler(filterData)}>Search</button>
              </div>
            </div>
          </div>
        </div>
      </span>

      
    </>
  );
};
export default WithDropdownState(EmployeeFilter);
