import React from 'react';


const SearchIcon = ({size}) => {
  // console.log('compannie icon size',props.size);
    return (
      <>
     

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="search" width={size} height={size}> <path fill="#616161" d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z" data-name="search"></path> </svg>
      </>
    );
  };
  SearchIcon.defaultProps ={
    size:18
}

export default SearchIcon;
  