import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n/config'; // Import the i18n instance
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import {LoadScript} from '@react-google-maps/api';
import FeedbackPanel from './components/UI/Common';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.warn  = () => { }
}
root.render(
    <Provider store={store}>
    <FeedbackPanel />
      <PersistGate loading={null} persistor={persistor}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_LOCATION_API}
          libraries={['places']}
          loadingElement={<></>} >
        <App />
        </LoadScript>
      </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
