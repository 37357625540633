import React from 'react';
import {
    WEEKDAYS_SHORT
} from '../../constants';

const MonthViewHeader  = ({showWeekends})=>{
    return(
        <thead className="fc-head">
			<tr>
			    <td className="fc-head-container fc-widget-header">
					<div className="fc-row fc-widget-header">
                        <table className="">
			                <thead>
							    <tr>
                                    {WEEKDAYS_SHORT.map((day,dayIndex)=>{
                                        if(showWeekends===false && (dayIndex==0 || dayIndex===6)){
                                            return false;
                                        }
                                        return(
                                            <th className={"text-md font-weight-bold fc-day-header fc-widget-header fc-"+dayIndex} key={dayIndex}>
                                                <span>{day}</span>
                                            </th>
                                        )
                                    })}
							    </tr>
							</thead>
						</table>
                    </div>
                </td>
            </tr>
        </thead>
    )
}

export default MonthViewHeader;