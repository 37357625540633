import { Modal } from "react-bootstrap";
import FormInputs from "../../components/UI/FormInputs";
import { useEffect, useState } from "react";
import { isSet } from "../../utils/commonUtils";
import Button from "react-bootstrap/Button";
import WithRoutify from "../../hoc/WithRoutify";
import AddLab from "./AddLab";
import AddVaccine from "./AddVaccine";
import { isLaboratoryVisitCategory, isPhysicalVisitCategory } from "../../utils/visitUtils";
import PatientFormService from "../../services/PatientFormService";

const PatientFormModel = (props) => {
  const { isOpen, visitId, visitData, visitCategory, Loader, Toast } = props;
  const [validation, setValidation] = useState({});
  const [formdata, setFormData] = useState({});
  const [labFormCompleted,setLabFormCompleted]=useState(false);
  // const [initialCheckboxes, setInitialCheckboxes] = useState({}); 
  
  useEffect(() => {
    const initialCheckboxState = {};
    inputFields.forEach(field => {
      initialCheckboxState[field.name] = !field.readOnly && field.show ? 1 : 0; 
    });
    setFormData(initialCheckboxState);
  }, []);
  
  
  
  
  
  const changeHandler = (value, fieldName, e) => {
    setFormData({ ...formdata, [fieldName]: value });
  }
  const formSubmitHandler = async() => {
    let payload={
      'visit_id':visitData.visit_id,
      'visit_category':visitCategory,
      'company_id':visitData.company.company_uuid,
      'employee_id':visitData.employee.employee_id,
      'clinic_id':visitData.employee.clinic_id,
      'organization_id':visitData.company.organization_uuid,
      'new_patient_intake_form':formdata.new_patient_intake_form,
      'patient_medical_history':formdata.patient_medical_history,
      'treatment_testing_consent':formdata.treatment_testing_consent,
      'medical_history_questionnaire':formdata.medical_history_questionnaire,
      'upload_required_id':formdata.upload_required_id,
      'upload_additional_documents':formdata.upload_additional_documents,
      'baseUrl':process.env.REACT_APP_BASEURL,
      //`${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
    }
    const response=await PatientFormService.sentPatientFormEmail(payload);
    Toast.success("Email Sent to Patient Successfully");
    props.modalToogleHandler(true);
  }

  const inputFields = [
    {
      type: "Checkbox",
      label: "New Patient Intake Forms",
      name: "new_patient_intake_form",
      readOnly: false,
      show:true,
    },
    {
      type: "Checkbox",
      label: "Patient Medical History",
      name: "patient_medical_history",
      show:isPhysicalVisitCategory(visitCategory),
      readOnly: false,
    },
    {
      type: "Checkbox",
      label: "Treatment & Testing Consents",
      name: "treatment_testing_consent",
      readOnly: true,
      show:true,
    }, {
      type: "Checkbox",
      label: "Medical History Questionnaire",
      name: "medical_history_questionnaire",
      readOnly: true,
      show:true,
    },
    {
      type: "Checkbox",
      label: "Upload Required ID",
      name: "upload_required_id",
      show:true,
      readOnly: false,
    },
    {
      type: "Checkbox",
      label: "Upload Additional Optional Documents",
      name: "upload_additional_documents",
      show:true,
      readOnly: false,
    },

  ];
  const addLabFormHandler=()=>{
    setLabFormCompleted(true)
  }
  console.log('labformCompleted',labFormCompleted);
  return (
    <>
      {
        isLaboratoryVisitCategory(visitCategory)&&labFormCompleted!=true ?
          <LabNotification
          addLabFormHandler={addLabFormHandler}
          visitData={visitData}
          visitId={visitId}
           />
          :
          <Modal
            show={true}
            onHide={() => props.modalToogleHandler(true)}
            dialogClassName="hResponsive"
            keyboard={false}
            backdrop="static"
          >
            <div className="sh_modalBox">
              <Modal.Header closeButton>
                <Modal.Title>
                  <h6 className="text-lg mb-0 font-weight-bold">{"Patient Forms"}</h6>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {inputFields &&
                    inputFields.map((field, index) =>
                      field.show&& (
                      <div className={`col-12 ${validation[field.name] ? 'was-validated' : ''}`} key={index}>
                        <div className="form-check form-check-inline d-inline-flex pl-0">
                          <FormInputs {...field}  value={isSet(formdata[field.name], '')} changeHandler={(value, e) => changeHandler(value, field.name, e)} isChecked={!field.readOnly}/>
                          <label className=" text-sm opacity-75 text-truncate mb-0 pl-2">
                            {field.label}
                          </label>
                          {isSet(validation[field.name], "") !== "" ? (
                            <span className="text-danger m-1">
                              {isSet(validation[field.name], "")}
                            </span>
                          ) : ("")}
                        </div>
                      </div>
                    ))}
                </div>
              </Modal.Body>

              <Modal.Footer className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  className=" "
                  onClick={() => formSubmitHandler()}
                >
                  Send to Patient /Employee
                </Button>
                <Button
                  variant="primary"
                  className=" "
                  onClick={() => {
                    const unCheckFormData = {};
                    inputFields.forEach(field => {
                      unCheckFormData[field.name] = 0; 
                    });
                    setFormData(unCheckFormData);
                  }}
                >
                  Uncheck  ALL
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
      }
    </>
  );
}
const LabNotification = (props) => {
  const {addLabFormHandler,visitData,visitId}=props
  const [isLabModelOpen, setLabModalOpen] = useState(false);
  const [isVaccineModelOpen, setVaccineModalOpen] = useState(false);
  const [showLabNotification, setShowLabNotification] = useState(true);
  const [option,setOption]=useState();
  const [labCompleted,setLabCompleted]=useState(false);
  const [vaccineCompleted,setVaccineCompleted]=useState(false);
  console.log('LabNotification','lab=>',labCompleted,'vaccine=>',vaccineCompleted);
  const labToogleHandler = () => {
    setLabCompleted(true);
    setLabModalOpen(!isLabModelOpen);
  }
  const vaccineToogleHandler = () => {
    setVaccineCompleted(true);  
    setVaccineModalOpen(!isVaccineModelOpen);
  }
  const addFormHandler=(type)=>{
    if (labCompleted==false) {
      labToogleHandler();
      setVaccineModalOpen(false);
      return;
    }
    if (vaccineCompleted==false) {
      vaccineToogleHandler();
      setLabModalOpen(false);
      return; 
    }
    setShowLabNotification(false)
    addLabFormHandler();
  }

  return (
    <>
      {
        isLabModelOpen ?
          <AddLab
            modelTitle={"Add Lab"}
            isOpen={isLabModelOpen}
            visitId={visitId}
            modalToogleHandler={()=>addFormHandler('lab')}
            fetchRecords={() => { }}
          />
          :
          ''

      }
      {
        isVaccineModelOpen ?
          <AddVaccine
            isOpen={isVaccineModelOpen}
            visitId={visitId}
            fetchRecords={() => { }}
            modalToogleHandler={()=>{addFormHandler('vaccine');setVaccineCompleted(true)}}
            fromChart={false}
          />
          :
          ''
      }

      <Modal
        show={true}
          onHide={addLabFormHandler}
        dialogClassName="hResponsive"
        keyboard={false}
        backdrop="static"
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{"Add Lab /Vaccine"}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row pl-2">
              Do you want to create Labs/Vaccine?
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=" "
              onClick={() => {labToogleHandler();setVaccineCompleted(true);setLabCompleted(true)}}
            >
              Add Lab
            </Button>
            <Button
              variant="primary"
              className=" "
              onClick={() => {vaccineToogleHandler();setVaccineCompleted(true);setLabCompleted(true)}}
            >
              Add Vaccine
            </Button>
            <Button
              variant="primary"
              className=""
              onClick={() => {labToogleHandler();setLabCompleted(true)}}

            >
              Both
            </Button>
            {/* <Button
              variant="primary"
              className=""
              onClick={() => {setOption('none')}}
            >
              None
            </Button> */}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
const PatientFormModelWithHoc = WithRoutify(PatientFormModel);
export { PatientFormModelWithHoc, LabNotification };