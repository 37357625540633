import { useEffect, useState } from "react";
import { evaluate, fetchTimeInterval, getCurrentDate, getCurrentTime, getLookupByKey, isObject, isSet } from "../../utils/commonUtils";
import { Modal } from "react-bootstrap";
import VisitsService from "../../services/VisitsService";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import withReduxState from "../../hoc/wReduxState";
import WithRoutify from "../../hoc/WithRoutify";
import { VACCINATION_NAME, VACCINATION_SPECIFY_REASON } from "../../utils/constant";
import PatientChartsService from "../../services/PatientChartsService";
import OrganizationService from "../../services/OrganizationService";
import VaccineAdministratorService from "../../services/VaccineAdministratorService";
import VaccineService from "../../services/VaccineService";
import { acl_check } from "../../utils/aclUtils";
import PERMISSIONS from "../../utils/AclPermissions";
import Icons from "../../components/UI/Icons";
import { FieldValidationFocus } from "../../utils/cssUtils";

const AddVaccine = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const { isOpen, modalToogleHandler, modelTitle, visitId, Loader, Toast, activeEditId, fetchRecords,fromChart} = props;
  const [visitDetails, setVisitDetails] = useState({});
  const [vaccinationName, setVaccinationName] = useState([]);
  const [vaccinationSpecifyReason, setVacciantionSpecifyReason] = useState([]);
  const [vaccineAdministationOption, setVaccineAdministationOption] = useState([])
  const [inputFields, setInputFields] = useState(getInputFields());
  let hasEditAccess = acl_check(props.profile.permissions, 'result-edit');
  let hasCreateAccess = acl_check(props.profile.permissions, 'result-create');
  //intial render
  useEffect(() => {
    const fetchIntialDatas = async () => {
      let updateFormData = { ...formData };
      Loader(true);
      await fetchVisitDetails();
      await fetchVaccineName();
      await fetchVaccineSpecifyReason();
      await fetchVaccineAdministration();
      if (activeEditId) {
        if (fromChart) {
          const response = await VaccineService.getVaccineId(activeEditId);
          updateFormData =response;
        }
        else{
          const response = await VaccineService.getVaccineId(activeEditId);
          updateFormData =response;
        }
      }
      updateFormData['visit_date'] = updateFormData['visit_date'] ? updateFormData['visit_date'] : getCurrentDate();
      updateFormData['visit_time'] = updateFormData['visit_time'] ? updateFormData['visit_time'] : getCurrentTime();
      updateFormData['visit_mapping_id'] = props.mapping_id;
      updateFormData['status'] = updateFormData['status'] ? updateFormData['status'] : "Ordered";
      updateFormData['visit_id'] = visitId;
      setFormData(updateFormData);
      Loader(false);
    }
    fetchIntialDatas();
  }, []);
  useEffect(() => {
    setInputFields(getInputFields());
  }, [vaccinationSpecifyReason, vaccinationName, vaccineAdministationOption])
  
  //patient_declines_vaccination
  
  //fetch details
  const fetchVisitDetails = async () => {
    try {
      const visitDetails = await VisitsService.getVisits(visitId);
      setVisitDetails(visitDetails[0])
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  }

  //fetchvaccine
  const fetchVaccineAdministration = async () => {
    const response = await VaccineAdministratorService.getVaccineAdministration(null);
    let optionsArr = [{ label: '--Select', value: '' }];
    if (response) {
      response.data.data.map(item => {
        optionsArr.push({ label: item.person_administrating_vaccine, value: item.id });
      });
    }
    setVaccineAdministationOption(optionsArr);
  }

  //fetch adminstration 
  useEffect(() => {
    const fetchAdminstionTitle = async () => {
      if (formData.person_adminstration_vaccine) {
        const response = await VaccineAdministratorService.getVaccineAdministrationById(null, formData.person_adminstration_vaccine);
        if (response) {
          setFormData({ ...formData, ['title']: response.title });
        }
      }
    }
    fetchAdminstionTitle();
  }, [formData.person_adminstration_vaccine]);

  //fetch vaccine name
  const fetchVaccineName = async () => {
    const vaccine_name = await getLookupByKey(VACCINATION_NAME);
    // console.log('vaccine_name', vaccine_name);
    setVaccinationName(vaccine_name);
  }

  //fetch reason
  const fetchVaccineSpecifyReason = async () => {
    const reasons = await getLookupByKey(VACCINATION_SPECIFY_REASON,false);
    setVacciantionSpecifyReason(reasons);
  }

  //change handler
  const changeHandler = (value, fieldName, dependencyValue) => {
    const updateFormData = { ...formData };
    const updateValidation = { ...validation };
    updateFormData[fieldName] = value;
    updateValidation[fieldName] = '';
    setFormData(updateFormData);
    setValidation(updateValidation);

  }
  //submit handler
  const formSubmitHandler = async () => {
    Loader(true);
    let response;
    let payload = { ...formData };
    payload.company_id = visitDetails?.company?.company_uuid;
    payload.organization_uuid = OrganizationService.getOrgId();
    if (fromChart) {
      if (activeEditId) {
        response = await VaccineService.updateVaccineById(activeEditId,payload); 
      }
      else {
        response = await PatientChartsService.savePatientCharts(isSet(formData.organization, null), visitDetails?.company?.company_uuid, props.mapping_id, payload);
      }    
    }
    else{
      if (activeEditId) {
        response = await VaccineService.updateVaccineById(activeEditId,payload); 
      }
      else{
        response = await VaccineService.saveVaccine(isSet(formData.organization, null), payload);
      }
      
    }

    let updateValidation = { ...validation };
    // console.log('response', response);
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(',')
          }
        }
      } else {
        Toast.error(response.data);
      }


      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
        FieldValidationFocus(inputFields, updateValidation);
      }
    } else {
      let message=`${activeEditId?'Vaccine Updated Successfully':'Vaccine Added Successfully'}`;
      props.Toast.success(message);
      modalToogleHandler();
    }
    fetchRecords();
    Loader(false);
  }
  return (
    <>
      <Modal
        show={true}
        size="xl"
        onHide={modalToogleHandler}
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">
                {activeEditId ? "View and Update Vaccine" : "Add Vaccine"}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sh_innerPortview w-100 d-flex">
              <div className="w-50 p-2">
                <div className="sh_cardBox h-auto sh_patientdetailwidget">
                  <div className="sh_cardHeader">
                    <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                    <h6 className="text-md font-weight-bold mb-0">
                    <span className="px-1">
                      <Icons type="patientdetails" />
                    </span>
                    Patient Details </h6>
                    </div>
                  </div>
                  <div className="sh_cardBody py-0">
                    <div className="row">
                    <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Company</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title="Company">{visitDetails?.company?.name}</h6>
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Patient</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title="Company">{visitDetails?.employee?.first_name + visitDetails?.employee?.last_name || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Patient ID</label>
                          </div> <div className="col-6 pt-1">
                         <h6 className="font-weight-bold text-sm text-truncate" title="Company">{visitDetails?.employee?.patient_id || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Booking ID</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title="Company">{visitDetails?.employee?.booking_id || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">System ID</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title="Company">{visitDetails?.company?.system_id || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">EMR Patient ID</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title="Company">{visitDetails?.company?.emr_interface_id || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Race</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title="Company">{visitDetails?.employee?.race || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Address</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title="Company">{visitDetails?.employee?.address || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div className="w-100">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {inputFields && inputFields.map((field,index)=>{
                        let result = null;
                        let action = '';
                        if(isSet(field.rules,[]).length>0){
                           const resultData = evaluate(isSet(field.rules,[]),formData);
                           action = resultData.action;
                           result = resultData.result;
                        }
                        if(action===''){
                        }else if((action==='SHOW' && result===false) ||  (action==='HIDE' && result===true)){
                           return false;
                        }
                        return(
                          <div className={`${field.col ? field.col : 'col-6'}`} key={index}>
                            <div className={isSet(field.formGroup, "form-group")}>
                              <label className={field.labelClassName}>{field.label}</label>
                              {field.type !== 'Checkbox' ?

                                <FormInputs
                                id={isSet(field.id,field.name)}
                                  changeHandler={(value, e) =>
                                    changeHandler(value, field.name, e)
                                  }
                                  readOnly={!(hasEditAccess && hasCreateAccess)}
                                   value={formData[field.name]}
                                  {...field}
                                />
                                :
                                <>
                                </>
                              }

                              {isSet(validation[field.name], "") !== "" ? (
                                <span className="text-danger m-1">
                                  {isSet(validation[field.name], "")}
                                </span>
                              ) : ("")}
                            </div>
                          </div>
                        )
                      })}
                      {/* {inputFields &&
                        inputFields.map((field, index) => (field &&
                          <div className={`${field.col ? field.col : 'col-6'}`} key={index}>
                            <div className={isSet(field.formGroup, "form-group")}>
                              <label className={field.labelClassName}>{field.label}</label>
                              {field.type !== 'Checkbox' ?

                                <FormInputs
                                  changeHandler={(value, e) =>
                                    changeHandler(value, field.name, e)
                                  }
                                  readOnly={!(hasEditAccess && hasCreateAccess)}
                                   value={formData[field.name]}
                                  {...field}
                                />
                                :
                                <>
                                </>
                              }

                              {isSet(validation[field.name], "") !== "" ? (
                                <span className="text-danger m-1">
                                  {isSet(validation[field.name], "")}
                                </span>
                              ) : ("")}
                            </div>
                          </div>
                        ))} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              type='Single'
              className="btn btn-primary"
              clickHandler={() => formSubmitHandler()}
              label={activeEditId ? "Update" : "Save"}
              acl={activeEditId?PERMISSIONS.RESULT.EDIT:PERMISSIONS.RESULT.CREATE} />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        type: "Patient ID",
        label: "Patient ID",
        placeholder: "Patient ID",
        className: "form-control form-control-solid",
        name: "patient_id",
        required: true,
      },
      {
        type: "Date",
        label: "Visit Date",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "visit_date",
        required:true,
      },
      {
        type: "SelectSearchList",
        label: "Visit Time",
        placeholder: "Time",
        isAsync: true,
        options: [{ 'label': getCurrentTime(), value: getCurrentTime() }],
        loadCallBack: (val, callback) => fetchTimeInterval(val, callback),
        className: "form-control form-control-solid",
        name: "visit_time",
      },
      {
        type: "SelectList",
        options: vaccinationName,
        label: "Vaccine Name",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "vaccine_name",
        required:true,
      },
      {
        type: "SelectList",
        options: [{ label: "--Select", value: '' }, { label: "Yes", value: 1 }, { label: "No", value: 0 }],
        label: "Patient declines vaccination",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "patient_declines_vaccination",
        required: true,
      },
      {
        type: "SelectSearchList",
        options: vaccinationSpecifyReason,
        label: "Specify reason",
        placeholder: "",
        rules:[{rule:[{field:'patient_declines_vaccination',value:1,operator:'==='}],action:'SHOW'}],
        isMulti:true,
        className: "form-control form-control-solid",
        name: "specify_reason",
      },
      {
        type: "",
        label: "Lot Number",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "lot_number",
      },
      {
        type: "SelectList",
        options: [{ label: "--Select--", value: "" }, { label: "Left Deltoid", value: "Left Deltoid" }, { label: "Right Deltoid", value: "Right Deltoid" }],
        label: "Vaccine Adminstration Site",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "vaccination_adminstration_site",
      },
      {
        type: "Date",
        label: "Vaccine Expiration Date",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "vaccine_expiration_date",
      },
      {
        type: "SelectList",
        options: [{ label: "--Select--", value: "" }, { label: "1", value: "1" }, { label: "2", value: "2" }],
        label: "Series",
        className: "form-control form-control-solid",
        name: "series",
      },
      {
        type: "SelectList",
        options: vaccineAdministationOption,
        label: "Person Adminstrating Vaccine",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "person_adminstration_vaccine",
      },
      {
        type: "TextInput",
        label: "Title",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "title",
        readOnly: true,
      },
      {
        type: "",
        label: "Notes",
        placeholder: "",
        className: "form-control form-control-solid",
        name: "notes",
      },
    ];
    return inputFields;
  }
};
export default withReduxState(WithRoutify(AddVaccine));
