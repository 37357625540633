const organization = {
    OrganizationList: 'Organization List',
    Organization: 'Organization',
    AddressAndContact:'Address & Contact',
    FederalTaxID:"Federal Tax ID",
    GroupNPI:"Group NPI",
    CLIA:"CLIA",
    MedicareGroupID:'Medicare Group ID',
    MedicaidGroupID:"Medicaid Group ID",
    DOLID:"DOL ID",
    PayerOverride:"Payer Override",
    VaccineRegID:"Vaccine Reg ID",
    MedicalDirectorName:"Medical Director Name",
    LabDirectorName:"Lab Director Name",
    RemitAddress:"Remit Address",
    RemitCity:"Remit City",
    RemitState:"Remit State",
    RemitZip4:"Remit Zip+4",
    BillingPhone:"Billing Phone",
    BillingFax:"Billing Fax",
    BillingEmails:"Billing Email (s)",
    RCMCompany:"RCM Company",
    OrganizationLogo:"Organization Logo",
    BusinessLicense:"Business License",
    IDsCertificates:"ID's & Certificates",
    Directors:"Directors",
    BillingRemitAddress:"Billing Remit Address",
    Images:"Images",
    ViewandUpdate:"View and Update",
    Organizations:"Organizations"








   
};

export default organization;