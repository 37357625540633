import React from 'react';
import { BrowserRouter   } from 'react-router-dom';
import AppRoutes from './routes';
import './App.css';
import { AxiosInterceptor } from './services/config';
import MaintenanceMode from './routes/MaintananceMode';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config';
// import { ToastProvider } from './context/ToaxtContext';

function App() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;
  if (isMaintenanceMode==='true') {
    return <MaintenanceMode />;
  }
  return(
    <BrowserRouter>
        <AxiosInterceptor>
            <I18nextProvider i18n={i18n}>
              <AppRoutes />
            </I18nextProvider>
          </AxiosInterceptor>
    </BrowserRouter>
  )
}



export default App;