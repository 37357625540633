import Buttons from "../../../components/UI/Buttons";

const MissingClaim=(props)=>{
    return(
        <>
         <div className="sh_cardBox sh_widgets sh_patientdetailwidget" >
                    <div className="sh_cardHeader">
                        <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                            <h6 className="text-md font-weight-bold mb-0">
                                <span className="px-2">
                                    <Buttons
                                        iconType='DashboardClaim'
                                    />
                                </span>
                                Missing Claim Numbers
                            </h6>
                        </div>
                    </div>
                    <div className="sh_cardBody py-0 dashboard_card">
                        <div className="row ">
                          <div className="col-8  font-weight-bold"><a>Claim Age</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>Count</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            <Buttons
                            iconType="redcircle"
                            className="px-2"
                            />
                            Total Missing Claims</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                        <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="bluecircle"
                            className="px-2"
                            />
                            Completed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="greencircle"
                            className="px-2"
                            />
                            Passed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="redcircle"
                            className="px-2"
                            />
                            Failed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                    </div>
                    <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                    <button className="btn dashboardbtn  btn-danger px-4"> Review (0)</button>

      
                  </div>
                  </div>
        </>
    );
}
export default MissingClaim;