
import { isSet } from "../../../../../../utils/commonUtils";

export const ScreeningHA = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-md-9',
        data:[
        {
            label:'Activity Details - '+props.activityName,
            fields:[
                {
                    label:'Standard: Equal to 40 dB, in better ear (with or without hearing aid).',
                    type:'Label',
                    col:'col-12'                
                },
            {
                label:'Check if hearing aid used for test',
                col:'col-12',
                type:'RadioGroup',
                name:'check_hearing_aid_used',
                id:'check_hearing_aid_used',
                options:['Right Ear','Left Ear','Neither'],
            },
        ]
        },
        { 
            label:'Audiometric Test Results',
            note:'Standard: Equal to 40 dB, in better ear (with or without hearing aid).',
            fields:[
                {
                    label:'Right Ear',
                    type:'Label',
                    appendClass:'bg-light',
                    col:'col-12',
                },
                {
                    label:'500 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_right_ear_500_hz',
                    id:'audiometric_test_results_right_ear_500_hz',
                },
                {
                    label:'1000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_right_ear_1000_hz',
                    id:'audiometric_test_results_right_ear_1000_hz',
                },
                {
                    label:'2000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_right_ear_2000_hz',
                    id:'audiometric_test_results_right_ear_2000_hz',
                },
                {
                    label:'3000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_right_ear_3000_hz',
                    id:'audiometric_test_results_right_ear_3000_hz',
                },
                {
                    label:'4000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_right_ear_4000_hz',
                    id:'audiometric_test_results_right_ear_4000_hz',
                },
                {
                    label:'6000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_right_ear_6000_hz',
                    id:'audiometric_test_results_right_ear_66000_hz',
                },
                {
                    label:'8000 Hz',
                    type:'Number',
                    col:'col-4',
                    rangeMax:40,
                    name:'audiometric_test_results_right_ear_8000_hz',
                    id:'audiometric_test_results_right_ear_8000_hz',
                },
                {
                    label:'Average',
                    type:'Number',
                    readOnly:true,
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_right_ear_average',
                    id:'audiometric_test_results_right_ear_average',
                },
                {
                    type:'Blank',
                    col:'col-4',
                    name:'blank'
                },
                {
                    label:'Left Ear',
                    appendClass:'bg-light',
                    type:'Label',
                    col:'col-12',
                },
                {
                    label:'500 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_left_ear_500_hz',
                    id:'audiometric_test_results_left_ear_500_hz',
                },
                {
                    label:'1000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_left_ear_1000_hz',
                    id:'audiometric_test_results_left_ear_1000_hz',
                },
                {
                    label:'2000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_left_ear_2000_hz',
                    id:'audiometric_test_results_left_ear_2000_hz',
                },
                {
                    label:'3000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_left_ear_3000_hz',
                    id:'audiometric_test_results_left_ear_3000_hz',
                },
                {
                    label:'4000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_left_ear_4000_hz',
                    id:'audiometric_test_results_left_ear_4000_hz',
                },
                {
                    label:'6000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_left_ear_6000_hz',
                    id:'audiometric_test_results_left_ear_6000_hz',
                },
                {
                    label:'8000 Hz',
                    type:'Number',
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_left_ear_8000_hz',
                    id:'audiometric_test_results_left_ear_8000_hz',
                },
                {
                    label:'Average',
                    type:'Number',
                    readOnly:true,
                    rangeMax:40,
                    col:'col-4',
                    name:'audiometric_test_results_left_ear_average',
                    id:'audiometric_test_results_left_ear_average',
                },
                {
                    type:'Blank',
                    col:'col-4',
                    name:'blank'
                }
            ]
        },
        {
            label:'Status and comments',
            fields:[
                {
                    label:'Notes',
                    col:'col-6',
                    type:'TextArea',
                    name:'notes',
                },
                {
                    label:'Suggested Result',
                    type:'TextInput',
                    disabled:true,
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'suggested_status',
                },
                {
                    type:'Blank',
                    name:'blank'
                },
                {
                    label:'Final Determination',
                    type:'SelectList',
                    options:isSet(props.lookupsOptions['chartResult'],[]),
                    name:'status',
                },
            
           
        ]
        }]
    }];
    const defaultValue = {
        status:'Not Started',
        suggested_status:'Not Started',
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,computationFunction: (formData,key) => computationRules(formData,key)};
}



const computationRules = (formData,key)=>{
     
    let status = 'Not Started';
    let returnData = {};
    const earPrefixes = ['right_ear_', 'left_ear_'];
    earPrefixes.forEach(earPrefix => {
        const frequencies = [500, 1000, 2000,3000,4000,6000,8000];
        let total = 0;
        
       
        frequencies.forEach(frequency => {
            const value = isSet(formData[`audiometric_test_results_${earPrefix}${frequency}_hz`], '');
            total += Number(value);
        });
        const average = Math.ceil(total / frequencies.length);
        returnData[`audiometric_test_results_${earPrefix}average`] = average;
    });

    const leftAverage = returnData[`audiometric_test_results_right_ear_average`];
    const rightAverage = returnData[`audiometric_test_results_left_ear_average`];

    if ((leftAverage <= 40 && leftAverage !== 0) || (rightAverage <= 40 && rightAverage !== 0)) {
        status = 'Passed';
    } else if ((leftAverage === 0 || rightAverage === 0) && (leftAverage > 40 || rightAverage > 40)) {
        status = 'Failed';
    } else if(leftAverage > 40 || rightAverage > 40){
        status = 'Failed';
    }else{
        status = 'Pending';
    }
    returnData = (key==='status')?returnData:{...returnData,'status':status,'suggested_status':status};
    return returnData;

}