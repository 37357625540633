import React, { useEffect, useState } from "react";
import FormInputs from "../../../../../components/UI/FormInputs";
import { USDateTimeString, isSet } from "../../../../../utils/commonUtils";
import SectionLoader from "../../../../../components/UI/SectionLoader";
import TableGrid from "../../../../../components/UI/TableGrid";
import { DOCUMENT_TYPE } from "../../../../../utils/constant";
import WithRoutify from "../../../../../hoc/WithRoutify";
import LookupService from "../../../../../services/LookupService";
import EmployeeService from "../../../../../services/EmployeeService";
import VisitsService from "../../../../../services/VisitsService";

const UploadPhysical = (props) => {
    const visit_id=props.visitDetails.visit_id
    const [uploadedFile,setUploadedFile] = useState(null);
    const [isLoading,setLoaded] = useState(false);
    const [documentsList, setDocumentsList] = useState({ tableHeaders: [], tableRows: { data: [] } });
    const [documentFormData,setDocumentFormData]=useState({})

    useEffect(() => {
      fetchDocumentList();
    }, []);
    const fetchDocumentList = async () => {
        try {
            let documents=await VisitsService.getPhysicalAttachementList(visit_id);
            let tableData=[];
            tableData = await documents.data.map((item) => ({
                rowId: item.physical_attachment_id,
                data: {
                    name: item.snap_file_manager,
                    updatedAt:  USDateTimeString(item.snap_file_manager.updated_at) ,
                    action: [{
                        className: 'btn btn-icon btn-transparent-dark',
                        iconType: 'Remove',
                        acl: 'any',
                        title: 'Delete',
                        isDelete:true,
                        clickHandler: () => deleteHandler(item.physical_attachment_id),
                    }]
                }
            }));
            setDocumentsList({ tableHeaders: getTableHeaders(), tableRows: { data: tableData } });

        } catch (error) {
            console.error("Error fetching additional documents:", error);
        }
    }
    const getTableHeaders = () => [
        { label: 'File Name', key: 'name', type:'FileType' },
        { label: 'Updated Date', key: 'updatedAt', type: '' },
        { label: 'Action', key: 'action', type: 'Actions' },
        // Add more columns as needed
    ];

    const submiHandler = async () => {
        
        try {
            //submit logic goes here
            if (uploadedFile) {
            setLoaded(true);
            const formData = new FormData();
            formData.append('file', uploadedFile);
            formData.append('visit_id',visit_id)
            let response=await VisitsService.addPhysicalAttachements(formData);
            props.Toast.success("Document Added Successfully");
            setUploadedFile(null);
            fetchDocumentList();
            setLoaded(false);
        }
        else{
            props.Toast.error("Select File to proceed further") 
        }
            
        } catch (error) {
            console.error("Error submitting document:", error);
            setLoaded(false);
        }
    }
    const inputFields = [];

    const deleteHandler = async (AttachmentId) => {  
            setLoaded(true);
            try {
                //delete logic goes here
                let response = await VisitsService.deletePhysicalAttachement(AttachmentId);
                props.Toast.success('Document Deleted successfully');
                setLoaded(false);
                fetchDocumentList();
            } catch (error) {
                setLoaded(false);
                console.error("Error deleting document:", error);
            }
    }

    const changeHandler=(val,fieldName)=>{
    setDocumentFormData(prevState => ({
              ...prevState.labformData, [fieldName]: val }
        ));
    }

    return (
      <div className="sh_innerPortview">
        <div className="row m-2">
            <div className="col-12 col-lg-4 p-1">
              <div className="sh_cardBox h-auto shadow-none border mb-0">
                <div className="sh_cardBody p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="f-500 text-md text-truncate mb-0">
                          {"Images"}
                        </label>
                        <FormInputs
                          type="FileUpload"
                          title="Field Document"
                          className=""
                          value={uploadedFile||""}
                          changeHandler={(val) => setUploadedFile(val)}
                        />
                      </div>
                    </div>
                    {inputFields.map((item, index) => (
                      <div className="col-12" key={index}>
                        <div className="form-group">
                          <label className="f-500 text-md text-truncate mb-0">
                            {item.label}
                          </label>
                          <FormInputs
                            {...item}
                            value={isSet(documentFormData[item.name], "")}
                            
                            changeHandler={(val) => changeHandler(val, item.name)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="sh_cardFooter justify-content-center">
                  <button
                    className="btn btn-primary btn-sm text-sm"
                    onClick={submiHandler}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8 p-1">
              <div className="sh_cardBox h-auto shadow-none border mb-0">
                <div className="sh_cardHeader">
                  <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                    <h6 className="text-md mb-0">Upload Physical Attachment</h6>
                  </div>
                </div>
                <div className="sh_cardBody p-0">
                  <SectionLoader isLoading={isLoading} />
                  <TableGrid {...documentsList} />
                </div>
              </div>
            </div>
        </div>
         
      </div>
    );
}

export default WithRoutify(UploadPhysical);
