
import { fetchTimeInterval,getCurrentDate, getCurrentTime, isSet, optionsToKeyValuePairs } from "../../../../../../utils/commonUtils";
// import { ItentificationTypeOptions } from "../../../../../../utils/constant";

export const RespiratorFitTest = (props)=>{

    const qualitativeFitTest  = [
        {label:'Exercise',name:'exercise'},
        {label:'Deep Breath',name:'deep_breath'},
        {label:'Up and Down',name:'up_and_down'},
        {label:'Grimace',name:'grimace'},
        {label:'Normal Breathing',name:'normal_breathing'},
        {label:'Side to Side',name:'side_to_side'},
        {label:'Talking',name:'talking'},
        {label:'Bend / Jog',name:'bend_or_jog'}
    ];
    
    const patientQuestionariesArr = [
        {label:'1. Do you currently smoke tobacco, or have you smoked tobacco in the last month?',name:'smoke_tobacco_last_month'},
        {label:'2. Have you ever had any of the following conditions?'},
                {label:'a. Seizures',name:'cardiovascular_problems_seizures',isSubField:true},
                {label:'b. Diabetes (sugar disease)',name:'cardiovascular_problems_diabetes',isSubField:true},
                {label:'c. Allergic reactions that interfere with your breathing',name:'cardiovascular_problems_allergic_reactions',isSubField:true},
                {label:'d. Claustrophobia (fear of closed-in places)',name:'cardiovascular_problems_claustrophobia',isSubField:true},
                {label:'e. Trouble smelling odors',name:'cardiovascular_problems_trouble_smelling_odors',isSubField:true},
        {label:'3. Have you ever had any of the following pulmonary or lung problems? ',
            },
                {label:'a. Asbestosis',name:'pulmonary_problems_asbestosis',isSubField:true},
                {label:'b. Asthma',name:'pulmonary_problems_asthma',isSubField:true},
                {label:'c. Chronic bronchitis',name:'pulmonary_problems_chronic_bronchitis',isSubField:true},
                {label:'d. Emphysema',name:'pulmonary_problems_emphysema',isSubField:true},
                {label:'e. Pneumonia',name:'pulmonary_problems_pneumonia',isSubField:true},
                {label:'f. Tuberculosis',name:'pulmonary_problems_tuberculosis',isSubField:true},
                {label:'g. Silicosis',name:'pulmonary_problems_silicosis',isSubField:true},
                {label:'h. Pneumothorax (collapsed lung)',name:'pulmonary_problems_pneumothorax',isSubField:true},
                {label:'i. Lung cancer',name:'pulmonary_problems_lung_cancer',isSubField:true},
                {label:'j. Broken ribs',name:'pulmonary_problems_broken_ribs',isSubField:true},
                {label:'k. Any chest injuries or surgeries',name:'',isSubField:true},
                {label:"l. Any other lung problem that you've been told about",name:'symptoms_pulmonary_lung_illness_seizures',isSubField:true},
       
        {label:'4. Do you currently have any of the following symptoms of pulmonary or lung illness? ',},
                {label:'a. Shortness of breath',name:'shortness_of_breath',isSubField:true},
                {label:'b. Shortness of breath when walking fast on level ground or walking up a slight hill or incline',name:'shortness_breath_fast_walking',isSubField:true},
                {label:'c. Shortness of breath when walking with other people at an ordinary pace on level ground',name:'shortness_breath_other_people',isSubField:true},
                {label:'d. Have to stop for breath when walking at your own pace on level ground',name:'shortness_breath_walking_pace',isSubField:true},
                {label:'e. Shortness of breath when washing or dressing yourself',name:'shortness_breath_washing_dressing',isSubField:true},
                {label:'f. Shortness of breath that interferes with your job',name:'shortness_breath_job',isSubField:true},
                {label:'g. Coughing that produces phlegm (thick sputum)',name:'coughing_produces_phlegm',isSubField:true},
                {label:'h. Coughing that wakes you early in the morning',name:'coughing_wakes_early_morning',isSubField:true},
                {label:'i. Coughing that occurs mostly when you are lying down',name:'coughing_occurs_lying_down',isSubField:true},
                {label:'j. Coughing up blood in the last month',name:'coughing_up_blood_last_month',isSubField:true},
                {label:'k. Wheezing',name:'wheezing',isSubField:true},
                {label:"l. Wheezing that interferes with your job",name:'wheezing_interferes_job',isSubField:true},
                {label:'m. Chest pain when you breathe deeply',name:'chest_pain_breathe_deeply',isSubField:true},
                {label:'n. Any other symptoms that you think may be related to lung problems',name:'other_symptoms_lung_problems',isSubField:true},

        {label:'5. Have you ever had any of the following cardiovascular or heart problems? ',
            },
                {label:'a. Heart attack',name:'cardiovascular_problems_heart_attack',isSubField:true},
                {label:'b. Stroke',name:'cardiovascular_problems_stroke',isSubField:true},
                {label:'c. Angina',name:'cardiovascular_problems_angina',isSubField:true},
                {label:'d. Heart failure',name:'cardiovascular_problems_heart_failure',isSubField:true},
                {label:'e. Swelling in your legs or feet (not caused by walking)',name:'cardiovascular_problems_swelling_legs_feet',isSubField:true},
                {label:'f. Heart arrhythmia (heart beating irregularly)',name:'cardiovascular_problems_heart_arrhythmia',isSubField:true},
                {label:'g. High blood pressure',name:'pulmonary_problems_high_blood_pressure',isSubField:true},
                {label:"h. Any other heart problem that you've been told about",name:'pulmonary_problems_other_heart_problem',isSubField:true},
        {label:'6. Have you ever had any of the following cardiovascular or heart symptoms? ',
            },
                {label:'a. Frequent pain or tightness in your chest',name:'cardiovascular_chest_pain',isSubField:true},
                {label:"b. Pain or tightness in your chest during physical activity",name:'cardiovascular_chest_pain_activity',isSubField:true},
                {label:'c. Pain or tightness in your chest that interferes with your job',name:'cardiovascular_chest_pain_job',isSubField:true},
                {label:'d. In the past two years, have you noticed your heart skipping or missing a beat',name:'cardiovascular_heart_skipping',isSubField:true},
                {label:'e. Heartburn or indigestion that is not related to eating',name:'cardiovascular_Heartburn',isSubField:true},
                {label:'f. Any other symptoms that you think may be related to heart or circulation problems',name:'cardiovascular_other_heart_symptoms',isSubField:true},
          
        {label:'7. Do you currently take medication for any of the following problems? ',
        },
            {label:'a. Breathing or lung problems',name:'medication_breathing_lung_problems',isSubField:true},
            {label:"b. Heart trouble",name:'medication_heart_trouble',isSubField:true},
            {label:'c. Blood pressure',name:'medication_blood_pressure',isSubField:true},
            {label:'d. Seizures',name:'medication_seizures',isSubField:true},

         {label:'8. Have you ever used a respirator?',name:'used_respirator_before'},
         {label:"If yes, have you ever had any of the following problems? If no, proceed to question 9. ",
        },
            {label:'a. Eye irritation',name:'eye_irritation',isSubField:true},
            {label:"b. Skin allergies or rashes",name:'skin_allergies_rashes',isSubField:true},
            {label:'c. Anxiety',name:'anxiety',isSubField:true},
            {label:'d. General weakness or fatigue',name:'general_weakness_fatigue',isSubField:true},
            {label:'e. Any other problem that interferes with your use of a respirator',name:'other_problem_interferes_respirator',isSubField:true},
     
         {label:'9. Would you like to talk to the health care professional who will review this questionnaire about your answers to this questionnaire?',name:'talk_to_health_care_professional'},

    ];
    const secondPatientQuestionariesArr = [
        {label:'10. Have you ever lost vision in either eye (temporarily or permanently)?',name:'lost_vision_either_eye'},
        {label:'11. Do you currently have any of the following vision problems? '},
                {label:'a. Wear contact lenses',name:'vision_problems_wear_contact_lenses',isSubField:true},
                {label:'b. Wear glasses',name:'vision_problems_wear_glasses',isSubField:true},
                {label:'c. Color blind',name:'vision_problems_color_blind',isSubField:true},
                {label:'d. Any other eye or vision problem',name:'vision_problems_other_eye_vision_problem',isSubField:true},
        {label:'12. Have you ever had an injury to your ears, including a broken eardrum',name:'injury_to_ears_broken_eardrum'},
        {label:'13. Do you currently have any of the following hearing problems? '},
                {label:'a. Difficulty hearing',name:'hearing_problems_difficulty_hearing',isSubField:true},
                {label:'b. Wear a hearing aid',name:'hearing_problems_wear_hearing_aid',isSubField:true},
                {label:'c. Any other hearing or ear problem',name:'hearing_problems_other_hearing_ear_problem',isSubField:true},
                // {label:'d. Any other eye or vision problem',name:'other_eye_vision_problem',isSubField:true},
        {label:'14. Have you ever had a back injury?',name:'back_injury'},
        {label:'15. Do you currently have any of the following musculoskeletal problems? '},
            {label:'a. Weakness in any of your arms, hands, legs, or feet',name:'vision_problems_weakness_arms_legs_feet',isSubField:true},
            {label:'b. Back pain',name:'vision_problems_back_pain',isSubField:true},
            {label:'c. Difficulty fully moving your arms and legs',name:'vision_problems_difficulty_moving_arms_legs',isSubField:true},
            {label:'d. Pain and stiffness when you lean forward or backward at the waist',name:'vision_problems_pain_stiffness_waist',isSubField:true},

            {label:'e. Difficulty fully moving your head up or down',name:'hearing_problems_difficulty_up_down',isSubField:true},
            {label:'f. Difficulty fully moving your head side to side',name:'hearing_problems_difficulty_head_side',isSubField:true},
            {label:'g. Difficulty bending at your knees',name:'hearing_problems_difficulty_bending_knees',isSubField:true},
            {label:'h. Difficulty squatting to the ground',name:'hearing_problems_difficulty_squatting',isSubField:true},
            {label:'i. Climbing a flight of stairs or a ladder carrying more than 25 lbs.',name:'hearing_problems_climbing_stairs_ladder',isSubField:true},
            {label:'j. Any other muscle or skeletal problem that interferes with using a respirator',name:'muscle_skeletal_problem_interferes_respirator',isSubField:true},
    ];

    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },
    {
        type:'Tab',
        col:'col-md-9',
        data:[
            {
            id:'provider',
            data:  [
                {
                    label:'Activity Details - Respirator Medical Clearance',
                    fields:[{
                        label:'Date',
                        type:'Date',
                        name:'examination_date',
                        col:'col-6',
                        id:'examination_date',
                    },
                    {
                        type:'Blank',
                        name:'blank'
                    },
                    {
                        label:'Respirator Information',
                        type:'Label',
                        appendClass:'bg-light',
                        col:'col-12',
                    },
                    {
                        label:'Classification',
                        type:'SelectList',
                        options:optionsToKeyValuePairs(["Air Purifying Mask","Self Contained Breathing Apparatus"]),
                        name:'respirator_classification',
                        id:'health_information_review',
                    },
                    {
                        label:'Style',
                        type:'SelectList',
                        options:optionsToKeyValuePairs(["Half Mask","Full Mask"]),
                        name:'provider_respirator_style',
                        id:'provider_respirator_style',
                    },
                    {
                        label:'Type',
                        type:'SelectList',
                        options:optionsToKeyValuePairs(["FFR’s (Filtering Facepiece Respirator)","EHMR (Elastomeric Half-mask Respirator)","PAPR (Powered Air-purifying Respirator)"]),
                        name:'respirator_type',
                        id:'respirator_type',
                    },
                    {
                        label:'Modal',
                        type:'SelectList',
                        options:optionsToKeyValuePairs(["N95","N99","N100","R95","R99","R100","P95","P99","P100"]),
                        name:'respirator_model',
                        id:'respirator_model',
                        rules:[{rule:[{field:'respirator_type',value:'FFR’s (Filtering Facepiece Respirator)',operator:'!=='}],action:'DISABLE'}],
                    },
                    {
                        label:'Qualitative Fit Test (Test Chemical Bitrex)',
                        type:'Label',
                        appendClass:'bg-light',
                        col:'col-12',
                    },
                    {
                        label:'Fitting',
                        type:'SelectList',
                        options:optionsToKeyValuePairs(["Satisfactory","Unsatisfactory"]),
                        name:'fitting',
                        id:'fitting',
                    },
                    {
                        label:'Does the subject wear prescription glasses?',
                        type:'RadioGroup',
                        options:[{label:'Yes',value:1},{label:'No',value:0}],
                        name:'wears_prescription_glasses',
                        id:'wears_prescription_glasses',
                    },
                    {
                        label:'Has a way for the subject to wear prescription glasses while wearing the respirator been arranged?',
                        type:'RadioGroup',
                        options:[{label:'Yes',value:1},{label:'No',value:0}],
                        name:'arranged_prescription_glasses',
                        id:'arranged_prescription_glasses',
                    },
                    {
                        type:'Blank',
                        name:'blank'
                    },
                    {
                        col:'col-12',
                        label:'',
                        defaultBtnLabel:'Set all to Pass',
                        type:'YesOrNoTable',
                        options:[{label:'Fail',value:'Fail'},{label:'Pass',value:'Pass'}],
                        defaultValues:qualitativeFitTest.reduce((acc, itm) => {
                            acc[itm.name] = 'Pass';
                            return acc;
                        }, {}),
                        list:qualitativeFitTest,
                        
                    },
                ]
                },
                {
                    label:'Fit Test Results',
                    fields:[{
                        label:'Valid From',
                        type:'Date',
                        name:'fit_test_valid_from',
                        col:'col-6',
                        id:'fit_test_valid_from',
                    },
                    {
                        label:'Valid To',
                        type:'Date',
                        name:'fit_test_valid_to',
                        col:'col-6',
                        id:'fit_test_valid_to',
                    },
                   
                    
                    {
                        label:'Comments',
                        type:'TextArea',
                        name:'comments',
                        col:'col-6',
                        id:'comments',
                    },{
                        type:'Blank',
                        name:'blank'
                    },
                    {
                        label:'Recommendations',
                        type:'Label',
                        appendClass:'bg-light',
                        col:'col-12',
                    },
                    {
                        type:'Label',
                        label:'I have evaluated the aforementioned person, based on the above, in accordance with the provisions of OSHA 29 CFR 1910.134 and other similar state regulatory provisions for respirator use, and it is my opinion that the employee is: (check all that apply) ',
                        col:'col-12',
                    },{
                        label:'',
                        isLabel:false,
                        type:'RadioGroup',
                        col:'col-12',
                        options:["Medically qualified for use of respirators without restriction","NOT medically qualified for the use of respirators for the following reasons"],
                        name:'medically_qualified',
                        id:'medically_qualified'
                    }
                ]
                },
                {   
                    label:'Status',
                    col:'col-6',
                    fields:[{
                        label:'In need of additional evaluation to assess qualification',
                        labelWrap:false,
                        type:'TextArea',
                        name:'additional_evaluation_needed',
                        id:'additional_evaluation_needed'
                    },
                    {
                        label:'Others',
                        type:'TextArea',
                        name:'additional_evaluation_reasons',
                        id:'additional_evaluation_reasons'
                    },
                    
                    {
                        label:"Subject's Signature",
                        type:'Signature',
                        name:'subject_signature',
                        id:'subject_signature',
                    },
                    {
                        label:'Date',
                        type:'Date',
                        name:'subject_signature_date',
                        id:'subject_signature_date',
                    },
                    {
                        label:"Tester's Signature",
                        type:'Signature',
                        name:'tester_signature',
                        id:'tester_signature',
                    },
                    {
                        label:'Date',
                        type:'Date',
                        name:'tester_signature_date',
                        id:'tester_signature_date',
                    },
                    {
                        type:'Blank',
                        name:'blank'
                    },
                    {
                        label:'Status',
                        type:'SelectList',
                        options:[
                            {label:'NOT STARTED', value:'NOT STARTED'},
                            {label:'PENDING', value:'PENDING'},
                            {label:'The subject Passed the fit test', value:'The subject Passed the fit test'},
                            {label:'The subject Failed the fit test', value:'The subject Failed the fit test'},
                        ],
                        // options:isSet(props.lookupsOptions['chartResult'],[]),
                        name:'status',
                        id:'status',
                    },
                ]
                },
              ]
        },
        {
            id:'patient',
            data:  [
                {
                    label:'Additonal Patient Details',
                    fields:[
                        {
                            label:"Job Title",
                            type:'TextInput',
                            name:'employee_job_title',
                            id:'employee_job_title'

                        },
                        {
                            label:"A phone number where you can be reached by the health care professional who reviews this questionnaire",
                            type:'Phone',
                            name:'patient_phone_can_be_reached',
                            id:'patient_phone_can_be_reached'

                        },
                        {
                        label:'Has your employer told you how to contact the health care professional who will review this questionnaire?',
                        type:'RadioGroup',
                        name:'employer_told_contact_health_professional',
                        id:'employer_told_contact_health_professional',
                        options:[{label:'Yes',value:1},{label:'No',value:0}],
                    },
                    {
                        label:"The best time to phone you at this number?",
                        type: "SelectSearchList",
                        placeholder:'Time',
                        isAsync:true,
                        options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                        loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                        name:'best_time_to_phone',
                        id:'best_time_to_phone'
                    },
]
                },
                {
                    label:'Health Information',
                    fields:[
                        {   
                            label:'PART A SECTION 1',
                            type:'Label',
                            appendClass:'bg-light',
                            col:'col-12',
                            
                        },
                        {
                            label:'Questionnaire Fulfillment Date',
                            type:'Date',
                            name:'questionnaire_fulfillment_date',
                            id:'questionnaire_fulfillment_date'
                        },
                        {
                            type:'Blank',
                            col:'col-6',
                        },
                        {
                            label:'Height',
                            placeholder:'Feet',
                            col:'col-4',
                            type:'Number',
                            name:'height_feet',
                            id:'height_feet',
                            
                        },
                        {
                            label:'',
                            isLabel:false,
                            placeholder:'Inches',
                            col:'col-2',
                            type:'Decimal',
                            name:'height_inches',
                            id:'height_inches',
                            
                        },
                        {
                            label:'Weight (In Pounds)',
                            placeholder:'Pounds',
                            col:'col-6',
                            type:'Number',
                            name:'weight_lbs',
                            id:'weight_lbs',
                        },
                        {
                            label:'Check the type of respirator you will use (you can check more than one category)',
                            type:'Label',
                            col:'col-12'
                        },
                        {
                            type:'CheckBoxGroup',
                            isLabel:false,
                            col:'col-12',
                            options:[{label:"N, R, or P disposable respirator (filter-mask, non-cartridge type only)",name:'disposable_respirator'},{label:"Other type (for example, half- or full-facepiece type, powered-air purifying, supplied-air,  self-contained breathing apparatus)",name:'other_type'}],
                            name:'previous_respirator_types',
                            id:'previous_respirator_types'
                        },
                        {
                            label:'Have you worn a respirator?',
                            type:'RadioGroup',
                            name:'worn_respirator_before',
                            id:'worn_respirator_before',
                            options:[{label:'Yes',value:1},{label:'No',value:0}],
                        },
                        {
                            label:'If “yes,” what type(s)?',
                            type:'TextInput',
                            rules:[{rule:[{field:'worn_respirator_before',value:1,operator:'!=='}],action:'DISABLE'}],
                            name:'respirator_types',
                            id:'respirator_types',
                        },
                        {   
                            label:'PART A SECTION 2',
                            type:'Label',
                            appendClass:'bg-light',
                            col:'col-12',
                        },
                        // ...appendQuestioner,
                        {
                            label:" Questions 1 through 9 below must be answered by every employee who has been selected to use any type of respirator",
                            type:'Label',
                            appendClass:'bg-light',
                            col:'col-12',

                        },
                        {
                            col:'col-12',
                            label:'',
                            defaultBtnLabel:'Set all to No',
                            type:'YesOrNoTable',
                            failedValue:[1],
                            options:[{label:'Yes',value:1},{label:'No',value:0}],
                            defaultValues:patientQuestionariesArr.reduce((acc, itm) => {
                                acc[itm.name] = 0;
                                return acc;
                            }, {}),
                            list:patientQuestionariesArr,
                            
                        }, 
                        {   
                            label:' Questions 10 to 15 below must be answered by every employee who has been selected to use either a full-facepiece respirator or a self-contained breathing apparatus (SCBA). For employees who have been selected to use other types of respirators, answering these questions is voluntary.',
                            type:'Label',
                            appendClass:'bg-light',
                            col:'col-12',
                        },
                        {
                            type:'Blank',
                            col:'col-12',
                            name:'blank'
                        },
                        {
                            col:'col-12',
                            label:'',
                            defaultBtnLabel:'Set all to No',
                            type:'YesOrNoTable',
                            options:[{label:'Yes',value:1},{label:'No',value:0}],
                            failedValue:[1],
                            defaultValues:secondPatientQuestionariesArr.reduce((acc, itm) => {
                                acc[itm.name] = 0;
                                return acc;
                            }, {}),
                            list:secondPatientQuestionariesArr,
                            
                        }, 
                        
                        
                ]
                },
                {
                    label:'Approval Signature & Date',
                    note:'I certify that the above information is accurate and complete.',
                    col:'col-12',
                    fields:[{
                        label:'Employee Signature',
                        col:'col-6',
                        type:'Signature',
                        name:'employee_signature',
                        id:'employee_signature',
                    },
                    {
                        label:'Date',
                        col:'col-6',
                        type:'Date',
                        id:'employee_signature_date',
                        name:'employee_signature_date',
                    }
                ]
                }]
        }]
    },
   ];
    const headers = [{
        type:'Tab',
        data:[{
            label:'Provider',id:'provider'
        },
        {
            label:'Patient',id:'patient'
        }]
    }];
    const defaultValue = {
        examination_date:getCurrentDate(),
        questionnaire_fulfillment_date:getCurrentDate(),
        status:'Not Started',
        phone_can_be_reached:props.employeeDetails.phone
       
        // reason:'Pre-employment'
    };
    
    return {charts,defaultValue,headers,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
    const pulse = isSet(formData.pulse_rate,'');
    const systolic = isSet(formData.sitting_systolic,'');
    const diastolic = isSet(formData.sitting_diastolic,'');
    let returnResult = {};

    let pulseRateResult = 'Not Started';
    if(pulse==''){
        pulseRateResult = 'Not Started'
    }else if (pulse < 90 || pulse > 150) {
        pulseRateResult =  'Failed';
    }else if(pulse!==''){
        pulseRateResult = 'Passed';
    }

    let bloodPressureResult = 'Not Started';
    if(systolic === '' && diastolic === ''){
        bloodPressureResult = 'Not Started'
    }else if (systolic === '' || diastolic === '') {
        bloodPressureResult = 'Pending';
    } else if ((systolic < 0 || systolic > 140) || (diastolic < 0 || diastolic > 90)) {
        bloodPressureResult = 'Failed';
    } else {
        bloodPressureResult = 'Passed';
    }
   

    let vissionResult = 'Not Started';
    const wearing_corrective_lenses = isSet(formData['wearing_corrective_lenses'],null);
    const conditions = wearing_corrective_lenses===0?'uncorrected':'corrected';
    const horizontalRighteye = isSet(formData['horizontal_field_of_vision_right_eye'],'');
    const horizontalLeftEye = isSet(formData['horizontal_field_of_vision_left_eye'],'');
    if(wearing_corrective_lenses!==null){
            const rightEye  = isSet(formData[''+conditions+'_right_eye'],'');
            const bothEyes  = isSet(formData[''+conditions+'_both_eyes'],'');
            const leftEye  = isSet(formData[''+conditions+'_left_eye'],'');

            if (rightEye === '' || bothEyes === '' || leftEye === '' || horizontalRighteye === '' || horizontalLeftEye === '') {
                vissionResult = 'Pending';
            } else if (rightEye > 40 || bothEyes > 40 || leftEye > 40 || horizontalRighteye < 70 || horizontalLeftEye < 70) {
                vissionResult = 'Failed';
            } else {
                vissionResult = 'Passed';
            }
    }else{
        if(horizontalRighteye==='' || horizontalLeftEye===''){
            vissionResult = 'Pending';
        }else if(horizontalRighteye<70 ||  horizontalLeftEye<70){
            vissionResult = 'Failed';
        }else{
            vissionResult = 'Passed';
        }
    }
        
    const earPrefixes = ['right_ear_', 'left_ear_'];
    earPrefixes.forEach(earPrefix => {
        const frequencies = [500, 1000, 2000,3000,4000,6000,8000];
        let total = 0;
        
       
        frequencies.forEach(frequency => {
            const value = isSet(formData[`audiometric_test_results_${earPrefix}${frequency}_hz`], '');
            total += Number(value);
        });
        const average = Math.ceil(total / frequencies.length);
        returnResult[`audiometric_test_results_${earPrefix}average`] = average;
    });

    const leftAverage = returnResult[`audiometric_test_results_right_ear_average`];
    const rightAverage = returnResult[`audiometric_test_results_left_ear_average`];
    let hearingResult = 'Not Started';
    if ((leftAverage <= 40 && leftAverage !== 0) || (rightAverage <= 40 && rightAverage !== 0)) {
        hearingResult = 'Passed';
    } else if ((leftAverage === 0 || rightAverage === 0) && (leftAverage > 40 || rightAverage > 40)) {
        hearingResult = 'Failed';
    } else if(leftAverage > 40 || rightAverage > 40){
        hearingResult = 'Failed';
    }else{
        hearingResult = 'Pending';
    }


    // let status = 'Not Started';
    // const horizontalRightEar = isSet(formData['whisper_test_results_right_ear'],'');
    // const horizontalLeftEar = isSet(formData['whisper_test_results_left_ear'],'');
    // if(horizontalRightEar==='' || horizontalLeftEar===''){
    //     status = 'Pending';
    // }else if(horizontalRightEar<70 &&  horizontalLeftEar<70){
    //     status = 'Failed';
    // }else{
    //     status = 'Passed';
    // }
    
    returnResult = {
        ...returnResult,
        pulse_rate_result:pulseRateResult,
        blood_pressure_result:bloodPressureResult,
        vission_result:vissionResult,
        hearing_result:hearingResult

    }
    return returnResult;
}