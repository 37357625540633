import React from "react";
import WithRoutify from "../../../hoc/WithRoutify";
import VisitHistory from "./VisitHistory";
import Buttons from "../../../components/UI/Buttons";
import OtherTab from "./OtherTab";
import FullScreenMode from "../../../components/UI/FullScreenMode";
import ImagesList from "./Images";
import ScreeningsList from "./ScreeningsList";
import { decryptString } from "../../../utils/commonUtils";
import LabsHistoryList from "./LabsHistoryList";
import VaccinesHistoryList from "./VaccinesHistoryList";
import SectionLoader from "../../../components/UI/SectionLoader";


class RightContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading:false,
      tableHeaders: [
        { label: "Visit History",id:'VisitHistory',component:VisitHistory },
        { label: "Labs" ,id:'LabsList', component:LabsHistoryList},
        { label: "Vaccines" ,id:'VaccinesList',component:VaccinesHistoryList},
        { label: "Screenings & Tests",id:'ScreeningsList',component:ScreeningsList },
        { label: "Incidents",id:'IncidentsList',component:OtherTab },
        { label: "Referrals",id:'ReferralList',component:OtherTab },
        { label: "Images",id:'ImagesList',component:ImagesList },
        { label: "Alerts",id:'AlertsList',component:OtherTab },
        { label: "Forms",id:'FormsList',component:OtherTab },
        { label: "Charting",id:'ChartingList',component:OtherTab },
      ],
      selectedTab: 0, // Initial selected tab
      scrollerRef: React.createRef(),
      inactiveLeft:true,
    };
  }
  componentDidMount() {
    const { scrollerRef } = this.state; // Access scrollerRef from state

    const checkScroller = () => {
      if (scrollerRef.current) {
        this.setState({ inactiveLeft: scrollerRef.current.scrollLeft === 0 });
      }
    };

    const handleScroll = () => {
      if (scrollerRef.current) {
        this.setState({ inactiveLeft: scrollerRef.current.scrollLeft === 0 });
      }
    };

    checkScroller(); // Run on mount to check if already scrolled to left
    if (scrollerRef.current) {
      scrollerRef.current.addEventListener("scroll", handleScroll);
    }
  }

  componentWillUnmount() {
    const { scrollerRef } = this.state;

    if (scrollerRef.current) {
      scrollerRef.current.removeEventListener("scroll", this.handleScroll);
    }
  }

  handleTabClick = (label) => {
    this.setState({ selectedTab: label });
  };

  render() {
    const { tableHeaders, selectedTab,isLoading,scrollerRef,inactiveLeft} = this.state;
    const { params } = this.props;
    const patientDetails = decryptString(params.patientDetails);
    const ActiveComponent = tableHeaders[selectedTab].component;
    const handleRightClick = () => {
      scrollerRef.current.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    };
    const handleLeftClick = () => {
      scrollerRef.current.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    }
    return (
      <>
       <div className="sh_innerPortview w-100 d-flex">
        <div className="w-100">
          <div className="sh_cardBox">
            <div className="sh_cardHeader" style={{ position: "relative", padding: "6px" }}>
              <div className="form-group d-flex mb-0 justify-content-between align-items-center">
              <div className="d-flex align-items-center"> 
                <h6 className="text-lg f-500 mb-0">
                    <Buttons
                      className="badge back_btn  mr-2 "
                      label={"Back"}
                      iconType={"Back"}
                      acl={"any"}
                      href={'/employee'}
                    />
                </h6>
                </div>
                <div className="tabs-wrapper">
                  <span className={`left scroller-btn ${inactiveLeft ? "inactive" : ""}`} onClick={handleLeftClick}>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </span>
                  <ul
                    className="nav nav-borders nav-tabs border-0" ref={scrollerRef}
                  >
                    {tableHeaders.map((header, headerInd) => (
                      <li key={headerInd}>
                        <a
                          data-bs-toggle="tab"
                          className={`nav-link ${
                            selectedTab === headerInd ? "active" : ""
                          }`}
                          onClick={() => this.handleTabClick(headerInd)}
                        >
                          {header.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                 <span id="right" className="right scroller-btn" onClick={handleRightClick}>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </div>
                <FullScreenMode />
              </div>
            </div>
            <div
              className="sh_cardBody p-0"
              // style={{ background: "#eef2f8", position: "relative" }}
            >
              <div
                className="card border-0 bg-transparent"
                style={{
                  // position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 0,
                }}
              >
                  <SectionLoader isLoading={isLoading} />
                <div className="card-body tab-content p-0 ced">
                  <ActiveComponent
                  selectedTab={tableHeaders[selectedTab].label}
                  setLoader={(bool)=>this.setState({isLoading:bool})} 
                  patientDetails={patientDetails}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default WithRoutify(RightContent);
