import React, { Component } from "react";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import WithRouter from "../../../hoc/WithRouter";
import BasicPhysicalService from "../../../services/PhysicalService";
import WithRoutify from "../../../hoc/WithRoutify";
import { ACTIVITY_TYPE, CREATED, OK } from "../../../utils/constant";
import { isObject, isSet } from "../../../utils/commonUtils";
import CompanyService from "../../../services/CompanyService";
import { SortableContainer,SortableElement} from "react-sortable-hoc";
import LookupService from "../../../services/LookupService";
import styled from "styled-components";
import OrganizationService from "../../../services/OrganizationService";
import withReduxState from "../../../hoc/wReduxState";
import PERMISSIONS from "../../../utils/AclPermissions";
import DropDownService from "../../../services/DropDownService";
class AssignActivities extends Component {


  constructor(props) {
    super(props);
    // You can initialize state here
    this.state = {
      leftContentState: {},
      rightContentState: {},
      validation:{},
      tableRecords:{
        tableHeaders:[],
        tableRows:{
            data:[]
        },
      },
      formDataInEdit:{},
      activityListOptions:[],
      activityTypeOption:[],
      isHoveringNumbers:false,
      isHoveringLetters:false,
      isDragging:false,
      draggingElmt:{},
      isLoading:true
    };
  }
  componentDidMount() {
    this.fetchBasicPhysicalsActivitiesList();
    this.fetchActivityListOptions();
    this.fetchActivityTypeOptions();
  
    // Code to run after the component is mounted
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.physicalId !== prevProps.params.physicalId) {
      this.fetchBasicPhysicalsActivitiesList();
    }
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }
  setIsHoveringNumbers = (val)=>{
    this.setState({isHoveringNumbers:val});
  }
  setIsHoveringLetters = (val)=>{
    this.setState({isHoveringLetters:val});
  }
  onSortEnd = ({ oldIndex, newIndex,...props})=>{
    const {isHoveringNumbers,draggingElmt} = this.state;
    this.setState({isDragging:true},()=>{
      if(isHoveringNumbers===true){
        if(draggingElmt.type==='physical'){
          this.sortingTableHandler(oldIndex,newIndex);
        }else{
          this.assignHandler({actitivity_id:draggingElmt.rowId,sort_order:newIndex},()=>{},true,newIndex);
         
        }
      }
    });
  }
  updateBeforeSortStart = ()=>{
    this.setState({isDragging:true});
  }
  setIsLoaded=(bool)=>{
    this.setState({isLoading:bool});
  }
  onSortStart = (e)=>{
    // console.log('e',e)
    this.setState({isDragging:true});
  }
  onSortOver = (val)=>{
    // console.log('onsortover',val);
  }
  render() {
    const {tableRecords,activityListOptions,activeEditId,formDataInEdit,activityTypeOption} = this.state;
    const {isDragging} = this.state;
    // console.log('isDragging',isDragging);
    return (
      <>
      <SortableList   onSortOver={this.onSortOver} onSortStart={this.onSortStart} helperClass="helperContainerClass" useDragHandle={true} updateBeforeSortStart={this.updateBeforeSortStart}  onSortEnd={this.onSortEnd}>
          <div className="col-lg-6 pb-1 col-12" 
            onMouseEnter={()=>this.setIsHoveringNumbers(true)}
            onMouseLeave={()=>this.setIsHoveringNumbers(false)}
            
              >
            <LeftContent
              assignHandler={this.assignHandler}
              formDataInEdit={formDataInEdit}
              activeEditId={activeEditId}
              onChangeFormDataInEdit={this.onChangeFormDataInEdit}
              sortingTableHandler={this.sortingTableHandler}
              tableRecords={tableRecords}
              isFromCompany={this.props.isFromCompany}
              handleSortStart={this.handleSortStart}
              fetchBasicPhysicalList={this.fetchBasicPhysicalsActivitiesList}
              activityListOptions={activityListOptions} />
          </div>
            <RightContent  
              assignHandler={this.assignHandler}
              isDragging={isDragging}
              leftContentTable={tableRecords}
              SortableItem={SortableItem}
              isLoading={this.state.isLoading}
              leftContentCount={tableRecords.tableRows.data.length}
              activityListOptions={activityListOptions}
              activityTypeOption={activityTypeOption}
              isFromCompany={this.props.isFromCompany}
              handleSortStart={this.handleSortStart}
              
              />
      </SortableList>
      </>
    );
  }

  assignHandler = async (data,callBack=()=>{},isReArrange=false,newIndex=null) => {
    this.setIsLoaded(true);
    let orgId=  isSet(this.props.profile.role) && this.props.profile.role === 'global_admin' && this.props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
    
    const {params,isFromCompany,navigate} = this.props;
    const {validation} = this.state;
    let updateValidation = {...validation};
    let payload = {
      ...data
    }
    let resData = {};
    if(isFromCompany===true){
       payload = {
        ...payload,
        company_physical_id:params.physicalId
      }
      resData = await  CompanyService.saveCompaniesPhysicalActivities(null,payload,params.companyId,params.physicalId);
    }else{
       payload = {
        ...payload,
        physical_id:params.physicalId
      }
      resData = await BasicPhysicalService.saveBasicPhysicalActivities(orgId,payload)
      if (resData.data && resData.data.physical_id !== params.physicalId) {
        // Construct the new URL
        const newPhysicalId = resData.data.physical_id;
        const physicalName= encodeURIComponent(params.physicalName);
        // console.log('physical name--->',physicalName);
        const newUrl = `/basic-physical/assign-activities/${newPhysicalId}/${encodeURIComponent(physicalName)}`;
        navigate(newUrl);   
      }
    }
    
        if (resData.code == OK || resData.code == CREATED) {
          this.fetchBasicPhysicalsActivitiesList('',isReArrange,newIndex);
          callBack();
          this.props.Toast.success("Physical Activity Added Successfully");
        } else if (resData.status && resData.status == "ERROR") {
          if (isObject(resData.data)) {
            for (let key in resData.data) {
              updateValidation = {
                ...updateValidation,
                [key]: resData.data[key].join(","),
              };
            }
          } else {
            this.props.Toast.error(resData.data);
          }
          if (Object.keys(updateValidation).length > 0) {
            this.setState({validation:updateValidation});
           
          }
        }
        return updateValidation;
  };
 
 sortingTableHandler = (from, to) => {
  // const {tableRecords} = this.state;
  const {params} = this.props;
  this.setState(prevState => {
    const { tableRows } = prevState.tableRecords;
    const { data } = tableRows;
  
    // Create a shallow copy of the original array
    const newArray = [...data];
  
    // Perform the array mutation
    this.arrayMoveMutate(newArray, from, to);
    // console.log('newArray',newArray,from,to);
    // Update the state immutably with the new array
    return {
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          ...tableRows,
          data: newArray
        }
      }
    };
  }, () => {
    // Perform any necessary operations after state has been updated
    const { tableRecords } = this.state;
    const mappingList = tableRecords?.tableRows?.data?.map((item, itemIndex) => ({
      mapping_id: item.rowId,
      position: itemIndex + 1
    }));
    this.updatePositionHandler(params.physicalId, mappingList);
  });
  // const tableData = tableRecords.tableRows.data;
  // let array = tableData.slice();
  
  // console.log('arraySort',tableData,from,to);
  // this.arrayMoveMutate(array, from, to);
  // this.setState({tableRecords:{
  //   ...this.state.tableRecords,
  //   tableRows: {
  //     type: "default",
  //     data: array,
  //   },
  // }},()=>{
  //   const {tableRecords} = this.state;
  //   const mappingList = [];
  //   tableRecords?.tableRows?.data?.map((item,itemIndex)=>{
  //     mappingList.push({
  //       mapping_id: item.rowId,
  //       position: (itemIndex+1)
  //     })
  //   });
  //   this.updatePositionHandler(params.physicalId,mappingList);
  //   // console.log('tableRecords',mappingList);
  // });
  // console.log('this.state.tableRecords',array);
};

arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};
 
handleSortStart = (draggingData)=>{
  this.setState({draggingElmt:draggingData});
}

  fetchBasicPhysicalsActivitiesList =async (query='',isReArrange=false,newIndex=null)=>{
    const {params,isFromCompany} = this.props;
    
      this.props.Loader(this.state.isLoading);
      let resData = {};
      let responseData=[];
      try{
        if(isFromCompany===true){
          resData = await  CompanyService.getCompaniesPhysicalActivities(params.companyId,params.physicalId,query);
          responseData = resData;
        }else{
          resData = await BasicPhysicalService.getBasicPhysicalActivities(params.physicalId,query);
          responseData = resData.data;;
        }
          
             let tableData = [];
             responseData.map((phyActivity, physicalIndex) => {
               tableData = [
                 ...tableData,
                 {
                   physicalIndex: 0,
                   handlers:{onMouseDown:() => this.handleSortStart({rowId:phyActivity.mapping_id,type:'physical'})},
                   TrHoc:SortableItem,
                   attributes:{index:physicalIndex,key:`physical-${physicalIndex}`},
                   isHeading: false,
                   rowId: phyActivity.mapping_id,
                   data: {
                     isActive: false,
                     dragging:{
                      acl:isFromCompany==true?PERMISSIONS.COMPANY.EDIT:PERMISSIONS.BASIC_PHYSICAL.EDIT
                     },
                     activity_name: phyActivity.activity.activity_name,
                     activity_id:phyActivity.actitivity_id,
                     activity_code: phyActivity.activity.activity_code,
                     activity_type: phyActivity.activity.activity_type,
                    //  sort_order: phyActivity.sort_order,
                     default_status: isSet(phyActivity.default_status, ''),
                     action: [
                       {
                         className:
                           "btn btn-icon btn-transparent-dark",
                         iconType: "Remove",
                         acl: isFromCompany==true?PERMISSIONS.COMPANY.DELETE:PERMISSIONS.BASIC_PHYSICAL.DELETE,
                         title: "Delete",
                         isDelete:true,
                         clickHandler: () =>
                           this.deleteHandler(phyActivity.mapping_id),
                       }, 
                     ],
                   },
                 },
               ];
             });
             this.setState({
               tableRecords: {
                 tableHeaders:this.getTableHeaders(),
                 tableRows: {
                   type: "default",
                   data: tableData,
                 },
               },
             },()=>{
              this.setIsLoaded(false);
             },()=>{
              
              if(isReArrange===true){
                // console.log('sorting...',tableData.length,newIndex);
                this.sortingTableHandler((tableData.length-1),newIndex);
              }
             }
            );
      }catch(e){
        this.setState({
          tableRecords: {
            tableHeaders:this.getTableHeaders(),
            tableRows: {
              type: "default",
              data: [],
            },
          },
        },()=>{
          this.setIsLoaded(false);
        });

        
      }
    
      
    }
    deleteHandler = async (id)=>{
      this.setIsLoaded(true);
      let orgId=  isSet(this.props.profile.role) && this.props.profile.role === 'global_admin' && this.props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
      const {params,isFromCompany,navigate} = this.props;
        try{
          if(isFromCompany===true){
            await CompanyService.deleteCompaniesPhysicalActivities(params.physicalId,id)
          }else{
            let resData = await BasicPhysicalService.deleteBasicPhysicalActivities(orgId,params.physicalId,id)
            if (resData&&resData.physical_id&& resData.physical_id !== params.physicalId) {
              // Construct the new URL
              const newPhysicalId = resData.physical_id;
              const physicalName= encodeURIComponent(params.physicalName);
              // console.log('physical name--->',physicalName);
              const newUrl = `/basic-physical/assign-activities/${newPhysicalId}/${encodeURIComponent(physicalName)}`;
              navigate(newUrl);   
            }
          }
        }catch(e){

        }
        //toast and show error logic
        this.fetchBasicPhysicalsActivitiesList();
        this.props.Toast.success('Physical Deleted Successfully');
     
    }
    
    onChangeFiltersHandler =(key,val)=>{
      const {filters} = this.state;
      this.setState({
        filters:{
          ...filters,
          [key]:val
        }
      })
    }
    fetchActivityListOptions = async ()=>{
      try{
        const data = await DropDownService.getActivityDropdownList(null);
        let activityListArr = [{label:'-Select-',value:''}];
        data.forEach((item)=>{
          activityListArr.push({
            label:item.activity_name,
            value:item.actitivity_id,
            activity_type:item.activity_type,
            activity_id:item.actitivity_id,
          })
        });
        this.setState({
          activityListOptions:activityListArr
        })
      }catch(e){
  
      }
  }
  //fetch activity type
  fetchActivityTypeOptions=async()=>{
    try{
      const data = await LookupService.getLookupByKey(ACTIVITY_TYPE);
      let activityTypeArr = [{ label: '-Activity Type-', value: '' }];
          data.forEach((item) => {
            activityTypeArr.push({
                    label: item.value,
                    value: item.value
                });
            });
            //set into state  [] activity options
        this.setState({activityTypeOption:activityTypeArr});
    }catch(e){
      //catch execption if need
    }
  }
    getTableHeaders = ()=>[
      {label:'',key:'dragging',type:'DragHandler'},
      // { label: '',key:'isActive',type:'checkBox'},
      { label: 'Activity Name',key:'activity_name',inputType:'SelectList',options:[]},
      { label: 'Activity code',key:'activity_code'},
      { label: 'Activity Type',key:'activity_type'},
      // { label: 'Sort Order',key:'sort_order',inputType:'TextInput'},
      { label: 'Default Status',key:'default_status'},
      { label: 'Action',key:'action',type:'Actions'},
      // Add more columns as needed
    ];
    updatePositionHandler = async (id,mappingArr)=>{
      let payload={
        mappings:mappingArr
      }
      try{
        let response=await BasicPhysicalService.updateBasicPhysicalActivitiesPosition(id,payload);
        // console.log("Update Response",response);
        if (response.code==OK) {
          // this.editHandler(null, {}, null);
          // this.fetchBasicPhysicalsActivitiesList();
          this.props.Toast.success("Physical updated Successfully");
        }
      }catch(e){
        
      }
      
    }
}

AssignActivities.defaultProps = {
  isFromCompany:false
}
export default  withReduxState(WithRoutify(WithRouter(AssignActivities)));

const SortableList = SortableContainer((props) => {
  return(
   
      <div className="row h-100">
        {props.children}
      </div>
 
  )
})
  
  
  
const TrWrapper = styled.tr`
cursor: default;
.firstElement {
  display: flex;
  flex-direction: row;
}

&.helperContainerClass {
  width: auto;
  border: 1px solid #efefef;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;

  &:active {
    cursor: grabbing;
  }

  & > td {
    padding: 5px;
    text-align: left;
  }
}
`;

const SortableItem = SortableElement(props => {
  // console.log('props',props);
  return <TrWrapper key={props.indexKey} id={props.indexKey} onMouseDown={props.onMouseDown}>{props.children}</TrWrapper>;
});
