import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { DrugScreenCollection } from "./DrugScreenCollection";
import { DrugScreenRapidPanel } from "./DrugScreenRapidPanel";
import ChartsFields from "../ChartsFields";
import SectionBasedDetails from "../SectionBasedDetails";
import { isSet } from "../../../../../../utils/commonUtils";
import { XrayPanel } from "./XrayPanel";
import { ScreeningBoxLifting } from "./ScreeningBoxLifting";
import { ScreeningPulmonary } from "./ScreeningPulmonary";
import { ScreeningLadderClimb } from "./ScreeningLadderClimb";
import { ScreeningBloodPressure } from "./ScreeningBloodPressure";
import { ScreeningHearing } from "./ScreeningHearing";
import { ScreeningVision } from "./ScreeningVision";
import { ScreeningHA } from "./ScreeningHA";
import { ScreeningHW } from "./ScreeningHw";
import { ScreeningVF } from "./ScreningVF";
import { Spirometry } from "./Spirometry";
import { ScreeningBAT } from "./ScreeningBAT";
import { ScreeningUrinalysis } from "./ScreeningUrinalysis";
import { MinimalExam } from "./MinimalExam";
import Wrap from "../../../../../../hoc/Wrap";
import Buttons from "../../../../../../components/UI/Buttons";
import { NonDOTExamBPVisionAudio } from "./NonDOTExamBPVisionAudio";
import { ScreeningVP } from "./ScreeningVP";
import {  RespiratorFitTest } from "./RespiratorFitTest";
import { Laboratory } from "./Laboratory";
import LabOrderdService from "../../../../../../services/LabOrderdService";
import { Vaccination } from "./Vaccination";
import PERMISSIONS from "../../../../../../utils/AclPermissions";
import Acl from "../../../../../../hoc/Acl";
import { REASON_FOR_LAB } from "../../../../../../utils/constant";
import LookupService from "../../../../../../services/LookupService";

import { ScreeningTbskinTest } from "./ScreeningTbskinTest";

import SendQuestionnairePopup from "../../../../../../components/Modal/SendQuestionnairePopup";
import withReduxState from "../../../../../../hoc/wReduxState";
import Button from "../../../../../../components/UI/Buttons/Button";
import { IshiharaPlates } from "./IshiharaPlates";
import { NonDOTExamHearingAudio } from "./NonDOTExamHearingAudio";
import AddDocumentModal from "../ChartsFields/AddDocumentModal";


class ActivityChartsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charts: [],
      headers:[],
      buttons:{},
      computationFunction:()=>{},
      reasonForLabs:{},
      isDocumentModalOpen:false,
      isSendQuestionnaireOpen:false
    };
  }

  componentDidMount = async()=> {
    await this.fetchReasonForLabList();
    this.setCharts();
  }
  componentDidUpdate = (prevProps)=>{
    if(prevProps.status!==this.props.status){
      this.setCharts();
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.type !== this.props.type) {
  //     this.setCharts();
  //   }
  // }

  setCharts = ()=> {
    //Drug Test - EZ-Reader
    const { type,activityName} = this.props;
    let returnData = {charts:[],defaultValue:{},headerContent:<></>};
    console.log("Type",type);
    switch (type) {
      case "Drug Screen Collection":
        returnData = DrugScreenCollection({...this.props});
        break;
      case "Drug Screen - 10 Panel":
        returnData = DrugScreenRapidPanel({...this.props},10);
        break;
      case "Drug Screen - 12 Panel":
        returnData = DrugScreenRapidPanel({...this.props},12);
        break;
      case "Drug Screen - 13 Panel":
        returnData = DrugScreenRapidPanel({...this.props},13);
        break;
      case "Drug Screen - 4 Panel":
        returnData = DrugScreenRapidPanel({...this.props},4);
        break;
      case "Drug Screen - 5 Panel":
        returnData = DrugScreenRapidPanel({...this.props},5);
        break;
      case "Drug Screen - 9 Panel":
        returnData = DrugScreenRapidPanel({ ...this.props }, 9);
        break;
      case "Drug Screen - EZ-Reader": 
        returnData = DrugScreenRapidPanel({...this.props},13,'EZ-Reader');
        break;
      case "Xray":
        returnData = XrayPanel({...this.props});
        break;
      case "Screening - Box Lift":
        returnData = ScreeningBoxLifting({...this.props});
        break;
      case "Pulmonary Function":
        returnData = ScreeningPulmonary({...this.props});
        break;
      case "Screening":
        returnData = activityName=='Screening - TB Skin Test'? ScreeningTbskinTest({...this.props}):ScreeningLadderClimb({...this.props});
          break;
      case "Screening - Blood Pressure":
        returnData = ScreeningBloodPressure({...this.props});
        break;
      case "Screening - Hearing":
       returnData = ScreeningHearing({...this.props});
       break;
      case "Screening - Urinalysis":
        returnData = ScreeningUrinalysis({...this.props});
        break;
      case "Screening - Vision - All Test":
        returnData = ScreeningVision({...this.props})
        break;
      case "Screening - VF":
          returnData = ScreeningVF({...this.props},'VF')
          break;
      case "Screening - VJ":
          returnData = ScreeningVF({...this.props},'VJ')
          break;
      case "Screening - VS":
          returnData = ScreeningVF({...this.props},'VS')
          break;
      case "Screening - HA":
        returnData = ScreeningHA({...this.props})
        break;
      case "Screening - HW":
        returnData = ScreeningHW({...this.props})
        break;
      case "Screening BAT":
        returnData = ScreeningBAT({...this.props});
        break;
      case "Screening - VP":
        returnData = ScreeningVP({...this.props});
        break;
      case "Ishihara - 6 Plates":
        returnData = IshiharaPlates({ ...this.props });
        break
      case "Ishihara - 14 Plates":
        returnData = IshiharaPlates({ ...this.props });
        break
      case "Spirometry":
        returnData = Spirometry({...this.props});
        break;
      case "Minimal Exam":
        returnData = MinimalExam({...this.props});
        break;
      case "Non DOT Exam - BP / Vision / Audio":
        returnData = NonDOTExamBPVisionAudio({...this.props});
        break;
        case "Exam - Non DOT - Hearing Audio":
          returnData = NonDOTExamHearingAudio({...this.props});
          break;
      case "Provider Exam":
        returnData = NonDOTExamBPVisionAudio({...this.props},'ProviderExam');
        break;
      case "Respirator Fit Test":
        returnData  = RespiratorFitTest({...this.props});
        break;
      case "Lab":
      case "Lab - Point of Care":
          returnData  = Laboratory({...this.props,reasonForLabs:this.state.reasonForLabs,fetchLabOrderList:this.fetchLabOrderList});
          break;
      case "Vaccine":
        returnData=Vaccination({...this.props});
        break;
      default:
        returnData = {charts:[{type:'Label',value:'Upcoming Development'}],defaultValue:{}};
    }
    const {charts,headerContent,headers,buttons,defaultValue,computationFunction=()=>{}} = returnData;
    // console.log('defaultValue',defaultValue,Object.entries(this.props.formData));
    this.setState({ charts:charts,headers:headers,headerContent:headerContent,buttons:isSet(buttons,{}),computationFunction:computationFunction });
    if(Object.entries(this.props.formData).length===0 && this.props.status===200){
      this.props.setFormData(defaultValue);
    }
   
  }

  fetchReasonForLabList = async () => {
    let lookupOptions = [];
    try {
      const data = await LookupService.getLookupByKey(REASON_FOR_LAB)
      data.forEach((item) => {
        lookupOptions.push({
          label: item.value,
          value: item.value
        });
      });
  
      
    } catch (e) {
      //catch execption if need
      console.error('error in getLookupByKey', e);
      
    }
    this.setState({reasonForLabs:lookupOptions}) ;
  }

  sendQuestionnaireHandler=(bool)=>{
    this.setState({isSendQuestionnaireOpen:bool});
  }

  
fetchLabOrderList = (val,prop,callback=()=>{})=>{
  //{search_query:val}
  const {formData} = this.props;
  // console.log('formData',formData);
  let optionArr =[];
  LabOrderdService.getLabOrder(null,formData?.lab_id) 
  .then((res) => {
    console.log('res',res);
    res?.map((item, itemIndex) => {
      optionArr.push({
        label:item.lab_order,
        value:item.lab_order_id,
        data:item,
        index:itemIndex
      });
    });
    callback(optionArr);
   
  })
  .catch((error) => {
    console.error(error);
  });
  return optionArr;
}
documentModalHandler=()=>{
  this.setState({isDocumentModalOpen:!this.state.isDocumentModalOpen});
}


  render() {
    const { isDocumentModalOpen,isSendQuestionnaireOpen,charts,buttons,headers,computationFunction } = this.state;
    const {activityName,type,hasEditAccess} = this.props;    
    return (
        
         isSendQuestionnaireOpen ?  <SendQuestionnairePopup mappingId={this.props.mappingId} visitDetails={this.props.visitDetails}  isOpen={isSendQuestionnaireOpen} sendQuestionnaireHandler={this.sendQuestionnaireHandler}/>:

        isDocumentModalOpen ?
          <AddDocumentModal
            addFormHandler={this.documentModalHandler}
            mappingId={this.props.mappingId}
            activityId={this.props.activityId}
            visitDetails={this.props.visitDetails}
          />
         :
        <Modal
              show={true}
              onHide={this.props.toogleHanlder}
              size="xl"
              dialogClassName="hResponsive FullScreen"
              contentClassName="h-100"
              backdrop="static"
              keyboard={false}
              autoFocus={false} >
              <div className="sh_modalBox">
                <Modal.Header closeButton>
                  <Modal.Title>
                    <div className="d-flex align-items-center justify-content-center">
                      <h6 className="text-lg mb-0 font-weight-bold">
                        {activityName} - ({type})
                      </h6>
                    </div>
                  </Modal.Title>
                  {isSet(headers,[]).map((header,headerIndex)=>{
                    return(
                      <Wrap key={headerIndex}>
                      {(header.type==='Tab')?
                        <div className="tabs-wrapper">
                          <ul className="nav nav-borders nav-tabs border-0">
                            {header.data.map((tab,tabIndex)=>{
                              const isActive = (tabIndex===0)?'active':'';
                              return tab&&(
                                <li key={tabIndex}>
                                  <a data-bs-toggle="tab" href={`#${tab.id}`} className={`nav-link cursor_pointer ${isActive}`} aria-selected="true" role="tab">
                                    {tab.label}
                                  </a>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      :<></>}
                      </Wrap>
                    )
                  })
                  }
                  </Modal.Header>
                  <Modal.Body>
                  <div className="h-100 row"> 
                  {charts.map((chart, chartIndex) => {
                    const { col, type, data} = chart;
                    return (
                      <div className={`${col} col-12`} key={chartIndex}>
                        {type === 'Grid' ? (
                          <div style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                            {data.map((grid, gridIndex) => (
                              <ChartsFields key={gridIndex} item={grid}  formData={this.props.formData} onSubmitHandler={"on"}/>
                            ))}
                          </div>
                        ) : type === 'SectionBased' ? (
                          <>
                            {data.map((secData, secIndex) => (
                              <SectionBasedDetails
                                key={secIndex}
                                {...secData}
                                readOnly={!hasEditAccess}
                                formData={this.props.formData}
                                validation={this.props.validation}
                                changeHandler={(key,val,obj)=>
                                  this.props.changeHandler(key,val,{...obj,...computationFunction({...this.props.formData,[key]:val},key)})
                                }
                                updateFormData={(obj)=>{
                                  this.props.updateFormData({...obj,...computationFunction({...this.props.formData,...obj})})
                                }
                              }
                              />
                            ))}
                          </>
                        ): type === 'Tab' ? (
                          <div className="tab-content">
                             {data.map((tabData, tabIndex) => (
                                <div id={isSet(tabData.id,'')} className={`tab-pane ${(tabIndex===0)?'active':''}`} key={tabIndex}>
                                 {tabData.id === 'patient' && tabIndex === 1 ?
                                   <>
                                     <Acl acl="visits-edit">
                                       <Button acl="visits-edit" clickHandler={() => { this.sendQuestionnaireHandler(true) }} className="btn btn-secondary" >
                                         Send Questionnaire
                                       </Button>
                                       <br /><br />
                                     </Acl></> : <></>
                                 }
                                  
                                  {isSet(tabData.data,[]).map((secData, secIndex) => (
                                  <div id={isSet(secData.id,'')}>
                                    <SectionBasedDetails
                                      key={secIndex}
                                      {...secData}
                                      readOnly={!(tabData.id === 'patient') && !hasEditAccess}
                                      formData={this.props.formData}
                                      changeHandler={(key,val,obj)=>this.props.changeHandler(key,val,{...obj,...computationFunction({...this.props.formData,[key]:val},key)})}
                                      updateFormData={(obj)=>{
                                        this.props.updateFormData({...obj,...computationFunction({...this.props.formData,...obj})})
                                      }
                                    }
                                    />
                                  </div>
                               ))}
                                </div>
                             ))}
                               
                          </div>
                        ):type==='Label'?
                          <label title={isSet(chart.value,'')}>{isSet(chart.value,'')}</label>:
                      ''}
                      </div>
                    );
                  })}
                </div>
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-between">
                  <div class="addAction ml-2">
                    {(isSet(buttons.label,false)===true)?
                       <div className="btn-group mr-1">
                       <a className="btn btn-xs btn-secondary ">
                         <i className="fas fa-print fa-sm"></i> Label
                       </a>
                       <button
                         className="btn btn-xs  btn-outline-secondary dropdown no-arrow"
                         data-bs-toggle="dropdown"
                         aria-expanded="false" >
                         <span className="dropdown-toggle">
                           <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                         </span>
                         <div
                           className="dropdown-menu dropdown-menu-right shadow"
                           aria-labelledby="userDropdown"
                         >
                           <a
                             className="dropdown-item"
                             href="!#"
                             onClick={() =>
                               this.props.printLabelHandler(
                                 "print_label_2x3",
                                 "2 x 3.8"
                               )
                             }
                           >
                             2 x 3.8
                           </a>
                           <a
                             className="dropdown-item"
                             href="!#"
                             onClick={() =>
                               this.props.printLabelHandler(
                                 "print_label_1x3",
                                 "1⅛ x 3½"
                               )
                             }
                           >
                             1⅛ x 3½
                           </a>
                         </div>
                       </button>
                     </div>
                    :<></>}


                    {(isSet(buttons.PDF,false)===true)?
                       <div className="btn-group mr-1">
                       <a className="btn btn-xs btn-secondary ">
                         <i className="fas fa-print fa-sm"></i> Print Pdf
                       </a>
                       <button
                         className="btn btn-xs  btn-outline-secondary dropdown no-arrow"
                         data-bs-toggle="dropdown"
                         aria-expanded="false" >
                         <span className="dropdown-toggle">
                           <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                         </span>
                         <div
                           className="dropdown-menu dropdown-menu-right shadow"
                           aria-labelledby="userDropdown" >
                           <a
                             className="dropdown-item"
                             href="!#"
                             onClick={() => this.props.printResultHandler('Provider')}>
                             Provider
                           </a>
                           <a
                             className="dropdown-item"
                             href="!#"
                             onClick={() => this.props.printResultHandler('Patient')} >
                             Patient
                           </a>
                         </div>
                       </button>
                     </div>
                    :<></>}
                   
                   {(isSet(buttons.result,false)===true)? (
                      <a
                        class="btn btn-xs  btn-secondary mr-1"
                        onClick={() => this.props.printResultHandler()}>
                        {" "}
                        <i className="fas fa-print fa-sm"></i> Result
                      </a>
                    ) : (
                      <></>
                    )}
                      {(isSet(buttons.labreport,false)===true)? (
                      <a
                        class="btn btn-xs  btn-secondary mr-1"
                        onClick={() => this.props.printResultHandler("Lab_Report")}>
                        {" "}
                        <i className="fas fa-print fa-sm"></i>Laboratory Report
                      </a>
                    ) : (
                      <></>
                    )}
                        {(isSet(buttons.labreport,false)===true)? (
                      <a
                        class="btn btn-xs  btn-secondary mr-1"
                        onClick={() => this.props.printResultHandler("Lab_Requisition")}>
                        {" "}
                        Print Lab Requisition
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                <Acl acl={this.props.profile.role === 'company_employee' ? [PERMISSIONS.APPOINTMENT.CREATE] : [PERMISSIONS.RESULT.EDIT, PERMISSIONS.VISITS.EDIT]}>
                  <div className="p-2">
                    <Buttons
                      clickHandler={() => this.props.onSubmitHandler()}
                      className="btn btn-primary"
                      acl={this.props.profile.role === 'company_employee' ? [PERMISSIONS.APPOINTMENT.CREATE] : [PERMISSIONS.RESULT.EDIT, PERMISSIONS.VISITS.EDIT]}
                      label={"Save"}
                    />
                    <Buttons
                      clickHandler={() =>
                        this.documentModalHandler()
                      }
                      className="btn btn-secondary ml-2"
                      acl={"any"}
                      label={"Add Document"}
                    />
                  </div>
                </Acl>
                  <div></div>
                </Modal.Footer>
                  </div>
        </Modal>
      
    );
  }
}
export default withReduxState(ActivityChartsContent) ;
