// import { BrowserRouter as Route, Routes } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";
import Wrap from '../../hoc/Wrap';
import WhiteboardList from "./WhiteboardList";

const WhiteBoardContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0} layoutType={'App'} element={<WhiteboardList/>} />
      </Routes>
  
     
        
    </Wrap>
  );
};






export default WhiteBoardContainer;
