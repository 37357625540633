import { useEffect, useState } from "react";
import Buttons from "../../../components/UI/Buttons";
import WithRoutify from "../../../hoc/WithRoutify";
import EmployeeDetails from "../../EmployeeContainer/EmployeeDetails";
import { formatPhoneNumberUS } from "../../../utils/commonUtils";
import CompanyService from "../../../services/CompanyService";
import Icons from "../../../components/UI/Icons";

const PatientDetails = (props) => {
  const [viewModelOpen,setViewModelOpen]=useState(false);
  const {employeeDetails,employeeId}=props;
  // console.log('employeeDetails',employeeDetails);
  const inputFields = [
    {
      label: "First Name",
      name: "name",
      value:employeeDetails['first_name'], 
    },
    {
      label: "Last Name",
      name: "name",
      value:employeeDetails['last_name'],
    },
    {
      label: "DOB",
      name: "DOB",
      value:employeeDetails['dob'],
    },
    // {
    //   label: "Patient Phone",
    //   name: "phone",
    //   value:formatPhoneNumberUS(employeeDetails['phone']),
    // },
  ];
  const viewPatientDetails=()=>{
    setViewModelOpen(!viewModelOpen);
  }
  return (
    <>
    {
      viewModelOpen?
      <EmployeeDetails
      employeeId={props.employeeId}
      modalToogleHandler={viewPatientDetails}
      fetchEmployeeList={()=>props.fetchEmployeeDetails(employeeId)}
      company_id={employeeDetails?.company?.company_uuid}
      />
      :
      ''
    }
      <div className="sh_cardBox h-auto sh_patientdetailwidget">
        <div className="sh_cardHeader p-2">
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
            <h6 className="text-md font-weight-bold mb-0">
            <span className="px-2">
              <Icons type="patientdetails" />
            </span>
            Patient Details </h6>
            <Buttons
            className="  text-xs text-info"
            label={" View More"}
            acl={'any'}
            clickHandler={viewPatientDetails}
            />
          </div>
        </div>
        <div className="sh_cardBody py-0">
          <div className="row">
            {inputFields.map((field, fieldInd) => (
              <div className="col-md-12" key={fieldInd}>
                <div className="form-group mb-0">
                  <div className="row" style={{borderBottom: '1px solid #f2f2f4'}}>
                    <div className="col-6 pt-2">
                  <label className="f-400 text-sm text-truncate mb-0 opacity-75">
                    {field.label}
                  </label>
                  </div>
                  <div className="col-6 pt-2">
                  <h6 className="font-weight-bold text-sm text-truncate mb-0" title={field.value}>{field.value}</h6>
                   </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default WithRoutify(PatientDetails);
