import Wrap from "../../hoc/Wrap";
import { Route, Routes } from "react-router-dom";
import ClinicDepartmentList from "./ClinicDepartmentList";


export const ClinicsLocationContainer = () => {
  return (
   <>
   <Wrap>
   <Routes>
        <Route path="/" key={0}  element={<ClinicDepartmentList/>} />
      </Routes>
   </Wrap>
   </>
  );
};
