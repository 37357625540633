import React from "react";
import VaccineResult from "../../ResultContainer/VaccineResult";

const VaccinesHistoryList = (props) => {
  const { patientDetails } = props;
  return (
    <>
      <div id="home" className="tab-pane active show" role="tabpanel">
        <div className="sh_innerPortview">
          <div className=" p-3">
            <div className="sh_cardBox visitboxheight shadow-none border">
              <VaccineResult
                isFromEmployeeHistory={true}
                employee_id={patientDetails.employee_id}
                patientDetails={patientDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VaccinesHistoryList;
