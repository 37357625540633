import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isSet } from '../../../utils/commonUtils';
import WithRoutify from '../../../hoc/WithRoutify';
import FileManagerService from '../../../services/FileManagerService';

const FileInput = (props) => {
  const {
    className,
    placeholder,
    value,
    style,
    name,
    required,
    readOnly,
    changeHandler,
    accept,
  } = props;
  const inputFile = useRef(null);
  //after remove orginal_name remove value from input field
  useEffect(() => {
    if (!value) {
      inputFile.current.value = "";
    }
    if(!isSet(value.base64) && !isSet(value.name)){
      inputFile.current.value = "";
    }
  }, [value]);

  const onChangeHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
      if (file.size >= maxSizeInBytes) {
        props.Toast.error("Selected file exceeds maximum 2 MB size.");
        inputFile.current.value='';
        return; // Exit the function
      }
      changeHandler(file, {e});
    }
  };

  const fetchFile = async (value) => {
    props.Loader(true);
    try {
      await FileManagerService.downloadFile(value);
      props.Loader(false);
    } catch (error) {
      console.log('Error downloading file:', error);
      props.Loader(false);
    }

  }
  return (
    <div className='d-flex flex-wrap file '>
      <input
        ref={inputFile}
        type="file"
        className={className}
        name={name}
        placeholder={placeholder}
        {...style}
        required={required}
        readOnly={readOnly}
        disabled={(isSet(value.file_id || value.base64, null) !== null) ? true : false}
        onChange={(e) => onChangeHandler(e)}
        accept={accept}
      />
      {(isSet(value.file_id, null) !== null) ?
        <div className='sh_fileupload'>
          <a href='#!' className='sh_fileupload_link' onClick={() => fetchFile(value.file_id)}>{value.original_name}</a>
          <span title='remove' onClick={() => changeHandler('')} className='badge bg-red-soft text-red cursor-pointer rounded-circle ml-2'>
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        : <></>}
      {(isSet(value.base64, null) !== null) ?
        <div className=''>
          <div>{value.original_name}</div>
          <span title='remove' onClick={() => changeHandler('')} className='badge bg-red-soft text-red cursor-pointer rounded-circle ml-2'>
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        : <></>}
    </div>
  );
};

FileInput.defaultProps = {
  className: 'form-control form-control-solid',
  placeholder: '',
  value: '',
  style: {},
  readOnly: false,
  required: false,
};

FileInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  changeHandler: PropTypes.func.isRequired,
};

export default WithRoutify(memo(FileInput));
