import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import VisitsService from "../../services/VisitsService";
import { isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import CompanyService from "../../services/CompanyService";
import WithRoutify from "../../hoc/WithRoutify";
import { FieldValidationFocus } from "../../utils/cssUtils";

const AddPhysical = (props) => {
    const { visit_id, isOpen, modalToogleHandler, fetchRecords, Toast,Loader} = props;
    const [validation, setValidation] = useState({});
    const [formData, setFormData] = useState({});
    const [companyPhysical, setCompanyPhysical] = useState([]);
    const [visitDetails, setVisitDetails] = useState({company:{},employee:{}});
    useEffect(() => {
        fetchVisit();
    }, []);
    useEffect(() => {
        fetchCompanyPhysical();
    }, [visitDetails]);
    const fetchVisit = async () => {
        Loader(true);          
        const payload = await VisitsService.getVisits(visit_id);
        setVisitDetails(payload[0]);
        Loader(false);          
    }
    const fetchCompanyPhysical = async () => {
        if (visitDetails.company_id) {
            const payload = await CompanyService.getCompaniesPhysical(visitDetails.company_id);
            // console.log('payload',payload);
            let options = [{ 'label': '--Select--', value: '' }];
            payload.data.data.forEach(item => {
                options.push({ label: item.physical_name, value: item.company_physical_id });
            });
            setCompanyPhysical(options);
        }

    }
    const inputFields = [
        {
            type: "",
            label: "Company Details",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "visit_date",
            readOnly: true,
            value: `${isSet(visitDetails?.company?.name,'')} - ${isSet(visitDetails?.company?.system_id,'')}`,
        },
        {
            type: "",
            label: "Employee Details",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "visit_category",
            readOnly: true,
            value: `${isSet(visitDetails?.employee?.first_name,'')} ${isSet(visitDetails?.employee?.last_name,'')} - ${isSet(visitDetails?.employee?.dob,'')}`,
        },
        {
            type: "SelectSearchList",
            options: companyPhysical,
            label: "Physical",
            required:true,
            id:'company_physical_id',
            placeholder: "",
            className: "form-control form-control-solid",
            name: "company_physical_id",
            value:isSet(formData['company_physical_id'],''),
        },
    ];
    const formSubmitHandler = async () => {
        let payload = { ...formData };
        payload.visit_id = visit_id;
        const response = await VisitsService.savePhysicalActivityForVisit(payload);
        let updateValidation = { ...validation };
        console.log('response', response);
        if (response.status && response.status === "ERROR") {
            if (isObject(response.data)) {
                for (let key in response.data) {
                    updateValidation = {
                        ...updateValidation,
                        [key]: response.data[key].join(","),
                    };
                }
            } else {
                props.Toast.error(response.data);
            }
            if (Object.keys(updateValidation).length > 0) {
                setValidation(updateValidation);
                console.log('updateValidation', updateValidation);
                FieldValidationFocus(inputFields, updateValidation);
            }
        } else {
            fetchRecords();
            modalToogleHandler();
            Toast.success("Physical Added Successfully");
        }
    }

    const changeHandler = (value, fieldName) => {
        const updateFormData = { ...formData };
        const updateValidation = { ...validation };
        updateFormData[fieldName] = value;
        updateValidation[fieldName] = '';
        setFormData(updateFormData);
        setValidation(updateValidation);
    }
    return (
        <>
            <Modal
                show={true}
                onHide={modalToogleHandler}
                dialogClassName="hResponsive"
                keyboard={false}
                backdrop="static"
            >
                <div className="sh_modalBox">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">{"Add Physical / Drug Screen"}</h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {inputFields &&
                                inputFields.map((field, index) => (
                                    <div className={`col-12`} key={index}>
                                        <div className="form-group">
                                            <label className="text-gray-900 text-md opacity-75">
                                                {field.label}
                                            </label>
                                            <FormInputs
                                                id={isSet(field.id,field.name)}
                                                changeHandler={(val, e) => changeHandler(val, field.name, e)}
                                                value={formData[field.name]}
                                                {...field}
                                            />
                                            {isSet(validation[field.name], "") !== "" ? (
                                                <span className="text-danger m-1">
                                                    {isSet(validation[field.name], "")}
                                                </span>
                                            ) : ("")}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                        <Buttons
                            type='Single'
                            className="btn btn-primary"
                            clickHandler={() => formSubmitHandler()}
                            label='Submit'
                            acl='visits-create' />
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    )
}
export default WithRoutify(AddPhysical);