import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import WithRouter from "../../hoc/WithRouter";
import WithRoutify from "../../hoc/WithRoutify";
import PatientFormService from "../../services/PatientFormService";


const SendQuestionnairePopup = (props) => {
    const { isOpen,Toast,sendQuestionnaireHandler,visitDetails,mappingId } = props;
    
    const patientName = visitDetails.employee.first_name+' '+visitDetails.employee.last_name;
    
    const formSubmitHandler = async () => {
        // console.log(visitDetails);
        props.Loader(true);
        const payload={
            "employee_id":visitDetails.employee.employee_id,
            "visit_id":visitDetails[0].visit_id,
            "mapping_id":mappingId,
            "baseUrl":process.env.REACT_APP_BASEURL,
        };
        let data = await PatientFormService.sentPatientQuestionnaire(payload);
        // console.log('data', data);
        
        
        if (data.status && data.status === "ERROR") { 
            props.Loader(false);
            props.Toast.error(data); 
        } else {
            props.Loader(false);
            sendQuestionnaireHandler(false);
            Toast.success('Questionnaire Send Successfully');
        }
    }
    
    return (
        <>
            <Modal
                show={isOpen} onHide={sendQuestionnaireHandler}
                size="md"
                dialogClassName="hResponsive "
                contentClassName="h-40"
                className=""
                backdrop="static"
                keyboard={false}
            >
                <div className="sh_modalBox">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">
                                {'Send Questionnaire to Employee'}
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <p style={{textAlign:"center"}}>
                                    <span >{`Would you like to send the activity visit questionnaire to ${patientName}?`}</span>
                                </p>
                            </div>
                        </div>                        
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary" className="" onClick={() => sendQuestionnaireHandler(false)}>
                            No
                        </Button>
                        <Button variant="primary" className="" onClick={() => formSubmitHandler()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default WithRouter(WithRoutify(SendQuestionnairePopup));