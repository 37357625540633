import React  from 'react';
import PropTypes from 'prop-types';
// import { preventNonNumericalInput } from '../../../utils/commonUtils';
// import {setAndGetAttributes} from './FormInputFunctions';

const NumberInput = (props)=>{
  // Props Attr
   const {
    className,
    placeholder,
    value,
    style,
    name,
    required,
    disabled,
    readOnly,
    max,
    decimalAllowed,
    onKeyPress
  } = props;
  // Handler
   const {
    changeHandler
  } = props;

  const onChangeHandler = (e) => {
    let val = e.target.value;
    if (decimalAllowed) {   
      val = decimalValidation(val);
    } else {// Allow only numbers
      val = val.replace(/\D/g, ''); // Replace non-numeric characters with empty string
    }

    // Validate against max value if provided
    if (max) {
      const maxValue = parseInt(max);
      val = val.slice(0, maxValue);
    }

    changeHandler(val, { e });
  }
  const decimalValidation=(val)=>{
    val = val.replace(/[^\d.]/g, '');
    if ((val.match(/\./g) || []).length > 1) {// Ensure there is only one decimal point
      val = val.replace(/\.+$/, "");
    }
    const parts = val.split('.'); // Ensure at most two decimal places
    if (parts.length > 1) {
      parts[1] = parts[1].slice(0, 2); // Limit to two decimal places
    }
    val = parts.join('.');
    return val;
  } 
    return(
        <input 
          className={className}
          name={name}
          id={name}
          type='text'
          placeholder={placeholder}
          value={value}
          style={{...style}}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          onKeyDown={onKeyPress}
          onChange={(e)=>onChangeHandler(e)} />    
    )
}
NumberInput.defaultProps = {
  className: 'form-control',
  placeholder:'',
  required:false,
  readOnly:false,
  disabled:false,
  onKeyPress:()=>{},
  value:'',
  style:{},
  decimalAllowed:false,
  validation:{
    isValid:true,
    message:'',
  },

}
NumberInput.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  // validationRules: PropTypes.array,
  // valid: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
};

export default NumberInput;