import React, { useEffect } from "react"
import PopupContent from "./PopupContent"
import VisitQuickViewPopup from "./VisitQuickViewPopup"
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import WithRoutify from "../../hoc/WithRoutify";


 const CalendarBodyHoc = ({children,currentDay,direction,...props})=>{
    
   
    const transitionScrollTopStartingTime = (interval=false)=>{
        if(interval===true){
            const checkInterval = 100; 
            const maxAttempts = 20;
            let attempts = 0;
            const intervalId = setInterval(() => {
            const elemt = document.getElementById("calendarRow_0700");
            if(elemt){
                clearInterval(intervalId);
                elemt.scrollIntoView({
                    behavior: "smooth",  // Optional: for smooth scrolling
                    block: "start",     // Scroll the element to the center of the viewport
                    inline: "nearest"    // Aligns the element vertically and horizontally
                });
            } else if (attempts >= maxAttempts) {
                // Stop checking if the maximum number of attempts is reached
                clearInterval(intervalId);
                // console.log("Element not found after maximum attempts");
            }
            attempts++;
            }, checkInterval);
        }else{
            const elemt = document.getElementById("calendarRow_0700");
            elemt&&elemt.scrollIntoView({
                behavior: "smooth",  // Optional: for smooth scrolling
                block: "start",     // Scroll the element to the center of the viewport
                inline: "nearest"    // Aligns the element vertically and horizontally
            });
        }
       
    }
    useEffect(()=>{
        transitionScrollTopStartingTime(true);
    },[props.location])
    return(
		    <div id="calendar" className="fc fc-bootstrap4 fc-ltr position-relative">
                    <TransitionGroup className={`fc-view-container ${direction}`} style={{position:'absolute'}}>
                        <CSSTransition
                            key={currentDay.getTime()}
                            timeout={100} // Slow and smooth transition
                            classNames="" 
                            // onEntered={() => transitionScrollTopStartingTime()} 
                            >
                            <div className="fc-view-container" style={{ position: 'absolute'}}>
                                {children}
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            key={currentDay.getTime()}
                            timeout={400} // Slow and smooth transition
                            classNames={direction === 'Right' ? 'slide-left' :direction === 'Left'?'slide-right':''} 
                            onEntered={() =>transitionScrollTopStartingTime()}>
                            <div className="fc-view-container" style={{ position: 'absolute' }}>
                                {children}
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                {/* View More popup */}
               {(props.isShowMorePopup===true) && <PopupContent 
                    isShow={props.isShowMorePopup}
                    position={props.anchorTagPosition} 
                    popupCalendarData={props.popupCalendarData}
                    showQuickVisitView={props.showQuickVisitView}
                    modalToogle={props.modalToogle} />}
                {/* Visit Quick status update popup */}
                {(props.isShowQuickViewPopup===true) && <VisitQuickViewPopup 
                    isShow={props.isShowQuickViewPopup}
                    position={props.anchorTagPosition} 
                    calendarActionHandler={props.calendarActionHandler}
                    quickViewDetails={props.quickViewDetails}
                    popupCalendarData={props.popupCalendarData}
                    viewVisitHandler={props.viewVisitHandler}
                    visitStatusOption={props.visitStatusOption}
                    modalToogle={props.quickViewModalToogle}
                    currentDay={currentDay}
                    />}
            </div>
        
    )
}

export default WithRoutify(CalendarBodyHoc);