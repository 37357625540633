import React, { useEffect, useRef } from 'react';
import { isSet } from '../../utils/commonUtils';
import { calendarWidgetVisitTypeBasedColor } from './calendarFunctions';

const PopupContent = ({ position, modalToogle, isShow, popupCalendarData, showQuickVisitView,...props }) => {
    const { dateTime, data } = popupCalendarData;
    const popupRef = useRef(null);
   
    useEffect(() => {
        // Function to handle click outside
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                modalToogle(); // Close the popup if clicked outside
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalToogle]);

    return (
        <div
            ref={popupRef}
            className='fc-popover fc-more-popover fc-day fc-day-mon fc-day-today'
            id="calendarViewMorePopup"
            style={{
                position: 'absolute',
                // top: position?.y,
                // left: position?.x,modalToogle();
                left:position?.left,
                top:position?.top,
                background: '#fff',
                zIndex: 992,
                display: isShow ? 'block' : 'none',
                maxHeight: '300px',
                overflowY: 'auto',
                padding:'4px',
                boxShadow: '0 1px 3px 0 rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15)'
            }}
        >
            <div className="fc-popover-header ">
                <span className="fc-popover-title" id="fc-dom-91">{dateTime.date} {dateTime?.time}</span>
                <span className="fc-popover-close fc-icon fc-icon-x" onClick={modalToogle} title="Close"></span>
            </div>
            <div className="fc-popover-body ">
                {isSet(data, []).map((item, itemIndex) => {
                    const typeBasedClass = calendarWidgetVisitTypeBasedColor(item.visit_category);
                    return (
                        <div className={`fc-daygrid-event-harness text-truncate`} key={itemIndex}>
                            <a  
                                title={`${item.visit_time} : ${item.employee.first_name} ${item.employee.last_name}`}
                                className={`fc-event fc-event-draggable fc-event-resizable fc-event-start fc-event-end fc-event-today fc-daygrid-event fc-daygrid-dot-event justify-content-start ${typeBasedClass}`}
                                onClick={(e) => {  e.stopPropagation(); showQuickVisitView(e,item) }}>
                                 
                                    <div className="fc-daygrid-event-dot"></div>
                                    <div className="fc-event-time text-sm font-weight-bold">{item.visit_time} <span class="font-weight-bold"> : </span> </div>
                                    <div className="fc-event-title text-sm font-weight-bold pl-1 text-truncate">{item.employee.first_name + ' ' + item.employee.last_name}</div>
                               
                                
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

PopupContent.defaultProps = {
    position: {
        x: 0,
        y: 0
    }
}

export default PopupContent;
