import React from "react";
import WithRoutify from "../../../hoc/WithRoutify";
import WithRouter from "../../../hoc/WithRouter";
import LabList from "../../LabContainer/LabList";

const LabsHistoryList = (props) => {
  const { patientDetails } = props;
  return (
    <>
      <div id="home" className="tab-pane active show" role="tabpanel">
        <div className="sh_innerPortview">
          <div className=" p-3">
            <div className="sh_cardBox visitboxheight shadow-none border">
              <LabList
                isFromEmployeeHistory={true}
                employee_id={patientDetails.employee_id}
                patientDetails={patientDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WithRouter(WithRoutify(LabsHistoryList));
