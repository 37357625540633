// src/services/userService.js
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';
import { OK } from '../utils/constant';

const DependentService = {
  getDependentList: wOrgIdMiddleware(async (url, employee_id) => {
    try {

      const response = await http.GET(`${url}dependent/${employee_id}`);
      if (response.code === OK && response.data) {
        return response.data;
      }
      return response;
    } catch (error) {
      // console.error('Error fetching user:', error);
      throw error;
    }
  }),
  //Save user
  saveDependent: wOrgIdMiddleware(async (url = '', employee_id, data) => {
    try {
      const response = await http.POST(`${url}dependent/${employee_id}`, data);
      return response;
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),

  //Save user
  updateDependent: wOrgIdMiddleware(async (url, employeeID, id, data) => {
    try {
      const response = await http.PUT(`${url}dependent/${employeeID}/${id}`, data);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),
  //Delete clinic
  deleteDependent: wOrgIdMiddleware(async (url, employeeID, id) => {
    try {
      const response = await http.DELETE(`${url}dependent/${employeeID}/${id}`);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),
  //get company
  getDependentId: wOrgIdMiddleware(async (url, employeeID, id) => {
    try {
      // const orgId       = OrganizationService.getOrgId();
      const response = await http.GET(`${url}dependent/${employeeID}/${id}`);
      return response;
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),

};

export default DependentService;