const login = {
    emailAddress: 'Email address',
    password: 'Password',
    login: 'Login',
    SelfRegister: 'Self-Register',
    ForgotPassword: 'Forgot Password',
    FirstTimeUser: 'First Time User',
    loginTerms: 'By logging in you confirm that you accept the Terms and Conditions and Privacy Policy',
    invalidEmail: 'Invalid Email',
    invalidPassword: 'Invalid Password',
    resetPassword: 'Reset Password',
    returnToLogin: 'Return to Login',
    submit: 'Submit',
    PasswordRecovery: 'Password Recovery',
    enterEmailAddress: 'Enter Email Address',
    ResetPassword: 'Reset Password',
    EnterPassword: 'Enter Password',
    ReEnterPassword: 'Re-Enter Password',
    resetPasswordDescription: 'Enter your email address and we will send you a link to reset your password.',
};

export default login;
