import common from './common';
import login from './login';
import organization from './organization';
import selfRegister from './selfRegister';

const translations = {
    
        ...common,
        ...login,
        ...selfRegister,
        ...organization
    // Add other languages here
};

export default translations;
