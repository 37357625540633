// src/services/authService.js
import http from './http';
import { persistor } from '../store';
import OrganizationService from './OrganizationService';
import { UNPROCESSABLE_CONTENT } from '../utils/constant';
import UserService from './UserService';
// import { OK } from '../utils/constant';
import i18n from '../i18n/config';

const AuthService = {
  login: async (credentials) => {
    const { email, password } = credentials;
    const params = `email=${email}&password=${password}`;
    try {
      const response = await http.POSTwithoutAES('login?' + params, credentials);
      // Handle token storage, session management, etc.
        if (response.code === 200 && response.data) {
          const responseData = response.data;
          AuthService.storeToken(responseData.access_token);
          AuthService.storeRefreshToken(responseData.refresh_token);
          
          return response.data;
        } else {
          return response;
        }
      


    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.log('Error during login:', error);
      //   throw error;
    }
  },
  impersonateLogin: async (id) => {
    try {
      const response = await http.POSTwithoutAES(`organizations/${id}/impersonate-login`, { organizationuuid: id });
      // Handle token storage, session management, etc.
        if (response.code === 200 && response.data) {
          AuthService.storeToken(response.data);

          return response.data;
        } else {
          return null;
        }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.log('Error during login:', error);
      //   throw error;
    }
  },
  impersonateLogout: async () => {
    try {
      const response = await http.POSTwithoutAES(`impersonate-logout`);
      // Handle token storage, session management, etc.
        if (response.code === 200 && response.data) {
          OrganizationService.removeOrgId();
          AuthService.storeToken(response.data);

          return response.data;
        } else {
          AuthService.logout();
          return null;
        }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      AuthService.logout();
      console.log('Error during login:', error);
      //   throw error;
    }
  },
  // impersonate-logout
  logout: async () => {
    try {
      // Perform logout actions, e.g., invalidate token
      // Optionally, you might also make an API call to log out on the server
      persistor.purge();
      persistor.flush();
      AuthService.removeProfileId();
      const bearerToken = AuthService.getToken();
      OrganizationService.removeOrgId();
      AuthService.removeRefreshToken();
      AuthService.removeToken();
      AuthService.removePrefLanguage();
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`,
      }
      await http.POSTwithoutAES(`logout`, {}, headers);
    } catch (error) {
      persistor.purge();
      persistor.flush();
      OrganizationService.removeOrgId();
      AuthService.removeProfileId();
      AuthService.removeRefreshToken();
      AuthService.removeToken();
      AuthService.removePrefLanguage();
      //   console.error('Error during logout:', error);
      //   throw error;
    }
  },
  storeToken: (token) => {
    localStorage.setItem('access_token', token);
  },
  removeToken: () => {
    // Remove the token from storage
    localStorage.removeItem('access_token');
  },
  getToken: () => {
    // Remove the id from storage
    return localStorage.getItem('access_token');
  },


  storePrefLanguage: (lang) => {
    localStorage.setItem('preferred_language', lang);
  },
  removePrefLanguage: () => {
    // Remove the token from storage
    i18n.changeLanguage('en');
    localStorage.removeItem('preferred_language');
  },
  getPrefLanguage: () => {
    // Remove the id from storage
    // console.log('configPref',localStorage.getItem('preferred_language'));
    return localStorage.getItem('preferred_language');
  },


  storeRefreshToken: (token) => {
    localStorage.setItem('refresh_token', token);
  },
  removeRefreshToken: () => {
    // Remove the refresh token from storage
    localStorage.removeItem('refresh_token');
  },
  getRefreshToken: () => {
    // Remove the refresh from storage
    return localStorage.getItem('refresh_token');
  },

  storeProfileId: (token) => {
    localStorage.setItem('profileId', token);
  },
  removeProfileId: () => {
    // Remove the Id from storage
    localStorage.removeItem('profileId');
  },
  getProfileId: () => {
    // Remove the Id from storage
    return localStorage.getItem('profileId');
  },

  isAuthenticated: () => {
    // Assuming you store the authentication token in localStorage
    const token = localStorage.getItem('access_token');

    // Check if the token exists and is not expired
    return !!token; // You might want to enhance this with token expiration checks
  },
  forgetPasswordEmailResetLink: async (formData, resetURL) => {
    try {
      let body = { "email": formData.email, "resetURL": resetURL }
      const response = await http.POSTwithoutAES('forgot-password', body);
      // if (response.code === 200 && response.data) {
        return response;
      // }
    } catch (error) {
      if (error.status === UNPROCESSABLE_CONTENT) {
        return error.data;
      }
      //   throw error;
    }
  },
  resetPassword: async (body) => {
    try {
      const response = await http.POSTwithoutAES('reset-password', body);
      // console.log("res",response);
      return response;
    } catch (error) {
      console.log('Error during login:', error);
      if (error.status === UNPROCESSABLE_CONTENT) {
        return error.data;
      }
      //   throw error;
    }
  },
  sessionExpired:()=>{
    persistor.purge();
    persistor.flush();
    AuthService.removeProfileId();
    OrganizationService.removeOrgId();
    AuthService.removeToken();
    AuthService.removeRefreshToken();
    UserService.removeProfile();
    AuthService.removePrefLanguage();
  },
  sendVerificationCodeForSelfRegistration:async(phoneNumber,employee_id,headers={})=>{
      try {
          let body = { "phone": phoneNumber, "employee_id": employee_id}
          const response = await http.POST('self-register-otp', body,headers);
          return response;
      } catch (error) {
          if (error.status === UNPROCESSABLE_CONTENT) {
            return error.data;
          }
          //   throw error;
      }
  },
  loginWithCheckingOTP:async(data, headers = {})=>{
      try {
        const response = await http.POST('login-by-checking-otp', data,headers);
        return response;
        
      } catch (error) {
        if (error.status === UNPROCESSABLE_CONTENT) {
          return error.data;
        }
        //   throw error;
      }
  },
};


export default AuthService;