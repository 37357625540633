import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import CompanyService from "../../services/CompanyService";
import { CREATED, OK } from "../../utils/constant";
import Buttons from "../../components/UI/Buttons";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import LookupService from "../../services/LookupService";
import WithRouter from "../../hoc/WithRouter";
import { FieldValidationFocus } from "../../utils/cssUtils";

const AddPhysical = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [reminderInOption,setReminderIn] = useState([]);
  const { isOpen, addFormHandler, modelTitle,activeEditId,companyId} = props;
  useEffect(()=>{
    if (activeEditId) {
      fetchPhysical();
    }
    fetchReminderIn();
  },[])
    // Effect to manage read-only state of "Bundle Price"
    useEffect(() => {
      if (formData.itemize_pricing) {
        setFormData((prevData) => ({
          ...prevData,
          bundle_price: "", // Optionally reset the value when read-only
        }));
      }
    }, [formData.itemize_pricing]);
  const fetchPhysical=async()=>{
    const response=await CompanyService.getCompaniesPhysicalById(companyId,activeEditId);
    let updatedFormData={...response};
    setFormData(updatedFormData);
  }
  const inputFields = [
    {
      type: "TextInput",
      label: "Physical",
      placeholder: "Physical",
      className: "form-control form-control-solid",
      name: "physical_name",
      required:true,
    },     
      {
        type: "TextInput",
        label: "Reporting Email",
        placeholder: "Reporting Email",
        className: "form-control form-control-solid",
        name: "reporting_email",
      },
      {
        type: "Number",
        label: "Bundle Price",
        placeholder: "Bundle Price",
        className: "form-control form-control-solid",
        name: "bundle_price",
        decimalAllowed:true,
        readOnly: formData.itemize_pricing, // Set read-only based on checkbox state
      },
      {
        type: "Checkbox",
        label: "Itemize Pricing",
        id:'itemize_pricing',
        className: "form-check-input",
        name: "itemize_pricing",
      },
      {
        type: "Checkbox",
        label: "Drug Screen",
        id:'drug_screen',
        className: "form-check-input",
        name: "drug_screen",
      },
    {
      type: "SelectList",
      label: "Remind in",
      options: reminderInOption,
      placeholder: "",
      className: "form-control form-control-solid",
      name: "reminder",
    },
    
    
  ];
  //change handler for add physical 
  const changeHandler = (fieldName,val) => {
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({ ...validation, [fieldName]: "" });
  };

  //fetch reminder in lookup
  const fetchReminderIn = async ()=>{
    try{ 
      const data = await LookupService.getLookupByKey('remind_in');
      let reminderInArr = [{ label: '-None-', value: '' }];
          data.forEach((item) => {
            reminderInArr.push({
                    label: item.value,
                    value: item.value
                });
            });
        setReminderIn(reminderInArr);
    }catch(e){
    
    }
  }

  //submit hanlder
  const formSubmitHandler = async () => {
    //prepare payload here
    const companyId = props.params.companyId;
    let payload = {
        ...formData,
        company_id:companyId
    }
    // payload.drug_screen==1?payload.drug_screen="Yes":payload.drug_screen="No"
    // payload.itemize_pricing==1?payload.itemize_pricing="Yes":payload.itemize_pricing="No"
    let updateValidation = {};
    let res=null;
    if (activeEditId) {
      res = await CompanyService.updateCompaniesPhysical(companyId,activeEditId,payload)
    }
    else{
      res = await CompanyService.saveCompaniesPhysical(isSet(payload.organization,null),companyId,payload)
    }
    if (res.code == OK || res.code == CREATED) {
      props.fetchPhysicalList();
      addFormHandler();
      let message= `${activeEditId?'Physical Updated Successfully':'Physical Added Successfully'}`
      props.Toast.success(message);
    } else if (res.status && res.status == "ERROR") {
      if (isObject(res.data)) {
        for (let key in res.data) {
          updateValidation = {
            ...updateValidation,
            [key]: res.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(res.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
         FieldValidationFocus(inputFields, updateValidation);
      }
    }
  };
  return (
    <>
      <Modal 
        show={isOpen} 
        onHide={addFormHandler}
        size="lg"
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
      >
      <div className="sh_modalBox">
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="text-lg mb-0 font-weight-bold">{`${activeEditId?'Update Physical':'Add Physical'}`}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {inputFields &&
                      inputFields.map((field, index) => (
                        <div className="col-12" key={index}>
                          <div className={`form-group ${field.type==='Checkbox'?'form-check form-check-solid':''}`}>
                            {(field.type!=='Checkbox')?<label className="text-gray-900 text-md opacity-75"> {field.label} </label>:''}
                            <FormInputs {...field}  id={isSet(field.id,field.name)} changeHandler={(val)=>changeHandler(field.name,val)} value={isSet(formData[field.name],'')} />
                            {(field.type==='Checkbox')?<label className="form-check-label" htmlFor={field.name}> {field.label} </label>:''}                        
                            {isSet(validation[field.name],'')!=='' ? <span className="text-danger m-1">{isSet(validation[field.name],'')}</span> : ''}
                          </div>
                        </div>
                      ))}
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <div className="btn-group">
            <Buttons
              clickHandler={() => formSubmitHandler()}
              className="btn btn-primary"
              acl={"any"}
              label={`${activeEditId?'Update':'Save'}`}
            />
          </div>
        </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default WithRouter(withReduxState(WithRoutify(AddPhysical)));
