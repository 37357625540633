import React from 'react';

const  AddIconDetailView = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    id="plus"
  >
    <path
      fill="#5453D2"
      d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
    ></path>
  </svg>
);

export default AddIconDetailView;