import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isSet , isObject , formatPhoneNumber} from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import WithRouter from "../../hoc/WithRouter";
import OrganizationService from "../../services/OrganizationService";
import ConsentFormService from "../../services/ConsentFormService";


const calculateAge = (dateOfBirth) => {
    // Parse the date of birth string to a Date object
    const dob = new Date(dateOfBirth);
    // Get the current date
    const currentDate = new Date();
    
    // Calculate the difference in years
    let age = currentDate.getFullYear() - dob.getFullYear();
    
    // Check if the current date hasn't reached the birth date yet this year
    if (currentDate.getMonth() < dob.getMonth() || (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())) {
        age--;
    }
    
    return age;
};

const EditClinicVisitCategoryConsentForm = (props) => {
    const { isOpen , snapEmployeeDetails,snapCompanyDetails,organizationLogo } = props;

    const [formData, setFormData] = useState({
        organization_uuid: OrganizationService.getOrgId(),
        visit_id : isSet(props.snapClinicConsentForm.visit_id,props.visitId) ,
        employee_id : snapEmployeeDetails.employee_id,
        company_id : snapEmployeeDetails.company_id,
        clinic_id : snapCompanyDetails?.clinic_id ?? snapEmployeeDetails.clinic_id,
        visit_category: props.visit_category,
        title_en : props.snapClinicConsentForm.title_en,
        consent_form_content_en : props.snapClinicConsentForm.consent_form_content_en,
        acknowledgement_en:props.snapClinicConsentForm.acknowledgement_en,
        printed_name: `${snapEmployeeDetails.first_name} ${snapEmployeeDetails.last_name}`,
        date_of_birth: snapEmployeeDetails.dob,
        email_address: snapEmployeeDetails.email,
        address: snapEmployeeDetails.address,
        phone: snapEmployeeDetails.phone,
        age: calculateAge(snapEmployeeDetails.dob),
        is_acknowledged : (props.snapClinicConsentForm.is_acknowledged  || props.snapClinicConsentForm.is_acknowledged ===1 ) ? 1 : 0,
        consent_date: isSet(props.snapClinicConsentForm.consent_date,""),
        patient_signature : isSet(props.snapClinicConsentForm.patient_signature,""),
    });



    // useEffect to update the age when the date of birth changes
    useEffect(() => {
        // Calculate the age based on the date of birth prop
        const calculatedAge = calculateAge(snapEmployeeDetails.dob);
        // Update the age state
        setFormData(prevFormData => ({
            ...prevFormData,
            age: calculatedAge
        }));
    }, [snapEmployeeDetails.dob]);

    


    const [validation, setValidation] = useState({});

    const inputFields = [
        {
            type: 'Checkbox',
            label: isSet(props.snapClinicConsentForm.acknowledgement_en),
            col: 'col-12',
            isLabelAppend: true,
            className: "form-check-input",
            name: 'is_acknowledged',
            checked: formData.is_acknowledged === 1,
        },
        {
            label: "Printed Name",
            
            className: "form-control form-control-solid",
            name: "printed_name",
            col: "col-4",
            required: true,
            value: formData.printed_name, // Assuming printedName is a prop containing the value
            readOnly: true // Set readOnly to true to make the field uneditable
        },
        {
            label: "Date of Birth",
            type: "TextInput",
            className: "form-control form-control-solid",
            name: "date_of_birth",
            col: "col-4",
            required: true,
            value: formData.date_of_birth, // Assuming dateOfBirth is a prop containing the value
            readOnly: true // Set readOnly to true to make the field uneditable
        },
        {
            label: "Age",
            type: "Number",
            className: "form-control form-control-solid",
            name: "age",
            col: "col-4",
            required: true,
            value: formData.age, // Assuming calculateAge is a function that calculates the age based on the date of birth
            readOnly: true // Set readOnly to true to make the field uneditable
        },
        {
            label: "Email Address",
            type: "Email",
            className: "form-control form-control-solid",
            name: "email_address",
            col: "col-4",
            required: true,
            value: formData.email_address, // Assuming emailAddress is a prop containing the value
            readOnly: true // Set readOnly to true to make the field uneditable
        },
        {
            label: "Address",
            type: "TextInput",
            className: "form-control form-control-solid",
            name: "address",
            col: "col-4",
            required: true,
            value: formData.address, // Assuming address is a prop containing the value
            readOnly: true // Set readOnly to true to make the field uneditable
        },
        {
            label: "Phone",
            type: "Tel",
            className: "form-control form-control-solid",
            name: "phone",
            col: "col-4",
            required: true,
            value: formatPhoneNumber(formData.phone), // Assuming phone is a prop containing the value
            readOnly: true // Set readOnly to true to make the field uneditable
        },
        {
            label: "Patient Signature/Consent",
            type: "Signature",
            className: "form-control form-control-solid",
            name: "patient_signature",
            options: [],
            col: "col-6",
            value:formData.patient_signature,
            required: true
        },
        {
            label: 'Date',
            type: 'Date',
            name: 'consent_date',
            col: "col-6",
            value:formData.consent_date,
            required: true
        }
    ];
    
    

    const changeHandler = (val, fieldName) => {
        // if (fieldName === 'declination_message_checked') {
        //     obj = { authorization_message_checked: false };
        // } else if (fieldName === 'authorization_message_checked') {
        //     obj = { declination_message_checked: false };
        // }

        setFormData({
            ...formData,
            [fieldName]: val,
          });
        setValidation({ ...validation, [fieldName]: "" });
    };


    const formSubmitHandler = async () => {
        let updateValidation = { ...validation };
        // if (!formData.is_acknowledged) {
        //     updateValidation.is_acknowledged = "Acknowledgement is required";
        //     setValidation(updateValidation);
        //     return; // Stop form submission
        // }
        const payload = formData;
        props.Loader(true);
        let data = null;
    
        data = await ConsentFormService.saveConsentFormToVisit(isSet(formData.organizationId, null), payload);

        if (data.status && data.status === "ERROR") {
            if (isObject(data.data)) {
                for (let key in data.data) {
                updateValidation = {
                    ...updateValidation,
                    [key]: data.data[key].join(","),
                };
                }
            } else {
                props.Toast.error(data.data);
            }
            if (Object.keys(updateValidation).length > 0) {
                setValidation(updateValidation);
            }
        } else {
            let message = "Consent Form Updated Successfully"
            props.editConsentHandler(true);
            props.Toast.success(message);
        }
        props.Loader(false);
    }

    
    return (
        <>
            <Modal show={isOpen}
            onHide={props.editConsentHandler}
            dialogClassName='hResponsive'
            keyboard={false}
            contentClassName='h-100'
            backdrop="static"
            size="lg">
                <div className="sh_modalBox">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">
                                Consent Form
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="tab-pane active show" role="tabpanel" id="ac">
                            <div className="card mb-2">
                                <a href="#collapseCardExample" className="d-block sh_accordionheader card-header" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample" style={{ position: 'sticky', top: '-20px', zIndex: '1' }}>
                                    <h6 className="m-0 text-md ">Clinic Consents for <span className="badge bg-blue-soft text-blue"> {props.visit_category} </span> </h6>
                                </a>
                                <div className="collapse show" id="collapseCardExample" data-bs-parent="#modal111">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="container">
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-8 col-md-10">
                                                        <div className="mb-4 p-3 text-center ">
                                                            <img src={`data:image/jpeg;base64,${organizationLogo}`} alt="" style={{ maxWidth: '75%', maxHeight: '150px', margin: 'auto' }} />
                                                        </div>
                                                        <div className="mb-4 p-3 text-center ">
                                                            <h6 className="font-weight-bold text-uppercase">{props.snapClinicConsentForm.title_en}</h6>
                                                        </div>
                                                        
                                                    </div>
                                                     <div className="mw-100 w-100">
                                                            <HTMLContent htmlString={props.snapClinicConsentForm.consent_form_content_en} />
                                                     </div>
                                                </div>
                                            </div>
                                            {inputFields &&
                                                inputFields.map((field, fieldIndex) => {
                                                    if (isSet(field.isShow, true) === false) { return false }
                                                    return (
                                                        <div className={field.col} key={fieldIndex}>
                                                            <div className={`form-group ${isSet(field.isLabelAppend, false) === true ? 'form-check form-check-solid' : ''}`} >
                                                                {(isSet(field.isLabelAppend, false) === false) ?
                                                                    <label className="mb-0 text-sm opacity-75 text-truncate">
                                                                        {field.label}
                                                                    </label>
                                                                    : <></>}

                                                                <FormInputs {...field} value={isSet(formData[field.name], '')} changeHandler={(value, e) => changeHandler(value, field.name)} />
                                                                {(isSet(field.isLabelAppend, false) === true) ?
                                                                    <>
                                                                        <label className="form-check-label" >
                                                                            {field.label}
                                                                        </label><br /><br />
                                                                    </>

                                                                    : <></>}
                                                                {isSet(validation[field.name], "") !== "" ? (
                                                                    <span className="text-danger m-1">
                                                                        {isSet(validation[field.name], "")}
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="" onClick={() => formSubmitHandler()}>
                            Update
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default WithRoutify(EditClinicVisitCategoryConsentForm);


const HTMLContent = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};