import {  OK } from '../utils/constant';
import http from './http';
// import OrganizationService from './OrganizationService';
import { isSet } from '../utils/commonUtils';


const ActivitiesService = {
  //Save
  saveActivities: async (orgId,data) => {
    try {
      
      const response = await http.POST(`activities?organization_id=${orgId}`, data);
      return response;
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  getActivities: async (orgId,query) => {
    const queryString = new URLSearchParams(query).toString();
    try {

      const response = await http.GET(`activities?${queryString}&organization_id=${orgId}`);
      
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  getActivitiesById: async (id) => {
    try {
      const response = await http.GET(`activities/${id}`);
      
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      // throw error;
    }
  },
  updateActivities: async (orgId,id, data) => {
    try {
      
      const response = await http.PUT(`activities/${id}?organization_id=${orgId}`, data);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
    }
  },
  deleteActivities: async (orgId,id) => {
    try {
      
      const response = await http.DELETE(`activities/${id}?organization_id=${orgId}`);

      if (isSet(response) && (response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return null;
      }
     
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
      throw error;
    }
  },
};
  
  export default ActivitiesService;