import React from 'react';

const CircleIcons = (props) => {
    const { type, size = 16 } = props;

    const renderIcon = () => {
        switch(type) {
            case 'greencircle':
                return (
                    <i className='fa fa-circle ' style={{fontSize:'12px',color:'#007500'}} aria-hidden="true"></i>

                );
            case 'redcircle':
                return (

                 <i className='fas fa-circle' style={{fontSize:'12px',color:'#FF0000'}} aria-hidden="true"></i>
                );
                case 'yellowcircle':
                    return(
                        <i className='fas fa-circle' style={{fontSize:'12px',color:'#FDA636'}} aria-hidden="true"></i>
                    );
                case 'bluecircle':
                    return (

                        <i className='fas fa-circle' style={{fontSize:'12px',color:'#017BFF'}} aria-hidden="true"></i>
                    );
                    case 'greycircle':
                        return (

                            <i className='fas fa-circle' style={{fontSize:'12px',color:'#848585'}} aria-hidden="true"></i>
                        );
 
        }
    };

    return (
        <>
            {renderIcon()}
        </>
    );
};

export default CircleIcons;
