
import { fetchTimeInterval,getCurrentDate, getCurrentTime, isSet, optionsToKeyValuePairs } from "../../../../../../utils/commonUtils";
import { ItentificationTypeOptions } from "../../../../../../utils/constant";

export const DrugScreenCollection = (props)=>{
    // console.log('props.lookupsOptions',props.lookupsOptions);
    const charts = [{
        type:'Grid',
        col:'col-lg-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },{
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        }],
    },{
        type:'SectionBased',
        col:'col-lg-9',
        data:[{
            label:'Testing Details',
            fields:[{
                label:'Arrival Date',
                type:'Date',
                name:'arrival_date',
                required:true,
            },
            {
                label:'Arrival Time',
                name:'arrival_time',
                type: "SelectSearchList",
                placeholder:'Time',
                isAsync:true,
                options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                required:true,
            },
            {
                label:'Alternate ID',
                type:'TextInput',
                name:'alternate_id',
            },
            {
                label:'',
                type:'Blank',
                name:'alternate_id',
            },
            {
                label:'Donor ID',
                type:'SelectList',
                options:ItentificationTypeOptions,
                name:'donor_id',
                required:true,
            },
            {
                label:'Donor ID Number',
                type:'TextInput',
                name:'donor_id_number',
                required:true,
            },
            {
                label:'State',
                type:'SelectList',
                options:props.stateList,
                name:'state',
                required:true,
            },{
                label:'DOT Agency',
                type:'SelectList',
                name:'dot_agency',
                id:'dot_agency',
                options:optionsToKeyValuePairs(['FMCSA','FAA','FRA','FTA','PHMSA','USCG']),
                required:true,
            },
            {
                label:'Collection Type',
                type:'SelectList',
                name:'collection_type',
                id:'collection_type',
                required:true,
                options:optionsToKeyValuePairs(['Split','Single','None Provided']),
                required:true,
            },
            {
                label:'Collection Remarks',
                type:'TextInput',
                rules:[{rule:[{field:'collection_type',value:'None Provided',operator:'==='}],action:'REQUIRED'}],
                name:'collection_remarks',
            },{
                label:'Drug Tests to be Performed',
                type:'RadioGroup',
                name:'drugs_tests',
                id:'drugs_tests',
                options:['Urine','Oral Fluid'],
                required:true,
            },{
                label:'Chain of Custody No',
                type:'TextInput',
                name:'chain_of_custody_no'
            },
            // {
            //     type:'Blank',
            //     name:'blank'
            // },
            {
                label:'Reason',
                col:'col-6',
                type:'SelectList',
                name:'reason',
                id:'reason',
                options:optionsToKeyValuePairs(['Pre-employment','Followup','Random','Post Accident','Return to Duty','Other','Reasonable Suspicion/Cause']),
                required:true,
            },
            {
                label:'Sample ID',
                type:'TextInput',
                name:'sample_id',
            },
            {
                type:'Blank',
                col:'col-6',
                name:'blank',
                rules:[{rule:[{field:'reason',value:'Other',operator:'!=='}],action:'SHOW'}]
            },
            {
                // label:'Specify Other Reason Time',
                isLabel:false,
                type:'TextArea',
                col:'col-6',
                name:'other_reason',
                placeholder:'Specify Other Reason Time',
                id:'other_reason',
                rules:[{rule:[{field:'reason',value:'Other',operator:'==='}],action:'SHOW'}],
                required:true,
            }]
        },
        {
            label:'Activity Details - '+props.activityName,
            fields:[{
                label:'Result Date',
                type:'Date',
                name:'result_date',
                required:true,
            },
            {
                label:'Result Time',
                type:'TextInput',
                name:'result_time',
                placeholder:'Time',
                type: "SelectSearchList",
                isAsync:true,
                options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                required:true,
            }
        ]
        },
        // {
        //     label:'Type of Screening',
        //     col:'col-6',
        //     fields:[{
        //         label:'Reason',
        //         type:'SelectList',
        //         name:'reason',
        //         id:'reason',
        //         options:optionsToKeyValuePairs(['Pre-employment','Followup','Random','Post Accident','Return to Duty','Other','Reasonable Suspicion/Cause']),
                
                
        //     },
        //     {
        //         type:'Blank',
        //         col:'col-6',
        //         name:'blank',
        //         rules:{rule:{'reason':{value:'Other',operator:'!=='}},action:'HIDE'},
        //     },
        //     {
        //         // label:'Specify Other Reason Time',
        //         isLabel:false,
        //         type:'TextArea',
        //         col:'col-6',
        //         name:'other_reason',
        //         placeholder:'Specify Other Reason Time',
        //         id:'other_reason',
        //         rules:{rule:{'reason':{value:'Other',operator:'==='}},action:'HIDE'},
        //     }
        // ]
        // },
        {
            label:'Signs of Adulteration',
            note:'specimen temperature must be read within 4 minutes of collection.',
            col:'col-12',
            fields:[{
                label:'Signs of adulteration include:',
                notes:['unusual color','unusual smell','foreign objects in specimen','unusual behavior by donor'],
                type:'YesOrNoTable',
                col:'col-8',
                defaultBtnLabel:'Set all to Normal',
                defaultValues:{new_adulteration_signs:0,sufficient_quantity:1,temperature_range:1},
                options:[{label:'Yes',value:1},{label:'No',value:0}],
                list:[
                    {label:'Any sign of adulteration?',name:'new_adulteration_signs'},
                    {label:'In temperature range?',name:'temperature_range'},
                    {label:'Is there a sufficient quantity?',name:'sufficient_quantity'},
                    {label:'In temperature range? (2nd read)?',name:'temperature_range_second_read'}],
            },
        ]
        },
        {
            label:'Shipping Information',
            col:'col-6',
            fields:[{
                label:'Shipping Service',
                type:'SelectList',
                options:isSet(props.lookupsOptions['shippingService'],[]),
                name:'shipping_service',
            },
            {
                label:'Shipping Billing No.',
                type:'TextInput',
                name:'shipping_billing_no',
            },
            
            {
                label:'Notes',
                type:'TextArea',
                name:'notes',
            },
            {
                label:'Status',
                type:'SelectList',
                // options:[],
                // options:isSet(props.lookupsOptions['chartResult'],[]),
                options: [
                    { label: "NOT STARTED", value: "Not Started" },
                    { label: "PENDING", value: "Pending" },
                    { label: "FAILED", value: "Failed" },
                    { label: "PASSED", value: "Passed" },                
                  ],
                name:'status',
            }
        ]
        },]
    }];
    const buttons = {
        label:true,
    }
    const defaultValue = {
        arrival_date:getCurrentDate(),
        arrival_time:getCurrentTime(),
        result_time:getCurrentTime(),
        result_date:getCurrentDate(),
        donor_id:isSet(props?.employeeDetails?.identification_type,''),
        donor_id_number:isSet(props?.employeeDetails?.identification_number,''),
        dot_agency:'FMCSA',
        collection_type:'Single',
        drugs_tests:'Urine',
        status:'Not Started',
        // new_adulteration_signs:0,
        // sufficient_quantity:0,
        // temperature_range:0,
        // temperature_range_second_read:0,
        // reason:'Pre-employment'
    };
    return {charts,defaultValue,buttons,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
   
    const adulteration = {new_adulteration_signs:0,sufficient_quantity:1,temperature_range:1};

    const adulterationEqual = Object.entries(adulteration).every(([key, value]) => formData[key] === value);
    let status = '';
    if(!isSet(key) 
    || key === 'new_adulteration_signs' 
    || key === 'temperature_range' 
    || key === 'sufficient_quantity' 
    || key === 'temperature_range_second_read'){
        if (adulterationEqual===true) {
            status = 'Passed';
        }else{
            status = 'Pending';
        }
    }else{
        status = formData.status;
    }
   
    return (key==='status')?{}:{'status':status};
}