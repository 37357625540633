import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PatientFormService from '../services/PatientFormService';

export const fetchNotificationStatus = createAsyncThunk('api/fetchNotificationStatus', async () => {
  const response = await await PatientFormService.getPatientFormStatus();
  return response.data; 
});

const NotificationSlice = createSlice({
  name: 'api',
  initialState: {
    data: {notification:{ pending: 0, completed: 0 }},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data.notification = action.payload;
      })
      .addCase(fetchNotificationStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const notificationStatus = (state) => state.notification.data;
// export const selectApiLoading = (state) => state.api.loading;
// export const selectApiError = (state) => state.api.error;

export default NotificationSlice.reducer;
