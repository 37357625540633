import { wOrgIdMiddleware } from '../utils/commonUtils';
import { CREATED, OK, UNPROCESSABLE_CONTENT } from '../utils/constant';
import http from './http';


const DivisionService = {

  saveDivision: wOrgIdMiddleware(async (url, data) => {
    try {
      const response = await http.POST(`${url}divisions`, data);
      if ((response.status === OK || response.status === CREATED)) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      if (error.status ===422 && error.data) {
        return error.data
      }
      console.error('Error fetching Division:', error);
      // throw error;
    }
  }),
  //get Division
  getDivision: wOrgIdMiddleware(async (url, query = {}) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}divisions?${queryString}`);
      // console.log('resposne', response);
      if (response.code === OK) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Division:', error);
    }
  }, true),
  //get Division by ID
  getDivisionById: wOrgIdMiddleware(async (url, id) => {
    try {
      const response = await http.GET(`${url}divisions/${id}`);
      if (response.code === OK) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Division:', error);
    }
  }, true),
  //update Division
  updateDivision: wOrgIdMiddleware(async (url, id, data) => {
    try {
      const response = await http.PUT(`${url}divisions/${id}`, data);
      return response;
    } catch (error) {
      if (error.status === UNPROCESSABLE_CONTENT && error.data) {
        return error.data
      }
      console.error('Error fetching Division:', error);
      throw error;
    }
  }),
  //delete Division
  deleteDivision: wOrgIdMiddleware(async (url,Id) => {
    try {
      const response = await http.DELETE(`${url}divisions/${Id}`);
      if (response.code === OK && response.data) {
        return response;
      }
    } catch (error) {
      console.error('Error fetching Division:', error);
    }
  },true),
  getDivisionByParentId: async (parent_id) => {
    try {
      const response = await http.GET(`divisions-by-parent/${parent_id}`);
      if (response.code === OK && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching Division:', error);
    }
  },
  getDivisionByCompanyId: async (company_id) => {
    try {
      const response = await http.GET(`divisions-by-company/${company_id}`);
      if (response.code === 200 && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching Division:', error);
    }
  }
};
export default DivisionService;