import React, { useEffect, useState } from "react";
import FormInputs from "../../../components/UI/FormInputs";
import EmployeeService from "../../../services/EmployeeService";
import { USDateTimeString, isObject, isSet } from "../../../utils/commonUtils";
import TableGrid from "../../../components/UI/TableGrid";
import WithRoutify from "../../../hoc/WithRoutify";
import SectionLoader from "../../../components/UI/SectionLoader";
import LookupService from "../../../services/LookupService";
import { DOCUMENT_TYPE } from "../../../utils/constant";

const Documents = (props) => {
    const [uploadedFile,    setUploadedFile] = useState(null);
    const [isLoading,setLoaded] = useState(false);
    const [documentsList, setDocumentsList] = useState({ tableHeaders: [], tableRows: { data: [] } });
    const [documentFormData,setDocumentFormData]=useState({})
    const [lookupData,setLookupData]=useState({});
    const [validation,setValidation]=useState({});

    const fetchAdditionalDocumentList = async () => {
        try {
            const additionalDocuments = isSet(props.formData.additional_documents, []);
            const tableData = additionalDocuments.map((item) => ({
                rowId: item.additional_document_id,
                data: {
                    name: item.snap_file_manager,
                    document_type:item.document_type,
                    updatedAt: USDateTimeString(item.snap_file_manager.updated_at),
                    action: [{
                        className: 'btn btn-icon btn-transparent-dark',
                        iconType: 'Remove',
                        acl: 'employee-delete',
                        title: 'Delete',
                        isDelete:true,
                        clickHandler: () => deleteHandler(item.additional_document_id),
                    }]
                }
            }));

            setDocumentsList({ tableHeaders: getTableHeaders(), tableRows: { data: tableData } });
        } catch (error) {
            console.error("Error fetching additional documents:", error);
        }
    }
    const fetchLookupsData = async () => {
        try {
            const [documentTypeData] = await Promise.all([
                LookupService.getLookupByKey(DOCUMENT_TYPE),
            ]);
            let documenTypeOptions = [{ label: '-None-', value: '' }];
            documentTypeData.forEach((item) => {
                documenTypeOptions.push({
                    label: item.value,
                    value: item.value
                });
            });
            console.log('document_type_data',documenTypeOptions);
            setLookupData({...lookupData,['document_type']:documenTypeOptions});
        } catch (e) {

        }
    }

    const getTableHeaders = () => [
        { label: 'File Name', key: 'name', type: 'FileType' },
        { label: 'Type', key: 'document_type', type: 'SelectList' },
        { label: 'Updated Date', key: 'updatedAt', type: '' },
        { label: 'Action', key: 'action', type: 'Actions' },
        // Add more columns as needed
    ];

    const submiHandler = async () => {
        
        try {
            const formData = new FormData();
            formData.append('file', uploadedFile);
            formData.append('employee_id', props.employeeId);
            formData.append('document_type',documentFormData.document_type);
            setLoaded(true);
            const response=await EmployeeService.addAdditionalDocument(formData);
            setLoaded(false);
            let updateValidation={...validation};
            if (response.status && response.status == "ERROR") {
                if (isObject(response.data)) {
                  for (let key in response.data) {
                    updateValidation = {
                      ...updateValidation,
                      [key]: response.data[key].join(","),
                    };
                  }
                } else {
                  props.Toast.error(response.data);
                }
                if (Object.keys(updateValidation).length > 0) {
                  setValidation(updateValidation);
                  return;
                }
              }
            props.fetchEmployeeDetails(props.employeeId);
            setUploadedFile(null);
            setDocumentFormData({});
        } catch (error) {
            console.error("Error submitting document:", error);
            setLoaded(false);
        }
    }
    const inputFields = [
        {
            type: 'SelectList',
            options:lookupData.document_type ||[],
            label: 'Document Type',
            className: 'form-control form-control-solid',
            name: 'document_type'
        },
    ];

    const deleteHandler = async (additionalDocId) => {
        
            setLoaded(true);
            try {
                await EmployeeService.deleteAdditionalDocument(additionalDocId);
                props.Toast.success('Document Removed successfully');
                props.fetchEmployeeDetails(props.employeeId);
                setLoaded(false);
            } catch (error) {
                setLoaded(false);
                console.error("Error deleting document:", error);
            }
    }

    useEffect(() => {
        fetchAdditionalDocumentList();
    }, [props.formData.additional_documents]);
    useEffect(()=>{
        fetchLookupsData();
    },[]);

    const changeHandler=(val,fieldName)=>{
    setDocumentFormData(prevState => ({
              ...prevState.labformData, [fieldName]: val }
        ));
        setValidation({...validation,[fieldName]:''});
    }

    return (
        <div className="sh_innerPortview">
            <div className="row">
                <div className="col-12 col-lg-4">
                <div className="sh_cardBox h-auto shadow-none border">
                    <div className="sh_cardBody p-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                <label className="text-sm text-truncate mb-0 opacity-75">{"Document"}</label>
                                    <FormInputs
                                        type='FileUpload'
                                        title="Field Document"
                                        className=''
                                        value={uploadedFile||''}
                                        changeHandler={(val) => { 
                                            setUploadedFile(val);
                                            setValidation(prevValidation => ({ ...prevValidation, file: '' }));
                                          }}
                                    />
                                        {isSet(validation['file'], "") !== "" ? (<span className="text-danger m-1"> {validation['file']}</span>)
                                            :
                                            ""
                                        }
                                </div>
                            </div>
                            {inputFields.map((item, index) => (
                                    <div className="col-12" key={index}>
                                        <div className="form-group">
                                            <label className="text-sm text-truncate mb-0 opacity-75">{item.label}</label>
                                            <FormInputs
                                                {...item}
                                                value={isSet(documentFormData[item.name], '')}
                                                changeHandler={(val) => changeHandler(val, item.name)}
                                            />
                                           {isSet(validation[item.name], "") !== "" ? (<span className="text-danger m-1"> {validation[item.name]}</span>)
                                             :
                                            ""
                                            }
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="sh_cardFooter justify-content-center">
                        <button className="btn btn-primary btn-sm text-sm" onClick={submiHandler}> Add </button>
                    </div>
                </div>
                </div>
                <div className="col-12 col-lg-8">
                
                <div className="sh_cardBox h-auto shadow-none border">
                    <div className="sh_cardHeader">
                        <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                            <h6 className="text-md mb-0">Document List </h6>
                        </div>
                    </div>
                    <div className="sh_cardBody p-0">
                        <SectionLoader isLoading={isLoading}/>
                        <TableGrid {...documentsList} />
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default WithRoutify(Documents);
