import React  from 'react';
import PropTypes from 'prop-types';
// import {setAndGetAttributes} from './FormInputFunctions';

const DecimalInput = (props)=>{

   const {
    className,
    placeholder,
    value,
    style,
    name,
    readOnly,
    required,
    disabled
  } = props;

  // Handler
   const {
    changeHandler
  } = props;

    const onChangeHandler = (e)=>{
      let val = e.target.value;
      val = val.replace(/[^\d.]/g, '');  // Replace non-numeric characters with empty string
      changeHandler(val,{e});       
    } 
    return(
        <input 
          className={className}
          name={name}
          type={'text'}
          placeholder={placeholder}
          value={value}
          style={style}
          readOnly={readOnly}
          required={required}
          disabled={disabled}
          onChange={(e)=>onChangeHandler(e)}
          />    
    )
}
DecimalInput.defaultProps = {
  className: 'form-control',
  placeholder:'',
  required:false,
  readOnly:false,
  disabled:false,
  value:'',
  style:{},
  validation:{
    isValid:true,message:''
  },

}
DecimalInput.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  changeHandler: PropTypes.func.isRequired,
};

export default DecimalInput;