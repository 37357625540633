import React, { useState, useCallback, useEffect,useRef } from "react";
import { SortableContainer } from "react-sortable-hoc";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
// import Wrap from "../../../hoc/Wrap";
import Pagination from "./Pagination";
import {createPortal} from 'react-dom';
import { isSet } from "../../../utils/commonUtils";
import HeaderButtons from "../HeaderButtons";

const TableGrid = (props) => {
  const [tableFooter,setTableFooter] = useState(null);
  
  // Check if the target container exists and is a valid DOM element
  const tableRef =  useRef(null);
  useEffect(()=>{
    if (tableRef.current&&tableFooter==null) {
      const paginationTargetContainer = tableRef.current.closest('.sh_cardBox');
      setTableFooter(paginationTargetContainer);
    }
  },[])
  return (
    <>
      {/* <HocComponent
        axis="y"
        lockAxis="y"
        lockToContainerEdges={true}
        lockOffset={["30%", "50%"]}
        helperClass="helperContainerClass"
        useDragHandle={true}> */}
      
      <TableGridHOC hasHeaderAction={props.hasHeaderAction}>
        {props.hasHeaderAction===true && 
        <HeaderButtons
          {...props.headersProps} />   
        }
            
      <table
        {...props.tableHandler}
        className="table dataTable table-hover nowrap my-0  "
        width="100%"
        cellSpacing="0"
        ref={tableRef}
        style={{ width: "100%" }}
      >
        <TableHeader {...props} />
        <TableBody {...props} />
      </table>
      
      {props.hasPagination == true ? (
        isSet(tableFooter, null) !== null ? (
          <div className="">
            {createPortal(<Pagination tableRef={tableRef}{...props} />, tableFooter)}
          </div>
        ) : (
          // Handle the case where the target container is not found
          <></>
        )
      ) : (
        ""
      )}
      </TableGridHOC>
      
    </>
  );
};
TableGrid.defaultProps = {
    hasHeaderAction:false,
    className:'table',
    width:'100%',
    style:{},
    additionalAttr:{},
    tableHandler:{}
}
const TableGridHOC = ({hasHeaderAction,children})=>{
  if(hasHeaderAction===false){ return children; }
 return(
  <div className="sh_cardBody p-0">
    {children}
  </div>
 )
}
const SortableCont = SortableContainer(({ children }) => {
    return children;
  });
export default TableGrid;
