import React from "react";
import { capitalizeFirstLetter, isArray } from "../../../utils/commonUtils";
import { hideToast } from "../../../store/CommonSlice";
import { useDispatch, useSelector } from 'react-redux';

const types = {
    error:'danger',
    success:'success',
    warning:'warning'
}

const Toast = ()=>{
    const dispatch = useDispatch();
    const toasts = useSelector(state=>state.common.toasts);
    return(
        <div style={{position:'absolute',top:'1rem',right:'1rem',zIndex:'9999'}}>
            {toasts.map((toast) => (
                <div key={toast.id} className={`toast ${types[toast.type]}`} role="alert" aria-live="assertive" aria-atomic="true" style={{ opacity: 1,minWidth:'350px' }}>
                <div className={`toast-header text-${types[toast.type]}`}>
                    <i data-feather="bell"></i>
                    <strong className="mr-auto">{capitalizeFirstLetter(toast.type)} </strong>
                    {/* <small class="text-muted ml-2">just now</small> */}
                    <button className="ml-2 mb-1 btn-close" type="button" onClick={() => dispatch(hideToast(toast.id))} aria-label="Close"></button>
                </div>
                    {
                        isArray(toast.message) ?
                            toast.message.map((msg, index) => {
                                return (
                                    <>
                                        <div className="toast-body" key={index}>{msg}</div>
                                    </>
                                )
                            })
                            :
                            <div className="toast-body">{toast.message}</div>
                    }
                
            </div>
            ))}
    </div>
    )
}

export default Toast;