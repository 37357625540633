import ProviderService from "../../../../../../services/ProviderService";
import {  isSet, optionsToKeyValuePairs } from "../../../../../../utils/commonUtils";
import { OnTabKeyPress } from "../../../../../../utils/cssUtils";


export const NonDOTExamHearingAudio = (props,type='Others')=>{

    const physicalExamination = [
        {label:'General',name:'physical_examination_general'},
        {label:'Skin',name:'physical_examination_skin'},
        {label:'Eyes',name:'physical_examination_eyes'},
        {label:'Ears',name:'physical_examination_ears'},
        {label:'Mouth / throat',name:'physical_examination_mouth_throat'},
        {label:'Cardiovascular',name:'physical_examination_cardiovascular'},
        {label:'Lungs / chest',name:'physical_examination_lungs_chest'},
        {label:'Abdomen',name:'physical_examination_abdomen'},
        {label:'Genito urinary sys / hernias',name:'physical_examination_genito_urinary'},
        {label:'Back / Spine',name:'physical_examination_back_spine'},
        {label:'Extremities / joints',name:'physical_examination_extremities_joints'},
        {label:'Neurological sys / reflexes',name:'physical_examination_neurological_sys_reflexes'},
        {label:'Gait',name:'physical_examination_gait'},
        {label:'Vascular system',name:'physical_examination_vascular_system'}];
        let patientHealthInformation = [];
        if(type=='ProviderExam'){
            patientHealthInformation = [
                {label:'1. Head/brain injuries (e.g., concussion, seizures)',name:'head_brain_injuries'},
                {label:'2. Seizures, epilepsy',name:'seizures_epilepsy'},
                {label:'3. Eye problems (except glasses or contacts)',name:'eye_problems'},
                {label:'4. Ear and/or hearing problems',name:'ear_hearing_problems'},
                {label:'5. Heart disease, heart attack, bypass, or other',name:'heart_disease'},
                {label:'6. Pacemaker,stents, implantable devices, or other heart procedures',name:'pacemaker_stents_implantable_devices'},
                {label:'7. High blood pressure',name:'high_blood_pressure'},
                {label:'8. High cholesterol',name:'high_cholesterol'},
                {label:'9. Chronic (long-term) cough, shortness of breath, or other breathing problems',name:'chronic_breathing_problems'},
                {label:'10. Lung disease (e.g., asthma)',name:'lung_disease'},
                {label:'11. Kidney problems, kidney stones, or pain w/ urination',name:'kidney_problems'},
                {label:'12. Stomach, liver, or digestive problems',name:'digestive_problems'},
                {label:'13. Diabetes or blood sugar problems Insulin used',name:'diabetes_insulin'},
                {label:'14. Anxiety, depression, other mental problems',name:'mental_problems'},
                {label:'15. Fainting or passing out',name:'fainting_passing_out'},
                {label:'16. Dizziness, headaches, numbness, tingling',name:'dizziness_headaches'},
                {label:'17. Unexplained weight loss',name:'unexplained_weight_loss'},
                {label:'18. Stroke, mini-stroke (TIA), paralysis, or weakness',name:'stroke_paralysis_weakness'},
                {label:'19. Missing or limited use of arm, hand, finger, leg, foot, toe',name:'missing_limited_use_of_limbs'},
                {label:'20. Neck or back problems',name:'neck_back_problems'},
                {label:'21. Bone, muscle, joint, or nerve problems',name:'bone_muscle_joint_nerve_problems'},
                {label:'22. Blood clots or bleeding problems',name:'blood_clots_bleeding'},
                {label:'23. Cancer',name:'cancer'},
                {label:'24. Chronic (long-term) infection or other',name:'chronic_infection'},
                {label:'25. Sleep disorders, pauses in breathing while asleep, daytime sleepiness, loud snoring',name:'sleep_disorders'},
                {label:'26. Have you ever had a sleep test (e.g., sleep apnea)?',name:'had_sleep_test'},
                {label:'27. Have you ever spent a night in the hospital?',name:'hospital_stay'},
                {label:'28. Have you ever had a broken bone?',name:'had_broken_bone'},
                {label:'29. Have you ever used or do you now use tobacco?',name:'tobacco_use'},
                {label:'30. Do you currently drink alcohol?',name:'alcohol_use'},
                {label:'31. Use of an illegal substance within the past 2 years',name:'illegal_substance_use'},
                {label:'32. Have you ever failed a drug test or been dependent?',name:'drug_test_failure'}
            ]; 
        }else{
            patientHealthInformation = [
                {label:'1. Head/brain injuries (e.g., concussion, seizures)',name:'head_brain_injuries'},
                {label:'2. Eye problems (except glasses or contacts)',name:'eye_problems'},
                {label:'3. Ear and/or hearing problems',name:'ear_hearing_problems'},
                {label:'4. Heart disease, heart attack, bypass, or other',name:'heart_disease'},
                {label:'5. High blood pressure',name:'high_blood_pressure'},
                {label:'6. High cholesterol',name:'high_cholesterol'},
                {label:'7. Lung disease (e.g., asthma)',name:'lung_disease'},
                {label:'8. Kidney problems, kidney stones, or pain w/ urination',name:'kidney_problems'},
                {label:'9. Stomach, liver, or digestive problems',name:'digestive_problems'},
                {label:'10. Diabetes or blood sugar problems Insulin used',name:'diabetes_insulin'},
                {label:'11. Anxiety, depression, other mental problems',name:'mental_problems'},
                {label:'12. Fainting or passing out',name:'fainting_passing_out'},
                {label:'13. Dizziness, headaches, numbness, tingling',name:'dizziness_headaches'},
                {label:'14. Unexplained weight loss',name:'unexplained_weight_loss'},
                {label:'15. Stroke, mini-stroke (TIA), paralysis, or weakness',name:'stroke_paralysis_weakness'},
                {label:'16. Neck or back problems',name:'neck_back_problems'},
                {label:'17. Bone, muscle, joint, or nerve problems',name:'bone_muscle_joint_nerve_problems'},
                {label:'18. Blood clots or bleeding problems',name:'blood_clots_bleeding'},
                {label:'19. Cancer',name:'cancer'},
                {label:'20. Chronic (long-term) infection or other',name:'chronic_infection'},
                {label:'21. Have you ever spent a night in the hospital?',name:'hospital_stay'},
                {label:'22. Have you ever used or do you now use tobacco?',name:'tobacco_use'},
                {label:'23. Do you currently drink alcohol?',name:'alcohol_use'},
                {label:'24. Use of an illegal substance within the past 2 years',name:'illegal_substance_use'},
                {label:'25. Have you ever failed a drug test or been dependent?',name:'drug_test_failure'}
            ]; 
        }
      
    const setAllDefaultValues = {
        accompanied_by_waiver_exemption:0,
        accompanied_by_spe_certificate:0,
        driving_within_an_exempt_intracity_zone:0,
        determination_pending:0,
        return_to_medical_exam_office_for_follow_up:0,
        medical_examination_report_amended:0,
        incomplete_examination:0
    };
   
    const charts = [{
        type:'Grid',
        col:'col-md-3',
        data:[{
            type:"PatientDetails",
            employeeDetails:props.employeeDetails,
            visitDetails:props.visitDetails
        },
        {
            type:"ResultSummary",
            // formData:props.formData,
            results:[
                {
                normalMsg:'Provider Section',
                abnormalMsg:'Provider Section',
                data:[
                    {label:'Pulse',keyVal:'pulse_rate_result'},
                    {label:'Blood Pressure',keyVal:'blood_pressure_result'},
                    {label:'Vision',keyVal:'vission_result'},
                    {label:'Hearing',keyVal:'hearing_result'},
                    {label:'Urinalysis',keyVal:'urinalysis_result'}
                ],
            },
            {
                normalMsg:'Patient Section',
                abnormalMsg:'Patient Section',
                label:'Patient',
                data:[
                    {label:'Surgeries',keyVal:'surgery_history',type:'yesorno'},
                    {label:'Medications',keyVal:'taking_medications',type:'yesorno'},
                    {label:'Health History',keyVal:patientHealthInformation.map(item => item.name),type:'ANY'},
                    
                ],
            }]
            
        },
        {
            type:"CompanyDetails",
            companyDetails:props.companyDetails
        },
    ],
    },
    {
        type:'Tab',
        col:'col-md-9',
        data:[
            {
            id:'provider',
            data:  [
                
                {
                    label:'Activity Details - Health Information Review',
                    note:(type==='ProviderExam')?'Examination Report (to be filled out by the medical examiner)':'Examination Report (to be filled out by the Provider)',
                    fields:[
                        (type==='ProviderExam')?{
                        label:"Review and discuss pertinent driver answers and any available medical records. Comment on the driver's responses to the 'health history' questions that may affect the driver's safe operation of a commercial motor vehicle (CMV)",
                        type:'TextArea',
                        labelWrap:false,
                        name:'health_information_review',
                        col:'col-12',
                        id:'health_information_review',
                    }:{
                        label:'Review and discuss Employee/patient answers and any available medical records. Comment on the responses to the "health history" questions that may affect the ability of the employee to perform their job functions. ',
                        labelWrap:false,
                        type:'TextArea',
                        name:'health_information_review',
                        col:'col-12',
                        id:'health_information_review',
                    }]
                },
                {
                    label:'Pulse Rate & Height',
                    fields:[{
                        label:'Pulse Rate',
                        type:'Number',
                        name:'pulse_rate',
                        rangeMin:60,
                        rangeMax:100,
                        id:'pulse_rate'
                    },
                    {
                        label:'Pulse Rhythm Regular',
                        type:'RadioGroup',
                        name:'pulse_rhythm_regular',
                        id:'pulse_rhythm_regular',
                        options:[{label:'Yes',value:1},{label:'No',value:0}],
                    },
                    {
                        label:'Height',
                        placeholder:'Feet',
                        type:'Number',
                        name:'height_feet',
                        id:'height_feet',
                        
                    },
                    {
                        label:'',
                        isLabel:false,
                        placeholder:'Inches',
                        type:'Decimal',
                        name:'height_inches',
                        id:'height_inches',
                        
                    },
                    {
                        label:'Weight (In Pounds)',
                        placeholder:'Pounds',
                        type:'Number',
                        name:'weight_pounds',
                        id:'weight_pounds',
                        
                    },
                    {
                        label:'Pulse Rate Status',
                        type:'TextInput',
                        name:'pulse_rate_result',
                        id:'pulse_rate_result',
                        disabled:true,                        
                    },
                   
                ]
                },
                { 
                    label:' Blood Pressure Reading (Systolic / Diastolic)',
                    fields:[
                        {
                            label:'Systolic',
                            col:'col-2',
                            type:'Label',
                        },
                        {
                        label:' Sitting',
                        col:'col-4',
                        rangeMax:139,
                        type:'Number',
                        name:'sitting_systolic',
                        id:'sitting_systolic',
                        onKeyPress:(event)=>OnTabKeyPress(event,"sitting_diastolic",""),
                    },
                    {
                        label:'Second reading (optional)',
                        col:'col-6',
                        rangeMax:139,
                        type:'Number',
                        name:'second_reading_systolic',
                        id:'second_reading_systolic',
                    },
                    {
                        label:'Diastolic',
                        col:'col-2',
                        type:'Label',
                    },
                    {
                        label:' Sitting',
                        col:'col-4',
                        type:'Number',
                        rangeMax:89,
                        name:'sitting_diastolic',
                        id:'sitting_diastolic',
                        onKeyPress:(event)=>OnTabKeyPress(event,"other_testing_if_indicated","sitting_systolic"),
                    },
                    {
                        label:'Second reading (optional)',
                        col:'col-6',
                        type:'Number',
                        rangeMax:89,
                        name:'second_reading_diastolic',
                        id:'second_reading_diastolic',
                    },
                    {
                        label:'Other testing if indicated',
                        type:'TextArea',
                        name:'other_testing_if_indicated',
                        id:'other_testing_if_indicated',
                        onKeyPress:(event)=>OnTabKeyPress(event,"","sitting_diastolic"),
                    },
                    {
                        label:'Blood Pressure Status',
                        type:'TextInput',
                        disabled:true,   
                        id:'blood_pressure_result',
                        name:'blood_pressure_result'
                    },
                    ...(type==='ProviderExam')?[{
                        label:'Suggested Length of Certification',
                        type:'TextInput',
                        disabled:true,
                        col:'col-12',
                        id:'certification_suggested_length',
                        name:'certification_suggested_length'
                    }]:[]
                    
                ]
                },
                {
                    label:'Urinalysis',
                    note:'Urinalysis is required.  Numerical readings must be recorded.',
                    fields:[
                        {
                            label:'Protein, blood, or sugar in the urine may be an indication for further testing to rule out any underlying medical problem.',
                            type:'Label',
                            col:'col-12'                
                        },
                        {
                            label:'Sp. Gr',
                            type:'Decimal',
                            name:'sp_gr',
                            rangeMax:1.030,
                            rangeMin:1.002,
                            id:'sp_gr',
                        },
                        {
                            label:'Protein',
                            type:'Number',
                            rangeMax:0,
                            name:'protein',
                            id:'protein',
                        },
                        {
                            label:'Blood',
                            type:'Number',
                            rangeMax:0,
                            name:'blood',
                            id:'blood',
                        },
                        {
                            label:'Sugar',
                            type:'Number',
                            rangeMax:0,
                            name:'sugar',
                            id:'sugar',
                        },
                        {
                            label:'',
                            type:'Blank',
                            name:'blank'
                        },
                        {
                            label:'Urinalysis Result',
                            type:'TextInput',
                            name:'urinalysis_result',
                            id:'urinalysis_result',
                            disabled:true,
                        }
                ]
                },
                {
                    label:'Vision',
                    fields:[
                        {
                            label:"Standard is below 20/40 acuity (Snellen) in each eye with or without correction. At least 70° field of vision in horizontal meridian measured in each eye. The use of corrective lenses should be noted on the Medical Examiner's Certificate",
                            type:'Label'
                        },
                        {
                            label:'Wearing corrective lenses',
                            col:'col-12',
                            type:'RadioGroup',
                            name:'wearing_corrective_lenses',
                            id:'wearing_corrective_lenses',
                            options:[{label:'Yes',value:1},{label:'No',value:0}],
                        },
                        {
                            label:'Uncorrected',
                            type:'Label',
                            // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                            appendClass:'bg-light',
                            col:'col-12',
                        },
                        {
                            label:'Right Eye',
                            type:'TextInput',
                            rangeMax:40,
                            // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                            isNumeric:true,
                            col:'col-6',
                            name:'uncorrected_right_eye',
                            id:'uncorrected_right_eye',
                        },
                        {
                            label:'Left Eye:',
                            type:'TextInput',
                            // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                            isNumeric:true,
                            rangeMax:40,
                            col:'col-6',
                            name:'uncorrected_left_eye',
                            id:'uncorrected_left_eye',
                        },
                        {
                            label:'Both Eyes:',
                            type:'TextInput',
                            // rules:[{rule:[{field:'wearing_corrective_lenses',value:0,operator:'==='}],action:'SHOW'}],
                            isNumeric:true,
                            col:'col-6',
                            rangeMax:40,
                            name:'uncorrected_both_eyes',
                            id:'uncorrected_both_eyes',
                        },
            
                        {
                            label:'Corrected',
                            type:'Label',
                            appendClass:'bg-light',
                            // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                            col:'col-12',
                        },
                        {
                            label:'Right Eye',
                            type:'TextInput',
                            col:'col-6',
                            isNumeric:true,
                            rangeMax:40,
                            // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                            name:'corrected_right_eye',
                            id:'corrected_right_eye',
                        },
                        {
                            label:'Left Eye:',
                            type:'TextInput',
                            col:'col-6',
                            // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                            isNumeric:true,
                            rangeMax:40,
                            name:'corrected_left_eye',
                            id:'corrected_left_eye',
                        },
                        {
                            label:'Both Eyes:',
                            type:'TextInput',
                            // rules:[{rule:[{field:'wearing_corrective_lenses',value:1,operator:'==='}],action:'SHOW'}],
                            isNumeric:true,
                            rangeMax:40,
                            col:'col-6',
                            name:'corrected_both_eyes',
                            id:'corrected_both_eyes',
                        },
                        {
                            label:'Horizontal Field of Vision',
                            type:'Label',
                            appendClass:'bg-light',
                            col:'col-12',
                        },
                        {
                            label:'Right Eye: (degrees)',
                            type:'TextInput',
                            rangeMin:70,
                            col:'col-6',
                            name:'horizontal_field_of_vision_right_eye',
                            id:'horizontal_field_of_vision_right_eye',
                        },
                        {
                            label:'Left Eye: (degrees)',
                            type:'TextInput',
                            col:'col-6',
                            rangeMin:70,
                            name:'horizontal_field_of_vision_left_eye',
                            id:'horizontal_field_of_vision_left_eye',
                        },
                        ...(type==='ProviderExam')?
                            [
                            {
                                label:"Applicant can recognize and distinguish among traffic control signals and devices showing red, green, and amber colors Monocular vision",
                                type:'RadioGroup',
                                labelWrap:false,
                                failedValue:['No'],
                                name:'applicant_recognize_traffic_control_signals',
                                rangeMin:70,
                                col:'col-12',
                                id:'applicant_recognize_traffic_control_signals',
                                options:['Yes','No','Non-DOT Physical'],
                            },
                            {
                                label:"Monocular vision",
                                type:'RadioGroup',
                                failedValue:['No'],
                                name:'monocular_vision',
                                rangeMin:70,
                                col:'col-12',
                                id:'monocular_vision',
                                options:['Yes','No','Non-DOT Physical'],
                            }]:
                        [],
                        {
                            label:'Referred to ophthalmologist or optometrist?',
                            type:'RadioGroup',
                            name:'referred_to_ophthalmologist',
                            rangeMin:70,
                            col:'col-12',
                            id:'referred_to_ophthalmologist',
                            options:[{label:'Yes',value:1},{label:'No',value:0}],
                        },
                        {
                            label:'Received documentation from ophthalmologist or optometrist?',
                            type:'RadioGroup',
                            name:'received_documentation_from_ophthalmologist',
                            col:'col-12',
                            id:'received_documentation_from_ophthalmologist',
                            options:[{label:'Yes',value:1},{label:'No',value:0}],
                        },
                        {
                            label:'',
                            type:'Blank',
                            name:'blank'
                        },
                        {
                            label:'Vision Status',
                            type:'TextInput',
                            name:'vission_result',
                            id:'vission_result',
                            disabled:true,                        
                        }
                ]
                },
                {
                    label:'Hearing',
                    fields:[
                        {
                            label:'Standard: Equal to 40 dB, in better ear (with or without hearing aid).',
                            type:'Label',
                            col:'col-12'                
                        },
                    {
                        label:'Check if hearing aid used for test',
                        col:'col-12',
                        type:'RadioGroup',
                        name:'check_hearing_aid_used',
                        id:'check_hearing_aid_used',
                        options:['Right Ear','Left Ear','Neither'],
                    },
                    {
                        label:'Whisper Test Results',
                        type:'Label',
                        appendClass:'bg-light',
                        col:'col-12',
                    },
                    {
                        label:'Right Ear',
                        type:'Number',
                        rangeMin:5,
                        name:'whisper_test_results_right_ear',
                        id:'whisper_test_results_right_ear',
                    },
                    {
                        label:'Left Ear',
                        type:'Number',
                        rangeMin:5,
                        name:'whisper_test_results_left_ear',
                        id:'whisper_test_results_left_ear',
                    },
                    {
                        label:'OR: Audiometric Test Results - Right Ear',
                        type:'Label',
                        appendClass:'bg-light',
                        col:'col-12',
                    },
                   
                    {
                        label:'500 Hz',
                        type:'Number',
                        rangeMax:40,
                        col:'col-6',
                        name:'audiometric_test_results_right_ear_500_hz',
                        id:'audiometric_test_results_right_ear_500_hz',
                    },
                    {
                        label:'1000 Hz',
                        type:'Number',
                        col:'col-6',
                        rangeMax:40,
                        name:'audiometric_test_results_right_ear_1000_hz',
                        id:'audiometric_test_results_right_ear_1000_hz',
                    },
                    {
                        label:'2000 Hz',
                        type:'Number',
                        rangeMax:40,
                        col:'col-6',
                        name:'audiometric_test_results_right_ear_2000_hz',
                        id:'audiometric_test_results_right_ear_2000_hz',
                    },
                    {
                        label:'Average',
                        type:'Number',
                        readOnly:true,
                        col:'col-6',
                        name:'audiometric_test_results_right_ear_average',
                        id:'audiometric_test_results_right_ear_average',
                    },
                    {
                        label:'OR: Audiometric Test Results - Left Ear',
                        appendClass:'bg-light',
                        type:'Label',
                        col:'col-12',
                    },
                    {
                        label:'500 Hz',
                        type:'Number',
                        col:'col-6',
                        rangeMax:40,
                        name:'audiometric_test_results_left_ear_500_hz',
                        id:'audiometric_test_results_left_ear_500_hz',
                    },
                    {
                        label:'1000 Hz',
                        type:'Number',
                        rangeMax:40,
                        col:'col-6',
                        name:'audiometric_test_results_left_ear_1000_hz',
                        id:'audiometric_test_results_left_ear_1000_hz',
                    },
                    {
                        label:'2000 Hz',
                        type:'Number',
                        rangeMax:40,
                        col:'col-6',
                        name:'audiometric_test_results_left_ear_2000_hz',
                        id:'audiometric_test_results_left_ear_2000_hz',
                    },
                    {
                        label:'Average',
                        type:'Number',
                        readOnly:true,
                        col:'col-6',
                        name:'audiometric_test_results_left_ear_average',
                        id:'audiometric_test_results_left_ear_average',
                    },
                    {
                        label:'',
                        type:'Blank',
                        name:'blank'
                    },
                    {
                        label:'Hearing Status',
                        type:'TextInput',
                        name:'hearing_result',
                        id:'hearing_result',
                        disabled:true,                        
                    },
                    
                ]
                },
                {
                    label:'Physical Examination',
                    note:'Check the body systems for abnormalities',
                    fields:[{
                        label:'The presence of a certain condition may not necessarily disqualify an employee from the job, particularly if the condition is controlled adequately, is not likely to worsen, or is readily amenable to treatment. Even if a condition does not disqualify an employee, the Provider may consider deferring employment temporarily. Also, the employee should be advised to take the necessary steps to correct the condition as soon as possible, particularly if neglecting the condition could result in a more serious illness that might affect employment.',
                        type:'Label'
                    }, {
                        col:'col-12',
                        label:'',
                        defaultBtnLabel:'Set all to Normal',
                        type:'YesOrNoTable',
                        options:[{label:'Normal',value:'Normal'},{label:'Abnormal',value:'Abnormal'}],
                        defaultValues:physicalExamination.reduce((acc, itm) => {
                            acc[itm.name] = 'Normal';
                            return acc;
                        }, {}),
                        list:physicalExamination,
                        
                    },{
                        label:"Discuss any abnormal answers in detail in the space below and indicate whether it would affect the employee to perform their job duties.",
                        type:'TextArea',
                        labelWrap:false,
                        col:'col-12',
                        name:'physical_examination_comments',
                        id:'physical_examination_comments',
                    },
                    ...(type==='ProviderExam')?[
                        {
                                label:"Medical Examiner Determination",
                                // isLabel:false,
                                type:'SelectList',
                                name:'medical_examiner_determination',
                                col:'col-12',
                                id:'medical_examiner_determination',
                                options:optionsToKeyValuePairs(['Federal','State','Non-DOT']),
                        }]:[]]
                },
                
                ...(type==='ProviderExam')?[
                    {
                        label:'Additional Details for Federal/State Determination',
                        col:'col-6',
                        rules:[{ rule:[
                            {field:'medical_examiner_determination',value:"Federal",operator:'===',logical:'OR'},
                            {field:'medical_examiner_determination',value:"State",operator:'==='}
                          ], action:'SHOW'}],
                        fields:[
                            {
                                label:'Use this section for examinations performed in accordance with the Federal Motor Carrier Safety Regulations (49 CFR 391.41-391.49)',
                                type:'SelectList',
                                options:optionsToKeyValuePairs(["Does not meet standards","Meets standards in 49 CFR 391.41; qualifies for 2-year certificate","Meets standards, but periodic monitoring required", "Determination pending", "Incomplete examination"]),
                                name:'examinations_performed',
                                id:'examinations_performed',
                                rules:[{ rule:[
                                    {field:'medical_examiner_determination',value:"Federal",operator:'==='}
                                  ], action:'SHOW'}],
                            }, 
                            {
                                label:'Use this section for examinations performed in accordance with the Federal Motor Carrier Safety Regulations (49 CFR 391.41-391.49) with any applicable State variances (which will only be valid for intrastate operations)',
                                type:'SelectList',
                                options:optionsToKeyValuePairs(["Does not meet standards in 49 CFR 391.41 with any applicable State variances","Meets standards in 49 CFR 391.41 with any applicable State variances","Meets standards, but periodic monitoring required"]),
                                name:'examinations_performed',
                                id:'examinations_performed',
                                rules:[{ rule:[
                                    {field:'medical_examiner_determination',value:"State",operator:'==='}
                                  ], action:'SHOW'}],
                            }, 
                            {
                                label:'',
                                isLabel:false,
                                type:'TextArea',
                                placeholder:'Specify reason',
                                disabled:true,
                                rules:[{ rule:[
                                          {field:'examinations_performed',value:"Does not meet standards",operator:'===',logical:'OR'},
                                          {field:'examinations_performed',value:"Meets standards, but periodic monitoring required",operator:'===',logical:'OR'},
                                          {field:'examinations_performed',value:"Determination pending",operator:'===',logical:'OR'},
                                          {field:'examinations_performed',value:"Incomplete examination",operator:'===',logical:'OR'},
                                          {field:'examinations_performed',value:"Does not meet standards in 49 CFR 391.41 with any applicable State variances",operator:'==='} 
                                        ], action:'ENABLE'}],
                                name:'reason_for_examinations_performed'
                            },
                            {
                                label:'Driver qualified for',
                                type:'SelectList',
                                options:optionsToKeyValuePairs(["3 months","6 months","1 year","Other"]),
                                name:'driver_qualified'
                            },
                            {
                                label:'',
                                isLabel:false,
                                type:'TextArea',
                                disabled:true,
                                placeholder:'Specify if other',
                                rules:[{rule:[{field:'driver_qualified',value:"Other",operator:'==='}],action:'ENABLE'}],
                                name:'driver_qualified_other_reason'
                            },
                            // {
                            //     label:"Wearing corrective lenses",
                            //     type:'RadioGroup',
                            //     name:'wearing_corrective_lenses',
                            //     id:'wearing_corrective_lenses',
                            //     options:[{label:'Yes',value:1},{label:'No',value:0}],
                            // },
                            {
                                label:"Wearing hearing aid",
                                type:'RadioGroup',
                                name:'wearing_hearing_aid',
                                id:'wearing_hearing_aid',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                type:'Blank',
                                name:'blank'
                            },
                            {
                                type:'Label',
                                label:<a onClick={()=>props.updateFormData({...setAllDefaultValues,...computationRules({...props.formData,...setAllDefaultValues})}) } className="badge bg-blue-soft text-light cursor-pointer custom-btn cursor_pointer">Set all to No</a>
                            },  
                            {
                                label:"Accompanied by a waiver/exemption",
                                type:'RadioGroup',
                                name:'accompanied_by_waiver_exemption',
                                id:'accompanied_by_waiver_exemption',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                label:'Type',
                                type:'TextInput',
                                disabled:true,
                                rules:[{rule:[{field:'accompanied_by_waiver_exemption',value:1,operator:'==='}],action:'ENABLE'}],
                                name:'type',
                                id:'type'
                            },
                            {
                                label:"Accompanied by a Skill Performance Evaluation (SPE) Certificate",
                                type:'RadioGroup',
                                name:'accompanied_by_spe_certificate',
                                id:'accompanied_by_spe_certificate',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                label:"Qualified by operation of 49 CFR 391.64",
                                type:'RadioGroup',
                                name:'qualified_by_operation',
                                id:'qualified_by_operation',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                label:"Driving within an exempt intracity zone",
                                type:'RadioGroup',
                                name:'driving_within_an_exempt_intracity_zone',
                                id:'driving_within_an_exempt_intracity_zone',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                type:'Blank',
                                name:''
                            },
                            {
                                label:"Determination pending",
                                type:'RadioGroup',
                                name:'determination_pending',
                                id:'determination_pending',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                label:'Reason',
                                type:'TextInput',
                                disabled:true,
                                rules:[{rule:[{field:'determination_pending',value:1,operator:'==='}],action:'ENABLE'}],
                                name:'determination_pending_reason',
                                id:'determination_pending_reason'
                            },
                            {
                                label:"Return to medical exam office for follow-up",
                                type:'RadioGroup',
                                name:'return_to_medical_exam_office_for_follow_up',
                                id:'return_to_medical_exam_office_for_follow_up',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                label:'On',
                                type:'Date',
                                disabled:true,
                                rules:[{rule:[{field:'return_to_medical_exam_office_for_follow_up',value:1,operator:'==='}],action:'ENABLE'}],
                                name:'follow_up_date',
                                id:'follow_up_date'
                            },
                            {
                                label:"Medical Examination Report amended",
                                type:'RadioGroup',
                                name:'medical_examination_report_amended',
                                id:'medical_examination_report_amended',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                label:'Reason',
                                type:'TextInput',
                                disabled:true,
                                rules:[{rule:[{field:'medical_examination_report_amended',value:1,operator:'==='}],action:'ENABLE'}],
                                name:'medical_examination_report_amended_reason',
                                id:'medical_examination_report_amended_reason'
                            },
                            
                            {
                                label:"Incomplete examination",
                                type:'RadioGroup',
                                name:'incomplete_examination',
                                id:'incomplete_examination',
                                options:[{label:'Yes',value:1},{label:'No',value:0}],
                            },
                            {
                                label:'Reason',
                                type:'TextInput',
                                disabled:true,
                                rules:[{rule:[{field:'incomplete_examination',value:1,operator:'==='}],action:'ENABLE'}],
                                name:'incomplete_examination_reason',
                                id:'incomplete_examination_reason'
                            },
                            {   
                                label:"If the driver meets the standards outlined in 49 CFR 391.41, then complete a Medical Examiner's Certificate as stated in 49 CFR 391.43(h), as appropriate.",
                                col:'col-12',
                                type:'Label'
                            },
                            {   
                                label:"I have performed this evaluation for certification. I have personally reviewed all available records and recorded information pertaining to this evaluation, and attest that to the best of my knowledge, I believe it to be true and correct",
                                col:'col-12',
                                type:'Label'
                            },
                            {
                                label:"Medical Examiner's Signature",
                                type:'Signature',
                                name:'medical_examiner_signature_two',
                                id:'medical_examiner_signature_two'
                            },
                            {
                                label:"Medical Examiner's Name",
                                type:'SelectSearchList',
                                isAutoPopulate:true,
                                placeholder:'Medical Examiner',
                                isAsync:true,
                                options:[],
                                loadCallBack:(val,callback)=>fetchProviderList(val,props,callback),
                                name:'medical_examiner_name',
                                id:'medical_examiner_name',
                               

                            },
                            {
                                label:"Medical Examiner's Address",
                                type:'Address',
                                name:'medical_examiner_address',
                                id:'medical_examiner_address',
                            },
                            {
                                label:"City",
                                type:'TextInput',
                                name:'city',
                                id:'city',
                            },
                            {
                                label:"State",
                                type:'TextInput',
                                name:'state',
                                id:'state',
                            },
                            {
                                label:"Zip code",
                                type:'TextInput',
                                name:'zip_code',
                                id:'zip_code',
                            },
                            {
                                label:"Medical Examiner's Telephone Number",
                                type:'Phone',
                                name:'medical_examiner_telephone_number',
                                id:'medical_examiner_telephone_number',
                            },
                            {
                                label:"Date Certificate Signed",
                                type:'Date',
                                name:'date_certificate_signed',
                                id:'date_certificate_signed',
                            },
                            {
                                label:"Issuing State",
                                type:'TextInput',
                                name:'issuing_state',
                                id:'issuing_state',
                            },
                            {
                                label:"Medical Examiner's State License, Certificate, or Registration Number",
                                type:'TextInput',
                                name:'medical_examiners_license_certificate_or_registration_number',
                                id:'medical_examiners_license_certificate_or_registration_number',
                            },
                            {
                                label:'Type of Medical Practitioner',
                                type:'MultiSelectDropDown',
                                isToogle:true,
                                ddKey:'medicalPractitioner',
                                options:optionsToKeyValuePairs(["Advanced Practice Nurse","Chiropractor","DO","MD","Other Practitioner","Physician Assistant"]),
                                name:'type_of_medical_practitioner',
                                id:'type_of_medical_practitioner'
                            },
                            {
                                label:'',
                                isLabel:false,
                                placeholder:'Specify',
                                type:'TextArea',
                                name:'specify',
                                id:'specify'
                            },
                            {
                                label:"National Registry Number",
                                type:'TextInput',
                                name:'national_registry_number',
                                id:'national_registry_number',
                            },
                            {
                                label:"Medical Examiner's Certificate Expiration Date",
                                type:'Date',
                                name:'medical_examiners_certificate_expiration_date',
                                id:'medical_examiners_certificate_expiration_date',
                            },
                        {
                            label:'Medical Record',
                            type:'TextInput',
                            name:'medical_record',
                            id:'medical_record'
                        },
                        {
                            label:'Exam Date',
                            type:'Date',
                            name:'exam_date',
                            id:'exam_date'
                        },
                        {
                            label:"Medical Examiner's Signature",
                            type:'Signature',
                            disabled:true,
                            rules:[{rule:[{field:'medical_examination_report_amended',value:1,operator:'==='}],action:'ENABLE'}],
                            name:'medical_examiner_signature',
                            id:'medical_examiner_signature'
                        },
                        {
                            label:'Date',
                            type:'Date',
                            disabled:true,
                            rules:[{rule:[{field:'medical_examination_report_amended',value:1,operator:'==='}],action:'ENABLE'}],
                            name:'medical_examination_report_amended_date',
                            id:'medical_examination_report_amended_date'
                        },
                        {
                            label:'Notes',
                            type:'TextArea',
                            name:'notes',
                        },
                        {
                            label:'Status',
                            type:'SelectList',
                            // options:[],
                            options:isSet(props.lookupsOptions['chartResult'],[]),
                            name:'status',
                            id:'status',
                        },
                    ]
                    }
                ]:[],
                    {
                        label:(type==='ProviderExam')?'Status':'Medical Examiner Determination and Status',
                        col:'col-6',
                        rules:(type==='ProviderExam')?[{ rule:[
                            {field:'medical_examiner_determination',value:"Non-DOT",operator:'===',logical:'OR'},
                            {field:'medical_examiner_determination',value:"",operator:'==='}
                          ], action:'SHOW'}]:[],
                        fields:[
                        {
                            label:'Medical Record',
                            type:'TextInput',
                            name:'medical_record',
                            id:'medical_record'
                        },
                        {
                            label:'Exam Date',
                            type:'Date',
                            name:'exam_date',
                            id:'exam_date'
    
                        },{
                            label:'Employment Status',
                            type:'MultiSelectDropDown',
                            ddKey:'employmentstatus',
                            isToogle:true,
                            rules:(type==='ProviderExam')?[{ rule:[
                                {field:'medical_examiner_determination',value:"",operator:'!=='}
                              ], action:'SHOW'}]:[],
                            options:{
                                default:[{label: 'Select All', value: 'ALL'}],
                                ['Medically acceptable for the position offered']:optionsToKeyValuePairs([
                                    "Commercial driving",
                                    "Limited lifting/pulling/pushing no more than",
                                    "Must wear corrective lenses",
                                    "No work where distinguishing color is required",
                                    "No work in high elevations",
                                    "Other existing conditions",
                                ],false),
                                ["Vaccines/Boosters"]:optionsToKeyValuePairs([
                                    "Recommended Hep A Vaccine/Booster",
                                    "Recommended Hep B Vaccine/Booster",
                                    "Other Vaccines/Boosters",
                                ],false),
                                ["Color Vision"]:optionsToKeyValuePairs([
                                    "No Recommendations for Color Vision",
                                    "Color vision recommendations"
                                ],false),
                                ["Audio Testing"]:optionsToKeyValuePairs([
                                    "No Recommendations for Audio Testing",
                                    "Audio testing recommendations"
                                ],false),
                                ["Grip Strength"]:optionsToKeyValuePairs([
                                    "No Recommendations for Grip Strength",
                                    "Grip strength recommendations"
                                ],false),
                                ["NO significant health problems present"]: [
                                    { label: "No Work Accommodations are necessary ", value: "NO significant_No Work Accommodations are necessary " },
                                    { label: "No activity restrictions are necessary", value: "NO significant_No activity restrictions are necessary" }
                                ],
                                ["MINOR significant health problems present"]: [
                                    { label: "No Work Accommodations are necessary", value: "MINOR significant_No Work Accommodations are necessary" },
                                    { label: "No activity restrictions are necessary", value: "MINOR significant_No activity restrictions are necessary" },
                                    { label: "Patient needs follow up with private doctor", value: "MINOR significant_Patient needs follow up with private doctor" },
                                ],
                                ["SIGNIFICANT health problems present"]:optionsToKeyValuePairs([
                                    "Work Accommodations are necessary",
                                    "We will contact the employer directly",
                                ],false),
                                ["Other"]:optionsToKeyValuePairs([
                                    "Placed on medical hold pending "
                                ],false)
                            },
                        
                            id:'employment_status',
                            name:'employment_status',
                        },
                        {
                            label:'Provider',
                            type:'SelectSearchList',
                            isAsync:true,
                            options:[],
                            autoPopulateFields:{
                                medical_examiners_certificate_expiration_date:'unavailable_until',
                            },
                            loadCallBack:(val,callback)=>fetchProviderList(val,props,callback),
                            name:'provider',
                            id:'provider',
                            rules:(type==='ProviderExam')?[{ rule:[
                                {field:'medical_examiner_determination',value:"",operator:'!=='}
                              ], action:'SHOW'}]:[],
                        },
                        {
                            label:'Provider Signature/Consent',
                            type:'Signature',
                            col:'col-6',
                            name:'provider_signature',
                            id:'provider_signature',
                            rules:(type==='ProviderExam')?[{ rule:[
                                {field:'medical_examiner_determination',value:"",operator:'!=='}
                              ], action:'SHOW'}]:[],
                        },
                        {
                            label:'Date',
                            type:'Date',
                            rules:(type==='ProviderExam')?[{ rule:[
                                {field:'medical_examiner_determination',value:"",operator:'!=='}
                              ], action:'SHOW'}]:[],
                            name:'provider_signature_date',
                        },
                        {
                            label:'Notes',
                            col:'col-6',
                            type:'TextArea',
                            rules:(type==='ProviderExam')?[{ rule:[
                                {field:'medical_examiner_determination',value:"",operator:'!=='}
                              ], action:'SHOW'}]:[],
                            name:'notes',
                        },
                        {
                            label:'Status',
                            type:'SelectList',
                            // options:[],
                            options:isSet(props.lookupsOptions['chartResult'],[]),
                            name:'status',
                            id:'status',
                        },
                    ]
                }
              ]
        },
        {
            id:'patient',
            data:  [
                {
                    label:'Medical Certificate History',
                    fields:[ {
                        label:'CLP/CDL Applicant/Holder',
                        type:'SelectList',
                        name:'clp_cdl_applicant_holder',
                        col:'col-6',
                        id:'clp_cdl_applicant_holder',
                        options:optionsToKeyValuePairs(['Yes','No','Non-DOT Physical']),
                    },
                    {
                        label:'Has your USDOT/FMCSA medical certificate ever been denied or issued for less than 2 years?',
                        type:'SelectList',
                        name:'usdot_fmcsa_medical_certificate',
                        col:'col-6',
                        id:'usdot_fmcsa_medical_certificate',
                        options:optionsToKeyValuePairs(['Yes','No','Not sure','Non-DOT Physical']),
                    },
]
                },
                {
                    label:'Health Information',
                    note:'Check the body systems for abnormalities',
                    fields:[
                        {
                        label:'Have you ever had surgery ?',
                        type:'RadioGroup',
                        name:'surgery_history',
                        col:'col-12',
                        id:'surgery_history',
                        options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                                , { label: 'No', value: 'No' }
                                , { label: 'Not Sure', value: 'Not Sure', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }],
                        },
                    {
                        type:'Blank',
                        col:'col-6',
                    },
                    {
                        label:'',
                        type:'TextArea',
                        col:'col-6',
                        disabled:true,
                        rules:[{rule:[{field:'surgery_history',value:'Yes',operator:'==='}],action:'ENABLE'}],
                        isLabel:false,
                        name:'surgery_explanation',
                        id:'surgery_explanation',
                    },
                    
                    {
                        label:"Are you currently taking medications (prescription, over-the-counter, herbal remedies, diet supplements)?",
                        type:'RadioGroup',
                        col:'col-12',
                        labelWrap:false,
                        name:'taking_medications',
                        id:'taking_medications',
                        options: [{ label: 'Yes', value: 'Yes', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }
                            , { label: 'No', value: 'No' }
                            , { label: 'Not Sure', value: 'Not Sure', labelStyle: { color: 'red' }, inputStyle: { backgroundColor: 'red', borderColor: 'red' } }],
                    },
                    {
                        type:'Blank',
                        col:'col-6',
                    },
                    {
                        label:'',
                        type:'TextArea',
                        col:'col-6',
                        isLabel:false,
                        disabled:true,
                        rules:[{rule:[{field:'taking_medications',value:'Yes',operator:'==='}],action:'ENABLE'}],
                        name:'taking_medication_explanation',
                        id:'taking_medication_explanation',
                    },
                    {
                        label:'Do you have or have you ever had:',
                        type:'Label'
                    }, 
                    {
                        col:'col-12',
                        label:'',
                        defaultBtnLabel:'Set all to No',
                        type:'YesOrNoTable',
                        options:[{label:'Yes',value:'Yes'},{label:'No',value:'No'},{label:'Not Sure',value:'Not Sure'}],
                        defaultValues:patientHealthInformation.reduce((acc, itm) => {
                            acc[itm.name] = 'No';
                            return acc;
                        }, {}),
                        failedValue:['Yes','Not Sure'],
                        list:patientHealthInformation,
                        
                    },
                    {
                        label:'Other health condition(s) not described above ?',
                        type:'RadioGroup',
                        name:'other_conditions',
                        col:'col-12',
                        id:'other_conditions',
                        options:['Yes','No','Not Sure'],
                    },
                    {
                        type:'Blank',
                        col:'col-6',
                    },
                    {
                        label:'',
                        type:'TextArea',
                        col:'col-6',
                        isLabel:false,
                        disabled:true,
                        rules:[{rule:[{field:'other_conditions',value:'Yes',operator:'==='}],action:'ENABLE'}],
                        name:'other_conditions_description',
                        id:'other_conditions_description',
                    },
                    
                    {
                        label:`Did you answer 'yes' to any of questions 1-${type==='ProviderExam' ? '32' : '25'}? If so, please comment further on those health conditions below.`,
                        type:'RadioGroup',
                        col:'col-12',
                        name:'additional_comments',
                        id:'additional_comments',
                        options:['Yes','No','Not Sure'],
                    },
                    {
                        type:'Blank',
                        col:'col-6',
                    },
                    {
                        label:'',
                        type:'TextArea',
                        col:'col-6',
                        isLabel:false,
                        disabled:true,
                        rules:[{rule:[{field:'additional_comments',value:'Yes',operator:'==='}],action:'ENABLE'}],
                        name:'additional_comments_description',
                        id:'additional_comments_description',
                    },
                ]
                },
                (type==='ProviderExam')?
                {
                    label:'CMC Driver',
                    col:'col-12',
                    fields:[
                        {
                            type:'Label',
                            label:"I certify that the above information is accurate and complete. I understand that inaccurate, false or missing information may invalidate the examination and my Medical Examiner's Certificate, that submission of fraudulent or intentionally false information is a violation of 49 CFR 390.35, and that submission of fraudulent or intentionally false information may subject me to civil or criminal penalties under 49 CFR 390.37 and 49 CFR 386 Appendices A and B.",
                        },
                        {
                        label:"Driver's Signature",
                        type:'Signature',
                        col:'col-6',
                        name:'driver_signature',
                        id:'driver_signature',
                    },
                    {
                        label:'Date',
                        type:'Date',
                        col:'col-6',
                        id:'signatured_date',
                        name:'signatured_date',
                    }
                ]
                }:
                {
                    label:'Approval Signature & Date',
                    note:'I certify that the above information is accurate and complete.',
                    col:'col-12',
                    fields:[{
                        label:'Employee Signature',
                        type:'Signature',
                        col:'col-6',
                        name:'employee_signature',
                        id:'employee_signature',
                    },
                    {
                        label:'Date',
                        type:'Date',
                        col:'col-6',
                        id:'signatured_date',
                        name:'signatured_date',
                    }
                ]
                }]
        },
        {
            id:'patientVisitHistory',
            data:[ {
                label:'Documents',
                fields:[{
                    type:'ChartDocument',
                    employeeDetails:props.employeeDetails,
                    visitDetails:props.visitDetails,
                    mappingId:props.mappingId,
                    additional_documents:props?.formData?.documents,
                }]
            }],
        }]
    },
   ];
    const headers = [{
        type:'Tab',
        data:[{
            label:'Provider',id:'provider'
        },
        {
            label:'Patient',id:'patient'
        },
        props.formData.id && props.formData.visit_mapping_id?
        {
            label:'Patient Visit History',
            id:'patientVisitHistory'
        }
        : ''
    ]
    }];
    const defaultValue = {
        status:'Not Started',
        pulse_rate_result:'Not Started',
        blood_pressure_result:'Not Started',
        vission_result:'Not Started',
        urinalysis_result:'Not Started',
        medical_examiner_determination:''
    };
    const buttons = {
        PDF:true
    }
    return {charts,defaultValue,buttons,headers,computationFunction: (formData,key) => computationRules(formData,key)};
}


const computationRules = (formData,key)=>{
    const pulse = isSet(formData.pulse_rate,'');
    const systolic = isSet(formData.sitting_systolic,'');
    const diastolic = isSet(formData.sitting_diastolic,'');
    let returnResult = {};

    let pulseRateResult = 'Not Started';
    if(pulse==''){
        pulseRateResult = 'Not Started'
    }else if (pulse >= 60 && pulse <= 100) {
        pulseRateResult =  'Normal';
    }else if(pulse!==''){
        pulseRateResult = 'Abnormal';
    }

    let bloodPressureResult = 'Not Started';
    if(systolic === '' && diastolic === ''){
        bloodPressureResult = 'Not Started'
    }else if (systolic === '' || diastolic === '') {
        bloodPressureResult = 'Pending';
    } else if ((systolic < 0 || systolic > 139) || (diastolic < 0 || diastolic >89)) {
        bloodPressureResult = 'Abnormal';
    } else {
        bloodPressureResult = 'Normal';
    }


    
    const spgr    = isSet(formData.sp_gr,'');
   const protein = isSet(formData.protein,'');
   const blood   = isSet(formData.blood,'');
   const sugar   = isSet(formData.sugar,'');

   let urinariesResult = 'Not Started';

   const sgNormalRange = { min: 1.002, max: 1.030 };
   const sgWithinNormalRange = spgr >= sgNormalRange.min && spgr <= sgNormalRange.max;

   // Check Protein, Blood, and Sugar levels
   const abnormalLevels = protein > 0 || blood > 0 || sugar > 0;

   // Determine status
   if(sgNormalRange==='' || protein==='' || blood==='' || sugar ===0){
    urinariesResult = 'Pending';
   }else if (sgWithinNormalRange && !abnormalLevels) {
    urinariesResult = "Normal";
   } else if (!sgWithinNormalRange || abnormalLevels) {
    urinariesResult = "Abnormal";
   } else {
    urinariesResult = "Pending";
   }

    let vissionResult = 'Not Started';
    const wearing_corrective_lenses = isSet(formData['wearing_corrective_lenses'],null);
    const conditions = wearing_corrective_lenses===0?'uncorrected':'corrected';
    const horizontalRighteye = isSet(formData['horizontal_field_of_vision_right_eye'],'');
    const horizontalLeftEye = isSet(formData['horizontal_field_of_vision_left_eye'],'');
    const applicantRecoginize = isSet(formData['applicant_recognize_traffic_control_signals'],'');
    const monocularvision = isSet(formData['monocular_vision'],'');
    
    
    if(wearing_corrective_lenses!==null){
            const rightEye  = isSet(formData[''+conditions+'_right_eye'],'');
            const bothEyes  = isSet(formData[''+conditions+'_both_eyes'],'');
            const leftEye  = isSet(formData[''+conditions+'_left_eye'],'');

            if (rightEye === '' || bothEyes === '' || leftEye === '' || horizontalRighteye === '' || horizontalLeftEye === '') {
                vissionResult = 'Pending';
            } else if (rightEye > 40 || bothEyes > 40 || leftEye > 40 || horizontalRighteye < 70 || horizontalLeftEye < 70) {
                vissionResult = 'Failed';
            } else {
                vissionResult = 'Passed';
            }
    }else{
        if(horizontalRighteye==='' || horizontalLeftEye===''){
            vissionResult = 'Pending';
        }else if(horizontalRighteye<70 ||  horizontalLeftEye<70 || applicantRecoginize==='No' || monocularvision==='No'){
            vissionResult = 'Failed';
        }else{
            vissionResult = 'Passed';
        }
    }

        
    const earPrefixes = ['right_ear_', 'left_ear_'];
    earPrefixes.forEach(earPrefix => {
        const frequencies = [500, 1000, 2000];
        let total = 0;
        
       
        frequencies.forEach(frequency => {
            const value = isSet(formData[`audiometric_test_results_${earPrefix}${frequency}_hz`], '');
            total += Number(value);
        });
        const average = Math.ceil(total / frequencies.length);
        returnResult[`audiometric_test_results_${earPrefix}average`] = average;
    });

    const leftAverage = returnResult[`audiometric_test_results_right_ear_average`];
    const rightAverage = returnResult[`audiometric_test_results_left_ear_average`];
    const whisperTestRightEar = isSet(formData[`whisper_test_results_right_ear`], '');
    const whisperTestLeftEar = isSet(formData[`whisper_test_results_left_ear`], '');
    let hearingResult = 'Not Started';
    if(Number(leftAverage)===0 && Number(rightAverage)===0 && whisperTestRightEar=='' && whisperTestLeftEar===''){
        hearingResult = 'Not Started';
    }else if ((leftAverage!=='0.00' && leftAverage <= 40 && leftAverage !== 0) || (rightAverage!=='0.00' && rightAverage <= 40 && rightAverage !== 0) || (whisperTestRightEar >= 5 || whisperTestLeftEar >= 5)) {
        // console.log('ssss',leftAverage,rightAverage,whisperTestRightEar,whisperTestLeftEar);
        hearingResult = 'Passed';
    } else if ((leftAverage === 0 || rightAverage === 0) && (leftAverage > 40 || rightAverage > 40)) {
        hearingResult = 'Failed';
    } else if(leftAverage > 40 || rightAverage > 40 || (whisperTestRightEar < 5 && whisperTestLeftEar < 5)){
        hearingResult = 'Failed';
    }else{
        hearingResult = 'Pending';
    }
    returnResult = {
        ...returnResult,
        pulse_rate_result:pulseRateResult,
        blood_pressure_result:bloodPressureResult,
        vission_result:vissionResult,
        hearing_result:hearingResult,
        urinalysis_result:urinariesResult,
        certification_suggested_length:getCertificationLength(systolic,diastolic),

    }
    return returnResult;
}



export const fetchProviderList = async (val,props, callback=()=>{}) => {
    const {employeeDetails} = props;
    let optionArr = [];
    try{
        const data = await ProviderService.getProviderByClinicId(employeeDetails.clinic_id,{search:val});
        isSet(data,[]).map((item,itemIndex)=>{
            optionArr.push({
                label:isSet(item.first_name,'')+' '+isSet(item.last_name,''),
                value:item.provider_id,
                autoPopulateFields:{
                    medical_examiners_license_certificate_or_registration_number:item.license,
                    national_registry_number:item.dol_registry_id,
                    medical_examiners_certificate_expiration_date:item.dol_registry_expires,
                },
                data:item,
                index:itemIndex
            })
        });
        callback(optionArr);
    }catch(e){

    }
    return optionArr;
  }

export const  getCertificationLength = (systolic, diastolic)=> {
    if(systolic==='' || diastolic===''){
        return '';
    }
    if (systolic < 140 && diastolic < 90) {
      return "2 year DOT Certificate";
    } else if (systolic >= 140 && systolic <= 159 && diastolic >= 90 && diastolic <= 99) {
      return "1 year DOT Certificate";
    } else if (systolic >= 160 && systolic <= 179 && diastolic >= 100 && diastolic <= 109) {
      return "3 month DOT Certificate";
    } else if (systolic >= 180 || diastolic >= 110) {
      return "Disqualified";
    } else {
      return "1 year DOT Certificate";
    }
  }