import React from "react";
import { isSet } from "../../utils/commonUtils";


const SectionLoader = ({isLoading})=>{
    if(isSet(isLoading,false)===false){
        return false;
    }
    return(
        <div className="card_loaderOuter">
            <div className="spinner-border spinner-border-sm text-dark" role="status">
            <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default SectionLoader;