// src/services/userService.js
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';
import { OK } from '../utils/constant';

const LabOrderdService = {
  getLabOrder: wOrgIdMiddleware(async (url, labId) => {
    try {

      const response = await http.GET(`${url}labs/${labId}/lab-order`);
      if (response.code === 200 && response.data) {
        return response.data;
      }
      
      return null;
    } catch (error) {
      // console.error('Error fetching user:', error);
      throw error;
    }
  }, true),
  saveLabOrder: wOrgIdMiddleware(async (url = '',labId, data) => {
    try {
      const response = await http.POST(`${url}labs/${labId}/lab-order`, data)
      // console.log('response',response);
      
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),

  updateLabOrder: wOrgIdMiddleware(async (url,labId, id, data) => {
    try {
      const response = await http.PUT(`${url}labs/${labId}/lab-order/${id}`, data);
      // if (response.code === 200 ) {
        return response;
      // }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),
  deleteLabOrder: wOrgIdMiddleware(async (url, labId,id) => {
    try {
      const response = await http.DELETE(`${url}labs/${labId}/lab-order/${id}`);
      
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return null;
      }
      
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),
  getLabOrderById: wOrgIdMiddleware(async (url, labId) => {
    try {
      const response = await http.GET(`${url}lab-order/${labId}`);
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),

};

export default LabOrderdService;