import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import VisitsList from "./VisitsList";
import EmployeeVisitDetailView from "./EmployeeVisitDetailView";

const EmployeeContainer = () => {

  return (
    <Wrap>  
      {/* Other common elements in UserContainer */}
      <Routes>
        <Route path="/" key={0}  element={<VisitsList/>} />
        <Route path="/visit-details/:visitId/:backTo?" key={1} element={<EmployeeVisitDetailView />}/>
        {/* <Route path=":filter" key={2}  element={<VisitsList/>} /> */}
      </Routes>
      {/* <Outlet /> */}
  
     
        
    </Wrap>
  );
};






export default EmployeeContainer;
