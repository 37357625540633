import React from "react";
import Acl from "../../hoc/Acl";

const ButtonLoader = (props) => {
    const { isLoading, className, clickHandler, label, id, name,acl } = props;
    return (
        <Acl acl={acl}>
            <div className="">
                <button
                    className={className}
                    disabled={isLoading}
                    onClick={clickHandler}
                    id={id}
                    name={name}
                >
                    {isLoading && (
                        <div className="spinner-border spinner-border-sm text-light mr-2" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                    {label}
                </button>
            </div>
        </Acl>
    );
};

ButtonLoader.defaultProps = {
    isLoading: false,
    className: "btn btn-primary",
    clickHandler: () => { },
    label: "Submit",
    id: "buttonLoader",
    name: "buttonLoader",
    acl:"any",
};

export default ButtonLoader;
